'use strict';

/**
 * @description Simple table Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { render } from 'lit-html';
import { store } from '../../../app.init';
import { templateSimpleTable } from './simpletable.template';

let componentSimpleTable = (function () {
  let context = {};
  let handlers = {};
  let renderSuccess = false;

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let renderComponent = (columns, dataPaginated, data, tableElement) => {
    try {
      // Render the template to the document
      render(templateSimpleTable(), document.querySelector(tableElement));
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = (columns, dataPaginated, data, tableElement) => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = (columns, dataPaginated, data, tableElement) => {
    //Call pre render component
    preRenderComponent(columns, dataPaginated, data, tableElement);

    // Call render component
    renderComponent(columns, dataPaginated, data, tableElement);

    // Call post render component if it has been rendered
    renderSuccess
      ? postRenderComponent(columns, dataPaginated, data, tableElement)
      : '';
  };

  return {
    render: (columns, dataPaginated, data, tableElement) =>
      init(columns, dataPaginated, data, tableElement),
  };
})();

export { componentSimpleTable };
