'use strict';
import i18n from 'i18next';

let templateChart17 = () => {
  return `
    <div
    class="esios-home__widget-legend highlighted-legend visual-legend"
    >
    <ul>
        <li
        class="legend-item"
        id="demanda-real"
        data-id="1293"
        >
        <div class="item">
            <span
            class="icon-eye"
            style="background-color: #FFCF09;"
            data-id="1293"
            ></span
            ><span class="label">${i18n.t('real_demand')}</span>
        </div>
        <div class="value is-number">
            <span id="demanda-real-value" class="caption is-big"
            >-</span
            >
            <span class="unit">MW</span>
        </div>
        </li>
        <li
        class="legend-item"
        id="demanda-prevista"
        data-id="544"
        >
        <div class="item">
            <span
            class="icon-eye"
            style="background-color: #74BA05;"
            data-id="544"
            ></span
            ><span class="label"
            >${i18n.t('forecasted_demand')}</span
            >
        </div>
        <div class="value is-number">
            <span
            id="demanda-prevista-value"
            class="caption is-medium is-medium--demanda"
            >-</span
            >
            <span class="unit">MW</span>
        </div>
        </li>
        <li
        class="legend-item"
        id="demanda-programada"
        data-id="545"
        >
        <div class="item">
            <span
            class="icon-eye"
            style="background-color: #DF4A32;"
            data-id="545"
            ></span
            ><span class="label"
            >${i18n.t('scheduled_demand')}</span
            >
        </div>
        <div class="value is-number">
            <span
            id="demanda-programada-value"
            class="caption is-medium is-medium--demanda"
            >-</span
            >
            <span class="unit">MW</span>
        </div>
        </li>
    </ul>
    </div>
  `;
};

export { templateChart17 };
