'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';
import backimage from '../../assets/images/header-default.jpg';
import { componentLayout } from '../../components/layout/layout.component';

let templateCalendario = () => {
  /**AQUÍ ESTOY SIMULANDO LOS TABS DINAMICOS PUEDES CREAR N TABS */
  let miArrayTab = [{ text: i18n.t('fraesp') }, { text: i18n.t('poresp') }];

  const removeClassActive = () => {
    /**Esto es para los tabs */
    const listItems = document.querySelectorAll('.ui-corner-top');
    listItems.forEach((item) => item.classList.remove('ui-tabs-active'));
  };

  const showTable = (itemTab) => {
    const listItems = document.querySelectorAll('.ui-corner-top');
    const index = Array.from(listItems).indexOf(itemTab);

    /**Esto es para el contenedor de las tablas */
    const listItemsContainer = document.querySelectorAll('.ui-tabs-panel');
    listItemsContainer.forEach((item) => {
      item.classList.remove('ui-tabs-active');
      return item.classList.add('ui-tabs-inactive');
    });

    listItemsContainer[index].classList.remove('ui-tabs-inactive');
    listItemsContainer[index].classList.add('ui-tabs-active');
  };

  /**Evento que detecta un click */
  const handleClick = (e) => {
    e.preventDefault();
    removeClassActive();
    e.target.parentElement.classList.add('ui-tabs-active');
    showTable(e.target.parentElement);
  };

  const buildLegend = (index) => {
    if (index === 0) {
      return html`<div
        class="esios-calendar__legend esios-calendar__legend--three"
      >
        <div class="esios-calendar__legend-col">
          <div class="esios-calendar__legend-content">
            <div class="esios-calendar__legend-part">
              <span class="esios-calendar__legend-working-day">26</span>
              <span class="esios-calendar__legend-txt"
                >${i18n.t('working_day')}</span
              >
            </div>
            <div class="esios-calendar__legend-part">
              <span class="esios-calendar__legend-not-working-day">26</span>
              <span class="esios-calendar__legend-txt"
                >${i18n.t('working_day_not')}</span
              >
            </div>
          </div>
        </div>
        <div class="esios-calendar__legend-col">
          <div class="esios-calendar__legend-content">
            <div class="esios-calendar__legend-part">
              <span class="esios-calendar__legend-title-txt"
                >${i18n.t('monthly')}</span
              >
            </div>
            <div class="esios-calendar__legend-part">
              <span class="esios-calendar__legend-month-specifications"
                >26</span
              >
              <span class="esios-calendar__legend-txt"
                >${i18n.t('specifications')}</span
              >
            </div>
            <div class="esios-calendar__legend-part">
              <span
                class="esios-calendar__legend-month-specifications esios-calendar__legend-month-specifications--inverse"
                >26</span
              >
              <span class="esios-calendar__legend-txt"
                >${i18n.t('auction')}</span
              >
            </div>
          </div>
        </div>
        <div class="esios-calendar__legend-col">
          <div class="esios-calendar__legend-content">
            <div class="esios-calendar__legend-part">
              <span class="esios-calendar__legend-title-txt"
                >${i18n.t('yearly')}</span
              >
            </div>
            <div class="esios-calendar__legend-part">
              <span class="esios-calendar__legend-year-specifications">26</span>
              <span class="esios-calendar__legend-txt"
                >${i18n.t('specifications')}</span
              >
            </div>
            <div class="esios-calendar__legend-part">
              <span
                class="esios-calendar__legend-year-specifications esios-calendar__legend-year-specifications--inverse"
                >26</span
              >
              <span class="esios-calendar__legend-txt"
                >${i18n.t('auction')}</span
              >
            </div>
          </div>
        </div>
      </div>`;
    } else {
      return html`<div
        class="esios-calendar__legend esios-calendar__legend--four"
      >
        <div class="esios-calendar__legend-col">
          <div class="esios-calendar__legend-content">
            <div class="esios-calendar__legend-part">
              <span class="esios-calendar__legend-working-day">26</span>
              <span class="esios-calendar__legend-txt"
                >${i18n.t('working_day')}</span
              >
            </div>
            <div class="esios-calendar__legend-part">
              <span class="esios-calendar__legend-not-working-day">26</span>
              <span class="esios-calendar__legend-txt"
                >${i18n.t('working_day_not')}</span
              >
            </div>
          </div>
        </div>
        <div class="esios-calendar__legend-col">
          <div class="esios-calendar__legend-content">
            <div class="esios-calendar__legend-part">
              <span class="esios-calendar__legend-title-txt"
                >${i18n.t('monthly')}</span
              >
            </div>
            <div class="esios-calendar__legend-part">
              <span class="esios-calendar__legend-month-specifications"
                >26</span
              >
              <span class="esios-calendar__legend-txt"
                >${i18n.t('specifications')}</span
              >
            </div>
            <div class="esios-calendar__legend-part">
              <span
                class="esios-calendar__legend-month-specifications esios-calendar__legend-month-specifications--inverse"
                >26</span
              >
              <span class="esios-calendar__legend-txt"
                >${i18n.t('auction')}</span
              >
            </div>
          </div>
        </div>
        <div class="esios-calendar__legend-col">
          <div class="esios-calendar__legend-content">
            <div class="esios-calendar__legend-part">
              <span class="esios-calendar__legend-title-txt"
                >${i18n.t('quarterly')}</span
              >
            </div>
            <div class="esios-calendar__legend-part">
              <span class="esios-calendar__legend-quarter-specifications"
                >26</span
              >
              <span class="esios-calendar__legend-txt"
                >${i18n.t('specifications')}</span
              >
            </div>
            <div class="esios-calendar__legend-part">
              <span
                class="esios-calendar__legend-quarter-specifications esios-calendar__legend-quarter-specifications--inverse"
                >26</span
              >
              <span class="esios-calendar__legend-txt"
                >${i18n.t('auction')}</span
              >
            </div>
          </div>
        </div>
        <div class="esios-calendar__legend-col">
          <div class="esios-calendar__legend-content">
            <div class="esios-calendar__legend-part">
              <span class="esios-calendar__legend-title-txt"
                >${i18n.t('yearly')}</span
              >
            </div>
            <div class="esios-calendar__legend-part">
              <span class="esios-calendar__legend-year-specifications">26</span>
              <span class="esios-calendar__legend-txt"
                >${i18n.t('specifications')}</span
              >
            </div>
            <div class="esios-calendar__legend-part">
              <span
                class="esios-calendar__legend-year-specifications esios-calendar__legend-year-specifications--inverse"
                >26</span
              >
              <span class="esios-calendar__legend-txt"
                >${i18n.t('auction')}</span
              >
            </div>
          </div>
        </div>
      </div>`;
    }
  };

  const buildCalendar = (index) => {
    if (index === 0) {
      return html`<div id="auctions-calendar"></div>`;
    } else {
      return html`<div id="auctions-calendar-por"></div>`;
    }
  };

  const buildTab = (array) => {
    return html`${array.map((item, index) => {
      let className =
        index === 0
          ? 'ui-state-default ui-corner-top ui-tabs-active'
          : 'ui-state-default ui-corner-top';
      return html` <li class=${className}>
        <a class="ui-tabs-anchor" href="#" @click=${handleClick}>
          ${item.text}
        </a>
      </li>`;
    })}`;
  };

  const buildContainer = (array) => {
    return html`${array.map((item, index) => {
      let className =
        index === 0
          ? 'ui-tabs-panel ui-widget-content ui-corner-bottom ui-tabs-active'
          : 'ui-tabs-panel ui-widget-content ui-corner-bottom ui-tabs-inactive';
      return html`
        <div
          class=${className}
          id="fra"
          aria-labelledby="ui-id-1"
          role="tabpanel"
          aria-hidden="false"
        >
          <div class="is-xs-hidden">
            <div class="row">${buildCalendar(index)}</div>
            ${buildLegend(index)}
          </div>
        </div>
      `;
    })}`;
  };

  return componentLayout.render(
    html`
      <div id="calendarspinner" class="spinner-container">
        <div class="spinner"></div>
      </div>
      <div id="calendar-container" class="esios-calendar">
        <div class="grid-xs-24 title-for-print">
          <h1 class="esios-help__title">${i18n.t('auctions_calendar')}</h1>
          <div id="select-year-print"></div>
        </div>
        <div class="esios-toolbar">
          <!-- toolbar -->
          <div class="esios-toolbar1"></div>
        </div>
        <div id="auctionsCalendar" class="widget-myp-auction-calendar">
          <div
            class="mod-tabs ui-tabs ui-widget ui-widget-content ui-corner-all"
          >
            <ul
              class="ui-tabs-nav ui-helper-reset ui-helper-clearfix ui-widget-header ui-corner-all"
              role="tablist"
            >
              ${buildTab(miArrayTab)}
            </ul>
          </div>
        </div>
        ${buildContainer(miArrayTab)}
        <div id="auctionsCalendarMobile" class="esios-calendar__mobile"></div>
        <div id="print-button" class="print-btn"></div>
      </div>
    `,
    {
      image: backimage,
      hasCalendar: false,
      select: true,
      showFooterImage: true,
    }
  );
};

export { templateCalendario };
