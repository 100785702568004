'use strict';
import { api } from './../../utils/api-interceptor';
import i18n from 'i18next';

let servicesDocumentacion = (() => {
  let init = () => llamarServicioDocumentacion();

  const llamarServicioDocumentacion = async () => {
    const urlApi =
      'static_pages?slug=unidades-fisicas&temporal=' +
      Math.floor(Math.random() * 1000);

    try {
      const data = await api(urlApi, { hasLang: true });
      return data.content;
    } catch (error) {}
  };

  // Obteniendo datos para la tabla Unidades Fisicas

  const getArchivesDocumentacion = async (params) => {
    const urlApi =
      `documentations?order=published&locale=${i18n.t(
        'routes.lng'
      )}&temporal=` + Math.floor(Math.random() * 1000);

    try {
      const data = await api(urlApi);
      return data.contents;
    } catch (error) {}
  };

  return {
    init: init(),
    getArchivesDocumentacion: (params) => getArchivesDocumentacion(params),
  };
})();

export { servicesDocumentacion };
