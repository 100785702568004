'use strict';
import 'regenerator-runtime/runtime';
import i18n from 'i18next';
import { api } from '../../utils/api-interceptor';

let servicesAyudaFAQ = (() => {
  let init = (e) => llamarServicio(e);

  const llamarServicio = async (e) => {
    let dataHelp = await api(
      `static_pages?slug=${i18n.t('routes.help')}&temporal=` +
        Math.floor(Math.random() * 1000),
      {
        hasLang: true,
      }
    );
    let dataFAQ = await api(
      `static_pages?slug=${i18n.t('routes.faq')}&temporal=` +
        Math.floor(Math.random() * 1000),
      {
        hasLang: true,
      }
    );
    return { dataHelp: dataHelp.content, dataFAQ: dataFAQ.content };
  };

  return {
    init: (e) => init(e),
  };
})();

export { servicesAyudaFAQ };
