'use strict';

/**
 * @description Help Component
 * @returns {Object} Devuelve la instancia del componente
 */

import i18n from 'i18next';
import { render } from 'lit-html';
import { store } from '../../app.init.js';
import { Utils } from '../../utils/utils.js';
import { componentButtons } from '../../shared/components/buttons/buttons.component.js';
import { componentListPrintView } from '../../shared/components/list-print-view/list-print-view.component.js';
import { templateTable } from '../table/table.template.js';
import { servicesActivation } from './activation.services.js';
import { templateActivation } from './activation.template.js';
import moment from 'moment';
import { componentPaginator } from '../../shared/components/paginator/paginator.component.js';
let componentActivation = (function () {
  let context = {};
  let renderSuccess = false;
  let contactData = '';
  let handlers = {
    onPageChange: (page) => onPageChange(page),
    onTotalChange: (event) => onTotalChange(event),
    onQueryChange: (event) => onQueryChange(event),
    onSmChange: (event) => onSmChange(event),
    onHide: (event) => onHide(event),
    ocultar: (event) => ocultar(event),
  };
  let select = [];
  let objMarket;
  let listMarket;
  let lng = window.location.pathname.split('/')[1];

  let columns;

  const searchForm = 'BUSCAR';

  let data = [];

  let filterForm = {
    query: '',
    sm: '',
  };
  let dataPaginated = [];

  let paginationValues = [25, 50, 100];
  let pagination = {
    total: 0,
    page: 1,
    totalPages: 1,
    pagination: paginationValues[0],
  };

  const getData = () => {
    return servicesActivation.getData().then((respData) => {
      data = Object.values(respData);
      pagination.total = data.length;
      pagination.totalPages = Math.ceil(data.length / pagination.pagination);

      const filters = {
        activation: [],
      };

      const filterAttrs = [
        { label: i18n.t('activation'), value: 'activation', id: 1 },
      ];
      select = Utils.createFilterModel(respData, filters, filterAttrs);
      if (respData) {
        document.getElementById('spinner1').style.display = 'none';
      } else {
        return respData;
      }
    });
  };

  const onPageChange = (page) => {
    pagination.page = page;
    pagination.totalPages = Math.ceil(data.length / pagination.pagination);
    filterData();
  };

  const onTotalChange = (event) => {
    pagination.page = 1;
    pagination.pagination = event.target.value;
    pagination.totalPages = Math.ceil(data.length / pagination.pagination);
    filterData();
  };

  const ocultar = (event) => {
    document
      .querySelector('.esios-smercado__boton-remove')
      .classList.add('hidden');
    filterForm.query = '';
    filterData();
  };

  const filterData = () => {
    let filtered = data.filter((item) => {
      return item[i18n.t('activation')]
        .toLowerCase()
        .includes(filterForm.query.toLowerCase());
    });

    const filterQuery = (column) => {
      let filter = data.filter((item) => {
        return item[i18n.t(column)]
          .toLowerCase()
          .includes(filterForm.query.toLowerCase());
      });
      filtered = [...filter, ...filtered];
    };

    const filterSelect = (select, attribute) => {
      filtered = filtered.filter((item) => {
        if (filterForm[attribute] === '') {
          return item[i18n.t(select)].toLowerCase().includes('');
        } else {
          return (
            item[i18n.t(select)].toLowerCase() ===
            filterForm[attribute].toLowerCase()
          );
        }
      });
    };

    filterQuery('activation-date');
    filterQuery('activation-power');
    filterQuery('activation-energy');
    filterQuery('activation-price');
    // filterSelect('BRP-Type', 'sm');

    var hash = {};
    filtered = filtered.filter(function (current) {
      var exists = !hash[current[i18n.t('activation')]];
      hash[current[i18n.t('activation')]] = true;
      return exists;
    });

    dataPaginated = filtered.slice(
      (pagination.page - 1) * pagination.pagination,
      pagination.page * pagination.pagination
    );
    const columns = [
      { name: i18n.t('activation'), field: i18n.t('activation') },
      { name: i18n.t('activation-date'), field: i18n.t('activation-date') },
      { name: i18n.t('activation-power'), field: i18n.t('activation-power') },
      { name: i18n.t('activation-energy'), field: i18n.t('activation-energy') },
      { name: i18n.t('activation-price'), field: i18n.t('activation-price') },
    ];
    componentListPrintView.render('print-list', dataPaginated, columns);
    pagination.total = filtered.length;
    pagination.totalPages = Math.ceil(filtered.length / pagination.pagination);
    renderAll();
    renderTable();
  };

  const onHide = (event) => {
    document.querySelector('.esios-tabla__input').value = '';
    document
      .querySelector('.esios-smercado__button-remove')
      .classList.add('hidden');
    filterForm.query = '';
    filterData();
  };

  let renderTable = () => {
    const tableElement = document.getElementById('table');
    render(templateTable(columns, dataPaginated, data), tableElement);
    componentPaginator.render(pagination, onPageChange, 'pagination-sujetos');
  };

  let renderAll = () => {
    templateActivation(
      contactData,
      { objMarket, listMarket },
      handlers,
      select,
      searchForm,
      paginationValues,
      pagination
    );

    document
      .getElementById('esios-layout-banner')
      .classList.add('hideForPrint');
  };

  const onQueryChange = (event) => {
    filterForm.query = event.target.value;
    if (event.target.value.length > 0)
      document
        .querySelector('.esios-smercado__button-remove')
        .classList.remove('hidden');
    else
      document
        .querySelector('.esios-smercado__button-remove')
        .classList.add('hidden');
    filterData();
  };

  const onSmChange = (event) => {
    filterForm.sm = event.target.value;
    let selected = document.querySelector('.ui-selectmenu-text');
    filterForm.sm === ''
      ? (selected.innerHTML = i18n.t('activation'))
      : filterForm.sm;
    filterData();
  };

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();

    document.title = `${i18n.t('service_activation')} | ${i18n.t(
      'page_subtitle'
    )}`;
    context.name = state.exampleReducer.name;
    columns = [
      {
        title: 'activation',
        dataIndex: i18n.t('activation'),
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            if (order === 'asc') {
              if (a[i18n.t('activation')] < b[i18n.t('activation')]) {
                return -1;
              }
              if (a[i18n.t('activation')] > b[i18n.t('activation')]) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (a[i18n.t('activation')] > b[i18n.t('activation')]) {
                return -1;
              }
              if (a[i18n.t('activation')] < b[i18n.t('activation')]) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
      {
        title: 'activation-date',
        dataIndex: i18n.t('activation-date'),
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            if (order === 'asc') {
              if (a[i18n.t('activation-date')] < b[i18n.t('activation-date')]) {
                return -1;
              }
              if (a[i18n.t('activation-date')] > b[i18n.t('activation-date')]) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (a[i18n.t('activation-date')] > b[i18n.t('activation-date')]) {
                return -1;
              }
              if (a[i18n.t('activation-date')] < b[i18n.t('activation-date')]) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
      {
        title: 'activation-power',
        dataIndex: [i18n.t('activation-power')],
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            if (order === 'asc') {
              if (
                a[i18n.t('activation-power')] < b[i18n.t('activation-power')]
              ) {
                return -1;
              }
              if (
                a[i18n.t('activation-power')] > b[i18n.t('activation-power')]
              ) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (
                a[i18n.t('activation-power')] > b[i18n.t('activation-power')]
              ) {
                return -1;
              }
              if (
                a[i18n.t('activation-power')] < b[i18n.t('activation-power')]
              ) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
      {
        title: 'activation-energy',
        dataIndex: i18n.t('activation-energy'),
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            if (order === 'asc') {
              if (
                a[i18n.t('activation-energy')] < b[i18n.t('activation-energy')]
              ) {
                return -1;
              }
              if (
                a[i18n.t('activation-energy')] > b[i18n.t('activation-energy')]
              ) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (
                a[i18n.t('activation-energy')] > b[i18n.t('activation-energy')]
              ) {
                return -1;
              }
              if (
                a[i18n.t('activation-energy')] < b[i18n.t('activation-energy')]
              ) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
      {
        title: 'activation-price2',
        dataIndex: i18n.t('activation-price'),
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            if (order === 'asc') {
              if (
                a[i18n.t('activation-price')] < b[i18n.t('activation-price')]
              ) {
                return -1;
              }
              if (
                a[i18n.t('activation-price')] > b[i18n.t('activation-price')]
              ) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (
                a[i18n.t('activation-price')] > b[i18n.t('activation-price')]
              ) {
                return -1;
              }
              if (
                a[i18n.t('activation-price')] < b[i18n.t('activation-price')]
              ) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
    ];

    objMarket = [
      {
        type: 'title',
        id: 1,
        title: 'how_to_become_a_balance_responsible_party',
      },
      {
        type: 'button',
        id: 1,
        label: 'guides_for_admission',
        url: `/${i18n.t('routes.lng')}/${i18n.t(
          'routes.documentation'
        )}?scope=${i18n.t('routes.structural_data')}`,
      },
      {
        type: 'button',
        id: 2,
        label: 'gde_website_text',
        url: '',
      },
      {
        type: 'text',
        id: 1,
        content: 'market_subject_wannabe_description',
      },
    ];

    listMarket = [
      {
        type: 'title',
        id: 2,
        title: 'structural_data',
      },
      {
        type: 'list',
        id: 1,
        text: 'participants_auction',
        url: `/${i18n.t('routes.lng')}/${i18n.t(
          'routes.participants_auction'
        )}`,
      },
      {
        type: 'list',
        id: 2,
        text: 'programming_units',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.programming_units')}`,
      },
      {
        type: 'list',
        id: 3,
        text: 'physical_units',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.physical_units')}`,
      },
      {
        type: 'list',
        id: 4,
        text: 'market_subjects',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.market_subjects')}`,
      },
    ];
  };

  let renderComponent = async () => {
    const state = store.getState();
    try {
      templateActivation(
        {
          title: i18n.t('service_activation2'),
          body: `<p>${i18n.t('service_activation_description')}</p>`,
        },
        { objMarket, listMarket },
        handlers,
        select,
        searchForm,
        paginationValues,
        pagination
      );
      // Render the template to the document
      contactData = await servicesActivation.init(
        state.exampleReducer.currentLang
      );
      await getData();

      templateActivation(
        contactData,
        { objMarket, listMarket },
        handlers,
        select,
        searchForm,
        paginationValues,
        pagination
      );

      // document
      //   .querySelector('.esios-pagination__results')
      //   .classList.remove('hidden');
      // document
      //   .querySelector('.esios-smercado__div-header')
      //   .classList.remove('hidden');
      document
        .querySelector('.esios-layout__print-logo')
        .classList.add('sujetos');
      document
        .getElementById('esios-layout-banner')
        .classList.add('hideForPrint');

      filterData();

      const dataCsv = data.map((i) => {
        return [
          i[i18n.t('activation')],
          i[i18n.t('activation-date')],
          i[i18n.t('activation-power')],
          i[i18n.t('activation-energy')],
          i[i18n.t('activation-price')],
        ];
      });
      dataCsv.unshift([
        i18n.t('activation'),
        i18n.t('activation-date'),
        i18n.t('activation-power'),
        i18n.t('activation-energy'),
        i18n.t('activation-price'),
      ]);
      const fileName =
        'export_' +
        i18n.t('routes.service_activation') +
        '_' +
        moment().format('YYYY-MM-DD_HH_mm');
      const buttonsArray = [
        {
          title: 'EXPORTAR JSON',
          action: 'exportJSON',
          data: data.map((i) => ({
            [i18n.t('activation')]: i[i18n.t('activation')],
            [i18n.t('activation-date')]: i[i18n.t('activation-date')],
            [i18n.t('activation-power')]: i[i18n.t('activation-power')],
            [i18n.t('activation-energy')]: i[i18n.t('activation-energy')],
            [i18n.t('activation-price')]: i[i18n.t('activation-price')],
          })),
          docName: fileName,
        },
        {
          title: 'EXPORTAR CSV',
          action: 'exportCSV',
          data: dataCsv,
          docName: fileName,
        },
        {
          title: 'EXPORTAR EXCEL',
          action: 'exportExcel',
          data: dataCsv,
        },
        { title: 'IMPRIMIR', action: 'print', url: '#' },
      ];

      componentButtons.render(buttonsArray, '#sujeto-mercado-buttons');
      renderSuccess = true;
    } catch (error) {
      console.error(error);
      renderSuccess = false;
    }

    document
      .querySelector('.esios-table')
      .classList.add('esios-table--sujetomercado');
    document
      .querySelector('.row.is-strech')
      .classList.add('row.is-strech--sujetomercado');

    document
      .querySelector('.esios-footer')
      .classList.add('esios-footer--sujetomercado');
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
    document
      .querySelector('.esios-btn__container')
      .classList.add('esios-btn__container--balance');
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentActivation };
