'use strict';
import 'regenerator-runtime/runtime';
import { api } from '../../utils/api-interceptor';

let servicesCalendario = (() => {
  const getEventsDefault = () => {
    return {
      monthlyAuction: [],
      monthlyAuctionPor: [],
      monthlySpec: [],
      monthlySpecPor: [],
      trimAuction: [],
      trimSpec: [],
      anualAuction: [],
      anualAuctionPor: [],
      anualSpec: [],
      anualSpecPor: [],
      notWorking: [],
      notWorkingPor: [],
    };
  };

  const getCalendars = async (year, e) => {
    let data = await api(
      `auctions?year=${year}&locale=${e}&temporal=${Math.floor(
        Math.random() * 1000
      )}`,
      { hasLang: false }
    );
    return data.auctions;
  };

  const getAuctionYears = async () => {
    let data = await api(
      `auctions?values=false&temporal=${Math.floor(Math.random() * 1000)}`,
      { hasLang: false }
    );
    return data;
  };

  return {
    getCalendars: (year, e) => getCalendars(year, e),
    getAuctionYears: () => getAuctionYears(),
    getEventsDefault: () => getEventsDefault(),
  };
})();

export { servicesCalendario };
