'use strict';

/**
 * @description Help Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { render } from 'lit-html';
import moment from 'moment';
import { store } from '../../app.init.js';
import { templateTable } from '../table/table.template.js';
import { serviceshabilitado } from './habilitado.services.js';
import { templatehabilitado } from './habilitado.template.js';
import i18n from 'i18next';
import { componentListPrintView } from '../../shared/components/list-print-view/list-print-view.component.js';
import { componentButtons } from '../../shared/components/buttons/buttons.component.js';
import { componentPaginator } from '../../shared/components/paginator/paginator.component.js';

let componenthabilitado = (function () {
  let context = {};
  let handlers = {
    onPageChange: (page) => onPageChange(page),
    onTotalChange: (event) => onTotalChange(event),

    onQueryChange: (event) => onQueryChange(event),
    onAmbitChange: (event) => onAmbitChange(event),
    onAreaChange: (event) => onAreaChange(event),

    onTypeSearchChange: (event) => onTypeSearchChange(event),
    onDateInitChange: (event) => onDateInitChange(event),
    onDateEndChange: (event) => onDateEndChange(event),
    onSearch: () => onSearch(),
    ocultar: (event) => ocultar(event),
  };
  let pageTitle = '';
  let renderSuccess = false;
  let listMarket;
  let searchForm = {
    type: 'publicacion',
    date_init: moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD'),
    date_end: moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD'),
  };
  let filterForm = {
    query: '',
    ambit: '',
    area: '',
  };
  let data = [];
  let dataPaginated = [];

  let paginationValues = [25, 50, 100];
  let pagination = {
    total: 0,
    page: 1,
    totalPages: 1,
    pagination: paginationValues[0],
  };

  const currentRoute = window.location.pathname;
  let lang = currentRoute.split('/')[1];
  // Definir sus columnas
  const columns = [
    {
      title: 'participant',
      dataIndex: 'Participante',
      defaultSortOrder: 'asc',
      sortDirections: ['asc', 'desc'],
      sorter: (datalist, order) => {
        const sorted = [...datalist].sort((a, b) => {
          if (order === 'asc') {
            if (a.Participante < b.Participante) {
              return -1;
            }
            if (a.Participante > b.Participante) {
              return 1;
            }
            return 0;
          } else if (order === 'desc') {
            if (a.Participante > b.Participante) {
              return -1;
            }
            if (a.Participante < b.Participante) {
              return 1;
            }
            return 0;
          }
        });
        data = sorted;

        filterData();
      },
    },
    {
      title: 'participants-eic',
      dataIndex: 'EIC',
      defaultSortOrder: 'asc',
      sortDirections: ['asc', 'desc'],
      sorter: (datalist, order) => {
        const sorted = [...datalist].sort((a, b) => {
          if (order === 'asc') {
            if (a.EIC < b.EIC) {
              return -1;
            }
            if (a.EIC > b.EIC) {
              return 1;
            }
            return 0;
          } else if (order === 'desc') {
            if (a.EIC > b.EIC) {
              return -1;
            }
            if (a.EIC < b.EIC) {
              return 1;
            }
            return 0;
          }
        });
        data = sorted;
        filterData();
      },
    },
  ];
  // Fin Definir columnas

  // paginacion funciones
  const onPageChange = (page) => {
    pagination.page = page;
    pagination.totalPages = Math.ceil(data.length / pagination.pagination);
    filterData();
  };

  const onTotalChange = (event) => {
    pagination.page = 1;
    pagination.pagination = event.target.value;
    pagination.totalPages = Math.ceil(data.length / pagination.pagination);
    filterData();
  };
  // fin paginacion
  // filtros funciones de filtrado abajo

  const onAmbitChange = (event) => {
    filterForm.ambit = event.target.value;
    filterData();
  };

  const onAreaChange = (event) => {
    filterForm.area = event.target.value;
    filterData();
  };

  const ocultar = (event) => {
    document.querySelector('.habilitado-buscar').value = '';
    document
      .querySelector('.esios-habilitado__boton-remove')
      .classList.add('hidden');
    filterForm.query = '';
    filterData();
  };

  const onQueryChange = (event) => {
    filterForm.query = event.target.value;
    if (event.target.value.length > 0)
      document
        .querySelector('.esios-habilitado__boton-remove')
        .classList.remove('hidden');
    else
      document
        .querySelector('.esios-habilitado__boton-remove')
        .classList.add('hidden');
    filterData();
  };

  // fin de funciones de filtrado

  const filterData = () => {
    let filtered = data.filter(
      (item) =>
        item.Participante.toLowerCase().includes(
          filterForm.query.toLowerCase()
        ) || item.EIC.toLowerCase().includes(filterForm.query.toLowerCase())
    );

    dataPaginated = filtered.slice(
      (pagination.page - 1) * pagination.pagination,
      pagination.page * pagination.pagination
    );
    const columns = [
      {
        name: i18n.t('participant'),
        field: 'Participante',
      },
      {
        name: i18n.t('participants-eic'),
        field: 'EIC',
      },
    ];
    componentListPrintView.render('print-list', dataPaginated, columns);
    pagination.total = filtered.length;
    pagination.totalPages = Math.ceil(filtered.length / pagination.pagination);
    renderAll();
    renderTable();
  };

  // filtros de busqueda por fecha, arriba

  const onTypeSearchChange = (event) => {
    searchForm.query = event.target.value;
  };

  const onDateInitChange = (event) => {
    searchForm.date_init = event.target.value;
  };

  const onDateEndChange = (event) => {
    searchForm.date_end = event.target.value;
  };

  // buscar por fecha
  const onSearch = () => {
    searchDataUnidades();
  };

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState(searchForm);
    document.title = `${i18n.t('participants_auction')} | ${i18n.t(
      'page_subtitle'
    )}`;
    context.name = state.exampleReducer.name;
    listMarket = [
      {
        type: 'title',
        id: 2,
        title: 'structural_data',
      },
      {
        type: 'list',
        id: 1,
        text: 'participants_auction',
        url: `/${i18n.t('routes.lng')}/${i18n.t(
          'routes.participants_auction'
        )}`,
      },
      {
        type: 'list',
        id: 2,
        text: 'programming_units',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.programming_units')}`,
      },
      {
        type: 'list',
        id: 3,
        text: 'physical_units',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.physical_units')}`,
      },
      {
        type: 'list',
        id: 4,
        text: 'market_subjects',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.market_subjects')}`,
      },
    ];
  };

  // get archives trae por fecha API
  const searchDataUnidades = () => {
    return serviceshabilitado.getDataTable().then((respData) => {
      data = respData;
      pagination.total = respData.length;
      pagination.totalPages = Math.ceil(
        respData.length / pagination.pagination
      );
      filterData();
      if (respData) {
        document.getElementById('spinner1').style.display = 'none';
      } else {
        return respData;
      }
    });
  };

  let renderComponent = async () => {
    let listDocumen = [
      {
        type: 'title',
        id: 1,
        title: 'how_to_become_a_balance_responsible_party',
      },
      {
        type: 'button',
        id: 1,
        label: 'guides_for_admission',
        url: `/${i18n.t('routes.lng')}/${i18n.t(
          'routes.documentation'
        )}?scope=${i18n.t('routes.structural_data')}`,
      },
      {
        type: 'button',
        id: 2,
        label: 'gde_website_text',
        url: 'https://www.ree.es/es/clientes',
      },
      {
        type: 'text',
        id: 1,
        content: 'market_subject_wannabe_description',
      },
    ];

    const state = store.getState();

    try {
      // Render the template to the document

      // Define a template
      templatehabilitado(
        context,
        handlers,
        listDocumen,
        listMarket,
        paginationValues,
        pagination,
        lang
      );
      document
        .querySelector('.esios-layout__main')
        .classList.add('esios-layout__main--habilitados');
      document
        .querySelector('.esios-layout__print-logo')
        .classList.add('habilitados');
      document
        .getElementById('esios-layout-banner')
        .classList.add('hideForPrint');
      let mostrardatos = '';

      await searchDataUnidades();
      document.querySelector('.habilitado-buscar').classList.remove('hidden');
      document
        .querySelector('.esios-pagination__results')
        .classList.remove('hidden');

      filterData();
      const dataCsv = data.map((i) => {
        return [i['Participante'], i['EIC']];
      });
      dataCsv.unshift([i18n.t('participant'), i18n.t('participants-eic')]);
      const fileName =
        'export_' +
        i18n.t('routes.participants_auction') +
        '_' +
        moment().format('YYYY-MM-DD_HH_mm');
      const buttonsArray = [
        {
          title: 'EXPORTAR JSON',
          action: 'exportJSON',
          data: data,
          docName: fileName,
        },
        {
          title: 'EXPORTAR CSV',
          action: 'exportCSV',
          data: dataCsv,
          docName: fileName,
        },
        {
          title: 'EXPORTAR EXCEL',
          action: 'exportExcel',
          data: dataCsv,
        },
        {
          title: 'IMPRIMIR',
          action: 'print',
          url: '#',
        },
      ];
      componentButtons.render(
        buttonsArray,
        '#participantes-habilitados-buttons'
      );
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
    document
      .querySelector('.row.is-strech')
      .classList.add('row.is-strech--habilitados');
    document
      .querySelector('.esios-layout__subcontent__wrapper')
      .classList.add('esios-layout__subcontent__wrapper--habilitados');
    document
      .querySelector('.esios-footer')
      .classList.add('esios-footer--habilitados');
  };

  let renderTable = () => {
    const tableElement = document.getElementById('habilitados-table');
    render(templateTable(columns, dataPaginated, data), tableElement);
    componentPaginator.render(
      pagination,
      onPageChange,
      'pagination-habilitados'
    );
  };

  let renderAll = () => {
    let listDocumen = [
      {
        type: 'title',
        id: 1,
        title: 'how_to_become_a_balance_responsible_party',
      },
      {
        type: 'button',
        id: 1,
        label: 'guides_for_admission',
        url: `/${i18n.t('routes.lng')}/${i18n.t(
          'routes.documentation'
        )}?scope=${i18n.t('routes.structural_data')}`,
      },
      {
        type: 'button',
        id: 2,
        label: 'gde_website_text',
        url: 'https://www.ree.es/es/clientes',
      },
      {
        type: 'text',
        id: 1,
        content: 'market_subject_wannabe_description',
      },
    ];

    templatehabilitado(
      context,
      handlers,
      listDocumen,
      listMarket,
      paginationValues,
      pagination,
      lang
    );
    document
      .getElementById('esios-layout-banner')
      .classList.add('hideForPrint');
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componenthabilitado };
