export const DEMAND_COLORS = {
  4: '#34B473',
  5: '#F18F78',
  6: '#444444',
  7: '#557A02',
  8: '#962A11',
  9: '#6D199E',
  10: '#AE7DC9',
  11: '#A2546D',
  12: '#3333', // Check color
  13: '#FFA64D',
  14: '#DDDDDD',
  15: '#006699',
  16: '#85B200',
  17: '#FFCF09',
  18: '#00A2D8',
  19: '#00A2D8',
  21: '#00A2D8',
  836: '#006699',
  837: '#85B200',
  838: '#FFCF09',
  839: '#006699',
  840: '#00A2D8',
  841: '#D90000',
  842: '#717171',
  844: '#333333',
  845: '#FD8FFF',
  846: '#85B200',
};
