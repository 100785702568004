'use strict';
import moment from 'moment';
import { saveAs } from 'file-saver';
import _ from 'underscore';
import { api } from './../../../utils/api-interceptor';

let servicesButtons = (() => {
  const fetchApi = async (url) => {
    try {
      if (url.includes('https://api.esios.ree.es/es/')) {
        let newUrl = url.replace('https://api.esios.ree.es/es/', '');
        const data = await api(newUrl, { hasLang: true });
        return data;
      }

      if (url.includes('https://api.esios.ree.es/')) {
        let newUrl = url.replace('https://api.esios.ree.es/', '');
        const data = await api(newUrl, { hasLang: false });
        return data;
      } else {
        const data = await api(url, { hasLang: false });
        return data;
      }
    } catch (error) {}
  };

  function base64(s) {
    return window.btoa(window.unescape(encodeURIComponent(s)));
  }

  function downloadFile(str, fileName, ext) {
    var link = document.createElement('a');
    var strMime = 'application/octet-stream';
    var datetime = moment().format('YYYY-MM-DD_HH:mm');
    var sUrl = document.location.href;

    fileName =
      fileName || sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
    fileName = 'export_' + fileName + '_' + datetime + '.' + (ext || 'txt');

    if (!/(iPhone|iPod|iPad).*AppleWebKit.*Safari/i.test(navigator.userAgent)) {
      var blob = new Blob([str], { type: strMime });
      saveAs(blob, fileName);
    } else {
      link.setAttribute('download', fileName);
      link.href = 'data:' + strMime + ';charset=utf-8;base64,' + base64(str);
      document.body.appendChild(link);
      link.click(); //Dispatching click event.
      document.body.removeChild(link);
    }
  }

  function jsonToCSV(dataJSON, columnsNames) {
    let array = typeof dataJSON !== 'object' ? JSON.parse(dataJSON) : dataJSON;
    let str = '';
    let line = '';

    if (columnsNames) {
      str += columnsNames.join(';') + '\r\n';
    } else {
      str += _.keys(array[0]).join(';') + '\r\n';
    }
    _.each(array, function (row) {
      line = '';
      line += _.values(row).join(';');
      str += line + '\r\n';
    });
    return str;
  }

  function jsonToTable(parsedJson, columnsNames, decimal) {
    let numDecimal = decimal || 3;
    let template = (data) => {
      let dataToTable = '';
      if (data.headers) {
        dataToTable = '<tr>';
        dataToTable += data.headers
          .map((header) => '<td>' + header + '</td>')
          .join('');
        dataToTable += '</tr>';
      }
      dataToTable += data.rows.map((row) => {
        let data = row.columns
          .map(
            (column) =>
              '<tr>' +
              Object.values(column)
                .map((col) => '<td>' + col + '</td>')
                .join('') +
              '</tr>'
          )
          .join('');

        return data;
      });

      return dataToTable;
    };

    let data = {
      headers: columnsNames || _.keys(parsedJson[0]),
      rows: _.map(parsedJson, function (p) {
        if (p.value && _.isNumber(Number(p.value))) {
          p.value = toNumber(p.value, numDecimal);
        }
        return {
          columns: _.values(p),
        };
      }),
    };
    return template(data);
  }

  function toNumber(value, decimals) {
    var result = _.isNumber(value) ? value : Number(value);
    if (!_.isNumber(result) || _.isNaN(result)) {
      return value;
    }
    if (typeof decimals === 'undefined' || decimals === null) {
      decimals = 1;
    }
    if (I18n.locale === 'es') {
      return _.str.numberFormat(result, decimals, ',', '.');
    }
    return _.str.numberFormat(result, decimals, '.', ',');
  }

  function formatData(data) {
    if (typeof data === 'object' && !Array.isArray(data)) {
      data = Object.values(data);
    }
    return data[0];
  }

  const deleteInput = () => {
    document.getElementById('web-calculadora1').value = '0';
    document.getElementById('web-calculadora2').value = '0';
    document.getElementById('web-calculadora3').value = '0';
    document.getElementById('P1').textContent = '0';
    document.getElementById('P1b').textContent = '0';
    document.getElementById('P2').textContent = '0';
    document.getElementById('P2b').textContent = '0';
    document.getElementById('P3').textContent = '0';
    document.getElementById('P3b').textContent = '0';
    document.getElementById('D1').textContent = '0';
    document.getElementById('D2').textContent = '0';
    document.getElementById('D3').textContent = '0';
    document.getElementById('F1').textContent = '0';
    document.getElementById('F2').textContent = '0';
    document.getElementById('F3').textContent = '0';
    document.getElementById('B1').textContent = '0';
    document.getElementById('B2').textContent = '0';
    document.getElementById('B3').textContent = '0';
    document.getElementById('TOTAL').textContent = '0';
  };

  const linkClick = (link) => {
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const showDownloadOptions = () => {
    document.querySelector('.options_select').classList.toggle('active');
    if (
      document.querySelector('.options_select').classList.contains('active')
    ) {
      window.addEventListener('click', (e) => {
        if (!e.target.classList.contains('esios-btn')) {
          document.querySelector('.options_select').classList.remove('active');
        }
      });
    }
  };

  const downloadJSON = async (data, docName) => {
    const jsonContent =
      'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(data));
    const link = document.createElement('a');
    link.setAttribute('href', jsonContent);
    link.setAttribute('download', docName + '.json');
    linkClick(link);
  };

  const downloadCSV = async (data, docName) => {
    const csvContent =
      'data:text/csv;charset=utf-8,' + data.map((i) => i.join(';')).join('\n');
    const link = document.createElement('a');
    link.setAttribute('href', encodeURI(csvContent));
    link.setAttribute('download', docName);
    linkClick(link);
  };

  const Handlebars = require('handlebars');

  /**
   * json to HTML Table
   * @param  {Object} parsedJson
   */
  function jsonToTable(parsedJson, columnsNames, decimal) {
    var numDecimal = decimal || 3;
    const headers = parsedJson[0];
    const valuesJson = parsedJson.map((i) => i);
    valuesJson.shift();
    var template = Handlebars.compile(
      '<table><tr>{{#each headers}}<td>{{this}}</td>{{/each}}</tr>{{#each rows}}<tr>{{#each columns}}<td>{{this}}</td>{{/each}}</tr>{{/each}}</table>'
    );
    var data = {
      headers: columnsNames || headers,
      rows: _.map(valuesJson, function (p) {
        if (p.value && _.isNumber(Number(p.value))) {
          p.value = toNumber(p.value, numDecimal);
        }
        return {
          columns: _.values(p),
        };
      }),
    };
    return template(data);
  }

  function downloadFile(str, fileName, ext) {
    var link = document.createElement('a');
    var strMime = 'application/octet-stream';
    var datetime = moment().format('YYYY-MM-DD_HH:mm');
    var sUrl = document.location.href;

    fileName =
      fileName || sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
    fileName = 'export_' + fileName + '_' + datetime + '.' + (ext || 'txt');

    if (!/(iPhone|iPod|iPad).*AppleWebKit.*Safari/i.test(navigator.userAgent)) {
      var blob = new Blob([str], { type: strMime });
      saveAs(blob, fileName);
    } else {
      link.setAttribute('download', fileName);
      link.href = 'data:' + strMime + ';charset=utf-8;base64,' + base64(str);
      document.body.appendChild(link);
      link.click(); //Dispatching click event.
      document.body.removeChild(link);
    }
  }

  /**
   * Download Excel for buttons
   * @param  {Object} dataJSON
   * @param  {Array}
   * @param  {String}
   */
  function downloadExcel(dataJSON, columnsNames, documentName, decimal) {
    var template = Handlebars.compile(
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{{worksheet}}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{{{table}}}</table></body></html>'
    );
    var ctx = {
      worksheet: documentName || 'excel',
      table: jsonToTable(dataJSON, null, decimal),
    };
    var str = template(ctx);
    downloadFile(str, documentName, 'xls');
  }

  return {
    fetchApi: (url) => fetchApi(url),
    downloadJSON: (url, columnsNames) => downloadJSON(url, columnsNames),
    downloadCSV: (url, columnsNames, documentName) =>
      downloadCSV(url, columnsNames, documentName),
    downloadExcel: (dataJSON, columnsNames, documentName, decimal) =>
      downloadExcel(dataJSON, columnsNames, documentName, decimal),
    deleteInput: () => deleteInput(),
    showDownloadOptions: () => showDownloadOptions(),
  };
})();

export { servicesButtons };
