'use strict';
import i18next from 'i18next';
import moment from 'moment-timezone';
import { api } from '../../utils/api-interceptor';
import { Utils } from '../../utils/utils';

let servicesgenerConsumo = (() => {
  let init = () => llamarServicio();

  const llamarServicio = async () => {
    try {
      const dataL = await api('widgets/1');
      const dataC = await api('widgets/2');
      const dataR = await api('widgets/3');
      const dataD = await api('widgets/4');
      const dataMore = await api('widgets/5');

      const dataSMP = await api('taxonomy_terms?vocabulary=20', {
        hasLang: true,
      });
      const dataSC = await api('taxonomy_terms?vocabulary=4', {
        hasLang: true,
      });
      const dataSA = await api('taxonomy_terms?vocabulary=5', {
        hasLang: true,
      });
      const dataSP = await api('taxonomy_terms?vocabulary=14', {
        hasLang: true,
      });

      const info = {
        left: dataL.widget,
        leftI: dataL.widget.indicators,

        center: dataC.widget,
        centerI: dataC.widget.indicators,

        right: dataR.widget,
        rightI: dataR.widget.indicators,

        down: dataD.widget,
        downI: dataD.widget.indicators,

        more: dataMore.widget.indicators,

        selectAmbito: dataSMP.terms,
        selectCategoria: dataSC.terms,
        selectAccion: dataSA.terms,
        selectProceso: dataSP.terms,
      };
      return info;
    } catch (error) {}
  };

  const getNumActivations = async (date) => {
    return api(
      `widgets/37?datetime=${date}&locale=${i18next.t('routes.lng')}`
    ).then((data) => {
      return api(data.widget.indicators[0].url).then(
        (arrActivations) => arrActivations.indicator.values.length
      );
    });
  };

  const getDataFromWidget = async (params) => {
    const lang = i18next.t('routes.lng');
    const { id, hours, minutes, date, UTC1 = false } = params;
    let newDate;

    newDate = moment(date, 'DD-MM-YYYY')
      .tz('Europe/Madrid')
      .hours(hours ? hours : '00')
      .minutes(minutes ? minutes : '00')
      .seconds('00')
      .format();

    let hoursOfDay = Utils.getHoursOfDay(date, 'DD-MM-YYYY');
    if (hoursOfDay == 25) {
      if (hours == '02') {
        if (UTC1) {
          newDate = newDate.replace('+02:00', '+01:00');
        }
      }
    }

    return api(`widgets/${id}?datetime=${newDate}&locale=${lang}`).then(
      (data) => {
        const indicators = data.widget.indicators;
        const slug = data.widget.id;
        const description = data.widget.description;
        const name = data.widget.name;
        const magnituds = [];
        const alias = [];
        const indicatorName = [];
        let urlAnalysis = Utils.getUrlAnalysis(data.widget.main_indicators);

        return Promise.all(
          indicators.map((el, index) => {
            if (index < 30) {
              magnituds.push(el.display_options);
              alias.push(el.alias);
              indicatorName.push(el.name);
              return el.url
                ? api(`${el.url}&locale=${lang}`)
                : { ...el, url: 'empty' };
            } else {
              return '';
            }
          })
        ).then((data) => {
          const indicators = data.map((ele, index) => {
            if (ele.indicator) {
              const values = ele.indicator.values.map((el) => {
                return {
                  x: new Date(el.datetime),
                  y: el.value,
                };
              });

              return {
                values,
                print_values: ele.indicator.values,
                alias: alias[index],
                display_options: magnituds[index],
                time: ele.indicator.tiempo[0]?.id,
                composited: ele.indicator?.composited,
                magnitud: ele.indicator?.magnitud,
                disaggregated: ele.indicator?.disaggregated,
                name: indicatorName[index],
                short_name: ele.indicator.short_name,
                step: ele.indicator.step_type,
                id: ele.indicator.id,
                geo_id: ele.indicator.geos[0]?.geo_id,
              };
            } else {
              return {
                values: [],
                print_values: null,
                alias: alias[index],
                display_options: magnituds[index],
                time: null,
                composited: null,
                magnitud: null,
                disaggregated: null,
                name: indicatorName[index],
                short_name: null,
                step: null,
                id: ele.id,
                geo_id: null,
              };
            }
          });

          return {
            description,
            name,
            indicators,
            slug,
            urlAnalysis,
          };
        });
      }
    );
  };

  const getArchives = async (params) => {
    const {
      type,
      date_init,
      date_end,
      ambit,
      category,
      action,
      process,
      query,
    } = params;

    const urlApi = `indicators?${
      ambit !== '' ? `taxonomy_ids[]=${ambit}&` : ''
    }${category !== '' ? `taxonomy_ids[]=${category}&` : ''}${
      action !== '' ? `taxonomy_ids[]=${action}&` : ''
    }${process !== '' ? `taxonomy_ids[]=${process}&` : ''}locale=${i18next.t(
      'routes.lng'
    )}${query ? `&text=${query}` : ''}`;

    let loading;
    let newIndicators;
    try {
      loading = true;
      const data = await api(urlApi);
      if (!ambit && !query && !category && !process && !action) {
        return {
          newIndicators: [],
          isLoading: loading,
        };
      }
      newIndicators = data.indicators
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
        .map((indicator) => {
          return {
            name: indicator.name,
            id: indicator.id,
          };
        });
      loading = false;
      return {
        isLoading: loading,
        newIndicators,
      };
    } catch (error) {}
  };

  return {
    init: init,
    getDataFromWidget: (params) => getDataFromWidget(params),
    getArchives: (params) => getArchives(params),
    getNumActivations,
  };
})();

export { servicesgenerConsumo };
