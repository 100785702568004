'use strict';
import { api } from '../../utils/api-interceptor';
import i18next from 'i18next';

let servicesUnidadesProgramacion = (() => {
  let init = (e) => llamarServicioUnidadesProgramacion(e);

  const llamarServicioUnidadesProgramacion = async (e) => {
    let data = await api(
      'static_pages?slug=unidades-programacion&temporal=' +
        Math.floor(Math.random() * 1000)
    );
    return data;
  };

  // Obteniendo datos para la tabla Unidades de Programacion

  const getArchivesUnidades = async (e) => {
    let id;
    let data;
    if (i18next.t('routes.lng') === 'en') {
      id = 111;
    } else {
      id = 82;
    }
    let response = await api(
      `archives/${id}/download_json?locale=${i18next.t('routes.lng')}`
    );
    if (i18next.t('routes.lng') === 'en') {
      data = response.ProgrammingUnits;
    } else {
      data = response.UnidadesProgramacion;
    }
    return data;
  };

  return {
    init: init,
    getArchivesUnidades: (params) => getArchivesUnidades(params),
  };
})();

export { servicesUnidadesProgramacion };
