"use strict";
import { html } from "lit-html";
import { store } from "../../../app.init";
import { setSelectedOption } from '../../../state/actions'
import { setSelectState } from '../../../state/actions'
import { setToggleSelect } from '../../../state/actions'
import i18n from "i18next";

let templateSelect = (id, label, options) => {

  const state = store.getState();
  let selections = state.exampleReducer.option;
  let toggle = state.exampleReducer.selectState;
  let show = state.exampleReducer.show;
  let optionSelected;
  let selected;
  let objSelect = {};
  let screenWidth =  screen.width;

// label --> PROGGRAMA label --> program
  return html `
    <div class="esios-select">
      <span for="${i18n.t(label)}" class="esios-select__label">${i18n.t(label).toUpperCase()}</span>
      <button id=${id} class="esios-select__selected" @click=${(e) => {
          store.dispatch( setToggleSelect( !show ) );
          selected = parseInt(e.target.id);
          objSelect = { show: !show , selected };
          store.dispatch( setSelectState( objSelect ) );
        } 
      }>
        <span class="esios-select__text" id=${id}>
          ${ selections.length == 0? i18n.t(options[0]).toUpperCase() : (id == selections[0].id? i18n.t(selections[0].optionSelected) : i18n.t(options[0]).toUpperCase())}
        </span>
        <span class="esios-select__arrow">
          <i class="fas fa-sort-down" id=${id}></i>
        </span>
      </button>
      <ul id="${id}" name="${i18n.t(label)}" class=${ ( toggle.length == 0? 'esios-select__selectableHidden' : (id == toggle[0].selected && toggle[0].show? 'esios-select__selectable' : 'esios-select__selectableHidden') )}>
        ${options.map( element => html`
          <li value="${i18n.t(element)}" class="esios-select__value" @click=${(e)=>{
            let selected = e.target;
            optionSelected = selected.innerText;
            store.dispatch( setSelectedOption( {optionSelected, id} ) )
          }}>${i18n.t(element).toUpperCase()}</li>
        `)}
      </ul>
    </div>
  `;
};

export {
  templateSelect
};