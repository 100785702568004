'use strict';
import i18n from 'i18next';
import { api } from '../../utils/api-interceptor';
import { Utils } from '../../utils/utils';

let servicesnoPeninsulares = (() => {
  let init = (e) => llamarServicio(e);

  let archivesData = (params) => getArchives(params);

  let dataNumber = (e, id, date) => getDataNumber(e, id, date);

  let modalData = (e, id, date) => getModaldata(e, id, date);

  let moreData = (e, id, date) => getMoreData(e, id, date);

  const llamarServicio = async (e) => {
    const urlApi =
      `static_pages?slug=${i18n.t('routes.snp')}&temporal=` +
      Math.floor(Math.random() * 1000);

    try {
      const data = await api(urlApi, { hasLang: true });
      return data.content;
    } catch (error) {
      null;
    }
  };

  const getTitles = async (e, id, date) => {
    let urlApi = `widgets/${id}`;
    if (date) {
      let dateFormart = date.replace(/ /g, '');
      const timezone = new Date().getTimezoneOffset() / 60;
      urlApi = `widgets/${id}?datetime=${dateFormart}T00%3A00%3A00${
        timezone <= 0 ? '+' + -1 * timezone : -1 * timezone
      }%3A00&locale=${e}`;
    }

    try {
      const data = await api(urlApi);
      return data.widget.indicators;
    } catch (error) {
      null;
    }
  };

  const getDataNumber = async (e, id, date) => {
    const urlApi = date
      ? `widgets/${id}?datetime=${date}&locale=${e}`
      : `widgets/${id}`;

    try {
      return api(urlApi).then((data) => {
        const indicators = data.widget.indicators;
        const description = data.widget.description;
        const name = data.widget.name;
        let urlAnalysis;
        const indicatorName = [];
        urlAnalysis = Utils.getUrlAnalysis(data.widget.main_indicators);

        return Promise.all(
          indicators.map((el) => {
            indicatorName.push(el.name);
            return api(`${el.url}`);
          })
        ).then((data) => {
          const newArray = data.map((ele, index) => {
            return {
              value: ele.indicator.values[0]?.value,
              step: ele.indicator?.step_type,
              id: ele.indicator?.id,
              print_values: ele.indicator.values,
              short_name: ele.indicator?.short_name,
              name: ele.indicator?.name,
              nameT: indicatorName[index],
              geo_id: ele.indicator.geos[0]?.geo_id,
              geo_name: ele.indicator.geos[0]?.geo_name,
              datetime: ele.indicator.values[0]?.datetime,
              urlAnalysis,
              description,
              name,
            };
          });
          return newArray;
        });
      });
    } catch (error) {}
  };

  const getArchives = async (params) => {
    const { ambit, category, action, process, query } = params;

    const urlApi = `indicators?${
      ambit !== '' ? `taxonomy_ids[]=${ambit}&` : ''
    }${category !== '' ? `taxonomy_ids[]=${category}&` : ''}${
      action !== '' ? `taxonomy_ids[]=${action}&` : ''
    }${process !== '' ? `taxonomy_ids[]=${process}&` : ''}locale=${i18n.t(
      'routes.lng'
    )}${query ? `&text=${query}` : ''}`;

    let loading;
    let newIndicators;

    try {
      loading = true;
      const data = await api(urlApi);
      if (!ambit && !query && !category && !process && !action) {
        return {
          newIndicators: [],
          isLoading: loading,
        };
      }
      newIndicators = data.indicators
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
        .map((indicator) => {
          return {
            name: indicator.name,
            id: indicator.id,
          };
        });
      loading = false;
      return {
        isLoading: loading,
        newIndicators,
      };
    } catch (error) {
      null;
    }
  };

  const getModaldata = async (e, id, date) => {
    const urlApi = `widgets/${id}?datetime=${date}T00%3A00%3A00%2B02%3A00&locale=${e}`;

    try {
      const fetchResponse = await api(urlApi);
      return fetchResponse.widget.description;
    } catch (error) {
      null;
    }
  };

  const getMoreData = async (e, id) => {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const usedDate = firstDay.toLocaleDateString();
    let formatDate = usedDate.split('/');
    const [d, m, y] = formatDate;

    const urlApi = `widgets/${id}?datetime=${y}-${m}-0${d}T00%3A00%3A00%2B02%3A00&locale=${e}`;

    try {
      const data = await api(urlApi);
      return data.widget.indicators;
    } catch (error) {
      null;
    }
  };

  return {
    init,
    archivesData,
    dataNumber,
    modalData,
    moreData,
    getTitles,
  };
})();

export { servicesnoPeninsulares };
