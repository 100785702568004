import moment from 'moment';
import { actions } from './actions';

const initialState = {
  analysis: {},
  interExchanges: {},
  buscador: '',
  responsiveMenu: true,
  show: false,
  toolGraph: false,
  name: '',
  age: '',
  pathname: '/es',
  option: [],
  selectState: [],
  shareState: [],
  currentLang: 'es',
  rowUsers: 10,
  routeParams: {},
  datetime: moment().tz('Europe/Madrid').format(),
  pages: {
    home: {
      indicatorsTime:
        moment().tz('Europe/Madrid').format('HH:mm').split(':')[0] + ':00',
    },
    myp: {
      unitaryHour:
        moment().tz('Europe/Madrid').format('HH:mm').split(':')[0] + ':00',
      volumeHour:
        moment().tz('Europe/Madrid').format('HH:mm').split(':')[0] + ':00',
      tableHour:
        moment().tz('Europe/Madrid').format('HH:mm').split(':')[0] + ':00',
    },
  },
};

export const exampleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_BUSCADOR.type:
      return {
        ...state,
        buscador: action.payload,
      };

    case actions.SET_NAME.type:
      return {
        ...state,
        name: action.payload,
      };

    case actions.SET_SHOW_RESPONSIVE_MENU.type:
      return {
        ...state,
        responsiveMenu: action.payload,
      };

    case actions.SET_ROUTE_ID.type:
      return {
        ...state,
        routeParams: action.payload,
      };

    case actions.SET_SHOW_SELECT.type:
      return {
        ...state,
        selectState: [action.payload],
      };

    case actions.SET_SELECT_TOGGLE.type:
      return {
        ...state,
        show: action.payload,
      };

    case actions.SET_SHOW_SHARE.type:
      return {
        ...state,
        shareState: [action.payload],
      };

    case actions.SET_TOOL_GRAPH.type:
      return {
        ...state,
        toolGraph: action.payload,
      };

    case actions.SET_PATHNAME.type:
      return {
        ...state,
        pathname: action.payload,
      };

    case actions.SET_ROWS_TO_SHOW_USERS.type:
      return {
        ...state,
        rowUsers: action.payload,
      };

    case actions.SET_CURRENT_LANG.type:
      return {
        ...state,
        currentLang: action.payload,
      };
    case actions.SET_SELECT.type:
      return {
        ...state,
        option: [action.payload],
      };
    case actions.SET_HOME_OTHER_INDICATORS_TIME.type:
      let newDate = action.payload;

      return {
        ...state,
        pages: {
          ...state.pages,
          home: {
            indicatorsTime: newDate,
          },
        },
      };
    case actions.SET_MYP_VOLUME_HOUR.type:
      let newHour = action.payload;

      return {
        ...state,
        pages: {
          ...state.pages,
          myp: {
            volumeHour: newHour,
          },
        },
      };
    case actions.SET_MYP_UNITARY_COST_HOUR.type:
      let newUnitaryHour = action.payload;

      return {
        ...state,
        pages: {
          ...state.pages,
          myp: {
            unitaryHour: newUnitaryHour,
          },
        },
      };
    case actions.SET_MYP_TABLE_HOUR.type:
      let newTableHour = action.payload;
      return {
        ...state,
        pages: {
          ...state.pages,
          myp: {
            tableHour: newTableHour,
          },
        },
      };
    case actions.SET_ANALYSIS.type:
      return {
        ...state,
        analysis: { ...state.analysis, ...action.payload },
      };
    case actions.SET_INT_EXCHANGES.type:
      return {
        ...state,
        interExchanges: { ...state.interExchanges, ...action.payload },
      };
    default:
      return state;
  }
};
