'use strict';
import i18n from 'i18next';
import { api } from '../../utils/api-interceptor';

let servicesAbout = (() => {
  const llamarServicioAbout = async (e) => {
    const urlApi =
      `static_pages?slug=${i18n.t('routes.about')}&temporal=` +
      Math.floor(Math.random() * 1000);

    try {
      const data = await api(urlApi, { hasLang: true });
      return data.content;
    } catch (error) {}
  };

  const getModalBody = async (e, id, selection, date) => {
    const urlApi = `glossaries/sujeto-del-mercado-sm`;
    try {
      const data = await api(urlApi, { hasLang: true });
      return data.content.body;
    } catch (error) {}
  };

  const getModalTitle = async (e, id, selection, date) => {
    const urlApi = `glossaries/sujeto-del-mercado-sm`;
    try {
      const data = await api(urlApi, { hasLang: true });
      return data.content.title;
    } catch (error) {}
  };

  return {
    llamarServicioAbout: (e) => llamarServicioAbout(e),
    getModalBody,
    getModalTitle,
  };
})();

export { servicesAbout };
