export const pageHelp = {
  labelAyuda: 'AYUDA',
  labelparrafo1:
    'A continuación se presenta un video explicativo de la sección ',
  labelAnalisis: 'Análisis',
  labelparrafo11:
    'de la Web Pública de e·sios, una página que además de las ya conocidas funciones de visualización, descarga e impresión de publicaciones, permite la consulta de información histórica, comparativas y estudios entre los distintos indicadores de la Operación del Sistema.',
  labelparrafo2:
    'Accesible mediante el icono "ANALIZAR INDICADOR" (icono) de la web, sus distintas funcionalidades son detalladas en este video de manera que los usuarios del sitio web puedan obtener su máximo provecho.',
  labelparrafo3:
    'Para más información, por favor consulte con nosotros a través de ',
  labelparrafo3Link: 'soportesios@ree.es.',
};

export const propuestas = {
  labelPropuesta: 'PROPUESTAS DE CAMBIOS NORMATIVOS',
  labelparrafo1: 'Tablón de publicación de propuestas de cambios normativos',
  labelSubtitle: 'En Curso',
  labelSubtitle2: 'Histórico',
};
export const codigoConexion = {
  labelCodigoConexionTitle:
    'INFORMACIÓN SOBRE IMPLEMENTACIÓN DE CÓDIGOS DE RED DE CONEXIÓN',
};
export const general = {
  labelEsta: 'Esta en :',
};
export const descargas = {
  labeltabla: 'downloads',
  labelparrafo1: 'Tablón de publicación de propuestas de cambios normativos',
  labelSubtitle: 'En Curso',
  labelSubtitle2: 'Histórico',
};

export const informacion = {
  labelInfo: 'WEB E·SIOS INFORMA',
  fecha1: '05 / 07/ 2021',
  titulo1:
    'PROPUESTA DE PROCEDIMIENTOS DE OPERACIÓN PARA SU ADAPTACIÓN AL PROCESO DE PROGRAMACIÓN CUARTO HORARIO',
  text1:
    'Una vez finalizado el proceso de consulta pública del OS que ha tenido lugar del 5 de abril al 7 de mayo de 2021, se encuentra disponible en este',
  text1Enlace: 'enlace',
  text11:
    'la propuesta del Operador del Sistema de modificación de los procedimientos de operación PO. 1.5, PO 3.1, PO 3.2, PO 3.3, PO 7.2, PO 7.3, PO. 9.1 y PO 14.4, para su adaptación al proceso de programación cuarto horario.',
  text2:
    'La propuesta se acompaña de una memoria explicativa en la que se resumen los principales cambios incorporados, así como la respuesta a los comentarios recibidos.',
  text3:
    'Pueden consultar también toda la información relativa a esta consulta desde el',
  text3Enlace: 'Portal de Servicios a Clientes del Operador del Sistema',
  text33: 'en su módulo Cambios normativos',

  fecha2: '22 / 06 / 2021',
  titulo2:
    'NOVEDADES E·SIOS+ (SISTEMA DE OPERACIÓN PARA LOS TERRITORIOS NO PENINSULARES) Y PRUEBAS CON AGENTES',
  text21:
    'La fecha de Puesta en Servicio del e·sios+ (nuevo Sistema de Operación para los Territorios No Peninsulares) que se había previsto la primera semana de octubre de 2021, se retrasa a la primera semana de diciembre de 2021.',
  text22:
    'Por corrección funcional, se publica la versión 2.1 del documento que recoge los mensajes de “Intercambio de información entre Agentes y e·sios+”. Este documento se puede descargar de la Web Pública de e·sios en la siguiente ruta:',
  text22Enlace: 'https://api.esios.ree.es/documents/602/download?locale=es',
  text23:
    'Se ha previsto establecer un periodo de Pruebas con Agentes que se iniciará el próximo 05/07/2021 y durará hasta la semana del 27/09/2021.',
  text24:
    'Los detalles de las pruebas y las condiciones necesarias para poder participar en ellas se especifican en estos',
  text24Enlace: 'documentos.',

  fecha5: '28 / 05 / 2021',
  titulo5:
    'NUEVA INFORMACIÓN PUBLICADA EN LA WEB PÚBLICA: HAN SIDO INCLUIDOS INDICADORES DE RESERVA ADICIONAL Y PRECIOS MEDIOS PONDERADOS DE ASIGNACIÓN DE ENERGÍAS DE BALANCE',
  text5:
    'Les informamos de que con el inicio de la publicación del nuevo mensaje de información relativo a las necesidades de reserva de potencia adicional a subir identificadas en tiempo real, el pasado 5 de mayo de 2021,  se incorpora el siguiente listado de nuevos indicadores correspondiente a la programación de reserva adicional a subir. Adicionalmente se ha incluido también la publicación de nuevos indicadores de precios medios ponderados de asignación en los servicios de balance',
  item1: 'NECESIDADES DE RESERVA ADICIONAL IDENTIFICADAS EN TIEMPO REAL (1880)',
  item2: 'RESERVA ADICIONAL PROGRAMADA EN TIEMPO REAL (1881)',
  item3:
    'PRECIO MEDIO PONDERADO PROGRAMACIÓN DE RESERVA ADICIONAL TIEMPO REAL (10392)',
  item4:
    'PRECIO MEDIO PONDERADO DE LAS NECESIDADES CUBIERTAS DE ENERGÍAS DE BALANCE RR A SUBIR (10384)',
  item5:
    'PRECIO MEDIO PONDERADO DE LAS NECESIDADES CUBIERTAS DE ENERGÍAS DE BALANCE RR A BAJAR (10385)',
  item6: 'PRECIO MEDIO PONDERADO DE REGULACIÓN TERCIARIA A SUBIR (10386)',
  item7: 'PRECIO MEDIO PONDERADO DE REGULACIÓN TERCIARIA A BAJAR (10387)',
  item8: 'PRECIO MEDIO PONDERADO BANDA DE REGULACIÓN SECUNDARIA (10388)',
  item9: 'PRECIO MEDIO PONDERADO DE REGULACIÓN SECUNDARIA A SUBIR (10389)',
  item10: 'PRECIO MEDIO PONDERADO DE REGULACIÓN SECUNDARIA A BAJAR (10390)',
};

export const previsionesDGRenovable = {
  labelTitulo:
    'PREVISIONES DE DEMANDA Y GENERACIÓN RENOVABLE (CIRCULAR CNMC 4/2019)',
  labelparrafoT1:
    'La Circular 4/2019 de la CNMC por la que se establece la metodología de retribución del Operador del Sistema, dispone la existencia de incentivos a la calidad de las previsiones de demanda y de generación renovable – eólica más fotovoltaica – para la península realizadas por el Operador del Sistema. ',
  labelSubtitulo: 'PREVISIÓN DE DEMANDA PENINSULAR',
  labelSTparrafo: 'En relación con las',
  labelSTLink: 'previsiones de demanda peninsular, ',
  labelSTparrafo1:
    'la circular establece la evaluación de su calidad en tres horizontes: anual, diario e intradiario.',

  labelsubSubtitulo1: 'HORIZONTE ANUAL',
  labelsubSubtparrafo1:
    'La calidad de la previsión de la demanda anual se evalúa en función del error de la estimación de demanda anual realizada en diciembre del año anterior y publicada en el informe de cobertura de dicho mes. El siguiente indicador corresponde a la predicción de la demanda anual realizada por el Operador del Sistema en diciembre del año anterior:',
  labelsubSubtLink1: 'Previsión demanda anual',

  labelsubSubtitulo11: 'HORIZONTE DIARIO',
  labelsubSubtparrafo11:
    'La calidad de la previsión diaria de la demanda se evalúa en función de la predicción diaria de demanda para el día siguiente, con desglose horario, publicada antes de las 11:00, una hora antes del cierre de ofertas al mercado diario (actualmente a las 12:00). El siguiente indicador corresponde a la predicción diaria realizada por el Operador del Sistema para el día siguiente:',
  labelsubSubtLink11: 'Previsión diaria D+1 demanda',

  labelsubSubtitulo12: 'HORIZONTE INTRADIARIO',
  labelsubSubtparrafo12:
    'La calidad de la previsión intradiaria de la demanda se evalúa en función de la predicción horaria de demanda publicada tres horas antes de cada hora. El siguiente indicador corresponde a la predicción horaria realizada tres horas antes:',
  labelsubSubtLink12: 'Previsión intradiaria H+3 demanda',

  labelSubtitulo2: 'PREVISIÓN DE GENERACIÓN PENINSULAR EÓLICA MÁS FOTOVOLTAICA',
  labelSTparrafo2: 'En relación con las',
  labelSTLink2:
    'previsiones de generación peninsular eólica más fotovoltaica, ',
  labelSTparrafo21:
    'la circular establece la evaluación de su calidad en dos horizontes: diario e intradiario.',

  labelsubSubtparrafo2:
    'La calidad de la previsión diaria de generación renovable eólica más fotovoltaica se evalúa en función de la predicción diaria de generación eólica más fotovoltaica para el día siguiente, con desglose horario, publicada antes de las 11:00, una hora antes del cierre de ofertas al mercado diario (actualmente a las 12:00). El siguiente indicador corresponde a la predicción diaria realizada por el Operador del Sistema para el día siguiente:',
  labelsubSubtLink2: 'Previsión diaria D+1 eólica más fotovoltaica',

  labelsubSubtparrafo21:
    'La calidad de la previsión intradiaria de generación renovable eólica más fotovoltaica se evalúa en función de la predicción horaria de generación eólica más fotovoltaica publicada tres horas antes de cada hora. El siguiente indicador corresponde a la predicción horaria realizada tres horas antes:',
  labelsubSubtLink21: 'Previsión intradiaria H+3 eólica más fotovoltaica',
};

export const frecuentes = {
  labelPreguntasFrecuentes: 'PREGUNTAS FRECUENTES',
  labelPreguntas1: '¿Qué información voy a encontrar en este portal web?',
  labelPreguntas2: '¿Puedo analizar esta información más en detalle?',
  labelPreguntas3:
    '¿Cada cuánto se actualiza la información que aparece en la web?',
  labelPreguntas4:
    '¿Puedo configurar mi propia página de contenidos favoritos?',
  labelPreguntas5:
    '¿Puedo acceder desde mi dispositivo móvil a esta página web?',

  labelPreguntasF1Parr1:
    'En este sitio web se publica la información resultante de los procesos de operación del sistema eléctrico que son responsabilidad de REE como Operador del Sistema, según los principios de neutralidad, transparencia, y en cumplimiento de la legislación vigente. ',
  labelPreguntasF1Parr2:
    ' Más en concreto, información de previsiones, programación, medidas, liquidaciones, gestión de la demanda entre otras, es presentada mediante infografías que le darán una rápida visión del estado del sistema eléctrico español desde el punto de vista de su operación.',
  labelPreguntasF1Parr3:
    'Si el usuario lo desea, puede realizar un análisis de todos estos datos. Para más información vea',

  labelPreguntasF2Parr1:
    'Desde el icono "Analizar indicador" presente en las distintas secciones de la web, se accede a una página específica de análisis en donde se encuentra por defecto el indicador seleccionado en origen. Existen distintas posibilidades diferenciadas en 4 pestañas: análisis comparativo, temporal, de composición y geográfico. Además, es posible la descarga de series históricas con resolución horaria, entre otras.',
  labelPreguntasF2Parr2: ' Para más información visite la sección',

  labelPreguntasF3Parr1:
    'En general la información es publicada en cuanto se genera en el sistema de operación (e·sios, SIMEL..., etc). Cada proceso tiene su periodicidad (previsiones, programación, recepción de medidas, liquidación..., etc). En este portal se muestra la mejor información disponible, siempre publica bajo los criterios de confidencilidad vigentes en cada momento.',
  labelPreguntasF3Parr2:
    ' Para información más concreta, consulte la sección "Ampliar Información"',
  labelPreguntasF3Parr3: ' de cada apartado',

  labelPreguntasF4Parr1:
    ' Efectivamente, es posible configurarse una web de e·sios personalizada, con la información de cada sección que más le interese. De esta manera, la navegación para llegar a información localizada en distintas páginas de la web se reduce, pues todo está en el mismo sitio.',
  labelPreguntasF4Parr2:
    ' Para disfrutar de esta funcionalidad no es necesario un usuario y contraseña, únicamente acceder a través de una las redes sociales que aparecen en la caja de acceso.',

  labelPreguntasF5Parr1:
    ' El diseño de la web pública de e·sios es adaptable a dispositivo ó RWD (Responsive Web Design), lo que implica que se puede acceder al sitio www.esios.ree.es y navegar en él desde dispositivos con diferentes características de resolución, memoria, etc.',
  labelPreguntasF5Parr2:
    ' De esta manera es aún más fácil acceder a la información publicada y visualizar los datos de la operación del sistema eléctrico español.',
};
