'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';
import { componentLayout } from '../../components/layout/layout.component';

let templateBalance = (
  handlers,
  programs,
  categories,
  rangeDate,
  itemSelected
) => {
  const { onSelectHandler, onStateHandler, onToogleHandler } = handlers;

  return componentLayout.render(
    html`<div class="esios-balance">
      <div id="spinner1" class="spinner-container">
        <div class="spinner"></div>
      </div>
      <div class="esios-balance__container row">
        <div class="esios-balance__title col-lg-12 col-md-12 col-xs-12">
          <h1>${i18n.t('balance').toUpperCase()}</h1>
        </div>
        <div class="esios-toolbar col-lg-3 col-md-3 col-xs-12">
          <div class="esios-toolbar1"></div>
        </div>
        <div class="row">
          <div class="esios-balance__wrap col-lg-3 col-md-3 col-xs-3">
            <div class="esios-select">
              <span for="${i18n.t('program')}" class="esios-select__label"
                >${i18n.t('program').toUpperCase()}</span
              >
              <button
                id="select_One"
                class="esios-select__selected"
                @click=${onStateHandler}
              >
                <span
                  class="esios-select__text"
                  value="${itemSelected !== undefined || null
                    ? itemSelected.toUpperCase()
                    : programs[0].name}"
                  id="selectOne"
                >
                  ${itemSelected !== undefined || null
                    ? itemSelected.toUpperCase()
                    : programs[0].name}
                </span>
                <span class="esios-select__arrow">
                  <i class="fas fa-sort-down" id=""></i>
                </span>
              </button>
              <ul
                id="list_programs"
                name="${i18n.t('programs')}"
                class="esios-select__selectableHidden"
                @click=${onStateHandler}
              >
                ${programs?.map(
                  (item) => html` <li
                    class="esios-select__value"
                    @click="${onSelectHandler}"
                  >
                    ${i18n.t(item.name).toUpperCase()}
                  </li>`
                )}
              </ul>
            </div>
            <div class="esios-select">
              <span for="${i18n.t('selection')}" class="esios-select__label"
                >${i18n.t('selection').toUpperCase()}</span
              >
              <button
                id="select_Two"
                class="esios-select__selected"
                @click=${onToogleHandler}
              >
                <span
                  class="esios-select__text"
                  value="${i18n.t(rangeDate[0].name).toUpperCase()}"
                  id="selectTwo"
                >
                  ${i18n.t(rangeDate[0].name).toUpperCase()}
                </span>
                <span class="esios-select__arrow">
                  <i class="fas fa-sort-down" id=""></i>
                </span>
              </button>
              <ul
                id="list_selections"
                name="${i18n.t('selection')}"
                class="esios-select__selectableHidden"
              >
                ${rangeDate?.map(
                  (item) => html` <li
                    class="esios-select__value"
                    @click="${onSelectHandler}"
                    value="${item.alias}"
                  >
                    ${i18n.t(item.name).charAt(0).toUpperCase() +
                    i18n.t(item.name).slice(1)}
                  </li>`
                )}
              </ul>
            </div>
            <div class="esios-balance__listEnergyDesktop">
              <div class="esios-listEnergyItems">
                <div class="esios-listEnergyItems__wrap">
                  <ul class="esios-listEnergyItems__list"></ul>
                </div>
              </div>
            </div>
          </div>
          <div class="esios-balance__wrap col-lg-9 col-xs-9">
            <div>
              <div id="balancesNoDataRect" class="balances-noDataRect">
                ${i18n.t('no_data_dates')}
              </div>
              <div
                class="esios-balance__graph chari-chart no-data-graphic-balances"
                id="balance-graph"
              ></div>
            </div>
          </div>
          <div class="esios-balance__listEnergyMobile">
            <div class="esios-listEnergyItems">
              <div class="esios-listEnergyItems__wrap">
                <ul class="esios-listEnergyItems__list"></ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="medium">
        <div class="selector-title">HORA</div>
        <div class="selector-container">
          <div id="mobile__selector"></div>
        </div>
      </div>

      <div class="row esios-balance__row-table">
        <div id="tabwithcontent"></div>
      </div>
    </div>`,
    {
      showBanner: false,
      showFooterImage: true,
    }
  );
};

export { templateBalance };
