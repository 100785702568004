"use strict";
import { html } from "lit-html";
import { store } from "../../../app.init";
import { setShareState } from "../../../state/actions";
import { setToogleGraph } from "../../../state/actions";
import i18n from "i18next";
import { templateSelect } from "../select/select.template";

let templateToolbarTime = (id, time,showTimePicker = false) => {
  let select = [
    {
      id: 1,
      label: "",
      close: true,
      options: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
    },
    {
      id: 2,
      label: "",
      close: true,
      options: ["00", "10", "20", "30", "40", "50"],
    },
  ];

  return html`
    <div class="esios-toolbar-time">
      <div id="${id}" class="esios-toolbar-time__component mod-date">00:00</div>
      <div class="esios-toolbar-time__selector" style="${showTimePicker === false ? "display: none;" : ""}">
        <div class="esios-toolbar-time__wrapper">
          ${templateSelect(select[0].id, "", select[0].options)}
          ${templateSelect(select[1].id, "", select[1].options)}
        </div>
      </div>
    </div>
  `;
};

export { templateToolbarTime };
