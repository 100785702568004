'use strict';

let servicesUsers = (() => {
  let getUsers = async () => {
    const fetchResponse = await fetch(window.ENV.API_URL + '/usuarios');
    return await fetchResponse.json();
  };

  return {
    getUsers: getUsers,
  };
})();

export { servicesUsers };
