'use strict';

/**
 * @description Unidades Fisicas Component
 * @returns {Object} Devuelve la instancia del componente
 */

import i18n from 'i18next';
import { render } from 'lit-html';
import moment from 'moment';
import _ from 'underscore';
import { store } from '../../app.init.js';
import { componentButtons } from '../../shared/components/buttons/buttons.component.js';
import { componentPaginator } from '../../shared/components/paginator/paginator.component.js';
import { templateTable } from '../table/table.template.js';
import { servicesDocumentacion } from './documentacion.services.js';
import { templateDocumentacion } from './documentacion.template.js';

let componentDocumentacion = (function () {
  let context = {};
  let handlers = {
    onPageChange: (page) => onPageChange(page),
    onTotalChange: (event) => onTotalChange(event),
    onQueryChange: (event) => onQueryChange(event),
    ocultar: (event) => ocultar(event),
    onSelectChange: (index) => onSelectChange(index),
  };
  let pageTitle = '';
  let renderSuccess = false;
  let listMarket;
  let select = [];
  let objMarket = [
    {
      type: 'title',
      id: 1,
      title: '¿QUIERE SER SUJETO DEL MERCADO Y DEL DESPACHO?',
    },
    {
      type: 'button',
      id: 2,
      label: 'ACCESO A INFORMACIÓN PARA CLIENTES',
      url: 'https://www.ree.es/es/clientes/',
    },
    {
      type: 'text',
      id: 1,
      content:
        'Información sobre el proceso de alta en el Operador del Sistema para la participación en el mercado de energía eléctrica y formularios de registro a través de la web pública de gestión de datos estructurales.',
    },
  ];

  // tres filtros
  let searchForm = {
    type: 'publicacion',
    date_init: moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD'),
    date_end: moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD'),
  };
  let filterForm = {
    query: '',
    potencia: '',
    tipoproduccion: '',
    vinculacionsm: '',
    vinculacionup: '',
  };
  let data = [];
  let dataPaginated = [];

  let paginationValues = [25, 50, 100];
  let pagination = {
    total: 0,
    page: 1,
    totalPages: 1,
    pagination: paginationValues[0],
  };
  // Definir sus columnas
  const columns = [
    {
      title: 'documentColumn',
      dataIndex: ['title'],
      defaultSortOrder: 'asc',
      sortDirections: ['asc', 'desc'],
      sorter: (datalist, order) => {
        const sorted = [...datalist].sort((a, b) => {
          if (order === 'asc') {
            if (a.title.toLowerCase() < b.title.toLowerCase()) {
              return -1;
            }
            if (a.title.toLowerCase() > b.title.toLowerCase()) {
              return 1;
            }
            return 0;
          } else if (order === 'desc') {
            if (a.title.toLowerCase() > b.title.toLowerCase()) {
              return -1;
            }
            if (a.title.toLowerCase() < b.title.toLowerCase()) {
              return 1;
            }
            return 0;
          }
        });
        data = sorted;
        filterData();
      },
    },
    {
      title: 'scope',
      dataIndex: 'taxonomy_terms',
      defaultSortOrder: 'asc',
      sortDirections: ['asc', 'desc'],
      sorter: (datalist, order) => {
        const sorted = [...datalist].sort((a, b) => {
          const ataxonomy = getAmbitText(a.taxonomy_terms).toLowerCase();
          const btaxonomy = getAmbitText(b.taxonomy_terms).toLowerCase();
          if (order === 'asc') {
            if (ataxonomy < btaxonomy) {
              return -1;
            }
            if (ataxonomy > btaxonomy) {
              return 1;
            }
            return 0;
          } else if (order === 'desc') {
            if (ataxonomy > btaxonomy) {
              return -1;
            }
            if (ataxonomy < btaxonomy) {
              return 1;
            }
            return 0;
          }
        });
        data = sorted;
        filterData();
      },
      render: (taxonomy_terms, rowData) => {
        return getAmbitText(taxonomy_terms);
      },
    },
    {
      title: 'area',
      dataIndex: 'taxonomy_terms',
      defaultSortOrder: 'asc',
      sortDirections: ['asc', 'desc'],
      sorter: (datalist, order) => {
        const sorted = [...datalist].sort((a, b) => {
          const aArea = getAreaText(a.taxonomy_terms).toLowerCase();
          const bArea = getAreaText(b.taxonomy_terms).toLowerCase();
          if (order === 'asc') {
            if (aArea < bArea) {
              return -1;
            }
            if (aArea > bArea) {
              return 1;
            }
            return 0;
          } else if (order === 'desc') {
            if (aArea > bArea) {
              return -1;
            }
            if (aArea < bArea) {
              return 1;
            }
            return 0;
          }
        });
        data = sorted;
        filterData();
      },
      render: (taxonomy_terms, rowData) => {
        return getAreaText(taxonomy_terms);
      },
    },
    {
      title: 'type',
      dataIndex: 'taxonomy_terms',
      defaultSortOrder: 'asc',
      sortDirections: ['asc', 'desc'],
      sorter: (datalist, order) => {
        const sorted = [...datalist].sort((a, b) => {
          const aArea = getTipoText(a.taxonomy_terms).toLowerCase();
          const bArea = getTipoText(b.taxonomy_terms).toLowerCase();
          if (order === 'asc') {
            if (aArea < bArea) {
              return -1;
            }
            if (aArea > bArea) {
              return 1;
            }
            return 0;
          } else if (order === 'desc') {
            if (aArea > bArea) {
              return -1;
            }
            if (aArea < bArea) {
              return 1;
            }
            return 0;
          }
        });
        data = sorted;
        filterData();
      },
      render: (taxonomy_terms, rowData) => {
        return getTipoText(taxonomy_terms);
      },
    },
    {
      title: 'updated_at',
      dataIndex: 'published_at',
      defaultSortOrder: 'asc',
      sortDirections: ['asc', 'desc'],
      sorter: (datalist, order) => {
        const sorted = [...datalist].sort((a, b) => {
          const aDate = getPublishDate(a.published_at).toLowerCase();
          const bDate = getPublishDate(b.published_at).toLowerCase();
          if (order === 'asc') {
            if (aDate < bDate) {
              return -1;
            }
            if (aDate > bDate) {
              return 1;
            }
            return 0;
          } else if (order === 'desc') {
            if (aDate > bDate) {
              return -1;
            }
            if (aDate < bDate) {
              return 1;
            }
            return 0;
          }
        });
        data = sorted;
        filterData();
      },
      render: (published_at, rowData) => {
        let formattedDate = new Date(published_at).toLocaleDateString();
        return moment(formattedDate, 'DD/MM/YYYY').format('DD/MM/YYYY');
      },
    },
  ];

  const selectElements = [
    {
      value: 'potencia',
      label: 'ambit',
    },
    {
      value: 'tipoproduccion',
      label: 'area',
    },
    {
      value: 'vinculacionsm',
      label: 'type',
    },
  ];
  // Fin Definir columnas

  // paginacion funciones
  const onPageChange = (page) => {
    pagination.page = page;
    pagination.totalPages = Math.ceil(data.length / pagination.pagination);
    filterData();
  };

  const onTotalChange = (event) => {
    pagination.page = 1;
    pagination.pagination = event.target.value;
    pagination.totalPages = Math.ceil(data.length / pagination.pagination);
    filterData();
  };
  // fin paginacion
  // filtros funciones de filtrado abajo

  const onQueryChange = (event) => {
    filterForm.query = event.target.value;
    if (event.target.value.length > 0)
      document
        .querySelector('.esios-documentacion__boton-remove')
        .classList.remove('hidden');
    else
      document
        .querySelector('.esios-documentacion__boton-remove')
        .classList.add('hidden');
    filterData();
  };

  const onSelectChange = (index) => {
    // cE = chosen element
    const cE = selectElements[index];
    return (event) => {
      filterForm[cE.value] = event.target.value;
      let selected = document.querySelectorAll('.ui-selectmenu-text');
      filterForm[cE.value] === ''
        ? (selected[index].innerHTML = i18n.t(cE.label))
        : filterForm[cE.value];
      filterData();
    };
  };

  const ocultar = (event) => {
    document.querySelector('.esios-documentacion__tabla__input').value = '';
    document
      .querySelector('.esios-documentacion__boton-remove')
      .classList.add('hidden');
    filterForm.query = '';
    filterData();
  };

  // fin de funciones de filtrado

  const filterData = () => {
    let filtered = data.filter((item) =>
      item.title?.toLowerCase().includes(filterForm.query.toLowerCase())
    );

    const filterQuery = (handler, parameter) => {
      let filter = data.filter((item) =>
        handler(item[parameter])
          .toLowerCase()
          .includes(filterForm.query.toLowerCase())
      );
      filtered = [...filter, ...filtered];
    };

    filterQuery(getAmbitText, 'taxonomy_terms');
    filterQuery(getAreaText, 'taxonomy_terms');
    filterQuery(getTipoText, 'taxonomy_terms');

    let filterQueryDate = data.filter((item) => {
      let date = moment(item.published_at, 'YYYY/MM/DD').format('DD/MM/YYYY');
      return date.includes(filterForm.query);
    });

    filtered = [...filterQueryDate, ...filtered];

    filtered = filtered.filter((item) =>
      getAmbitText(item.taxonomy_terms)
        .toLowerCase()
        .includes(filterForm.potencia.toLowerCase())
    );

    filtered = filtered.filter((item) =>
      getAreaText(item.taxonomy_terms)
        .toLowerCase()
        .includes(filterForm.tipoproduccion.toLowerCase())
    );

    filtered = filtered.filter((item) =>
      getTipoText(item.taxonomy_terms)
        .toLowerCase()
        .includes(filterForm.vinculacionsm.toLowerCase())
    );
    var hash = {};
    filtered = filtered.filter(function (current) {
      var exists = !hash[current.id];
      hash[current.id] = true;
      return exists;
    });
    dataPaginated = filtered.slice(
      (pagination.page - 1) * pagination.pagination,
      pagination.page * pagination.pagination
    );
    pagination.total = filtered.length;
    pagination.totalPages = Math.ceil(filtered.length / pagination.pagination);
    renderAll();
    renderTable();
  };

  // filtros de busqueda por fecha, arriba

  const getAmbitText = (taxonomy_terms) => {
    const ambito = taxonomy_terms.filter(
      (taxonomy_termsItem) => taxonomy_termsItem.vocabulary_id === 20
    );
    let ambitoText = '';
    ambito.forEach((ambitoItem) => {
      ambitoText += ambitoItem.name + ', ';
    });
    return ambitoText.substring(0, ambitoText.length - 2);
  };

  const getSelect = (taxonomy_terms, id) => {
    const select = taxonomy_terms.filter(
      (taxonomy_termsItem) => taxonomy_termsItem.vocabulary_id === id
    );
    return select;
  };

  const getAreaText = (taxonomy_terms) => {
    const areas = taxonomy_terms.filter(
      (taxonomy_termsItem) => taxonomy_termsItem.vocabulary_id === 22
    );
    let areaText = '';
    areas.forEach((areaItem) => {
      areaText += areaItem.name + ', ';
    });
    return areaText.substring(0, areaText.length - 2);
  };
  const getTipoText = (taxonomy_terms) => {
    const tipos = taxonomy_terms.filter(
      (taxonomy_termsItem) => taxonomy_termsItem.vocabulary_id === 21
    );
    let tipoText = '';
    tipos.forEach((areaItem) => {
      tipoText += areaItem.name + ', ';
    });
    return tipoText.substring(0, tipoText.length - 2);
  };
  // dos fechas
  const getPublishDate = (publication_date) => {
    let dateText = '';
    dateText = publication_date;
    dateText = dateText.split('-').join('/');
    return dateText.substring(0, dateText.length - 15);
  };

  let preRenderComponent = async () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState(searchForm);
    document.title = `${i18n.t('documentation')} | ${i18n.t('page_subtitle')}`;
    context.name = state.exampleReducer.name;
    listMarket = [
      {
        type: 'title',
        id: 2,
        title: 'structural_data',
      },
      {
        type: 'list',
        id: 1,
        text: 'participants_auction',
        url: `/${i18n.t('routes.lng')}/${i18n.t(
          'routes.participants_auction'
        )}`,
      },
      {
        type: 'list',
        id: 2,
        text: 'programming_units',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.programming_units')}`,
      },
      {
        type: 'list',
        id: 3,
        text: 'physical_units',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.physical_units')}`,
      },
      {
        type: 'list',
        id: 4,
        text: 'market_subjects',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.market_subjects')}`,
      },
    ];
  };

  // get archives trae por fecha API
  const searchDataUnidades = async () => {
    return servicesDocumentacion.getArchivesDocumentacion().then((respData) => {
      data = respData;
      var extRegex = /(?:\.([^.]+))?$/;

      let processData = data.map((i) => {
        var thereIsMedia = i.media[0];
        var type;
        if (thereIsMedia) {
          type =
            i.media[0]['media_type'] === 'Link'
              ? 'link'
              : extRegex.exec(i.media[0]['document_value_file_name'])[1];
        }

        return {
          id: i.id,
          name: i.title,
          url:
            i.media[0]?.embed_path ||
            window.ENV.API_URL + i.media[0]?.download ||
            '',
          scopes: _.where(i['taxonomy_terms'], { vocabulary_id: 20 }),
          areas: _.where(i['taxonomy_terms'], { vocabulary_id: 22 }),
          subjects: _.where(i['taxonomy_terms'], { vocabulary_id: 21 }),
          type: type,
          publicationDate: i.published_at,
          downloable: type !== 'link',
        };
      });

      const dataCsv = respData.map((i) => {
        var thereIsMedia = i.media[0];
        var type;
        if (thereIsMedia) {
          type =
            i.media[0]['media_type'] === 'Link'
              ? 'link'
              : extRegex.exec(i.media[0]['document_value_file_name'])[1];
        }

        let taxonomyTerms = [];
        i.vocabularies.forEach((j) => {
          const tax = i.taxonomy_terms.filter(
            (item) => item.vocabulary_id === j.id_vocabulary
          );
          taxonomyTerms.push({
            name: tax.map((tax) => tax.name).join(','),
            id: tax.map((tax) => tax.vocabulary_id)[0],
          });
        });
        return [
          i.id,
          i.title,
          i.media[0]?.embed_path ||
            window.ENV.API_URL + i.media[0]?.download ||
            '',
          taxonomyTerms.find((el) => el.id === 20)?.name,
          taxonomyTerms.find((el) => el.id === 22)?.name,
          taxonomyTerms.find((el) => el.id === 21)?.name,
          type,
          i.published_at,
          type !== 'link',
        ];
      });
      dataCsv.unshift([0, 1, 2, 3, 4, 5, 6, 7, 8]);

      const fileName =
        'export_' +
        i18n.t('routes.documentation') +
        '_' +
        moment().format('YYYY-MM-DD_HH_mm');
      const buttonsArray = [
        {
          title: 'EXPORTAR JSON',
          action: 'exportJSON',
          data: processData,
          docName: fileName,
        },
        {
          title: 'EXPORTAR CSV',
          action: 'exportCSV',
          data: dataCsv,
          docName: fileName,
        },
        {
          title: 'EXPORTAR EXCEL',
          action: 'exportExcel',
          data: dataCsv,
        },
        { title: 'IMPRIMIR', action: 'print', url: '#' },
      ];

      componentButtons.render(buttonsArray, '#button-fisicas');
      pagination.total = respData.length;
      pagination.totalPages = Math.ceil(
        respData.length / pagination.pagination
      );

      const filters = {
        ambit: [],
        area: [],
        type: [],
      };

      let filterAttrs = [];

      const addFilterAttrs = (handlerParameter, value, id) => {
        filterAttrs = [
          {
            label: i18n.t('taxonomy_terms'),
            handler: getSelect,
            handlerParameter,
            value,
            id,
          },
          ...filterAttrs,
        ];
      };

      addFilterAttrs(21, 'type', 3);
      addFilterAttrs(22, 'area', 2);
      addFilterAttrs(20, 'ambit', 1);

      const createFilterModel = (data, filters, filterAttrs) => {
        data.forEach((element) => {
          for (const attr of filterAttrs) {
            const value = attr.handler(
              element[attr.label],
              attr.handlerParameter
            );

            value.map(({ name }) => {
              if (!filters[attr.value].some((e) => e.value === name)) {
                filters[attr.value].push({ label: name, value: name });
              }
            });
          }
        });
        const result = filterAttrs.map((at) => {
          const orderedF = filters[at.value].sort((a, b) => {
            const comma2Float = (label) =>
              label.split('.').join('').split(',').join('.');

            const comma2FloatA = comma2Float(a.label);
            const comma2FloatB = comma2Float(b.label);

            if (isNaN(comma2FloatA) || isNaN(comma2FloatB)) {
              if (a.label < b.label) {
                return -1;
              }
              return 1;
            } else {
              if (Number(comma2FloatA) < Number(comma2FloatB)) {
                return -1;
              }
              return 1;
            }
          });

          return {
            id: at.id,
            label: '',
            options: [{ label: 'show_all', value: '' }, ...orderedF],
          };
        });
        return result;
      };

      select = createFilterModel(data, filters, filterAttrs);
      if (respData) {
        document.getElementById('spinner1').style.display = 'none';
      } else {
        return respData;
      }
    });
  };

  let renderComponent = async () => {
    let scope;
    try {
      await templateDocumentacion(
        context,
        handlers,
        pageTitle,
        select,
        paginationValues,
        pagination,
        filterForm,
        searchForm,
        objMarket,
        listMarket,
        selectElements
      );
      document
        .querySelector('.esios-layout__main')
        .classList.add('esios-layout__main--doc');
      document
        .querySelector('.esios-layout__print-logo')
        .classList.add('documentation');
      document
        .querySelector('#esios-layout-banner')
        .classList.add('ly-descargas');
      document
        .querySelector('.esios-layout__wrap')
        .classList.add('esios-layout__wrap--doc');
      /* document
        .querySelector('.mod-documents-download')
        .classList.add('mod-documents-download--doc');*/
      document
        .querySelector('.esios-pagination')
        .classList.add('esios-pagination--doc');
      document
        .querySelector('.esios-layout__subcontent__wrapper')
        .classList.add('.esios-layout__subcontent__wrapper--doc');
      document
        .querySelector('.esios-footer')
        .classList.add('.esios-footer--doc');

      await searchDataUnidades();

      const urlParams = new URLSearchParams(window.location.search);
      scope = urlParams.get('scope') || '';

      if (scope.length > 0) {
        scope.replace('+', ' ');
        filterForm.query = scope;
      }
      filterData();

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }

    const selects = [
      {
        className: '.ree-select-potencia',
        evento: handlers.onSelectChange(0),
      },
      {
        className: '.ree-select-tipoproduccion',
        evento: handlers.onSelectChange(1),
      },
      {
        className: '.ree-select-vinculacionsm',
        evento: handlers.onSelectChange(2),
      },
    ];

    const createSelect = (selects) => {
      selects.map(({ className, evento }) => {
        $(className).selectmenu({
          appendTo: '.esios-documentacion__wrap2',
        });

        $(className).on('selectmenuchange', function (event, ui) {
          evento(event);
        });
      });
    };
    createSelect(selects);
    if (scope == i18n.t('exchanges_i')) {
      document.querySelector('.ree-select-tipoproduccion').value =
        i18n.t(scope);
      $('.ree-select-tipoproduccion').selectmenu('refresh');
    } else {
      document.querySelector('.ree-select-potencia').value = i18n.t(scope);
      $('.ree-select-potencia').selectmenu('refresh');
    }

    document.querySelector('#pagination').classList.remove('hidden');
    document
      .querySelector('.esios-pagination__results')
      .classList.remove('hidden');
    document
      .querySelector('.esios-selects-documents')
      .classList.remove('hidden');
  };

  let renderTable = () => {
    const tableElement = document.getElementById('download-table');
    render(templateTable(columns, dataPaginated, data), tableElement);
    componentPaginator.render(
      pagination,
      onPageChange,
      'pagination-documentacion'
    );
  };

  let renderAll = () => {
    templateDocumentacion(
      context,
      handlers,
      pageTitle,
      select,
      paginationValues,
      pagination,
      filterForm,
      searchForm,
      objMarket,
      listMarket,
      selectElements
    );
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentDocumentacion };
