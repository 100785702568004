// Función para convertir la fecha recibida en el formato requerido

import moment from 'moment';
import i18n from 'i18next';

export function setDateFormat(current, split = ' / ') {
  let recibed = new Date(current);

  let day = recibed.getDate();
  let month = recibed.getMonth() + 1;
  let year = recibed.getFullYear();

  day < 10 ? (day = `0${day}`) : day;
  month < 10 ? (month = `0${month}`) : month;

  return `${day}${split}${month}${split}${year}`;
}

export function setDateFormatWithTime(current, split = '-') {
  let recibed = new Date(current);

  let day = recibed.getDate();
  let month = recibed.getMonth() + 1;
  let year = recibed.getFullYear();
  let hours = recibed.getHours();
  let minutes = recibed.getMinutes();

  day < 10 ? (day = `0${day}`) : day;
  month < 10 ? (month = `0${month}`) : month;

  return `${day}${split}${month}${split}${year}T${hours}:${minutes}`;
}

export function urlDateToJSDate(date) {
  let [d, m, y, hh] = date.split(/-|T/);
  return `${y}-${m}-${d}T${hh}`;
}

export function dateWithTime() {
  let currentDate = localStorage.getItem('bread-crumbs-date') || undefined;
  if (currentDate) {
    const [day, month, year] = currentDate.split('/');
    currentDate = `${month}/${day}/${year}`;
  }
  const date = moment(currentDate)
    .tz('Europe/Madrid')
    .format('DD-MM-YYYYTHH:mm');
  return date;
}

export function dateWithoutTime() {
  let currentDate = localStorage.getItem('bread-crumbs-date') || undefined;
  if (currentDate) {
    const [day, month, year] = currentDate.split('/');
    currentDate = `${month}/${day}/${year}`;
  }
  const date = moment(currentDate).tz('Europe/Madrid').format('DD-MM-YYYY');
  return date;
}

export function dateSetTime() {
  let currentDate = localStorage.getItem('bread-crumbs-date') || undefined;
  if (currentDate) {
    const [day, month, year] = currentDate.split('/');
    currentDate = `${month}/${day}/${year}`;
  }
  const date = moment(currentDate)
    .tz('Europe/Madrid')
    .format('DD-MM-YYYYT00:00');
  return date;
}

export function parseDate(stringDate) {
  let parsedDate;
  const [day, month, year] = stringDate.split('-');
  parsedDate = `${month}/${day}/${year}`;
  return parsedDate;
}

export function getBreadCrumbsDate() {
  let currentDate = localStorage.getItem('bread-crumbs-date') || undefined;
  let day, month, year;
  if (!currentDate) {
    [day, month, year] = moment().format('DD-MM-YYYY').split('-');
  } else {
    [day, month, year] = currentDate.split(' / ');
  }
  return {
    day,
    month,
    year,
    date: currentDate,
  };
}

export function resetBreadCrumbsDate() {
  let currentDate = localStorage.getItem('bread-crumbs-date');
  if (currentDate) {
    localStorage.removeItem('bread-crumbs-date');
  }
}

export const getHoursList = (from = 0, to = 23) => {
  const res = [];
  for (let i = from; i <= to; i++) {
    const hour = i < 10 ? '0' + i : '' + i;
    res.push(hour);
  }
  return res;
};

export const getMinutesList = (step = 10, from = 0, to = 60) => {
  const res = [];
  for (let i = from; i < to; i += step) {
    const minute = i < 10 ? '0' + i : '' + i;
    res.push(minute);
  }
  return res;
};

export const getYears = (lastYears = 13) => {
  const currentYear = new Date().getFullYear();
  return Array(lastYears)
    .fill()
    .map((_, index) => String(currentYear - lastYears + index + 1));
};

export const getYearsBase = () => {
  const currentYear = new Date().getFullYear();
  const BASE_YEAR = 2010;

  return Array(currentYear + 1 - BASE_YEAR)
    .fill()
    .map((_, index) => String(BASE_YEAR + index));
};

export const getYearsPlus = (addYears = 10) => {
  const currentYear = new Date().getFullYear();
  const BASE_YEAR = 2010;
  return Array(currentYear + addYears + 1 - BASE_YEAR)
    .fill()
    .map((_, index) => String(BASE_YEAR + index));
};

export const getMonths = (index, min = false) => {
  const months = [
    i18n.t('january'),
    i18n.t('february'),
    i18n.t('march'),
    i18n.t('april'),
    i18n.t('ma'),
    i18n.t('june'),
    i18n.t('july'),
    i18n.t('august'),
    i18n.t('september'),
    i18n.t('october'),
    i18n.t('november'),
    i18n.t('december'),
  ];
  const minMonths = [
    i18n.t('jan'),
    i18n.t('feb'),
    i18n.t('mar'),
    i18n.t('apr'),
    i18n.t('may'),
    i18n.t('jun'),
    i18n.t('jul'),
    i18n.t('aug'),
    i18n.t('sep'),
    i18n.t('oct'),
    i18n.t('nov'),
    i18n.t('dec'),
  ];
  if (typeof index !== 'undefined') {
    return min ? minMonths[index] : months[index];
  } else {
    return min ? minMonths : months;
  }
};

export const getWeekDays = (index) => {
  const weekDays = [
    i18n.t('Sun'),
    i18n.t('Mon'),
    i18n.t('Tue'),
    i18n.t('Wed'),
    i18n.t('Thu'),
    i18n.t('Fri'),
    i18n.t('Sat'),
  ];
  if (typeof index !== 'undefined') {
    return weekDays[index];
  } else {
    return weekDays;
  }
};
