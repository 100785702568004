'use strict';
import 'regenerator-runtime/runtime';
import i18n from 'i18next';
import { api } from './../../utils/api-interceptor';

let servicesCodigoConexion = (() => {
  let init = (e) => llamarServicio(e);
  const llamarServicio = async (e) => {
    const urlApi =
      `static_pages?slug=${i18n.t(
        'routes.connection_network_codes'
      )}&temporal=` + Math.floor(Math.random() * 1000);
    const data = await api(urlApi, { hasLang: true });
    return data.content;
  };
  return {
    init: (e) => init(e),
  };
})();

export { servicesCodigoConexion };
