import i18n from 'i18next';
import { html } from 'lit-html';
import { templateToolbarTime } from '../../../../shared/components/toolbarTime/toolbarTime.template';

const MyEsiosWidget38 = () => {
  return html`
    <div class="esios-toolbar col-12 mb-4">
      <div class="esios-toolbar38"></div>
      ${templateToolbarTime('horario-frontera-map')}
    </div>
    <div
      id="map-wrapper"
      class="esios-intercambios"
      style="position: relative;"
    >
      <div id="spinner38" class="spinner-container">
        <div class="white-overlay"></div>
        <div class="spinner"></div>
      </div>
      <div
        id="char38"
        class="esios-chart esios-intercambios__no-data-graphic"
        style="display:none"
      >
        <div id="rect1" class="esios-pvpc__no-data">
          ${i18n.t('no-data-available')}
        </div>
      </div>
      <div id="map-main-container"></div>
    </div>
  `;
};

export default MyEsiosWidget38;
