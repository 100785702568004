'use strict';

import i18n from 'i18next';
import { combineReducers, createStore } from 'redux';
import * as translationEn from './assets/translations/en.json';
import * as translationEs from './assets/translations/es.json';
import ENV from './environment.json';
import { go } from './routing/routes.js';
import {
  setCurrentLang,
  setNameAction,
  setPathname,
  setShareState,
  setShowResponsiveMenuAction,
} from './state/actions.js';
import { exampleReducer } from './state/example-reducer';
// Añadir los reducers pertinentes
const appReducers = combineReducers({
  exampleReducer,
});

// Creacion del store con el reducer declarado

export const store = createStore(
  appReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const ABORT = {
  controller: null,
  signal: null,
  abort: () => {
    ABORT.controller = ABORT.controller || new AbortController();
    ABORT.controller.abort();
  },
  new: () => {
    ABORT.controller = new AbortController();
    ABORT.signal = ABORT.controller.signal;
    return {
      signal: ABORT.signal,
    };
  },
};

function startApp() {
  const currentRoute = window.location.pathname;
  const resources = {
    en: {
      translation: translationEn,
    },
    es: {
      translation: translationEs,
    },
  };
  i18n.init({
    resources,
    lng: 'es',
    fallbackLng: 'en',
  });
  store.dispatch(setNameAction('name'));
  store.dispatch(setShowResponsiveMenuAction(false));
  store.dispatch(setShareState(false));
  store.dispatch(setPathname(currentRoute));
  const lang = currentRoute.split('/')[1];
  i18n.changeLanguage(lang);
  store.dispatch(setCurrentLang(lang));
  document.documentElement.lang = lang;
  go(currentRoute);
  // Con esa linea, la aplicación estará a la escucha de un cambio y se renderizará de nuevo
  // store.subscribe(() => go(currentRoute))
}

document.addEventListener('DOMContentLoaded', function () {
  fetch('./environment.json')
    .then((data) => data.json())
    .then((env) => (window.ENV = env))
    .catch((e) => (window.ENV = ENV))
    .finally(() => startApp());
});
