import i18n from 'i18next';
import { html } from 'lit-html';
import moment from 'moment-timezone';
import { componentButtons } from '../../../shared/components/buttons/buttons.component.js';
import { Utils } from '../../../utils/utils.js';

let dataJson = [];
let dataCsv = [];
let dataExcel = [];

const AnalysisFormButtons = () => {
  return html`<div class="analysis-buttons" id="analysis-btn"></div>`;
};
export default AnalysisFormButtons;

export function exportDataProccess(selector, arr, analysis) {
  dataJson = [];
  dataCsv = [];
  dataExcel = [];
  const { tabSelected } = analysis;
  let firstRow = ['id', 'name', 'geoid', 'geoname', 'value', 'datetime'];
  if (tabSelected.label === 'geographical') {
    arr.rows.map((geoData) => {
      fillArrays(
        {
          id: analysis.indicator.id,
          name: analysis.indicator.name,
          geoid: geoData.cartodb_id,
          geoname: geoData.name,
          values: Utils.toNumber(geoData.total, 1, 'comma'),
          datetime: geoData.stamp,
        },
        true,
        tabSelected.label
      );
    });
  } else if (tabSelected.label === 'composition') {
    firstRow = [
      'id',
      'name',
      'geoid',
      'geoname',
      'value',
      'start_date',
      'end_date',
    ];
    arr.map((indicator) => {
      const currentGeo = getCurrentGeo(indicator);
      indicator.values.map((val) => {
        fillArrays(
          {
            id: indicator.excelId || indicator.id,
            name: indicator.name,
            geoid: currentGeo.geo_id,
            geoname: currentGeo.geo_name,
            values: val.value,
            start_date: moment(analysis.start_date, 'DD-MM-YYYY-THH:mm')
              .tz('Europe/Madrid')
              .format(),
            end_date: moment(analysis.end_date, 'DD-MM-YYYY-THH:mm')
              .tz('Europe/Madrid')
              .format(),
          },
          true
        );
      });
    });
  } else {
    arr.map((indicator) => {
      const currentGeo = getCurrentGeo(indicator);
      indicator.values.map((val) => {
        fillArrays(
          {
            id: indicator.excelId || indicator.id,
            name: indicator.name,
            geoid: currentGeo.geo_id,
            geoname: currentGeo.geo_name,
            values: val.y,
            datetime: moment(val.x || val.datetime)
              .tz('Europe/Madrid')
              .format(),
          },
          true
        );
      });
    });
  }

  dataCsv.unshift(firstRow);
  dataExcel.unshift(firstRow);

  let currentDatetime = moment().tz('Europe/Madrid').format('YYYY-MM-DD_HH:mm');

  let exportName = Utils.removeAccents(analysis.indicator.name);

  let words = '';
  if (exportName) {
    words = exportName?.split(' ');
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0]?.toUpperCase() + words[i].substr(1);
    }
    exportName = words.join('');
  }

  let fileTitle = `export_${exportName}_${currentDatetime}`;
  let buttonsArray = [
    {
      title: i18n.t('export'),
      action: 'exports',
      id: 'export_multiple',
      dataCSV: dataCsv,
      dataExcel: dataExcel,
      csvName: fileTitle,
      dataJSON: dataJson,
      jsonName: fileTitle,
    },
    { title: i18n.t('print'), action: 'print', url: '#' },
  ];
  componentButtons.render(buttonsArray, selector);
}

const fillArrays = (values, excel = false, type = '') => {
  dataJson.push(values);
  dataCsv.push(Object.values(values));
  if (excel) {
    const valuesForExcel = Object.values(values);
    if (type !== 'geographical') {
      valuesForExcel[4] = Utils.toNumber(valuesForExcel[4], 3, 'comma');
    }
    dataExcel.push(valuesForExcel);
  }
};

const getCurrentGeo = (indicator) => {
  const itemId = `${indicator.id}`;
  const currentGeo = indicator.geos.filter(
    (geoid) => geoid.geo_name === itemId.toString().split('-')[1]
  )[0] || { geo_id: '', geo_name: '' };
  return currentGeo;
};
