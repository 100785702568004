'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';
import moment from 'moment';
import { componentLayout } from '../../components/layout/layout.component';
import { templateToolbarTime } from '../../shared/components/toolbarTime/toolbarTime.template';
import { getUrlParams } from '../../utils/url';

let templatepvpc = (fdate = false) => {
  const idex = 1001;
  let infoToSHOW = [
    'PMH',
    'SAH',
    'FOS',
    'FOM',
    'CCV',
    'TEU',
    'PCAP',
    'EDSR',
    'EDMACP',
    'INT',
  ];
  let newinfoToSHOW = [
    'PMH',
    'TAH',
    'SAH',
    'FOS',
    'FOM',
    'CCV',
    'TEU',
    'PCAP',
    'EDSR',
    'INT',
  ];

  let Datepicker = $('#datepicker').val();
  let DateTAH = '01-01-2024';
  if (Datepicker != null) {
    let dateParts = Datepicker.split('/').map((part) => part.trim());
    // Create a Date object with the correct format (year, month, day)
    let DateSEL = dateParts[0]
      .concat('-', dateParts[1])
      .concat('-', dateParts[2]);
    // Divide las cadenas en partes: día, mes y año
    const [dayTAH, monthTAH, yearTAH] = DateTAH.split('-');
    const [dayFIN, monthFIN, yearFIN] = DateSEL.split('-');

    // Crea objetos Date
    const dateTAH = new Date(yearTAH, monthTAH - 1, dayTAH);
    const dateSEL = new Date(yearFIN, monthFIN - 1, dayFIN);

    // Compara las fechas
    if (dateTAH > dateSEL) {
      console.log('DateTAH es mayor que date seleccionada');
    } else if (dateTAH < dateSEL) {
      infoToSHOW = newinfoToSHOW;
    } else {
      infoToSHOW = newinfoToSHOW;
    }
  } else {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams != null && urlParams.get('date') != null) {
      let date = urlParams.get('date');
      let DateSEL = date;
      // Divide las cadenas en partes: día, mes y año
      const [dayTAH, monthTAH, yearTAH] = DateTAH.split('-');
      const [dayFIN, monthFIN, yearFIN] = DateSEL.split('-');
      // Crea objetos Date
      const dateTAH = new Date(yearTAH, monthTAH - 1, dayTAH);
      const dateSEL = new Date(yearFIN, monthFIN - 1, dayFIN);

      // Compara las fechas
      if (dateTAH > dateSEL) {
        console.log('DateTAH es mayor que date seleccionada');
      } else if (dateTAH < dateSEL) {
        infoToSHOW = newinfoToSHOW;
      } else {
        infoToSHOW = newinfoToSHOW;
      }
    } else {
      const fechaactual = new Date();
      const fechaTAH = new Date('01-01-2024');
      if (fechaactual > fechaTAH) {
        infoToSHOW = newinfoToSHOW;
      }
      if (fechaactual == fechaTAH) {
        infoToSHOW = newinfoToSHOW;
      }
    }
  }

  let [d, m, y] = getUrlParams().date
    ? getUrlParams()?.date.split('-')
    : moment().tz('Europe/Madrid').format('DD-MM-YYYY');

  return componentLayout.render(
    html`
      <div class="wrap">
        <div class="row">
          <h1 class="esios-pvpc__title">${i18n.t(
            'active_energy_billing_term_of_VBAC'
          )}</h1>
        </div>
        <div class="row">
          <div>
            <div id="pvpcContent">
              <div>
                <div class="esios-toolbar esios-pvpc__toolbar">
                  <div class="esios-toolbar__component">
                    <div class="esios-toolbar__wrapper">

                      <span class="esios-toolbar__items">
                      <button
                        id='buttonModalInfo'
                        type="button"
                        class="esios-toolbar__buttons toolbar-info"
                      ></button>
                      <span
                        class="esios-toolbar__title title-info"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                        >${i18n.t('more_info').toUpperCase()}</span>
                      </span>

                      <span class="esios-toolbar__items">
                      <button
                        id='buttonModalDownload'
                        type="button"
                        class="esios-toolbar__buttons toolbar-share"
                      ></button>
                      <span
                        class="esios-toolbar__title title-share"
                        >${i18n.t('share').toUpperCase()}</span>
                      </span>
                      <span class="esios-toolbar__items">
                        <a href="/${i18n.t('routes.lng')}/${i18n.t(
      'routes.analysis'
    )}/${idex}?compare_indicators=,,&start_date=19-04-2022" target="_blank"
                        class="esios-toolbar__buttons toolbar-analysis"
                      ></a>
                      <span class="esios-toolbar__title title-analysis"
                        >${i18n.t('see_analysis').toUpperCase()}</span>
                      </span>
                      <div id='modalInfo'></div>
                      <div id='modal'></div>
                    </div>
                  </div>
                  </span>
                </div>
                
                </div>
                <div class="esios-pvpc__legends">
                  <div class="esios-pvpc__legend">
                    <div
                      class="esios-pvpc__legend-color esios-pvpc__legend-color--1"
                    ></div>
                    <p id="legendColor1" class="esios-pvpc__legend-text"></p>
                    <p></p>
                  </div>

                  <div class="esios-pvpc__legend">
                    <div
                      class="esios-pvpc__legend-color esios-pvpc__legend-color--2"
                    ></div>
                    <p id="legendColor2" class="esios-pvpc__legend-text"></p>
                    <p></p>
                  </div>

                  <div class="esios-pvpc__legend">
                    <div
                      class="esios-pvpc__legend-color esios-pvpc__legend-color--3"
                    ></div>
                    <p id="legendColor3" class="esios-pvpc__legend-text"></p>
                    <p></p>
                  </div>
                </div>
                <div class="esios-toolbar">
                  <div></div>
                  ${templateToolbarTime('main-timer')}
                </div>
                <div
                  id="pvpcPCBCYM"
                  class="esios-pvpc__chart chari-chart"
                >
                  <div class="spinner-container">
                    <div class="spinner"></div>
                  </div>
                  <div style="display:none" class="esios-pvpc__no-data">${i18n.t(
                    'no-data-available'
                  )}</div>
              </div>

                <div class="esios-pvpc__row esios-pvpc__data-pvpc hidden">
                  <div class="esios-pvpc__info pvpc__main-Legend">
                    <div>
                      <span
                        class="esios-pvpc__legend-circle esios-pvpc__legend-circle--orange"
                      ></span>
                      <span class="esios-pvpc__info-title"
                        >${i18n.t('pcb')}</span
                      >
                    </div>
                    <div>
                      <span id="pricePCB" class="esios-pvpc__value"></span>
                      <span class="esios-pvpc__unit"></span>
                    </div>
                  </div>
                  <div class="esios-pvpc__info pvpc__main-Legend">
                    <div>
                      <span
                        class="esios-pvpc__legend-circle esios-pvpc__legend-circle--blue"
                      ></span>
                      <span class="esios-pvpc__info-title"
                        >${i18n.t('cym')}</span
                      >
                    </div>
                    <div>
                      <span id="priceCYM" class="esios-pvpc__value"></span>
                      <span class="esios-pvpc__unit"></span>
                    </div>
                  </div>
                  <div class="esios-pvpc__info pvpc__second-Legend">
                    <div>
                      <span
                        class="esios-pvpc__legend-circle esios-pvpc__legend-circle--orange"
                      ></span>
                      <span class="esios-pvpc__info-title"
                        >${i18n.t('rate1') + ' ' + i18n.t('rate_a')}</span
                      >
                    </div>
                    <div>
                      <span id="priceGEN" class="esios-pvpc__value"></span>
                      <span class="esios-pvpc__unit"></span>
                    </div>
                  </div>
                  <div class="esios-pvpc__info pvpc__second-Legend">
                    <div>
                      <span
                        class="esios-pvpc__legend-circle esios-pvpc__legend-circle--blue"
                      ></span>
                      <span class="esios-pvpc__info-title"
                        >${i18n.t('rate2') + ' ' + i18n.t('rate_dha')}</span
                      >
                    </div>
                    <div>
                      <span id="priceNOC" class="esios-pvpc__value"></span>
                      <span class="esios-pvpc__unit"></span>
                    </div>
                  </div>
                  <div class="esios-pvpc__info pvpc__second-Legend">
                    <div>
                      <span
                        class="esios-pvpc__legend-circle esios-pvpc__legend-circle--green"
                      ></span>
                      <span class="esios-pvpc__info-title"
                        >${i18n.t('rate3') + ' ' + i18n.t('rate_dhs')}</span
                      >
                    </div>
                    <div>
                      <span id="priceVHC" class="esios-pvpc__value"></span>
                      <span class="esios-pvpc__unit"></span>
                    </div>
                  </div>
                </div>
              </div>
              <h1 class="esios-pvpc__title esios-pvpc__title-is-sm-hidden--pcb">${i18n.t(
                'pcb'
              )}</h1>
              <div class="esios-toolbar esios-toolbar--cym">
                <div></div>
                ${templateToolbarTime('timer')}
              </div>
              <div class="esios-pvpc__row esios-pvpc__row--char">
                <div id="pvpcPCB" class="esios-pvpc__column">
                  <div class="esios-pvpc__chart chari-chart">
                    <div class="spinner-container">
                      <div class="spinner"></div>
                    </div>
                   <div style="display:none" class="esios-pvpc__no-data">${i18n.t(
                     'no-data-available'
                   )}</div>

                  </div>
                  <div class='esios-pvpc__data'>
                    ${infoToSHOW.map(
                      (key, index) =>
                        html` <div class="esios-pvpc__info">
                          <div>
                            <span
                              class="esios-pvpc__legend-circle esios-pvpc__legend-circle--orange${index +
                              1}"
                            ></span>
                            <span class="esios-pvpc__info-title"
                              >${i18n.t(key)}</span
                            >
                          </div>
                          <div>
                            <span
                              id="price${key}PCB"
                              class="esios-pvpc__value"
                            ></span>
                            <span class="esios-pvpc__unit"></span>
                          </div>
                        </div>`
                    )}
                  </div>
                </div>
                <h1 class="esios-pvpc__title  esios-pvpc__title-is-sm-hidden--cym">${i18n.t(
                  'cym'
                )}</h1>
                <div class="esios-toolbar esios-toolbar-is-sm-hidden--cym">
                <div></div>
                ${templateToolbarTime('timer')}
                </div>
                <div id="pvpcCYM" class="esios-pvpc__column">
                  <div class="esios-pvpc__chart chari-chart">
                    <div class="spinner-container">
                      <div class="spinner"></div>
                    </div>
                    <div style="display:none" class="esios-pvpc__no-data">${i18n.t(
                      'no-data-available'
                    )}</div>
                  </div>
                  <div class='esios-pvpc__data'>
                    ${infoToSHOW.map(
                      (key, index) =>
                        html` <div class="esios-pvpc__info">
                          <div>
                            <span
                              class="esios-pvpc__legend-circle esios-pvpc__legend-circle--blue${index +
                              1}"
                            ></span>
                            <span class="esios-pvpc__info-title"
                              >${i18n.t(key)}</span
                            >
                          </div>
                          <div>
                            <span
                              id="price${key}CYM"
                              class="esios-pvpc__value"
                            ></span>
                            <span class="esios-pvpc__unit"></span>
                          </div>
                        </div>`
                    )}
                  </div>
                </div>
              </div>

              <div class="esios-pvpc__grid">
              <div id="pvpcGEN" class="">
                  <div class="esios-pvpc__chart chari-chart">
                    <div class="spinner-container">
                      <div class="spinner"></div>
                    </div>
                    <div style="display:none" class="esios-pvpc__no-data">${i18n.t(
                      'no-data-available'
                    )}</div>
                  </div>
                  <div class='esios-pvpc__data'>
                    ${infoToSHOW.map(
                      (key, index) =>
                        html` <div class="esios-pvpc__info">
                          <div>
                            <span
                              class="esios-pvpc__legend-circle esios-pvpc__legend-circle--orange${index +
                              1}"
                            ></span>
                            <span class="esios-pvpc__info-title"
                              >${i18n.t(key)}</span
                            >
                          </div>
                          <div>
                            <span
                              id="price${key}GEN"
                              class="esios-pvpc__value"
                            ></span>
                            <span class="esios-pvpc__unit"></span>
                          </div>
                        </div>`
                    )}
                  </div>
                </div>

                <div id="pvpcNOC" class="">
                  <div class="esios-pvpc__chart chari-chart">
                    <div class="spinner-container">
                      <div class="spinner"></div>
                    </div>
                    <div style="display:none" class="esios-pvpc__no-data">${i18n.t(
                      'no-data-available'
                    )}</div>
                  </div>
                  <div class='esios-pvpc__data'>
                    ${infoToSHOW.map(
                      (key, index) =>
                        html` <div class="esios-pvpc__info">
                          <div>
                            <span
                              class="esios-pvpc__legend-circle esios-pvpc__legend-circle--blue${index +
                              1}"
                            ></span>
                            <span class="esios-pvpc__info-title"
                              >${i18n.t(key)}</span
                            >
                          </div>
                          <div>
                            <span
                              id="price${key}NOC"
                              class="esios-pvpc__value"
                            ></span>
                            <span class="esios-pvpc__unit"></span>
                          </div>
                        </div>`
                    )}
                  </div>
                </div>

                <div id="pvpcVHC" class="">
                  <div class="esios-pvpc__chart chari-chart">
                    <div class="spinner-container">
                      <div class="spinner"></div>
                    </div>
                    <div style="display:none" class="esios-pvpc__no-data">${i18n.t(
                      'no-data-available'
                    )}</div>
                  </div>
                  <div class='esios-pvpc__data'>
                    ${infoToSHOW.map(
                      (key, index) =>
                        html` <div class="esios-pvpc__info">
                          <div>
                            <span
                              class="esios-pvpc__legend-circle esios-pvpc__legend-circle--green${index +
                              1}"
                            ></span>
                            <span class="esios-pvpc__info-title"
                              >${i18n.t(key)}</span
                            >
                          </div>
                          <div>
                            <span
                              id="price${key}VHC"
                              class="esios-pvpc__value"
                            ></span>
                            <span class="esios-pvpc__unit"></span>
                          </div>
                        </div>`
                    )}
                  </div>
                </div>
              </div>

            </div>
          </div>
          
          <div class="esios-pvpc__wrapper-center">
            <div class="esios-pvpc__wrapper">
              <div class="row">
                <hr class="is-xs-hidden" />
                <div class="esios-pvpc__subcontent">
                  <a
                    href="/${i18n.t('routes.lng')}/${i18n.t('routes.lumios')}"
                    class="esios-pvpc__subcontent-calculadora"
                  >
                    <h2 class="esios-pvpc__subcontent-title">
                      ${i18n.t('calculadora')}
                    </h2>
                    <p class="esios-pvpc__subcontent-text">
                      ${i18n.t('calculadora_highlight')}
                    </p>
                  </a>
                </div>
              </div>
              <div class="row">
                <hr class="is-xs-hidden" />
                <div class="esios-pvpc__subcontent">
                  <a
                    href="/${i18n.t('routes.lng')}/${i18n.t(
      'routes.analysis'
    )}/1739?compare_indicators=1001"
                    class="esios-pvpc__subcontent-autoconsumo"
                  >
                    <h2 class="esios-pvpc__subcontent-title">
                      ${i18n.t('autoconsumo')}
                    </h2>
                    <p class="esios-pvpc__subcontent-text">
                      ${i18n.t('autoconsumo_highlight')}
                    </p>
                  </a>
                </div>
              </div>
              <div class="row">
                <hr class="is-xs-hidden" />
                <div class="esios-pvpc__subcontent">
                  <a
                    href="https://facturaluz2.cnmc.es/"
                    class="esios-pvpc__subcontent-simulador"
                    target="_blank"
                  >
                    <h2 class="esios-pvpc__subcontent-title">
                      ${i18n.t('simulator')}
                    </h2>
                    <p class="esios-pvpc__subcontent-text">
                      ${i18n.t('simulator_highlight')}
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    `,
    {
      showBanner: false,
      showFooterImage: true,
      select: true,
      showSelect: true,
    }
  );
};

export { templatepvpc };
