import i18n from 'i18next';
import { html } from 'lit-html';
import moment from 'moment';
import { store } from '../../../app.init';
import cIf from '../../../shared/components/conditional';
import DatePicker from '../../../shared/components/datepicker/datepicker';
import Select from '../../../shared/components/selects/select';
import { setAnalysis } from '../../../state/actions';
import {
  getMonths,
  getYears,
  getYearsBase,
  getYearsPlus,
} from '../../../utils/dateFormat';
import { sRender } from '../../../utils/render';
import { getState, getSubscriber } from '../../../utils/state';
import { getUrlParams, setUrlParam } from '../../../utils/url';
import { getCompareDate } from './analysis-form-period';
import { ANALYSIS_GROUP_ID } from './analysis-form.service';

const AnalysisFormCompareTemp = ({ indicator }) => {
  let { compare_start_date, start_date, end_date, groupby } = getUrlParams();
  let compare_end_date = getCompareDate(
    start_date,
    end_date,
    compare_start_date,
    'end_date'
  );
  let [dStart, mStart, yStart] = compare_start_date.split(/-|T|:|%3A/);
  let [dEnd, mEnd, yEnd] = compare_end_date.split(/-|T|:|%3A/);

  const months = getMonths();
  const years = getYearsBase();
  const yearsArrEnd = getYearsPlus();
  let groupBy = ANALYSIS_GROUP_ID[groupby];

  if (!groupBy && indicator) {
    let timeIdVal = indicator.tiempo[0].id;
    if (timeIdVal === 154) {
      timeIdVal = 225;
    }
    groupBy = timeIdVal;
  }

  getSubscriber(handleGroup);

  return html`
    <div class="analysis-compare-temp">
      <div
        id="analysis-compare-temp-container"
        class="analysis-compare-temp-container esios-select esios-date-link"
      >
        ${CompareContainer()}
      </div>
    </div>
  `;

  function CompareContainer() {
    const { analysis: state } = getState();
    compare_start_date = state.compare_start_date || compare_start_date;
    compare_end_date = getCompareDate(
      state.start_date,
      state.end_date,
      compare_start_date,
      'end_date'
    );
    [dStart, mStart, yStart] = compare_start_date.split(/-|T|:|%3A/);
    [dEnd, mEnd, yEnd] = compare_end_date.split(/-|T|:|%3A/);

    const showCalendar = [
      ANALYSIS_GROUP_ID.minutes5,
      ANALYSIS_GROUP_ID.minutes10,
      ANALYSIS_GROUP_ID.minutes15,
      ANALYSIS_GROUP_ID.hour,
      ANALYSIS_GROUP_ID.day,
    ];
    const showMonth = [ANALYSIS_GROUP_ID.month];
    const showYear = [ANALYSIS_GROUP_ID.month, ANALYSIS_GROUP_ID.year];

    return html`
      <div
        class=${groupBy === ANALYSIS_GROUP_ID.year
          ? 'esios-date-link__row esios-date-link__row--year'
          : 'esios-date-link__row'}
      >
        <span class="esios-date-link__label">${i18n.t('start')}</span>
        <div
          class=${groupBy === ANALYSIS_GROUP_ID.month
            ? 'esios-date-link__date-wrapper esios-date-link__date-wrapper--month'
            : 'esios-date-link__date-wrapper'}
        >
          ${cIf(showCalendar.includes(groupBy), DatePicker, {
            id: 'compare-start-date',
            isAnalysis: true,
            value: `${dStart}/${mStart}/${yStart}`,
            onSelected: handleDate('compare_start_date', compare_start_date),
          })}
          ${cIf(showMonth.includes(groupBy), Select, {
            values: months,
            selected: months[Number(mStart - 1)],
            onClick: handleMonth('compare_start_date', compare_start_date),
          })}
          <div class="esios-date-link__icon-cal"></div>
        </div>
        <div class="esios-date-link__time-wrapper">
          ${cIf(showYear.includes(groupBy), Select, {
            values: years,
            selected: yStart,
            onClick: handleYear('compare_start_date', compare_start_date),
          })}
        </div>
      </div>
      <div
        class=${groupBy === ANALYSIS_GROUP_ID.year
          ? 'esios-date-link__row esios-date-link__row--year'
          : 'esios-date-link__row'}
      >
        <span class="esios-date-link__label">${i18n.t('end')}</span>
        <div
          class=${groupBy === ANALYSIS_GROUP_ID.month
            ? 'esios-date-link__date-wrapper esios-date-link__date-wrapper--month'
            : 'esios-date-link__date-wrapper'}
        >
          ${cIf(showCalendar.includes(groupBy), DatePicker, {
            id: 'compare-end-date',
            isAnalysis: true,
            value: `${dEnd}/${mEnd}/${yEnd}`,
            onSelected: handleDate('compare_end_date', compare_end_date),
            disabled: 'disabled',
          })}
          ${cIf(showMonth.includes(groupBy), Select, {
            values: months,
            selected: months[Number(mEnd - 1)],
            onClick: handleMonth('compare_end_date', compare_end_date),
            disabled: true,
          })}
          <div class="esios-date-link__icon-cal"></div>
        </div>
        <div class="esios-date-link__time-wrapper">
          ${cIf(showYear.includes(groupBy), Select, {
            values: years,
            selected: yEnd,
            onClick: handleYear('compare_end_date', compare_end_date),
            disabled: true,
          })}
        </div>
      </div>
    `;
  }

  function handleGroup() {
    const type = getUrlParams().groupby;
    groupBy = ANALYSIS_GROUP_ID[type];
    sRender(CompareContainer, 'analysis-compare-temp-container');
  }

  function handleDate(type, chosenDate) {
    // A value object will be displayed everytime we change the date.
    return ({ day, month, year }) => {
      const date = `${day}-${month}-${year}`;
      let newDate = chosenDate.split('T');
      newDate[0] = date;
      newDate = newDate.join('T');

      setUrlParam(type, newDate);

      if (type === 'compare_start_date') {
        const endDate = new Date(
          moment($('#end-date').val(), 'DD / MM / YYYY')
        );
        const startDate = new Date(
          moment($('#start-date').val(), 'DD / MM / YYYY')
        );
        const difference = endDate.getTime() - startDate.getTime();
        const compareEnd = new Date(
          new Date(
            moment($('#compare-start-date').val(), 'DD / MM / YYYY')
          ).getTime() + difference
        );

        // ! CHECK IF THIS AFFECT TIME SELECT // SETTING 23:55 by default
        store.dispatch(
          setAnalysis({
            [type]: newDate,
            compare_end_date: moment(compareEnd)
              .hours('23')
              .minutes('55')
              .format('DD-MM-YYYYTHH:mm'),
          })
        );
      }
    };
  }

  function handleMonth(type, chosenDate) {
    // Here we can manage the current month selected
    return ({ index }) => {
      let newDate = chosenDate.split('-');
      const newMonth = index + 1 < 10 ? '0' + (index + 1) : '' + (index + 1);
      newDate[1] = newMonth;
      newDate = newDate.join('-');
      setUrlParam(type, newDate);
      compare_end_date = getCompareDate(
        start_date,
        end_date,
        newDate,
        'start_end'
      );
      store.dispatch(
        setAnalysis({
          [type]: newDate,
          compare_end_date,
        })
      );
    };
  }

  function handleYear(type, chosenDate) {
    // Here we can manage the current year selected
    return ({ label }) => {
      let [day, month, , time] = chosenDate.split(/T|-/);
      const newDate = `${day}-${month}-${label}T${time}`;
      compare_end_date = getCompareDate(
        start_date,
        end_date,
        newDate,
        'end_date'
      );
      store.dispatch(
        setAnalysis({
          [type]: newDate,
          compare_end_date,
        })
      );
      setUrlParam(type, newDate);
    };
  }
};

export default AnalysisFormCompareTemp;
