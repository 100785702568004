'use strict';

import { render } from 'lit-html';
import { templatePaginator } from './paginator.template.js';

let componentPaginator = (function () {
  let renderSuccess = false;

  let renderComponent = (pagination, onPageChange, idParent) => {
    try {
      // Render the template to the document
      render(
        // Define a template
        templatePaginator(pagination, onPageChange),
        document.querySelector('#' + idParent)
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = () => {};

  let init = (pagination, onPageChange, idParent) => {
    renderComponent(pagination, onPageChange, idParent);
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: (pagination, onPageChange, idParent) =>
      init(pagination, onPageChange, idParent),
  };
})();

export { componentPaginator };
