'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';

let templateerror = (context, handlers) => {
  return html`
    <div class="esios-error">
      <h1 class="esios-error__title">LO SENTIMOS, ALGO FUE MAL</h1>
      <p class="esios-error__text">
        Para más información por favor visite nuestro
        <a href="/es/mapa-del-sitio"> mapa del sitio </a> o utilice el recurso
        de búsqueda.
      </p>
      <p class="esios-error__text">
        ${html([
          "También puede contactar con nosotros a través de <html><a href='mailto:soportesios@ree.es?subject=Consulta desde la Web Publica'>soportesios@ree.es</a></html>",
        ])}
      </p>
      <a class="btn" href="/es">VOLVER A INICIO</a>

      <br />
      <br />
      <br />

      <h1 class="esios-error__title">
        ${i18n.t('We are sorry, but something went wrong')}
      </h1>
      <p class="esios-error__text">
        ${i18n.t('For more information please visit our')}
        <a href="/en/sitemap"> ${i18n.t('sitemap')}</a> ${i18n.t(
          'or use the search resource.'
        )}
      </p>
      <p class="esios-error__text">
        ${html([
          " Please contact us via <html><a href='mailto:soportesios@ree.es?subject=Request from public Website'>soportesios@ree.es</a></html>",
        ])}
      </p>
      <a class="btn" href="/en">BACK TO HOME</a>
    </div>
  `;
};

export { templateerror };
