'use strict';
import { html } from 'lit-html';
import backImage from '../../assets/images/header-default.jpg';
import { componentLayout } from '../../components/layout/layout.component';

let templateEnlace = async (context, handlers, contactData, tempHTML) => {
  const { title } = contactData;

  return componentLayout.render(
    html`
      <div class="esios-link">
        <div class="grid-xs-24">
          <h1 class="esios-link__title">${title || ''}</h1>
          ${tempHTML}
        </div>
      </div>
    `,
    {
      image: backImage,
      showFooterImage: true,
    }
  );
};

export { templateEnlace };
