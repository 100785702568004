'use strict';
import { html } from 'lit-html';
import i18n from 'i18next';

// EXPLICADO COMO USAR EL COMPONENTE EN EL FICHERO buttons.component
let templateButtons = (
  buttons,
  { downloadJSON, downloadCSV, downloadExcel, deleteInput, showDownloadOptions }
) => {
  return html`
    <div class="esios-btn__container">
      ${buttons.map(
        ({
          title,
          action,
          data,
          handler,
          docName,
          id,
          dataCSV,
          dataExcel,
          csvName,
          dataJSON,
          jsonName,
        }) => {
          if (action == 'print') {
            return html`<a
              href="#"
              @click=${(ev) => {
                ev.preventDefault();
                window.print();
              }}
              class="esios-btn esios-btn__icon esios-btn__icon--print"
              >${i18n.t('print')}</a
            >`;
          }

          if (action == 'handler') {
            return html`<a
              href="#"
              @click=${() => handler(data)}
              class="esios-btn esios-btn__icon esios-btn__icon--download"
              >${title}</a
            >`;
          }

          if (action === 'exportJSON') {
            return html`<a
              href="#"
              @click=${(ev) => {
                ev.preventDefault();
                downloadJSON(data, docName);
              }}
              class="esios-btn esios-btn__icon esios-btn__icon--download"
              >${i18n.t('export_to_json')}</a
            >`;
          }

          if (action === 'exportCSV') {
            return html`<a
              href="#"
              @click=${(ev) => {
                ev.preventDefault();
                downloadCSV(data, docName);
              }}
              class="esios-btn esios-btn__icon esios-btn__icon--download"
              >${i18n.t('export_to_csv')}</a
            >`;
          }

          if (action === 'exportExcel') {
            return html`<a
              href="#"
              @click=${(ev) => {
                ev.preventDefault();
                downloadExcel(data, null, docName, 6);
              }}
              class="esios-btn esios-btn__icon esios-btn__icon--download"
            >
              ${i18n.t('export_to_excel')}</a
            >`;
          }

          if (action == 'onClick') {
            return html`<a @click=${() => deleteInput()} class="esios-btn">
              ${i18n.t('delete')}</a
            >`;
          }

          if (action == 'exports') {
            return html`<a
                id="${id}"
                @click=${() => showDownloadOptions()}
                class="esios-btn esios-btn__icon esios-btn__icon--download"
              >
                ${title}</a
              >
              <div class="options_select">
                <div
                  class="opt-ancle"
                  @click=${(ev) => {
                    ev.preventDefault();
                    downloadCSV(dataCSV, csvName);
                  }}
                >
                  CSV
                </div>
                <div
                  class="opt-ancle"
                  @click=${(ev) => {
                    ev.preventDefault();
                    downloadJSON(dataJSON, jsonName);
                  }}
                >
                  JSON
                </div>
                <div
                  class="opt-ancle"
                  @click=${(ev) => {
                    ev.preventDefault();
                    downloadExcel(dataExcel || dataCSV, null, csvName, 6);
                  }}
                >
                  EXCEL
                </div>
              </div> `;
          }

          if (action == 'download') {
            return html`<a
              href="${data}"
              class="esios-btn esios-btn__icon esios-btn__icon--download"
              target="_blank"
            >
              ${title}</a
            >`;
          } else {
            return html`<a href="${data}" class="esios-btn">${title}</a>`;
          }
        }
      )}
    </div>
  `;
};

export { templateButtons };
