'use strict';
import { html } from 'lit-html';
import moment from 'moment-timezone';
import newsIcon from '../../assets/images/news-icon.svg';
import moreIcon from '../../assets/images/more-info.svg';

import 'tiny-slider/dist/tiny-slider.css';
import i18next from 'i18next';

let templateSlider = (showSlider, contents) => {
  return html`
    <div
      class="esios-slider__wrapper"
      style="${showSlider ? '' : 'display: none'}"
    >
      <button
        class="esios-slider__prev-button button"
        style="${contents.length <= 1 ? 'display: none' : ''}"
      ></button>
      <h3
        class="heading"
        style="${contents.length <= 0 ? 'display: none' : ''}"
      >
        <img
          src="${newsIcon}"
          alt=""
          style="${contents.length <= 0 ? 'display: none' : ''}"
        />
        ${i18next.t('last_minute')}
      </h3>
      <div
        class="esios-slider__articles"
        style="${contents.length <= 0 ? 'display: none' : ''}"
      >
        <div class="carouselSlider">
          ${contents.map((notice) => {
            return html` <article class="article">
              <time datetime="${notice.published_at}"
                >${moment(notice.published_at, 'YYYY/MM/DDTHH:mm').format(
                  'DD/MM/YYYY'
                )}</time
              >
              <h2 class="title">${notice.title}</h2>
              <a
                href="/${i18next.t('routes.lng')}/${i18next.t('routes.umms')}"
                class="arrow"
              >
                <img src="${moreIcon}" alt="" />
              </a>
            </article>`;
          })}
        </div>
        <div class="esios-slider__progress-desktopBar-wrapper">
          <div
            class="esios-slider__progress-desktopBar"
            style="${contents.length <= 0 ? 'display: none' : ''}"
          ></div>
        </div>
      </div>
      <div
        class="esios-slider__next-button button"
        style="${contents.length <= 1 ? 'display: none' : ''}"
      ></div>
    </div>
    <div class="esios-slider__progress-mobileBar"></div>
  `;
};

export { templateSlider };
