const actions = {
  SET_BUSCADOR: {
    type: 'SET_BUSCADOR',
  },
  SET_NAME: {
    type: 'SET_NAME',
  },
  SET_AGE: {
    type: 'SET_AGE',
  },

  SET_PATHNAME: {
    type: 'SET_PATHNAME',
  },

  SET_SHOW_RESPONSIVE_MENU: {
    type: 'SET_SHOW_RESPONSIVE_MENU',
  },

  SET_SELECT: {
    type: 'SET_SELECT',
  },

  SET_SHOW_SELECT: {
    type: 'SET_SHOW_SELECT',
  },

  SET_SHOW_SHARE: {
    type: 'SET_SHOW_SHARE',
  },

  SET_SELECT_TOGGLE: {
    type: 'SET_SELECT_TOGGLE',
  },

  SET_CURRENT_LANG: {
    type: 'SET_CURRENT_LANG',
  },

  SET_TOOL_GRAPH: {
    type: 'SET_TOOL_GRAPH',
  },
  SET_ROWS_TO_SHOW_USERS: {
    type: 'SET_ROWS_TO_SHOW_USERS',
  },
  SET_HOME_OTHER_INDICATORS_TIME: {
    type: 'SET_HOME_OTHER_INDICATORS_TIME',
  },
  SET_MYP_VOLUME_HOUR: {
    type: 'SET_MYP_VOLUME_HOUR',
  },
  SET_MYP_UNITARY_COST_HOUR: {
    type: 'SET_MYP_UNITARY_COST_HOUR',
  },
  SET_MYP_TABLE_HOUR: {
    type: 'SET_MYP_TABLE_HOUR',
  },
  SET_ROWS_TO_SHOW_USERS: {
    type: 'SET_ROWS_TO_SHOW_USERS',
  },
  SET_ROUTE_ID: {
    type: 'SET_ROUTE_ID',
  },
  SET_ANALYSIS: {
    type: 'SET_ANALYSIS',
  },
  SET_INT_EXCHANGES: {
    type: 'SET_INT_EXCHANGES',
  },
};

const setAnalysis = (payload) => {
  return {
    type: actions.SET_ANALYSIS.type,
    payload,
  };
};

const setInterExchanges = (payload) => {
  return {
    type: actions.SET_INT_EXCHANGES.type,
    payload,
  };
};

const setNameAction = (payload) => {
  return {
    type: actions.SET_NAME.type,
    payload,
  };
};

const setRowsToShowUsers = (payload) => {
  return {
    type: actions.SET_ROWS_TO_SHOW_USERS.type,
    payload,
  };
};

const setPathname = (payload) => {
  return {
    type: actions.SET_PATHNAME.type,
    payload,
  };
};

const setShowResponsiveMenuAction = (payload) => {
  return {
    type: actions.SET_SHOW_RESPONSIVE_MENU.type,
    payload,
  };
};

const setSelectedOption = (payload) => {
  return {
    type: actions.SET_SELECT.type,
    payload,
  };
};

const setSelectState = (payload) => {
  return {
    type: actions.SET_SHOW_SELECT.type,
    payload,
  };
};

const setShareState = (payload) => {
  return {
    type: actions.SET_SHOW_SHARE.type,
    payload,
  };
};

const setToggleSelect = (payload) => {
  return {
    type: actions.SET_SELECT_TOGGLE.type,
    payload,
  };
};

const setCurrentLang = (payload) => {
  return {
    type: actions.SET_CURRENT_LANG.type,
    payload,
  };
};

const setToogleGraph = (payload) => {
  return {
    type: actions.SET_TOOL_GRAPH.type,
    payload,
  };
};

const setHomeChangeOtherIndicatorsTime = (payload) => {
  return {
    type: actions.SET_HOME_OTHER_INDICATORS_TIME.type,
    payload,
  };
};

const setMyPChangeVolumeHour = (payload) => {
  return {
    type: actions.SET_MYP_VOLUME_HOUR.type,
    payload,
  };
};
const setMyPChangeUnitaryCostHour = (payload) => {
  return {
    type: actions.SET_MYP_UNITARY_COST_HOUR.type,
    payload,
  };
};

const setMyPChangeTableHour = (payload) => {
  return {
    type: actions.SET_MYP_TABLE_HOUR.type,
    payload,
  };
};

const setRouteId = (payload) => {
  return {
    type: actions.SET_ROUTE_ID.type,
    payload,
  };
};

export {
  actions,
  /* setBuscador, */
  setNameAction,
  setShowResponsiveMenuAction,
  setPathname,
  setSelectedOption,
  setSelectState,
  setShareState,
  setToggleSelect,
  setCurrentLang,
  setToogleGraph,
  setRowsToShowUsers,
  setRouteId,
  setHomeChangeOtherIndicatorsTime,
  setMyPChangeVolumeHour,
  setMyPChangeUnitaryCostHour,
  setMyPChangeTableHour,
  setAnalysis,
  setInterExchanges,
};
