'use strict';

import { getSplittedRoute } from '../../utils/url';
import { api } from '../../utils/api-interceptor.js';
import i18n from 'i18next';

let ServicesDocument = () => {
  const getDataOfFile = () => {
    const urlApi = `documentations?order=published&locale=${i18n.t(
      'routes.lng'
    )}`;
    return api(urlApi);
  };

  return {
    getDataOfFile: () => getDataOfFile(),
  };
};

export default ServicesDocument;
