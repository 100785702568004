'use strict';

/**
 * @description Dashboard Component
 * @returns {Object} Devuelve la instancia del componente
 */

import i18n from 'i18next';
import { store } from '../../app.init.js';
import { api } from '../../utils/api-interceptor.js';
import { servicesEnlace } from './enlace.services.js';
import { templateEnlace } from './enlace.template.js';

let componentEnlace = (function () {
  let context = {};
  let handlers = {};
  let renderSuccess = false;

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    document.title = `${i18n.t('other_links')} | ${i18n.t('page_subtitle')}`;
    context.name = state.exampleReducer.name;
  };

  let renderComponent = async () => {
    const state = store.getState();
    try {
      templateEnlace(context, handlers, []);

      await servicesEnlace
        .init(state.exampleReducer.currentLang)
        .then((data) => {
          let tempData = { ...data };
          let tempHTML = processImages(tempData.body);
          setTimeout(() => {
            templateEnlace(context, handlers, tempData, tempHTML);
          }, 100);

          document.querySelector('.esios-layout').classList.add('layout-links');
          return data;
        });

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentEnlace };

export const processImages = function (str) {
  let parser = new DOMParser();
  let doc = parser.parseFromString(str, 'text/html');

  doc.querySelectorAll('img')?.forEach(async (image) => {
    if (image.src.includes('api.esios.ree.es')) {
      let getURL = image.src.split('api.esios.ree.es/')[1];
      image.src = await api(getURL, { isPhoto: true });
    }
  });
  return doc.querySelector('.other-links');
};
