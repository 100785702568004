'use strict';
import i18next from 'i18next';
import { html } from 'lit-html';
import backImage from '../../assets/images/header-mercados-precios.jpg';
import { componentLayout } from '../../components/layout/layout.component';
import { templateBoxDocument } from '../../shared/components/box-documents/box-documents.template';
import { templateBoxMarket } from '../../shared/components/box-market/box-market.template';
import { templateToolbarTime } from '../../shared/components/toolbarTime/toolbarTime.template';
import { templateBoxMarketColumn } from '../../shared/components/box-market-column/box-market-column.template';

let templateMercadosPrecios = (listMarket, documents, lang, buttons) => {
  return componentLayout.render(
    html`
      <div class="esios-market-prices">
        <div class="esios-market-prices__container wrap">
          <div class="row mb-5">
            <div
              id="mypVolumenWidgetView"
              class="mypVolumenWidgetView col-xs-5"
            >
              <h1 class="esios-market-prices__title">
                ${i18next.t('programming_in_Production_Market')}
              </h1>
              <div class="esios-toolbar col-12">
                <div class="esios-toolbar1"></div>
                <div id="mypVolumenWidgetViewSelector"></div>
              </div>
              <div id="spinner6" class="spinner-container">
                <div class="overlay"></div>
                <div class="spinner"></div>
              </div>
              <div id="widget6">
                <h2 class="esios-market-prices__subtitle">
                  ${i18next.t('volume')} MWh
                </h2>
                <div class="row">
                  <div id="mypGraphic1" class="chari-chart col-xs-6"></div>
                  <div
                    id="mypNoDataRect1"
                    class="esios-home__no-data-container_cuadrado hidden"
                    style="margin: 95px 22px; width: 220px;"
                  >
                    ${i18next.t('no-data-available')}
                  </div>
                  <div class="volume-leyends offset-1 col-xs-5"></div>
                </div>
                <div class="row mt-2 mb-2">
                  <div class="col-sm-12">
                    <div class="widget-summary">
                      <div
                        class="widget-summary-item energia-total-mercado-de-produccion"
                      >
                        <div class="label">${i18next.t('total_volume')}</div>
                        <div class="value">
                          <div
                            id="total-volume"
                            class="is-big-number is-bold"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="offset-md-1 col-xs-6 mypCosteWidgetView"
              id="mypCosteWidgetView"
            >
              <h1 class="esios-market-prices__title">
                ${i18next.t('energy_final_price')}
              </h1>
              <div class="esios-toolbar col-12">
                <div class="esios-toolbar2"></div>

                <div id="mypCosteWidgetViewSelector"></div>
              </div>
              <h2 class="esios-market-prices__subtitle">
                ${i18next.t('unitary_cost')} €/MWh
              </h2>
              <div id="spinner7" class="spinner-container">
                <div class="overlay"></div>
                <div class="spinner"></div>
              </div>
              <div id="widget7" style="display: flex;flex-direction: column;">
                <div class="cost" style="display: flex">
                  <div style="display: flex">
                    <div
                      id="mypGraphic2"
                      class="chari-chart large col-xs-6"
                    ></div>
                    <div
                      id="mypNoDataRect2"
                      class="esios-home__no-data-container_cuadrado hidden"
                      style="margin: 130px 20px"
                    >
                      ${i18next.t('no-data-available')}
                    </div>
                  </div>
                  <div class="volume-leyends" style="margin-left: -235px"></div>
                </div>
                <div class="col-xs-24 col-sm-24 mt-5">
                  <div class="widget-summary table-summary"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="mp-second-graphs row mb-5 border-top">
            <div
              id="mypPrecioWidgetView"
              class="mypPrecioWidgetView col-sm-6 col-xs-6"
            >
              <h1 class="esios-market-prices__title">
                ${i18next.t('deviations_cost_in_real_time')}
              </h1>
              <div class="esios-toolbar col-12" style="z-index: 1">
                <div class="esios-toolbar3"></div>
                ${templateToolbarTime('volumen')}
              </div>
              <div id="spinner8" class="spinner-container">
                <div class="overlay"></div>
                <div class="spinner"></div>
              </div>
              <div id="widget8">
                <div id="mypGraphic3" class="chari-chart aspect myp-aspect">
                  <div
                    id="mypNoDataRect3"
                    class="esios-home__no-data-container_cuadrado hidden"
                  >
                    ${i18next.t('no-data-available')}
                  </div>
                </div>
                <ul class="esios-listEnergyItems__list"></ul>
              </div>
            </div>
            <div
              class="col-sm-6 col-xs-6 mypFacturacionWidgetView"
              id="mypFacturacionWidgetView"
            >
              <h1 class="esios-market-prices__title">
                ${i18next.t('active_energy_billing_term_of_VBAC')}
              </h1>
              <div class="esios-toolbar col-12">
                <div class="esios-toolbar4"></div>
                ${templateToolbarTime('volumen')}
              </div>
              <div id="spinner9" class="spinner-container">
                <div class="overlay"></div>
                <div class="spinner"></div>
              </div>
              <div id="widget9">
                <div id="mypGraphic4" class="chari-chart aspect myp-aspect">
                  <div
                    id="mypNoDataRect4"
                    class="esios-home__no-data-container_cuadrado hidden"
                  >
                    ${i18next.t('no-data-available')}
                  </div>
                </div>
                <ul class="esios-listEnergyItems__list">
                  <div class="row"></div>
                </ul>
              </div>

              <div class="col-xs-24 col-sm-24 mt-5"></div>
            </div>
          </div>

          <div class="mp-tree-graphs row mb-5 border-top">
            <div
              class="col-sm-12 col-xs-6 mypErniWidgetView"
              id="mypErniWidgetView"
            >
              <h1 class="esios-market-prices__title">${i18next.t('erni')}</h1>
              <div class="esios-toolbar col-12">
                <div class="esios-toolbar6"></div>
              </div>
              <div id="spinner42" class="spinner-container">
                <div class="overlay"></div>
                <div class="spinner"></div>
              </div>
              <div id="widget42">
                <div id="mypGraphic5" class="chari-chart aspect myp-aspect">
                  <div
                    id="mypNoDataRect5"
                    class="esios-home__no-data-container_cuadrado hidden"
                  >
                    ${i18next.t('no-data-available')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="myp__modals"></div>
    `,
    {
      text: '',
      image: backImage,
      showBanner: true,
    },
    html``,
    {
      title: i18next.t('more'),
      subcontent: html`
      <ul class="esios-also__list row" id="more-myp"></ul>

        <!----->

        <div id="document-list-myp"></div>

        <!----->
        <div class="esios-documents">
          <div class="grid-xs-24 grid-xs-24--gc documents-container">
            <div class="mod-documents mod-documents--gc">
              ${templateBoxDocument(documents, buttons)}
            </div>
            <div class="mod-documents mod-documents--gc">
              ${templateBoxMarketColumn(listMarket)}
              <div class="mod-buttons mod-buttons--gc">
              </br>
              ${
                lang == 'es'
                  ? html` <a
                      class="mod-buttons__btn"
                      href="https://www.ree.es/es/clientes"
                      target="_blank"
                      >${i18next.t('gde_website_text')}</a
                    >`
                  : ''
              }
              </div>
            </div>
          </div>
        </div>
      `,
    }
  );
};

export { templateMercadosPrecios };
