'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';
import headerStatic from '../../assets/images/header-gd.jpg';
import imageWorld from '../../assets/images/imgWorld.png';
import { componentLayout } from '../../components/layout/layout.component';
import { templateBoxDocument } from '../../shared/components/box-documents/box-documents.template';
import Select from '../../shared/components/selects/select';
import { templateToolbarTime } from '../../shared/components/toolbarTime/toolbarTime.template';
import { gestionDemandaTabContent } from './tab.content.js';
let templateGestionDemanda = (context, handler, documents, buttonsDoc) => {
  let selectDetails = {
    disabled: false,
    values: [
      { label: i18n.t('ccaa'), value: 'ccaa' },
      { label: i18n.t('sectors'), value: 'sector' },
    ],
    selected: i18n.t('ccaa'),
    onClick: handler.changeTime,
  };
  return componentLayout.render(
    html`
      <div class="esios-gd" id="gd">
        <div class="esios-gd__wrap">
          <div class="row esios-gd__row--content0">
            <h1 class="esios-gd__main-title">
              ${i18n.t('general_autcion2')}
            </h1>
            <div class="grid-xs-24 grid-sm-16">
              <div class="esios-toolbar">
                <!-- toolbar -->
                <div class="esios-toolbar5"></div>
              </div>
            </div>
            <div id="tabwithcontent1"></div>
            ${['1', '2', '3'].map((element) => {
              return html` <div
                id="tab1-gd-content${element}"
                class="tab1-gd-content tab__content"
              ></div>`;
            })}
          </div>
          <div id="accordion1" class="esios-accordion">
            <div id="">
              ${i18n.t('general_information').toLocaleUpperCase()}
            </div>
            <p>${html([gestionDemandaTabContent.returnTabContent(1)])}</p>

            <div id="">
              ${i18n.t('incoming_auctions').toLocaleUpperCase()}
            </div>
            <p>${html([gestionDemandaTabContent.returnTabContent(2)])}</p>

            <div id="">${i18n.t('tab3-gd-title').toLocaleUpperCase()}<br>
            ${html([gestionDemandaTabContent.returnTabContent('3-1')])}</div>
            <p>${html([gestionDemandaTabContent.returnTabContent('3-2')])}</p>
          </div>
          <div class="esios-gd__row esios-gd__row--content1 esios-gd__layoutPadding">
            <!-- COLUMNA IZQUIERDA -->
            <div class="esios-gd__col1 col-xs-9">
              <div class="esios-gd__widget widget-gd-demanda" id="gdDemandaWidgetView">
                <div class="esios-gd__row">
                  <div class="grid-xs-24 grid-sm-24">
                    <h1 class="esios-gd__main-title esios-gd__main-title--int">
                      ${i18n.t('interruptible-demand')}
                    </h1>
                    <div class="esios-gd__widget-toolbar">
                      <div class="esios-toolbar">
                        <!-- toolbar -->
                        <div class="esios-toolbar1"></div>
                        ${templateToolbarTime('timer1')}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="esios-gd__row esios-gd__row--left" id="gd-first">
                  <div class="esios-gd__row-left_1 col-xs-3">
                    <div class="esios-gd__widget-summary gd-summary" id="indicadores">
                      <div class="esios-gd__widget-summary-item esios-gd__is-number--first">
                        <div class="esios-gd__value">
                          <div class="esios-gd__is-number">
                            <div class="value"></div>
                            <small class="esios-gd__unit esios-gd__unit--first">MW</small>
                          </div>
                        </div>
                        <div class="esios-gd__label"></div>
                      </div>
                      <div class="esios-gd__widget-summary-item esios-gd__is-number--second">
                        <div class="esios-gd__value">
                          <div class="esios-gd__is-number">
                            <div class="value"></div>
                            <span class="esios-gd__unit">tCO2</span>
                          </div>
                        </div>
                        <div class="esios-gd__label"></div>
                      </div>
                    </div>
                  </div>
      
                  <div id="dmGraphic" class="col-xs-9 row">
                    <div class="no-data-graphic-demand"></div>
                    <div id="dmNoDataRect" class="esios-home__no-data-container_cuadrado hidden" style="margin: 225px 130px">
                      ${i18n.t('no-data-available')}
                    </div>
                    <div id="spinner22" class="spinner-container">
                      <div class="spinner"></div>
                    </div>
                    <div id="chari-chart" class="chari-chart demanda">
                    </div>
                    <div id="widgetData" class="grid-xs-24">
                      <div class="esios-home__widget-legend highlighted-legend widget-legend visual-legend in-line">
                        <ul></ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
      
              <hr />
      
              <!-- INTERRUMPIBILIDAD -->
      
              <div id="gdInterrumpibilidadWidgetView" class="esios-gd__widget-gd-interrumpibilidad widget-34">
                <div class="esios-gd__row esios-gd__row-interr">
                  <div class="grid-xs-24 grid-sm-8 esios-gd__interr-col-left">
                    <div class="esios-gd__widget-custom">
                      <img alt="${i18n.t(
                        'non_peninsular_systems'
                      )}" src=${imageWorld} />
                    </div>
                  </div>
      
                  <div class="esios-gd__interr-right">
                    <div class="grid-xs-24 grid-sm-16 esios-gd__interr-col-right">
                      <h1 class="esios-gd__main-title snp-interrumptible-title"></h1>
                    </div>
      
                    <div class="grid-xs-24 grid-sm-16">
                      <div class="esios-toolbar">
                        <!-- toolbar -->
                        <div class="esios-toolbar2"></div>
                      </div>
                    </div>
      
                    <div class="grid-xs-24 grid-sm-14">
                      <div class="esios-gd__widget-custom-summary">
                        <div id="button1" class="esios-gd__mod-action-buttons esios-gd__mod-action-buttons--center mod-maps"
                          style="border: none">
                          <a class="btn" href="${i18n.t(`routes.lng`)}/${i18n.t(
      `routes.page`
    )}/${i18n.t(`routes.interruptibility_snp`)}">
                            ${i18n.t(`enter`)}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      
            <!-- COLUMNA DERECHA -->
      
            <div class="esios-gd__col2 col-xs-3">
              <aside class="esios-gd__aside_chart" id="idAside">
                <div class="esios-gd__widget widget-gd-desglose" id="gdDesgloseWidgetView">
                  <div class="esios-gd__row">
                    <div class="row">
                      <div class="col-xs-12">
                        <h1 class="esios-gd__main-title esios-gd__main-title--aside">
                          <!-- name of widget -->
                        </h1>
                      </div>
                      <div class="col-xs-5">
                        <div class="esios-toolbar">
                          <!-- toolbar -->
                          <div class="esios-toolbar3"></div>
                        </div>
                      </div>
                      <div class="col-xs-7 gd-options-buttons">
                        ${Select(selectDetails)}
                        <div class="esios-gd__selector"></div>
                        <div class="esios-gd__selector" id="gd-timeSelector"></div>
                      </div>
                    </div>
                  </div>
      
                  <div id="breakdownGraphic" class="esios-gd__row graph_gd" style="background-size:cover">
                    <!-- GRÁFICO DERECHO -->
      
                    <div id="dmNoDataRect2" class="esios-home__no-data-container_cuadrado hidden">
                      ${i18n.t('no-data-available')}
                    </div>
                    <div class="chari-chart gen-convencional piePotencia dibujoDemanda">
                      <div id="spinner23" class="spinner-container">
                        <div class="spinner"></div>
                      </div>
                    </div>
      
                    <div class="widget-legend basic-legend legend-potencial-pie "></div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
      </div>
    `,
    {
      image: headerStatic,
    },

    html`
      <div
        class="esios-layout__related-content esios-layout__related-content--gd"
      >
        <div class="esios-gd__wrap">
          <div class="row">
            <h1 class="esios-gd__main-title esios-gd__main-title--tabs">
              ${i18n.t('general_autcion')}
            </h1>
            <div class="grid-xs-24 grid-sm-16 esios-gd__toolbar">
              <div class="esios-toolbar">
                <!-- toolbar -->
                <div class="esios-toolbar4"></div>
              </div>
            </div>
            <div class="row">
              <div id="tabwithcontent2"></div>
              ${['1', '2', '3'].map((element) => {
                return html` <div
                  id="tab2-gd-content${element}"
                  class="tab2-gd-content tab__content"
                ></div>`;
              })}
            </div>
            <!--accordion-->
            <div id="accordion2" class="esios-accordion">
              <div id="">
                ${i18n.t('general_information').toLocaleUpperCase()}
              </div>
              <p>${html([gestionDemandaTabContent.returnTabContent(4)])}</p>

              <div id="">
                ${i18n.t('incoming_auctions').toLocaleUpperCase()}
              </div>
              <p>${html([gestionDemandaTabContent.returnTabContent(5)])}</p>

              <div id="">${i18n.t('providers_guide').toLocaleUpperCase()}</div>
              <p>${html([gestionDemandaTabContent.returnTabContent(6)])}</p>
            </div>
          </div>
        </div>
      </div>
    `,
    {
      title: i18n.t(`more`),
      subcontent: html`
        <ul class="esios-also__list row"></ul>
        <!-- <div id="document-list-gd"></div> -->
        <div id="document-list-ges"></div>
        <!-- DOCUMENTOS -->
        
        <div class="esios-documents">
          <div class="esios-gd__grid">
            <div class="esios-gd__documents-container">
              ${templateBoxDocument(documents, buttonsDoc)}
            </div>
          </div>
        </div>
        </div>
      `,
    }
  );
};

export { templateGestionDemanda };
