'use strict';

/**
 * @description Header Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { render } from 'lit-html';
import { store } from '../../../app.init.js';
import { resetBreadCrumbsDate } from '../../../utils/dateFormat.js';
import { datepickerComponent } from '../datepicker/datepicker.component.js';
import { BreadCrumbs } from './breadcrumbs.template.js';

let componentBread = (function () {
  let context = {};
  let renderSuccess = false;

  let pagesCalendar = [
    '', //INICIO
    'balance',
    'gestion-de-la-demanda',
    'demand-management',
    'generacion-y-consumo',
    'generation-and-consumption',
    'international-exhanges',
    'mercados-y-precios', //???
    'market-and-prices',
    'previsiones-os',
    'forecasts-os',
    'intercambios-internacionales',
    'generacion-y-consumo/sistemas-no-peninsulares', //???
    'generation-and-consumption/non-peninsular-systems',
    'pvpc', //???
    'saldo-frontera',
    'border-balance',
  ];

  let calendarActionButtons = ['pvpc'];

  let preRenderComponent = () => {
    // Get global Redux state
    const state = store.getState();

    // Current date (if we have not a chosen date)
    let pathname = state.exampleReducer.pathname;
    let pageName = pathname.substring(4, pathname.length);

    if (
      pathname == '/' + state.exampleReducer.currentLang &&
      !window.location.search.includes('?date=')
    ) {
      resetBreadCrumbsDate();
    }
    let date = localStorage.getItem('bread-crumbs-date');
    if (!date) {
      const currentDate = new Date();
      let day = currentDate.getDate();
      let month = currentDate.getMonth() + 1;
      const year = currentDate.getFullYear();
      // add 0s if day or month are less than 10
      if (day < 10) day = '0' + day;
      if (month < 10) month = '0' + month;

      date = `${day}/${month}/${year}`;
    }

    context = {
      date: date,
    };

    //Dependiendo de la página, si el breadcrumb lleva calendario
    pagesCalendar.map((element) => {
      if (element === pageName) {
        context.calendar = true;
      }
    });
    calendarActionButtons.map((element) => {
      if (element === pageName) {
        context.calendarActionButtons = true;
      }
    });
  };

  let renderComponent = (data) => {
    try {
      // Render the template to the document
      render(
        // Define a template
        BreadCrumbs(context, data),
        document.querySelector('.esios-layout__breadcrumbs')
      );
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = () => {
    let hasDatePicker = context.calendar;
    if (hasDatePicker) {
      hasDatePicker = document.getElementsByClassName('hasDatePicker');

      if (!hasDatePicker[0]?.dataset.render) {
        datepickerComponent.render();
        hasDatePicker[0].dataset.render = true;
      }
    }
  };

  let init = (data) => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent(data);

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: (data) => init(data),
  };
})();

export { componentBread };
