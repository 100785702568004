'use strict';
import moment from 'moment-timezone';
import { api } from '../../utils/api-interceptor';

let servicesBalance = (() => {
  let init = (e) => llamarServicio(e);

  let modalData = (e, date) => getModaldata(e, date);

  let energyData = (e, date) => getData(e, date);
  let exportData = (e, date) => getExportData(e, date);
  let completeData = (e, date) => getCompleteData(e, date);
  let dataTabs = (e, id, selection, date) =>
    getDataTabs(e, id, selection, date);

  let dataGraphs = (e, date) => getGraphData(e, date);

  const llamarServicio = async (e) => {
    const urlApi =
      `static_pages?slug=balance&temporal=` + Math.floor(Math.random() * 1000);
    try {
      const data = await api(urlApi, { hasLang: true });
      return data.content;
    } catch (error) {}
  };

  const getDataTabs = async (e, id, selection, date) => {
    try {
      let finalData = [];
      await Promise.all(
        id.map(async (element) => {
          try {
            if (selection == 'month') {
              let year = new Date(date).getFullYear();
              const urlApi = `indicators/${element}?start_date=${year}-01-01T00%3A00%3A00%2B01%3A00&end_date=${year}-12-31T23%3A59%3A59%2B01%3A00&locale=${e}&start_date=${year}-01-01T00%3A00%3A00%2B01%3A00&end_date=${year}-12-31T23%3A59%3A59%2B01%3A00&time_trunc=${selection}`;
              const data = await api(urlApi);
              finalData.push(data.indicator);
              return finalData;
            } else if (selection == 'day') {
              const startDate = moment(date, 'YYYY-MM-DD')
                .tz('Europe/Madrid')
                .startOf('month')
                .hours('00')
                .minutes('00')
                .format();
              const endDate = moment(date, 'YYYY-MM-DD')
                .tz('Europe/Madrid')
                .endOf('month')
                .hours('23')
                .minutes('59')
                .format();
              const urlApi = `indicators/${element}?locale=${e}&start_date=${startDate}&end_date=${endDate}&time_trunc=${selection}`;
              const data = await api(urlApi);
              finalData.push(data.indicator);
              return finalData;
            } else if (selection == 'hour') {
              let startDate = moment(date, 'YYYY-MM-DD')
                .tz('Europe/Madrid')
                .hours('00')
                .format();
              let endDate = moment(date, 'YYYY-MM-DD')
                .tz('Europe/Madrid')
                .hours('23')
                .minutes('59')
                .format();
              const urlApi = `indicators/${element}?end_date=${endDate}&start_date=${startDate}&locale=${e}&start_date=${startDate}&end_date=${endDate}&time_trunc=${selection}`;
              const data = await api(urlApi);
              finalData.push(data.indicator);
              return finalData;
            }
          } catch (error) {}
        })
      );

      return finalData;
    } catch (error) {
      return;
    }
  };

  const getModaldata = async (e, date) => {
    const urlApi = `widgets/24?datetime=${date}T00%3A00%3A00%2B02%3A00&locale=${e}`;

    try {
      const data = await api(urlApi);
      return data.widget.description;
    } catch (error) {}
  };

  const getData = async (e, date) => {
    const urlApi = `widgets/24?datetime=${date}&locale=${e}`;

    try {
      const data = await api(urlApi);
      return data.widget.indicators;
    } catch (error) {
      null;
    }
  };

  const getCompleteData = async (e, date) => {
    const urlApi = `widgets/24?datetime=${date}&locale=${e}`;
    try {
      const data = await api(urlApi);
      return data.widget;
    } catch (error) {
      null;
    }
  };

  const getExportData = async (e, date) => {
    const urlApi = `widgets/24?datetime=${date}T00%3A00%3A00%2B02%3A00&locale=${e}`;

    try {
      const data = await api(urlApi);
      return {
        name: data.widget.name,
        slug: data.widget.id,
      };
    } catch (error) {}
  };

  const getGraphData = async (e, date) => {
    const urlApi = `widgets/24?datetime=${date}T00%3A00%3A00%2B02%3A00&locale=${e}&time_trunc=hour`;
    try {
      return api(urlApi).then((data) => {
        const indicators = data.widget.indicators;

        return Promise.all(
          indicators.map((el) => {
            return api(`${el.url} `);
          })
        ).then((data) => {
          const newArray = data.map((ele, index) => {
            const values = ele.indicator.values.map((el) => {
              return {
                x: new Date(el.datetime),
                y: el.value,
              };
            });

            return {
              values,
              step: ele.indicator.step_type,
              id: ele.indicator.id,
              short_name: ele.indicator.short_name,
              geo_id: ele.indicator.geos[0]?.geo_id,
            };
          });
          return newArray;
        });
      });
    } catch (error) {}
  };

  return {
    init,
    modalData,
    energyData,
    dataTabs,
    dataGraphs,
    exportData,
    completeData,
  };
})();

export { servicesBalance };
