'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';
import backimage from '../../assets/images/header-default.jpg';
import { componentLayout } from '../../components/layout/layout.component';

let templateMapasInteres = (context, mapsData) => {
  let articleChain = '';

  mapsData.forEach((currentMap) => {
    const {
      body,
      permalink,
      title,
      slug,
      updated_at,
      updated_at_formated,
      published_at,
    } = currentMap;
    let day = new Date(published_at).getDate();
    let month = new Date(published_at).getMonth() + 1;
    let formattedMonth = month < 10 ? '0' + month : month;
    let year = new Date(published_at).getFullYear();

    //Permalink es erroneo, dividimos ruta por slash y nos quedamos con la última parte
    let delimiter = '/';
    let correctedUrl = context.path + delimiter + slug;

    let newArticle = `<article class="esios-mapas-int__mod-article-item">
          <time class="esios-mapas-int__time" datetime="${updated_at}">${day} / ${formattedMonth} / ${year}</time>
          <h2 class="esios-mapas-int__title"><a href="${correctedUrl}">${title}</a></h2> <!--$ {permalink}-->
          <p></p>
          ${body}
        </article>`;
    articleChain += newArticle.trim();
  });

  return componentLayout.render(
    html`
      <div class="esios-mapas-int">
        <div class="esios-mapas-int__wrap">
          <div class="esios-mapas-int__row">
            <div class="grid-xs-24">
              <h1 class="esios-mapas-int__main-title">
                ${i18n.t(`interest_maps`)}
              </h1>
            </div>
          </div>
        </div>
        <div class="esios-mapas-int__wrap">
          <div class="esios-mapas-int__row">
            <div class="grid-xs-24 grid-sm-16">${html([articleChain])}</div>
          </div>
        </div>
      </div>
    `,
    {
      image: backimage,
      showBanner: true,
      showFooterImage: true,
    }
  );
};

export { templateMapasInteres };
