'use strict';

/**
 * @description Select Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { render } from 'lit-html';
import { store } from '../../../app.init';
import { setHomeChangeOtherIndicatorsTime } from '../../../state/actions';
import { templateTimeSelector } from './time-selector.template';

let componentTimeSelector = (function () {
  let context = {};
  let handlers = {
    openTimeSelector: (event) => openTimeSelector(event),
    openTimeHoursSelector: (event) => openTimeHoursSelector(event),
  };
  let ID = '';
  let idSelector;
  let changeDatetime;
  let renderSuccess = false;

  const openTimeSelector = (e) => {
    if (e.target.parentElement.classList.contains('active')) {
      e.target.parentElement.classList.remove('active');
    } else {
      e.target.parentElement.classList.add('active');
    }

    ID = e.target.parentElement.id;
    window.onclick = function (e) {
      let timers = document.querySelectorAll('.esios-toolbar-time__mod-date');

      timers.forEach((element) => {
        if (!element.parentElement.id === ID) {
          element.parentElement.classList.remove('active');
        }
      });

      if (
        !e.target.matches('.esios-toolbar-time__mod-date') &&
        !e.target.matches('.selected')
      ) {
        let timeSelect = document.querySelector(`#${ID}.esios-toolbar-time`);
        let selectHours = document.querySelector(`#${ID} .hours.custom-select`);
        let selectMinutes = document.querySelector(
          `#${ID} .minutes.custom-select`
        );
        let selectedHours = document
          .querySelector(`#${ID} .hours .selected`)
          .getAttribute('value');
        let selectedMinutes = document
          .querySelector(`#${ID} .minutes .selected`)
          .getAttribute('value');
        if (e.target.matches(`#${ID} .option`)) {
          let newHours = selectedHours;
          let newMinutes = selectedMinutes;

          let currentTimeFormat =
            e.target.parentElement.parentElement.getAttribute('data-target');
          if (currentTimeFormat === 'hours') {
            newHours = e.target.getAttribute('value');
          }
          if (currentTimeFormat === 'minutes') {
            newMinutes = e.target.getAttribute('value');
          }
          changeDatetime({
            id: ID,
            newValue: `${newHours}:${newMinutes}`,
          });
        }

        if (timeSelect.classList.contains('active')) {
          timeSelect.classList.remove('active');
          selectHours.classList.remove('active');
          selectMinutes.classList.remove('active');
        }
      }
    };
  };
  const openTimeHoursSelector = (e) => {
    if (e.target.parentElement.classList.contains('active')) {
      e.target.parentElement.classList.remove('active');
    } else {
      e.target.parentElement.classList.add('active');
    }
  };

  let preRenderComponent = (selectorData, documentElement) => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    ID = selectorData.id;
    idSelector = documentElement;

    changeDatetime = selectorData.changeTime;
  };

  let renderComponent = (selectorData, documentElement) => {
    ID = selectorData.id;
    idSelector = documentElement;
    changeDatetime = selectorData.changeTime;

    try {
      render(
        //  Define a template
        templateTimeSelector(handlers, selectorData),
        document.querySelector(documentElement)
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = (selectorData, documentElement) => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
    ID = selectorData.id;
    idSelector = documentElement;
    changeDatetime = selectorData.changeTime;
  };

  let init = (selectorData, documentElement) => {
    //Call pre render component
    preRenderComponent(selectorData, documentElement);

    // Call render component
    renderComponent(selectorData, documentElement);

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent(selectorData, documentElement) : '';
  };

  return {
    render: (selectorData, documentElement) =>
      init(selectorData, documentElement),
  };
})();

export { componentTimeSelector };
