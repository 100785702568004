import { html } from 'lit-html';
import { componentLayout } from '../../components/layout/layout.component';
import { servicesUsers } from './usuarios.service';
import backImage from '../../assets/images/header-default.jpg';
let templateUsuarios = () => {
  return componentLayout.render(
    html`
      <div class="esios-usuarios">
        <h1>TABLA USUARIOS</h1>
        <div id="table-user"></div>
      </div>
    `,
    {
      image: backImage,
    }
  );
};

export { templateUsuarios };
