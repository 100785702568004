'use strict';
import i18n from 'i18next';
import { store } from '../../app.init.js';
import { html } from 'lit-html';
import backImage from '../../assets/images/header-default.jpg';
import { componentLayout } from '../../components/layout/layout.component';

let templateAbout = (context, handlers, data, modalTitle, modalContent) => {
  const { body } = data;
  const state = store.getState();
  const lang = state.exampleReducer.currentLang;
  const myWidth = window.innerWidth;

  return componentLayout.render(
    html`
      <div class="esios-about">
        <h1>${i18n.t('about')}</h1>

        ${html([body])}
        <div
          class="tooltip-sm arrow"
          id=${myWidth > 540 ? 'modal' : 'modal-responsive'}
        >
          <div
            class=${myWidth > 540
              ? 'esios-about__close-container'
              : 'esios-about__close-container-sm'}
          >
            <span
              class=${myWidth > 540
                ? 'esios-about__close'
                : 'esios-about__close-sm'}
              id=${myWidth > 540 ? 'close' : 'close-responsive'}
            >
              <i class="fas fa-times"></i>
            </span>
          </div>
          <div
            class=${myWidth > 540
              ? 'esios-about__container'
              : 'esios-about__button-container-sm'}
          >
            <div
              class=${myWidth > 540
                ? 'esios-about__wrap'
                : 'esios-about__wrap-sm'}
            >
              <h1>${modalTitle ? modalTitle : ''}</h1>
              ${html([modalContent])}
              <div
                class=${myWidth > 540
                  ? 'esios-about__button-container'
                  : 'esios-about__button-container-sm'}
              >
                <button
                  onclick="window.location.href='${lang}/${i18n.t(
                    'routes.glossary'
                  )}';"
                  class=${myWidth > 540
                    ? 'esios-about__button'
                    : 'esios-about__button-sm'}
                >
                  <a>${i18n.t('go_to_glossary')}</a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    `,
    {
      image: backImage,
      showFooterImage: true,
    }
  );
};

export { templateAbout };
