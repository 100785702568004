'use strict';

export const frontJs = {};

frontJs.breakpoints = {
  xl: 1440,
  lg: 1280,
  md: 1024,
  sm: 768,
  xs: 480,
};

frontJs.settings = {
  method: 'GET',
  mode: 'cors',
  headers: {
    Accept: 'application/json; application/vnd.esios-api-v1+json',
    'Content-Type': 'application/json',
    Host: 'api.esios.ree.es',
    Authorization:
      'Token token=1eede262f86779a75b864046537da94a259a7974e3fad9ee60b79fbbd7e07bda',
  },
};
