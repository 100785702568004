'use strict';
import { html } from 'lit-html';
import i18n from 'i18next';
let templateBoxMarket = (objMarket) => {
  return html`
    <div class="esios-boxmarket">
      <div class="esios-boxmarket__container">
        <div class="esios-boxmarket__header">
          <div class="esios-boxmarket__title">
            ${objMarket?.map((item) => html`<h2>${i18n.t(item.title)}</h2>`)}
          </div>
        </div>

        <div class="esios-boxmarket__body">
          <div class="esios-boxmarket__list" id="listMarket">
            <ul>
              ${objMarket?.map((item) =>
                item.type == 'list'
                  ? html`
                      <li>
                        <span></span>
                        <a href="${item.url}">${i18n.t(item.text)}</a>
                      </li>
                    `
                  : ``
              )}
            </ul>
          </div>
          ${objMarket?.map((item) =>
            item.type == 'button' && item.id == 1
              ? html`
                  <div class="esios-boxmarket__button">
                    <button class="esios-boxmarket__btn">
                      <a href="${item.url}">${i18n.t(item.label)}</a>
                    </button>
                  </div>
                `
              : ``
          )}
        </div>

        <div class="esios-boxmarket__text">
          ${objMarket?.map((item) =>
            item.type == 'text'
              ? html` <div class="esios-boxmarket__text">
                  <p class="esios-boxmarket__list">${i18n.t(item.content)}</p>
                </div>`
              : ``
          )}
        </div>
        <div class="esios-boxdoc__footer">
          ${objMarket?.map((item) =>
            item.type == 'button' && item.id == 2
              ? html`
                  <button
                    type="button"
                    target="_blank"
                    class="esios-boxmarket__button"
                  >
                    <a
                      class="esios-boxmarket__btn"
                      target="_blank"
                      href="${item.url}"
                      >${i18n.t(item.label)}</a
                    >
                  </button>
                  <!-- <div class="esios-boxmarket__button">
                <button class="esios-boxmarket__btn">
                  <a href="${item.url}">${item.label}</a>
                </button>
              </div> -->
                `
              : ``
          )}
        </div>
      </div>
    </div>
  `;
};
export { templateBoxMarket };
