import { html } from 'lit-html';

const Banner = (img) => {
  return html`
    <div
      id="regular-banner"
      class="esios-layout__banner-image"
      style="background-image: url('${img}'); background-repeat: no-repeat;"
    ></div>
  `;
};

export default Banner;
