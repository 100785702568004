import i18n from 'i18next';
import moment from 'moment';
import { html } from 'lit-html';
import { store } from '../../../app.init';
import Select from '../../../shared/components/selects/select';
import { getState } from '../../../utils/state';
import { setAnalysis } from '../../../state/actions';
import { getUrlParams, setUrlParam } from '../../../utils/url';
import { parseDate } from '../../../utils/dateFormat';
import { getCompareDate } from './analysis-form-period';

const AnalysisFormMoveDate = (toggleOn, indicator) => {
  const { analysis } = getState();
  if (!indicator) return;

  let disabled = false;
  let values = [];
  let selected = {};
  const groupby = analysis.groupby;

  let startDate = analysis.start_date
    ? new Date(parseDate(analysis.start_date).split('T').join(' '))
    : new Date();
  let endDate = analysis.end_date
    ? new Date(parseDate(analysis.end_date).split('T').join(' '))
    : new Date();
  let nextEndDate = analysis.moveBy
    ? modifyDate(endDate, analysis.moveBy, 'next')
    : modifyDate(endDate, 'hour', 'next');
  let nextStartDate = analysis.moveBy
    ? modifyDate(startDate, analysis.moveBy, 'next')
    : modifyDate(startDate, 'hour', 'next');

  let currentDate = new Date();
  const res = getValuesAndSelected();
  values = res.values;
  selected = res.selected;

  let isDisable = nextStartDate >= endDate;

  const toggle = analysis.toggle;
  isDisable = toggle ? false : isDisable;

  return html`
    <div class="analysis-move esios-select">
      <span class="analysis-move__title">${i18n.t('move')}</span>
      <div class="analysis-move__flex">
        <button
          class="analysis-move__btn-prev"
          type="button"
          @click=${handleMove('prev')}
        ></button>
        ${Select({ disabled, values, selected, onClick: handleMoveDateLogic })}
        <button
          class="analysis-move__btn-next"
          type="button"
          ?disabled="${isDisable}"
          @click=${handleMove('next')}
        ></button>
      </div>
    </div>
  `;

  function handleMove(type) {
    const dateFormat = 'DD-MM-YYYYTHH:mm';
    return (e) => {
      e.preventDefault();
      let { start_date, end_date, compare_start_date } = getUrlParams();
      const { analysis } = getState();
      const moveBy = analysis.moveBy || analysis.groupby || 'hour';

      let newCompareStartDate;
      let newStartDate;
      newCompareStartDate = new Date(
        parseDate(compare_start_date).split('T').join(' ')
      );
      newStartDate = new Date(parseDate(start_date).split('T').join(' '));
      newCompareStartDate = modifyDate(newCompareStartDate, moveBy, type);
      newStartDate = modifyDate(newStartDate, moveBy, type);

      let newStartDateMoment = moment(newStartDate).format(dateFormat);
      let newCompareStartDateMoment =
        moment(newCompareStartDate).format(dateFormat);

      setUrlParam({
        start_date: newStartDateMoment,
        compare_start_date: newCompareStartDateMoment,
      });
      const newState = {
        start_date: newStartDateMoment,
        compare_start_date: newCompareStartDateMoment,
      };
      if (toggleOn) {
        let analysisCompareEndDate = analysis.compare_end_date;
        let newEndDate;
        newEndDate = new Date(parseDate(end_date).split('T').join(' '));
        newEndDate = modifyDateEnd(newEndDate, moveBy, type, analysis.groupby);
        let newEndDateMoment = moment(newEndDate).format(dateFormat);
        setUrlParam('end_date', newEndDateMoment);
        newState.end_date = newEndDateMoment;
        // let compareEndDateTemp = $('#compare-end-date').val();
        let compareEndDateTemp = analysis.compare_end_date;

        if (!compareEndDateTemp) {
          compareEndDateTemp = getCompareDate(
            newStartDateMoment,
            newEndDateMoment,
            newCompareStartDateMoment,
            'end_date'
          );
          compareEndDateTemp = moment(compareEndDateTemp, dateFormat).format(
            'DD-MM-YYYYTHH:mm'
          );
        }
        const compareEndDate = new Date(
          moment(compareEndDateTemp, 'DD-MM-YYYYTHH:mm')
        );
        const newCompareEndDate = modifyDateEnd(
          compareEndDate,
          moveBy,
          type,
          analysis.groupby
        );
        const newCompareEndDateMoment =
          moment(newCompareEndDate).format(dateFormat);
        newState.compare_end_date = newCompareEndDateMoment;
      }

      store.dispatch(setAnalysis(newState));
    };
  }
};

function handleMoveDateLogic({ content }) {
  store.dispatch(
    setAnalysis({
      moveBy: content,
    })
  );
}

function modifyDate(date, moveBy, type) {
  let newDate = new Date(date);
  let operator = type === 'prev' ? -1 : 1;
  switch (moveBy) {
    case 'hour':
      newDate.setHours(operator + date.getHours());
      break;
    case 'day':
      newDate.setHours(operator * 24 + date.getHours());
      break;
    case 'month':
      newDate =
        type == 'prev'
          ? new Date(
              moment(newDate).subtract('1', 'month').format('YYYY-MM-DDTHH:mm')
            )
          : new Date(
              moment(newDate).add('1', 'month').format('YYYY-MM-DDTHH:mm')
            );
      // newDate.setMonth(operator + date.getMonth());
      break;
    case 'year':
      newDate.setFullYear(operator + date.getFullYear());
  }
  return newDate;
}

function modifyDateEnd(date, moveBy, type, grupby) {
  let newDate = new Date(date);
  let operator = type === 'prev' ? -1 : 1;
  switch (moveBy) {
    case 'hour':
      newDate.setHours(operator + date.getHours());
      break;
    case 'day':
      newDate.setHours(operator * 24 + date.getHours());
      break;
    case 'month':
      const currentMonth = newDate.getMonth();
      newDate =
        type == 'prev'
          ? new Date(
              moment(newDate).subtract('1', 'month').format('YYYY-MM-DDTHH:mm')
            )
          : new Date(
              moment(newDate).add('1', 'month').format('YYYY-MM-DDTHH:mm')
            );
      const newMonth = newDate.getMonth();
      if (currentMonth !== newMonth && grupby === 'month') {
        // Obtiene el mes actual de la fecha
        const month = newDate.getMonth();
        // Establece el día al último día del nuevo mes
        const nextMonthDate = new Date(newDate);
        nextMonthDate.setMonth(month + 1, 1);
        // Resta un día para obtener el último día del mes actual
        nextMonthDate.setDate(nextMonthDate.getDate() - 1);
        newDate = nextMonthDate;
      }
      // newDate.setMonth(operator + date.getMonth());
      break;
    case 'year':
      // Verifica si el mes de newDate es diferente al mes de date, esto pasa cuando el mes de otro año tiene menos dias
      if (
        operator + date.getFullYear() !== date.getFullYear() &&
        grupby === 'month'
      ) {
        // Establece el día al primero del mes
        newDate.setDate(1);
      }
      newDate.setFullYear(operator + date.getFullYear());
      if (grupby === 'month') {
        // Obtiene el mes actual de la fecha
        const month = newDate.getMonth();
        // Establece el día al último día del nuevo mes
        const nextMonthDate = new Date(newDate);
        nextMonthDate.setMonth(month + 1, 1);
        // Resta un día para obtener el último día del mes actual
        nextMonthDate.setDate(nextMonthDate.getDate() - 1);
        newDate = nextMonthDate;
      }
  }
  return newDate;
}

function getValuesAndSelected() {
  let groupby = getUrlParams().groupby
    ? getUrlParams().groupby
    : getState().analysis.groupBy;

  const grpByMinutes = ['minutes5', 'minutes10', 'minutes15'];
  let moveBy = getState().analysis.moveBy
    ? getState().analysis.moveBy
    : grpByMinutes.includes(groupby)
    ? 'hour'
    : groupby;

  const grpByExceptions = ['year', 'month', 'day', 'hour'];
  if (!grpByExceptions.includes(groupby)) {
    groupby = 'hour';
  }

  const disabled = {
    hour: [false, false, false, false],
    day: [true, false, false, false],
    month: [true, true, false, false],
    year: [true, true, true, false],
  };
  const values = [
    {
      label: '1 ' + i18n.t('hour'),
      content: 'hour',
      disabled: disabled[groupby][0],
    },
    {
      label: '1 ' + i18n.t('day'),
      content: 'day',
      disabled: disabled[groupby][1],
    },
    {
      label: '1 ' + i18n.t('month'),
      content: 'month',
      disabled: disabled[groupby][2],
    },
    {
      label: '1 ' + i18n.t('year'),
      content: 'year',
      disabled: disabled[groupby][3],
    },
  ];

  let selected = values.find((i) => i.content === moveBy);

  return {
    values,
    selected,
  };
}

export default AnalysisFormMoveDate;
