'use strict';

/**
 * @description Footer Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { render, html } from 'lit-html';
import { templateFooter } from './footer.template.js'; 

let componentFooter = (function () {

	let context = {}
	let renderSuccess = false;

	let preRenderComponent = () => {
		
	};

	let renderComponent = () => {

		try{
			// Render the template to the document
			render(
				// Define a template
				templateFooter(context), 
				document.querySelector("#footer-app")
			)
			renderSuccess = true;
		}catch(error){
			renderSuccess = false;
		}
	
	};

	let postRenderComponent = () => {
		
		
	}

	let init = () => {
		
		//Call pre render component
		preRenderComponent();

		// Call render component
		renderComponent();

		// Call post render component if it has been rendered
		renderSuccess ? postRenderComponent() : ''
	}
	
	
	return {
		render: init
	};
})();

export { componentFooter };