import i18n from 'i18next';
import { html } from 'lit-html';
import { templateShareModal } from '../../shared/components/modal/modalShare.template';
import { FavMe } from '../login/login.service';
import { renderModal } from './analysis.page';

const AnalysisToolbar = (indicator, favourite = false, analysis) => {
  const id = indicator.id;
  const shareUrl = favourite
    ? favourite.uri
    : analysis
    ? analysis
    : `/${id}${window.location.search}`;
  setTimeout(() => {
    renderModal(id, indicator?.name, indicator?.description);
    renderModal('Share' + id, 'none', templateShareModal('analysis', shareUrl));
  }, 0);
  FavMe({ id }, (res) => {
    if (res) {
      document
        .getElementById(`analysis-fav-${id}`)
        .classList.add('toolbar-favorite-selected');
    }
  });
  return html`<div class="esios-analysis__widget-toolbar">
      <div class="toolbar-wrapper">
        <span class="widget-toolbar-item item__info tooltip-switcher">
          <button
            id="btnModal${id}"
            class="widget-toolbar-info icon-widget-info"
          ></button>
          <div class="mod-tooltip">
            <p class="ampliar_info">${i18n.t('more_info')}</p>
          </div>
        </span>
        <span
          class="widget-toolbar-item item__share tooltip-switcher"
          @click=${openShare}
        >
          <button class="widget-toolbar-info icon-widget-share"></button>
          <div class="mod-tooltip">
            <p>${i18n.t('share')}</p>
          </div>
          <div class="esios-toolbar__share" id="esios-toolbar__list-${id}">
            <ul class="esios-toolbar__list">
              <li class="esios-toolbar__item" id="buttonShareModal1">
                <i class="fas fa-share-alt"></i>
                <a class="ancle" id="btnModalShare${id}">
                  ${i18n.t('share')}
                </a>
              </li>
            </ul>
          </div>
        </span>
        <span class="widget-toolbar-item item__info tooltip-switcher">
          <button
            @click=${handleFav}
            id="analysis-fav-${id}"
            class="widget-toolbar-info icon-widget-favorite"
          ></button>
          <div class="mod-tooltip">
            <p class="aniadir_fav">${i18n.t('add_to_fav')}</p>
          </div>
        </span>
      </div>
    </div>
    <div id="modal${id}"></div>
    <div id="modalShare${id}"></div> `;
  function openShare() {
    document
      .getElementById('esios-toolbar__list-' + id)
      .classList.toggle('active');
    setTimeout(() => {
      renderModal(id, indicator?.name, indicator?.description);
      renderModal(
        'Share' + id,
        'none',
        templateShareModal('analysis', `/${id}${window.location.search}`)
      );
    }, 0);
  }
  function handleFav() {
    FavMe({ type: 'analysis', id }, favResponse);
  }
  function favResponse(res) {
    const favBtn = document.getElementById('analysis-fav-' + id);
    const favClass = 'toolbar-favorite-selected';
    if (res === null) {
      // Not logged
      let idIndicator = window.location.pathname.split('/analisis/');
      let uri = idIndicator[1] + window.location.search;
      localStorage.setItem('addToFav', uri);
      localStorage.setItem('addToFavIsAnalysis', true);
      setTimeout(() => {
        window.location.pathname = '/es/mi-esios';
      }, 1000);
    } else if (res.message) {
      // Deleted
      favBtn.classList.remove(favClass);
    } else {
      // Added
      favBtn.classList.add(favClass);
    }
  }
};

export default AnalysisToolbar;
