import { html } from 'lit-html';
import { sRender } from '../../../utils/render';
import { getState, getSubscriber } from '../../../utils/state';
import { idViews, mapViews, sections } from './analysis-form.service';

let information;

const AnalysisForm = (info) => {
  handleViews();
  getSubscriber(handleViews);
  information = info;
  return html`${sections.map((section) => FormSection(info, section))}`;
};

function FormSection(info, section) {
  return html`
    <div id="analysis-form-${section}">${mapViews[section](info)}</div>
  `;
}

export function handleViews() {
  const { tabSelected, indicator } = getState().analysis;
  let currentView = idViews[tabSelected.label];
  if (information) {
    information = {
      indicator: {
        ...information.indicator,
        geos: indicator.geos,
      },
    };
  }
  const info = information || { indicator };
  if (
    info.indicator.nivel[0].geo_nivel == 0 &&
    tabSelected.label === 'geographical'
  ) {
    currentView = currentView.filter((el) => el != 'levels');
  }

  if (tabSelected.label == 'comparative' && indicator.universe == null) {
    currentView = currentView.filter((el) => el != 'compare');
  }
  for (const section of sections) {
    let content = '';
    if (currentView.includes(section)) {
      content = mapViews[section];
    }
    sRender(content, `analysis-form-${section}`, info);
  }
}

export default AnalysisForm;
