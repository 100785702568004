'use strict';
import { html } from 'lit-html';
import i18n from 'i18next';

let templateLayoutSubcontent = ({ title, subcontent, showMaps = true }) => {
  return html`
    <aside
      id="currentNews"
      class="esios-layout__subcontent ${showMaps ? '' : 'no-image'}"
    >
      <div class="esios-layout__subcontent__wrapper">
        <div class="title">
          <h1 class="is-title-with-line">${title}</h1>
        </div>

        ${subcontent}

        <div class="row is-strech" style="${showMaps ? '' : 'display: none'}">
          <div class="col-md-9 col-sm-8">
            <div class="mod-news" id="newsModView">
              <h2 class="is-section-title">${i18n.t('news')}</h2>
              <div id="news" class="row"></div>
            </div>
          </div>
          <div class="col-md-3 col-sm-4 is-stretch">
            <div class="mod-maps" id="mapsModView">
              <h2 class="is-section-title">${i18n.t('maps')}</h2>
              <ul>
                <li>
                  <a
                    href="/${i18n.t('routes.lng')}/${i18n.t(
                      'routes.maps'
                    )}/${i18n.t('routes.wind-installations-map')}"
                    >${i18n.t('wind_installations_map')}</a
                  >
                </li>
                <li>
                  <a
                    href="/${i18n.t('routes.lng')}/${i18n.t(
                      'routes.maps'
                    )}/${i18n.t('routes.solar-thermal-installations-map')}"
                    >${i18n.t('solar_thermal_installations_map')}</a
                  >
                </li>
                <li>
                  <a
                    href="/${i18n.t('routes.lng')}/${i18n.t(
                      'routes.maps'
                    )}/${i18n.t(
                      'routes.cogeneration-wastes-biomass-installations-town-map'
                    )}"
                    >${i18n.t(
                      'cogeneration_wastes_and_biomass_installations_by_town_map'
                    )}</a
                  >
                </li>
                <li>
                  <a
                    href="/${i18n.t('routes.lng')}/${i18n.t(
                      'routes.maps'
                    )}/${i18n.t(
                      'routes.renewable-installations-production-time-lapse-map'
                    )}"
                    >${i18n.t(
                      'renewable_installations_production_time_lapse_map'
                    )}</a
                  >
                </li>
              </ul>
              <div class="mod-action-buttons">
                <a
                  href="/${i18n.t('routes.lng')}/${i18n.t(
                    'routes.interest_maps'
                  )}"
                  class="btn-info"
                  >${i18n.t('more_maps')}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </aside>
  `;
};

export { templateLayoutSubcontent };
