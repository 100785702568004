'use strict';

/**
 * @description Dashboard Component
 * @returns {Object} Devuelve la instancia del componente
 */

import i18n from 'i18next';
import { store } from '../../app.init.js';
import { servicesPropuestas } from './propuestas.services.js';
import { templatePropuestas } from './propuestas.template.js';

let componentPropuestas = (function () {
  let context = {};
  let handlers = { casa: 'casa' };
  let renderSuccess = false;
  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    document.title = `${i18n.t('reg_chang_prop')} | ${i18n.t('page_subtitle')}`;
    context.name = state.exampleReducer.name;
  };

  let renderComponent = async () => {
    try {
      // Render the template to the document
      let propuestaData = await servicesPropuestas.callService();
      templatePropuestas(context, handlers, propuestaData);

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentPropuestas };
