'use strict';

/**
 * @description Help Component
 * @returns {Object} Devuelve la instancia del componente
 */

import i18n from 'i18next';
import { store } from '../../app.init.js';
import { componentRapidAccess } from '../../shared/components/rapid-access/rapid-access.component.js';
import { Utils } from '../../utils/utils';
import { servicesAyudaFAQ } from './ayudaFAQ.services.js';
import { templateAyudaFAQ } from './ayudaFAQ.template.js';

let componentAyudaFAQ = (function () {
  let renderComponent = async () => {
    const state = store.getState();
    document.title = `${i18n.t('help')} - ${i18n.t('faq_title')} | ${i18n.t(
      'page_subtitle'
    )}`;

    try {
      // Render the template to the document
      let contactData = await servicesAyudaFAQ.init(
        state.exampleReducer.currentLang
      );
      templateAyudaFAQ(contactData);
      changetab(contactData);

      const RapidAccessData = [
        {
          id: '#info-en-esta-web',
          title: 'web_info',
        },
        {
          id: '#mas-detalle',
          title: 'further_analysis_information',
        },
        {
          id: '#actualizacion-de-informacion',
          title: 'how_often_information_is_updated',
        },
        {
          id: '#configurar-favoritos',
          title: 'favorite_content',
        },
        {
          id: '#acceso-movil',
          title: 'mobile_access',
        },
      ];
      componentRapidAccess.render(RapidAccessData);
    } catch (error) {
      console.error(error);
    }
    document.querySelector('.esios-footer').classList.add('esios-footer--help');
  };

  const changetab = () => {
    document.querySelectorAll('a[href^="#"]').forEach((a) => {
      a.addEventListener('click', function (e) {
        e.preventDefault();
        var href = this.getAttribute('href');
        var elem =
          document.querySelector(href) ||
          document.querySelector(
            'a[name=' + href.substring(1, href.length) + ']'
          );
        var elemPosition = elem.getBoundingClientRect().top + window.scrollY;

        let headerNavHeight =
          document.querySelector('.esios-header').clientHeight;
        let breadcrumbsHeight = document.querySelector(
          '.esios-layout__breadcrumbs__wrapper'
        ).clientHeight;
        var tabletOffset = headerNavHeight + breadcrumbsHeight;
        var desktopOffset = 15 + breadcrumbsHeight;
        var offset = Utils.isTablet() ? tabletOffset : desktopOffset;

        window.scroll({
          top: elemPosition - offset,
          left: 0,
          behavior: 'smooth',
        });
      });
    });
  };

  let init = () => {
    renderComponent();
  };

  return {
    render: init,
  };
})();

export { componentAyudaFAQ };
