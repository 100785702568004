import { html } from 'lit-html';
import i18n from 'i18next';
import { templateToolbarTime } from '../../../../shared/components/toolbarTime/toolbarTime.template';

const MyEsiosWidget2 = () => {
  return html`
    <div
      class="grid-md-14 grid-sm-12 grid-xs-24 is-mobile-first-item div-consumo"
    >
      <div class="widget" id="char2">
        <div class="row">
          <div class="grid-xs-24 grid-sm-24">
            <div class="widget-toolbar">
              <div class="esios-toolbar col-12">
                <div class="esios-toolbar2"></div>
                ${templateToolbarTime('generation-consumption')}
              </div>
            </div>
          </div>
        </div>
        <div id="widget2" class="generacion-consum widget-chart">
          <div id="rect21" class="rect-no-data hidden">
            ${i18n.t('no-data-available')}
          </div>
          <div id="spinner2" class="spinner-container">
            <div class="spinner"></div>
          </div>
        </div>

        <div id="widget2Data" class="grid-xs-24">
          <div class="widget-legend visual-legend in-line">
            <ul></ul>
          </div>

          <div class="widget-summary gyc-summary">
            <div class="gyc-summary highlighted-summary">
              <div class="in-line"></div>

              <div class="widget-summary-item ">
                <div class="value is-big variacion"></div>
                <div class="label">${i18n.t('variation')}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `;
};

export default MyEsiosWidget2;
