'use strict';
import { html } from 'lit-html';
import './paralax_mod_view.js';
import esiosLogo from '../../assets/images/eSIOS-logo.svg';

let templateLayout = ({ bannerIsAligned = false, showFooterImage = false }) => {
  return html`
    <div class="esios-layout">
      <div id="esios-layout-banner"></div>
      <div
        class="esios-layout-back__container ${bannerIsAligned
          ? 'banner-aligned'
          : ''}"
      >
        <div class="esios-layout__main">
          <div id="esios-slider" class="esios-slider"></div>
          <div class="esios-layout__breadcrumbs"></div>
          <div class="esios-layout__print-logo">
            <img src="${esiosLogo}" alt="Logo" />
          </div>
          <div id="esios-main-page"></div>
        </div>
      </div>
      <div class="test"></div>
      <div id="layout-extended"></div>
      <div
        class="esios-layout__footer-image"
        style="${showFooterImage ? '' : 'display: none;'}"
      ></div>
    </div>
  `;
};

export { templateLayout };
