import i18n from 'i18next';
import { html } from 'lit-html';

const MyEsiosWidget23 = () => {
  return html`
    <div
      class="esios-gd esios-gd__widget widget-gd-desglose"
      id="gdDesgloseWidgetView"
    >
      <div class="esios-gd__row">
        <div class="row">
          <div class="col-xs-12">
            <h1 class="esios-gd__main-title esios-gd__main-title--aside">
              <!-- name of widget -->
            </h1>
          </div>
          <div class="col-xs-9">
            <div class="esios-toolbar">
              <!-- toolbar -->
              <div class="esios-toolbar23"></div>
            </div>
          </div>
          <div class="col-xs-3">
            <div class="esios-gd__selector"></div>
            <div class="esios-gd__selector" id="gd-timeSelector"></div>
          </div>
        </div>
      </div>
      <div id="widget23">
        <div
          id="dmNoDataRect2"
          class="esios-home__no-data-container_cuadrado hidden"
        >
          ${i18n.t('no-data-available')}
        </div>
        <div
          id="breakdownGraphic"
          class="esios-gd__row graph_gd"
          style="background-size:cover"
        >
          <!-- GRÁFICO DERECHO -->

          <div class="chari-chart gen-convencional piePotencia dibujoDemanda">
            <div id="spinner23" class="spinner-container">
              <div class="spinner"></div>
            </div>
          </div>

          <div class="widget-legend basic-legend legend-potencial-pie "></div>
        </div>
      </div>
    </div>
  `;
};

export default MyEsiosWidget23;
