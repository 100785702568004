'use strict';

/**
 * @description Dashboard Component
 * @returns {Object} Devuelve la instancia del componente
 */

import i18n from 'i18next';
import { componentDocumentList } from '../../shared/components/document-list/document-list.component.js';
import { sRender } from '../../utils/render.js';
import AnalysisHelp from '../analysis/form/analysis-form-help.js';
import { servicesAnalysisLanding } from './analysisLanding.services';
import { templateAnalysisLanding } from './analysisLanding.template';
let componentAnalysisLanding = (function () {
  let context = {};
  let handlers = {
    linkDates: (e) => linkDates(e),
  };
  let filterForm = {
    ambit: '',
    category: '',
    process: '',
    action: '',
    query: '',
  };

  let renderSuccess = false;

  let render = async () => {
    document.title = `${i18n.t('analysis')} | ${i18n.t('page_subtitle')}`;
    try {
      templateAnalysisLanding(context, handlers);

      // sRender(AnalysisHelp, 'help_btn');

      let newDLArray = {
        renderAgain: (form) => renderComponentDocumentList(form),
      };

      componentDocumentList.render(newDLArray, '#document-list-analysis');

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderComponentDocumentList = async (form) => {
    filterForm.ambit = form.ambit;
    filterForm.action = form.actions;
    filterForm.category = form.category;
    filterForm.process = form.process;
    filterForm.query = form.query;
    let documentListData = await servicesAnalysisLanding.getArchives(
      filterForm
    );

    let newDLArray = {
      data: documentListData.newIndicators,
      renderAgain: (form) => renderComponentDocumentList(form),
    };
    componentDocumentList.render(newDLArray, '#document-list-analysis');
  };

  return {
    render,
  };
})();

export { componentAnalysisLanding };
