import { html } from 'lit-html';

const AnalysisTitle = (title) => {
  return html`
    <div class="wrap">
      <div class="row is-xs-hidden">
        <div class="grid-xs-24" id="navView">
          <div id="titleView">
            <h1>${title}</h1>
          </div>
        </div>
      </div>
    </div>
  `;
};

export default AnalysisTitle;
