'use strict';
import i18next from 'i18next';
import { html } from 'lit-html';
import { componentLayout } from '../../components/layout/layout.component';
let templateSupplyCurves = (context, handlers) => {
  return componentLayout.render(
    html`
      <div class="esios-supply-curves">
        <h1>${i18next.t('supply_curves')}</h1>
        <p>${i18next.t('text_curves')}</p>
        <div class="row">
          <div class="col-sm-4">
            <div class="fixed-form">
              <div class="esios-period-selector">
                <div class="period-inputs">
                  <h3 class="esios-supply-curves__subtitle group-in">
                    ${i18next.t('curve_selection')}
                  </h3>
                  <div id="group-in-selector"></div>

                  <div class="border-select">
                    <h3 class="esios-supply-curves__subtitle">
                      ${i18next.t('border')}
                    </h3>
                    <div id="border-selector"></div>
                  </div>

                  <div class="auctions-select">
                    <h3 class="esios-supply-curves__subtitle">
                      ${i18next.t('auction_type')}
                    </h3>
                    <div id="auctions-type-selector"></div>
                  </div>

                  <h3 class="esios-supply-curves__subtitle">
                    ${i18next.t('date')}
                  </h3>

                  <div class="date-selectors">
                    <div class="date-hour-selector">
                      <div class="date-selector p-hour1">
                        <div
                          class="datepicker-mod mod-date mod-date-days is-day-selectable"
                        >
                          <input
                            id="datepicker"
                            class="datepicker-btn datepicker-abs"
                            name="datepicker"
                          />
                        </div>
                      </div>
                      <div class="hour-selector p-hour2">
                        <div id="hour-selector"></div>
                      </div>
                      <div id="month-btn" class="p-month-1"></div>
                      <div id="quarter-btn" class="p-quarter-1"></div>
                      <div id="year-btn" class="p-year-1"></div>
                    </div>
                  </div>

                  <div id="time-move-selector" class="move-select"></div>

                  <div class="direction1">
                    <h3 class="esios-supply-curves__subtitle ">
                      ${i18next.t('direction')}
                    </h3>
                    <div id="direction-selector"></div>
                  </div>

                  <div class="direction2">
                    <h3 class="esios-supply-curves__subtitle">
                      ${i18next.t('direction')}
                    </h3>
                    <div id="direction-selector-two"></div>
                  </div>
                </div>

                <div id="supply-buttons"></div>
              </div>
            </div>
          </div>
          <div class="col-sm-8">
            <div
              id="supplyCurvesWidgetView"
              class="supplyCurvesWidgetView col-sm-12"
            >
              <p class="esios-supply-curves__timeline-info"></p>

              <div class="esios-toolbar col-12">
                <div class="esios-toolbar3"></div>
              </div>
              <div id="spinner1" class="spinner-container">
                <div class="spinner"></div>
              </div>

              <div class="row">
                <div id="graphic" class="chari-chart aspect supply"></div>
                <div id="rect19" class="rect-no-data hidden">
                  ${i18next.t('no-data-available')}
                </div>
                <ul class="esios-listEnergyItems__list row"></ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    `,
    {
      image: false,
      showBanner: false,
      showFooterImage: true,
    }
  );
};

export { templateSupplyCurves };
