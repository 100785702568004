'use strict';

/**
 * @description Header Component
 * @returns {Object} Devuelve la instancia del componente
 */

import i18n from 'i18next';
import { render, html } from 'lit-html';
import { store } from '../../app.init';
import { templateMapLinks } from './mapasLinks.template.js';
import { mapLinksService } from './mapasLinks.services.js';

let componentMapLinks = (function () {
  let context = {};

  let renderSuccess = false;

  let state;

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    state = store.getState();
    context.name = state.exampleReducer.name;
    context.path = state.exampleReducer.pathname;
  };

  let renderComponent = async () => {
    state = store.getState();

    try {
      const map = state.exampleReducer.routeParams.map;

      // Render the template to the document
      const data = await mapLinksService.init(state.exampleReducer.currentLang);

      let selectedMap;

      data.map((element) => {
        if (element.slug == map) {
          selectedMap = element;
        }
      });

      // Define a template
      templateMapLinks(context, data, selectedMap);
      document.querySelector('#app');

      //Para poder cambiar el padding superior sin tocar layout directamente
      let content = document.getElementById('mapas-links');
      let layoutWrap = content.parentElement;
      layoutWrap.classList.add('esios-mapas-links__layoutPadding');

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }

    let title = document.querySelector('h1').textContent;
    document.title = `${title} | ${i18n.t('page_subtitle')}`;
  };

  let postRenderComponent = () => {};

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentMapLinks };
