import i18n from 'i18next';
import { html, render } from 'lit-html';
import { store } from '../../app.init';
import { componentSelect } from '../../shared/components/select/select.component';
import { componentTable } from '../../shared/components/table/table.component';
import { templateUsuarios } from './usuarios.template';

let componentUsuarios = (function () {
  let context = {};
  let handlers = {};
  let renderSuccess = false;

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    document.title = `${i18n.t('users')} | ${i18n.t('page_subtitle')}`;
    context.name = state.exampleReducer.name;
  };

  let renderComponent = async () => {
    const state = store.getState();
    const currentRowsToShow = state.exampleReducer.rowUsers;

    try {
      // Render the template to the document
      templateUsuarios();
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = () => {
    const users = [
      {
        id: 1,
        name: 'Juan Quintero',
        email: 'juan.quintero@meloinvento.com',
        role: 'admin',
      },
      {
        id: 2,
        name: 'Jose Manuel Garcia',
        email: 'jm.garcia@meloinvento.com',
        role: 'secondadmin',
      },
      {
        id: 3,
        name: 'Juan Quintero',
        email: 'juan.quintero@meloinvento.com',
        role: 'admin',
      },
      {
        id: 4,
        name: 'Jose Manuel Garcia',
        email: 'jm.garcia@meloinvento.com',
        role: 'secondadmin',
      },
      {
        id: 5,
        name: 'Juan Quintero',
        email: 'juan.quintero@meloinvento.com',
        role: 'admin',
      },
      {
        id: 6,
        name: 'Jose Manuel Garcia',
        email: 'jm.garcia@meloinvento.com',
        role: 'secondadmin',
      },
      {
        id: 7,
        name: 'Juan Quintero',
        email: 'juan.quintero@meloinvento.com',
        role: 'admin',
      },
      {
        id: 8,
        name: 'Jose Manuel Garcia',
        email: 'jm.garcia@meloinvento.com',
        role: 'secondadmin',
      },
      {
        id: 9,
        name: 'Juan Quintero',
        email: 'juan.quintero@meloinvento.com',
        role: 'admin',
      },
      {
        id: 10,
        name: 'Jose Manuel Garcia',
        email: 'jm.garcia@meloinvento.com',
        role: 'secondadmin',
      },
      {
        id: 11,
        name: 'Juan Quintero',
        email: 'juan.quintero@meloinvento.com',
        role: 'admin',
      },
      {
        id: 12,
        name: 'Jose Manuel Garcia',
        email: 'jm.garcia@meloinvento.com',
        role: 'secondadmin',
      },
    ];
    const columnsName = ['ID', 'Nombre', 'Email', 'Rol'];

    const state = store.getState();
    context.name = state.exampleReducer.name;
    componentTable.render('#table-user', columnsName, users);
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentUsuarios };
