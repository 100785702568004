'use strict';

import { default as i18n } from 'i18next';
import moment from 'moment-timezone';
import { store } from '../../app.init.js';
import { componentDocumentList } from '../../shared/components/document-list/document-list.component.js';
import { templateShareModal } from '../../shared/components/modal/modalShare.template.js';
import { componentSelects } from '../../shared/components/selects/selects.component.js';
import { componentTabs } from '../../shared/components/tabs/tabs.component.js';
import { componentTimeSelector } from '../../shared/components/time-selector/time-selector.component.js';
import { componentToolbar } from '../../shared/components/toolbar/toolbar.component';
import { dateSetTime } from '../../utils/dateFormat.js';
import { getUrlParams } from '../../utils/url.js';
import { Utils } from '../../utils/utils.js';
import { servicesgenerConsumo } from '../generacion y consumo/generConsumo.service.js';
import { gestionDemandaService } from './gestionDemanda.service';
import { templateGestionDemanda } from './gestionDemanda.template';
import { gestionDemandaRenderContent } from './renderContent.js';
import { gestionDemandaTabContent } from './tab.content.js';

let componentGestionDemanda = (function () {
  // changeSelectValue
  let dataInterruptible;
  let dataDesglose;
  let dataSNP;
  let dataTabs;
  let selectedBalance = 'ccaa';
  let state;
  let context = {};
  let handler = {
    changeTime: (value) => changeSelectValueDesglose(value),
  };
  let date = moment().tz('Europe/Madrid');
  let generalDate;
  //Accordion
  let setAccordion = (anchor) => {
    $(anchor).accordion({
      active: false,
      collapsible: true,
      icons: false,
      heightStyle: 'content',
    });
  };

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  let filterForm = {
    query: '',
    ambit: '',
    category: '',
    action: '',
    process: '',
  };

  let renderSuccess = false;
  let obToolbar = [];
  //COMPONENT TIME SELECTOR
  const selectTimeDesglose = {
    id: 'selectTimeDesglose',
    currentHour: date.format('HH'),
    currentMinutes: '00',
    optionsHours: Utils.getArrayOfHours(),
    optionsMinutes: Utils.getArrayOfMinutes(10),
    changeTime: (value) => changeSelectValue(value),
  };

  const changeSelectValue = (value) => {
    const { id, newValue } = value;
    let hours = newValue.split(':')[0];
    let minutes = newValue.split(':')[1];

    if (id === selectTimeDesglose.id) {
      selectTimeDesglose.optionsHours.map((el) => {
        if (el.value === hours) {
          selectTimeDesglose.currentHour = hours;
          selectTimeDesglose.currentMinutes = minutes;
          componentTimeSelector.render(selectTimeDesglose, '#gd-timeSelector');
          renderDesgloceAgain();
        }
      });
      return;
    }
  };
  const changeSelectValueDesglose = (val) => {
    const { value } = val;
    selectedBalance = value;
    renderDesgloceAgain();
  };

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    state = store.getState();
    // cambia el título en el navegador
    document.title = `${i18n.t('demand_management')} | ${i18n.t(
      'page_subtitle'
    )}`;
    ///COMPONENT TIME SELECTOR
    let otherIndicatorsTime = state.exampleReducer.pages.home.indicatorsTime;
    selectTimeDesglose.currentHour = otherIndicatorsTime.split(':')[0];
    selectTimeDesglose.currentMinutes = otherIndicatorsTime.split(':')[1];
  };
  const setParameter = (key, value) => {
    urlParams.set(key, value);
    history.pushState({}, '', window.location.pathname + '?' + urlParams);
  };

  let renderComponent = async () => {
    state = store.getState();
    let tempDate;
    if (getUrlParams().date) {
      tempDate = getUrlParams().date + 'T00%3A00';
    }
    //Objetos para cada toolbar de la página
    obToolbar = [
      {
        id: 1,
        slug: i18n.t('routes.interruptible-demand'),
        info: true,
        share: true,
        favorite: true,
        favId: 22,
        analysis: true,
        url: () =>
          `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.analysis'
          )}/847?compare_indicators=835&start_date=${
            tempDate ? tempDate : dateSetTime()
          }&geoids=19`,
      },
      { id: 2, info: true, share: false, favorite: false, analysis: false },
      {
        id: 3,
        slug: i18n.t('routes.interrumtible-breakdown'),
        info: true,
        share: true,
        favorite: true,
        favId: 23,
        analysis: true,
        url: () =>
          `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.analysis'
          )}/10059?compare_indicators=836,837,838,839,840,841,842,844,845,846&start_date=${dateSetTime()}&geoids=`,
      },
      { id: 4, info: true, share: false, favorite: false, analysis: false },
      { id: 5, info: true, share: false, favorite: false, analysis: false },
    ];

    try {
      //DOCS Y BOTONES DE DOCS
      let documents = gestionDemandaTabContent.returnTabContent('docs');

      let buttonsDoc = [
        {
          label: 'see_more_doc',
          url: `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.documentation'
          )}?scope=${i18n.t('demand_management').replaceAll(' ', '+')}`,
        },
        {
          label: `${i18n.t('downloads_files')}`,
          url: `/${i18n.t('routes.lng')}/${i18n.t('routes.downloads')}`,
        },
      ];

      let loaded = {
        22: false,
        23: false,
      };

      const loadedCheck = (id) => {
        loaded[id] = true;
        let allLoaded = Object.values(loaded).some(
          (isLoaded) => isLoaded == false
        );

        if (!allLoaded) {
          let inputCalendar = document.querySelector('#datepicker');
          let divCalendar = document.querySelector('.is-day-selectable');
          inputCalendar.removeAttribute('disabled');
          divCalendar.classList.remove('disabled');
        }
      };
      templateGestionDemanda(context, handler, documents, buttonsDoc);

      let data1 = await gestionDemandaService.getStaticPageDataForTab(1);
      let data2 = await gestionDemandaService.getStaticPageDataForTab(2);
      let data3 = await gestionDemandaService.getStaticPageDataForTab(3);
      let activations = await gestionDemandaService.getDataTable();
      let inputCalendar = document.querySelector('#datepicker');
      let divCalendar = document.querySelector('.is-day-selectable');
      inputCalendar.setAttribute('disabled', 'true');
      divCalendar.classList.add('disabled');

      let dateFromURL = urlParams.get('date');
      let now = moment().format('DD/MM/YYYY');
      let datVal = document.getElementById('datepicker').value.includes(' ');
      if (!datVal) {
        document.getElementById('datepicker').value = document
          .getElementById('datepicker')
          .value.replaceAll('/', ' / ');
      }
      let dateFromDatepickert = moment(
        document.getElementById('datepicker').value,
        'DD / MM / YYYY'
      ).format('DD-MM-YYYY');

      if (dateFromURL) {
        generalDate = dateFromURL;
      } else {
        setParameter('date', dateFromDatepickert);
        generalDate = dateFromDatepickert;
      }
      let minutes = Math.floor(date.format('mm') / 10) * 10;
      minutes = minutes <= 10 ? '00' : minutes - 10;
      //COMPONENT TIME SELECTOR
      let otherIndicatorsTime = state.exampleReducer.pages.home.indicatorsTime;
      selectTimeDesglose.currentHour = Utils.datesMatch(generalDate)
        ? otherIndicatorsTime.split(':')[0]
        : '00';
      selectTimeDesglose.currentMinutes = Utils.datesMatch(generalDate)
        ? minutes
        : '00';

      const spinnerNone = (id) =>
        (document.getElementById(id).style.display = 'none');

      let anchor1 = document.getElementById('accordion1');
      if (anchor1) {
        setAccordion(anchor1);
      }
      let anchor2 = document.getElementById('accordion2');
      if (anchor2) {
        setAccordion(anchor2);
      }
      let shareData = window.location.search;
      gestionDemandaService
        .getInterruptibleDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: state.exampleReducer,
          date: generalDate,
        })
        .then((data) => {
          setInfoModal(
            0,
            data,
            templateShareModal('interruptible-demand', shareData, false, {
              height: 648,
              width: '100%',
            }),
            'all'
          );
          spinnerNone('spinner22');
          gestionDemandaRenderContent.renderInterruptibleWidget(
            data,
            generalDate
          );

          window.addEventListener('resize', () => {
            document.querySelector('#dmGraphic svg').remove();
            let eyes = document.querySelectorAll(
              '#dmGraphic #widgetData ul .legend-icon-eye'
            );
            eyes.forEach((eye) => {
              eye.classList.remove('disabled');
            });
            gestionDemandaRenderContent.renderInterruptibleWidget(
              data,
              generalDate
            );

            Utils.showAndHideGraphLinesEyes(
              '#dmGraphic',
              '#dmGraphic #widgetData ul .legend-icon-eye'
            );
          });
          Utils.showAndHideGraphLinesEyes(
            '#dmGraphic',
            '#dmGraphic #widgetData ul .legend-icon-eye'
          );
        })
        .finally(() => {
          loadedCheck(22);
        });
      gestionDemandaService
        .getDesgloseDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: state.exampleReducer,
          date: generalDate,
          hours: selectTimeDesglose.currentHour,
          minutes: selectTimeDesglose.currentMinutes,
        })
        .then((data) => {
          spinnerNone('spinner23');
          setInfoModal(
            2,
            data,
            templateShareModal('interrumtible-breakdown', shareData, false, {
              height: 422,
              width: '100%',
            }),
            'all'
          );
          if (data.indicators[0]?.values.length === 0) {
            selectTimeDesglose.currentHour = '00';
            selectTimeDesglose.currentMinutes = '00';
          }
          componentTimeSelector.render(selectTimeDesglose, '#gd-timeSelector');

          gestionDemandaRenderContent.renderPie(data, selectedBalance);
          window.addEventListener('resize', () => {
            document.querySelector('#gdDesgloseWidgetView svg').remove();
            document
              .querySelector('#gdDesgloseWidgetView .widget-legend ul')
              .remove();
            gestionDemandaRenderContent.renderPie(data, selectedBalance);
          });
        })
        .finally(() => {
          loadedCheck(23);
        });

      gestionDemandaService
        .getSNPDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: state.exampleReducer,
          date: generalDate,
        })
        .then((data) => {
          gestionDemandaRenderContent.renderSNPWidget(data);
          setInfoModal(1, data, templateShareModal('power', shareData), 'all');
        });

      gestionDemandaService
        .getTabsDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: state.exampleReducer,
          date: generalDate,
        })
        .then((data) => {
          setInfoModal(3, data, templateShareModal('power', shareData), 'all');
        });

      servicesgenerConsumo
        .getDataFromWidget({
          id: '36',
          lang: state.exampleReducer.currentLang,
          globalState: state.exampleReducer,
          date: generalDate,
        })
        .then((data) => {
          setInfoModal(4, data, templateShareModal('power', shareData), 'all');
        });

      servicesgenerConsumo.getNumActivations(generalDate).then((data) => {
        data = data == 0 ? '-' : data;
        document.querySelectorAll('.nActivations').forEach((el) => {
          el.textContent = data;
        });
      });

      if (dateFromURL) {
        if (date !== now) {
          $('#datepicker').val(
            moment(dateFromURL, 'DD-MM-YYYY').format('DD / MM / YYYY')
          );
        }
      }

      let updateChars = async (currentDay = true) => {
        let inputCalendar = document.querySelector('#datepicker');
        let divCalendar = document.querySelector('.is-day-selectable');
        inputCalendar.setAttribute('disabled', 'true');
        divCalendar.classList.add('disabled');

        if (currentDay) {
          date = moment().tz('Europe/Madrid').format('DD/MM/YYYY');
        } else {
          date = $('#datepicker').val();
        }

        setParameter('date', moment(date, 'DD/MM/YYYY').format('DD-MM-YYYY'));
        selectTimeDesglose.currentHour = '00';
        selectTimeDesglose.currentMinutes = '00';
        await renderDesgloceAgain();
        await renderInterruptibleWidgetAgain();
        inputCalendar.removeAttribute('disabled');
        divCalendar.classList.remove('disabled');
      };

      $(document).on('change', '#datepicker', () => {
        updateChars(false);
      });

      Utils.clickHoy('datepicker', updateChars, true);

      let selectedTab = window.location.href.split('#');

      if (selectedTab[1] === 'general_information') {
        selectedTab = '1';
      } else if (selectedTab[1] === 'incoming_auctions') {
        selectedTab = '2';
      } else if (selectedTab[1] === 'providers_guide') {
        selectedTab = '3';
      } else {
        selectedTab = '1';
      }

      let miArrayTab1 = {
        activeTab: selectedTab,
        tabsContent: [
          {
            text: `${i18n.t('general_information')} `,
            class: 'tab1-gd-content',
            ancle: '',
            content: gestionDemandaTabContent.returnTabContent(
              1,
              data1.body,
              activations
            ),
          },
          {
            text: `${i18n.t('incoming_auctions')} `,
            class: 'tab1-gd-content',
            ancle: '',
            content: gestionDemandaTabContent.returnTabContent(
              2,
              data2.body,
              activations
            ),
          },
          {
            text: `${i18n.t('service_activation')} `,
            class: 'tab1-gd-content',
            ancle: '',
            content: gestionDemandaTabContent.returnTabContent(
              3,
              data3.body,
              activations
            ),
          },
        ],
      };

      let data4 = await gestionDemandaService.getStaticPageDataForTab(4);
      let data5 = await gestionDemandaService.getStaticPageDataForTab(5);
      let data6 = await gestionDemandaService.getStaticPageDataForTab(6);

      let miArrayTab2 = {
        activeTab: selectedTab,
        tabsContent: [
          {
            text: `${i18n.t('general_information')}`,
            class: 'tab2-gd-content',
            ancle: 'general_information',
            content: gestionDemandaTabContent.returnTabContent(
              4,
              data4.body,
              activations
            ),
          },
          {
            text: `${i18n.t('incoming_auctions')}`,
            class: 'tab2-gd-content',
            ancle: 'incoming_auctions',
            content: gestionDemandaTabContent.returnTabContent(
              5,
              data5.body,
              activations
            ),
          },
          {
            text: `${i18n.t('providers_guide')}`,
            class: 'tab2-gd-content',
            ancle: 'providers_guide',
            content: gestionDemandaTabContent.returnTabContent(
              6,
              data6.body,
              activations
            ),
          },
        ],
      };
      //TABS:
      //TAB 1:
      let table1Content1 = document.getElementById('tab1-gd-content1');
      table1Content1.innerHTML = miArrayTab1.tabsContent[0].content;
      let table1Content2 = document.getElementById('tab1-gd-content2');
      table1Content2.innerHTML = miArrayTab1.tabsContent[1].content;
      let table1Content3 = document.getElementById('tab1-gd-content3');
      table1Content3.innerHTML = miArrayTab1.tabsContent[2].content;
      componentTabs.render(miArrayTab1, '#tabwithcontent1');

      //TAB 2:
      let table2Content1 = document.getElementById('tab2-gd-content1');
      table2Content1.innerHTML = miArrayTab2.tabsContent[0].content;
      let table2Content2 = document.getElementById('tab2-gd-content2');
      table2Content2.innerHTML = miArrayTab2.tabsContent[1].content;
      let table2Content3 = document.getElementById('tab2-gd-content3');
      table2Content3.innerHTML = miArrayTab2.tabsContent[2].content;
      componentTabs.render(miArrayTab2, '#tabwithcontent2');

      //Mover padding
      movePaddingLayout();

      componentTimeSelector.render(selectTimeDesglose, '#gd-timeSelector');

      let newDLArray = {
        renderAgain: (form) => renderComponentDocumentList(form),
      };
      componentDocumentList.render(newDLArray, '#document-list-ges');
      document.querySelector('.esios-layout').classList.add('esios-layout--gd');
      document
        .querySelector('.esios-layout__wrap')
        .classList.add('esios-layout__wrap--gd');

      renderSuccess = true;
    } catch (error) {
      console.error(error);
      renderSuccess = false;
    }
  };

  let setInfoModal = (id, data, html, updateShareModal = 'all') => {
    if (updateShareModal !== 'share') {
      obToolbar[id].textModal = `<h2>${data.name}</h2>${data.description}`;
    }
    obToolbar[id].widgetData = data;

    obToolbar[id].datetime = urlParams.get('date')
      ? moment(urlParams.get('date'), 'DD-MM-YYYY')
          .tz('Europe/Madrid')
          .format('YYYY-MM-DD_HH_mm')
      : moment().tz('Europe/Madrid').format('YYYY-MM-DD_HH_mm');
    obToolbar[id].shareModal = html;
    componentToolbar.render(obToolbar[id], '.esios-toolbar' + (id + 1));
  };

  let postRenderComponent = () => {};

  //Para poder cambiar el padding superior sin tocar layout directamente
  function movePaddingLayout() {
    document
      .querySelector('.esios-layout__extended')
      .classList.add('esios-layout__extended--gd');
  }

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  let spinnerNone = (id) =>
    (document.getElementById(id).style.display = 'none');
  let spinnerTrue = (id) =>
    (document.getElementById(id).style.display = 'flex');

  let renderDesgloceAgain = async () => {
    document.querySelector('#datepicker').setAttribute('disabled', 'true');
    document.querySelector('.legend-potencial-pie ul')?.remove();
    document.querySelector('.piePotencia svg')?.remove();
    document.querySelector('.chartLegend')?.remove();
    let newDate = document.getElementById('datepicker').value.split(' / ');
    newDate = `${newDate[0]}-${newDate[1]}-${newDate[2]}`;
    let hours = selectTimeDesglose.currentHour;
    let minutes = selectTimeDesglose.currentMinutes;
    try {
      let shareData = window.location.search;
      spinnerTrue('spinner23');

      let dataDesgloce = await gestionDemandaService
        .getDesgloseDataFromWidget({
          lang: state.exampleReducer.currentLang,
          hours,
          minutes,
          date: newDate,
        })
        .then((data) => {
          if (data.indicators[0]?.values.length === 0) {
            selectTimeDesglose.currentHour = '00';
            selectTimeDesglose.currentMinutes = '00';
          }
          componentTimeSelector.render(selectTimeDesglose, '#gd-timeSelector');
          spinnerNone('spinner23');
          gestionDemandaRenderContent.renderPie(data, selectedBalance);

          renderSuccess = true;
          setInfoModal(
            2,
            data,
            templateShareModal('interrumtible-breakdown', shareData, false, {
              height: 422,
              width: '100%',
            }),
            'all'
          );

          return data;
        });
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderInterruptibleWidgetAgain = async () => {
    try {
      let shareData = window.location.search;
      spinnerTrue('spinner22');
      let dataGestion = await gestionDemandaService
        .getInterruptibleDataFromWidget({
          lang: state.exampleReducer.currentLang,
          date,
        })
        .then((data) => {
          setInfoModal(
            0,
            data,
            templateShareModal('interruptible-demand', shareData, false, {
              height: 648,
              width: '100%',
            }),
            'all'
          );
          spinnerNone('spinner22');
          document.querySelector('#gdDemandaWidgetView svg')?.remove();
          gestionDemandaRenderContent.renderInterruptibleWidget(data);
          Utils.showAndHideGraphLinesEyes(
            '#dmGraphic',
            '#dmGraphic #widgetData ul .legend-icon-eye'
          );
          window.addEventListener('resize', () => {
            document.querySelector('#gdDemandaWidgetView svg')?.remove();
            let eyes = document.querySelectorAll(
              '#dmGraphic #widgetData ul .legend-icon-eye'
            );
            eyes.forEach((eye) => {
              eye.classList.remove('disabled');
            });
            gestionDemandaRenderContent.renderInterruptibleWidget(data);

            Utils.showAndHideGraphLinesEyes(
              '#dmGraphic',
              '#dmGraphic #widgetData ul .legend-icon-eye'
            );
          });
          return data;
        });

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderComponentDocumentList = async (form) => {
    filterForm.ambit = form.ambit;
    filterForm.action = form.actions;
    filterForm.category = form.category;
    filterForm.process = form.process;
    filterForm.query = form.query;
    let documentListData = await gestionDemandaService.getArchives(filterForm);

    let newDLArray = {
      data: documentListData.newIndicators,
      renderAgain: (form) => renderComponentDocumentList(form),
    };
    componentDocumentList.render(newDLArray, '#document-list-ges');
  };

  return {
    render: init,
  };
})();

export { componentGestionDemanda };
