'use strict';
import { html } from 'lit-html';
import { store } from '../../app.init';
import { setNameAction } from '../../state/actions';
import { forEach } from 'lodash';
import { componentTable } from '../../shared/components/simpletable/simpletable.component';

let templateTab = (context, handlers, data) => {
  /**AQUÍ ESTOY SIMULANDO LOS TABS DINAMICOS PUEDES CREAR N TABS */

  /**función que remueve la clase q activa un tab */
  const removeClassActive = () => {
    /**Esto es para los tabs */
    const listItems = document.querySelectorAll('.ui-corner-top');
    listItems.forEach((item) => item.classList.remove('ui-tabs-active'));
  };

  const showTable = (itemTab) => {
    const listItems = document.querySelectorAll('.ui-corner-top');
    const index = Array.from(listItems).indexOf(itemTab);

    /**Esto es para el contenedor de las tablas */
    const listItemsContainer = document.querySelectorAll('.ui-tabs-panel');
    listItemsContainer.forEach((item) => {
      item.classList.remove('ui-tabs-active');
      return item.classList.add('ui-tabs-inactive');
    });

    listItemsContainer[index].classList.remove('ui-tabs-inactive');
    listItemsContainer[index].classList.add('ui-tabs-active');
  };

  /**Evento que detecta un click */
  const handleClick = (e) => {
    e.preventDefault(); // evita el refresh de la pagina al momento de hacer clic, para una etiqueta a
    removeClassActive();
    e.target.parentElement.classList.add('ui-tabs-active');
    showTable(e.target.parentElement);

    let elem = document.getElementsByClassName('ui-tabs-active');
    let text = Array.from(elem)[0].innerText;

    if (
      text === 'SUBASTAS' ||
      text === 'AUCTIONS' ||
      text === 'GENERAL INFORMATION' ||
      text === 'INFORMACIÓN GENERAL' ||
      text === 'PROVIDERS GUIDE' ||
      text === 'ÁREA DE PROVEEDORES'
    ) {
      window.scroll({
        top: 1300,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const mouseover = (e) => {
    /**Esto es para cambiar los estilos del tab cuando el mouse este sobre el mismo */
    changeTabStyle(e, 'add');
  };

  const mouseout = (e) => {
    /**Esto es para cambiar los estilos del tab cuando el mouse ya no este sobre el mismo */
    changeTabStyle(e, 'remove');
  };

  const changeTabStyle = (e, tipo) => {
    let itemTab = e.target.parentElement;

    const listItems = document.querySelectorAll('.ui-corner-top');
    const index = Array.from(listItems).indexOf(itemTab);

    const item = listItems[index].children;
    if (tipo === 'add') {
      item[0].classList.add('ui-tabs-over');
    } else if (tipo === 'remove') {
      item[0].classList.remove('ui-tabs-over');
    }
  };

  /**Función que construye los tabs */
  const buildTab = (array) => {
    return html`${array.map((item, index) => {
      let className =
        index === 0
          ? 'ui-state-default ui-corner-top ui-tabs-active'
          : 'ui-state-default ui-corner-top';
      return html`<li class=${className}>
        <a
          class="ui-tabs-anchor"
          href="#"
          @click=${handleClick}
          @mouseover=${mouseover}
          @mouseout=${mouseout}
        >
          ${item.text}
        </a>
      </li>`;
    })} `;
  };

  /**Función que construye los tabs */
  const buildContainer = (array) => {
    return html`${array.map((item, index) => {
      let className =
        index === 0
          ? 'ui-tabs-panel ui-tabs-active ui-widget-content ui-corner-bottom'
          : 'ui-tabs-panel ui-tabs-inactive ui-widget-content ui-corner-bottom';
      return html` <div id="table-${index}" class=${className}>
        ${html([item.content])}
      </div>`;
    })} `;
  };

  /** */
  return html`
    <div class=" esios-tab ">
      <div class="row">
        <div class="grid-sm-24">
          <div
            class="mod-tabs ui-tabs ui-widget ui-widget-content ui-corner-all"
          >
            <ul
              class="ui-tabs-nav ui-helper-reset ui-helper-clearfix ui-widget-header ui-corner-all"
            >
              ${buildTab(data)}
            </ul>

            ${buildContainer(data)}
          </div>
        </div>
      </div>
    </div>
  `;
};

export { templateTab };
