'use strict';

/**
 * @description Mapas de interés Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { store } from '../../app.init.js';
import { mapasInteresService } from './mapasInteres.service';
import { templateMapasInteres } from './mapasInteres.template';
import i18n from 'i18next';

let componentMapasInteres = (function () {
  let context = {};
  let renderSuccess = false;

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    document.title = `${i18n.t('maps')} | ${i18n.t('page_subtitle')}`;
    context.name = state.exampleReducer.name;
    context.path = state.exampleReducer.pathname;
  };

  let renderComponent = async () => {
    const state = store.getState();
    try {
      // Render the template to the document
      let mapsData = await mapasInteresService.init(
        state.exampleReducer.currentLang
      );

      templateMapasInteres(context, mapsData);

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentMapasInteres };
