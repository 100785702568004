import i18n from 'i18next';
import { render } from 'lit-html';
import moment from 'moment-timezone';
import { store } from '../../../app.init';
import { templateShareModal } from '../../../shared/components/modal/modalShare.template';
import { componentTimeSelector } from '../../../shared/components/time-selector/time-selector.component';
import { componentToolbar } from '../../../shared/components/toolbar/toolbar.component';
import { charts } from '../../../utils/options-charts';
import { sRender } from '../../../utils/render';
import { Utils } from '../../../utils/utils';
import { componentgenerConsumo } from '../../generacion y consumo/generConsumo.component';
import { servicesgenerConsumo } from '../../generacion y consumo/generConsumo.service';
import { gestionDemandaService } from '../../gestion-demanda/gestionDemanda.service';
import { gestionDemandaRenderContent } from '../../gestion-demanda/renderContent';
import { servicesHome } from '../../home/home.services';
import { renderMercadosPrecios } from '../../mercados-precios/mercadosPreciosRenders';
import { servicesnoPeninsulares } from '../../noPeninsulares/noPeninsulares.services';
import { templatenoPeninsulares } from '../../noPeninsulares/noPeninsulares.template';
import servicesMyEsios from '../my-esios.services';

let servicesMyEsiosChart = (() => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const showSpinner = (show, id) => {
    let type = show ? 'flex' : 'none';
    !!document.getElementById(id)
      ? (document.getElementById(id).style.display = type)
      : '';
  };

  const updateDateTime = (id, date) => {
    document.getElementById(id).innerHTML = date;
  };

  let selectTimeDesglose,
    selectDesglose,
    selectTimeForGenConventional,
    selectTimeForFreeGeneration,
    selectedBalance,
    selectTimeForIndicators,
    selectTimeForElectricBalance,
    selectTimeForVolume,
    selectTimeForCost;
  const postRenderChart = async (id, data) => {
    let date = document.getElementById('datepicker').value;
    let currentHour = moment(date, 'DD-MM-YYYY').hours();
    let currentMinutes = moment(date, 'DD-MM-YYYY').minutes();
    let currentDay = moment().tz('Europe/Madrid');
    let sameDate = false;

    date == currentDay.format('DD / MM / YYYY') ||
    date == currentDay.format('DD/MM/YYYY') + '' ||
    date == currentDay.format('DD-MM-YYYY')
      ? (sameDate = true)
      : (sameDate = false);

    currentHour = sameDate ? currentDay.format('HH') : '00';
    let minutes = Math.floor(currentDay.format('mm') / 10) * 10;
    minutes = minutes <= 10 ? '00' : minutes - 10;
    currentMinutes = `${sameDate ? minutes : '00'}`;

    if (id == 1) {
      selectTimeForGenConventional = {
        id: 'select_conventional',
        currentHour,
        currentMinutes,
        optionsHours: Utils.getArrayOfHours(),
        optionsMinutes: [],
        changeTime: (value) => changeSelectValue(value),
      };
      componentTimeSelector.render(
        selectTimeForGenConventional,
        '#gen_conventional-time'
      );
      return;
    }

    if (id == 3) {
      currentHour == 0 ? (currentHour = '00') : '';
      selectTimeForFreeGeneration = {
        id: 'select_other_free_gen',
        currentHour,
        currentMinutes:
          currentMinutes < 10
            ? '00'
            : currentMinutes.toString().substring(0, 1) + '0',
        optionsHours: Utils.getArrayOfHours(),
        optionsMinutes: Utils.getArrayOfMinutes(5),
        changeTime: (value) => changeSelectValue(value),
      };
      componentTimeSelector.render(
        selectTimeForFreeGeneration,
        '#free-gen-timeSelector'
      );
      return;
    }

    if (id == 4) {
      selectTimeForElectricBalance = {
        id: 'select_electrict_balance',
        currentHour,
        currentMinutes: '00',
        optionsHours: Utils.getArrayOfHours(),
        optionsMinutes: [],
        changeTime: (value) => changeSelectValue(value),
      };
      componentTimeSelector.render(
        selectTimeForElectricBalance,
        '#electric-balance-time'
      );
      return;
    }

    if (id == 6) {
      selectTimeForVolume = {
        id: 'select_volumen',
        datetimeFor: 'volumeTime',
        currentHour,
        currentMinutes: '00',
        optionsHours: Utils.getArrayOfHours(),
        optionsMinutes: [],
        changeTime: (value) => changeSelectValue(value),
      };
      componentTimeSelector.render(
        selectTimeForVolume,
        '#mypVolumenWidgetViewSelector'
      );
      return;
    }

    if (id == 7) {
      let currentHour = '00';
      if (sameDate) {
        currentHour = moment().hours();
      }
      selectTimeForCost = {
        id: 'select_cost',
        datetimeFor: 'volumeTime',
        currentHour,
        currentMinutes: '00',
        optionsHours: Utils.getArrayOfHours(),
        optionsMinutes: [],
        changeTime: (value) => changeSelectValue(value),
      };
      componentTimeSelector.render(
        selectTimeForCost,
        '#mypCosteWidgetViewSelector'
      );
      return;
    }

    if (id == 12) {
      const state = store.getState();
      let dateCurrentTime = document.getElementById('datepicker').value;
      let datetimeFormated = moment(dateCurrentTime, 'DD / MM / YYYY')
        .tz('Europe/Madrid')
        .format('YYYY-MM-DDTHH:mm:ss');
      servicesnoPeninsulares
        .init(state.exampleReducer.currentLang)
        .then((snpData) => {
          servicesnoPeninsulares
            .getTitles(state.exampleReducer.currentLang, 12, datetimeFormated)
            .then((title) => {
              let htmlNoPeninsulares = templatenoPeninsulares(
                snpData,
                '',
                '',
                title,
                '',
                data,
                '',
                '',
                '',
                id
              );
              render(
                htmlNoPeninsulares,
                document.querySelector('.esios-myesios__widget' + id)
              );
              drawNoPeninsularesToolBar(id, data, snpData);
            });
        });
    }

    if (id == 13) {
      const state = store.getState();
      servicesnoPeninsulares
        .init(state.exampleReducer.currentLang)
        .then((snpData) => {
          let htmlNoPeninsulares = templatenoPeninsulares(
            snpData,
            '',
            '',
            '',
            '',
            '',
            data,
            '',
            '',
            id
          );
          render(
            htmlNoPeninsulares,
            document.querySelector('.esios-myesios__widget' + id)
          );
          drawNoPeninsularesToolBar(id, data, snpData);
        });
    }

    if (id == 14) {
      const state = store.getState();
      servicesnoPeninsulares
        .init(state.exampleReducer.currentLang)
        .then((snpData) => {
          let htmlNoPeninsulares = templatenoPeninsulares(
            snpData,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            data,
            id
          );
          render(
            htmlNoPeninsulares,
            document.querySelector('.esios-myesios__widget' + id)
          );
          drawNoPeninsularesToolBar(id, data, snpData);
        });
    }

    if (id == 15) {
      const state = store.getState();
      servicesnoPeninsulares
        .init(state.exampleReducer.currentLang)
        .then((snpData) => {
          let htmlNoPeninsulares = templatenoPeninsulares(
            snpData,
            '',
            '',
            '',
            '',
            '',
            '',
            data,
            '',
            id
          );
          render(
            htmlNoPeninsulares,
            document.querySelector('.esios-myesios__widget' + id)
          );
          drawNoPeninsularesToolBar(id, data, snpData);
        });
    }

    if (id == 17) {
      Utils.showAndHideGraphLinesEyes(
        '#char17',
        '#char17 #widget17Data .icon-eye'
      );
    }

    if (id == 18) {
      Utils.showAndHideGraphLinesEyes(
        '#char18',
        '#char18 #widget18Data .icon-eye'
      );
    }

    if (id == 19) {
      let dateLastDayWithData;
      if (data.indicators[0].print_values[0].datetime) {
        dateLastDayWithData = data.indicators[0].print_values[0].datetime;
      } else {
        new Date().subtract(2, 'days');
      }

      let dateParse = moment(dateLastDayWithData).format('DD / MM / YYYY');
      updateDateTime('demand', dateParse);
      return;
    }

    if (id == 22) {
      Utils.showAndHideGraphLinesEyes(
        '#char22',
        '#char22 #widget22Data .icon-eye'
      );
    }

    if (id == 23) {
      selectedBalance = 'ccaa';

      selectTimeDesglose = {
        id: 'selectTimeDesglose',
        currentHour: currentHour,
        currentMinutes: currentMinutes,
        optionsHours: Utils.getArrayOfHours(),
        optionsMinutes: Utils.getArrayOfMinutes(5),
        changeTime: (value) => changeSelectValue(value),
      };

      selectDesglose = {
        id: 'select_desglose_widget',
        currentLabel: 'ccaa',
        currentValue: 'ccaa',
        label: '',
        options: [
          { label: 'ccaa', value: 'ccaa' },
          { label: 'sectors', value: 'sector' },
        ],
        columns: 1,
        onlySelect: true,
        changeTime: (value) => changeSelectValue(value),
      };
      componentTimeSelector.render(selectTimeDesglose, '#gd-timeSelector');
      return;
    }

    if (id == 25) {
      selectTimeForIndicators = {
        id: 'select_otherIndicators',
        datetimeFor: 'otherIndicatorsTime',
        currentHour,
        currentMinutes,
        optionsHours: Utils.getArrayOfHours(),
        optionsMinutes: Utils.getArrayOfMinutes(5),
        changeTime: (value) => changeSelectValue(value),
      };
      componentTimeSelector.render(
        selectTimeForIndicators,
        '#otherIndicatorsTimeSelector'
      );
      return;
    }

    if (id == 20) {
      let dateParse =
        moment(data.indicators[0].values[0].x)
          .tz('Europe/Madrid')
          .format('MM / YYYY') || '-- / --';
      updateDateTime('measured-generation', dateParse);
      return;
    }

    if (id == 2) {
      Utils.showAndHideGraphLinesEyes(
        '#char2',
        '#char2 #widget2Data .icon-eye'
      );
      return;
    }
  };

  const changeSelectValue = (value) => {
    let date = document.getElementById('datepicker').value;

    const { id, newValue } = value;
    let hours = newValue.split(':')[0];
    let minutes = newValue.split(':')[1];

    if (selectTimeForGenConventional?.id === id) {
      document.getElementById('spinner1').style.cssText = `
        position: absolute;
        top: 50%;
        left: 45%;
        width: 100%;
        height: 100%;
        background: white;
        z-index:1;
        opacity: .7;`;
      selectTimeForGenConventional.currentHour = hours;
      selectTimeForGenConventional.currentMinutes = minutes;

      servicesgenerConsumo
        .getDataFromWidget({
          id: 1,
          hours,
          minutes,
          date,
        })
        .then((data) => {
          showSpinner(false, 'spinner1');
          componentTimeSelector.render(
            selectTimeForGenConventional,
            '#gen_conventional-time'
          );
          document.querySelector('#char1 .gen-bar svg')?.remove();
          document.querySelector('#char1 .piePotencia svg')?.remove();
          document.querySelector('#char1 .piePotencia .legend')?.remove();
          componentgenerConsumo.renderDatageneracionPublic(data);
        });
    }

    if (selectTimeForIndicators?.id === id) {
      const state = store.getState();
      selectTimeForIndicators.currentHour = hours;
      selectTimeForIndicators.currentMinutes = minutes;

      servicesHome
        .getDataOtherIndicators('25', {
          lang: state.exampleReducer.currentLang,
          date,
          globalState: newValue,
        })
        .then((data) => {
          componentTimeSelector.render(
            selectTimeForIndicators,
            '#otherIndicatorsTimeSelector'
          );
          charts[25](data);
        });
    }

    if (selectTimeForFreeGeneration?.id === id) {
      showSpinner(true, 'spinner3');
      selectTimeForFreeGeneration.currentHour = hours;
      selectTimeForFreeGeneration.currentMinutes = minutes;
      servicesgenerConsumo
        .getDataFromWidget({
          id: 3,
          hours,
          minutes,
          date,
        })
        .then((data) => {
          showSpinner(false, 'spinner3');
          componentTimeSelector.render(
            selectTimeForFreeGeneration,
            '#free-gen-timeSelector'
          );
          componentgenerConsumo.renderDataLibrePublic(data);
        });
    }

    if (selectTimeForElectricBalance?.id === id) {
      showSpinner(true, 'spinner4');
      selectTimeForElectricBalance.currentHour = hours;
      selectTimeForElectricBalance.currentMinutes = minutes;
      servicesgenerConsumo
        .getDataFromWidget({
          id: 4,
          hours,
          minutes,
          date,
        })
        .then((data) => {
          showSpinner(false, 'spinner4');
          document.querySelector('#gycBalanceWidgetView .dibujo svg').remove();
          document.querySelector('#gycBalanceWidgetView .dibujoR svg').remove();

          componentTimeSelector.render(
            selectTimeForElectricBalance,
            '#electric-balance-time'
          );
          componentgenerConsumo.renderDataBalancePublic(data);
        });
    }

    if (selectTimeForVolume?.id === id) {
      showSpinner(true, 'spinner6');
      selectTimeForVolume.currentHour = hours;
      selectTimeForVolume.currentMinutes = minutes;
      servicesgenerConsumo
        .getDataFromWidget({
          id: 6,
          hours,
          minutes,
          date,
        })
        .then((data) => {
          showSpinner(false, 'spinner6');
          document.querySelector('#mypVolumenWidgetView svg').remove();
          document
            .querySelector('#mypVolumenWidgetView .esios-listEnergyItems__list')
            .remove();

          componentTimeSelector.render(
            selectTimeForVolume,
            '#mypVolumenWidgetViewSelector'
          );
          renderMercadosPrecios.renderVolumeGraph(data);
        });
    }

    if (selectTimeForCost?.id === id) {
      showSpinner(true, 'spinner7');
      selectTimeForCost.currentHour = hours;
      selectTimeForCost.currentMinutes = minutes;
      servicesgenerConsumo
        .getDataFromWidget({
          id: 7,
          hours,
          minutes,
          date,
        })
        .then((data) => {
          showSpinner(false, 'spinner7');
          document.querySelector('#mypCosteWidgetView svg').remove();
          document.querySelector('.informativeWindow').remove();
          document
            .querySelector('#mypCosteWidgetView .esios-listEnergyItems__list')
            .remove();

          componentTimeSelector.render(
            selectTimeForCost,
            '#mypCosteWidgetViewSelector'
          );

          renderMercadosPrecios.renderUnitaryCostGraph(data);
        });
    }

    if (selectTimeDesglose?.id === id) {
      selectTimeDesglose.optionsHours.map((el) => {
        if (el.value === hours) {
          selectTimeDesglose.currentHour = hours;
          selectTimeDesglose.currentMinutes = minutes;
          componentTimeSelector.render(selectTimeDesglose, '#gd-timeSelector');
          (selectedBalance = 'selected'),
            document
              .querySelector('#select_desglose_widget .option-selected')
              .getAttribute('value');
          renderDesgloceAgain();
        }
      });
    }
  };
  const drawNoPeninsularesToolBar = (id, data, dataWidget) => {
    const idZone = {
      12: 'baleares',
      13: 'canarias',
      14: 'ceuta',
      15: 'melilla',
    };

    let textModal = dataWidget.body;
    textModal = `
        <div class="esios-noPeninsulares__title">
          <h1>${idZone[id].toUpperCase()}</h1>
        </div>
      ${data[0]?.description}`;

    let toolBarOption = {
      id,
      slug: idZone[id],
      info: true,
      share: true,
      favorite: true,
      favId: id,
      analysis: true,
      textModal,
      myEsios: true,
      url: () => data[0].urlAnalysis,
    };
    toolBarOption.widgetData = { indicators: data, slug: idZone[id] };
    let shareData = window.location.search;
    toolBarOption.shareModal = templateShareModal(idZone[id], shareData, true);

    toolBarOption.datetime = urlParams.get('date')
      ? moment(urlParams.get('date'), 'DD-MM-YYYY')
          .tz('Europe/Madrid')
          .format('YYYY-MM-DD_HH_mm')
      : moment().tz('Europe/Madrid').format('YYYY-MM-DD_HH_mm');
    componentToolbar.render(toolBarOption, '.esios-toolbar' + id);
    let dateInput = document.querySelector(
      `.esios-noPeninsulares__date.${idZone[id]}`
    );
    if (data) {
      dateInput.innerText =
        moment(data[0].print_values[0]?.datetime)
          .tz('Europe/Madrid')
          .format('DD / MM / YYYY') || '-- / -- / --';
    }
  };

  let renderDesgloceAgain = async () => {
    let spinnerNone = (id) =>
      (document.getElementById(id).style.display = 'none');
    let spinnerTrue = (id) =>
      (document.getElementById(id).style.display = 'flex');

    document.querySelector('#datepicker').setAttribute('disabled', 'true');
    document.querySelector('.legend-potencial-pie ul')?.remove();
    document.querySelector('.piePotencia svg')?.remove();
    document.querySelector('.chartLegend')?.remove();
    let newDate = document.getElementById('datepicker').value.split(' / ');
    newDate = `${newDate[0]}-${newDate[1]}-${newDate[2]}`;
    let hours = selectTimeDesglose.currentHour;
    let minutes = selectTimeDesglose.currentMinutes;
    let state = store.getState();
    try {
      let shareData = window.location.search;
      spinnerTrue('spinner23');
      await gestionDemandaService
        .getDesgloseDataFromWidget({
          lang: state.exampleReducer.currentLang,
          hours,
          minutes,
          date: newDate,
        })
        .then((data) => {
          const svgNoData = document.getElementById('breakdownGraphic');
          const rectNoData = document.getElementById('dmNoDataRect2');
          if (data.indicators[0]?.values.length === 0) {
            selectTimeDesglose.currentHour = '00';
            selectTimeDesglose.currentMinutes = '00';
            rectNoData.classList.remove('hidden');
            svgNoData.classList.add('no-data-graphic');
          } else {
            rectNoData?.classList.add('hidden');
            svgNoData?.classList.remove('no-data-graphic');
          }

          componentTimeSelector.render(selectTimeDesglose, '#gd-timeSelector');
          spinnerNone('spinner23');
          document.querySelector('#datepicker').removeAttribute('disabled');
          gestionDemandaRenderContent.renderPie(data, selectedBalance);
          setInfoModal(
            2,
            data,
            templateShareModal('interrumtible-breakdown', shareData, false, {
              height: 422,
              width: '100%',
            }),
            'all'
          );
        });
    } catch (error) {
      document.querySelector('#datepicker').removeAttribute('disabled');
    }
  };

  return {
    postRenderChart,
  };
})();

export default servicesMyEsiosChart;
