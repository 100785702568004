import i18next from 'i18next';
import { html } from 'lit-html';

const TemplateEmbedIndicatorsErni = () => {
  return html` <div
    class="col-sm-12 col-xs-12 mypErniWidgetView"
    id="mypErniWidgetView"
  >
    <div id="spinner42" class="spinner-container">
      <div class="spinner"></div>
    </div>
    <div class="row">
      <div id="mypGraphic5" class="chari-chart">
        <div
          id="mypNoDataRect5"
          class="esios-home__no-data-container_cuadrado hidden"
        >
          ${i18next.t('no-data-available')}
        </div>
      </div>
      <ul class="esios-listEnergyItems__list">
        <div class="row"></div>
      </ul>
    </div>

    <div class="col-xs-24 col-sm-24 mt-5"></div>
  </div>`;
};

export default TemplateEmbedIndicatorsErni;
