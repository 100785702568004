export function geoParseDate(stringDate) {
  let parsedDate;
  let splitDate = stringDate.split('T');
  const [day, month, year] = splitDate[0].split('-');
  parsedDate = `${year}-${month}-${day} `;
  return parsedDate + splitDate[1] + ':00';
}
export const getAgg = (magnitud) => {
  const avgIndicators = [20, 23, 21, 152, 153, 209, 211]; // potencia, precio, capacidad, coeficiente, precio emision CO2
  let aggregation;

  avgIndicators.includes(magnitud)
    ? (aggregation = 'AVG')
    : (aggregation = 'SUM');

  return aggregation;
};

export const getGeoAgg = (magnitud) => {
  const avgIndicators = [23, 152, 211]; // precio, coeficiente, precio emision CO2
  let aggregation;

  avgIndicators.includes(magnitud)
    ? (aggregation = 'AVG')
    : (aggregation = 'SUM');

  return aggregation;
};
