import i18n from 'i18next';
import { render } from 'lit-html';
import { store } from '../../app.init';
import { templateSeeUsuarios } from './see-usuarios.template';

let componentSeeUsuario = (function () {
  let context = {};
  let handlers = {};
  let renderSuccess = false;

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    document.title = `${i18n.t('users')} | ${i18n.t('page_subtitle')}`;
    context.name = state.exampleReducer.name;
  };

  let renderComponent = async () => {
    const state = store.getState();
    const user = {
      id: 1,
      name: 'Juan Quintero',
      email: 'juan.quintero@meloinvento.com',
      role: 'admin',
    };
    try {
      // Render the template to the document
      templateSeeUsuarios(user);
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentSeeUsuario };
