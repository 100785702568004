'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';
import backImage from '../../assets/images/header-welcome.jpg';
import { componentLayout } from '../../components/layout/layout.component';
import { templateToolbarTime } from '../../shared/components/toolbarTime/toolbarTime.template';
import { getUrlParams } from '../../utils/url';

let templateHome = () => {
  let { date } = getUrlParams();

  return componentLayout.render(
    html`
      <div class="esios-home">
        <div class="esios-home__container">
          <div class="esios-home__left-container">
            <div class="esios-home__container-gc">
              <div class="esios-home__gc">
                <div id="char17" class="homeGyCWidgetView">
                  <h1 class="esios-home__title">
                    ${i18n.t('generation_and_consumption')}
                  </h1>
                  <div class="esios-toolbar">
                    <div class="esios-toolbar1"></div>
                    ${templateToolbarTime('generation-consumption')}
                  </div>

                  <div>
                    <div id="widget17" class="generacion-consum">
                      <div
                        id="rect17"
                        style="display:none"
                        class="esios-home__no-data-container_cuadrado"
                      >
                        ${i18n.t('no-data-available')}
                      </div>
                      <div id="spinner17" class="spinner-container">
                        <div class="spinner"></div>
                      </div>
                    </div>
                    <div
                      id="widget17Data"
                      class="esios-home__widget-legend highlighted-legend visual-legend"
                    ></div>
                  </div>
                </div>
              </div>
              <hr class="small-hr" />
              <div class="esios-home__container-other">
                <div id="homeOtherIndicators" class="homeOtherIndicators">
                  <h1 class="esios-home__title mt-3">
                    ${i18n.t('other_indicators')}
                  </h1>
                  <div class="esios-toolbar col-12">
                    <div class="esios-toolbar2"></div>
                    <!-- ${templateToolbarTime('other-indicators')} -->

                    <!-- TOOLBARTIME -->
                    <div id="otherIndicatorsTimeSelector"></div>
                  </div>
                </div>

                <div class="grid-xs-24 grid-sm-24">
                  <div
                    id="widget25"
                    class="esios-home__widget-legend basic-summary"
                  >
                    <div id="spinner25" class="spinner-container">
                      <div class="spinner"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="esios-home__general-container-mp">
              <div class="esios-home__container-mp">
                <div class="esios-home__container-graphic-mp">
                  <div class="content-grid__mp" id="homeMyPWidgetView">
                    <div
                      id="char18"
                      class="esios-home__component homeMyPWidgetView"
                    >
                      <h1 class="esios-home__title">
                        ${i18n.t('markets_and_prices')}
                      </h1>
                      <div class="esios-toolbar col-12">
                        <div class="esios-toolbar3"></div>
                        ${templateToolbarTime('mercade-prices')}
                      </div>
                      <div>
                        <div id="widget18" class="mercadosyprecios">
                          <div
                            id="rect18"
                            style="display:none"
                            class="esios-home__no-data-container_cuadrado"
                          >
                            ${i18n.t('no-data-available')}
                          </div>
                          <div id="spinner18" class="spinner-container">
                            <div class="spinner"></div>
                          </div>
                        </div>

                        <div
                          id="widget18Data"
                          class="esios-home__widget-legend highlighted-legend visual-legend"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="container-datos"></div>
                </div>
                <div class="esios-home__container-highlight">
                  <div class="mod-highlight mod-highlight--pvpc pvpc-home">
                    <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.pvpc')}">
                      <h1>PVPC</h1>
                      <p>${i18n.t('pvpc_highlightv2')}</p>
                    </a>
                  </div>
                </div>

                <hr class="hr-pvpc" />

                <div class="esios-home__container-highlight">
                  <div class="mod-highlight mod-highlight--analysis analysis">
                    <a
                      href="/${i18n.t('routes.lng')}/${i18n.t(
                        'routes.analysis'
                      )}"
                    >
                      <h1>${i18n.t('analysis')}</h1>
                      <p>${i18n.t('analysis_highlight')}</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="esios-home__container-demanda">
              <div id="char19" class="esios-home__demanda">
                <h1 class="esios-home__title">
                  ${i18n.t('non-peninsular_demand')}
                </h1>
                <div class="esios-toolbar col-12">
                  <div class="esios-toolbar5"></div>
                  ${templateToolbarTime('demand')}
                </div>
                <div id="widget19" class="esios-home__widget-legend two-colums">
                  <div id="spinner19" class="spinner-container">
                    <div class="spinner"></div>
                  </div>
                </div>
                <div class="mod-action">
                  <a
                    id="snp-button-more"
                    href="/${i18n.t('routes.lng')}/${i18n.t(
                      'routes.gyc'
                    )}/${i18n.t('routes.snp')}${date ? `?date=${date}` : ''}"
                    class="btn-info"
                    >${i18n.t('more_info')}</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="esios-home__right-container">
            <aside class="esios-home__aside">
              <div id="char26" class="homeMetaforaWidgetView">
                <h1 class="esios-home__title esios-home__title--system">
                  ${i18n.t('system_status')}
                </h1>
                <div
                  class="esios-toolbar esios-toolbar--home system-state-datetime"
                >
                  <div class="esios-toolbar4"></div>
                  ${templateToolbarTime('system-status-time')}
                </div>
                <div id="widget26" class="homeMetaforaWidgetView">
                  <div id="spinner26" class="spinner-container">
                    <div class="spinner"></div>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    `,
    {
      text: 'PVPC T. 2.0TD',
      bannerIsAligned: true,
      image: backImage,
      showSlider: true,
    },
    html`
      <div
        class="esios-layout__related-content esios-layout__related-content--home"
      >
        <div class="esios-layout__related-content__wrapper">
          <div class="homeBalanceWidgetView" id="char20">
            <h1 class="esios-home__title--small mt-3">
              ${i18n.t('measured_generation')}
            </h1>
            <div class="esios-toolbar col-12">
              <div class="esios-toolbar6"></div>
              ${templateToolbarTime('measured-generation')}
            </div>
            <div class="row">
              <div id="widget20" class="gm-graphic">
                <div id="spinner20" class="spinner-container">
                  <div class="spinner"></div>
                </div>
                <div id="rect20" class="esios-home__no-data-container_cuadrado">
                  ${i18n.t('no-data-available')}
                </div>
                <div class="esios-pie-graph chart" id="chart">
                  <div class="chartLegend"></div>
                </div>
                <div id="bar" class="percentBar"></div>
              </div>
              <div id="widget20Data" class="gm-graphic-indicators"></div>
            </div>
          </div>
          <div class="homeEYDWidgetView" id="char21">
            <h1 class="esios-home__title--small mt-3">
              ${i18n.t('evolution_of_demand')}
            </h1>
            <div class="esios-toolbar col-12">
              <div class="esios-toolbar7"></div>
              ${templateToolbarTime('demand-evolution')}
            </div>
            <div class="">
              <div id="widget21" class="evoluciondemanda">
                <div id="spinner21" class="spinner-container">
                  <div class="spinner"></div>
                </div>
              </div>
              <div
                id="widget21Data"
                class="esios-home__widget-legend esios-home__widget-legend--ed-legend highlighted-legend visual-legend"
              ></div>
            </div>
          </div>
        </div>
      </div>
    `,
    {
      title: i18n.t('current_news'),
      subcontent: html``,
    }
  );
};

export { templateHome };
