'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';
import backImage from '../../assets/images/header-default.jpg';
import { componentLayout } from '../../components/layout/layout.component';
import { templateBoxDocument } from '../../shared/components/box-documents/box-documents.template';
import { templateBoxMarket } from '../../shared/components/box-market/box-market.template';
import Select from '../../shared/components/selects/select';

let templateSaldoFronteras = (handlers, buttons, documents, listMarket) => {
  const { handleSelect } = handlers;
  let selectDetails = {
    values: [
      {
        label: i18n.t('france'),
        value: 0,
      },
      {
        label: i18n.t('portugal'),
        value: 1,
      },
      {
        label: i18n.t('morocco'),
        value: 2,
      },
      {
        label: i18n.t('andorra'),
        value: 3,
      },
    ],
    selected: i18n.t('france'),
    onClick: handleSelect,
  };
  return componentLayout.render(
    html`
      <div class="esios-intercambios">
        <div id="widget41" class="row">
          <div class="col-12">
            <h2 class="esios-market-prices__subtitle mb-2">
              ${i18n.t('capacity_use_interconnections')}
            </h2>
            <div class="row">
              <div class="esios-toolbar col-6">
                <div class="esios-toolbar1"></div>
              </div>
            </div>
            <div class="graph-wrapper">
              <div
                id="char41"
                class="esios-chart esios-intercambios__no-data-graphic"
                style="display:none"
              >
                <div id="rect1" class="esios-pvpc__no-data">
                  ${i18n.t('no-data-available')}
                </div>
              </div>
              <div id="spinner41" class="spinner-container">
                <div class="white-overlay"></div>
                <div class="spinner"></div>
              </div>
              <div class="esios-intercambios__row">
                <div class="col-3">
                  <div class="esios-select">${Select(selectDetails)}</div>
                  <div class="legend__container mt-4 mb-4">
                    <div id="area-graph-0-legends" class="legends"></div>
                    <div id="area-graph-1-legends" class="legends hidden"></div>
                    <div id="area-graph-2-legends" class="legends hidden"></div>
                    <div id="area-graph-3-legends" class="legends hidden"></div>
                  </div>
                </div>
                <div class="col-9 chart-0">
                  <div
                    id="area-graph-0"
                    class="chari-chart multi-bar"
                    style="min-height: initial!important;"
                  ></div>
                </div>
                <div class="col-9 chart-1" style="display: none;">
                  <div
                    id="area-graph-1"
                    class="chari-chart multi-bar hidden"
                    style="min-height: initial!important;"
                  ></div>
                </div>
                <div class="col-9 chart-2" style="display: none;">
                  <div
                    id="area-graph-2"
                    class="chari-chart multi-bar hidden"
                    style="min-height: initial!important;"
                  ></div>
                </div>
                <div class="col-9 chart-3" style="display: none;">
                  <div
                    id="area-graph-3"
                    class="chari-chart multi-bar hidden"
                    style="min-height: initial!important;"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="medium">
            <div class="selector-title">HORA</div>
            <div class="selector-container">
              <div id="mobile__selector"></div>
            </div>
          </div>
          <div class="row esios-balance__row-table">
            <div id="spinner41table" class="spinner-container">
              <div class="white-overlay"></div>
              <div class="spinner"></div>
            </div>
            ${[0, 1, 2, 3].map(
              (el) =>
                html`<div
                  class="tabcontentclass"
                  id="tabwithcontent${el}"
                ></div>`
            )}
          </div>
        </div>
      </div>
    `,
    {
      text: '',
      image: backImage,
      showBanner: true,
      showFooterImage: true,
    },
    html``
  );
};

export { templateSaldoFronteras };
