import i18n from 'i18next';
import { html, render } from 'lit-html';
import moment from 'moment-timezone';
import { store } from '../../app.init';
import esiosLogo from '../../assets/images/eSIOS-logo.svg';
import { BreadCrumbs } from '../../shared/components/breadcrumbs/breadcrumbs.template';
import { componentModal } from '../../shared/components/modal/modal.component';
import moreSearch from '../../shared/components/moreSearch/moreSearch';
import { setAnalysis } from '../../state/actions';
import { api } from '../../utils/api-interceptor';
import { setDateFormat } from '../../utils/dateFormat';
import { sRender } from '../../utils/render';
import { getState } from '../../utils/state';
import { getSplittedRoute, getUrlParams, setUrlParam } from '../../utils/url';
import { templatenoAccess } from '../noAccesibilidad/noAccess.template';
import AnalysisTabs, { getTabsForAnalysis } from './analysis-tabs';
import AnalysisTitle from './analysis-title';
import AnalysisToolbar from './analysis-toolbar';
import AnalysisChart from './chart/analysis-chart';
import AnalysisForm from './form/analysis-form';
import { getCompareDate } from './form/analysis-form-period';
import { ANALYSIS_TRUNCS } from './form/analysis-form.service';

const AnalysisPage = () => {
  document.title = i18n.t('analysis_title');
  // Getting global common state
  const state = getState();
  // Current language we are using
  const lang = state.currentLang;
  // This is the way to get the ID
  const id = getSplittedRoute().pop();
  // Calling APIs
  let info = {};
  apiCall(id, lang, info);

  const script = document.createElement('script');
  const scriptCss = document.createElement('script');
  const link = document.createElement('link');
  script.src = 'https://libs.cartocdn.com/cartodb.js/v3/3.14.6/cartodb.js';
  scriptCss.src =
    'https://libs.cartocdn.com/cartodb.js/v3/3.14.6/themes/css/cartodb.css';
  link.rel = 'stylesheet';
  link.href =
    'https://libs.cartocdn.com/cartodb.js/v3/3.14.6/themes/css/cartodb.css';
  document.head.appendChild(script);
  document.head.appendChild(scriptCss);
  document.head.appendChild(link);

  if (
    window.innerWidth <= 540 &&
    window.matchMedia('(orientation: portrait)').matches
  ) {
    window.location.href = '/es/analisis-movil';
  }

  window.addEventListener('resize', () => {
    if (
      window.innerWidth <= 540 &&
      window.matchMedia('(orientation: portrait)').matches
    ) {
      window.location.href = '/es/analisis-movil';
    }
  });

  // Rendering
  return html`${BreadCrumbs({}, {}, id, 'analysis')}
    <div class="esios-layout__print-logo">
      <img src="${esiosLogo}" alt="Logo" />
    </div>
    <div class="esios-layout__extended">
      <div
        class="esios-layout__related-content esios-layout__related-content--gd esios-layout__analysis"
      >
        <div class="esios-gd__wrap esios-gd__wrap--analysis dont-show-mobile">
          <div class="row">
            <div class="spinner-container"></div>
            <div id="analysis-info-title" class="esios-analysis__info-title">
              <span>...</span>
            </div>
            <div id="analysis-toolbar"></div>
            <div id="modalFav-${id}"></div>
            <div id="analysis-info-tabs" class="esios-analysis__info-tabs">
              <span>...</span>
            </div>
            <div class="row row-flex">
              <div
                id="analysis-form"
                class="esios-analysis__info-form esios-analysis__period grid-sm-7 "
              ></div>
              <div
                id="analysis-info-chart"
                class="esios-analysis__info-chart grid-sm-17"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="external-modal"></div>
    <div>${moreSearch()}</div>`;
};
export function doParametersLogic(groupby) {
  const state = getState();
  let { start_date, end_date, compare_start_date } = getUrlParams();

  const url = new URL(window.location.href);
  const currentDate = new Date();
  const today = setDateFormat(currentDate, '-');
  let yesterday = setDateFormat(
    currentDate.setDate(currentDate.getDate() - 1),
    '-'
  );
  if (start_date) {
    yesterday = moment(start_date, 'DD-MM-YYYYTHH:mm')
      .tz('Europe/Madrid')
      .subtract('1', 'day')
      .format('DD-MM-YYYY');
  }
  const mandatoryFields = [
    { name: 'vis', value: 1 },
    { name: 'start_date', value: `${today}T00:00` },
    { name: 'end_date', value: `${today}T23:55` },
    { name: 'compare_start_date', value: `${yesterday}T00:00` },
    { name: 'groupby', value: `${groupby}` },
  ];
  const removeHour = (date) => {
    if (!date) {
      date = moment().tz('Europe/Madrid').format('DD-MM-YYYY');
    }
    let day = date?.split('T');
    let newDate = date ? day[0] + 'T23:55' : '';
    return newDate;
  };

  const getMoveBy = () => {
    let { groupby } = getUrlParams();
    if (groupby.includes('minutes')) {
      return 'hour';
    } else return groupby;
  };

  for (const field of mandatoryFields) {
    if (!url.searchParams.has(field.name)) {
      if (field.name == 'end_date') {
        let { start_date } = getUrlParams();
        field.value = removeHour(start_date);
      }
      url.searchParams.append(field.name, field.value);
    }
  }
  window.history.pushState({ path: url.href }, '', url.href);

  let tempStartDate = start_date || today;
  let tempEndDate = end_date || today;
  let tempCompareDate = compare_start_date || today;
  if (getUrlParams().groupby == 'year') {
    let stDate = moment(
      '01-01-' + moment(tempStartDate, 'DD-MM-YYYY').year(),
      'DD-MM-YYYY'
    ).format('DD-MM-YYYY');
    let endDate = moment(tempEndDate, 'DD-MM-YYYY')
      .endOf('year')
      .format('DD-MM-YYYY');

    tempStartDate = stDate + 'T00:00';
    tempEndDate = endDate + 'T23:55';
    tempCompareDate =
      moment(stDate, 'DD-MM-YYYY').subtract('1', 'year').format('DD-MM-YYYY') +
      'T00:00';
  } else if (getUrlParams().groupby == 'month') {
    let stDate = moment(tempStartDate, 'DD-MM-YYYY')
      .startOf('month')
      .format('DD-MM-YYYY');
    let endDate = moment(tempEndDate, 'DD-MM-YYYY')
      .endOf('month')
      .format('DD-MM-YYYY');

    // THH:mm added
    tempStartDate = stDate + 'T00:00';
    tempEndDate = endDate + 'T23:55';
    tempCompareDate = moment(tempStartDate, 'DD-MM-YYYYTHH:mm')
      .subtract('1', 'month')
      .format('DD-MM-YYYYTHH:mm');
  } else if (getUrlParams().groupby == 'day') {
    let stDate = moment(tempStartDate, 'DD-MM-YYYY').format('DD-MM-YYYY');
    let endDate = moment(tempEndDate, 'DD-MM-YYYY').format('DD-MM-YYYY');

    tempStartDate = stDate + 'T00:00';
    tempEndDate = endDate + 'T23:55';
    tempCompareDate = moment(stDate, 'DD-MM-YYYY')
      .subtract('1', 'day')
      .format('DD-MM-YYYY');
  } else {
    let stDate = moment(tempStartDate, 'DD-MM-YYYYTHH:mm').format(
      'DD-MM-YYYYTHH:mm'
    );
    let endDate = moment(tempEndDate, 'DD-MM-YYYYTHH:mm').format(
      'DD-MM-YYYYTHH:mm'
    );
    if (!end_date) {
      endDate =
        moment(tempStartDate, 'DD-MM-YYYY').format('DD-MM-YYYY') + 'T23:55';
    }
    tempStartDate = stDate;
    tempEndDate = endDate;
    tempCompareDate = moment(stDate, 'DD-MM-YYYYTHH:mm')
      .subtract('1', 'day')
      .format('DD-MM-YYYYTHH:mm');
  }

  setUrlParam('start_date', tempStartDate);
  setUrlParam('end_date', tempEndDate);
  setUrlParam('compare_start_date', tempCompareDate);
  let tempEndCompare = getCompareDate(
    tempStartDate,
    tempEndDate,
    tempCompareDate,
    'end_date'
  );
  store.dispatch(
    setAnalysis({
      ...getUrlParams(),
      moveBy: getMoveBy(),
      compare_end_date: tempEndCompare,
    })
  );
}
// } else {
//   let tempStartDate = start_date ? start_date : mandatoryFields[1].value;
//   let tempEndDate = mandatoryFields[2].value;
//   let tempCompareDate = mandatoryFields[3].value;
//   let idsAVGIncluded = [10300, 10301, 10302, 10303, 10304, 1483];
//   if (
//     getUrlParams().groupby == 'month' &&
//     idsAVGIncluded.includes(state.analysis.indicator.id)
//   ) {
//     tempStartDate = moment(tempStartDate, 'DD-MM-YYYYTHH:mm')
//       .subtract('1', 'month')
//       .date(1)
//       .format('DD-MM-YYYYTHH:mm');
//     tempEndDate = moment(tempEndDate, 'DD-MM-YYYYTHH:mm')
//       .subtract('1', 'month')
//       .date(moment(tempEndDate, 'DD-MM-YYYYTHH:mm').daysInMonth())
//       .format('DD-MM-YYYYTHH:mm');
//     tempCompareDate = moment(tempStartDate, 'DD-MM-YYYYTHH:mm')
//       .subtract('1', 'month')
//       .format('DD-MM-YYYYTHH:mm');

//     setUrlParam('start_date', tempStartDate);
//     setUrlParam('end_date', tempEndDate);
//     setUrlParam('compare_start_date', tempCompareDate);
//   }
//   store.dispatch(
//     setAnalysis({
//       ...getUrlParams(),
//       moveBy: getMoveBy(),
//       compare_end_date: getCompareDate(
//         tempStartDate,
//         tempEndDate,
//         tempCompareDate,
//         'end_date'
//       ),
//     })
//   );
// }
// }

export function apiCall(id, lang, info, renderEmbed = false) {
  let { start_date, end_date, compare_start_date } = getUrlParams();
  Promise.all([
    api(
      `indicators/${id}?values=false&start_date=${
        start_date
          ? start_date
          : moment().tz('Europe/Madrid').format('YYYY-MM-DD') + 'T00:00'
      }&locale=${lang}`
    ),
  ]).then((data) => {
    let indicator = data[0] != undefined ? data[0].indicator : data.indicator;
    let newGeos =
      data[0] != undefined ? data[0].indicator.geos : data.indicator.geos;

    let timeIdVal;
    if (indicator.tiempo[0].id === 223) {
      timeIdVal = 4;
    } else if (timeIdVal === 154) {
      timeIdVal = 225;
    } else {
      timeIdVal = indicator.tiempo[0].id;
    }
    info.indicator = indicator;
    const groupBy = ANALYSIS_TRUNCS[timeIdVal];

    const tabsForAnalysis = getTabsForAnalysis(indicator);
    let containerToRenderGraph = renderEmbed
      ? 'embed-container'
      : 'analysis-info-chart';
    const { vis } = getUrlParams();
    const activeTab = vis || 1;
    let label = tabsForAnalysis[activeTab - 1];
    store.dispatch(
      setAnalysis({
        indicator: {
          ...indicator,
          geos: newGeos,
        },
        groupBy,
        groupby: groupBy,
        tabSelected: {
          index: activeTab - 1,
          label: label,
        },
      })
    );

    // Make sure all mandatory parameters are put
    doParametersLogic(groupBy);
    if (!renderEmbed) {
      render(
        AnalysisTabs(indicator),
        document.getElementById('analysis-info-tabs')
      );
      let tabContainer = document.querySelector(
        '.mod-tabs.ui-tabs.ui-widget.ui-widget-content.ui-corner-all'
      );
      tabContainer.classList.add('mod-tabs--analysis');

      sRender(AnalysisForm, 'analysis-form', info);
    }
    if (
      getSplittedRoute()[3] === i18n.t('routes.analysis') &&
      label === 'comparative'
    ) {
      sRender(
        html`<div id="comparative-form" class="hidden"></div>`,
        'analysis-form'
      );
      sRender(AnalysisForm, 'comparative-form', info);
    }

    let selectedGeo = getUrlParams()?.geoids;
    let geoName = indicator?.geos.find(
      (el) => el.geo_id == selectedGeo
    )?.geo_name;
    geoName = geoName ? geoName : '';
    render(
      AnalysisTitle(`${indicator.name} ${geoName}`),
      document.getElementById('analysis-info-title')
    );
    if (getSplittedRoute()[3] !== i18n.t('routes.analysis')) {
      render(
        AnalysisToolbar(indicator),
        document.getElementById('analysis-toolbar')
      );
    }
    sRender(AnalysisChart, containerToRenderGraph, info);
  });
}

export function renderModal(id, title, text) {
  let textModal = `
  <div class="esios-noPeninsulares__title">
    <h1>${title.toUpperCase()}</h1>
  </div>
  ${text}`;

  componentModal.render(
    title === 'none' ? text : textModal,
    'btnModal' + id,
    'modal' + id
  );
}

export default AnalysisPage;
