'use strict';

import i18next from 'i18next';
import { getColour } from '../../shared/components/chart/chart-helpers';
import { Utils } from '../../utils/utils';

let intercambiosInternacionalesContent = (() => {
  let init = () => {};

  let tableContent = (data) => {
    const getDataForIdAndValue = (id, value = 'values') => {
      const found = data.find((el) => el.id === id);
      if (found) {
        if (value === 'values') {
          if (found[value].length > 0) {
            if (found.magnitud[0].id === 23) {
              return found[value][0].y.toFixed(2).toLocaleString();
            }
            if (found.magnitud[0].id === 21) {
              return found[value][0].y.toFixed(1).toLocaleString();
            }
            return found[value][0].y;
          } else {
            return '-';
          }
        }
        return found[value];
      }
    };

    const headTable = [
      `${i18next.t('auction')}`,
      `${i18next.t('num_of_participants')}`,
      `${i18next.t('num_of_purchaser')}`,
      `${i18next.t('price')} (€/MW)`,
      `${i18next.t('assignment')} (MW)`,
    ];

    let miArrayHead = `
      <thead class="esios-table__head">
        <tr class="esios-table__row">
          ${headTable
            .map(
              (el) => `<th>
            <div class="esios-table__cell">
              <p>${el}</p>
              <div class="esios-table__cell--sort"></div>
            </div>
          </th>`
            )
            .join('')}
        </tr>
      </thead>
    `;

    const tableData = [
      {
        text: `${i18next.t('esfr')}`,
        content: [
          [1074, 1074, 1076, 1080, 1078],
          [1082, 1082, 1084, 1088, 1086],
        ],
      },
      {
        text: `${i18next.t('fres')}`,
        content: [
          [1073, 1073, 1075, 1079, 1077],
          [1081, 1081, 1083, 1087, 1085],
        ],
      },
      {
        text: `${i18next.t('espt')}`,
        content: [
          [1639, 1639, 1641, 1645, 1643],
          [1647, 1647, 1649, 1653, 1651],
          [1655, 1655, 1657, 1661, 1659],
        ],
      },
      {
        text: `${i18next.t('ptes')}`,
        content: [
          [1638, 1638, 1640, 1644, 1642],
          [1646, 1646, 1648, 1652, 1650],
          [1654, 1654, 1656, 1660, 1658],
        ],
      },
    ];

    let parseData = (element, index) => {
      if (element !== '-' && typeof element === 'string') {
        if (index == 4) {
          return Utils.toNumber(element, 1, 'comma');
        }
        return Utils.toNumber(element, 2, 'comma');
      } else {
        return element;
      }
    };
    let miArrayTab = {
      activeTab: '1',
      tabsContent: tableData.map((el) => {
        return {
          text: el.text,
          class: 'tab-myp-content',
          content: `<div class="esios-tabla simple">
            <table class="esios-table">
              ${miArrayHead}
              <tbody>
                  ${el.content
                    .map((ele) => {
                      return `<tr class="esios-table__row ${ele}">
                      ${ele
                        .map((el, index) => {
                          return `<td data-title='${headTable[index]}'>${
                            index % 5 === 0
                              ? getDataForIdAndValue(el, 'short_name')
                              : parseData(
                                  getDataForIdAndValue(el, 'values', index),
                                  index
                                )
                          }</td>`;
                        })
                        .join('')}
                      </tr>`;
                    })
                    .join('')}
              </tbody>
            </table>
          </div>

          ${
            el.footer
              ? `<p class="table-description cursive">
          ${i18next.t(el.footer)}
          </p>`
              : ''
          }`,
        };
      }),
    };
    return miArrayTab;
  };

  const contentForMyP = {
    listMarket: [
      {
        type: 'title',
        id: 2,
        title: 'structural_data',
      },
      {
        type: 'list',
        id: 1,
        text: 'participants_auction',
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.participants_auction'
          )}`,
      },
      {
        type: 'list',
        id: 2,
        text: 'programming_units',
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.programming_units'
          )}`,
      },
      {
        type: 'list',
        id: 3,
        text: 'market_subjects',
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t('market_subjects')}`,
      },
      {
        type: 'button',
        id: 2,
        label: 'gde_website_text',
        url: 'https://www.ree.es/es/clientes',
      },
    ],
    toolbar: [
      {
        id: 1,
        info: true,
        share: true,
        favorite: true,
        analysis: true,
      },
      {
        id: 2,
        info: true,
        share: true,
        favorite: true,
        analysis: true,
      },
      {
        id: 3,
        info: true,
        share: true,
        favorite: true,
        analysis: true,
      },
      {
        id: 4,
        info: true,
        share: true,
        favorite: true,
        analysis: true,
      },
      {
        id: 5,
        info: true,
        share: true,
        favorite: true,
        analysis: true,
      },
    ],
  };
  const optsForMultipleGraph = (date) => {
    return [
      {
        type: 'multi',
        fit: true,
        lineInterpolation: 'linear',
        elem: '#area-graph-0',
        height: 450,
        date: date,
        yticks: 10,
        areaColor: {
          positive: 'rgba(14, 123, 245, 0.4)',
          negative: 'rgba(255, 129, 0, 0.6)',
        },
        left: {
          label: 'MWh',
        },
        bottom: {
          hideHour: false,
          center: true,
          label: 'hour',
          mayus: true,
        },
        legends: [
          {
            name: 'long_term',
            color: getColour(0),
            type: 'badge',
          },
          {
            name: 'md',
            color: getColour(1),
            type: 'badge',
          },
          {
            name: 'intraday',
            color: getColour(2),
            type: 'badge',
          },
          {
            name: 'rr',
            color: getColour(3),
            type: 'badge',
          },
          {
            name: 'acb',
            color: getColour(4),
            type: 'badge',
          },
          {
            name: 'ntc-imp-fr',
            color: 'rgba(14, 123, 245, 0.4)',
            type: 'box',
          },
          {
            name: 'ntc-exp-fr',
            color: 'rgba(255, 129, 0, 0.6)',
            type: 'box',
          },
          // {
          //   name: 'program-balance',
          //   color: '#333333',
          //   type: 'line',
          // },
        ],
      },
      {
        type: 'multi',
        fit: true,
        lineInterpolation: 'linear',
        elem: '#area-graph-1',
        height: 450,
        date: date,
        yticks: 10,
        areaColor: {
          positive: 'rgba(14, 123, 245, 0.4)',
          negative: 'rgba(255, 129, 0, 0.6)',
        },
        left: {
          label: 'MWh',
        },
        bottom: {
          hideHour: false,
          center: true,
          label: 'hour',
          mayus: true,
        },
        legends: [
          {
            name: 'md',
            color: getColour(1),
            type: 'badge',
          },
          {
            name: 'intraday',
            color: getColour(2),
            type: 'badge',
          },
          {
            name: 'rr',
            color: getColour(3),
            type: 'badge',
          },
          {
            name: 'acb',
            color: getColour(4),
            type: 'badge',
          },
          {
            name: 'ntc-imp-pt',
            color: 'rgba(14, 123, 245, 0.4)',
            type: 'box',
          },
          {
            name: 'ntc-exp-pt',
            color: 'rgba(255, 129, 0, 0.6)',
            type: 'box',
          },
          // {
          //   name: 'program-balance',
          //   color: '#333333',
          //   type: 'line',
          // },
        ],
      },
      {
        type: 'multi',
        fit: true,
        lineInterpolation: 'linear',
        elem: '#area-graph-2',
        height: 450,
        date: date,
        yticks: 10,
        areaColor: {
          positive: 'rgba(14, 123, 245, 0.4)',
          negative: 'rgba(255, 129, 0, 0.6)',
        },
        left: {
          label: 'MWh',
        },
        bottom: {
          hideHour: false,
          center: true,
          mayus: true,
          label: 'hour',
        },
        legends: [
          {
            name: 'md',
            color: getColour(1),
            type: 'badge',
          },
          {
            name: 'intraday',
            color: getColour(2),
            type: 'badge',
          },
          {
            name: 'ntc-imp-ma',
            color: 'rgba(14, 123, 245, 0.4)',
            type: 'box',
          },
          {
            name: 'ntc-exp-ma',
            color: 'rgba(255, 129, 0, 0.6)',
            type: 'box',
          },
          // {
          //   name: 'program-balance',
          //   color: '#333333',
          //   type: 'line',
          // },
        ],
      },
      {
        type: 'multi',
        fit: true,
        lineInterpolation: 'linear',
        elem: '#area-graph-3',
        height: 450,
        date: date,
        yticks: 10,
        areaColor: {
          positive: 'rgba(14, 123, 245, 0.4)',
          negative: 'rgba(255, 129, 0, 0.6)',
        },
        left: {
          label: 'MWh',
        },
        bottom: {
          hideHour: false,
          center: true,
          mayus: true,
          label: 'hour',
        },
        legends: [
          {
            name: 'md',
            color: getColour(1),
            type: 'badge',
          },
          {
            name: 'intraday',
            color: getColour(2),
            type: 'badge',
          },
          {
            name: 'ntc-imp-ad',
            color: 'rgba(14, 123, 245, 0.4)',
            type: 'box',
          },
          {
            name: 'ntc-exp-ad',
            color: 'rgba(255, 129, 0, 0.6)',
            type: 'box',
          },
          // {
          //   name: 'program-balance',
          //   color: '#333333',
          //   type: 'line',
          // },
        ],
      },
    ];
  };

  return {
    init: init,
    tableContent: (data) => tableContent(data),
    optsForMultipleGraph: (date) => optsForMultipleGraph(date),
    contentForMyP: contentForMyP,
  };
})();
export { intercambiosInternacionalesContent };
