'use strict';

/**
 * @description Dashboard Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { api } from './../../utils/api-interceptor';
import { templateInfo } from './info.template.js';
import i18n from 'i18next';

let componentInfo = (function () {
  let renderSuccess = false;

  let preRenderComponent = () => {
    document.title = `${i18n.t('umm')} | ${i18n.t('page_subtitle')}`;
  };

  let renderComponent = async () => {
    try {
      const { contents } = await api(
        `umms?temporal=` + Math.floor(Math.random() * 1000),
        { hasLang: true }
      );
      templateInfo(contents);

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = () => {};

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentInfo };
