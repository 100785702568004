'use strict';
import i18n from 'i18next';
import 'regenerator-runtime/runtime';
import { api } from '../../utils/api-interceptor';

let servicesinterrupcion = (() => {
  let init = (e) => llamarServicio(e);
  const llamarServicio = async (e) => {
    let data = await api(
      `static_pages?slug=${i18n.t('routes.interruptibility_snp')}&temporal=` +
        Math.floor(Math.random() * 1000),
      { hasLang: true }
    );
    return data.content;
  };

  return {
    init: (e) => init(e),
  };
})();

export { servicesinterrupcion };
