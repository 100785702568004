'use strict';
import { html } from 'lit-html';
import backImage from '../../assets/images/header-documentacion.jpg';
import { componentLayout } from '../../components/layout/layout.component';
import { templateBoxMarketColumn } from '../../shared/components/box-market-column/box-market-column.template';
import { descargas } from '../globals.js';
import i18n from 'i18next';

const { labeltabla, labelSubtitle, labelparrafo1, labelSubtitle2 } = descargas;

let templateDocumentacion = (
  context,
  handlers,
  pageTitle,
  select,
  paginationValues,
  pagination,
  filterForm,
  searchForm,
  objMarket,
  listMarket,
  selectElements
) => {
  const {
    onTotalChange,
    onPageChange,
    ocultar,
    onQueryChange,
    onSelectChange,
    onSearch,
  } = handlers;

  const sortSelect = (a, b) => {
    if (i18n.t(a.label) > i18n.t(b.label)) {
      return 1;
    }
    if (i18n.t(a.label) < i18n.t(b.label)) {
      return -1;
    }
    return 0;
  };
  const arrayMove = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };
  select = select.map((i) => {
    const options = i.options.sort(sortSelect);
    return {
      id: i.id,
      label: i.label,
      options: arrayMove(
        options,
        options.indexOf(options.find((i) => i.label === 'show_all')),
        0
      ),
    };
  });

  const id = select.map((item) => item.id);
  const label = select.map((item) => item.label);
  const options = select.map((item) => item.options);

  const headerOfFruit = (value, property) =>
    html`<div style="color: red;">${value}</div>`;
  const bodyOfFruit = (value, property) =>
    html`<div style="color: red;">${value}</div>`;
  const sort = (value, property) => html`
    <ld-header-with-sort
      language="en"
      direction="desc"
      data-property="${property}"
    >
      ${value}
    </ld-header-with-sort>
  `;

  return componentLayout.render(
    html`
      <head>
        <link
          href="https://unpkg.com/boxicons@2.0.9/css/boxicons.min.css"
          rel="stylesheet"
        />
      </head>

      <div class="esios-documentacion__tabla">
        <h1 class="esios-tabla__title">${i18n.t('documentation')}</h1>
        <!--filtros con su onchange-->

        <div
          class="esios-documentacion__content esios-selects-documents row hidden"
        >
          <div class="esios-documentacion__wrap col-4">
            <input
              class="esios-documentacion__tabla__input"
              placeholder="${i18n.t('go_search')}"
              type="text"
              @keyup="${onQueryChange}"
            />
            <button
              class="esios-documentacion__boton-remove hidden"
              @click="${ocultar}"
            >
              &nbsp
            </button>
          </div>
          <div class="esios-documentacion__form row">
            ${selectElements.map(
              (select, index) =>
                html`<a class="esios-documentacion__wrap2">
                  <select
                    class="esios-documentacion__tabla--dropdown2 ree-select-${select.value}"
                    value="${filterForm[select.value]}"
                    @change="${onSelectChange(index)}"
                  >
                    <option class="option" value="" selected disabled hidden>
                      ${i18n.t(select.label)}
                    </option>
                    ${options[index]?.map(
                      (item) =>
                        html` <option class="option" value="${item.value}">
                          ${i18n.t(item.label)}
                        </option>`
                    )}
                  </select>
                </a>`
            )}
          </div>
        </div>
        <div id="spinner1" class="spinner-container">
          <div class="spinner"></div>
        </div>
        <div
          id="download-table"
          class="esios-documentacion__content esios-documentacion__content--border-bottom"
        ></div>

        <div id="pagination" class="esios-pagination hidden">
          <div class="esios-pagination__results hidden">
            <span class="esios-pagination__txt esios-pagination__arrow">
              ${i18n.t('showing')}
              <select
                class="esios-pagination__select"
                value="${pagination.pagination}"
                @change="${onTotalChange}"
              >
                ${paginationValues.map(
                  (item) => html`<option value="${item}">${item}</option>`
                )}
              </select>
            </span>
            <span class="esios-pagination__txt"
              >${i18n.t('text_results')} ${pagination.total}</span
            >
          </div>
          ${pagination.totalPages > 0
            ? html`<div
                id="pagination-documentacion"
                class="esios-pagination__paginator esios-pagination__paginator--doc"
              ></div>`
            : ''}
        </div>
      </div>

      <div
        class="esios-documentacion__buttons esios-documentacion__buttons--doc"
      >
        <div id="button-fisicas"></div>
      </div>
    `,
    {
      text: '',
      image: backImage,
      showBanner: true,
    },
    html``,
    {
      title: `${i18n.t('more')}`,
      subcontent: html`
        <div class="esios-documents">
          <div class="grid-xs-12 documents-container-download">
            <div class="mod-documents-download">
              ${templateBoxMarketColumn(listMarket)}
              <div class="mod-buttons">
                <a
                  class="mod-buttons__btn"
                  href="https://www.ree.es/es/clientes"
                  target="_blank"
                  >${i18n.t('gde_website_text')}</a
                >
              </div>
            </div>
          </div>
        </div>
      `,
    }
  );
};

export { templateDocumentacion };
