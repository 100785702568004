import { html, render } from 'lit-html';
import { isEmpty, noAccutes } from '../../../utils/core';

const Select = ({
  values = [],
  selected = {},
  label = 'label',
  onClick,
  placeholder = '',
  disabled = false,
  deletable = false,
  hidden = false,
  onDelete = () => {},
  onFocusOut = () => {},
}) => {
  values = setValues(values);
  selected =
    typeof selected !== 'object'
      ? values.find((i) => noAccutes(i[label]) === noAccutes(selected))
      : selected;
  const disabledStyle = disabled ? 'opacity: .5' : 'opacity: 1';
  let isOpen = false;
  const onToggle = (ev) => {
    const container = ev.currentTarget.parentElement.parentElement.children[1];
    isOpen = !isOpen;
    render(OptionsContainer(), container);
  };

  const BtnContent = () => {
    let element = values.find((i) => selected && i[label] === selected[label]);
    let title = (selected && selected[label]) || placeholder;

    if (element == null) {
      element = values[values.length - 1].label;
    }
    if (title == null || title == '') {
      title = values[values.length - 1].label;
    }
    return html`
      <button
        class="esios-selector__selected"
        ?disabled="${disabled}"
        style="${disabledStyle}"
        @click="${onToggle}"
        tabindex="-1"
      >
        <span class="esios-selector__selected--content">${title}</span>
      </button>
      ${deletable && !isEmpty(selected)
        ? html`<button
            class="esios-selector__delete"
            @click=${deleteLogic(element)}
          ></button>`
        : ''}
    `;
  };

  function deleteLogic(element) {
    return (ev) => {
      selected = {};
      onDelete(element, ev);
    };
  }

  const OptionsContainer = () => {
    if (!isOpen) {
      return '';
    }
    const handleClick = (el) => (ev) => {
      isOpen = false;
      selected = el;
      const container = ev.currentTarget.parentElement.parentElement.children;
      if (!el.disabled) {
        render(BtnContent(), container[0]);
        render(OptionsContainer(), container[1]);
        onClick(el, ev);
      }
    };
    return html`
      <div class="arrow-con">
        <div class="top-arrow"></div>
        <div class="top-arrow-sd"></div>
        <div class="shadow"></div>
      </div>
      ${values.map((el) => {
        const disabled = el.disabled || false;
        const cStyles = disabled ? 'opacity: .5' : '';
        let optHidden = hidden ? hidden.find((hid) => hid == el.label) : false;
        return html`
          <div
            class="esios-selector__option ${optHidden} ${(hidden &&
              optHidden) ||
            (!el.show && el.custom)
              ? 'hidden'
              : ''} ${selected[label] === el[label]
              ? 'esios-selector__option--selected'
              : ''}"
            style="${cStyles}"
            ?disabled="${disabled}"
            @click=${handleClick(el)}
            tabindex="0"
          >
            ${el[label]}
          </div>
        `;
      })}
    `;
  };
  const handleFocusOut = (e) => {
    let option = e.relatedTarget ? e.relatedTarget : false;
    let clickInScroll =
      option && option.classList.contains('esios-selector') ? true : false;
    let clickInOption =
      option && option.classList.contains('esios-selector__option')
        ? true
        : false;

    if (
      isOpen &&
      !clickInScroll &&
      (!e.relatedTarget ||
        e.relatedTarget.parentElement.parentElement !=
          e.srcElement.parentElement.parentElement)
    ) {
      if (!clickInOption) {
        isOpen = false;
        render(OptionsContainer(), e.currentTarget.children[1]);
        onFocusOut();
      }
    }
  };
  return html`
    <div class="esios-selector" tabindex="0" @focusout=${handleFocusOut}>
      <div class="esios-selector__btn">${BtnContent()}</div>
      <div class="esios-selector__options">${OptionsContainer()}</div>
    </div>
  `;
};

function setValues(values) {
  // Take the first value for example
  let newValues = [...values];
  if (typeof values[0] !== 'object') {
    newValues = values.map((i, index) => ({ label: i, index }));
  }
  return newValues;
}

export default Select;
