'use strict';
import i18n from 'i18next';

let templateChart20 = () => {
  return `
    <div class="esios-listEnergyItems">
    <div class="esios-listEnergyItems__wrap esios-listEnergyItems__wrap--home">
        <ul>
        <li>
            <div
            class="esios-listEnergyItems__item-with-value mb-2"
            >
            <div class="esios-listEnergyItems__label bold legend">
                <span
                class="esios-listEnergyItems__decorator-square"
                style="background-color: #99ff33;"
                ></span>
                ${i18n.t('renewable')}
            </div>
            <div
                class="value is-number"
                data-id="renovable"
            ></div>
            </div>
            <ul class="esios-listEnergyItems__list">
            <li
                class="esios-listEnergyItems__item-with-value"
                data-id="10035"
            ></li>

            <li
                class="esios-listEnergyItems__item-with-value"
                data-id="10037"
            ></li>

            <li
                class="esios-listEnergyItems__item-with-value"
                data-id="1161"
            ></li>

            <li
                class="esios-listEnergyItems__item-with-value"
                data-id="1162"
            ></li>

            <li
                class="esios-listEnergyItems__item-with-value"
                data-id="10041"
            ></li>

            <li
                class="esios-listEnergyItems__item-with-value"
                data-id="10062"
            ></li>
            </ul>
        </li>

        <li class="mt-3">
            <div
            class="esios-listEnergyItems__item-with-value mb-2"
            >
            <div class="esios-listEnergyItems__label bold legend">
                <span
                class="esios-listEnergyItems__decorator-square"
                style="background-color: #c0c0c0;"
                ></span>
                ${i18n.t('no_renewable')}
            </div>
            <div class="value is-number" data-id="no-renovable">
                52,3
                <span class="unit">%</span>
            </div>
            </div>
            <ul class="esios-listEnergyItems__list">
            <li
                class="esios-listEnergyItems__item-with-value"
                data-id="1153"
            ></li>

            <li
                class="esios-listEnergyItems__item-with-value"
                data-id="1152"
            ></li>

            <li
                class="esios-listEnergyItems__item-with-value"
                data-id="1156"
            ></li>

            <li
                class="esios-listEnergyItems__item-with-value"
                data-id="10036"
            ></li>

            <li
                class="esios-listEnergyItems__item-with-value"
                data-id="10038"
            ></li>

            <li
                class="esios-listEnergyItems__item-with-value"
                data-id="10039"
            ></li>
            <li
                class="esios-listEnergyItems__item-with-value"
                data-id="10040"
            ></li>
            </ul>
        </li>
        </ul>
    </div>
    </div>
  `;
};

export { templateChart20 };
