import { componentAbout } from '../pages/about/about.component';
import { componentAnalysisLanding } from '../pages/analysis-landing/analysisLanding.component';
import AnalysisPage from '../pages/analysis/analysis.page';
import { componentAyuda } from '../pages/ayuda/ayuda.component';
import { componentBalance } from '../pages/balance/balance.component';
import { componentCalendario } from '../pages/calendar/calendario.component';
import { componentRedMercado } from '../pages/codigoRedMercado/codigoRedMercado.component';
import { componentCodigos } from '../pages/codigos/codigos.component';
import { componentCodigosConexion } from '../pages/codigos_conexion/codigos_conexion.component';
import { componentOperacion } from '../pages/codigos_red_operacion/codigos_operacion.component';
import { componentContact } from '../pages/contact/contact.component';
import { componentCreateUsuario } from '../pages/create-usuario/create-usuarios.component';
import { componentDashboard } from '../pages/dashboard/dashboard.component';
import { componentDocumentacion } from '../pages/documentacion/documentacion.component';
import Document from '../pages/document/document';
import OperationNew from '../pages/operation-news/operation-news';
import { componentEditUsuario } from '../pages/edit-usuario/edit-usuarios.component';
import EmbedIndicators from '../pages/embed-indicators/embed-indicators';
import { componentEmbed } from '../pages/embed-page/embed.component';
import { componentEnlace } from '../pages/enlace/enlace.component';
import { componenterror } from '../pages/error/error.component';
import { componentfaq } from '../pages/faq/faq.component';
import { componentgenerConsumo } from '../pages/generacion y consumo/generConsumo.component';
import { componentGestionDemanda } from '../pages/gestion-demanda/gestionDemanda.component.js';
import { componentGlosario } from '../pages/glosario/glosario.component';
import { componenthabilitado } from '../pages/habilitados/habilitado.component';
import { componentHome } from '../pages/home/home.component';
import { componentInfo } from '../pages/informacion/info.component';
import { componentinterrupcion } from '../pages/interrumpibilidad/interrupcion.component';
import { componentLogin } from '../pages/login/login.component';
import { componentMapa } from '../pages/mapa/mapa.component';
import { componentMapLinks } from '../pages/mapas-links/mapasLinks.component';
import { componentMapasInteres } from '../pages/mapas_de_interes/mapasInteres.component';
import { componentMercadosPrecios } from '../pages/mercados-precios/mercadosPrecios.component';
import { componentPrevisionesCircular } from '../pages/previsiones-circular/previsionesCircular.component.js';
import { componentnoAccess } from '../pages/noAccesibilidad/noAccess.component';
import { componentobsoleto } from '../pages/noCompatible/obsoleto.component';
import { componentnoPeninsulares } from '../pages/noPeninsulares/noPeninsulares.component';
import { componentPoliticaPrivacidad } from '../pages/politica_privacidad/politicaprivacidad.component';
import { componentprevisionesDRenovable } from '../pages/previsionesDemandaReno/previsionesDRenovable.component';
import { componentPropuestas } from '../pages/propuestas/propuestas.component';
import { componentpvpc } from '../pages/pvpc/pvpc.component';
import { componentResultados } from '../pages/resultados/resultados.component';
import { componentSeeUsuario } from '../pages/see-usuarios/see-usuarios.component';
import { componentSujetosMercado } from '../pages/sujetos_mercado/sujetosmercado.component';
import { componentSupplyCurves } from '../pages/supply-curves/supply-curves.component';
import { componentTab } from '../pages/tab/tab.component';
import { componentDescargas } from '../pages/tablaDescargas/descargas.component';
import { componentTitulacionOperacion } from '../pages/titularesOperacion/operacion.component';
import { componentUnidades } from '../pages/unidades/unidades.component';
import { componentUnidadesProgramacion } from '../pages/unidades_programacion/unidadprogramacion.component';
import { componentUsuarios } from '../pages/usuarios/usuarios.component';
import { componentWebCalculadora } from '../pages/web-calculadora/webCalculadora.component';
import { componentIntercambiosInternacionales } from '../pages/intercambios-internacionales/intercambiosInternacionales.component';
import { componentAyudaFAQ } from '../pages/ayuda-FAQ/ayudaFAQ.component';
import StaticPages from '../pages/static-pages/static-pages';
import { componentActivation } from '../pages/activation/activation.component';
import { componentSaldoFronteras } from '../pages/saldo-fronteras/saldoFronteras.component';

export default [
  {
    url: '',
    redirectTo: 'routes.lng',
  },
  {
    url: 'routes.lng',
    component: componentHome,
    childrens: [
      {
        url: 'routes.embed',
        childrens: [
          {
            url: 'routes.analysis',
            component: componentHome,
            childrens: [
              {
                url: ':slug',
                component: EmbedIndicators,
                header: false,
                footer: false,
              },
            ],
          },
          {
            url: ':slug',
            component: EmbedIndicators,
            header: false,
            footer: false,
          },
        ],
      },
      {
        url: 'routes.myesios',
        component: componentLogin,
      },
      {
        url: 'routes.market_subjects',
        component: componentSujetosMercado,
      },
      {
        url: 'routes.service_activation',
        component: componentActivation,
      },
      {
        url: 'routes.embed',
        component: componentEmbed,
        childrens: [
          {
            url: 'routes.auctions_calendar',
            component: componentCalendario,
          },
        ],
      },
      {
        url: 'routes.users',
        component: componentUsuarios,
        childrens: [
          {
            url: 'routes.myesios',
            component: componentLogin,
          },
          {
            url: 'routes.users',
            component: componentUsuarios,
            childrens: [
              {
                url: 'routes.create',
                component: componentCreateUsuario,
              },
              {
                url: 'routes.see',
                childrens: [
                  {
                    url: ':id',
                    component: componentSeeUsuario,
                  },
                ],
              },
              {
                url: 'routes.edit',
                childrens: [
                  {
                    url: ':id',
                    component: componentEditUsuario,
                  },
                ],
              },
            ],
          },
          {
            url: 'routes.about',
            component: componentAbout,
            step: 'ACERCA DE E·SIOS',
          },
          {
            url: 'routes.exchanges_i',
            component: componentIntercambiosInternacionales,
            step: 'INTERECAMBIOS INTERNACIONALES',
          },
          {
            url: 'saldo-frontera',
            component: componentSaldoFronteras,
            step: 'SALDO FRONTERA',
          },
          {
            url: 'routes.help-FAQ',
            component: componentAyudaFAQ,
            step: 'AYUDA Y FAQ',
          },
          {
            url: 'routes.lumios',
            component: componentWebCalculadora,
            step: 'CALCULADORA',
          },
          {
            url: 'routes.contact',
            component: componentContact,
            step: 'CONTACTO',
          },
          {
            url: 'routes.umms',
            component: componentInfo,
            step: 'WEB ESIOS INFORMA',
          },
          {
            url: 'routes.tabla',
            component: componentDescargas,
          },
          {
            url: 'routes.legal',
            component: componentPoliticaPrivacidad,
          },
          {
            url: 'routes.sitemap',
            component: componentMapa,
            step: 'MAPA DEL SITIO',
          },
          {
            url: 'routes.supply_curves',
            component: componentSupplyCurves,
            step: 'MAPA DEL',
            childrens: [
              {
                url: ':id',
                component: componentSeeUsuario,
              },
            ],
          },
          {
            url: 'routes.documentation',
            component: componentDocumentacion,
            step: 'DOCUMENTACIÓN',
            childrens: [
              {
                url: ':id',
                component: Document,
              },
            ],
          },
          {
            url: 'routes.glossary',
            component: componentGlosario,
            step: 'GLOSARIO',
          },
          {
            url: 'routes.help',
            component: componentAyuda,
            step: 'AYUDA',
          },
          {
            url: 'routes.information',
            component: componentInfo,
            step: 'INFORMACIÓN',
          },
          {
            url: 'routes.result',
            component: componentResultados,
            step: 'INFORMACIÓN',
          },
          {
            url: 'routes.gyc',
            component: componentgenerConsumo,
            childrens: [
              {
                url: 'routes.snp',
                component: componentnoPeninsulares,
                step: 'SISTEMAS NO PENINSULARES',
                childrens: [
                  {
                    url: '/test',
                    component: componentnoPeninsulares,
                  },
                ],
              },
            ],
          },
          {
            url: 'routes.maps',
            component: componentMapasInteres,
          },
          {
            url: 'routes.myp',
            component: componentMercadosPrecios,
            step: 'MERCADOS Y PRECIOS',
          },
          {
            url: 'routes.gd',
            component: componentGestionDemanda,
            step: 'GESTIÓN DE LA DEMANDA',
          },
          {
            url: 'routes.physical_units',
            component: componentUnidades,
            step: 'UNIDADES FÍSICAS',
          },
          {
            url: 'routes.programming_units',
            component: componentUnidadesProgramacion,
            step: 'UNIDADES DE PROGRAMACIÓN',
          },
          {
            url: 'routes.faq',
            component: componentfaq,
            step: 'FAQ',
          },
          {
            url: 'routes.other_links',
            component: componentEnlace,
            step: 'OTROS ENLACES',
          },
          {
            url: 'routes.interruptibility_snp',
            component: componentinterrupcion,
            step: 'INFORMACIÓN SOBRE EL SERVICIO DE INTERRUMPIBILIDAD PARA PROVEEDORES DE LOS SISTEMAS ELÉCTRICOS DE LOS TERRITORIOS NO PENINSULARES',
          },
          {
            url: 'routes.proposals',
            component: componentPropuestas,
            step: 'PROPUESTAS DE CAMBIOS NORMATIVOS',
          },
          {
            url: 'routes.balance',
            component: componentBalance,
            step: 'BALANCE DE GENERACIÓN PROGRAMADA',
          },
          {
            url: 'routes.pvpc',
            component: componentpvpc,
          },
          {
            url: 'routes.auctions_calendar',
            component: componentCalendario,
            step: 'CALENDARIO',
          },
          {
            url: 'routes.market_subjects',
            component: componentSujetosMercado,
          },
          {
            url: 'routes.analysis',
            component: componentAnalysisLanding,
            childrens: [
              {
                url: ':id',
                component: AnalysisPage,
              },
            ],
          },
          {
            url: 'routes.analysis_mobile',
            component: componentnoAccess,
            step: 'INICIO',
          },
          {
            url: 'routes.outdated_browser',
            component: componentobsoleto,
          },
          {
            url: 'routes.participants_auction',
            component: componenthabilitado,
            step: 'PARTICIPANTES HABILITADOS PARA NOMINAR DERECHOS DE CAPACIDAD EN INTERCONEXIÓN FR-ES',
          },
          {
            url: 'routes.information-about-implementatio-of-market-network-codes',
            component: componentRedMercado,
            step: 'INFORMACION SOBRE IMPLEMENTACIÓN DE LOS CÓDIGOS DE RED DE MERCADOS',
          },
        ],
      },

      {
        url: 'routes.supply_curves',
        component: componentSupplyCurves,
        step: 'CURVAS DE OFERTA',
        childrens: [
          {
            url: ':id',
            component: componentSeeUsuario,
          },
        ],
      },
      {
        url: 'routes.about',
        component: componentAbout,
        step: 'ACERCA DE E·SIOS',
      },
      {
        url: 'routes.exchanges_i',
        component: componentIntercambiosInternacionales,
        step: 'INTERECAMBIOS INTERNACIONALES',
      },
      {
        url: 'routes.saldo-frontera',
        component: componentSaldoFronteras,
        step: 'SALDO FRONTERA',
      },
      {
        url: 'routes.help-FAQ',
        component: componentAyudaFAQ,
        step: 'AYUDA Y FAQ',
      },
      {
        url: 'routes.lumios',
        component: componentWebCalculadora,
        step: 'CALCULADORA',
      },
      {
        url: 'routes.contact',
        component: componentContact,
        step: 'CONTACTO',
      },
      {
        url: 'routes.sitemap',
        component: componentMapa,
        step: 'MAPA DEL SITIO',
      },
      {
        url: 'routes.documentation',
        component: componentDocumentacion,
        step: 'DOCUMENTACIÓN',
        childrens: [
          {
            url: ':id',
            component: Document,
          },
        ],
      },
      {
        url: 'routes.downloads',
        component: componentDescargas,
        step: 'DESCARGAS',
      },
      {
        url: 'routes.glossary',
        component: componentGlosario,
        step: 'GLOSARIO',
      },
      {
        url: 'routes.help',
        component: componentAyuda,
        step: 'AYUDA',
      },
      {
        url: 'routes.umms',
        component: componentInfo,
        step: 'WEB ESIOS INFORMA',
      },
      {
        url: 'routes.legal',
        component: componentPoliticaPrivacidad,
      },
      {
        url: 'routes.information',
        component: componentInfo,
        step: 'INFORMACIÓN',
      },
      {
        url: 'routes.result',
        component: componentResultados,
        step: 'INFORMACIÓN',
      },
      {
        url: 'routes.gyc',
        component: componentgenerConsumo,
        childrens: [
          {
            url: 'routes.snp',
            component: componentnoPeninsulares,
            step: 'SISTEMAS NO PENINSULARES',
            childrens: [
              {
                url: '/test',
                component: componentnoPeninsulares,
              },
            ],
          },
        ],
      },
      {
        url: 'routes.maps',
        component: componentMapasInteres,
        childrens: [
          {
            url: ':map',
            component: componentMapLinks,
          },
        ],
      },
      {
        url: 'routes.myp',
        component: componentMercadosPrecios,
        step: 'MERCADOS Y PRECIOS',
      },
      {
        url: 'routes.previsiones',
        component: componentPrevisionesCircular,
        step: 'Previsones OS y Circular',
      },
      {
        url: 'routes.gd',
        component: componentGestionDemanda,
        step: 'GESTIÓN DE LA DEMANDA',
      },
      {
        url: 'routes.physical_units',
        component: componentUnidades,
        step: 'UNIDADES FÍSICAS',
      },
      {
        url: 'routes.programming_units',
        component: componentUnidadesProgramacion,
        step: 'UNIDADES DE PROGRAMACIÓN',
      },
      {
        url: 'routes.faq',
        component: componentfaq,
        step: 'FAQ',
      },
      {
        url: 'routes.other_links',
        component: componentEnlace,
        step: 'OTROS ENLACES',
      },
      {
        url: 'routes.proposals',
        component: componentPropuestas,
        step: 'PROPUESTAS DE CAMBIOS NORMATIVOS',
      },
      {
        url: 'routes.balance',
        component: componentBalance,
        step: 'BALANCE DE GENERACIÓN PROGRAMADA',
      },
      {
        url: 'routes.pvpc',
        component: componentpvpc,
      },
      {
        url: 'routes.auctions_calendar',
        component: componentCalendario,
        step: 'CALENDARIO',
      },
      {
        url: 'routes.analysis',
        component: componentAnalysisLanding,
        childrens: [
          {
            url: ':id',
            component: AnalysisPage,
          },
        ],
      },
      {
        url: 'routes.analysis_mobile',
        component: componentnoAccess,
        step: 'INICIO',
      },
      {
        url: 'routes.outdated_browser',
        component: componentobsoleto,
      },
      {
        url: 'routes.participants_auction',
        component: componenthabilitado,
        step: 'PARTICIPANTES HABILITADOS PARA NOMINAR DERECHOS DE CAPACIDAD EN INTERCONEXIÓN FR-ES',
      },
      {
        url: 'routes.news',
        component: componentTitulacionOperacion,
        step: 'TITULARES DE OPERACIÓN',
        childrens: [
          {
            url: ':id',
            component: OperationNew,
          },
        ],
      },
      {
        url: 'routes.page',
        component: componentDashboard,
        childrens: [
          {
            url: 'routes.network_codes_slug',
            component: componentCodigos,
            step: 'IMPLEMENTACIÓN DE CÓDIGOS DE RED EUROPEOS EN ESPAÑA',
          },
          {
            url: 'routes.proposals',
            component: componentPropuestas,
            step: 'PROPUESTAS DE CAMBIOS NORMATIVOS',
          },
          {
            url: 'routes.demand-renewable-forecasts',
            component: componentprevisionesDRenovable,
            step: 'PREVISIONES DE DEMANDA Y GENERACIÓN RENOVABLE (CIRCULAR CNMC 4/2019)',
          },
          {
            url: 'routes.connection_network_codes',
            component: componentCodigosConexion,
            step: 'INFORMACIÓN SOBRE IMPLEMENTACIÓN DE CÓDIGOS DE RED DE CONEXIÓN',
          },
          {
            url: 'routes.interruptibility_snp',
            component: componentinterrupcion,
            step: 'INFORMACIÓN SOBRE EL SERVICIO DE INTERRUMPIBILIDAD PARA PROVEEDORES DE LOS SISTEMAS ELÉCTRICOS DE LOS TERRITORIOS NO PENINSULARES',
          },
          {
            url: 'routes.information-about-implementation-of-market-network-codes',
            component: componentRedMercado,
            step: 'INFORMACION SOBRE IMPLEMENTACIÓN DE LOS CÓDIGOS DE RED DE MERCADOS',
          },
          {
            url: 'routes.information-about-implementation-of-operation-network-codes',
            component: componentOperacion,
            step: 'NFORMACION SOBRE IMPLEMENTACIÓN DE LOS CÓDIGOS DE RED DE OPERACION',
          },
          {
            url: ':id',
            component: StaticPages,
          },
        ],
      },
    ],
  },
  {
    url: 'routes.500',
    component: componenterror,
  },
  {
    url: '/dashboard',
    component: componentDashboard,
    childrens: [
      {
        url: '/home',
        component: componentDashboard,
      },
    ],
  },
  {
    url: '/tab',
    component: componentTab,
  },
];
