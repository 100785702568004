'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';
import moment from 'moment';
import backImage from '../../assets/images/header-default.jpg';
import { componentLayout } from '../../components/layout/layout.component';

let templateInfo = (data) => {
  return componentLayout.render(
    html`
      <div class="esios-info">
        <h1 class="esios-info__title">${i18n.t('umm')}</h1>
        ${data.map(({ title, body, published_at }) => {
          return html`<article class="esios-info__container">
            <time class="esios-info__time" datetime="${published_at}"
              >${moment(published_at).format('DD / MM / YYYY')}</time
            >
            <h2 class="esios-info__subtitle">${title}</h2>
            <p class="">${html([body])}</p>
          </article>`;
        })}
      </div>
    `,
    {
      text: '',
      image: backImage,
      showBanner: true,
    }
  );
};

export { templateInfo };
