import i18n from 'i18next';
import { html } from 'lit-html';
import moment from 'moment';
import { getDecimal } from '../../../shared/components/chart/chart-helpers';
import { getState } from '../../../utils/state';
import { getUrlParams } from '../../../utils/url';
import AnalysisGeographical from '../../analysis/geographical/analysis-geographical';
import { exportDataProccess } from '../form/analysis-form-buttons';
import { geoParseDate, getAgg, getGeoAgg } from '../geographical/geo-utils';
import indicatorsComposited from '../geographical/queries/indicators_composited';
import indicatorsOperationDiv from '../geographical/queries/indicators_operation_div';
import indicatorsSimple from '../geographical/queries/indicators_simple';
import levelIndicatorsComposited from '../geographical/queries/level_indicators_composited';
import levelIndicatorsOperationDiv from '../geographical/queries/level_indicators_operation_div';
import levelIndicatorsSimple from '../geographical/queries/level_indicators_simple';
import { getCustomTime } from './analysis-compo-chart';

const GeoChart = (analysis, indicatorInfo, datevalue = true) => {
  const indicator = indicatorInfo.indicator;
  const indicatorLevel = indicator.nivel[0].geo_nivel;

  // Getting global common state
  const state = getState();
  // Current language we are using
  const lang = state.currentLang;
  const { start_date, end_date, level } = getUrlParams();

  const startDate = analysis.start_date || start_date;
  const endDate = analysis.end_date || end_date;
  let datetime =
    analysis.datetime || moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');

  if (datevalue) {
    datetime = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');
  }
  const currentLevel = analysis.level || level || indicator.nivel[0].geo_nivel;
  let sqlParams = {
    id: indicator.id,
    start_date: moment(new Date(geoParseDate(startDate)))
      .tz('Europe/London')
      .format('YYYY-MM-DD HH:mm:ss'),
    end_date: moment(new Date(geoParseDate(endDate)))
      .tz('Europe/London')
      .format('YYYY-MM-DD HH:mm:ss'),
    agg: getAgg(indicator.magnitud[0].id),
    geo_agg: getGeoAgg(indicator.magnitud[0].id),
    decimals: getDecimal(indicator.magnitud[0].id) || 1,
    lang: lang,
    nivel: currentLevel,
    datetime,
    where_geoids: '',
  };
  // query for cartoCss
  let query;
  let geoIds = getUrlParams().geoids;
  // ! setting geoids
  if (geoIds) {
    sqlParams.where_geoids = ' AND geo_def.cartodb_id IN (' + geoIds + ') ';
  }

  if (currentLevel != indicatorLevel) {
    if (indicator.operation === 'DIV' && indicator.composited) {
      query = levelIndicatorsOperationDiv(sqlParams);
    } else if (indicator.composited) {
      query = levelIndicatorsComposited(sqlParams);
    } else {
      query = levelIndicatorsSimple(sqlParams);
    }
  } else {
    if (indicator.operation === 'DIV' && indicator.composited) {
      query = indicatorsOperationDiv(sqlParams);
    } else if (indicator.composited) {
      sqlParams.sons = indicatorInfo.compare || [];
      query = indicatorsComposited(sqlParams);
    } else {
      query = indicatorsSimple(sqlParams);
    }
  }

  $.ajax({
    url: 'https://ree-admin.carto.com/api/v2/sql',
    data: { q: query, type: 'json' },
  }).done((response) => {
    if (response.total_rows == 0) {
      let noData = `<div class="widget-nodata">
        <div>${i18n.t('no_data')}</div>
      </div> `;
      document.getElementById('geo-map-container-' + indicator.id).innerHTML =
        noData;
      document.getElementById('geo-legend').classList.add('is-hidden');
    } else {
      AnalysisGeographical(analysis, indicator, sqlParams, response);
    }
    exportDataProccess('#analysis-btn', response, analysis);
  });

  return html`
    <h2 class="info-chart-title">${getCustomTime(analysis)}</h2>
    <div id="geo-map-container-${indicator.id}">
      <div style="display: flex;" class="spinner-container">
        <div class="spinner"></div>
      </div>
    </div>
    <div id="geo-legend" class="mod-analysis-map-legend is-hidden"></div>
  `;
};

export default GeoChart;
