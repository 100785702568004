'use strict';

import { html } from 'lit-html';
import imgOperation from '../../assets/images/header-titulares-operacion.jpg';
import Banner from '../../shared/components/banner/banner';
import { BreadCrumbs } from '../../shared/components/breadcrumbs/breadcrumbs.template';
import { sRender } from '../../utils/render';
import { getSplittedRoute } from '../../utils/url.js';
import { componentnoEncontrada } from '../noEncontrada/noEncontrada.component';
import { getDataOfSlug } from './static-pages.service';

const StaticPages = (content) => {
  try {
    const slugToSearch = getSplittedRoute().pop();
    sRender(BreadCrumbs, 'breadcrumb', {}, {}, slugToSearch, '');
    getDataOfSlug(slugToSearch).then((data) => {
      if (data.contents?.length == 0) {
        componentnoEncontrada.render();
      }
      const returnContent = (body, title) =>
        html([`<h1 class='esios-static-page__title'>${title}</h1>` + body]);
      sRender(
        returnContent,
        'new-view',
        data?.content?.body,
        data?.content?.title
      );
      sRender(
        BreadCrumbs,
        'breadcrumb',
        {},
        {},
        slugToSearch,
        data?.content?.title
      );
    });

    return html`
      ${Banner(imgOperation)}
      <div class="esios-layout__main esios-static-page" style="top: 0px!important">
        <div id="breadcrumb"></div>
        <div class="esios-static-page__container">
          <div id="new-view" class="esios-static-page__content"></div>
          </div>
        </div>
      </div>
      <div class="esios-layout__footer-image"></div>
    `;
  } catch (error) {
    console.error(error);
  }
};

export default StaticPages;
