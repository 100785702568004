'use strict';

/**
 * @description faq Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { store } from '../../app.init.js';
import { servicesfaq } from './faq.services';
import { templatefaq } from './faq.template.js';
import { Utils } from '../../utils/utils';
import i18n from 'i18next';
import { componentRapidAccess } from '../../shared/components/rapid-access/rapid-access.component.js';

let componentfaq = (function () {
  let context = {};
  let handlers = {};
  let renderSuccess = false;

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    document.title = `${i18n.t('faq_title')} | ${i18n.t('page_subtitle')}`;
    context.name = state.exampleReducer.name;
  };

  let renderComponent = async () => {
    const state = store.getState();
    try {
      // Render the template to the document
      let contactData = await servicesfaq.init(
        state.exampleReducer.currentLang
      );
      templatefaq(contactData);
      changetab(contactData);

      const RapidAccessData = [
        {
          id: '#info-en-esta-web',
          title: 'web_info',
        },
        {
          id: '#mas-detalle',
          title: 'further_analysis_information',
        },
        {
          id: '#actualizacion-de-informacion',
          title: 'how_often_information_is_updated',
        },
        {
          id: '#configurar-favoritos',
          title: 'favorite_content',
        },
        {
          id: '#acceso-movil',
          title: 'mobile_access',
        },
      ];
      componentRapidAccess.render(RapidAccessData);
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
    document.querySelector('.esios-footer').classList.add('esios-footer--faq');
  };

  const changetab = () => {
    document.querySelectorAll('a[href^="#"]').forEach((a) => {
      a.addEventListener('click', function (e) {
        e.preventDefault();
        var href = this.getAttribute('href');
        var elem =
          document.querySelector(href) ||
          document.querySelector(
            'a[name=' + href.substring(1, href.length) + ']'
          );
        var elemPosition = elem.getBoundingClientRect().top + window.scrollY;
        // var elemPosition = window.scrollTo(0, elem.getBoundingClientRect().top);

        let headerNavHeight =
          document.querySelector('.esios-header').clientHeight;
        let breadcrumbsHeight = document.querySelector(
          '.esios-layout__breadcrumbs__wrapper'
        ).clientHeight;
        var tabletOffset = headerNavHeight + breadcrumbsHeight;
        var desktopOffset = 15 + breadcrumbsHeight;
        var offset = Utils.isTablet() ? tabletOffset : desktopOffset;

        window.scroll({
          top: elemPosition - offset,
          left: 0,
          behavior: 'smooth',
        });
      });
    });
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentfaq };
