'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';
import { componentLayout } from '../../components/layout/layout.component';

let templateWebCalculadora = (context, handlers, data, fechaS) => {
  return componentLayout.render(
    html`
      <div class="esios-web-calculadora">
        <h1 class="esios-web-calculadora__title">${i18n.t('calculadora')}</h1>
        <div class="widget-toolbar">
          <div class="esios-toolbar">
            <div class="esios-toolbar1"></div>
            <div class=" pickers-wrapper">
              <div id="gen_conventional-time"></div>
            </div>
          </div>
        </div>
        <p class="highlight-text">${i18n.t('calculadora_subtitle')}</p>
        <div id="tabwithcontent"></div>
        ${['1', '2'].map((element) => {
          return html` <div
            id="tab-web-calculadora${element}"
            class="tab-web-calculadora tab__content"
          ></div>`;
        })}

        <div id="accordion" class="esios-accordion">
          <div>
            <span class="icon-0" style="pointer-events: none"></span>
            ${html([i18n.t('calculadora_tab1')])}
          </div>
          <p id="accordion-tab-calculadora1"></p>
          <div>
            <span class="icon-1" style="pointer-events: none"></span>
            ${html([i18n.t('calculadora_tab2')])}
          </div>
          <p id="accordion-tab-calculadora2"></p>
        </div>

        <div id="tabwithbody"></div>

        <div id="spinner1" class="spinner-container">
          <div class="spinner"></div>
        </div>
        <div id="results" style="display:none">
          ${data.status || !handlers.hasData(data, fechaS)
            ? html`
                <div class="esios-web-calculadora__panel">
                  <h1 class="esios-web-calculadora__title">
                    ${i18n.t('result')}
                  </h1>
                  <table>
                    <tr>
                      <td class="section-left no-data">
                        ${i18n.t('no_data_available')}
                      </td>
                    </tr>
                  </table>
                  <div class="main-right">
                    <div class="billing">
                      <p>${i18n.t('final_bill')}</p>
                      <p class="billing-p"><label id="TOTAL">-</label> €</p>
                    </div>
                  </div>
                </div>
              `
            : html`
                <div class="esios-web-calculadora__panel">
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <h1 class="esios-web-calculadora__title">
                            ${i18n.t('result')}
                          </h1>
                        </th>
                      </tr>
                    </thead>
                    <tr class="info">
                      <td>
                        Punta (<label id="A1"
                          >${data[0]?.PVPC_PBC_P1_DD
                            ? data[0]?.PVPC_PBC_P1_DD[fechaS]?.Precio
                            : data[0]?.PVPC_CYM_P1_DD[fechaS]?.Precio}</label
                        >
                        €/kWh)
                      </td>
                      <td class="main-right section-right">
                        <label id="B1">0</label> €
                      </td>
                    </tr>
                    <tr>
                      <td class="section-left">
                        ${i18n.t('calculadora_access_rate')}
                      </td>
                    </tr>
                    <tr>
                      <td class="section-left">
                        (<label id="P1">0</label> kWh X
                        <label id="C1"
                          >${data[0]?.PVPC_PBC_P1_DD
                            ? data[0]?.PVPC_PBC_P1_DD[fechaS]['Peaje Acceso']
                            : data[0]?.PVPC_CYM_P1_DD[fechaS][
                                'Peaje Acceso'
                              ]}</label
                        >
                        €/kWh)
                      </td>
                      <td class="section-right"><label id="D1">0</label> €</td>
                    </tr>
                    <tr>
                      <td class="section-left">${i18n.t('energy_cost')}</td>
                    </tr>
                    <tr>
                      <td class="section-left">
                        (<label id="P1b">0</label> kWh X
                        <label id="E1"
                          >${data[0]?.PVPC_PBC_P1_DD
                            ? data[0]?.PVPC_PBC_P1_DD[fechaS]['Coste Energia']
                            : data[0]?.PVPC_CYM_P1_DD[fechaS][
                                'Coste Energia'
                              ]}</label
                        >
                        €/kWh)
                      </td>
                      <td class="section-right"><label id="F1">0</label> €</td>
                    </tr>
                    <tr class="info">
                      <td>
                        Llano (<label id="A2"
                          >${data[1]?.PVPC_PBC_P2_DD
                            ? data[1]?.PVPC_PBC_P2_DD[fechaS]?.Precio
                            : data[1]?.PVPC_CYM_P2_DD[fechaS]?.Precio}</label
                        >
                        €/kWh)
                      </td>
                      <td class="main-right section-right">
                        <label id="B2">0</label> €
                      </td>
                    </tr>
                    <tr>
                      <td class="section-left">
                        ${i18n.t('calculadora_access_rate')}
                      </td>
                    </tr>
                    <tr>
                      <td class="section-left">
                        (<label id="P2">0</label> kWh X
                        <label id="C2"
                          >${data[1]?.PVPC_PBC_P2_DD
                            ? data[1]?.PVPC_PBC_P2_DD[fechaS]['Peaje Acceso']
                            : data[1]?.PVPC_CYM_P2_DD[fechaS][
                                'Peaje Acceso'
                              ]}</label
                        >
                        €/kWh)
                      </td>
                      <td class="section-right"><label id="D2">0</label> €</td>
                    </tr>
                    <tr>
                      <td class="section-left">${i18n.t('energy_cost')}</td>
                    </tr>
                    <tr>
                      <td class="section-left">
                        (<label id="P2b">0</label> kWh X
                        <label id="E2"
                          >${data[1]?.PVPC_PBC_P2_DD
                            ? data[1]?.PVPC_PBC_P2_DD[fechaS]['Coste Energia']
                            : data[1]?.PVPC_CYM_P2_DD[fechaS][
                                'Coste Energia'
                              ]}</label
                        >
                        €/kWh)
                      </td>
                      <td class="section-right"><label id="F2">0</label> €</td>
                    </tr>
                    <tr class="info">
                      <td>
                        Valle (<label id="A3"
                          >${data[2]?.PVPC_PBC_P3_DD
                            ? data[2]?.PVPC_PBC_P3_DD[fechaS]?.Precio
                            : data[2]?.PVPC_CYM_P3_DD[fechaS]?.Precio}</label
                        >
                        €/kWh)
                      </td>
                      <td class="main-right section-right">
                        <label id="B3">0</label> €
                      </td>
                    </tr>
                    <tr>
                      <td class="section-left">
                        ${i18n.t('calculadora_access_rate')}
                      </td>
                    </tr>
                    <tr>
                      <td class="section-left">
                        (<label id="P3">0</label> kWh X
                        <label id="C3"
                          >${data[2]?.PVPC_PBC_P3_DD
                            ? data[2]?.PVPC_PBC_P3_DD[fechaS]['Peaje Acceso']
                            : data[2]?.PVPC_CYM_P3_DD[fechaS][
                                'Peaje Acceso'
                              ]}</label
                        >
                        €/kWh)
                      </td>
                      <td class="section-right"><label id="D3">0</label> €</td>
                    </tr>
                    <tr>
                      <td class="section-left">${i18n.t('energy_cost')}</td>
                    </tr>
                    <tr>
                      <td class="section-left">
                        (<label id="P3b">0</label> kWh X
                        <label id="E3"
                          >${data[2]?.PVPC_PBC_P3_DD
                            ? data[2]?.PVPC_PBC_P3_DD[fechaS]['Coste Energia']
                            : data[2]?.PVPC_CYM_P3_DD[fechaS][
                                'Coste Energia'
                              ]}</label
                        >
                        €/kWh)
                      </td>
                      <td class="section-right"><label id="F3">0</label> €</td>
                    </tr>
                  </table>
                  <div class="main-right">
                    <div class="billing">
                      <p>${i18n.t('final_bill')}</p>
                      <p class="billing-p"><label id="TOTAL">0</label> €</p>
                    </div>
                  </div>
                </div>
              `}
        </div>
        <br />
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-6">
            <div class="esios-home__container-pvpc">
              <div class="mod-highlight mod-highlight--pvpc pvpc-home">
                <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.pvpc')}">
                  <h1>${i18n.t('pvpc')}</h1>
                  <p>${i18n.t('pvpc_highlightv2')}</p>
                </a>
              </div>
            </div>
          </div>
          <div class="col-sm-3"></div>
        </div>
        <br />
      </div>
    `,
    {
      text: '',
      image: false,
      showBanner: false,
    }
  );
};

export { templateWebCalculadora };
