import { html } from 'lit-html';
import { componentLayout } from '../../components/layout/layout.component';
import backImage from '../../assets/images/header-default.jpg';
let templateSeeUsuarios = (user) => {
  return componentLayout.render(
    html`
      <div class="esios-usuarios">
        <h1>Viendo al usuario</h1>
        <table
          id="example"
          class="table table-striped table-bordered"
          style="width:100%"
        >
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Email</th>
              <th>Rol</th>
              <th>Api Token</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>${user.id}</td>
              <td>${user.name}</td>
              <td>${user.email}</td>
              <td>${user.role}</td>
              <td>A8789addsdf9udd8h2h9fsd98yf</td>
              <td>
                <a class="esios-usuarios__edit esios-usuarios__edit--see-user"
                  >Editar</a
                ><a>Eliminar</a
                ><a class="esios-usuarios__api-key">Generar Api Key</a
                ><a>Borrar Api Key</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    `,
    {
      image: backImage,
    }
  );
};

export { templateSeeUsuarios };
