"use strict";
import { html } from "lit-html";
import { store } from "../../../app.init";
import { setShareState } from '../../../state/actions'

let templatePieGraph = (id, middleContent) => {
  const state = store.getState();

  return html`
    <div class="esios-pie-graph chart" id="${id}">
      ${middleContent}
    </div>
  `;
};

export { templatePieGraph };
