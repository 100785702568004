'use strict';
import i18n from 'i18next';
import 'regenerator-runtime/runtime';
import { api } from './../../utils/api-interceptor';

let servicesResultados = (() => {
  let init = () => {};
  const getResults = async (params) => {
    const { query, completeWord, onlyDesciption } = params;
    let urlApi = `search?query=${query}`;

    if (completeWord && onlyDesciption) {
      urlApi = urlApi + '&competeword=true&onlydescription=true';
    } else if (completeWord) {
      urlApi = urlApi + '&competeword=true';
    } else if (onlyDesciption) {
      urlApi = urlApi + '&onlydescription=true';
    }

    urlApi = urlApi + `&locale=${i18n.t('routes.lng')}`;

    try {
      const data = await api(urlApi);
      return data.results;
    } catch (error) {}
  };

  return {
    init: init,
    getResults: (params) => getResults(params),
  };
})();

export { servicesResultados };
