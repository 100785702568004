'use strict';
import moment from 'moment';
import 'regenerator-runtime/runtime';
import { api } from '../../utils/api-interceptor';
import servicesLogin from '../login/login.service';
import { Utils } from '../../utils/utils';

let servicesMyEsios = (() => {
  let init = (e) => llamarServicio(e);

  let getFavourites = async () => {
    let token = servicesLogin().validateSession();
    return api(
      'favourites?authentication_token=' +
        token +
        '&rdn=' +
        Math.floor(Math.random() * 1000)
    );
  };

  let changeOrder = (order) => {
    let token = servicesLogin().validateSession();
    let body = 'authentication_token=' + token + '&favourites_order=' + order;
    return api('favourites', {
      method: 'PUT',
      body,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });
  };

  const getDataFromWidget = async (id, params) => {
    const { lang, date, globalState } = params;
    const timeInHours = globalState.pages.home.indicatorsTime;
    const customDates = [10033, 1293];
    let calculatedDateMin;
    let calculatedDateHour;
    let minutes;
    let newDate;
    let urlAnalysis;

    newDate = date
      ? moment(date, 'DD-MM-YYYY')
          .hours(timeInHours.split(':')[0] ? timeInHours.split(':')[0] : '00')
          .minutes(timeInHours.split(':')[1] ? timeInHours.split(':')[1] : '00')
          .seconds('00')
          .format()
      : '';
    //Varibale para los id que no son widgets
    let findate = newDate
      ? moment(newDate).hours(23).minutes(59).seconds(59).format()
      : '';

    minutes = Math.floor(moment().tz('Europe/Madrid').format('mm') / 10) * 10;

    calculatedDateMin = moment(date)
      .tz('Europe/Madrid')
      .minutes(minutes)
      .subtract('10', 'minutes')
      .format('YYYY-MM-DDTHH:mm');

    calculatedDateHour = moment(date)
      .tz('Europe/Madrid')
      .hours(`${moment().tz('Europe/Madrid').format('HH')}`)
      .minutes('00')
      .format('YYYY-MM-DDTHH:mm');
    if (
      id == 544 ||
      id == 541 ||
      id == 542 ||
      id == 543 ||
      id == 1775 ||
      id == 10358 ||
      id == 1776 ||
      id == 10359 ||
      id == 1774 ||
      id == 1742 ||
      id == 460 ||
      id == 461 ||
      id == 603 ||
      id == 620 ||
      id == 10034 ||
      id == 621 ||
      id == 604 ||
      id == 619 ||
      id == 622 ||
      id == 10249
    ) {
      if (id === 1774 || id === 622) {
        newDate = newDate ? moment(newDate).subtract(5, 'years').format() : '';
      } else if (id === 461 || id === 621 || id === 620) {
        newDate = newDate
          ? moment(newDate).subtract(12, 'months').format()
          : '';
      } else if (id === 603 || id === 604 || id === 619) {
        newDate = newDate ? moment(newDate).subtract(7, 'days').format() : '';
      }

      return api(
        `indicators/${id}?${
          newDate
            ? `start_date=${newDate}&end_date=${
                findate.replace('T00:00:00', 'T23:59:59')
                  ? findate.replace('T00:00:00', 'T23:59:59')
                  : newDate.replace('T00:00:00', 'T23:59:59')
              }&`
            : ``
        }locale=${lang}`
      ).then((data) => {
        const values = data.indicator.values.map((el) => {
          return {
            x: new Date(el.datetime),
            y: el.value,
          };
        });
        urlAnalysis =
          '/es/analisis/' +
          id +
          '?start_date=' +
          newDate +
          '&end_date=' +
          findate +
          'geoids=';
        const name = data.indicator.name;
        const description = '';
        const slug = data.indicator.short_name;

        const indicator = {
          values: values,
          print_values: data.indicator.values,
          alias: data.indicator.short_name,
          time: data.indicator.tiempo[0].id,
          composited: data.indicator?.composited,
          magnitud: data.indicator.magnitud[0].id,
          disaggregated: data.indicator.disaggregated,
          short_name: data.indicator.short_name,
          name: data.indicator.name,
          step: data.indicator.step_type,
          id: data.indicator.id,
          geo_id: data.indicator.geos[0]?.geo_id,
        };

        const indicators = [indicator];

        return {
          description,
          name,
          indicators,
          urlAnalysis,
          slug,
        };
      });
    } else {
      return api(
        `widgets/${id}?${
          newDate ? `datetime=${moment(newDate).format()}&` : ``
        }locale=${lang}`
      ).then((data) => {
        const slug = data.widget.id;
        const indicators = data.widget.indicators;
        const description = data.widget.description;
        const name = data.widget.name;
        const magnituds = [];
        const alias = [];
        const indicatorName = [];
        const shortName = [];
        let dateForCurrent;
        urlAnalysis = Utils.getUrlAnalysis(data.widget.main_indicators);

        return Promise.allSettled(
          indicators.map((el) => {
            magnituds.push(el.display_options);
            alias.push(el.alias);
            shortName.push(el.short_name);
            indicatorName.push(el.name);
            if (parseInt(id) === 26 && !el.url.includes('datetime')) {
              dateForCurrent = customDates.includes(el.id)
                ? `&datetime=${calculatedDateMin}`
                : `&datetime=${calculatedDateHour}`;
            }
            return api(
              `${el.url.substring(1)}&locale=${lang}${
                parseInt(id) == 26 && dateForCurrent ? dateForCurrent : ''
              }`
            );
          })
        ).then((data) => {
          const indicators = data.map(({ value }, index) => {
            let values;
            if (value.status) {
              return {
                values: [{ x: 0, y: 0 }],
                alias: '',
                display_options: null,
                composited: false,
                magnitud: [{}],
                disaggregated: false,
                short_name: '',
                step: 'linear',
                id: 1293,
                geo_id: undefined,
              };
            } else {
              values = value.indicator.values.map((el) => {
                const date = new Date(el.datetime);
                return {
                  x: date,
                  y: el.value,
                };
              });
            }

            return {
              values,
              print_values: value.indicator.values,
              alias: alias[index],
              time: value.indicator.tiempo[0]?.id,
              display_options: magnituds[index],
              composited: value.indicator?.composited,
              magnitud: value.indicator?.magnitud,
              disaggregated: value.indicator?.disaggregated,
              short_name: shortName[index],
              name: indicatorName[index],
              step: value.indicator.step_type,
              id: value.indicator.id,
              geo_id: value.indicator.geos[0]?.geo_id,
            };
          });

          return {
            description,
            name,
            indicators,
            slug,
            urlAnalysis,
          };
        });
      });
    }
  };

  return {
    init: (e) => init(e),
    getFavourites,
    getDataFromWidget,
    changeOrder,
  };
})();

export default servicesMyEsios;
