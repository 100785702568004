'use strict';
import { api } from '../../utils/api-interceptor';
import i18n from 'i18next';
import { Utils } from '../../utils/utils';

let servicesDescargas = (() => {
  let init = () => {};

  const getArchives = async (params) => {
    const { type, date_init, date_end } = params;
    const UTCStartDate = Utils.dateToUTCString(new Date(date_init));
    const UTCEndDate = Utils.dateToUTCString(
      new Date(new Date(date_end).setHours(23, 59, 59))
    );
    const urlApi = `archives?date_type=${type}&start_date=${UTCStartDate}&end_date=${UTCEndDate}&locale=${i18n.t(
      'routes.lng'
    )}`;

    try {
      const data = await api(urlApi);
      const finalData = data.archives
        .map((el) => {
          return {
            ...el,
            slug: Utils.removeAccents(el.name).toLowerCase(),
          };
        })
        .sort((a, b) => {
          if (a.slug < b.slug) {
            return -1;
          }
          if (a.slug > b.slug) {
            return 1;
          }
          return 0;
        });
      return finalData;
    } catch (error) {}
  };

  return {
    init: init,
    getArchives: (params) => getArchives(params),
  };
})();

export { servicesDescargas };
