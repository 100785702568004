import { html } from 'lit-html';
import moment from 'moment';
import DPOptions from './datepicker-options';

const DatePicker = ({
  value,
  onSelected,
  id,
  disabled,
  minDate,
  maxDate,
  isAnalysis,
}) => {
  let [mDay, mMonth, mYear] = value.split('/');
  instanceDatePicker(id);
  return html`<input id="${id}" type="text" value=${value} />`;

  function instanceDatePicker(id) {
    // lord forgive me
    const instancer = setInterval(instance, 0);
    function instance() {
      const node = document.getElementById(id);
      if (node) {
        clearInterval(instancer);
        const options = {
          ...DPOptions(),
          disabled,
          onSelect: (text) => {
            const [day, month, year] = text.split(' / ');
            if (onSelected) {
              onSelected({ day, month, year, text });
            }
          },
        };
        if (minDate) {
          options['minDate'] = minDate;
        }
        if (maxDate) {
          options['maxDate'] = maxDate;
        }
        $(`#${id}`).datepicker(options);
        // Tricky jQuery behaviour
        setTimeout(() => {
          $(`#${id}`).datepicker(
            'setDate',
            new Date(`${mMonth} / ${mDay} / ${mYear}`)
          );
          if (isAnalysis) {
            $(`#${id}`).off('click');
            $(`#${id}`).on('click', () => {
              if ($('.ui-datepicker-current')) {
                $('.ui-datepicker-current').on('click', () => {
                  const text = moment()
                    .tz('Europe/Madrid')
                    .format('DD / MM / YYYY');
                  const [day, month, year] = text.split(' / ');
                  onSelected({ day, month, year, text });
                });
              }
            });

            $.datepicker._gotoToday = function (id) {
              $(id).datepicker('setDate', new Date()).datepicker('hide').blur();
            };
          }
        }, 0);
      }
    }
  }
};

export default DatePicker;
