'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';
import backImage from '../../assets/images/header-unidades-fisicas.jpg';
import leftArrow from '../../assets/images/left-arrow.svg';
import rightArrow from '../../assets/images/right-arrow.svg';
import { componentLayout } from '../../components/layout/layout.component';
import { templateBoxMarket } from '../../shared/components/box-market/box-market.template';
import { templateBoxMarketColumn } from '../../shared/components/box-market-column/box-market-column.template';

let templateUnidades = (
  context,
  handlers,
  listDocumen,
  pageTitle,
  select,
  paginationValues,
  pagination,
  filterForm,
  searchForm,
  listMarket,
  lang
) => {
  const {
    onTotalChange,
    onPageChange,
    onQueryChange,
    onPotenciaChange,
    onTipoProduccionChange,
    onVinculacionSMChange,
    onVinculacionUPChange,
    onHide,
    onSearch,
    onSelectChange,
  } = handlers;

  const options = select.map((item) => item.options);
  const selects = [
    {
      value: 'potencia',
      label: 'maximum_power_capacity_MW',
    },
    {
      value: 'tipoproduccion',
      label: 'production_type',
    },
    {
      value: 'vinculacionsm',
      label: 'linking_with_SM',
    },
    {
      value: 'vinculacionup',
      label: 'pairing_with_UP',
    },
  ];

  return componentLayout.render(
    html`
      <head>
        <link
          href="https://unpkg.com/boxicons@2.0.9/css/boxicons.min.css"
          rel="stylesheet"
        />
      </head>

      <div class="esios-unidades">
        <h1 class="esios-tabla__title">${i18n.t('physical_units')}</h1>
        <p>${i18n.t('list_of_generation_units')}</p>
        <div id="print-list"></div>
        <!--filtros con su onchange-->

        <div class="esios-unidades__content unidades-selects hidden">
          <div class="esios-unidades__wrap esios-unidades__wrap--input">
            <input
              class="esios-unidades__tabla__input"
              placeholder="${i18n.t('go_search')}"
              type="text"
              @keyup="${onQueryChange}"
            />
            <button
              class="esios-unidades__button-remove hidden"
              @click="${onHide}"
              )
            >
              &nbsp
            </button>
          </div>

          ${selects.map(
            (select, index) =>
              html`<div
                class="init-dropdown esios-unidades__wrap esios-unidades__dropdown"
              >
                <select
                  class="esios-unidades__tabla--dropdown2 ree-select-${select.value}"
                  value="${filterForm[select.value]}"
                  @change="${onSelectChange(index)}"
                >
                  <option class="option" value="" selected disabled hidden>
                    ${i18n.t(select.label)}
                  </option>
                  ${options[index]?.map(
                    (item) =>
                      html` <option class="option" value="${item.value}">
                        ${i18n.t(item.label)}
                      </option>`
                  )}
                </select>
              </div>`
          )}
        </div>

        <div class="esios-tablaUnidades">
          <div id="spinner1" class="spinner-container">
            <div class="spinner"></div>
          </div>
          <div id="download-table"></div>

          <div id="pagination" class="esios-pagination">
            <div class="esios-pagination__results hidden">
              <span class="esios-pagination__txt esios-pagination__arrow">
                ${i18n.t('showing')}
                <select
                  class="esios-pagination__select"
                  value="${pagination.pagination}"
                  @change="${onTotalChange}"
                >
                  ${paginationValues.map(
                    (item) => html`<option value="${item}">${item}</option>`
                  )}
                </select>
              </span>
              <span class="esios-pagination__txt"
                >${i18n.t('text_results')} ${pagination.total}</span
              >
            </div>
            ${pagination.totalPages > 0
              ? html`<div
                  id="pagination-unidades"
                  class="esios-pagination__paginator"
                ></div>`
              : ''}
          </div>
        </div>
      </div>

      <div id="unidad-fisica-buttons" class="esios-smercado__buttons"></div>
    `,
    {
      image: backImage,
    },
    html``,
    {
      title: `${i18n.t('more')}`,
      subcontent: html`
        <div class="esios-documents">
          <div class="grid-xs-12 documents-container-download">
            <div class="mod-documents-download">
              ${templateBoxMarketColumn(listMarket)}
              <div class="mod-buttons">
                ${lang == 'es'
                  ? html` <a
                      class="mod-buttons__btn"
                      href="https://www.ree.es/es/clientes"
                      target="_blank"
                      >${i18n.t('gde_website_text')}</a
                    >`
                  : ''}
              </div>
            </div>
          </div>
        </div>
      `,
    }
  );
};

export { templateUnidades };
