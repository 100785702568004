const indicatorsOperationDiv = (data) => {
  return `
  WITH t as (

    SELECT indicator_values.geo_id, sum(value * indicator_relations.factor * CASE WHEN indicator_relations.factor BETWEEN 0.001 AND 100 THEN 1 ELSE 0 END) / sum(greatest(value,0) * indicator_relations.factor * CASE WHEN indicator_relations.factor BETWEEN -1 AND -0.001 THEN -1 ELSE 0 END) AS total
  
    FROM "indicator_values"
      INNER JOIN "indicators" ON "indicators"."id_indicator" = "indicator_values"."indicator_id"
      INNER JOIN "indicator_relations" ON "indicator_relations"."son_id" = "indicators"."id_indicator"
  
    WHERE "indicator_relations"."father_id" = ${data.id}
      AND datetime >= to_timestamp('${data.start_date}', 'YYYY-MM-DD HH24:MI:SS')
      AND datetime <= to_timestamp('${data.end_date}', 'YYYY-MM-DD HH24:MI:SS')
  
    GROUP BY "indicator_values"."geo_id"
  ), j as (
  
    SELECT geo_def.cartodb_id, geo_def.name AS name,
    (SELECT ROUND(${data.geo_agg}(total)::numeric, ${data.decimals}) FROM t WHERE geo_id = cartodb_id LIMIT 1) as total,
    to_timestamp('${data.datetime}', 'YYYY-MM-DD HH24:MI:SS') AS stamp
    FROM geo_def
    WHERE nivel = ${data.nivel} ${data.where_geoids}
  )
  
  SELECT j.*, k.name as local_name FROM j JOIN GEOLOCALIZATION_TRANSLATIONS k ON k.geolocalization_id=j.cartodb_id WHERE j.total IS NOT NULL AND k.locale='${data.lang}'
  
  `;
};

export default indicatorsOperationDiv;
