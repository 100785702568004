'use strict';
import i18next from 'i18next';
import { html } from 'lit-html';
import { getUrlParams } from '../../../utils/url';
const Tabs = (data, onTabSelected) => {
  let { activeTab, tabsContent } = data;
  let tabsClasses;

  // ? Identificador por clase del contenido de los tabs
  tabsClasses = document.querySelectorAll(`.${tabsContent[0].class}`);

  // ? Función que remueve la clase q activa un tab
  const removeClassActive = () => {
    let listItems = document.querySelectorAll('.ui-corner-top');
    if (data.parent) {
      listItems = document.querySelectorAll(data.parent + ' .ui-corner-top');
    }

    listItems.forEach((item) => item.classList.remove('ui-tabs-active'));
  };

  const showTable = (itemTab = activeTab) => {
    let listItems = document.querySelectorAll('.ui-corner-top');
    if (data.parent) {
      listItems = document.querySelectorAll(data.parent + ' .ui-corner-top');
    }
    const selectedTab = Array.from(listItems).indexOf(itemTab);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (
      window.location.pathname.split('/')[2] === i18next.t('routes.analysis')
    ) {
      urlParams.set('vis', selectedTab + 1);
      history.pushState({}, '', window.location.pathname + '?' + urlParams);
    }

    tabsClasses.forEach((content, index) => {
      if (index === selectedTab) {
        content.classList.add('active');
      } else {
        content.classList.remove('active');
      }
    });
  };

  const mouseover = (e) => {
    /**Esto es para cambiar los estilos del tab cuando el mouse este sobre el mismo */
    changeTabStyle(e, 'add');
  };

  const mouseout = (e) => {
    /**Esto es para cambiar los estilos del tab cuando el mouse ya no este sobre el mismo */
    changeTabStyle(e, 'remove');
  };

  const changeTabStyle = (e, tipo) => {
    if (
      e.target.classList[0] == 'icon-0' ||
      e.target.classList[0] == 'icon-1'
    ) {
      return '';
    }

    let itemTab = e.target.parentElement;

    const listItems = document.querySelectorAll('.ui-corner-top');
    const index = Array.from(listItems).indexOf(itemTab);

    const item = listItems[index].children;
    if (tipo === 'add') {
      item[0].classList.add('ui-tabs-over');
    } else if (tipo === 'remove') {
      item[0].classList.remove('ui-tabs-over');
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    removeClassActive();
    e.target.parentElement.classList.add('ui-tabs-active');
    showTable(e.target.parentElement);
    let elem = document.getElementsByClassName('ui-tabs-active');
    let text = Array.from(elem)[0].innerText;

    if (
      text === 'SUBASTAS' ||
      text === 'AUCTIONS' ||
      text === 'GENERAL INFORMATION' ||
      text === 'INFORMACIÓN GENERAL' ||
      text === 'PROVIDERS GUIDE' ||
      text === 'ÁREA DE PROVEEDORES'
    ) {
      if (data.parent == '#tabwithcontent2') {
        window.scroll({
          top: 1900,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  };

  const onClick = (ev) => {
    handleClick(ev);
    if (onTabSelected) {
      const tabs = ev.currentTarget.parentElement.parentElement.children;
      const selectedTab = ev.currentTarget.parentElement;
      const index = Array.from(tabs).indexOf(selectedTab);
      onTabSelected(index, ev);
    }

    let condition = window.location.pathname.split('/')[2];
    if (condition === i18next.t('routes.gd')) {
      const url = new URL(window.location.href);
      let ancleContent = `${ev.target.getAttribute('href')}`;
      if (!url.href.includes('#')) {
        window.history.pushState(
          { path: url.href },
          '',
          url.href + ancleContent
        );
      } else {
        window.history.pushState(
          { path: url.href.split('#')[0] },
          '',
          url.href.split('#')[0] + ancleContent
        );
      }
    }
  };

  // ?  Función que construye los tabs
  const buildTabs = (array) => {
    return html`${array.map((item, index) => {
      if (window.location.pathname.includes('lumios')) {
        activeTab = getUrlParams().rate === 'rate1' ? 1 : 2;
      }
      let className =
        index === activeTab - 1
          ? 'ui-state-default ui-corner-top ui-tabs-active'
          : 'ui-state-default ui-corner-top';
      return html`<li class=${className}>
        <a
          class="ui-tabs-anchor"
          id="${item.id}"
          href="#${item.ancle}"
          @click=${onClick}
          @mouseover=${mouseover}
          @mouseout=${mouseout}
        >
          <span
            class="icon-${index}"
            style="${window.location.pathname.includes('lumios')
              ? 'pointer-events: none'
              : ''}"
          ></span
          >${html([item.text])}
        </a>
      </li>`;
    })}`;
  };

  // ? Función que construye el contenido de los tabs
  const buildContainer = () => {
    tabsClasses.forEach((content, index) => {
      if (index === activeTab - 1) {
        content.classList.add('active');
      } else {
        content.classList.remove('active');
      }
    });
  };

  return html`
    <div class=" esios-tab ">
      <div class="row">
        <div class="grid-sm-24">
          <div
            class="mod-tabs ui-tabs ui-widget ui-widget-content ui-corner-all"
          >
            <ul
              class="ui-tabs-nav ui-helper-reset ui-helper-clearfix ui-widget-header ui-corner-all"
            >
              ${buildTabs(tabsContent)}
            </ul>

            ${buildContainer()}
          </div>
        </div>
      </div>
    </div>
  `;
};

export default Tabs;
