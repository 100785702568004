'use strict';

/**
 * @description Dashboard Component
 * @returns {Object} Devuelve la instancia del componente
 */

import i18n from 'i18next';
import { render } from 'lit-html';
import moment from 'moment-timezone';
import { store } from '../../../app.init';
import servicesLogin from '../../../pages/login/login.service';
import { api } from '../../../utils/api-interceptor';
import { Utils } from '../../../utils/utils';
import { componentModal } from '../modal/modal.component.js';
import { templateModalFav } from '../modal/modalFav.template.js';
import { templateToolbar } from './toolbar.template.js';

let componentToolbar = (function () {
  let renderSuccess = false;

  window.onresize = displayWindowSize;
  window.onload = displayWindowSize;
  let buttons = document.getElementsByTagName('button');
  let listElements = document.getElementsByTagName('li');

  function displayWindowSize() {
    let myWidth = window.innerWidth;

    if (myWidth <= 539) {
      Array.from(buttons).forEach((x) => {
        if (x.className == 'esios-toolbar__buttons toolbar-analysis') {
          x.classList.add('hidden');
        }
      });
    } else {
      Array.from(buttons).forEach((x) => {
        if (x.className == 'esios-toolbar__buttons toolbar-analysis hidden') {
          x.classList.remove('hidden');
        }
      });
    }

    if (myWidth <= 768) {
      Array.from(listElements).forEach((x) => {
        if (x.className == 'esios-toolbar__item print') {
          x.classList.add('hidden');
        }
      });
    } else {
      Array.from(listElements).forEach((x) => {
        if (x.className == 'esios-toolbar__item print hidden') {
          x.classList.remove('hidden');
        }
      });
    }
  }

  const linkClick = (link) => {
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onClickJSON = (obToolbar, updateDate, e) => {
    e.preventDefault();
    const { widgetData } = obToolbar;
    const finalJson = [];
    let namedefault;
    const name =
      widgetData.slug === i18n.t('routes.generation-consumption-variation')
        ? Utils.removeAccents(widgetData.name).toLowerCase().replace(' ', '_')
        : widgetData.slug;
    if (obToolbar.calendarData) {
      widgetData.indicators.map((indicator) => {
        let printVals;
        if (indicator.print_values) {
          printVals = [...indicator.print_values];
        }

        printVals.map((el) => {
          finalJson.push({
            event_date: el.event_date || null,
            data_date: el.data_date || null,
            event_type: el.event_type || null,
            geo_id: el.geo_id || null,
            download_links: el.download_links,
          });
        });
      });
    } else if (obToolbar.calculatorData) {
      widgetData.indicators.map((indicator) => {
        let printVals;
        if (indicator.print_values) {
          printVals = [...indicator.print_values];
        }

        printVals.map((el) => {
          finalJson.push({
            zone: el.zone || null,
            tariff: el.tariff || null,
            name: el.name || null,
            start_date: el.start_date || null,
            end_date: el.end_date || null,
            value:
              parseFloat(el.value.replace(',', '.')) &&
              parseFloat(el.value.replace(',', '.')),
          });
        });
      });
    } else {
      if (widgetData.indicators == null) {
        let indicator = widgetData;
        namedefault = indicator.name.replace(/ /g, '_');
        let printVals;
        if (indicator?.print_values) {
          printVals = [...indicator?.print_values];
        }
        printVals?.forEach((el) => {
          const date = updateDate
            ? localStorage.getItem('bread-crumbs-date')
            : moment(el.datetime).tz('Europe/Madrid').format() || '-';

          finalJson.push({
            id: indicator.id || '-',
            name: indicator.name || '-',
            geoname: el.geo_name || '',
            value:
              (widgetData.slug === i18n.t('routes.other-indicators') &&
              el.value < 0
                ? el.value * -1
                : el.value) || 0,
            datetime: date,
          });
        });
      } else {
        widgetData.indicators.map((indicator) => {
          let printVals;
          if (indicator?.print_values) {
            printVals = [...indicator?.print_values];
          }
          printVals?.forEach((el) => {
            const date = updateDate
              ? localStorage.getItem('bread-crumbs-date')
              : moment(el.datetime).tz('Europe/Madrid').format() || '-';

            finalJson.push({
              id: indicator.id || '-',
              name: indicator.name || '-',
              geoname: el.geo_name || '',
              value:
                (widgetData.slug === i18n.t('routes.other-indicators') &&
                el.value < 0
                  ? el.value * -1
                  : el.value) || 0,
              datetime: date,
            });
          });
        });
      }
    }

    let currentDatetime = moment()
      .tz('Europe/Madrid')
      .format('YYYY-MM-DD_HH:mm');
    let storageObj = obToolbar.calendarData ? finalJson.reverse() : finalJson;
    let dataStr =
      'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(storageObj));
    let dlAnchorElem = document.createElement('a');
    dlAnchorElem.setAttribute('href', dataStr);
    dlAnchorElem.setAttribute(
      'download',
      `export_${name ?? namedefault}_${currentDatetime}.json`
    );

    linkClick(dlAnchorElem);
  };

  function JSONToCSVConvertor(
    JSONData,
    ReportTitle,
    ShowLabel,
    type,
    calendarData = false
  ) {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    var CSV = '';
    if (type === 'excel') {
      CSV = 'sep=;' + '\r\n\n';
    }

    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = '';

      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        row += index + ';';
      }

      row = row.slice(0, -1);

      //append Label row with line break
      CSV += row + '\r\n';
    }
    const avoidedValues = ['datetime', 'download_links'];
    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = '';

      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        row +=
          '' + arrData[i][index] + (!avoidedValues.includes(index) ? ';' : '');
      }
      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + '\r\n';
    }

    if (CSV == '') {
      alert('Invalid data');
      return;
    }
    let finalCSV;
    if (type === 'excel') {
      finalCSV = escape(CSV);
    } else {
      finalCSV = CSV;
    }

    var uri =
      `data:text/${type === 'excel' ? 'xls' : 'csv'};charset=utf-8,` + finalCSV;
    var link = document.createElement('a');
    link.href = uri;

    link.style = 'visibility:hidden';

    link.download = ReportTitle + `.${type === 'excel' ? 'xls' : 'csv'}`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const onClickCSV = (obToolbar, updateDate, e) => {
    e.preventDefault();
    const { widgetData, calendarData } = obToolbar;
    const finalJson = [];
    let namedefault;
    const name =
      widgetData.slug === i18n.t('routes.generation-consumption-variation')
        ? Utils.removeAccents(widgetData.name).toLowerCase().replace(' ', '_')
        : widgetData.slug;
    if (calendarData) {
      widgetData.indicators.map((indicator) => {
        let printVals = [...indicator.print_values];
        printVals.map((el) => {
          finalJson.push({
            event_date: el.event_date || '',
            data_date: el.data_date || '',
            event_type: el.event_type || '',
            geo_id: el.geo_id || '',
            download_links: el.download_links || '',
          });
        });
      });
    } else if (obToolbar.calculatorData) {
      widgetData.indicators.map((indicator) => {
        let printVals = [...indicator.print_values];
        printVals.map((el) => {
          finalJson.push({
            zone: el.zone || '',
            tariff: el.tariff || '',
            name: el.name || '',
            start_date: el.start_date || '',
            end_date: el.end_date || '',
            value: el.value ? parseFloat(el.value.replace(',', '.')) : '',
          });
        });
      });
    } else {
      if (widgetData.indicators == null) {
        let indicator = widgetData;
        namedefault = indicator.name.replace(/ /g, '_');
        let printVals;
        if (indicator?.print_values) {
          printVals = [...indicator?.print_values];
        }
        printVals?.forEach((el) => {
          const date = updateDate
            ? localStorage.getItem('bread-crumbs-date')
            : moment(el.datetime).tz('Europe/Madrid').format() || '-';

          finalJson.push({
            id: indicator.id || '-',
            name: indicator.name || '-',
            geoname: el.geo_name || '',
            value:
              (widgetData.slug === i18n.t('routes.other-indicators') &&
              el.value < 0
                ? el.value * -1
                : el.value) || 0,
            datetime: date,
          });
        });
      } else {
        widgetData.indicators.map((indicator) => {
          let printVals;
          if (indicator?.print_values) {
            printVals = [...indicator?.print_values];
          }
          printVals?.map((el) => {
            const date = updateDate
              ? localStorage.getItem('bread-crumbs-date')
              : moment(el.datetime).tz('Europe/Madrid').format() || '-';
            finalJson.push({
              id: indicator.id || '-',
              name: indicator.name || '-',
              geoname: el.geo_name || '',
              value:
                (widgetData.slug === i18n.t('routes.other-indicators') &&
                el.value < 0
                  ? el.value * -1
                  : el.value) || 0,
              datetime: date,
            });
          });
        });
      }
    }
    let itemsFormatted = [];
    if (calendarData) {
      finalJson.forEach((item) => {
        itemsFormatted.push({
          event_date: item.event_date,
          data_date: item.data_date,
          event_type: item.event_type,
          geo_id: item.geo_id,
          download_links: item.download_links,
        });
      });
    } else if (obToolbar.calculatorData) {
      finalJson.map((el) => {
        itemsFormatted.push({
          zone: el.zone,
          tariff: el.tariff,
          name: el.name,
          start_date: el.start_date,
          end_date: el.end_date,
          value: el.value,
        });
      });
    } else {
      finalJson.forEach((item) => {
        itemsFormatted.push({
          id: item.id,
          name: item.name,
          geoname: item.geoname,
          value: item.value,
          datetime: item.datetime,
        });
      });
    }

    let currentDatetime = moment()
      .tz('Europe/Madrid')
      .format('YYYY-MM-DD_HH:mm');

    let fileTitle = `export_${name ?? namedefault}_${currentDatetime}`;
    JSONToCSVConvertor(
      calendarData ? itemsFormatted.reverse() : itemsFormatted,
      fileTitle,
      true,
      'csv',
      calendarData
    );
  };

  const onClickExcel = (obToolbar, updateDate, e) => {
    e.preventDefault();
    const { widgetData, calendarData } = obToolbar;
    const finalJson = [];
    let namedefault;
    const name =
      widgetData.slug === i18n.t('routes.generation-consumption-variation')
        ? Utils.removeAccents(widgetData.name).toLowerCase().replace(' ', '_')
        : widgetData.slug;
    if (calendarData) {
      widgetData.indicators.map((indicator) => {
        let printVals = [...indicator.print_values];
        printVals.map((el) => {
          finalJson.push({
            event_date: el.event_date || '',
            data_date: el.data_date || '',
            event_type: el.event_type || '',
            geo_id: el.geo_id || '',
            download_links: el.download_links || '',
          });
        });
      });
    } else if (obToolbar.calculatorData) {
      widgetData.indicators.map((indicator) => {
        let printVals = [...indicator.print_values];
        printVals.map((el) => {
          finalJson.push({
            zone: el.zone || '',
            tariff: el.tariff || '',
            name: el.name || '',
            start_date: el.start_date || '',
            end_date: el.end_date || '',
            value: el.value ? el.value : '',
          });
        });
      });
    } else {
      if (widgetData.indicators == null) {
        let indicator = widgetData;
        namedefault = indicator.name.replace(/ /g, '_');
        let printVals;
        if (indicator?.print_values) {
          printVals = [...indicator?.print_values];
        }
        printVals?.forEach((el) => {
          const date = updateDate
            ? localStorage.getItem('bread-crumbs-date')
            : moment(el.datetime).tz('Europe/Madrid').format() || '-';

          finalJson.push({
            id: indicator.id || '-',
            name: indicator.name || '-',
            geoname: el.geo_name || '',
            value:
              (widgetData.slug === i18n.t('routes.other-indicators') &&
              el.value < 0
                ? el.value * -1
                : el.value) || 0,
            datetime: date,
          });
        });
      } else {
        widgetData.indicators.map((indicator) => {
          let printVals;
          if (indicator?.print_values) {
            printVals = [...indicator?.print_values];
          }
          printVals?.map((el) => {
            const date = updateDate
              ? localStorage.getItem('bread-crumbs-date')
              : moment(el.datetime).tz('Europe/Madrid').format() || '-';
            finalJson.push({
              id: indicator.id || '-',
              name: indicator.name || '-',
              geoname: el.geo_name || '',
              value:
                (widgetData.slug === i18n.t('routes.other-indicators') &&
                el.value < 0
                  ? el.value * -1
                  : el.value) || 0,
              datetime: date,
            });
          });
        });
      }
    }
    let itemsFormatted = [];
    if (calendarData) {
      finalJson.forEach((item) => {
        itemsFormatted.push({
          event_date: item.event_date,
          data_date: item.data_date,
          event_type: item.event_type,
          geo_id: item.geo_id,
          download_links: item.download_links,
        });
      });
    } else if (obToolbar.calculatorData) {
      finalJson.forEach((item) => {
        itemsFormatted.push({
          zone: item.zone,
          tariff: item.tariff,
          name: item.name,
          start_date: item.start_date,
          end_date: item.end_date,
          value: item.value,
        });
      });
    } else {
      finalJson.forEach((item) => {
        itemsFormatted.push({
          id: item.id,
          name: item.name,
          geoname: item.geoname,
          value:
            Utils.toNumber(item.value, 3, 'comma') === '-'
              ? 0
              : Utils.toNumber(item.value, 3, 'comma'),
          datetime: item.datetime,
        });
      });
    }

    let currentDatetime = moment()
      .tz('Europe/Madrid')
      .format('YYYY-MM-DD_HH:mm');
    let fileTitle = `export_${name ?? namedefault}_${currentDatetime}`;
    JSONToCSVConvertor(
      calendarData ? itemsFormatted.reverse() : itemsFormatted,
      fileTitle,
      true,
      'excel',
      calendarData
    );
  };

  let renderComponent = (obToolbar, div = '.esios-toolbar', updateDate) => {
    //Si sólo se ha enviado objeto con id (renderizamos el toolbar con los 4 botones)
    if (obToolbar.length == 1) {
      obToolbar = {
        id: obToolbar.id,
        slug: 'empty',
        info: true,
        share: true,
        favorite: true,
        analysis: true,
        url: obToolbar.url(),
      };
    }

    try {
      // Render the template to the document
      render(
        // Define a template
        templateToolbar(
          obToolbar,
          { onClickCSV, onClickExcel, onClickJSON },
          updateDate
        ),
        document.querySelector(div)
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = async (obToolbar) => {
    displayWindowSize();
    const state = store.getState();
    let shareButton = document.getElementById(
      'esios-toolbar__buttons' + obToolbar.id
    );

    shareButton &&
      shareButton.addEventListener('click', function () {
        let list = document.getElementById(
          'esios-toolbar__list' + obToolbar.id
        );
        let pathname = window.location.pathname;

        if (pathname.split('/')[2] === i18n.t('routes.web_calculator')) {
          return document.getElementById('buttonShareModal1').click();
        }
        list.classList.remove('hidden');
      });

    document.addEventListener('click', function (e) {
      let list = document.getElementById('esios-toolbar__list' + obToolbar.id);
      if (e.target !== shareButton) {
        list?.classList.add('hidden');
      }
    });

    if (obToolbar.favId) {
      let token = servicesLogin().validateSession();

      let favorite;
      let initFav = async () => {
        favorite = '';
        if (token) {
          let { favourites } = await api(
            'favourites?authentication_token=' +
              token +
              '&rdn=' +
              Math.floor(Math.random() * 1000)
          );
          let uriToolbar = '/' + obToolbar.favId;
          if (obToolbar.uri) {
            uriToolbar = obToolbar.uri;
          }
          favorite = favourites.find(({ uri }) => uri == uriToolbar);
          favorite ? selected(obToolbar.id) : notSelected(obToolbar.id);
          favorite
            ? removeDraw(favorite.id, obToolbar.id, token)
            : draw(obToolbar.favId, obToolbar.id, token, uriToolbar);
        } else {
          document
            .getElementById('buttonFav' + obToolbar.id)
            .addEventListener('click', () => {
              localStorage.setItem('addToFav', obToolbar.favId);
              if (obToolbar.uri) {
                localStorage.setItem('addToFav', obToolbar.uri);
              }
              setTimeout(() => {
                window.location.pathname = '/es/mi-esios';
              }, 1000);
            });
        }
      };

      initFav();

      let reset = (idFav) => {
        let container = document.getElementById('buttonFavContainer' + idFav);
        let btn = document.getElementById('buttonFav' + idFav);
        let btnClone = btn.cloneNode(true);
        btn.remove();
        container.appendChild(btnClone);
      };

      let notSelected = (idToolbar) =>
        (document.getElementById('buttonFav' + idToolbar).className =
          'esios-toolbar__buttons toolbar-favorite');

      let selected = (idToolbar) => {
        document.getElementById('buttonFav' + idToolbar).className =
          'esios-toolbar__buttons toolbar-favorite-selected';
        document.querySelector(
          `#buttonFavContainer${idToolbar} .title-fav`
        ).innerHTML = `${i18n
          .t(selected ? 'remove_from_fav' : 'add_to_fav')
          .toUpperCase()}`;
      };

      let draw = async (idFav, idToolbar, token, uriToolbar) => {
        let fav = uriToolbar ? uriToolbar : '/' + idFav;
        document
          .getElementById('buttonFav' + idToolbar)
          .addEventListener('click', () => {
            if (token) {
              try {
                selected(idToolbar);
                reset(idToolbar);
                api('favourites', {
                  method: 'POST',
                  body: JSON.stringify({
                    uri: fav,
                    authentication_token: token,
                    favourite_type: 'widget',
                    order: 0,
                  }),
                }).then(() => {
                  initFav();
                });
              } catch {
                notSelected(idToolbar);
                reset(idToolbar);
              }
            }
          });
      };

      let removeDraw = async (id, idToolbar, token) => {
        let handlerDelete = () => {
          notSelected(idToolbar);
          reset(idToolbar);
          handlerCloseModal();
          try {
            api('favourites/' + id + '?authentication_token=' + token, {
              method: 'DELETE',
            }).then(() => {
              initFav();
              if (obToolbar.myEsios) {
                location.reload(true);
              }
            });
          } catch {
            selected(idToolbar);
            reset(idToolbar);
          }
        };

        let handlerCloseModal = () => {
          let modal = document.getElementById('modal-buttonFav' + idToolbar);
          let body = document.getElementsByTagName('body')[0];
          modal.style.display = 'none';
          body.style.position = 'inherit';
          body.style.height = 'auto';
          body.style.overflow = 'visible';
        };

        componentModal.render(
          templateModalFav(handlerDelete, handlerCloseModal, idToolbar),
          'buttonFav' + idToolbar,
          'modalFav' + idToolbar
        );
      };
    }

    if (obToolbar.textModal) {
      componentModal.render(
        obToolbar.textModal,
        'buttonModal' + obToolbar.id,
        'modal' + obToolbar.id
      );
    }

    if (obToolbar.shareModal) {
      componentModal.render(
        obToolbar.shareModal,
        'buttonShareModal' + obToolbar.id,
        'share-modal' + obToolbar.id
      );
    }
  };

  let init = (obToolbar, div, updateDate) => {
    // Call render component
    renderComponent(obToolbar, div, updateDate);

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent(obToolbar) : '';
  };

  return {
    render: init,
  };
})();

export { componentToolbar };
