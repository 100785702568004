import { html } from 'lit-html';
import i18n from 'i18next';

const MyEsiosWidget7 = () => {
  return html`
    <div
      class="offset-md-1 col-xs-6 mypCosteWidgetView"
      id="mypCosteWidgetView"
    >
      <div class="esios-toolbar col-12">
        <div class="esios-toolbar7"></div>

        <div id="mypCosteWidgetViewSelector"></div>
      </div>
      <h2 class="esios-market-prices__subtitle">
        ${i18n.t('unitary_cost')} €/MWh
      </h2>
      <div id="spinner7" class="spinner-container">
        <div class="spinner"></div>
      </div>
      <div class="row">
        <div id="mypGraphic2" class="chari-chart large col-xs-6">
          <div
            id="mypNoDataRect7"
            class="esios-home__no-data-container_cuadrado hidden"
            style="margin: 130px 20px;"
          >
            ${i18n.t('no-data-available')}
          </div>
        </div>
        <div class="volume-leyends offset-1 col-xs-5"></div>
      </div>

      <div class="col-xs-24 col-sm-24 mt-5">
        <div class="widget-summary table-summary"></div>
      </div>
    </div>
  `;
};

export default MyEsiosWidget7;
