'use strict';

/**
 * @description Tab Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { render } from 'lit-html';
import { store } from '../../app.init.js';
import { templateTab } from './tab.template';

let componentTab = (function () {
  let context = {};
  let handlers = {};
  let renderSuccess = false;

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let renderComponent = async (data, parent) => {
    try {
      // Render the template to the document

      const table = document.querySelector(table);

      render(
        // Define a template
        templateTab(context, handlers, data, parent),
        document.querySelector(parent)
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = (data, parent) => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = (data, parent) => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent(data, parent);

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent(data, parent) : '';
  };

  return {
    render: (data, parent) => init(data, parent),
  };
})();

export { componentTab };
