import i18n from 'i18next';
import { html, render } from 'lit-html';
import moment from 'moment';
import { store } from '../../app.init';
import balearesImage from '../../assets/images/snp-baleares.jpeg';
import canaryImage from '../../assets/images/snp-canarias.jpeg';
import ceutaImage from '../../assets/images/snp-ceuta.jpeg';
import melillaImage from '../../assets/images/snp-melilla.jpeg';
import { componentButtons } from '../../shared/components/buttons/buttons.component';
import { api } from '../../utils/api-interceptor';
import { getUrlParams, setUrlParam } from '../../utils/url';
import TemplateEmbedIndicatorsCalendario from './embed-indicators-calendar.template';
import TemplateEmbedIndicatorsGraph from './embed-indicators-graph.template';
import TemplateEmbedIndicatorsItemList from './embed-indicators-item-list.template';
// IMPORTANT: We should add every widget id to it type in this JSON
import indicatorsList from './embed-indicators-list.json';
import TemplateEmbedIndicatorsMaps from './embed-indicators-maps.template';
import EmbedTitles from './embed-indicators-titles.json';

const ServicesEmbedIndicators = () => {
  let state = store.getState();
  const currentLang = i18n.t('routes.lng');
  const indicatorsType = Object.keys(indicatorsList);
  const { print } = getUrlParams();

  // This will check what type of content will make
  const checkSlug = async (slug) => {
    if (slug === 'balance-programmed-interconections') {
      slug = 38;
    }
    if (slug == 'spot-europa') {
      slug = 40;
    }
    if (slug == 'spot-europa-map') {
      slug = 40;
    }
    if (slug === 'saldo-frontera') {
      slug = 41;
    }
    if (slug == 'hourly-balances-border') {
      slug = 39;
    }

    await api(`widgets/${slug}?locale=${currentLang}`).then((resp) => {
      // Type variable will be filled with the correspondent type, checked by it widgetId

      let widgetId = resp?.widget?.id_widget;
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const dateUrl =
        urlParams.get('date') || moment(new Date()).format('DD-MM-YYYY');

      if (i18n.t('routes.auctions_calendar') === slug) {
        widgetId = 99;
      }
      if (slug == 'demand-or-forecasts' || slug == 'demanda-de-previsiones') {
        widgetId = 544;
      } else if (slug == 'wind-forecast' || slug == 'prevision-eolica') {
        widgetId = 541;
      } else if (
        slug == 'photovoltaic-solar-forecast' ||
        slug == 'prevision-solar-fotovoltaica'
      ) {
        widgetId = 542;
      } else if (
        slug == 'solar-thermal-forecast' ||
        slug == 'prevision-solar-termica'
      ) {
        widgetId = 543;
      } else if (
        slug == 'daily-forecast-d1-demand' ||
        slug == 'prevision-diaria-d1-demanda'
      ) {
        widgetId = 1775;
      } else if (
        slug == 'daily-forecast-d1-wind-plus-photovoltaic' ||
        slug == 'prevision-diaria-d1-eolica-fotovoltaica'
      ) {
        widgetId = 10358;
      } else if (
        slug == 'intraday-forecast-h3-demand' ||
        slug == 'prevision-intradiaria-h3-demanda'
      ) {
        widgetId = 1776;
      } else if (
        slug == 'intraday-forecast-h3-more-photovoltaic-demand' ||
        slug == 'prevision-intradiaria-h3-mas-fotovoltaica-demanda'
      ) {
        widgetId = 10359;
      } else if (
        slug == 'annual-demand-forecast' ||
        slug == 'prevision-demanda-anual'
      ) {
        widgetId = 1774;
      } else if (
        slug == 'snp-forecast-demand' ||
        slug == 'demanda-prevista-snp'
      ) {
        widgetId = 1742;
      } else if (
        slug == 'daily-forecast-peninsular-electricity-demand' ||
        slug == 'prevision-diaria-demanda-electrica-peninsular'
      ) {
        widgetId = 460;
      } else if (
        slug == 'monthly-forecast-peninsular-electricity-demand' ||
        slug == 'prevision-mensual-demanda-electrica-peninsular'
      ) {
        widgetId = 461;
      } else if (
        slug == 'weekly-forecast-peninsular-electricity-demand' ||
        slug == 'prevision-semanal-demanda-electrica-peninsular'
      ) {
        widgetId = 603;
      } else if (
        slug == 'maximum-annual-forecast-peninsular-electricity-demand' ||
        slug == 'prevision-maxima-anual-demanda-electrica-peninsular'
      ) {
        widgetId = 541;
      } else if (
        slug == 'solar-planned-generation' ||
        slug == 'generacion-solar-planificada'
      ) {
        widgetId = 10034;
      } else if (
        slug == 'minimum-annual-forecast-peninsular-electricity-demand' ||
        slug == 'prevision-minima-anual-demanda-electrica-peninsular'
      ) {
        widgetId = 621;
      } else if (
        slug == 'maximum-monthly-forecast-peninsular-electricity-demand' ||
        slug == 'prevision-maxima-mensual-demanda-electrica-peninsular'
      ) {
        widgetId = 604;
      } else if (
        slug == 'residual-demand-forecast' ||
        slug == 'previsión-de-demanda-residual' ||
        slug == 'previsi%C3%B3n-de-demanda-residual'
      ) {
        widgetId = 10249;
      } else if (
        slug == 'minimum-monthly-forecast-peninsular-electricity-demand' ||
        slug == 'prevision-minima-mensual-demanda-electrica-peninsular'
      ) {
        widgetId = 619;
      } else if (
        slug == 'available-generation-margin-maximum-forecast' ||
        slug == 'margen-generacion-disponible-prevision-maxima'
      ) {
        widgetId = 622;
      }

      callWidget(
        widgetId,
        dateUrl
          ? new Date(moment(dateUrl, 'DD / MM / YYYY').format('MM-DD-YYYY'))
          : new Date()
      );

      const setParameter = (key, value) => {
        urlParams.set(key, value);
        history.pushState({}, '', window.location.pathname + '?' + urlParams);
      };

      const renderWidget = (date = $('#datepicker').val()) => {
        document
          .getElementById(`spinner${widgetId}`)
          ?.classList.remove('hidden');
        const svgs = document.querySelectorAll(`#widget${widgetId} svg`);
        if (widgetId != 23 && widgetId != 10) {
          for (let svg of svgs) {
            svg.remove();
          }
          const chariLegends = document.querySelectorAll(
            `#widget${widgetId} .piePotencia`
          );
          for (let chariLegend of chariLegends) {
            chariLegend.remove();
          }
          const summaryLegends = document.querySelectorAll(
            `#widget${widgetId} .widget-summary`
          );
          for (let summaryLegend of summaryLegends) {
            summaryLegend.remove();
          }

          const pieCharts = document.querySelectorAll(`.esios-pie-graph svg`);
          for (let chart of pieCharts) {
            chart.remove();
          }
          const bars = document.querySelectorAll(`svg`);
          const legends = document.querySelectorAll(`.legend`);
          const windowsModal = document.querySelectorAll(`.informativeWindow`);
          for (let windowModal of windowsModal) {
            windowModal.remove();
          }
          for (let bar of bars) {
            bar.remove();
          }
          for (let legend of legends) {
            legend.remove();
          }
          const widgetCharts = document.querySelectorAll('.widget-chart svg');
          for (let charts of widgetCharts) {
            charts.remove();
          }
        }

        setUrlParam(
          'date',
          moment(date, 'DD / MM / YYYY').format('DD-MM-YYYY')
        );

        callWidget(
          widgetId,
          new Date(moment(date, 'DD / MM / YYYY').format('MM-DD-YYYY'))
        );
      };

      $(document).on('change', '#datepicker', () => {
        renderWidget();
      });

      document.getElementById('datepicker')?.addEventListener('click', () => {
        setTimeout(
          () =>
            (document.getElementById('ui-datepicker-div').style.position =
              'absolute'),
          200
        );

        document
          .getElementsByClassName('ui-datepicker-current')[0]
          .addEventListener('click', () => {
            renderWidget(moment().format('DD-MM-YYYY'));
          });
      });
    });
    setTimeout(() => {
      if (document.getElementById('button-fisicas')) {
        document.getElementById('button-fisicas').classList.remove('hidden');
      }
    }, 1000);
  };

  const getTypeByWidget = (widgetId) => {
    if (99 === widgetId) {
      return 'calendar';
    }
    let type = '';
    for (let i = 0; i < indicatorsType.length; i++) {
      const checkType = indicatorsType[i];
      if (indicatorsList[checkType].find((i) => i === widgetId)) {
        type = checkType;
      }
    }
    return type;
  };

  const callWidget = (widgetId, date) => {
    let dateTime = moment(date, 'DD-MM-YYYYTHH:mm').format(
      'YYYY-MM-DDTHH:mm:ss'
    );
    if (widgetId == 8 || widgetId === 20) {
      dateTime = moment(date, 'DD-MM-YYYYTHH:mm')
        .hour('00')
        .minutes('00')
        .format('YYYY-MM-DDTHH:mm:ss');
    }
    if (
      widgetId === 4 ||
      widgetId === 6 ||
      widgetId === 7 ||
      widgetId === 22 ||
      widgetId == 23 ||
      widgetId === 24 ||
      widgetId === 25 ||
      widgetId === 26
    ) {
      let currentHour = moment().tz('Europe/Madrid').format('HH');
      let currentMinutes = '00';
      let currentMinutes10in10 = `${Math.trunc(
        moment().tz('Europe/Madrid').format('mm') / 10
      )}0`;

      if (widgetId == 22 || widgetId == 23 || widgetId == 25) {
        currentMinutes = currentMinutes10in10;
      }

      if (moment().format('DD-MM-YYYY') != moment(date).format('DD-MM-YYYY')) {
        currentHour = '00';
        currentMinutes = '00';
      }

      dateTime = moment(date, 'DD-MM-YYYYTHH:mm')
        .hour(currentHour)
        .minutes(currentMinutes)
        .format('YYYY-MM-DDTHH:mm:ss');
    }
    const type = getTypeByWidget(widgetId);

    const getTitleID = (widgetId) => {
      // 45map
      let returnVal = widgetId;
      if (state.exampleReducer.routeParams.slug == 'spot-europa-map') {
        returnVal = `${widgetId}map`;
      }

      return returnVal;
    };

    switch (type) {
      case 'maps':
        api(
          `widgets/${widgetId}?datetime=${dateTime}&locale=${currentLang}`
        ).then((resp) => {
          render(
            html`
              <div id="spinner${widgetId}" class="spinner-container">
                <div class="spinner"></div>
              </div>
            `,
            document.querySelector('#embed-container')
          );
          const indicatorsCalls = resp.widget.indicators.map((i) => api(i.url));
          Promise.all(indicatorsCalls).then((indicators) => {
            TemplateEmbedIndicatorsMaps(mapTypeParse(indicators), getImage());
          });
        });
        break;
      case 'calendar':
        TemplateEmbedIndicatorsCalendario(widgetId, EmbedTitles[widgetId]);
        break;
      case 'graph':
        TemplateEmbedIndicatorsGraph(
          widgetId,
          EmbedTitles[getTitleID(widgetId)],
          dateTime
        );
        break;
      case 'list':
        TemplateEmbedIndicatorsItemList(
          widgetId,
          EmbedTitles[widgetId],
          dateTime
        );
        break;
      default:
        break;
    }
    const buttonsArray = [{ title: 'IMPRIMIR', action: 'print', url: '#' }];
    componentButtons.render(buttonsArray, '#button-fisicas');
    if (print) {
      document
        .querySelector('.esios-embed-indicators')
        .classList.add('print-embed');
    }
  };

  const getImage = () => {
    const map = window.location.pathname.split('/')[3].split('?')[0];
    switch (map) {
      case 'baleares':
      case 'balearic-islands':
        return balearesImage;
      case 'canarias':
      case 'canary-islands':
        return canaryImage;
      case 'ceuta':
        return ceutaImage;
      case 'melilla':
        return melillaImage;
      default:
        break;
    }
  };

  const mapTypeParse = (data) => {
    const dataMap = data.map((ele) => ({
      value: ele.indicator.values[0]?.value,
      step: ele.indicator?.step_type,
      id: ele.indicator?.id,
      short_name: ele.indicator?.short_name,
      name: ele.indicator?.name,
      geo_id: ele.indicator.geos[0]?.geo_id,
      geo_name: ele.indicator.geos[0]?.geo_name,
      datetime: ele.indicator.values[0]?.datetime,
    }));
    return dataMap;
  };

  return {
    checkSlug: (slug) => checkSlug(slug),
  };
};

export default ServicesEmbedIndicators;
