'use strict';

/**
 * @description PrevisionesDRenovable Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { store } from '../../app.init.js';
import { servicesprevisionesDRenovable } from './previsionesDRenovable.services';
import { templateprevisionesDRenovable } from './previsionesDRenovable.template.js';
import i18n from 'i18next';

let componentprevisionesDRenovable = (function () {
  let context = {};
  let handlers = {};
  let renderSuccess = false;

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    document.title = `${i18n.t('demand_ren_g_f')} | ${i18n.t('page_subtitle')}`;
    context.name = state.exampleReducer.name;
  };

  let renderComponent = async () => {
    const state = store.getState();
    try {
      // Render the template to the document

      let previData = await servicesprevisionesDRenovable.init(
        state.exampleReducer.currentLang
      );
      templateprevisionesDRenovable(context, handlers, previData);

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
    document
      .querySelector('.esios-footer')
      .classList.add('esios-footer--previsiones');
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentprevisionesDRenovable };
