'use strict';

/**
 * @description Help Component
 * @returns {Object} Devuelve la instancia del componente
 */
import i18n from 'i18next';
import { store } from '../../app.init.js';
import { API_URL } from '../../environment.json';
import { componentButtons } from '../../shared/components/buttons/buttons.component.js';
import { templateShareModal } from '../../shared/components/modal/modalShare.template.js';
import { componentSelects } from '../../shared/components/selects/selects.component.js';
import { componentToolbar } from '../../shared/components/toolbar/toolbar.component.js';
import { getUrlParams } from '../../utils/url.js';
import { Utils } from '../../utils/utils';
import { createTable } from '../embed-indicators/embed-indicators-calendar.template.js';
import { servicesCalendario } from './calendario.services.js';
import { templateCalendario } from './calendario.template.js';

let componentCalendario = (function () {
  let context = {};
  let renderSuccess = false;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let period = ['mensual', 'trimestral', 'anual'];

  const selectGroupYearStart = {
    id: 'select_groupYearStart',
    currentLabel: '',
    currentValue: '',
    label: '',
    options: [],
    columns: 1,
    onlySelect: true,
    changeTime: (value) => changeTimeFromSelector(value),
  };

  let ruta = window.location.pathname.split('/').includes('embed');
  let path = window.location.pathname.split('/').includes('insertado');

  const changeTimeFromSelector = (value) => {
    const { id, newValue } = value;

    if (selectGroupYearStart.id === id) {
      selectGroupYearStart.options.map((el) => {
        if (el.value === newValue) {
          selectGroupYearStart.currentLabel = el.label;
          selectGroupYearStart.currentValue = el.value;
          componentSelects.render(selectGroupYearStart, '#selectYear');
          const state = store.getState();
          setParameter('year', el.value);
          eventSelect(el.value, state);
        }
      });
      return;
    }
  };
  const setParameter = (key, value) => {
    urlParams.set(key, value);
    history.pushState({}, '', window.location.pathname + '?' + urlParams);
  };
  //Filtra todos los eventos del calendario
  let getAllEventsDates = (calendarData) => {
    let eventDates = servicesCalendario.getEventsDefault();
    for (let date of calendarData) {
      switch (date.event_type) {
        case 'Celebración subasta mensual':
          eventDates.monthlyAuction.push(date);
          break;
        case 'Celebración subasta mensual POR':
          eventDates.monthlyAuctionPor.push(date);
          break;
        case 'Publicación especificación de subasta mensual':
          eventDates.monthlySpec.push(date);
          break;
        case 'Publicación especificación de subasta mensual POR':
          eventDates.monthlySpecPor.push(date);
          break;
        case 'Celebración subasta trim POR':
          eventDates.trimAuction.push(date);
          break;
        case 'Publicación especificación de subasta trim POR':
          eventDates.trimSpec.push(date);
          break;
        case 'Celebración subasta anual':
          eventDates.anualAuction.push(date);
          break;
        case 'Celebración subasta anual POR':
          eventDates.anualAuctionPor.push(date);
          break;
        case 'Publicación especificación de subasta anual':
          eventDates.anualSpec.push(date);
          break;
        case 'Publicación especificación de subasta anual POR':
          eventDates.anualSpecPor.push(date);
          break;
        case 'Festivo España':
          eventDates.notWorking.push(date);
          eventDates.notWorkingPor.push(date);
          break;
        case 'Festivo Francia':
          eventDates.notWorking.push(date);
          break;
        case 'Festivo Portugal':
          eventDates.notWorkingPor.push(date);
          break;
      }
    }
    return eventDates;
  };

  //Itera sobre los días de la API
  let searchDays = (dayCalendar, daysApi, monthCounter, daysDatepicker) => {
    //Recuperamos los días y los recorremos
    let eventDays = [];
    let daysInfo = [];
    for (let dayApi of daysApi) {
      let day = new Date(dayApi.event_date).getDate();
      let month = new Date(dayApi.event_date).getMonth();

      //Comprobamos que coinciden día y mes del datepicker con los días de la API
      if (day.toString() === dayCalendar && month === monthCounter) {
        eventDays.push(daysDatepicker[dayCalendar - 1]);
        daysInfo.push(dayApi);
      }
    }
    return [eventDays, daysInfo];
  };

  //Creamos el texto del tooltip
  let createTooltipText = (date, event, periodEvent, quarterText) => {
    let monthsName = [
      i18n.t('january'),
      i18n.t('february'),
      i18n.t('march'),
      i18n.t('april'),
      i18n.t('ma'),
      i18n.t('june'),
      i18n.t('july'),
      i18n.t('august'),
      i18n.t('september'),
      i18n.t('october'),
      i18n.t('november'),
      i18n.t('december'),
    ];
    let dateAuction = new Date(date);

    let monthName, yearAuction;
    if (dateAuction.getMonth() + 1 == 12) {
      let { year } = getUrlParams();
      monthName = monthsName[0];
      yearAuction = year ? year : new Date().getFullYear();
    } else {
      monthName = monthsName[dateAuction.getMonth() + 1];
      yearAuction = dateAuction.getFullYear();
    }
    if (periodEvent === 'mensual') {
      return `${event} ${monthName} ${yearAuction}`;
    } else if (periodEvent === 'trimestral') {
      return `${event} ${quarterText}`;
    } else {
      return `${event} ${yearAuction}`;
    }
  };

  //Itera sobre los días no laborables de la API
  let searchNotWorkingDays = (
    dayCalendar,
    daysApi,
    monthCounter,
    daysDatepicker
  ) => {
    //Recuperamos los días y los recorremos
    let eventDays = [];
    let daysInfo = [];
    for (let dayApi of daysApi) {
      let day = new Date(dayApi.event_date).getUTCDate();
      let month = new Date(dayApi.event_date).getUTCMonth();

      //Comprobamos que coinciden día y mes del datepicker con los días de la API
      if (day.toString() === dayCalendar && month === monthCounter) {
        eventDays.push(daysDatepicker[dayCalendar - 1]);
        daysInfo.push(dayApi);
      }
    }
    return [eventDays, daysInfo];
  };

  //Creación tootltip
  let createTooltip = (parent, element, day, text, country) => {
    let tooltip = document.createElement('div');
    let downloads = day.download_links;
    tooltip.classList.add('esios-tooltip');
    let title = document.createElement('div');
    title.classList.add('esios-tooltip__title');
    title.innerText = text;
    let content = document.createElement('div');
    content.classList.add('esios-tooltip__content');
    if (downloads && downloads.length > 0) {
      for (let link in downloads) {
        let linkText;
        if (downloads.length === 2) {
          if (link === '0') {
            linkText = i18n.t('download') + ' ' + i18n.t('ES') + '-' + country;
          } else {
            linkText = i18n.t('download') + ' ' + country + '-' + i18n.t('ES');
          }
        } else {
          linkText = i18n.t('download');
        }
        let anchor = document.createElement('a');
        anchor.classList.add('esios-tooltip__link');
        anchor.setAttribute('href', API_URL + downloads[link]);
        anchor.innerText = linkText;
        content.appendChild(anchor);
      }
    }
    tooltip.appendChild(title);
    if (content.innerHTML !== '') {
      tooltip.appendChild(content);
    }
    parent.insertBefore(tooltip, element);
  };

  //Averigua el trimestre
  let getTrimester = (date) => {
    let trimester;
    switch (new Date(date).getMonth()) {
      case 11:
        trimester = 1;
        break;
      case 2:
        trimester = 2;
        break;
      case 5:
        trimester = 3;
        break;
      case 8:
        trimester = 4;
        break;
      default:
        break;
    }
    return trimester;
  };
  //quitar campos de info no necesarios si el texto se setea fuera
  let setTooltipTest = (info) => {
    info.day.classList.add(info.class);
    let text = info.text;
    createTooltip(info.parent, info.day, info.dayApi, text, info.country);
  };

  let setTooltip = (info) => {
    //Añadimos la clase al calendario
    info.day.classList.add(info.class);
    //Creamos el texto para el tooltip
    let text = createTooltipText(
      info.date,
      info.text,
      info.period,
      info.quarterText
    );
    //Creamos el tooltip
    createTooltip(info.parent, info.day, info.dayApi, text, info.country);
  };

  //Función para crear el calendario
  let setCalendar = (year, anchor) => {
    $(anchor).datepicker({
      firstDay: i18n.t('routes.lng') === 'en' ? 0 : 1,
      numberOfMonths: 12,
      monthNames: [
        i18n.t('january'),
        i18n.t('february'),
        i18n.t('march'),
        i18n.t('april'),
        i18n.t('ma'),
        i18n.t('june'),
        i18n.t('july'),
        i18n.t('august'),
        i18n.t('september'),
        i18n.t('october'),
        i18n.t('november'),
        i18n.t('december'),
      ],
      monthNamesShort: [
        i18n.t('jan'),
        i18n.t('feb'),
        i18n.t('mar'),
        i18n.t('apr'),
        i18n.t('may'),
        i18n.t('june'),
        i18n.t('july'),
        i18n.t('aug'),
        i18n.t('sep'),
        i18n.t('oct'),
        i18n.t('nov'),
        i18n.t('dec'),
      ],
      dayNames: [
        i18n.t('sunday'),
        i18n.t('monday'),
        i18n.t('tuesday'),
        i18n.t('wednesday'),
        i18n.t('thursday'),
        i18n.t('friday'),
        i18n.t('saturday'),
      ],
      dayNamesShort: [
        i18n.t('sun'),
        i18n.t('mon'),
        i18n.t('tue'),
        i18n.t('wed'),
        i18n.t('thu'),
        i18n.t('fri'),
        i18n.t('sat'),
      ],
      dayNamesMin: [
        i18n.t('S'),
        i18n.t('M'),
        i18n.t('T'),
        i18n.t('W'),
        i18n.t('T'),
        i18n.t('F'),
        i18n.t('S'),
      ],
      minDate: new Date(year - 1, 11, 1),
      maxDate: new Date(year, 10, 30),
      defaultDate: new Date(year - 1, 11, 1),
      hideIfNoPrevNext: true,
      beforeShowDay: function () {
        return [false];
      },
    });
  };

  //Marca los días con eventos en el calendario
  let setEventsDaysCalendar = (calendarData) => {
    let calendars = document.querySelectorAll('.hasDatepicker');
    if (calendars) {
      for (let calendar of calendars) {
        //Recorremos los meses del datepicker
        let monthsDatepicker = calendar.querySelectorAll(
          '.ui-datepicker-group'
        );
        let monthCounter = 11;

        for (let month of monthsDatepicker) {
          let daysDatepicker = month.querySelectorAll('.ui-state-default');

          //Recorremos los días de cada mes del datepicker
          for (let dayPicker in daysDatepicker) {
            let notWorkingDays;
            let monthlyAuction;
            let monthlySpec;
            let trimAuction;
            let trimSpec;
            let annuallyAuction;
            let annuallySpec;
            let country;
            let eventDates = getAllEventsDates(calendarData);

            if (calendar.matches('#auctions-calendar')) {
              country = i18n.t('FR');
              notWorkingDays = eventDates.notWorking;
              monthlyAuction = eventDates.monthlyAuction;
              monthlySpec = eventDates.monthlySpec;
              annuallyAuction = eventDates.anualAuction;
              annuallySpec = eventDates.anualSpec;
            } else if (calendar.matches('#auctions-calendar-por')) {
              country = i18n.t('PT');
              notWorkingDays = eventDates.notWorkingPor;
              monthlyAuction = eventDates.monthlyAuctionPor;
              monthlySpec = eventDates.monthlySpecPor;
              trimAuction = eventDates.trimAuction;
              trimSpec = eventDates.trimSpec;
              annuallyAuction = eventDates.anualAuctionPor;
              annuallySpec = eventDates.anualSpecPor;
            }

            //Marcamos los días inhábiles
            let notWorking = searchNotWorkingDays(
              dayPicker,
              notWorkingDays,
              monthCounter,
              daysDatepicker
            );
            if (notWorking.length > 0) {
              for (let day of notWorking[0]) {
                day.classList.add('not-working');
              }
            }
            //Marcamos las subastas mensuales
            let monthlyAuctions = searchDays(
              dayPicker,
              monthlyAuction,
              monthCounter,
              daysDatepicker
            );
            if (monthlyAuctions.length > 0) {
              let index = 0;
              for (let day of monthlyAuctions[0]) {
                let info = {
                  day: day,
                  class: 'monthy-auction',
                  date: monthlyAuctions[1][0].event_date,
                  text: i18n.t('month_auction'),
                  period: period[0],
                  parent: day.parentNode,
                  dayApi: monthlyAuctions[1][index],
                  country: country,
                };
                setTooltip(info);
                let infoTest = {};
                index++;
              }
            }
            //Marcamos las especificaciones de las subastas mensuales
            let monthSpec = searchDays(
              dayPicker,
              monthlySpec,
              monthCounter,
              daysDatepicker
            );
            if (monthSpec.length > 0) {
              let index = 0;
              for (let day of monthSpec[0]) {
                let info = {
                  day: day,
                  class: 'monthly-spec',
                  date: monthSpec[1][0].event_date,
                  text: i18n.t('month_specifications'),
                  period: period[0],
                  parent: day.parentNode,
                  dayApi: monthSpec[1][index],
                  country: country,
                };
                setTooltip(info);
                index++;
              }
            }
            //Marcamos las subastas trimestrales
            if (trimAuction) {
              let trimestralAuction = searchDays(
                dayPicker,
                trimAuction,
                monthCounter,
                daysDatepicker
              );
              let trimester;
              if (trimestralAuction.length > 0) {
                let index = 0;
                for (let day of trimestralAuction[0]) {
                  trimester = getTrimester(trimestralAuction[1][0].event_date);
                  let info = {
                    day: day,
                    class: 'trim-auction',
                    date: trimestralAuction[1][0].event_date,
                    text: i18n.t('quarter_auction'),
                    period: period[1],
                    parent: day.parentNode,
                    dayApi: trimestralAuction[1][index],
                    country: country,
                    quarterText: ' ' + i18n.t('quarter') + ' ' + trimester,
                  };
                  setTooltip(info);
                  index++;
                }
              }
            }
            //Marcamos las especificaciones de las subastas trimestrales
            if (trimSpec) {
              let trimestralSpec = searchDays(
                dayPicker,
                trimSpec,
                monthCounter,
                daysDatepicker
              );
              let trimester;
              if (trimestralSpec.length > 0) {
                let index = 0;
                for (let day of trimestralSpec[0]) {
                  trimester = getTrimester(trimestralSpec[1][0].event_date);
                  let info = {
                    day: day,
                    class: 'trim-spec',
                    date: trimestralSpec[1][0].event_date,
                    text: i18n.t('quarter_specifications'),
                    period: period[1],
                    parent: day.parentNode,
                    dayApi: trimestralSpec[1][index],
                    country: country,
                    quarterText: ' ' + i18n.t('quarter') + ' ' + trimester,
                  };
                  setTooltip(info);
                  index++;
                }
              }
            }
            //Marcamos las subastas anuales
            let anualAuction = searchDays(
              dayPicker,
              annuallyAuction,
              monthCounter,
              daysDatepicker
            );
            if (annuallyAuction.length > 0) {
              let index = 0;
              for (let day of anualAuction[0]) {
                let info = {
                  day: day,
                  class: 'anual-auction',
                  date: anualAuction[1][0].event_date,
                  text: i18n.t('year_auction'),
                  period: period[2],
                  parent: day.parentNode,
                  dayApi: anualAuction[1][index],
                  country: country,
                };
                setTooltip(info);
                index++;
              }
            }
            //Marcamos las especificaciones de las subastas anuales
            let anualSpec = searchDays(
              dayPicker,
              annuallySpec,
              monthCounter,
              daysDatepicker
            );
            if (anualSpec.length > 0) {
              let index = 0;
              for (let day of anualSpec[0]) {
                let info = {
                  day: day,
                  class: 'anual-spec',
                  date: anualSpec[1][0].event_date,
                  text: i18n.t('year_specifications'),
                  period: period[2],
                  parent: day.parentNode,
                  dayApi: anualSpec[1][index],
                  country: country,
                };
                setTooltip(info);
                index++;
              }
            }
          }
          if (monthCounter === 11) {
            monthCounter = 0;
          } else {
            monthCounter += 1;
          }
        }
      }
    }
  };

  //Calendario responsive
  let getAllEvents = (calendarData, year) => {
    let orderedData = calendarData.reverse();
    let months = [];
    //Separamos los eventos por meses
    for (let i = 0; i <= 11; i++) {
      let month = [];
      for (let day of orderedData) {
        let monthEvent = new Date(day.event_date).getMonth();
        if (monthEvent === i) {
          month.push(day);
        }
      }
      if (i === 11) {
        months.unshift(month);
      } else {
        months.push(month);
      }
    }
    createAllTables(months, year);
  };

  //Creamos una tabla por cada mes
  let createAllTables = (months, year) => {
    let anchor = document.getElementById('auctionsCalendarMobile');
    //Borramos el calendario si lo hubiera
    if (anchor.childNodes.length > 0) {
      anchor.textContent = '';
    }
    for (let month in months) {
      let monthNames = [
        i18n.t('december'),
        i18n.t('january'),
        i18n.t('february'),
        i18n.t('march'),
        i18n.t('april'),
        i18n.t('ma'),
        i18n.t('june'),
        i18n.t('july'),
        i18n.t('august'),
        i18n.t('september'),
        i18n.t('october'),
        i18n.t('november'),
      ];
      let currentMonth = months[month];
      createTable(monthNames[month], year, currentMonth);
    }
  };

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    document.title = `${i18n.t('auctions_calendar')} | ${i18n.t(
      'page_subtitle'
    )}`;
    context.name = state.exampleReducer.name;

    if (urlParams.get('year')) {
      selectGroupYearStart.currentLabel = urlParams.get('year');
      selectGroupYearStart.currentValue = urlParams.get('year');
    }
  };

  //Creacion del select del breadcrumbs con los años de las subastas
  let selectBreadcrumbs = async (state) => {
    // let select = document.getElementById('selectUnit');
    let { meta } = await servicesCalendario.getAuctionYears();

    let thisYears = [];
    let filteredYears = meta.years.filter((el, index) => {
      if (index < meta.years.length - 1) {
        return el;
      }
    });
    filteredYears.map((year) => {
      thisYears.unshift({
        label: year,
        value: year,
      });
    });

    const { label, value } = thisYears[thisYears.length - 1];
    let urlValue;
    if (urlParams.get('year')) {
      urlValue = urlParams.get('year');
    }

    selectGroupYearStart.currentValue = urlValue ? urlValue : value;
    selectGroupYearStart.currentLabel = urlValue ? urlValue : label;
    selectGroupYearStart.options = thisYears;

    selectGroupYearStart.options = thisYears;
    eventSelect(selectGroupYearStart.currentValue, state);
    return selectGroupYearStart;
  };
  let objToolbar = {
    id: 1,
    slug: `${i18n.t('routes.auctions_calendar')}`,
    share: true,
  };
  let setInfoModal = (id, data, html, updateShareModal = 'all') => {
    objToolbar.shareModal = html;
    objToolbar.slug = i18n.t('routes.auctions_calendar');
    objToolbar.widgetData = {
      slug: `${i18n.t('routes.auctions_calendar')}`,
      name: 'calendario de subastas',
      indicators: [
        {
          alias: i18n.t('routes.auctions_calendar'),
          name: i18n.t('routes.auctions_calendar'),
          short_name: i18n.t('routes.auctions_calendar'),
          print_values: data,
          values: data,
        },
      ],
    };
    objToolbar.calendarData = true;
    componentToolbar.render(objToolbar, '.esios-toolbar');
  };

  //Evento para cambiar el año desde el select del breadcrumbs
  let eventSelect = async (valor, state) => {
    document.getElementById('calendar-container').classList.add('hidden');
    document.getElementById('calendarspinner').classList.remove('hidden');
    let calendars = await servicesCalendario.getCalendars(
      valor,
      state.exampleReducer.currentLang
    );
    let shareData = window.location.search;

    $('#auctions-calendar').datepicker('destroy');
    $('#auctions-calendar-por').datepicker('destroy');
    setCalendar(valor, '#auctions-calendar');
    setCalendar(valor, '#auctions-calendar-por');
    setEventsDaysCalendar(calendars);
    getAllEvents(calendars, valor);
    setInfoModal(
      1,
      calendars,
      templateShareModal('auctions_calendar', shareData, false, {
        width: '100%',
        height: '1196',
      }),
      'all'
    );
    document.getElementById('calendarspinner').classList.add('hidden');
    document.getElementById('calendar-container').classList.remove('hidden');
  };

  let renderComponent = async () => {
    const state = store.getState();
    try {
      templateCalendario();
      document.getElementById('calendar-container').classList.add('hidden');
      // Render the template to the document
      let currentYear = urlParams.get('year')
        ? urlParams.get('year')
        : new Date().getFullYear();
      let calData = await servicesCalendario.getCalendars(
        currentYear,
        state.exampleReducer.currentLang
      );
      componentButtons.render(
        [
          {
            title: i18n.t('print'),
            action: 'print',
            url: '#',
          },
        ],
        '#print-button'
      );

      $(function () {
        //Calendario Francia-Espana
        setCalendar(currentYear, '#auctions-calendar');

        //Calendario Portugal-Espana
        setCalendar(currentYear, '#auctions-calendar-por');
        setEventsDaysCalendar(calData);
      });

      let select = await selectBreadcrumbs(state);

      //Creación calendario responsive
      getAllEvents(calData, select.value);

      // llamada al componente Toolbar

      setInfoModal(
        1,
        calData,
        templateShareModal('auctions_calendar', window.location.search, {
          width: '100%',
          height: '1196',
        }),
        'all'
      );
      componentSelects.render(selectGroupYearStart, '#selectYear');
      componentSelects.render(selectGroupYearStart, '#select-year-print');

      if (ruta || path) {
        document.querySelector('.esios-layout').classList.add('print-page');
        document.querySelector('.esios-calendar').style.cssText = `
        width: 18cm;
        margin-top: 200px;
        margin-bottom: 50px;`;
        document.querySelector('.title-for-print').style.cssText = `
        display: flex; 
        align-items: center;
        justify-content: space-between;
      `;

        let selectConfig = {
          hide: [
            '.esios-footer',
            '.esios-header',
            '#esios-layout-banner',
            '.esios-layout__footer-image',
            '.esios-breadcrumbs-nav__wrapper',
            '.esios-layout__breadcrumbs__wrapper',
            '.esios-toolbar',
          ],
          show: [],
        };
        Utils.changeStyles(selectConfig);
      } else {
        let selectConfig = {
          hide: ['#print-button', '#select-year-print'],
          show: [],
        };
        Utils.changeStyles(selectConfig);
      }
      renderSuccess = true;
      document.getElementById('calendarspinner').classList.add('hidden');
      document.getElementById('calendar-container').classList.remove('hidden');
    } catch (error) {
      renderSuccess = false;
    }

    document
      .querySelector('.esios-layout__breadcrumbs__select')
      .classList.add('esios-layout__breadcrumbs__select--csubastas');
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentCalendario };
