import i18next from 'i18next';
import { html } from 'lit-html';

const TemplateEmbedIndicatorsInbalances = () => {
  return html`
    <div id="mypPrecioWidgetView" class="mypPrecioWidgetView col-sm-6 col-xs-6">
      <div id="spinner8" class="spinner-container">
        <div class="spinner"></div>
      </div>
      <div class="row">
        <div id="mypGraphic3" class="chari-chart aspect myp-aspect"></div>
        <div
          id="mypNoDataRect3"
          class="esios-home__no-data-container_cuadrado hidden"
        >
          ${i18next.t('no-data-available')}
        </div>
        <ul class="esios-listEnergyItems__list"></ul>
      </div>
    </div>
  `;
};

export default TemplateEmbedIndicatorsInbalances;
