'use strict';

/**
 * @description Dashboard Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { render } from 'lit-html';
import { store } from '../../../app.init';
import { templateLayoutExtended } from './layoutExtended.template';
import { servicesLayoutMain } from './layoutExtended.services';
import i18n from 'i18next';
import moment from 'moment-timezone';

let componentLayoutExtended = (function () {
  let context = {};
  let handlers = {};
  let renderSuccess = false;

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let renderComponent = async (template, subcontentData) => {
    try {
      // Render the template to the document
      render(
        // Define a template
        templateLayoutExtended(template, subcontentData),
        document.querySelector('#layout-extended')
      );
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }

    function truncateString(string, limit) {
      if (string.length > limit) {
        return string.substring(0, limit) + '...';
      } else {
        return string;
      }
    }

    function stripTags(text) {
      return text.replace(/(<([^>]+)>)/gi, '');
    }

    let newsData = await servicesLayoutMain.getNews();
    let newsHtml = '';
    newsData.map((e, index) => {
      let bodyParse = stripTags(e.body);
      let truncateBody = truncateString(bodyParse, 150);
      index <= 2
        ? (newsHtml += `<div class="news">
          <p class="news-date">${moment(e.published_at).format(
            'DD / MM / YYYY'
          )}</p>
          <hr class="news-hr">
          <p class="news-title">
            <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.news')}/${
            e.slug
          }"
            class="news-title">${e.title}</a></p>
          <p class="news-body">${truncateBody}</p>
        </div>`)
        : '';
    });
    if (!!document.querySelector('#news')) {
      document.querySelector(
        '#news'
      ).innerHTML = `<div class="news-container">${newsHtml}</div>`;
    }
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = (template, subcontentData) => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent(template, subcontentData);

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: (template, subcontentData) => init(template, subcontentData),
  };
})();

export { componentLayoutExtended };
