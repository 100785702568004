"use strict";
import { html } from "lit-html";

let templateTable = (columns, elements, tableId) => {
  return html`
    <table id=${tableId} class="table table-striped table-bordered" style="width:100%">
        <thead>
            ${columns}
        </thead>
        <tbody>
            ${elements}
        </tbody>
    </table>
  `;
};

export { templateTable };
