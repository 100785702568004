import { html } from 'lit-html';
import i18n from 'i18next';

const MyEsiosWidget6 = () => {
  return html`
    <div id="mypVolumenWidgetView" class="mypVolumenWidgetView col-xs-5">
      <div class="esios-toolbar col-12">
        <div class="esios-toolbar6"></div>
        <div id="mypVolumenWidgetViewSelector"></div>
      </div>
      <div id="spinner6" class="spinner-container">
        <div class="spinner"></div>
      </div>
      <h2 class="esios-market-prices__subtitle">${i18n.t('volume')} MWh</h2>
      <div id="widget6" class="row">
        <div id="mypGraphic1" class="chari-chart col-xs-6">
          <div
            id="mypNoDataRect1"
            class="esios-home__no-data-container_cuadrado"
            style="margin: 95px 22px; width: 220px;"
          >
            ${i18n.t('no-data-available')}
          </div>
        </div>
        <div class="volume-leyends offset-1 col-xs-5"></div>
      </div>

      <div class="row mt-2 mb-2">
        <div class="col-sm-12">
          <div class="widget-summary">
            <div
              class="widget-summary-item energia-total-mercado-de-produccion"
            >
              <div class="label">${i18n.t('total_volume')}</div>
              <div class="value">
                <div id="total-volume" class="is-big-number is-bold"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `;
};

export default MyEsiosWidget6;
