'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';
import backImage from '../../assets/images/header-default.jpg';
import loupe from '../../assets/images/loupe.svg';
import { componentLayout } from '../../components/layout/layout.component';

let templateResultados = (
  context,
  handlers,
  searchForm,
  cantidad,
  data,
  select,
  selectElements,
  filterFormData
) => {
  const {
    onSearch,
    search,
    handleClickButton,
    onHide,
    onSelectChange,
    ocultar,
    onQueryChange,
  } = handlers;
  const { title, body, url } = data;

  const sortSelect = (a, b) => {
    if (i18n.t(a.label) > i18n.t(b.label)) {
      return 1;
    }
    if (i18n.t(a.label) < i18n.t(b.label)) {
      return -1;
    }
    return 0;
  };
  const arrayMove = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  select = select.map((i) => {
    const options = i.options.sort(sortSelect);
    return {
      id: i.id,
      label: i.label,
      options: arrayMove(
        options,
        options.indexOf(options.find((i) => i.label === 'show_all')),
        0
      ),
    };
  });
  const options = select.map((item) => item.options);
  return componentLayout.render(
    html`
      <div class="esios-resultados">
        <h1 class="esios-resultados__title">${i18n.t('search')}</h1>
        <div class='header'>
          <span class="esios-resultados__opinion"
            >${i18n.t(
              'your-search'
            )} "<strong class="esios-resultados__strongOpinion">${
      searchForm.template
    }</strong>"
    ${
      cantidad.length === 0
        ? i18n.t('onprogress')
        : `${i18n.t('returned-result')} ${cantidad} ${i18n.t('result-s')}`
    }
  </span>
          <button id="btn-filter" class="filter-off">${i18n.t(
            'advanced_search'
          )}</button>
        </div>

        <div id='allFilters' style='display:none' class='all-filters'>
          <div class='option-filter'>
            <p>${i18n.t('search_complete_words')}</p>
            <input id='p1' type='checkbox' checked>
          </div>
          <div class='option-filter'>
            <p>${i18n.t('search_indicator_information')}</p>
            <input id='p2' type='checkbox'>
          </div>
        </div>
        
        <form>
          <div class="esios-resultados__containerInput">
            <input
              class="esios-resultados__buscador"
              id="query"
              name="query"
              type="search"
              value=${searchForm.template}
              @keyup="${search}"
            />
            <input
              type="button"
              class="esios-resultados__button-remove hidden"
              @click="${onHide}"
            >
            </input>

            <span class="esios-resultados__search-filter">
              <img src="${loupe}" alt="Logo" />
            </span>
          </div>
        </form>

        <div class="esios-resultados__filter-buttons" id="filter-buttons">
          <button id="btn-todo" class="esios-resultados__btn" @click=${handleClickButton}>
            ${i18n.t('all')}
          </button>
          <button
            class="esios-resultados__btn"
            value="StaticPage"
            @click=${handleClickButton}
          >
            ${i18n.t('pages')}
          </button>
          <button
            class="esios-resultados__btn"
            value="Glossary"
            @click=${handleClickButton}
          >
            ${i18n.t('glossary')}
          </button>
          <button
            class="esios-resultados__btn"
            value="Documentation"
            @click=${handleClickButton}
          >
            ${i18n.t('documentation')}
          </button>
          <button
            id='btnData'
            class="esios-resultados__btn btn-selected"
            value="Data"
            @click=${handleClickButton}
          >
            ${i18n.t('data')}
          </button>
          <button
            class="esios-resultados__btn"
            value="News"
            @click=${handleClickButton}
          >
            ${i18n.t('news')}
          </button>
        </div>

        <div id='filterData' display='block'>
        </div>

        <div id='filterDocument' style='opacity:0.5; pointer-events: none;' class="esios-documentacion__content row">
          <div class="esios-documentacion__wrap col-4">
            <input
              class="esios-documentacion__tabla__input"
              placeholder="${i18n.t('go_search')}"
              type="text"
              @keyup="${onQueryChange}"
            />
            <button
              class="esios-documentacion__boton-remove hidden"
              @click="${ocultar}"
            >
              &nbsp
            </button>
          </div>
          <div class="esios-documentacion__form row">
            ${selectElements.map(
              (select, index) =>
                html`<a class="esios-documentacion__wrap2">
                  <select
                    class="esios-documentacion__tabla--dropdown2 ree-select-${select.value}"
                    value="${filterFormData[select.value]}"
                    @change="${onSelectChange(index)}"
                  >
                    <option class="option" value="" selected disabled hidden>
                      ${i18n.t(select.label)}
                    </option>
                    ${options[index]?.map(
                      (item) =>
                        html` <option class="option" value="${item.value}">
                          ${i18n.t(item.label)}
                        </option>`
                    )}
                  </select>
                </a>`
            )}
          </div>
        </div>

        <div class="spinner-displayer"></div>
        <ul class='table-data'>
          ${data.map(
            (data) =>
              html`<li>
                <div class="esios-resultados__contenido">
                    <h3>
                      <a
                        class="esios-resultados__contenidoTitle"
                        href="${data.url}"
                        >${data.title}</a
                      >
                    </h3>
                    <p class="esios-resultados__contenidoBody">
                      ${!data.body ? '' : html([data.body])}
                    </p>
                  </div>     
            </div>
            </li>`
          )}
        </ul>
      </div>
    `,
    {
      image: backImage,
    }
  );
};

export { templateResultados };
