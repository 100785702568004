import i18n from 'i18next';
import { html, render } from 'lit-html';
import moment from 'moment';
import { store } from '../../app.init';
import { API_URL } from '../../environment.json';
import { datepickerComponent } from '../../shared/components/datepicker/datepicker.component';
import { componentSelects } from '../../shared/components/selects/selects.component';
import { componentTimeSelector } from '../../shared/components/time-selector/time-selector.component';
import { getUrlParams } from '../../utils/url';
import { Utils } from '../../utils/utils';
import { servicesCalendario } from '../calendar/calendario.services';
import { templateEmbedCalendario } from './embed-indicators-complex-graphs/embed-indicator.calendar.template';

const TemplateEmbedIndicatorsCalendario = (widgetId, title) => {
  const state = store.getState();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let period = ['mensual', 'trimestral', 'anual'];
  const dateUrl = urlParams.get('date');
  let currentYear = urlParams.get('year')
    ? urlParams.get('year')
    : new Date().getFullYear();

  const selectGroupYearStart = {
    id: 'select_groupYearStart',
    currentLabel: '',
    currentValue: '',
    label: '',
    options: [],
    columns: 1,
    onlySelect: true,
    changeTime: (value) => changeTimeFromSelector(value),
  };

  const changeSelectValue = (value) => {
    const { id, newValue } = value;
    let hours = newValue.split(':')[0];
    let minutes = newValue.split(':')[1];
    document.querySelector(`#char${widgetId}`).style.opacity = '.5';
    // document.getElementById(`spinner${widgetId}`).classList.remove('hidden');
    if (id === selectTimeDesglose.id) {
      selectTimeDesglose.optionsHours.map((el) => {
        if (el.value === hours) {
          selectTimeDesglose.currentHour = hours;
          selectTimeDesglose.currentMinutes = minutes;
          componentTimeSelector.render(
            selectTimeDesglose,
            '#embed-timeSelector'
          );
        }
      });
    }
  };

  const getFormat = () => {
    if (title === 'evolution_of_demand') {
      return 'MM / YYYY';
    } else {
      return 'DD / MM / YYYY';
    }
  };

  const checkGraph = () => {
    switch (title) {
      case 'auctions':
        return templateEmbedCalendario();
      default:
        return html` <div id="spinner${widgetId}" class="spinner-container">
            <div class="spinner"></div>
          </div>
          <div id="widget${widgetId}" class="generacion-consum">
            ${widgetId !== 21
              ? html`<div id="rect${widgetId}" class="rect-no-data">
              ${i18n.t('no-data-available')}
            </div>
        </div>`
              : ``}
          </div>`;
    }
  };

  // CALENDAR FUNCTIONS
  let ruta = window.location.pathname.split('/').includes('embed');
  let path = window.location.pathname.split('/').includes('insertado');

  const setParameter = (key, value) => {
    urlParams.set(key, value);
    history.pushState({}, '', window.location.pathname + '?' + urlParams);
  };

  const changeTimeFromSelector = (value) => {
    const { id, newValue } = value;
    if (selectGroupYearStart.id === id) {
      selectGroupYearStart.options.map((el) => {
        if (el.value === newValue) {
          selectGroupYearStart.currentLabel = el.label;
          selectGroupYearStart.currentValue = el.value;
          componentSelects.render(selectGroupYearStart, '#select-year-print');
          componentSelects.render(selectGroupYearStart, '#selectYear');
          const state = store.getState();
          setParameter('year', el.value);
          eventSelect(el.value, state);
        }
      });
      return;
    }
  };

  let selectBreadcrumbs = async (state) => {
    let { meta } = await servicesCalendario.getAuctionYears();

    let thisYears = [];
    let filteredYears = meta.years.filter((el, index) => {
      if (index < meta.years.length - 1) {
        return el;
      }
    });
    filteredYears.map((year) => {
      thisYears.unshift({
        label: year,
        value: year,
      });
    });

    const { label, value } = thisYears[thisYears.length - 1];
    let urlValue;
    if (urlParams.get('year')) {
      urlValue = urlParams.get('year');
    }

    selectGroupYearStart.currentValue = urlValue ? urlValue : value;
    selectGroupYearStart.currentLabel = urlValue ? urlValue : label;
    selectGroupYearStart.options = thisYears;

    selectGroupYearStart.options = thisYears;
    eventSelect(selectGroupYearStart.currentValue, state);
    return selectGroupYearStart;
  };
  //Función para crear el calendario
  let setCalendar = (year, anchor) => {
    $(anchor).datepicker({
      firstDay: i18n.t('routes.lng') === 'en' ? 0 : 1,
      numberOfMonths: 12,
      monthNames: [
        i18n.t('january'),
        i18n.t('february'),
        i18n.t('march'),
        i18n.t('april'),
        i18n.t('ma'),
        i18n.t('june'),
        i18n.t('july'),
        i18n.t('august'),
        i18n.t('september'),
        i18n.t('october'),
        i18n.t('november'),
        i18n.t('december'),
      ],
      monthNamesShort: [
        i18n.t('jan'),
        i18n.t('feb'),
        i18n.t('mar'),
        i18n.t('apr'),
        i18n.t('may'),
        i18n.t('june'),
        i18n.t('july'),
        i18n.t('aug'),
        i18n.t('sep'),
        i18n.t('oct'),
        i18n.t('nov'),
        i18n.t('dec'),
      ],
      dayNames: [
        i18n.t('sunday'),
        i18n.t('monday'),
        i18n.t('tuesday'),
        i18n.t('wednesday'),
        i18n.t('thursday'),
        i18n.t('friday'),
        i18n.t('saturday'),
      ],
      dayNamesShort: [
        i18n.t('sun'),
        i18n.t('mon'),
        i18n.t('tue'),
        i18n.t('wed'),
        i18n.t('thu'),
        i18n.t('fri'),
        i18n.t('sat'),
      ],
      dayNamesMin: [
        i18n.t('S'),
        i18n.t('M'),
        i18n.t('T'),
        i18n.t('W'),
        i18n.t('T'),
        i18n.t('F'),
        i18n.t('S'),
      ],
      minDate: new Date(year - 1, 11, 1),
      maxDate: new Date(year, 10, 30),
      defaultDate: new Date(year - 1, 11, 1),
      hideIfNoPrevNext: true,
      beforeShowDay: function () {
        return [false];
      },
    });
  };
  //Marca los días con eventos en el calendario
  let setEventsDaysCalendar = (calendarData) => {
    let calendars = document.querySelectorAll('.hasDatepicker');
    if (calendars) {
      for (let calendar of calendars) {
        //Recorremos los meses del datepicker
        let monthsDatepicker = calendar.querySelectorAll(
          '.ui-datepicker-group'
        );
        let monthCounter = 11;

        for (let month of monthsDatepicker) {
          let daysDatepicker = month.querySelectorAll('.ui-state-default');

          //Recorremos los días de cada mes del datepicker
          for (let dayPicker in daysDatepicker) {
            let notWorkingDays;
            let monthlyAuction;
            let monthlySpec;
            let trimAuction;
            let trimSpec;
            let annuallyAuction;
            let annuallySpec;
            let country;
            let eventDates = getAllEventsDates(calendarData);

            if (calendar.matches('#auctions-calendar')) {
              country = i18n.t('FR');
              notWorkingDays = eventDates.notWorking;
              monthlyAuction = eventDates.monthlyAuction;
              monthlySpec = eventDates.monthlySpec;
              annuallyAuction = eventDates.anualAuction;
              annuallySpec = eventDates.anualSpec;
            } else if (calendar.matches('#auctions-calendar-por')) {
              country = i18n.t('PT');
              notWorkingDays = eventDates.notWorkingPor;
              monthlyAuction = eventDates.monthlyAuctionPor;
              monthlySpec = eventDates.monthlySpecPor;
              trimAuction = eventDates.trimAuction;
              trimSpec = eventDates.trimSpec;
              annuallyAuction = eventDates.anualAuctionPor;
              annuallySpec = eventDates.anualSpecPor;
            }

            //Marcamos los días inhábiles
            let notWorking = searchNotWorkingDays(
              dayPicker,
              notWorkingDays,
              monthCounter,
              daysDatepicker
            );
            if (notWorking.length > 0) {
              for (let day of notWorking[0]) {
                day.classList.add('not-working');
              }
            }
            //Marcamos las subastas mensuales
            let monthlyAuctions = searchDays(
              dayPicker,
              monthlyAuction,
              monthCounter,
              daysDatepicker
            );
            if (monthlyAuctions.length > 0) {
              let index = 0;
              for (let day of monthlyAuctions[0]) {
                let info = {
                  day: day,
                  class: 'monthy-auction',
                  date: monthlyAuctions[1][0].event_date,
                  text: i18n.t('month_auction'),
                  period: period[0],
                  parent: day.parentNode,
                  dayApi: monthlyAuctions[1][index],
                  country: country,
                };
                setTooltip(info);
                let infoTest = {};
                index++;
              }
            }
            //Marcamos las especificaciones de las subastas mensuales
            let monthSpec = searchDays(
              dayPicker,
              monthlySpec,
              monthCounter,
              daysDatepicker
            );
            if (monthSpec.length > 0) {
              let index = 0;
              for (let day of monthSpec[0]) {
                let info = {
                  day: day,
                  class: 'monthly-spec',
                  date: monthSpec[1][0].event_date,
                  text: i18n.t('month_specifications'),
                  period: period[0],
                  parent: day.parentNode,
                  dayApi: monthSpec[1][index],
                  country: country,
                };
                setTooltip(info);
                index++;
              }
            }
            //Marcamos las subastas trimestrales
            if (trimAuction) {
              let trimestralAuction = searchDays(
                dayPicker,
                trimAuction,
                monthCounter,
                daysDatepicker
              );
              let trimester;
              if (trimestralAuction.length > 0) {
                let index = 0;
                for (let day of trimestralAuction[0]) {
                  trimester = getTrimester(trimestralAuction[1][0].event_date);
                  let info = {
                    day: day,
                    class: 'trim-auction',
                    date: trimestralAuction[1][0].event_date,
                    text: i18n.t('quarter_auction'),
                    period: period[1],
                    parent: day.parentNode,
                    dayApi: trimestralAuction[1][index],
                    country: country,
                    quarterText: ' ' + i18n.t('quarter') + ' ' + trimester,
                  };
                  setTooltip(info);
                  index++;
                }
              }
            }
            //Marcamos las especificaciones de las subastas trimestrales
            if (trimSpec) {
              let trimestralSpec = searchDays(
                dayPicker,
                trimSpec,
                monthCounter,
                daysDatepicker
              );
              let trimester;
              if (trimestralSpec.length > 0) {
                let index = 0;
                for (let day of trimestralSpec[0]) {
                  trimester = getTrimester(trimestralSpec[1][0].event_date);
                  let info = {
                    day: day,
                    class: 'trim-spec',
                    date: trimestralSpec[1][0].event_date,
                    text: i18n.t('quarter_specifications'),
                    period: period[1],
                    parent: day.parentNode,
                    dayApi: trimestralSpec[1][index],
                    country: country,
                    quarterText: ' ' + i18n.t('quarter') + ' ' + trimester,
                  };
                  setTooltip(info);
                  index++;
                }
              }
            }
            //Marcamos las subastas anuales
            let anualAuction = searchDays(
              dayPicker,
              annuallyAuction,
              monthCounter,
              daysDatepicker
            );
            if (annuallyAuction.length > 0) {
              let index = 0;
              for (let day of anualAuction[0]) {
                let info = {
                  day: day,
                  class: 'anual-auction',
                  date: anualAuction[1][0].event_date,
                  text: i18n.t('year_auction'),
                  period: period[2],
                  parent: day.parentNode,
                  dayApi: anualAuction[1][index],
                  country: country,
                };
                setTooltip(info);
                index++;
              }
            }
            //Marcamos las especificaciones de las subastas anuales
            let anualSpec = searchDays(
              dayPicker,
              annuallySpec,
              monthCounter,
              daysDatepicker
            );
            if (anualSpec.length > 0) {
              let index = 0;
              for (let day of anualSpec[0]) {
                let info = {
                  day: day,
                  class: 'anual-spec',
                  date: anualSpec[1][0].event_date,
                  text: i18n.t('year_specifications'),
                  period: period[2],
                  parent: day.parentNode,
                  dayApi: anualSpec[1][index],
                  country: country,
                };
                setTooltip(info);
                index++;
              }
            }
          }
          if (monthCounter === 11) {
            monthCounter = 0;
          } else {
            monthCounter += 1;
          }
        }
      }
    }
  };
  //Filtra todos los eventos del calendario
  let getAllEventsDates = (calendarData) => {
    let eventDates = servicesCalendario.getEventsDefault();
    for (let date of calendarData) {
      switch (date.event_type) {
        case 'Celebración subasta mensual':
          eventDates.monthlyAuction.push(date);
          break;
        case 'Celebración subasta mensual POR':
          eventDates.monthlyAuctionPor.push(date);
          break;
        case 'Publicación especificación de subasta mensual':
          eventDates.monthlySpec.push(date);
          break;
        case 'Publicación especificación de subasta mensual POR':
          eventDates.monthlySpecPor.push(date);
          break;
        case 'Celebración subasta trim POR':
          eventDates.trimAuction.push(date);
          break;
        case 'Publicación especificación de subasta trim POR':
          eventDates.trimSpec.push(date);
          break;
        case 'Celebración subasta anual':
          eventDates.anualAuction.push(date);
          break;
        case 'Celebración subasta anual POR':
          eventDates.anualAuctionPor.push(date);
          break;
        case 'Publicación especificación de subasta anual':
          eventDates.anualSpec.push(date);
          break;
        case 'Publicación especificación de subasta anual POR':
          eventDates.anualSpecPor.push(date);
          break;
        case 'Festivo España':
          eventDates.notWorking.push(date);
          eventDates.notWorkingPor.push(date);
          break;
        case 'Festivo Francia':
          eventDates.notWorking.push(date);
          break;
        case 'Festivo Portugal':
          eventDates.notWorkingPor.push(date);
          break;
      }
    }
    return eventDates;
  };
  //Itera sobre los días no laborables de la API
  let searchNotWorkingDays = (
    dayCalendar,
    daysApi,
    monthCounter,
    daysDatepicker
  ) => {
    //Recuperamos los días y los recorremos
    let eventDays = [];
    let daysInfo = [];
    for (let dayApi of daysApi) {
      let day = new Date(dayApi.event_date).getUTCDate();
      let month = new Date(dayApi.event_date).getUTCMonth();

      //Comprobamos que coinciden día y mes del datepicker con los días de la API
      if (day.toString() === dayCalendar && month === monthCounter) {
        eventDays.push(daysDatepicker[dayCalendar - 1]);
        daysInfo.push(dayApi);
      }
    }
    return [eventDays, daysInfo];
  };
  //Creación tootltip
  let createTooltip = (parent, element, day, text, country) => {
    let tooltip = document.createElement('div');
    let downloads = day.download_links;
    tooltip.classList.add('esios-tooltip');
    let title = document.createElement('div');
    title.classList.add('esios-tooltip__title');
    title.innerText = text;
    let content = document.createElement('div');
    content.classList.add('esios-tooltip__content');
    if (downloads && downloads.length > 0) {
      for (let link in downloads) {
        let linkText;
        if (downloads.length === 2) {
          if (link === '0') {
            linkText = i18n.t('download') + ' ' + i18n.t('ES') + '-' + country;
          } else {
            linkText = i18n.t('download') + ' ' + country + '-' + i18n.t('ES');
          }
        } else {
          linkText = i18n.t('download');
        }
        let anchor = document.createElement('a');
        anchor.classList.add('esios-tooltip__link');
        anchor.setAttribute('href', API_URL + downloads[link]);
        anchor.innerText = linkText;
        content.appendChild(anchor);
      }
    }
    tooltip.appendChild(title);
    if (content.innerHTML !== '') {
      tooltip.appendChild(content);
    }
    parent.insertBefore(tooltip, element);
  };
  //Itera sobre los días de la API
  let searchDays = (dayCalendar, daysApi, monthCounter, daysDatepicker) => {
    //Recuperamos los días y los recorremos
    let eventDays = [];
    let daysInfo = [];
    for (let dayApi of daysApi) {
      let day = new Date(dayApi.event_date).getDate();
      let month = new Date(dayApi.event_date).getMonth();

      //Comprobamos que coinciden día y mes del datepicker con los días de la API
      if (day.toString() === dayCalendar && month === monthCounter) {
        eventDays.push(daysDatepicker[dayCalendar - 1]);
        daysInfo.push(dayApi);
      }
    }
    return [eventDays, daysInfo];
  };

  //Averigua el trimestre
  let getTrimester = (date) => {
    let trimester;
    switch (new Date(date).getMonth()) {
      case 11:
        trimester = 1;
        break;
      case 2:
        trimester = 2;
        break;
      case 5:
        trimester = 3;
        break;
      case 8:
        trimester = 4;
        break;
      default:
        break;
    }
    return trimester;
  };
  //Evento para cambiar el año desde el select del breadcrumbs
  let eventSelect = async (valor, state) => {
    document.getElementById('calendar-container').classList.add('hidden');
    document.getElementById('calendarspinner').classList.remove('hidden');
    let calendars = await servicesCalendario.getCalendars(
      valor,
      state.exampleReducer.currentLang
    );

    $('#auctions-calendar').datepicker('destroy');
    $('#auctions-calendar-por').datepicker('destroy');
    setCalendar(valor, '#auctions-calendar');
    setCalendar(valor, '#auctions-calendar-por');
    setEventsDaysCalendar(calendars);
    getAllEvents(calendars, valor);

    document.getElementById('calendarspinner').classList.add('hidden');
    document.getElementById('calendar-container').classList.remove('hidden');
  };
  let setTooltip = (info) => {
    //Añadimos la clase al calendario
    info.day.classList.add(info.class);
    //Creamos el texto para el tooltip
    let text = createTooltipText(
      info.date,
      info.text,
      info.period,
      info.quarterText
    );
    //Creamos el tooltip
    createTooltip(info.parent, info.day, info.dayApi, text, info.country);
  };
  //Calendario responsive
  let getAllEvents = (calendarData, year) => {
    let orderedData = calendarData.reverse();
    let months = [];
    //Separamos los eventos por meses
    for (let i = 0; i <= 11; i++) {
      let month = [];
      for (let day of orderedData) {
        let monthEvent = new Date(day.event_date).getMonth();
        if (monthEvent === i) {
          month.push(day);
        }
      }
      if (i === 11) {
        months.unshift(month);
      } else {
        months.push(month);
      }
    }
    createAllTables(months, year);
  };
  let createTooltipText = (date, event, periodEvent, quarterText) => {
    let monthsName = [
      i18n.t('january'),
      i18n.t('february'),
      i18n.t('march'),
      i18n.t('april'),
      i18n.t('ma'),
      i18n.t('june'),
      i18n.t('july'),
      i18n.t('august'),
      i18n.t('september'),
      i18n.t('october'),
      i18n.t('november'),
      i18n.t('december'),
    ];
    let dateAuction = new Date(date);

    let monthName, yearAuction;
    if (dateAuction.getMonth() + 1 == 12) {
      let { year } = getUrlParams();
      monthName = monthsName[0];
      yearAuction = year ? year : new Date().getFullYear();
    } else {
      monthName = monthsName[dateAuction.getMonth() + 1];
      yearAuction = dateAuction.getFullYear();
    }
    if (periodEvent === 'mensual') {
      return `${event} ${monthName} ${yearAuction}`;
    } else if (periodEvent === 'trimestral') {
      return `${event} ${quarterText}`;
    } else {
      return `${event} ${yearAuction}`;
    }
  };
  //Creamos una tabla por cada mes
  let createAllTables = (months, year) => {
    let anchor = document.getElementById('auctionsCalendarMobile');
    //Borramos el calendario si lo hubiera
    if (anchor.childNodes.length > 0) {
      anchor.textContent = '';
    }
    for (let month in months) {
      let monthNames = [
        i18n.t('december'),
        i18n.t('january'),
        i18n.t('february'),
        i18n.t('march'),
        i18n.t('april'),
        i18n.t('ma'),
        i18n.t('june'),
        i18n.t('july'),
        i18n.t('august'),
        i18n.t('september'),
        i18n.t('october'),
        i18n.t('november'),
      ];
      let currentMonth = months[month];
      createTable(monthNames[month], year, currentMonth);
    }
  };

  render(
    html`
      <div
        id="char${widgetId}"
        class=${'row main__chart esios-embed-indicators__' + title}
      >
        <div class="col-xs-6 hidden" style="display: none;">
          <h2 class="esios-home__title" style="margin-bottom: 35px">
            ${i18n.t(title)}
          </h2>
        </div>
        <div class="col-xs-6 time__selector hidden" style="display: none;">
          <div class="mod-date-embed">
            <input
              id="datepicker"
              class="datepicker-btn mod-date-embed--input-datepicker"
              size="2"
              name="datepicker"
              value=${dateUrl
                ? moment(dateUrl, 'DD-MM-YYYY').format(getFormat())
                : moment().format(getFormat())}
              readonly="true"
            />
          </div>
        </div>
        ${checkGraph()}
        <div id="widget${widgetId}Data"></div>
      </div>
    `,
    document.querySelector('#embed-container')
  );
  datepickerComponent.render();
  const datePicker = document.querySelector('#datepicker');
  if (dateUrl) {
    datePicker.value = moment(dateUrl, 'DD-MM-YYYY').format(getFormat());
  }

  const selectTimeDesglose = {
    id: 'selectTimeDesglose',
    currentHour: '00',
    currentMinutes: '00',
    optionsHours: Utils.getArrayOfHours(),
    optionsMinutes: [],
    changeTime: changeSelectValue,
  };
  componentTimeSelector.render(selectTimeDesglose, '#embed-timeSelector');

  if (widgetId) {
    document.querySelector(`#char${widgetId}`).style.opacity = '.5';

    servicesCalendario
      .getCalendars(currentYear, state.exampleReducer.currentLang)
      .then(async (calData) => {
        $(function () {
          //Calendario Francia-Espana
          setCalendar(currentYear, '#auctions-calendar');

          //Calendario Portugal-Espana
          setCalendar(currentYear, '#auctions-calendar-por');
          setEventsDaysCalendar(calData);
        });

        let select = await selectBreadcrumbs(state);

        //Creación calendario responsive
        getAllEvents(calData, select.value);
        componentSelects.render(selectGroupYearStart, '#selectYear');
        componentSelects.render(selectGroupYearStart, '#select-year-print');

        if (ruta || path) {
          document.querySelector('.esios-calendar').style.cssText = `
        width: 18cm;
        margin-top: 0px;
        margin-bottom: 5px;`;
          document.querySelector('.title-for-print').style.cssText = `
        display: flex; 
        align-items: center;
        justify-content: space-between;
      `;

          let selectConfig = {
            hide: [
              '.esios-footer',
              '.esios-header',
              '#esios-layout-banner',
              '.esios-layout__footer-image',
              '.esios-breadcrumbs-nav__wrapper',
              '.esios-layout__breadcrumbs__wrapper',
              '.esios-toolbar',
            ],
            show: [],
          };
          Utils.changeStyles(selectConfig);
        } else {
          let selectConfig = {
            hide: ['#print-button', '#select-year-print'],
            show: [],
          };
          Utils.changeStyles(selectConfig);
        }

        document.getElementById('calendarspinner').classList.add('hidden');
        document
          .getElementById('calendar-container')
          .classList.remove('hidden');
        document.querySelector(`#char${widgetId}`).style.opacity = '1';
      });
  }
};

export default TemplateEmbedIndicatorsCalendario;

//Crear los enlaces del tooltip responsive
export const createLinksTooltip = (tooltip, downloadLinks, eventType, text) => {
  for (let link in downloadLinks) {
    let linkText;
    let linkMailText;
    //Enlace mail
    let linkMail = document.createElement('a');
    linkMail.classList.add('esios-tooltip__link');
    linkMail.href =
      'mailto:?body=https://api.esios.ree.es' + downloadLinks[link];
    //Enlace PDF
    let linkElement = document.createElement('a');
    linkElement.classList.add('esios-tooltip__link');
    linkElement.href = 'https://api.esios.ree.es' + downloadLinks[link];

    //Si hay 2 enlaces y es el primer enlace
    if (downloadLinks.length === 2 && link === '0') {
      if (eventType.indexOf('POR') === -1) {
        let texts = setLinks(
          i18n.t('see_pdf'),
          i18n.t('share'),
          i18n.t('ES'),
          i18n.t('FR')
        );
        linkText = texts.linkText;
        linkMailText = texts.linkMailText;
      } else {
        let texts = setLinks(
          i18n.t('see_pdf'),
          i18n.t('share'),
          i18n.t('ES'),
          i18n.t('PT')
        );
        linkText = texts.linkText;
        linkMailText = texts.linkMailText;
      }
      //Si hay 2 enlaces y es el segundo enlace
    } else if (downloadLinks.length === 2 && link === '1') {
      if (eventType.indexOf('POR') === -1) {
        let texts = setLinks(
          i18n.t('see_pdf'),
          i18n.t('share'),
          i18n.t('FR'),
          i18n.t('ES')
        );
        linkText = texts.linkText;
        linkMailText = texts.linkMailText;
      } else {
        let texts = setLinks(
          i18n.t('see_pdf'),
          i18n.t('share'),
          i18n.t('PT'),
          i18n.t('ES')
        );
        linkText = texts.linkText;
        linkMailText = texts.linkMailText;
      }
      //Si solo hay un enlace
    } else {
      linkText = i18n.t('see_pdf');
      linkMailText = i18n.t('share');
    }
    linkMail.innerHTML = linkMailText;
    linkElement.innerHTML = linkText;

    tooltip.appendChild(linkMail);
    tooltip.appendChild(linkElement);
  }
};

export const setLinks = (text1, text2, lang1, lang2) => {
  let linkText = `${text1} ${lang1}-${lang2}`;
  let linkMailText = `${text2} ${lang1}-${lang2}`;
  let texts = {
    linkText: linkText,
    linkMailText: linkMailText,
  };
  return texts;
};

//Crear tooltip responsive
export const createTooltipResponsive = (cell, links, eventType) => {
  let tooltip = document.createElement('div');
  tooltip.classList.add('esios-tooltip', 'esios-tooltip--responsive');
  cell.appendChild(tooltip);
  let content = document.createElement('div');
  content.classList.add('esios-tooltip__content');
  createLinksTooltip(content, links, eventType);
  tooltip.appendChild(content);
};

//Creamos tabla para calendario responsive
export const createTable = (month, year, data) => {
  let anchor = document.getElementById('auctionsCalendarMobile');
  //Tabla
  let table = document.createElement('table');
  table.classList.add('esios-calendar__mobile-table');
  //Header
  let header = document.createElement('thead');
  let row = document.createElement('tr');
  header.appendChild(row);
  let day = document.createElement('th');
  day.innerHTML = i18n.t('day');
  let yearTxt = document.createElement('th');
  if (month === i18n.t('december')) {
    year = year - 1;
  }
  yearTxt.innerHTML = `${month} ${year}`;
  table.appendChild(header);
  row.appendChild(day);
  row.appendChild(yearTxt);
  row.appendChild(document.createElement('th'));
  //Body
  let body = document.createElement('tbody');
  table.appendChild(body);
  //Filas y celdas
  for (let event of data) {
    let row = document.createElement('tr');
    let eventClass = filterDates(event);
    if (eventClass) {
      row.classList.add(eventClass);
    }
    let dayEvent = document.createElement('td');
    let span = document.createElement('span');
    span.innerHTML = new Date(event.event_date).getDate();
    dayEvent.appendChild(span);
    let eventType = document.createElement('td');
    eventType.innerHTML = event.event_type;
    let downloadLinks = event.download_links;
    let button;
    let downButton;
    let cell = document.createElement('td');
    cell.classList.add('esios-calendar__cell-download');
    //Enlaces de descarga
    if (downloadLinks && downloadLinks.length > 0) {
      button = document.createElement('button');
      button.classList.add('esios-calendar__download-btn');
      cell.appendChild(button);
      //Crear tooltip
      createTooltipResponsive(cell, event.download_links, event.event_type);
      window.addEventListener('click', toggleTooltip);
    }
    row.appendChild(dayEvent);
    row.appendChild(eventType);
    row.appendChild(cell);
    if (downButton) {
      row.appendChild(downButton);
    }
    body.appendChild(row);
  }
  anchor.appendChild(table);
};

//Filtramos por evento
export const filterDates = (date) => {
  if (date.geo_id === 8741 || date.geo_id === 2) {
    return 'esios-calendar__mobile-not-working';
  } else {
    let eventClass;
    switch (date.event_type) {
      case 'Publicación especificación de subasta anual':
        eventClass = 'esios-calendar__mobile-anual-spec';
        break;
      case 'Celebración subasta anual':
        eventClass = 'esios-calendar__mobile-anual-auction';
        break;
      case 'Publicación especificación de subasta mensual':
        eventClass = 'esios-calendar__mobile-monthly-spec';
        break;
      case 'Celebración subasta mensual':
        eventClass = 'esios-calendar__mobile-monthly-auction';
      default:
        break;
    }
    return eventClass;
  }
};

//Abrir tooltip responsive
export const toggleTooltip = (event) => {
  let sibling = event.target.nextSibling;
  if (sibling && sibling.classList) {
    if (!sibling.classList.contains('esios-tooltip--opened')) {
      closeTooltips();
    }
    sibling.classList.toggle('esios-tooltip--opened');
  }
};
//Cerrar tooltip responsive
export const closeTooltips = () => {
  let openedTooltips = document.querySelectorAll('.esios-tooltip--opened');
  for (let tooltip of openedTooltips) {
    tooltip.classList.remove('esios-tooltip--opened');
  }
};
