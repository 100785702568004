'use strict';

/**
 * @description About Component
 * @returns {Object} Devuelve la instancia del componente
 */

import i18n from 'i18next';
import { store } from '../../app.init.js';
import { componentRapidAccess } from '../../shared/components/rapid-access/rapid-access.component.js';
import { servicesAbout } from './about.services.js';
import { templateAbout } from './about.template.js';

let componentAbout = (function () {
  let context = {};
  let handlers = {};
  let renderSuccess = false;
  let modalContent;
  let modalTitle;

  let preRenderComponent = async () => {
    const state = store.getState();
    document.title = `${i18n.t('about_title')} | ${i18n.t('page_subtitle')}`;
    context.name = state.exampleReducer.name;
    modalContent = await servicesAbout.getModalBody();
    modalTitle = await servicesAbout.getModalTitle();
  };

  let renderComponent = async () => {
    const state = store.getState();
    modalContent = await servicesAbout.getModalBody();
    modalTitle = await servicesAbout.getModalTitle();
    try {
      let RapidAccessData;
      if (i18n.t('routes.lng') === 'es') {
        RapidAccessData = [
          {
            id: '#operador-de-sistema',
            title: 'ELECTRICAL_NETWORK',
          },
          {
            id: '#esios-web',
            title: 'public_web_esios',
          },
          {
            id: '#funciones',
            title: 'functions_esios',
          },
          {
            id: '#cecoel',
            title: 'REE_ELECTRICITY_CONTROL',
          },
        ];
      } else {
        RapidAccessData = [
          {
            id: '#public_web',
            title: 'public_web_esios',
          },
          {
            id: '#cecoel',
            title: 'REE_ELECTRICITY_CONTROL',
          },
          {
            id: '#simel',
            title: 'ELECTRICAL_NETWORK',
          },
        ];
      }

      componentRapidAccess.render(RapidAccessData);
      // Render the template to the document
      const data = await servicesAbout.llamarServicioAbout(
        state.exampleReducer.currentLang
      );
      let isShowing = false;
      let isHover = false;
      let isShowingPriority = false;

      templateAbout(context, handlers, data, modalTitle, modalContent);

      let smTooltip = document.querySelector('.is-glossary-item');
      let modal = document.querySelector('#modal');
      let modalResponsive = document.querySelector('#modal-responsive');
      let close = document.querySelector('#close');
      let closeResponsive = document.querySelector('#close-responsive');

      if (modal) {
        smTooltip.addEventListener('mouseenter', () => {
          isShowing = true;
          isHover = true;
          modal.style.display = 'inline';
        });
        smTooltip.addEventListener('mouseleave', () => {
          isHover = false;

          setTimeout(() => {
            if (!isShowingPriority && !isHover) {
              modal.style.display = 'none';
              isShowing = false;
            }
          }, 1000);
        });
        modal.addEventListener('mouseover', () => {
          modal.style.display = 'inline';
          isShowingPriority = true;
          isHover = true;
          isShowing = true;
        });
        modal.addEventListener('mouseleave', () => {
          if (isShowing) {
            modal.style.display = 'none';
            isShowingPriority = false;
            isShowing = false;
            isHover = false;
          }
        });
        close.addEventListener('click', () => {
          modal.style.display = 'none';
          isShowingPriority = false;
          isShowing = false;
        });
      } else {
        smTooltip.addEventListener('click', () => {
          modalResponsive.style.display = 'inline';
        });
        closeResponsive.addEventListener('click', () => {
          modalResponsive.style.display = 'none';
        });
      }

      document
        .querySelector('.esios-footer')
        .classList.add('esios-footer--about');

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentAbout };
