'use strict';

/**
 * @description Red de Operacion Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { store } from '../../app.init.js';
import { servicesOperacion } from './codigos_operacion.services';
import { templateOperacion } from './codigos_operacion.template';
import i18n from 'i18next';

let componentOperacion = (function () {
  let context = {};
  let handlers = {};
  let renderSuccess = false;

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    document.title = `${i18n.t('information_network_codes')} | ${i18n.t(
      'page_subtitle'
    )}`;
    context.name = state.exampleReducer.name;
  };

  let renderComponent = async () => {
    const state = store.getState();
    try {
      // Render the template to the document
      let contactData = await servicesOperacion.init(
        state.exampleReducer.currentLang
      );
      templateOperacion(contactData);
      changetab(contactData);
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  const changetab = () => {
    document.querySelectorAll('a[href^="#"]').forEach((a) => {
      a.addEventListener('click', function (e) {
        e.preventDefault();
        var href = this.getAttribute('href');
        var elem =
          document.querySelector(href) ||
          document.querySelector(
            'a[name=' + href.substring(1, href.length) + ']'
          );
        window.scroll({
          top: elem.offsetTop,
          left: 0,
          behavior: 'smooth',
        });
      });
    });
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();
    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentOperacion };
