import { html } from 'lit-html';

const AnalysisHelpModal = () => {
  return html`<div>
    <iframe
      id="video-modal-help"
      class="yt_player_iframe"
      width="850"
      height="480"
      src="https://www.youtube.com/embed/gkmE0JpbY8c?enablejsapi=1&version=3&playerapiid=ytplayer"
      frameborder="0"
      allowscriptaccess="always"
      allowfullscreen
    ></iframe>
  </div>`;
};

export default AnalysisHelpModal;
