"use strict";
import {
  html
} from "lit-html";
import {
  store
} from "../../app.init";
import {
  templatePieGraph
} from "../../shared/components/pieGraph/pieGraph.template";
import {
  setCurrentLang,
  setNameAction
} from "../../state/actions";
import i18n from "i18next";
import { componentLayout } from "../../components/layout/layout.component";

let templateDashboard = (context, handlers) => {
  return componentLayout.render(html `
    <div class="esios-dashboard">
      <h1>Traduccion: ${i18n.t('HELLO_WORLD')}</h1>
    </div>
  `)
};

export {
  templateDashboard
};