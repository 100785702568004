'use strict';
import { html } from 'lit-html';

let templateEmbed = () => {
  return html` <div class="esios-embed">
    <div id="embed-tag"></div>
  </div>`;
};

export { templateEmbed };
