'use strict';
import i18next from 'i18next';
import { api } from '../../utils/api-interceptor';
let servicesPropuestas = (() => {
  let init = () => {};

  const callService = async () => {
    let data = await api(
      `static_pages?slug=${i18next.t('routes.proposals')}&temporal=` +
        Math.floor(Math.random() * 1000),
      {
        hasLang: true,
      }
    );
    return data.content;
  };

  return {
    init: init,
    callService: callService,
  };
})();

export { servicesPropuestas };
