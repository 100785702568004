'use strict';
import { html } from 'lit-html';
import { componentLayout } from '../../components/layout/layout.component';
import backImage from '../../assets/images/header-default.jpg';

let templateContact = (context, handlers, contactData) => {
  const { title, body } = contactData;
  return componentLayout.render(
    html`
      <div class="esios-contact">
        <h1>${title}</h1>

        ${html([body])}
      </div>
    `,
    {
      image: backImage,
      showFooterImage: true,
      bannerIsAligned: false,
      showBanner: true,
      showSlider: false,
    }
  );
};

export { templateContact };
