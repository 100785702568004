import { render } from 'lit-html';
import { store } from '../../app.init.js';
import { api } from './../../utils/api-interceptor';
import { templateSlider } from './slider.template.js';
import { tns } from 'tiny-slider/src/tiny-slider';

let componentSlider = (function () {
  let context = {};
  let handlers = {};
  let renderSuccess = false;

  let preRenderComponent = async () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let renderComponent = async (showSlider) => {
    const state = store.getState();
    try {
      const lang = state.exampleReducer.currentLang;
      const { contents } = await api(
        `umms?locale=${lang}&temporal=` + Math.floor(Math.random() * 1000)
      );
      // Render the template to the document
      render(
        // Define a template
        templateSlider(showSlider, contents),
        document.querySelector('#esios-slider')
      );
      renderSlider(contents);

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = (showSlider) => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = (showSlider) => {
    //Call pre render component

    preRenderComponent();

    // Call render component
    renderComponent(showSlider);

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent(showSlider) : '';
  };

  return {
    render: (showSlider) => init(showSlider),
  };
})();

export { componentSlider };

const renderSlider = (contents) => {
  const homeSlider = tns({
    container: '.carouselSlider',
    items: 1,
    slideBy: 'page',
    mouseDrag: true,
    swipeAngle: false,
    loop: true,
    speed: 400,
  });

  var counter = 0;
  var width = 1;

  const prev = document.querySelector('.esios-slider__prev-button');
  const next = document.querySelector('.esios-slider__next-button');

  prev.addEventListener('click', () => {
    homeSlider.goTo('prev');
    width = 1;
  });

  next.addEventListener('click', () => {
    homeSlider.goTo('next');
    width = 1;
  });
  var desktopBar = document.querySelector('.esios-slider__progress-desktopBar');
  var desktopBarWrapper = document.querySelector(
    '.esios-slider__progress-desktopBar-wrapper'
  );
  var mobileBar = document.querySelector('.esios-slider__progress-mobileBar');
  desktopBarWrapper.style.left = getDistanceLeft();

  desktopBar.style.width = '100%';
  mobileBar.style.width = '100%';
  if (counter === 0) {
    var desktopBar = document.querySelector(
      '.esios-slider__progress-desktopBar'
    );
    var desktopBarWrapper = document.querySelector(
      '.esios-slider__progress-desktopBar-wrapper'
    );
    var mobileBar = document.querySelector('.esios-slider__progress-mobileBar');
    if (homeSlider && contents.length >= 1) {
      setInterval(progressBar, 70);
    }
    function progressBar() {
      if (width >= 100 && contents.length > 1) {
        homeSlider.goTo('next');
        counter = 0;
        width = 1;
      } else {
        width++;
      }
      desktopBar.style.width = width + '%';
      mobileBar.style.width = width + '%';
    }
  }

  function getDistanceLeft() {
    var deskLeft = desktopBarWrapper.getBoundingClientRect().left;
    var windowWidth = window.innerWidth;
    var distanceLeft = windowWidth - deskLeft;
    var distanceOutside = 5551 - windowWidth;
    var totalDistance = -(distanceLeft + distanceOutside) / 2 + 'px';
    return totalDistance;
  }
};
