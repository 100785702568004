'use strict';
import { api } from '../../../utils/api-interceptor';

let servicesLayoutMain = (() => {
  let init = () => {};

  const getNews = async () => {
    let news = await api('news?temporal=' + Math.floor(Math.random() * 1000), {
      hasLang: true,
    });
    return news.contents;
  };

  return {
    init: init,
    getNews: () => getNews(),
  };
})();

export { servicesLayoutMain };
