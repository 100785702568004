'use strict';
import 'regenerator-runtime/runtime';
import { api } from '../../utils/api-interceptor';
import i18n from 'i18next';

let serviceshabilitado = (() => {
  let init = (e) => llamarServicio(e);
  const llamarServicio = async (e) => {
    let data = await api(
      'static_pages?slug=participantes-habilitados-en-subastas-de-capacidad&temporal=' +
        Math.floor(Math.random() * 1000),
      { hasLang: true }
    );
    return data.content;
  };

  // Obteniendo datos para la tabla

  const getDataTable = async (params) => {
    let dataT = await api(
      `archives/84/download_json?locale=${i18n.t('routes.lng')}`
    );
    return dataT.ParticipantesSubasta;
  };

  return {
    init: (e) => init(e),
    getDataTable: (params) => getDataTable(params),
  };
})();

export { serviceshabilitado };
