'use strict';
import { html } from 'lit-html';

let templateSimpleTable = () => {
  const columns = [
    {
      title: 'Documento',
      dataIndex: 'name',
      defaultSortOrder: 'desc',
      sortDirections: ['asc', 'desc'],
    },
    {
      title: 'Ámbito',
      dataIndex: 'taxonomy_terms',
      defaultSortOrder: 'desc',
      sortDirections: ['asc', 'desc'],
    },
    {
      title: 'Área',
      dataIndex: 'taxonomy_terms',
      defaultSortOrder: 'desc',
      sortDirections: ['asc', 'desc'],
    },
    {
      title: 'Fecha de datos',
      dataIndex: 'date_times',
      defaultSortOrder: 'desc',
      sortDirections: ['asc', 'desc'],
    },
    {
      title: 'Publicado',
      dataIndex: 'publication_date',
      defaultSortOrder: 'desc',
      sortDirections: ['asc', 'desc'],
    },
  ];

  return html`
    <table class="esios-table">
      <thead class="esios-table__head">
        <tr class="esios-table__row">
          ${columns.map(
            (columnItem) => html`<th @click=${() => sortData(columnItem)}>
              <div class="esios-table__cell">
                <p>${columnItem.title}</p>
                <div class="esios-table__cell--sort">
                  <img class="sort__image" src=${upArrow} alt="up arrow" />
                  <img class="sort__image" src=${downArrow} alt="down arrow" />
                </div>
              </div>
            </th>`
          )}
        </tr>
      </thead>
      <tbody>
        ${sortedData.map((dataItem) => {
          return html`<tr class="esios-table__row">
            ${columns.map((columnItem) => {
              if (columnItem.render) {
                return html`<td>
                  ${columnItem.render(
                    dataItem[columnItem.dataIndex],
                    columnItem
                  )}
                </td>`;
              } else {
                return html`<td>${dataItem[columnItem.dataIndex]}</td>`;
              }
            })}
          </tr>`;
        })}
      </tbody>
    </table>
  `;
};

export { templateSimpleTable };
