'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';
import backImage from '../../assets/images/header-unidades-programacion.jpg';
import { componentLayout } from '../../components/layout/layout.component';
import { templateBoxMarket } from '../../shared/components/box-market/box-market.template';
import { templateBoxMarketColumn } from '../../shared/components/box-market-column/box-market-column.template';

let templateUnidadesProgramacion = (
  context,
  handlers,
  listDocumen,
  select,
  paginationValues,
  pagination,
  filterForm,
  searchForm,
  listMarket,
  lang
) => {
  const {
    onTotalChange,
    onPageChange,
    onHide,
    onQueryChange,
    onSearch,
    onSelectChange,
  } = handlers;
  const id = select?.map((item) => item.id);
  const label = select?.map((item) => item.label);
  const options = select?.map((item) => item.options);
  const selectElements = [
    {
      value: 'tipoproduccion',
      label: 'production_type',
    },
    {
      value: 'negocio',
      label: 'trade',
    },
    {
      value: 'zonaregulacion',
      label: 'regulation_zone',
    },
    {
      value: 'sujetomercado',
      label: 'market_subject',
    },
    {
      value: 'tipoup',
      label: 'UP-type',
    },
  ];
  const headerOfFruit = (value, property) =>
    html`<div style="color: red;">${value}</div>`;
  const bodyOfFruit = (value, property) =>
    html`<div style="color: red;">${value}</div>`;
  const sort = (value, property) => html`
    <ld-header-with-sort
      language="en"
      direction="desc"
      data-property="${property}"
    >
      ${value}
    </ld-header-with-sort>
  `;

  return componentLayout.render(
    html`
      <head>
        <link
          href="https://unpkg.com/boxicons@2.0.9/css/boxicons.min.css"
          rel="stylesheet"
        />
      </head>

      <div class="esios-unidadesprogramacion">
        <h1 class="esios-tabla__title">${i18n.t('programming_units')}</h1>
        <p>${i18n.t('list_of_programming')}</p>
        <!--filtros con su onchange-->
        <div id="print-list"></div>
        <div class="esios-unidadesprogramacion__content row">
          <div class="esios-unidadesprogramacion__selectors hidden">
            <div class="esios-unidadesprogramacion__wrap col-2">
              <input
                class="esios-unidadesprogramacion__tabla__input"
                placeholder="${i18n.t('go_search')}"
                type="text"
                @keyup="${onQueryChange}"
              />
              <button
                class="esios-unidadesprogramacion__button-remove hidden"
                @click="${onHide}"
                )
              >
                &nbsp
              </button>
            </div>

            ${selectElements.map(
              (select, index) =>
                html`<div
                  class="init-dropdown esios-unidadesprogramacion__wrap esios-unidadesprogramacion__dropdown"
                >
                  <select
                    class="esios-unidadesprogramacion__tabla--dropdown2 ree-select-${select.value}"
                    value="${filterForm[select.value]}"
                    @change="${onSelectChange(index)}"
                  >
                    <option class="option" value="" selected disabled hidden>
                      ${i18n.t(select.label)}
                    </option>
                    ${options[index]?.map(
                      (item) =>
                        item.label !== '' &&
                        html` <option class="option" value="${item.value}">
                          ${i18n.t(item.label)}
                        </option>`
                    )}
                  </select>
                </div>`
            )}
          </div>

          <div class="esios-tablaUnidadesProgramacion">
            <div id="spinner1" class="spinner-container">
              <div class="spinner"></div>
            </div>
            <div id="download-table"></div>

            <div id="pagination" class="esios-pagination">
              <div class="esios-pagination__results hidden">
                <span class="esios-pagination__txt esios-pagination__arrow">
                  ${i18n.t('showing')}
                  <select
                    class="esios-pagination__select"
                    value="${pagination?.pagination}"
                    @change="${onTotalChange}"
                  >
                    ${paginationValues?.map(
                      (item) => html`<option value="${item}">${item}</option>`
                    )}
                  </select>
                </span>
                <span class="esios-pagination__txt"
                  >${i18n.t('text_results')} ${pagination?.total}</span
                >
              </div>
              ${pagination.totalPages > 0
                ? html`<div
                    id="paginator-progra"
                    class="esios-pagination__paginator"
                  ></div>`
                : ''}
            </div>
          </div>
        </div>
        <div id="unidad-prog-buttons" class="esios-smercado__buttons"></div>
      </div>
    `,
    {
      image: backImage,
    },
    html``,
    {
      title: `${i18n.t('more')}`,
      subcontent: html`
        <div class="esios-documents">
          <div class="grid-xs-12 documents-container-download">
            <div class="mod-documents-download">
              ${templateBoxMarketColumn(listMarket)}
              <div class="mod-buttons">
                ${lang == 'es'
                  ? html` <a
                      class="mod-buttons__btn"
                      href="https://www.ree.es/es/clientes "
                      target="_blank"
                      >${i18n.t('gde_website_text')}</a
                    >`
                  : ''}
              </div>
            </div>
          </div>
        </div>
      `,
    }
  );
};

export { templateUnidadesProgramacion };
