import { render } from 'lit-html';
import { store } from '../../app.init.js';
import templateBanner from './banner.template.js';

let componentBanner = (function () {
  let context = {};
  let handlers = {};
  let renderSuccess = false;

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let renderComponent = async (pathname, showBanner, text, image) => {
    const state = store.getState();
    try {
      // Render the template to the document
      render(
        // Define a template
        templateBanner(pathname, showBanner, text, image),
        document.querySelector('#esios-layout-banner')
      );
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = (pathname, showBanner, text, image) => {
    //Call pre render component

    preRenderComponent();

    // Call render component
    renderComponent(pathname, showBanner, text, image);

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: (pathname, showBanner, text, image) =>
      init(pathname, showBanner, text, image),
  };
})();

export { componentBanner };
