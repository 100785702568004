'use strict';

import { html, render } from 'lit-html';
import ServicesDocument from './document.services.js';
import i18n from 'i18next';
import { getSplittedRoute } from '../../utils/url.js';
import imgDocument from '../../assets/images/header-consumo.jpg';
import { BreadCrumbs } from '../../shared/components/breadcrumbs/breadcrumbs.template';
import Banner from '../../shared/components/banner/banner';
import { sRender } from '../../utils/render';

let Document = () => {
  ServicesDocument()
    .getDataOfFile()
    .then(({ contents }) => {
      const titleToSearch = getSplittedRoute().pop();
      let { title, media } = contents.find(({ slug }) => slug == titleToSearch);
      document.title = `${title} | ${i18n.t('page_subtitle')}`;
      render(
        html`
          <h1 class="esios-document__title">${title}</h1>
          <div class="esios-document__container-button">
            ${media[0].media_type == 'Link'
              ? html`<a
                  href="${media[0].embed_path}"
                  class="esios-btn esios-btn--document esios-btn__icon"
                  >${i18n.t('open_link')}</a
                >`
              : html` <a
                  href="${ENV.API_URL + media[0].download}"
                  download
                  class="esios-btn esios-btn--document esios-btn__icon esios-btn__icon--download"
                  >${i18n.t('download_document')}</a
                >`}
          </div>
        `,
        document.getElementById('doc-view')
      );
      sRender(BreadCrumbs, 'breadcrumb', {}, {}, titleToSearch, title);
    });

  return html`
    ${Banner(imgDocument)}
    <div class="esios-layout__main esios-layout__main--documents">
      <div id="breadcrumb"></div>
      <div class="esios-document__container">
        <div id="doc-view" class="esios-document__content"></div>
      </div>
    </div>
  `;
};

export default Document;
