import i18n from 'i18next';
import { html } from 'lit-html';
import { store } from '../../../app.init';
import Modal from '../../../shared/components/modal/modal';
import Select from '../../../shared/components/selects/select';
import { setAnalysis } from '../../../state/actions';
import { api } from '../../../utils/api-interceptor';
import { isEmpty, pushNew, removeRepeated } from '../../../utils/core';
import { sRender } from '../../../utils/render';
import { getState } from '../../../utils/state';
import {
  getSplittedRoute,
  getUrlParams,
  setUrlParam,
} from '../../../utils/url';
import {
  sortAlphaNumeric,
  sortAlphaNumericForCompare,
} from '../chart/analysis-summary';
import { getCompareModel, sort, UNIVERSE_ID } from './analysis-form.service';

let compare;
let selectedValues = [];
const AnalysisFormCompare = () => {
  const breakdown = i18n.t('breakdown');
  const nps = i18n.t('snp_indicators');
  const others = i18n.t('others');
  const losses = i18n.t('loss');
  const consLabel = i18n.t('consumption');
  const genLabel = i18n.t('generation');
  const exchaLabel = i18n.t('exchanges');
  const relevantLabel = i18n.t('relevant_indicators');
  // Getting global common state
  let {
    currentLang,
    analysis: {
      indicator: { universe },
      tabSelected: { label },
    },
  } = getState();
  // This is the way to get the ID
  const id = getSplittedRoute().pop();
  let groups = [];
  let indicatorGroups = [];
  // isIndicator tells whether there is no groups and only indicators
  // example: 847
  if (label !== 'comparative') return;
  if (isEmpty(compare)) {
    api(`compare-groups/${id}?locale=${currentLang}`).then(({ indicator }) => {
      compare = indicator;
      sRender(AnalysisFormCompare, 'analysis-form-compare');
    });
  }
  let isIndicator = false;
  let compareModel;
  if (universe && compare) {
    compareModel = getCompareModel(compare, universe);
    if (Array.isArray(compareModel)) {
      isIndicator = true;
      groups = [...compareModel];
    } else {
      groups = getGroups(compareModel);
    }
    doUrlIndicatorsLogic();

    if (selectedValues.length === 0) {
      selectedValues.push({});
    }

    return html`
      <div class="analysis-compare esios-select">
        <h3 class="analysis-compare__title">${i18n.t('compare')}</h3>
        <div id="selected-compare-indicators">${CVIterator()}</div>
        <div id="compare-magnitudes-modal"></div>
      </div>
    `;
  } else {
    return html`<div class="analysis-compare">
      <h3 class="analysis-compare__title">${i18n.t('compare')}</h3>
    </div>`;
  }
  function doUrlIndicatorsLogic() {
    const { compare_indicators } = getUrlParams();
    if (!compare_indicators || selectedValues.length !== 0) return;
    const compareIndicators = compare_indicators
      .split(',')
      .map((i) => Number(i));
    const breakdownModel =
      compareModel[i18n.t('breakdown')] == 'null' ||
      compareModel[i18n.t('breakdown')] == null
        ? []
        : compareModel[i18n.t('breakdown')];
    if (isIndicator) {
      selectedValues = groups.filter((i) => compareIndicators.includes(i.id));
      selectedValues.push({});
      store.dispatch(setAnalysis({ indicators: selectedValues }));
    } else if (
      breakdownModel &&
      breakdownModel?.some((i) => i?.id === compareIndicators[0]) &&
      breakdownModel?.length === compareIndicators.length
    ) {
      const breakdownLabel = i18n.t('breakdown');
      selectedValues = [{ label: breakdownLabel }];
      store.dispatch(
        setAnalysis({
          indicators: ['breakdown', ...compareModel[breakdownLabel]],
          isBreakdown: true,
        })
      );
    } else {
      const allIndicators = [...compare.indicadores_de_universo];
      if (compare.indicadores_snp) {
        allIndicators.push(...compare.indicadores_snp);
      }
      const selectedIndicators = allIndicators.filter((i) =>
        compareIndicators.includes(i.id)
      );

      // Breakdown and nps are not included here
      // Only for losses, normal grps/subgrps
      const interModel = getCompareModel(
        {
          indicadores_de_universo: selectedIndicators,
          extrasnp: compare.indicadores_snp,
        },
        universe
      );
      for (let indiModel of Object.keys(interModel)) {
        if (!isEmpty(interModel[indiModel])) {
          const selectedSubgroupValues = [];
          const subgroups = Object.keys(interModel[indiModel]);
          for (let subgroup of subgroups) {
            let subgroups = interModel[indiModel][subgroup];
            if (!Array.isArray(subgroups)) {
              subgroups = [subgroups];
            }
            const obj = {
              label: subgroup,
              name: subgroups[subgroup]?.name,
              selectedIndicators: [...subgroups, {}],
            };
            selectedSubgroupValues.push(obj);
          }
          selectedSubgroupValues.push({});
          const obj = {
            label: indiModel,
            selectedSubgroupValues,
            subgroups: compareModel[indiModel],
          };
          selectedValues.push(obj);
        }
      }
      selectedValues.push({});
      // IDK but we have to do this in order to see properly all.
      // Some asyncrhronous task is running and it is difficult get it.
      store.dispatch(setAnalysis({ indicators: selectedIndicators }));
    }
  }

  function CVIterator() {
    let component = Group;
    if (isIndicator) {
      component = ValueIndicator;
      indicatorGroups = [...groups];
      indicatorGroups = indicatorGroups.filter(
        (i) => !selectedValues.some((j) => j.name === i.name)
      );
    }

    let isSNP = universe[0].id == UNIVERSE_ID.NPS;
    // || universe[0].id == UNIVERSE_ID.DEMAND_MANAGEMENT;

    if (isSNP && selectedValues.length > 1) {
      selectedValues.pop();
    }
    // !CHECK: for gest
    selectedValues = selectedValues.map((el) => {
      if (el.name) {
        return {
          label: el.name,
          subgroups: el,
        };
      } else {
        return el;
      }
    });
    selectedValues = selectedValues.filter((el) => el.label != undefined);
    if (selectedValues.length == 0) {
      selectedValues.push({});
    } else {
      let labelArr = selectedValues.filter((el) => el.label == breakdown);

      if (labelArr.length == 0) {
        selectedValues.push({});
      }
    }
    return html`${selectedValues.map(component)}`;
  }

  function Group({ label, selectedSubgroupValues } = {}, iGroup) {
    let state = getState();
    let isSNP =
      selectedValues[iGroup].label == i18n.t('snp_indicators') ||
      selectedValues[iGroup].label == i18n.t('loss');
    let isLoss = selectedValues[iGroup].label == i18n.t('loss');

    // TODO: VALIDATE FOR LOSS
    let isDMAN = universe[0].id == UNIVERSE_ID.DEMAND_MANAGEMENT;
    let isGEN = universe[0].id == UNIVERSE_ID.GEN_AND_CON;
    let isMYP = universe[0].id == UNIVERSE_ID.MARKETS_AND_PRICES;
    // replicar esto para eliminar seleccionado
    let selectedIds = selectedValues;
    let hiddenVals = selectedIds
      .map((el) => {
        return el?.label || el?.name;
      })
      .filter((el) => el !== undefined);
    //Pasar como atributo al select
    if (isGEN || isMYP) {
      const order = [...groups].filter(
        (el) => el !== others && el !== nps && el !== relevantLabel
      );
      let tempGroups = [...sortAlphaNumericForCompare(order, 'ASC')];
      if (groups.includes(relevantLabel)) {
        tempGroups.push(relevantLabel);
      }
      if (groups.includes(nps)) {
        tempGroups.push(nps);
      }
      if (groups.includes(others)) {
        tempGroups.push(others);
      }
      groups = tempGroups;
    }
    // TODO: ONLY FOR COMPOSITED SELECTS INDICATOR
    if (isDMAN) {
      groups = groups.filter((el) => el !== state?.analysis?.indicator?.name);
    }
    if (state.analysis.indicator.composited) {
      let tempGroups = [...groups].filter((el) => el !== breakdown);
      if (groups.includes(breakdown)) {
        tempGroups.unshift(breakdown);
      }
      groups = tempGroups;
    }

    return html`<div class="esios-analysis__compare-group">
      ${isDMAN
        ? Select({
            placeholder:
              '--- ' +
              (isDMAN ? i18n.t('pick_indicator') : i18n.t('pick_group')) +
              ' ---',
            values: groups,
            selected: label,
            hidden: hiddenVals,
            onClick: handleGroupGDM(iGroup),
            onDelete: deleteGroupGDM(iGroup),
            deletable: true,
          })
        : Select({
            placeholder: '--- ' + i18n.t('pick_group') + ' ---',
            values: groups,
            selected: label,
            hidden: hiddenVals,
            onClick: handleGroup(iGroup),
            onDelete: deleteGroup(iGroup),
            deletable: true,
          })}
      ${selectedSubgroupValues && !isDMAN
        ? html`<div
            class="esios-analysis__compare-subgroup  ${isSNP || isLoss
              ? 'primary'
              : ''}"
          >
            ${selectedSubgroupValues.map(SubGroup(iGroup))}
          </div>`
        : ''}
    </div>`;
  }

  function SubGroup(iGroup) {
    let isSNP =
      selectedValues[iGroup].label == i18n.t('snp_indicators') ||
      selectedValues[iGroup].label == i18n.t('loss') ||
      selectedValues[iGroup].label == i18n.t('relevant_indicators');
    let isLoss = selectedValues[iGroup].label == i18n.t('loss');
    let subGroupList = isSNP
      ? selectedValues[iGroup].subgroups
      : Object.keys(selectedValues[iGroup].subgroups).sort(sort);

    subGroupList = isSNP
      ? Object.values(selectedValues[iGroup].subgroups).sort(sort)
      : subGroupList;

    let selectedIds = selectedValues[iGroup].selectedSubgroupValues;

    let hiddenVals = selectedIds
      .map((el) => {
        return el.label;
      })
      .filter((el) => el !== undefined);

    return (value, iSubGroup) => {
      const selectedIndicators =
        selectedValues[iGroup].selectedSubgroupValues[iSubGroup]
          .selectedIndicators;
      let isGEN = universe[0].id == UNIVERSE_ID.GEN_AND_CON;
      let isMYP = universe[0].id == UNIVERSE_ID.MARKETS_AND_PRICES;

      if (selectedIndicators && isSNP) {
        value = selectedIndicators[0];
      }
      let snpOpts;
      let falseCount = 0;
      if (isSNP) {
        let state = getState();
        let selectedIds = selectedValues[iGroup].selectedSubgroupValues
          .map((el) => {
            if (el?.selectedIndicators) {
              return el?.selectedIndicators[0];
            }
          })
          .filter((el) => el != undefined);

        if (selectedIds.length == 0) {
          selectedIds = state.analysis?.indicators || [];
        }
        let foundArr = selectedIds.map((el) => {
          return el.id;
        });
        let newValues = subGroupList.map((el) => {
          let foundElem = foundArr.find((fel) => fel === el.id);
          foundElem = foundElem ? false : true;
          if (!foundElem) {
            falseCount++;
          }
          return {
            ...el,
            custom: true,
            show: foundElem,
          };
        });
        newValues = newValues.length == 0 ? subGroupList : newValues;

        newValues = sortAlphaNumericForCompare(newValues, 'ASC', 'name');
        snpOpts = {
          placeholder: '--- ' + i18n.t('pick_indicator') + ' ---',
          values: newValues,
          selected: value,
          label: 'name',
          onClick: handleGSIndicatorSNP(iGroup, iSubGroup, iSubGroup),
          onDelete: deleteSNPSubgroup(iGroup, iSubGroup),
          deletable: true,
        };
      } else {
        let orderTemp = [];
        let orderTempLimit = [];
        if (isGEN) {
          compare['indicadores_de_universo'].map((el) => {
            el.terms?.find((i) => {
              if (subGroupList.includes(i.name)) {
                if (!orderTempLimit.includes(i.id)) {
                  orderTemp.push(i);
                  orderTempLimit.push(i.id);
                }
              }
            });
          });

          let tempOrder = sortAlphaNumericForCompare(
            orderTemp,
            'ASC',
            'name'
          ).map((el) => el.name);
          if (selectedValues[iGroup].label == others) {
            subGroupList = orderSelects(subGroupList, [...tempOrder, others]);
          } else {
            subGroupList = tempOrder;
          }
        }
        if (isMYP) {
          compare['indicadores_de_universo'].map((el) => {
            el.terms?.find((i) => {
              if (subGroupList.includes(i.name)) {
                if (!orderTempLimit.includes(i.id)) {
                  orderTemp.push(i);
                  orderTempLimit.push(i.id);
                }
              }
            });
          });
          let tempOrder = sortAlphaNumeric(orderTemp, 'ASC', 'name').map(
            (el) => el.name
          );
          subGroupList = tempOrder;
        }
        snpOpts = {
          placeholder: '--- ' + i18n.t('pick_subgroup') + ' ---',
          values: subGroupList,
          selected: value,
          hidden: hiddenVals,
          onClick: handleSubGroup(iGroup, iSubGroup),
          onDelete: deleteSubgroup(iGroup, iSubGroup),
          deletable: true,
        };
      }
      let showGSIndicator = !isSNP;
      return html`${isSNP && subGroupList.length == falseCount && !value?.id
        ? ''
        : Select(snpOpts)}
      ${showGSIndicator
        ? html`<div class="esios-analysis__compare-indicators">
            ${selectedIndicators &&
            selectedIndicators.map(GSIndicator(iGroup, iSubGroup))}
          </div>`
        : ``}`;
    };
  }

  function deleteIndicator(elem) {
    const {
      analysis: { indicators: selectedValues },
    } = getState();
    const deleteIndex = selectedValues.findIndex((i) => elem.label === i.label);
    selectedValues.splice(deleteIndex, 1);
    setUrlParam(
      'compare_indicators',
      selectedValues.map((i) => i.id).join(',')
    );
    store.dispatch(setAnalysis({ indicators: selectedValues }));
  }

  function deleteGSIndicator(elem) {
    const { analysis } = getState();

    for (let i = 0; i < selectedValues.length; i++) {
      const group = selectedValues[i];
      for (let j = 0; i < group.selectedSubgroupValues.length; j++) {
        const subgroup = group.selectedSubgroupValues[j];
        if (subgroup.selectedIndicators) {
          for (let k = 0; k < subgroup.selectedIndicators.length; k++) {
            if (elem.id === subgroup.selectedIndicators[k].id) {
              subgroup.selectedIndicators.splice(k, 1);
              const index = analysis.indicators.findIndex(
                (i) => i.id === elem.id
              );
              const newIndicators = [...analysis.indicators];
              newIndicators.splice(index, 1);
              setUrlParam(
                'compare_indicators',
                newIndicators.map((i) => i.id).join(',')
              );

              store.dispatch(
                setAnalysis({
                  indicators: newIndicators,
                  compare_indicators: `${getUrlParams()?.compare_indicators}`,
                })
              );

              break;
            }
          }
        }
      }
    }
  }

  function deleteGroup(iGroup) {
    return ({ label }) => {
      const { analysis } = getState();
      let newIndicators = [...analysis.indicators];
      if (
        label === i18n.t('breakdown') ||
        (label === i18n.t('snp_indicators') &&
          analysis.indicator.universe[0].id == UNIVERSE_ID.DEMAND_MANAGEMENT) ||
        (label === i18n.t('loss') &&
          analysis.indicator.universe[0].id == UNIVERSE_ID.DEMAND_MANAGEMENT)
      ) {
        newIndicators = [];
      } else {
        for (let subgroup of selectedValues[iGroup].selectedSubgroupValues) {
          if (subgroup.selectedIndicators) {
            for (let indicator of subgroup.selectedIndicators) {
              if (indicator.id) {
                const index = newIndicators.findIndex(
                  (i) => i.id === indicator.id
                );
                newIndicators.splice(index, 1);
              }
            }
          }
        }
      }
      selectedValues.splice(iGroup, 1);

      let temIndicators = [];
      selectedValues.forEach((el) => {
        el?.selectedSubgroupValues?.map((elem) => {
          elem?.selectedIndicators
            ?.filter((val) => val?.id != undefined)
            .map((elemento) => {
              temIndicators.push(elemento);
            });
        });
      });

      if (label === i18n.t('loss') || label === i18n.t('snp_indicators')) {
        newIndicators = temIndicators;
      }

      setUrlParam(
        'compare_indicators',
        newIndicators.map((i) => i.id).join(',')
      );

      store.dispatch(
        setAnalysis({
          indicators: newIndicators,
          compare_indicators: `${getUrlParams()?.compare_indicators}`,
          isBreakdown: false,
        })
      );
    };
  }

  function deleteGroupGDM(iGroup) {
    const { analysis } = getState();
    return ({ label }) => {
      let newIndicators = [...analysis.indicators];
      if (
        label === i18n.t('breakdown') ||
        label === i18n.t('snp_indicators') ||
        label === i18n.t('loss')
      ) {
        selectedValues = [];
      } else {
        let tempArrForDelete = selectedValues.filter(
          (el) => el.label != undefined
        );
        selectedValues = tempArrForDelete.map((el) => {
          return {
            ...el,
            id: el.subgroups.id,
          };
        });
        selectedValues.splice(iGroup, 1);
      }
      let idsList = selectedValues.map((i) => i.id).join(',');
      let finalArr = newIndicators.filter((el) => {
        if (idsList.includes(el.id)) {
          return el;
        }
      });
      setUrlParam('compare_indicators', finalArr.map((i) => i.id).join(','));
      store.dispatch(
        setAnalysis({
          indicators: finalArr,
          compare_indicators: idsList,
          isBreakdown: false,
        })
      );
    };
  }

  function deleteSNPSubgroup(iGroup, iSubGroup) {
    return (element) => {
      const { analysis } = getState();
      let newIndicators = [...analysis.indicators];
      let subgroups = selectedValues[iGroup].selectedSubgroupValues.filter(
        (el) => el?.id != undefined
      );
      for (let indicator of subgroups) {
        const index = newIndicators.findIndex((i) => i.id === indicator?.id);
        if (index !== -1) {
          newIndicators.splice(index, 1);
        }
      }
      selectedValues[iGroup].selectedSubgroupValues.splice(iSubGroup, 1);
      setUrlParam(
        'compare_indicators',
        newIndicators.map((i) => i.id).join(',')
      );
      if (universe[0].id == UNIVERSE_ID.DEMAND_MANAGEMENT) {
        if (subgroups.length > 0) {
          subgroups.splice(iSubGroup, 1);
          newIndicators = subgroups;
        } else {
          newIndicators.splice(iSubGroup, 1);
        }
        setUrlParam(
          'compare_indicators',
          newIndicators.map((i) => i.id).join(',')
        );
      } else if (universe[0].id == UNIVERSE_ID.NPS) {
        if (subgroups.length > 0) {
          subgroups.splice(iSubGroup, 1);
          newIndicators = subgroups;
        } else {
          newIndicators.splice(iSubGroup, 1);
        }
        setUrlParam(
          'compare_indicators',
          newIndicators.map((i) => i.id).join(',')
        );
      } else {
        if (subgroups.length > 0) {
          // subgroups.splice(iSubGroup, 1);
          // newIndicators = subgroups;
        } else {
          newIndicators.splice(iSubGroup, 1);
        }
        setUrlParam(
          'compare_indicators',
          newIndicators.map((i) => i.id).join(',')
        );
      }

      store.dispatch(
        setAnalysis({
          compare_indicators: newIndicators.map((i) => i.id).join(','),
          indicators: newIndicators,
        })
      );
    };
  }

  function deleteSubgroup(iGroup, iSubGroup) {
    return () => {
      const { analysis } = getState();
      const newIndicators = [...analysis.indicators];
      const subgroups = selectedValues[iGroup].selectedSubgroupValues;
      for (let indicator of subgroups[iSubGroup].selectedIndicators) {
        const index = newIndicators.findIndex((i) => i.id === indicator?.id);
        if (index !== -1) {
          newIndicators.splice(index, 1);
        }
      }
      selectedValues[iGroup].selectedSubgroupValues.splice(iSubGroup, 1);
      setUrlParam(
        'compare_indicators',
        newIndicators.map((i) => i.id).join(',')
      );
      store.dispatch(setAnalysis({ indicators: newIndicators }));
    };
  }

  function GSIndicator(iGroup, iSubGroup) {
    return (elem, index) => {
      const subGroup = selectedValues[iGroup].selectedSubgroupValues[iSubGroup];
      let isSNP =
        selectedValues[iGroup].label == i18n.t('snp_indicators') ||
        selectedValues[iGroup].label == i18n.t('loss');

      let isGEN = universe[0].id == UNIVERSE_ID.GEN_AND_CON;
      let values = isSNP
        ? selectedValues[iGroup].subgroups[subGroup.label]
        : [...selectedValues[iGroup].subgroups[subGroup.label]];
      let selectedIds = subGroup.selectedIndicators;

      let foundArr = selectedIds.map((el) => {
        return el.id;
      });
      let falseCount = 0;
      let newValues = values.map((el) => {
        let foundElem = foundArr.find((fel) => fel === el.id);
        foundElem = foundElem ? false : true;
        if (!foundElem) {
          falseCount++;
        }
        return {
          ...el,
          custom: true,
          show: foundElem,
        };
      });
      newValues = newValues.length == 0 ? values : newValues;
      if (!Array.isArray(values)) {
        values = [values];
      }
      const selected = elem;

      newValues = sortAlphaNumericForCompare(newValues, 'ASC', 'name');

      return html`${values.length == falseCount && !elem?.id
        ? ''
        : Select({
            placeholder: i18n.t('pick_indicator'),
            values: newValues,
            label: 'name',
            selected,
            onClick: handleGSIndicator(iGroup, iSubGroup, index),
            onDelete: deleteGSIndicator,
            deletable: true,
          })}`;
    };
  }

  function handleGroup(iGroup) {
    const { analysis } = getState();
    let tempIndicators = [];
    return (elem) => {
      if (elem.label === i18n.t('breakdown')) {
        selectedValues = [elem];
        const breakdownList = compareModel[elem.label];
        const idList = breakdownList.map((i) => i.id).join(',');
        setUrlParam('compare_indicators', idList);
        store.dispatch(
          setAnalysis({
            indicators: ['breakdown', ...compareModel[elem.label]],
            isBreakdown: true,
          })
        );
      } else {
        elem.selectedSubgroupValues = [{}];
        elem.subgroups = compareModel[elem.label];
        selectedValues[iGroup] = elem;
        selectedValues.forEach((val) => {
          val?.selectedSubgroupValues?.map((el) => {
            if (el?.selectedIndicators) {
              let tempArr = el?.selectedIndicators.filter(
                (elem) => elem.id != undefined
              );

              if (tempArr.length > 0) {
                tempArr.map((elems) => {
                  tempIndicators.push(elems);
                });
              }
            }
          });
        });

        let tempIds = tempIndicators
          .map((el) => {
            return el.id;
          })
          .join(',');

        let finalGroups = [...(analysis.indicators || [])].filter(
          (el) => el.id != undefined
        );

        finalGroups = finalGroups.filter((el) => !tempIds.includes(el.id));

        finalGroups = [...(finalGroups || []), ...(tempIndicators || [])];
        tempIds = finalGroups.map((el) => el.id).join(',');
        setUrlParam('compare_indicators', tempIds);
        store.dispatch(
          setAnalysis({
            isBreakdown: false,
            compare_indicators: tempIds,
            indicators: finalGroups,
          })
        );
      }

      sRender(AnalysisFormCompare, 'analysis-form-compare');
    };
  }

  function handleGroupGDM(iGroup) {
    let tempIndicators = [];
    const { analysis } = getState();

    return (elem) => {
      if (elem.label === i18n.t('breakdown')) {
        selectedValues = [elem];
        const breakdownList = compareModel[elem.label];
        const idList = breakdownList.map((i) => i.id).join(',');
        setUrlParam('compare_indicators', idList);
        store.dispatch(
          setAnalysis({
            indicators: ['breakdown', ...compareModel[elem.label]],
            isBreakdown: true,
          })
        );
      } else {
        let analysisArr = analysis.indicators || [];
        if (analysis.isBreakdown) {
          analysisArr = [];
        } else {
          analysisArr = analysis.indicators || [];
        }
        selectedValues[iGroup] = compareModel[elem.label];
        // selectedValues.forEach((val) => {
        //   return val;
        // });
        elem = compareModel[elem.label];

        let tempArr = analysisArr;
        let itExists = tempArr.find((el) => el.id == elem.id);

        let finalIndicators = [...analysisArr];

        if (!itExists) {
          finalIndicators.push(elem);
        }

        let storeIndicators = [...(finalIndicators || []), {}];

        storeIndicators = storeIndicators.filter((el) => el?.id != undefined);
        storeIndicators.push({});
        const ids = storeIndicators.map((i) => i.id).join(',');
        setUrlParam('compare_indicators', ids);
        store.dispatch(
          setAnalysis({
            isBreakdown: false,
            compare_indicators: ids,
            indicators: storeIndicators,
          })
        );
      }

      sRender(AnalysisFormCompare, 'analysis-form-compare');
    };
  }
  function handleSubGroup(iGroup, iSubGroup) {
    const { analysis } = getState();
    return (elem) => {
      elem.selectedIndicators = elem.selectedIndicators || [{}];
      const subGroupName =
        selectedValues[iGroup].selectedSubgroupValues[iSubGroup].label;
      elem.indicators = selectedValues[iGroup].subgroups[subGroupName];
      selectedValues[iGroup].selectedSubgroupValues[iSubGroup] = elem;

      let tempIndicators = [];
      selectedValues.forEach((val) => {
        val?.selectedSubgroupValues?.map((el) => {
          if (el?.selectedIndicators) {
            let tempArr = el?.selectedIndicators.filter(
              (elem) => elem.id != undefined
            );
            if (tempArr.length > 0) {
              tempArr.map((elems) => {
                tempIndicators.push(elems);
              });
            }
          }
        });
      });
      let tempIds = tempIndicators.map((el) => el.id).join(',');

      let finalGroups = [...(analysis.indicators || [])].filter(
        (el) => el.id != undefined
      );

      finalGroups = finalGroups.filter((el) => !tempIds.includes(el.id));

      finalGroups = [...(finalGroups || []), ...(tempIndicators || [])];
      tempIds = finalGroups.map((el) => el.id).join(',');

      setUrlParam('compare_indicators', tempIds);
      store.dispatch(
        setAnalysis({
          compare_indicators: tempIds,
          indicators: finalGroups,
        })
      );
      sRender(AnalysisFormCompare, 'analysis-form-compare');
    };
  }

  function handleGSIndicatorSNP(iGroup, iSubGroup, index) {
    const { analysis } = getState();

    return (elem) => {
      const stop = checkMagnitudes(elem);
      if (stop) {
        sRender(AnalysisFormCompare, 'analysis-form-compare');
        sRender(
          Modal,
          'compare-magnitudes-modal',
          i18n.t('warning'),
          i18n.t('warning_units')
        );
        return;
      }

      let isSNP = universe[0].id == UNIVERSE_ID.NPS;
      if (isSNP) {
        selectedValues[0].selectedSubgroupValues =
          selectedValues[0].selectedSubgroupValues.map((el) => {
            if (el.label) {
              return el.selectedIndicators[0];
            }
            return el;
          });
      }
      let indicators = selectedValues[iGroup].selectedSubgroupValues;
      selectedValues[iGroup].selectedSubgroupValues[iSubGroup] = elem;
      let found = indicators.filter((el) => el.id === undefined);
      if (found) {
        indicators = [...(indicators || []), {}];
      }

      selectedValues[iGroup].selectedSubgroupValues = indicators;

      if (!isEmpty(selectedValues[selectedValues.length - 1])) {
        selectedValues.push({});
      }
      if (
        !isEmpty(
          selectedValues[iGroup].selectedSubgroupValues[
            selectedValues[iGroup].selectedSubgroupValues.length - 1
          ]
        )
      ) {
        selectedValues[iGroup].selectedSubgroupValues.push({});
      }
      let tempArr = analysis.indicators || [];
      let itExists = tempArr.find((el) => el.id == elem.id);

      let newTempIndicators = [];
      if (universe[0].id == UNIVERSE_ID.DEMAND_MANAGEMENT) {
        indicators?.map((el) => {
          let found = selectedValues[0].subgroups[el?.name || el?.label] || {};
          newTempIndicators.push(found);
        });
        selectedValues[iGroup].selectedSubgroupValues = newTempIndicators;
        indicators = newTempIndicators;
      }

      let analysisTemporal = (analysis.indicators || []).filter(
        (el) => el.id != undefined
      );
      let tempIds = analysisTemporal.map((i) => i.id).join(',');
      indicators = indicators
        .filter((el) => el.id !== undefined)
        .filter((el) => !tempIds.includes(el.id));
      indicators = [...indicators, ...analysisTemporal, {}];

      let storeIndicators =
        itExists === undefined
          ? [...(indicators || [])]
          : [...(analysis.indicators || [])];

      storeIndicators = storeIndicators.filter(
        (el) => el.id != analysis.indicator.id
      );
      const ids = storeIndicators.map((i) => i.id).join(',');
      setUrlParam('compare_indicators', ids);
      store.dispatch(setAnalysis({ indicators: storeIndicators }));
      sRender(AnalysisFormCompare, 'analysis-form-compare');
    };
  }

  function handleGSIndicator(iGroup, iSubGroup, index) {
    const { analysis } = getState();
    return (elem) => {
      const stop = checkMagnitudes(elem);
      if (stop) {
        sRender(AnalysisFormCompare, 'analysis-form-compare');
        sRender(
          Modal,
          'compare-magnitudes-modal',
          i18n.t('warning'),
          i18n.t('warning_units')
        );
        return;
      }
      let indicators =
        selectedValues[iGroup].selectedSubgroupValues[iSubGroup]
          .selectedIndicators;
      const last = indicators?.pop();

      selectedValues[iGroup].selectedSubgroupValues[
        iSubGroup
      ].selectedIndicators[index] = elem;
      indicators = [...(indicators || []), last];
      selectedValues[iGroup].selectedSubgroupValues[
        iSubGroup
      ].selectedIndicators = indicators;
      if (!isEmpty(selectedValues[selectedValues.length - 1])) {
        selectedValues.push({});
      }
      if (
        !isEmpty(
          selectedValues[iGroup].selectedSubgroupValues[
            selectedValues[iGroup].selectedSubgroupValues.length - 1
          ]
        )
      ) {
        selectedValues[iGroup].selectedSubgroupValues.push({});
      }
      let tempArr = analysis.indicators || [];
      let itExists = tempArr.find((el) => el.id == elem.id);

      let finalIndicators = [...(analysis.indicators || [])];

      if (!itExists) {
        finalIndicators.push(elem);
      }

      const storeIndicators = [...(finalIndicators || [])];
      const ids = storeIndicators.map((i) => i.id).join(',');
      setUrlParam('compare_indicators', ids);
      store.dispatch(setAnalysis({ indicators: storeIndicators }));
      sRender(AnalysisFormCompare, 'analysis-form-compare');
    };
  }

  function ValueIndicator(value, index) {
    let values = [...indicatorGroups];
    if (!isEmpty(value)) {
      values.push(value);
    }
    values = values.sort(sort);
    return html`<div class="esios-analysis__compare-group">
      ${Select({
        placeholder: i18n.t('pick_indicator'),
        values,
        selected: value,
        deletable: true,
        onClick: handleIndicator(index),
        onDelete: deleteIndicator,
      })}
    </div>`;
  }
  function handleIndicator(index) {
    return (elem) => {
      const stop = checkMagnitudes(elem);
      if (stop) {
        sRender(AnalysisFormCompare, 'analysis-form-compare');
        sRender(
          Modal,
          'compare-magnitudes-modal',
          i18n.t('warning'),
          i18n.t('warning_units')
        );

        return;
      }
      const last = selectedValues.pop();
      selectedValues[index] = elem;
      const urlIndicators = selectedValues.map((i) => i.id).join(',');
      setUrlParam('compare_indicators', urlIndicators);
      groups.splice(groups.indexOf(elem), 1);
      store.dispatch(setAnalysis({ indicators: selectedValues }));
      if (selectedValues.length < 6) {
        selectedValues.push(last);
      }
    };
    // sRender(CVIterator, 'selected-compare-indicators');
  }

  function checkMagnitudes(elem) {
    let max_magnitudes = universe[0].id === UNIVERSE_ID.NPS ? 3 : 2;

    const {
      analysis: { indicators, indicator },
    } = getState();
    const noHasInd =
      indicators && indicators.length === 1 && isEmpty(indicators[0]);

    if (noHasInd || !indicators || elem.label === i18n.t('breakdown'))
      return false;
    const mainMagnitude = indicator.magnitud[0].id;
    const currentMagnitude = elem.magnitud[0].id;
    let magnitudes = indicators
      .filter((i) => i.magnitud)
      .map((i) => i.magnitud[0].id);
    pushNew(magnitudes, mainMagnitude);
    pushNew(magnitudes, currentMagnitude);
    magnitudes = removeRepeated(magnitudes);

    let idPotencia = [
      ...magnitudes.filter((magnitude) => {
        return magnitude == '20' || magnitude == '21' ? true : false;
      }),
    ];
    let hasPotencia = idPotencia.length > 1 ? true : false;
    max_magnitudes = hasPotencia ? max_magnitudes + 1 : max_magnitudes;
    return max_magnitudes < magnitudes.length;
  }

  function getGroups(compareModel) {
    return Object.keys(compareModel).sort(sort());
  }
};

export default AnalysisFormCompare;

export const orderSelects = (array, order, key) => {
  if (!key) {
    array.sort(function (a, b) {
      var A = a,
        B = b;
      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });

    return array;
  } else {
    array.sort(function (a, b) {
      var A = a[key],
        B = b[key];
      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });

    return array;
  }
};
