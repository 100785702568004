'use strict';
/**
 * @description Dashboard Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { default as i18n, default as i18next } from 'i18next';
import moment from 'moment-timezone';
import { store } from '../../app.init.js';
import { componentButtons } from '../../shared/components/buttons/buttons.component.js';
import { datepickerComponent } from '../../shared/components/datepicker/datepicker.component.js';
import { componentSelects } from '../../shared/components/selects/selects.component.js';
import { componentTimeSelector } from '../../shared/components/time-selector/time-selector.component.js';
import { Utils } from '../../utils/utils.js';
import { servicesSupplyCurves } from './supply-curves.services.js';
import { templateSupplyCurves } from './supply-curves.template';

let componentSupplyCurves = (function () {
  let context = {};
  let handlers = {};

  let renderSuccess = false;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let dataCsv = [];
  let dataJson = [];
  let newDataJson;
  let newDataCsv;
  let exportName = '';
  const today = moment().tz('Europe/Madrid');
  let buttonsArray;

  const exportNames = [
    {
      id: 29,
      slug: 'secondary-reserve-export',
    },
    {
      id: 30,
      slug: 'replacement-export',
    },
    {
      id: 31,
      slug: 'tertiary-export',
    },
    {
      id: 32,
      slug: 'upwards_reserve_power',
    },
    {
      id: 28,
      slug: 'cross_border_balancing_services-export',
    },
    {
      id: 33,
      slug: 'explicit_auctions-export',
    },
  ];

  // Select group in
  const selectCurveSelection = {
    id: 'select_curve_selection',
    currentLabel: 'Secondary_reserve',
    currentValue: '29',
    label: '',
    options: [
      { label: 'Secondary_reserve', value: '29' },
      { label: 'Replacement_Reserves', value: '30' },
      { label: 'Tertiary_reserve', value: '31' },
      { label: 'upwards_reserve_power', value: '32' },
      {
        label: 'cross_border_balancing_services',
        value: '28',
      },
      { label: 'Explicit_auctions', value: '33' },
    ],
    columns: 1,
    onlySelect: true,
    changeTime: (value) => changeTimeFromSelector(value),
  };

  const selectBorderSelection = {
    id: 'select_border_selection',
    currentLabel: 'fraesp',
    currentValue: 'fraesp',
    label: '',
    options: [
      { label: 'fraesp', value: 'France' },
      { label: 'poresp', value: 'Portugal' },
    ],
    columns: 1,
    onlySelect: true,
    changeTime: (value) => changeTimeFromSelector(value),
  };

  const selectAuctionTypeSelection = {
    id: 'select_auction_type_selection',
    currentLabel: 'intraday',
    currentValue: 'intraday',
    currentComplement: '1',
    label: '',
    options: [
      { label: 'intraday', complement: '1', value: 'intraday1' },
      { label: 'intraday', complement: '2', value: 'intraday2' },
      { label: 'daily', value: 'daily' },
      { label: 'monthly', value: 'monthly' },
      { label: 'yearly', value: 'yearly' },
    ],
    columns: 1,
    onlySelect: true,
    changeTime: (value) => changeTimeFromSelector(value),
  };

  // Select time to move
  const selectForMoveTime = {
    id: 'select_for_move_time',
    label: 'move',
    currentValue: 'hour',
    currentLabel: 'hour',
    currentQuantity: '1',
    options: [
      { quantity: '1', label: 'hour', value: 'hour' },
      { quantity: '1', label: 'day', value: 'day' },
      { quantity: '1', label: 'month', value: 'month' },
      { quantity: '1', label: 'year', value: 'year' },
    ],
    changeTime: (value) => changeTimeFromSelector(value),
  };

  const selectSupplyMonth = {
    id: 'select_supplyMonth',
    currentLabel: 'january',
    currentValue: '0',
    label: '',
    options: Utils.getArrayOfMonths(),
    columns: 1,
    onlySelect: true,
    changeTime: (value) => changeTimeFromSelector(value),
  };

  const selectSupplyQuartely = {
    id: 'select_supplyQuartely',
    currentLabel: 'quarter',
    currentValue: '1',
    currentComplement: '1',
    label: '',
    options: [
      { label: 'quarter', complement: '1', value: '2' },
      { label: 'quarter', complement: '2', value: '5' },
      { label: 'quarter', complement: '3', value: '8' },
      { label: 'quarter', complement: '4', value: '11' },
    ],
    columns: 1,
    onlySelect: true,
    changeTime: (value) => changeTimeFromSelector(value),
  };

  const selectSupplyYear = {
    id: 'select_supply_year',
    currentLabel: '2021',
    currentValue: '2021',
    label: '',
    options: Utils.getArrayOfYears(),
    columns: 1,
    onlySelect: true,
    changeTime: (value) => changeTimeFromSelector(value),
  };

  // select_start
  const selectTime = {
    id: 'hour_select',
    currentHour: '00',
    currentMinutes: '00',
    columns: 1,
    optionsHours: Utils.getArrayOfHours(),
    optionsMinutes: [],
    changeTime: (value) => changeTime(value),
  };

  // Select group in
  const selectDirectionSelection = {
    id: 'select_direction_selection',
    currentLabel: 'up',
    currentValue: 'up',
    label: '',
    options: [
      { label: 'up', value: 'up' },
      { label: 'down', value: 'down' },
    ],
    columns: 1,
    onlySelect: true,
    changeTime: (value) => changeTimeFromSelector(value),
  };
  const selectDirectionSelectionTwo = {
    id: 'select_direction_selection_two',
    currentLabel: 'import',
    currentValue: 'import',
    label: '',
    options: [
      { label: 'import', value: 'import' },
      { label: 'export', value: 'export' },
    ],
    columns: 1,
    onlySelect: true,
    changeTime: (value) => changeTimeFromSelector(value),
  };

  const changeTime = (value) => {
    const { id, newValue } = value;
    let hours = newValue.split(':')[0];
    if (selectTime.id === id) {
      selectTime.optionsHours.map((el) => {
        if (el.value === hours) {
          selectTime.currentHour = el.value;
          componentTimeSelector.render(selectTime, '#hour-selector');
          setParameter(
            'date',
            moment($('#datepicker').val(), 'DD-MM-YYYY')
              .tz('Europe/Madrid')
              .hour(el.value)
              .minutes('00')
              .format('YYYY-MM-DDTHH:mm')
          );
          renderSupplyCurvesAgain();
        }
      });
      return;
    }
  };

  const changeTimeFromSelector = (value) => {
    const { id, newValue } = value;

    if (selectCurveSelection.id === id) {
      selectCurveSelection.options.map((el) => {
        if (el.value === newValue) {
          selectCurveSelection.currentLabel = el.label;
          selectCurveSelection.currentValue = el.value;
          setParamsForId(el.value);
          renderForm(el.value);
          setParameter('id', el.value);

          renderSupplyCurvesAgain();

          componentSelects.render(selectCurveSelection, '#group-in-selector');
        }
      });

      return;
    }
    if (selectBorderSelection.id === id) {
      selectBorderSelection.options.map((el) => {
        if (el.value === newValue) {
          selectBorderSelection.currentLabel = el.label;
          selectBorderSelection.currentValue = el.value;

          if (
            el.value === 'intradary1' ||
            el.value === 'intradary2' ||
            el.value === 'daily'
          ) {
            let selectConfig = {
              show: ['.p-hour1', '.p-hour2', '.move-select'],
              hide: ['.p-month-1', '.p-year-1', '.p-quarter-1'],
            };
            Utils.changeStyles(selectConfig);
          } else {
            let selectConfig = {
              show: [],
              hide: ['.move-select'],
            };
            Utils.changeStyles(selectConfig);
          }
        }

        if (selectBorderSelection.currentValue === 'France') {
          selectAuctionTypeSelection.options = [
            { label: 'intraday', complement: '1', value: 'intraday1' },
            { label: 'intraday', complement: '2', value: 'intraday2' },
            { label: 'daily', value: 'daily' },
            { label: 'monthly', value: 'monthly' },
            { label: 'yearly', value: 'yearly' },
          ];
          setParameter('border', 'France');
        } else {
          selectAuctionTypeSelection.options = [
            { label: 'monthly', value: 'monthly' },
            { label: 'quarterly', value: 'quarterly' },
            { label: 'yearly', value: 'yearly' },
          ];

          setParameter('border', 'Portugal');
        }
        selectAuctionTypeSelection.currentLabel =
          selectAuctionTypeSelection.options[0].label;
        selectAuctionTypeSelection.currentValue =
          selectAuctionTypeSelection.options[0].value;
        selectAuctionTypeSelection.currentComplement =
          selectAuctionTypeSelection.options[0].complement;

        setParameter('type', selectAuctionTypeSelection.currentValue);
        showAuctionsOptions(selectAuctionTypeSelection.currentValue);
        renderSupplyCurvesAgain();
        componentSelects.render(
          selectAuctionTypeSelection,
          '#auctions-type-selector'
        );
        componentSelects.render(selectBorderSelection, '#border-selector');
      });
    }
    if (selectAuctionTypeSelection.id === id) {
      selectAuctionTypeSelection.options.map((el) => {
        if (el.value === newValue) {
          selectAuctionTypeSelection.currentLabel = el.label;
          selectAuctionTypeSelection.currentValue = el.value;
          selectAuctionTypeSelection.currentComplement = el.complement;

          setParameter('type', el.value);
          showAuctionsOptions(el.value);
          renderSupplyCurvesAgain();
        }
        componentSelects.render(
          selectAuctionTypeSelection,
          '#auctions-type-selector'
        );
      });
    }
    if (selectDirectionSelection.id === id) {
      selectDirectionSelection.options.map((el) => {
        if (el.value === newValue) {
          selectDirectionSelection.currentLabel = el.label;
          selectDirectionSelection.currentValue = el.value;

          setParameter('direction', el.value);
          renderSupplyCurvesAgain();
        }
        componentSelects.render(
          selectDirectionSelection,
          '#direction-selector'
        );
      });
    }
    if (selectDirectionSelectionTwo.id === id) {
      selectDirectionSelectionTwo.options.map((el) => {
        if (el.value === newValue) {
          selectDirectionSelectionTwo.currentLabel = el.label;
          selectDirectionSelectionTwo.currentValue = el.value;

          setParameter('direction', el.value);
          renderSupplyCurvesAgain();
        }
        componentSelects.render(
          selectDirectionSelectionTwo,
          '#direction-selector-two'
        );
      });
    }
    if (selectForMoveTime.id === id) {
      selectForMoveTime.options.map((el) => {
        if (el.value === newValue) {
          selectForMoveTime.currentLabel = el.label;
          selectForMoveTime.currentValue = el.value;
          selectForMoveTime.currentQuantity = el.quantity;

          componentSelects.render(selectForMoveTime, '#time-move-selector');
        }
      });
      return;
    }
    if (selectSupplyMonth.id === id) {
      selectSupplyMonth.options.map((el) => {
        if (el.value === newValue) {
          selectSupplyMonth.currentLabel = el.label;
          selectSupplyMonth.currentValue = el.value;

          componentSelects.render(selectSupplyMonth, '#month-btn');

          let date = $('#datepicker').val().split('/');

          let newDate = moment(
            `${date[0]}-${el.value}-${date[2]}`,
            'DD-MM-YYYY'
          )
            .tz('Europe/Madrid')
            .hour('00')
            .minutes('00');

          setParameter('date', newDate.format('YYYY-MM-DDTHH:mm'));
          $('#datepicker').val(newDate.format('DD/MM/YYYY'));

          renderSupplyCurvesAgain();
        }
      });
      return;
    }
    if (selectSupplyQuartely.id === id) {
      selectSupplyQuartely.options.map((el) => {
        if (el.value === newValue) {
          selectSupplyQuartely.currentLabel = el.label;
          selectSupplyQuartely.currentValue = el.value;
          selectSupplyQuartely.currentComplement = el.complement;

          componentSelects.render(selectSupplyQuartely, '#quarter-btn');

          let date = $('#datepicker').val().split('/');

          let newDate = moment(
            `${date[0]}-${el.value - 1}-${date[2]}`,
            'DD-MM-YYYY'
          )
            .tz('Europe/Madrid')
            .hour('00')
            .minutes('00');

          setParameter(
            'date',
            newDate
              .date('01')
              .hour('00')
              .minutes('00')
              .format('YYYY-MM-DDTHH:mm')
          );
          $('#datepicker').val(newDate.format('DD/MM/YYYY'));
          renderSupplyCurvesAgain();
        }
      });
      return;
    }

    if (selectSupplyYear.id === id) {
      selectSupplyYear.options.map((el) => {
        if (el.value === newValue) {
          selectSupplyYear.currentLabel = el.label;
          selectSupplyYear.currentValue = el.value;
          componentSelects.render(selectSupplyYear, '#year-btn');

          let date = $('#datepicker').val().split('/');

          let newDate = moment(
            `${date[0]}-${date[1]}-${el.value}`,
            'DD-MM-YYYY'
          )
            .tz('Europe/Madrid')
            .hour('00')
            .minutes('00');

          setParameter(
            'date',
            newDate
              .date('01')
              .hour('00')
              .minutes('00')
              .format('YYYY-MM-DDTHH:mm')
          );
          $('#datepicker').val(newDate.format('DD/MM/YYYY'));
          renderSupplyCurvesAgain();
        }
      });

      return;
    }
  };

  let preRenderComponent = async () => {
    document.title = `${i18n.t('supply_curves')} | ${i18n.t('page_subtitle')}`;
    let id = urlParams.get('id');
    let date = urlParams.get('date');
    let type = urlParams.get('type');
    let border = urlParams.get('border');

    if (!id) {
      setParameter('id', '29');
    } else {
      setParamsForId(id);
    }

    if (!date) {
      setParameter('date', today.minutes('00').format('YYYY-MM-DDTHH:mm'));
    }

    showAuctionsOptions(type);
    renderSelects();
  };

  const setParameter = (key, value) => {
    urlParams.set(key, value);
    history.pushState({}, '', window.location.pathname + '?' + urlParams);
  };

  const deleteParameter = (key) => {
    urlParams.delete(key);
    history.pushState({}, '', window.location.pathname + '?' + urlParams);
  };

  const setParamsForId = (id) => {
    let dir = urlParams.get('direction');
    let type = urlParams.get('type');
    let border = urlParams.get('border');

    if (id === '28') {
      setParameter('direction', 'import');
      deleteParameter('border');
      deleteParameter('type');
      let selectConfig = {
        show: ['.p-hour1', '.p-hour2', '.move-select'],
        hide: ['.p-month-1', '.p-year-1', '.p-quarter-1'],
      };
      Utils.changeStyles(selectConfig);
    } else if (id === '29' || id === '32') {
      deleteParameter('direction');
      deleteParameter('border');
      deleteParameter('type');
      let selectConfig = {
        show: ['.p-hour1', '.p-hour2', '.move-select'],
        hide: ['.p-month-1', '.p-year-1', '.p-quarter-1'],
      };
      Utils.changeStyles(selectConfig);
    } else if (id === '30' || id === '31') {
      if (!dir) {
        setParameter('direction', 'up');
      }
      deleteParameter('border');
      deleteParameter('type');
      let selectConfig = {
        show: ['.p-hour1', '.p-hour2', '.move-select'],
        hide: ['.p-month-1', '.p-year-1', '.p-quarter-1'],
      };
      Utils.changeStyles(selectConfig);
    } else if (id === '33') {
      if (!type) {
        setParameter('type', 'intraday1');
      }
      if (!dir) {
        setParameter('direction', 'import');
      }
      if (!border) {
        setParameter('border', 'France');
      }
      let selectConfig = {
        show: ['.p-hour1', '.p-hour2'],
        hide: [],
      };
      Utils.changeStyles(selectConfig);
    }
  };

  const changeDate = (selector, oper, number, date) => {
    if (oper === 'add') {
      $(selector).val(
        moment($(selector).val(), 'DD/MM/YYYY')
          .tz('Europe/Madrid')
          .add(number, date)
          .format('DD/MM/YYYY')
      );
    } else if (oper === 'subtract') {
      $(selector).val(
        moment($(selector).val(), 'DD/MM/YYYY')
          .tz('Europe/Madrid')
          .subtract(number, date)
          .format('DD/MM/YYYY')
      );
    }
  };

  const renderSelects = () => {
    let date = moment(urlParams.get('date'), 'YYYY-MM-DDTHH:mm');
    let direction = urlParams.get('direction');
    let border = urlParams.get('border');
    let type = urlParams.get('type');

    selectBorderSelection.options.map((el) => {
      if (el.value === border) {
        selectBorderSelection.currentLabel = el.label;
        selectBorderSelection.currentValue = el.value;

        if (el.value === 'France') {
          selectAuctionTypeSelection.options = [
            { label: 'intraday', complement: '1', value: 'intraday1' },
            { label: 'intraday', complement: '2', value: 'intraday2' },
            { label: 'daily', value: 'daily' },
            { label: 'monthly', value: 'monthly' },
            { label: 'yearly', value: 'yearly' },
          ];
        } else {
          selectAuctionTypeSelection.options = [
            { label: 'monthly', value: 'monthly' },
            { label: 'quarterly', value: 'quarterly' },
            { label: 'yearly', value: 'yearly' },
          ];
        }

        selectAuctionTypeSelection.currentLabel =
          selectAuctionTypeSelection.options[0].label;
        selectAuctionTypeSelection.currentValue =
          selectAuctionTypeSelection.options[0].value;
        selectAuctionTypeSelection.complement =
          selectAuctionTypeSelection.options[0].complement;
      }
    });

    selectAuctionTypeSelection.options.map((el) => {
      if (el.value === type) {
        selectAuctionTypeSelection.currentLabel = el.label;
        selectAuctionTypeSelection.currentValue = el.value;
        selectAuctionTypeSelection.currentComplement = el.complement;
      }
    });

    let monthNumber = date.format('MM');
    let yearNumber = date.format('YYYY');

    selectSupplyMonth.options.map((el) => {
      if (el.value === monthNumber) {
        selectSupplyMonth.currentLabel = el.label;
        selectSupplyMonth.currentValue = el.value;

        componentSelects.render(selectSupplyMonth, '#month-btn');
      }
    });

    selectSupplyYear.options.map((el) => {
      if (el.value === yearNumber) {
        selectSupplyYear.currentLabel = el.label;
        selectSupplyYear.currentValue = el.value;

        componentSelects.render(selectSupplyYear, '#year-btn');
      }
    });

    selectDirectionSelection.options.map((el) => {
      if (el.value === direction) {
        selectDirectionSelection.currentLabel = el.label;
        selectDirectionSelection.currentValue = el.value;
      }
    });
    selectDirectionSelectionTwo.options.map((el) => {
      if (el.value === direction) {
        selectDirectionSelectionTwo.currentLabel = el.label;
        selectDirectionSelectionTwo.currentValue = el.value;
      }
    });

    selectTime.currentHour = date.format('HH');
    componentTimeSelector.render(selectTime, '#hour-selector');
  };

  const showAuctionsOptions = (type) => {
    if (type === 'monthly') {
      let selectConfig = {
        show: ['.p-month-1#1/2', '.p-year-1#2/3'],
        hide: ['.p-hour1', '.p-hour2', '.move-select', '.p-quarter-1'],
      };
      Utils.changeStyles(selectConfig);
    } else if (type === 'quarterly') {
      let selectConfig = {
        show: ['.p-quarter-1#1/2', '.p-year-1#2/3'],
        hide: [
          '.p-hour1',
          '.p-hour2',
          '.p-month-1',
          '.move-select',
          '.p-quarter-1',
        ],
      };
      Utils.changeStyles(selectConfig);
    } else if (type === 'yearly') {
      let selectConfig = {
        show: ['.p-year-1#1/4'],
        hide: [
          '.p-hour1',
          '.p-hour2',
          '.p-month-1',
          '.move-select',
          '.p-quarter-1',
        ],
      };
      Utils.changeStyles(selectConfig);
    } else {
      if (type) {
        let selectConfig = {
          show: ['.p-hour1', '.p-hour2', '.move-select'],
          hide: ['.p-month-1', '.p-year-1', '.p-quarter-1'],
        };
        Utils.changeStyles(selectConfig);
      }
    }
  };

  let sortArrayByIdAndName = (arr, type = 'json') => {
    return arr
      .sort(function (a, b) {
        if (type === 'json' ? a.name > b.name : a[1] > b[1]) {
          return -1;
        }
        if (type === 'json' ? a.name < b.name : a[1] < b[1]) {
          return 1;
        }
        return 0;
      })
      .sort(function (a, b) {
        if (type === 'json' ? a.id > b.id : a[0] > b[0]) {
          return -1;
        }
        if (type === 'json' ? a.id < b.id : a[0] < b[0]) {
          return 1;
        }
        return 0;
      });
  };

  let exportDataProccess = (arr, id, sentido = '') => {
    dataJson = [];
    dataCsv = [];
    const changeDate = (datetime) => {
      if (datetime.includes('.000')) {
        return datetime.replace('.000', '');
      } else {
        return datetime;
      }
    };
    arr.indicators.map((i) => {
      i.values.map((el) => {
        dataJson.push({
          id: i.id,
          name: i.alias.offer,
          datetime: changeDate(el.datetime),
          MW: Utils.toNumber(el.offer, 1, 'comma'),
          'Euro/MW': Utils.toNumber(el.precio, 2, 'comma'),
        });
        if (el.assignation_sum) {
          dataJson.push({
            id: i.id,
            name: i.alias.assignation,
            datetime: changeDate(el.datetime),
            MW: Utils.toNumber(el.assignation, 1, 'comma'),
            'Euro/MW': Utils.toNumber(el.precio, 2, 'comma'),
          });
        }
        dataCsv.push([
          i.id,
          i.alias.offer,
          changeDate(el.datetime),
          Utils.toNumber(el.offer, 1, 'comma'),
          Utils.toNumber(el.precio, 2, 'comma'),
        ]);
        if (el.assignation_sum) {
          dataCsv.push([
            i.id,
            i.alias.assignation,
            changeDate(el.datetime),
            Utils.toNumber(el.assignation, 1, 'comma'),
            Utils.toNumber(el.precio, 2, 'comma'),
          ]);
        }
      });
    });
    // check when export needs to be reversed

    let idImpArr = [33, 28];
    let idDownImpArr = [30, 31, 33];

    if (idImpArr.includes(parseInt(id))) {
      if (sentido === 'import') {
        dataJson.reverse();
        dataCsv.reverse();
      }
    }
    if (idDownImpArr.includes(parseInt(id))) {
      if (sentido === 'down' || sentido === 'export') {
        dataJson.reverse();
        dataCsv.reverse();
      }
    }

    newDataJson = sortArrayByIdAndName(dataJson);
    newDataCsv = sortArrayByIdAndName(dataCsv, 'csv');
    newDataCsv.unshift(['id', 'name', 'datetime', 'MW', 'Euro/MW']);

    exportNames.forEach((el) => {
      if (el.id === parseInt(id)) {
        exportName = i18n.t(`routes.${el.slug}`);
      }
    });

    let currentDatetime = moment()
      .tz('Europe/Madrid')
      .format('YYYY-MM-DD_HH:mm');

    let fileTitle = `export_${exportName}_${currentDatetime}`;

    buttonsArray = [
      {
        title: i18n.t('export'),
        action: 'exports',
        id: 'export_multiple',
        dataCSV: newDataCsv,
        csvName: fileTitle,
        dataJSON: newDataJson,
        jsonName: fileTitle,
      },
      { title: i18n.t('print'), action: 'print', url: '#' },
    ];
    componentButtons.render(buttonsArray, '#supply-buttons');
  };

  let renderComponent = async () => {
    const state = store.getState();

    try {
      templateSupplyCurves(context, handlers);
      document.querySelector('.fixed-form').style.display = 'none';

      let id = urlParams.get('id');
      let date = urlParams.get('date');
      let dir = urlParams.get('direction');
      let type = urlParams.get('type');
      let border = urlParams.get('border');

      let data = await servicesSupplyCurves
        .getSupplyCurvesDataFromWidget({
          date,
          id,
          dir,
          type,
          border,
        })
        .then((data) => {
          document.querySelector('.fixed-form').style.display = 'block';
          Utils.spinnerStatus('spinner1', false);
          exportDataProccess(data, id, dir);
          return data;
        });

      datepickerComponent.render();

      if (date) {
        document.getElementById('datepicker').value = moment(date, 'YYYY-MM-DD')
          .tz('Europe/Madrid')
          .format('DD/MM/YYYY');
      }
      if (id) {
        selectCurveSelection.options.map((el) => {
          if (el.value === id) {
            selectCurveSelection.currentLabel = el.label;
            selectCurveSelection.currentValue = el.value;
            componentSelects.render(selectCurveSelection, '#group-in-selector');
          }
        });
      }

      componentSelects.render(selectCurveSelection, '#group-in-selector');
      componentSelects.render(selectBorderSelection, '#border-selector');
      componentSelects.render(
        selectAuctionTypeSelection,
        '#auctions-type-selector'
      );

      componentSelects.render(selectForMoveTime, '#time-move-selector');
      componentSelects.render(selectSupplyMonth, '#month-btn');
      componentSelects.render(selectSupplyQuartely, '#quarter-btn');
      componentTimeSelector.render(selectTime, '#hour-selector');

      componentSelects.render(selectDirectionSelection, '#direction-selector');
      componentSelects.render(
        selectDirectionSelectionTwo,
        '#direction-selector-two'
      );

      componentSelects.render(selectSupplyYear, '#year-btn');
      $('#datepicker').change(function () {
        let time = document.querySelector(
          '#hour-selector .esios-toolbar-time__mod-date'
        ).innerHTML;
        let date = $('#datepicker').val();

        setParameter(
          'date',
          moment(date, 'DD/MM/YYYY')
            .tz('Europe/Madrid')
            .hour(time.split(':')[0].trim())
            .minutes('00')
            .format('YYYY-MM-DDTHH:mm')
        );

        renderSupplyCurvesAgain();
      });

      $('.next-button').click(function () {
        let moveValue = selectForMoveTime.currentValue;

        if (moveValue === 'hour') {
          selectTime.currentHour = moment(selectTime.currentHour, 'HH')
            .add(1, 'hour')
            .format('HH');

          if (selectTime.currentHour === '00') {
            changeDate('#datepicker', 'add', 1, 'days');
          }
        } else {
          changeDate('#datepicker', 'add', 1, moveValue);
        }

        componentTimeSelector.render(selectTime, '#hour-selector');

        setParameter(
          'date',
          moment($('#datepicker').val(), 'DD/MM/YYYY')
            .tz('Europe/Madrid')
            .hour(selectTime.currentHour)
            .format('YYYY-MM-DDTHH:mm')
        );
        renderSupplyCurvesAgain();
      });

      $('.prev-button').click(function () {
        let moveValue = selectForMoveTime.currentValue;

        if (moveValue === 'hour') {
          selectTime.currentHour = moment(selectTime.currentHour, 'HH')
            .subtract(1, 'hour')
            .format('HH');

          if (selectTime.currentHour === '23') {
            changeDate('#datepicker', 'subtract', 1, 'days');
          }
        } else {
          changeDate('#datepicker', 'subtract', 1, moveValue);
        }

        componentTimeSelector.render(selectTime, '#hour-selector');

        setParameter(
          'date',
          moment($('#datepicker').val(), 'DD/MM/YYYY')
            .tz('Europe/Madrid')
            .hour(selectTime.currentHour)
            .format('YYYY-MM-DDTHH:mm')
        );

        renderSupplyCurvesAgain();
      });

      renderForm(id);
      setParamsForId(id);
      renderSelects();
      showAuctionsOptions(type);

      renderSupplyCurvesGraph(data, {
        date,
        id,
        type,
        dir,
      });

      var doItOnce;
      async function resizedw() {
        let id = urlParams.get('id');
        let date = urlParams.get('date');
        let dir = urlParams.get('direction');
        let type = urlParams.get('type');
        let border = urlParams.get('border');
        Utils.spinnerStatus('spinner1', true);
        let data = await servicesSupplyCurves
          .getSupplyCurvesDataFromWidget({
            date,
            id,
            dir,
            type,
            border,
          })
          .then((data) => {
            document.querySelector('.fixed-form').style.display = 'block';
            Utils.spinnerStatus('spinner1', false);
            exportDataProccess(data, id, dir);
            return data;
          });
        document.querySelector('#supplyCurvesWidgetView svg').remove();

        renderSupplyCurvesGraph(data, {
          date,
          id,
          type,
          dir,
        });
      }

      window.addEventListener('resize', () => {
        clearTimeout(doItOnce);
        doItOnce = setTimeout(function () {
          resizedw();
        }, 300);
      });

      document
        .querySelector('.esios-layout__breadcrumbs__wrapper')
        .classList.add('esios-layout__breadcrumbs__wrapper--curves');
      document
        .querySelector('.esios-layout__breadcrumbs__wrap')
        .classList.add('esios-layout__breadcrumbs__wrap--curves');
      document
        .querySelector('.esios-layout__wrap')
        .classList.add('esios-layout__wrap--curves');
      document
        .querySelector('.esios-layout__footer-image')
        .classList.add('esios-layout__footer-image--curves');
      document
        .querySelector('.esios-footer__wrapper')
        .classList.add('esios-footer__wrapper--curves');
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = () => {
    const state = store.getState();
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  const renderSupplyCurvesAgain = async () => {
    document.getElementById('supplyCurvesWidgetView').style.opacity = 0.5;
    Utils.spinnerStatus('spinner1', true);
    let id = urlParams.get('id');
    let date = urlParams.get('date');
    let dir = urlParams.get('direction');
    let type = urlParams.get('type');
    let border = urlParams.get('border');

    let data = await servicesSupplyCurves
      .getSupplyCurvesDataFromWidget({
        date,
        id,
        dir,
        type,
        border,
      })
      .then((data) => {
        Utils.spinnerStatus('spinner1', false);
        document.getElementById('supplyCurvesWidgetView').style.opacity = 1;
        exportDataProccess(data, id, dir);
        return data;
      });
    document.querySelector('#supplyCurvesWidgetView svg').remove();
    renderSupplyCurvesGraph(data, {
      date,
      id,
      type,
      dir,
    });
  };

  return {
    render: init,
  };
})();

export { componentSupplyCurves };

const renderSupplyCurvesGraph = async (data, params) => {
  const { date, id, type, dir } = params;
  const indicators = data.indicators;
  const graphColors = ['#FFB445', '#D90000', '#CECEFC', '#03689A'];
  const lineColors = [
    635, 670, 679, 629, 1148, 1095, 1091, 1693, 1089, 1090, 1096, 1098, 1097,
    1668, 1669, 1671, 1670, 1672, 1673,
  ];
  const parsedData = [];
  indicators.map((el, index) => {
    parsedData.push(
      {
        id: `${parseInt(el.id) + 1}`,
        type: 'line',
        name: el.name + ' ' + el.id,
        short_name: el.alias.offer,
        values: el.values.map((ele, index) => {
          return {
            x: ele.precio,
            y: ele.offer_sum,
          };
        }),
        interpolation: 'step-after',
        color: graphColors[lineColors.includes(el.id) ? 0 : 2],
      },
      {
        id: `${parseInt(el.id) + 2}`,
        type: 'line',
        name: el.name + ' ' + el.id,
        short_name: el.alias.assignation,
        values: el.values.map((ele, index) => {
          return {
            x: ele.precio,
            y: ele.assignation_sum,
          };
        }),
        interpolation: 'step-after',
        color: graphColors[lineColors.includes(el.id) ? 1 : 3],
      }
    );
  });

  // * Functions to reverse lines and reverse xaxis
  const xaxisIdsForInvert = [30, 31, 33];
  const xaxisDirForInvert = ['down', 'import', 'export'];

  let invertGraph = () => {
    if (xaxisIdsForInvert.includes(parseInt(id))) {
      if (xaxisDirForInvert.includes(dir)) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };
  /* We compute the x domain */
  var domain = [Infinity, -Infinity];
  if (invertGraph()) {
    parsedData.map((elem) => {
      elem.values.reverse();
    });

    _.each(parsedData, function (indicator) {
      var indicatorDomain = d3.extent(
        _.map(indicator.values, function (o) {
          return o.x;
        })
      );
      domain[0] =
        indicatorDomain[0] < domain[0] ? indicatorDomain[0] : domain[0];
      domain[1] =
        indicatorDomain[1] > domain[1] ? indicatorDomain[1] : domain[1];
    });
    domain.reverse();
  } else {
    domain = null;
  }

  let exponentValue = 1,
    fakeValue = false,
    fitValue = true,
    moduleValue = 0;
  /* We inverse the x scale and change the x axis interpolation for some
         charts */
  if (
    (id === '30' && dir === 'down') ||
    (id === '31' && dir === 'down') ||
    (id === '28' && dir === 'import') ||
    id === '33'
  ) {
    fakeValue = true;
    exponentValue = 1.6;
  } else {
    fakeValue = false;
    exponentValue = 0.6;
  }

  /* For charts with only negative values, we hide positive ones */
  if ((id === '30' && dir === 'down') || (id === '31' && dir === 'down')) {
    fitValue = true;
    moduleValue = 1;
  } else {
    fitValue = false;
    moduleValue = 0;
  }

  const getNumOfTicks = (id) => {
    if (id == 31) {
      return Utils.isMobileDevice() ? [5] : [8];
    }
    return Utils.isMobileDevice() ? [8] : [16];
  };

  var options = {
    target: d3.select('#supplyCurvesWidgetView .chari-chart').node(),
    margin: '5 10 25 60',
    noFakeValue: fakeValue,
    trail: {
      enabled: true,
      parseStep: function (x) {
        return x;
      },
      initXValue: function (x) {
        let xValue = 0;
        let val = [];
        indicators[0].values.map((el) => {
          if (el.assignation_sum !== null) {
            val.push(el.precio);
          }
        });
        if (val.length === 0) {
          xValue = indicators[0].values[0].precio;
          if (invertGraph()) {
            xValue = indicators[0].values[1].precio * -1;
          }
        } else {
          xValue = val.at(-1);
          if (dir) {
            if (dir === 'up' || dir === 'import') {
              xValue = val.at(-1);
            }
            if (dir === 'down' || dir === 'export') {
              xValue = val.at(0);
            }
          }
          if (invertGraph()) {
            xValue = indicators[0].values[1].precio * -1;
          }
        }
        return xValue;
      },
    },
    yaxis: {
      fit: fitValue,
      textMarginTop: -10,
      textMarginLeft: -10,
      left: {
        label: 'MW',
        tickFormat: function (d) {
          return d.toLocaleString();
        },
      },
    },
    xaxis: {
      scale: 'pow',
      exponent: exponentValue,
      ticks: getNumOfTicks(id),
      bottom: {
        label: `${i18n.t('price')} €/MW`,
        tickFormat: function (d, i) {
          if (id === '33' && dir === 'import' && i % 2 === 0) {
            return d;
          }
          if (
            (id === '30' && dir === 'down' && i % 2 === 0) ||
            (id === '33' && dir === 'export' && i % 2 === 0)
          ) {
            return d;
          }
          if (id === '30' && dir === 'up' && i % 2 === 0) {
            return d;
          }
          if (id === '31' && dir === 'down' && i % 2 === 0) {
            return d;
          }
          if (
            id !== '33' &&
            dir !== 'import' &&
            id !== '30' &&
            dir !== 'down' &&
            i % 2 === moduleValue
          ) {
            return d;
          }
        },
        domain,
      },
    },
  };

  let dataForChart = [...parsedData];
  var chart = new Charicharts.Chart(options, dataForChart);

  let timeInfo = document.querySelector('.esios-supply-curves__timeline-info');

  let momentDate = moment(date);
  if (type === 'monthly') {
    timeInfo.innerHTML = `
  ${i18next.t('month')} ${i18next.t(
      momentDate.format('MMMM').toLowerCase()
    )} ${momentDate.format('YYYY')}
  `;
  } else if (type === 'yearly') {
    timeInfo.innerHTML = `
  ${i18next.t('year')} ${momentDate.format('YYYY')}
  `;
  } else if (type === 'quarterly') {
    timeInfo.innerHTML = `
  ${i18next.t('quarter')} ${
      momentDate.format('MM') >= 0 && momentDate.format('MM') < 4
        ? '1'
        : momentDate.format('MM') >= 4 && momentDate.format('MM') < 7
        ? '2'
        : momentDate.format('MM') >= 7 && momentDate.format('MM') < 10
        ? '3'
        : momentDate.format('MM') >= 10 && momentDate.format('MM') < 13
        ? '4'
        : '-'
    } ${momentDate.format('YYYY')}
  `;
  } else {
    timeInfo.innerHTML = `
  ${momentDate.format('DD-MM-YYYY')} 
  ${i18next.t('between')} ${momentDate.format('HH:mm')} ${i18next.t(
      'and'
    )} ${momentDate.add(1, 'hour').format('HH:mm')}
  `;
  }
  let facturacionLegends = document.querySelector(
    '#supplyCurvesWidgetView .esios-listEnergyItems__list'
  );
  chart.on('Trail/moved', function (parsedData, d) {
    facturacionLegends.innerHTML = '';

    if (id === '29') {
      var sortOrder = ['636', '637', '602', '603'];
      parsedData.sort(function (a, b) {
        return sortOrder.indexOf(a.id) - sortOrder.indexOf(b.id);
      });
    }
    if (id === '33') {
      let numForSrarch =
        d == 0 ? 0 : Utils.toNumber(d, 2, 'dot').replaceAll(',', '.') * 1;
      dataForChart.forEach((el, index) => {
        parsedData[index].x = dataForChart[index].values.filter(
          (val) => val.x == numForSrarch
        )[0]?.x;
        parsedData[index].y = dataForChart[index].values.filter(
          (val) => val.x == numForSrarch
        )[0]?.y;

        let tempArr = el.values.map((el) => el.x);
        let val1 = calculateClosest(tempArr, numForSrarch);
        let ind = tempArr.findIndex((el) => el == val1);
        if (el.values[ind].y !== null) {
          parsedData[index].x = el.values[ind].x;
          parsedData[index].y = el.values[ind].y;
        } else {
          parsedData[index].x = '-';
          parsedData[index].y = '-';
        }
      });
    }
    parsedData.map((el) => {
      facturacionLegends.innerHTML += `
          <li id="${
            el.id
          }" class="esios-listEnergyItems__item-with-value col-sm-6">
            <div class="esios-listEnergyItems__label">
              <span class="esios-listEnergyItems__decorator" style="background-color: ${
                el.color
              };"></span>
              <span class="label">${el.short_name}</span>
              <div class="value is-number main-value">
                <span class="caption">${
                  el.y ? Utils.toNumber(el.y, 1, 'comma') : '-'
                }</span><span class="unit">MW</span>
              </div>

              <div class="value is-number secondary-value">
              <span class="caption">${
                el?.x == 0
                  ? '0'
                  : el?.x == null
                  ? '-'
                  : el?.x
                  ? Utils.toNumber(el.x, 2, 'comma')
                  : '-'
              }</span>
              <span class="unit">€/MW</span>
              </div>
            </div>
          </li>
        `;
    });
  });

  const dataLength = data.indicators[0].values.length;
  const rect17 = document.getElementById('rect19');
  const wid17 = document.getElementById('graphic');
  if (dataLength === 0) {
    wid17.classList.add('no-data-graphic');
    rect17.classList.remove('hidden');
    document.querySelector('#graphic > svg').classList.add('hidden');
    facturacionLegends.innerHTML = '';
    parsedData.map((el) => {
      facturacionLegends.innerHTML += `
          <li id="${el.id}" class="esios-listEnergyItems__item-with-value col-sm-6">
            <div class="esios-listEnergyItems__label">
              <span class="esios-listEnergyItems__decorator" style="background-color: ${el.color};"></span>
              <span class="label">${el.short_name}</span>
              <div class="value is-number main-value">
                <span class="caption"> - </span><span class="unit">MW</span>
              </div>

              <div class="value is-number secondary-value">
              <span class="caption"> - </span>
              <span class="unit">€/MW</span>
              </div>
            </div>
          </li>
        `;
    });
  } else {
    wid17.classList.remove('no-data-graphic');
    rect17.classList.add('hidden');
  }

  if (id === '29') {
    document.querySelector('.chari-chart').classList.add('dashed-cero');
    document.querySelector('.chari-chart').classList.remove('cero');
  } else if (id === '30') {
    document.querySelector('.chari-chart').classList.remove('dashed-cero');
    document.querySelector('.chari-chart').classList.add('cero');
  }
  if (moduleValue === 1) {
    document.querySelector('.chari-chart').classList.add('dashed-cero');
  } else {
    document.querySelector('.chari-chart').classList.remove('dashed-cero');
  }
  if (window.innerWidth <= 540) {
    const supplyWidgetSVG = document.querySelector(
      '#supplyCurvesWidgetView svg'
    );
    supplyWidgetSVG.setAttribute('preserveAspectRatio', 'xMinYMin meet');
    supplyWidgetSVG.setAttribute('viewBox', '0 0 790 600');
    supplyWidgetSVG.setAttribute('width', '790px');
    supplyWidgetSVG.setAttribute('height', '600px');
    supplyWidgetSVG.removeAttribute('width');
    supplyWidgetSVG.removeAttribute('height');
  }

  const supplyWidgetSVG = document.querySelector('#supplyCurvesWidgetView svg');
  supplyWidgetSVG.setAttribute('preserveAspectRatio', 'xMinYMin meet');
  supplyWidgetSVG.setAttribute('viewBox', '0 0 790 400');
  supplyWidgetSVG.setAttribute('width', '790px');
  supplyWidgetSVG.setAttribute('height', '400px');
  supplyWidgetSVG.removeAttribute('width');
  supplyWidgetSVG.removeAttribute('height');
};

const renderForm = (id) => {
  if (id === '28') {
    let selectConfig = {
      show: ['.direction2'],
      hide: ['.border-select', '.auctions-select', '.direction1'],
    };
    Utils.changeStyles(selectConfig);
  } else if (id === '29') {
    let selectConfig = {
      show: [],
      hide: [
        '.border-select',
        '.auctions-select',
        '.direction1',
        '.direction2',
      ],
    };
    Utils.changeStyles(selectConfig);
  } else if (id === '30' || id === '31') {
    let selectConfig = {
      show: ['.direction1'],
      hide: ['.border-select', '.auctions-select', '.direction2'],
    };

    Utils.changeStyles(selectConfig);
  } else if (id === '32') {
    let selectConfig = {
      show: [],
      hide: [
        '.border-select',
        '.auctions-select',
        '.direction1',
        '.direction2',
      ],
    };
    Utils.changeStyles(selectConfig);
  } else if (id === '33') {
    let selectConfig = {
      show: ['.border-select', '.auctions-select', '.direction2'],
      hide: ['.direction1'],
    };

    Utils.changeStyles(selectConfig);
  }
};

export const calculateClosest = (arr, goal) => {
  return arr.reduce(function (prev, curr) {
    return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
  });
};
