'use strict';

/**
 * @description Header Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { render } from 'lit-html';
import { store } from '../../../app.init.js';
import servicesLogin from '../../../pages/login/login.service.js';
import { api } from '../../../utils/api-interceptor.js';
import { templateHeader } from './header.template.js';

let componentHeader = (function () {
  let context = {};
  let renderSuccess = false;
  let showOptions;
  let showRapidAccess;

  // WHITELIST
  let pagesRappidAccess = [
    '', //INICIO
    'generacion-y-consumo',
    'generation-and-consumption',
    'mercados-y-precios',
    'market-and-prices',
    'acerca-de-esios',
    'about-esios',
    'page/network-codes',
    'pagina/codigos-de-red',
    'pagina/codigos-red-conexion',
    'page/connection-network-codes',
    'aviso-legal-y-politica-de-privacidad',
    'legal',
    'preguntas-frecuentes',
    'faq',
    'contacto',
    'contact',
    'pagina/informacion-sobre-implementacion-de-los-codigos-de-red-de-mercados',
    'page/information-about-implementatio-of-market-network-codes',
    'pagina/codigos-red-operacion',
    'page/information-about-implementatio-of-operation-network-codes',
    'pagina/interrumpibilidad_snp',
    'page/interruptibility_snp',
    'pagina/previsiones-demanda-renovable',
    'page/demand-renewable-forecasts',
    'pagina/propuestas-de-procedimientos-de-operacion',
    'page/operation-procedures-proposals',
    'generacion-y-consumo/sistemas-no-peninsulares',
    'generation-and-consumption/non-peninsular-systems',
  ];

  const checkIfMobileMenuIsOpen = () => {
    if (
      document
        .querySelector('.esios-header__responsive-menu')
        ?.classList.contains('is-open') &&
      window.innerWidth <= 540
    ) {
      document.body.classList.add('no__scroll');
    } else {
      document.body.classList.remove('no__scroll');
    }
  };
  const showResponsiveMenu = () => {
    document
      .querySelector('.esios-header__responsive-menu')
      .classList.toggle('is-open');
    document
      .querySelector('.esios-header__main-header .menu-button .bars')
      .classList.toggle('close');

    checkIfMobileMenuIsOpen();
  };

  window.addEventListener('resize', checkIfMobileMenuIsOpen);

  let preRenderComponent = () => {
    const state = store.getState();

    showRapidAccess = () => {
      const content = document.getElementById('rapid-access');
      const contentHeight = document.querySelector('#rapid-access ul');
      const buttonActivator = document.getElementById('buttonActivator');
      if (content.classList.contains('expanded')) {
        content.style.height = `${contentHeight.offsetHeight + 10}px`;
        buttonActivator.classList.add('is-open');
      } else {
        content.style.height = '0px';
        buttonActivator.classList.remove('is-open');
      }
      content.classList.toggle('expanded');
    };

    showOptions = (e) => {
      const options = document.querySelectorAll('.top-nav .options');
      let currentElement = document.querySelector(
        `#${e.target.parentElement.id} .options`
      );

      options.forEach((option) => {
        option.classList.remove('active');
      });

      currentElement.classList.add('active');
    };

    window.onclick = function (e) {
      if (!e.target.matches('.button')) {
        const options = document.querySelectorAll('.top-nav .options');
        options.forEach((option) => {
          if (option.classList.contains('active')) {
            option.classList.remove('active');
          }
        });
      }
    };

    //Dependiendo de la página, si el breadcrumb lleva calendario
    let pathname = state.exampleReducer.pathname;
    let pageName = pathname.substring(4, pathname.length);

    pagesRappidAccess.map((element) => {
      if (element === pageName) {
        context.rapidAccess = true;
      }
    });
  };

  const logOut = () => {
    let token = servicesLogin().validateSession();
    let body = '?authentication_token=' + token;
    localStorage.removeItem('myUserEntity');
    document.getElementById('btnLogout').remove();
    api('logout' + body, {
      method: 'DELETE',
    }).then(({ success }) => {
      if (success) {
        location.reload(true);
      }
    });

    // const auth2 = gapi.auth2.getAuthInstance();
    // auth2.signOut().then(function () {

    //   window.location.pathname = '/es/mi-esios';
    //   sessionStorage.removeItem('myUserEntity');
    // });
    // FB.logout(function (response) {
    //   window.location.pathname = '/es/mi-esios';
    // });
  };

  let renderComponent = () => {
    try {
      const state = store.getState();
      // Render the template to the document
      render(
        // Define a template
        templateHeader(
          context,
          showResponsiveMenu,
          showOptions,
          showRapidAccess,
          state.exampleReducer.pathname,
          logOut
        ),
        document.querySelector('#header-app')
      );

      checkIfMobileMenuIsOpen();

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = () => {
    const state = store.getState();
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentHeader };
