'use strict';
import i18next from 'i18next';
import i18n from 'i18next';
import { html } from 'lit-html';

let templateSelects = (handlers, selectorData) => {
  const { openTimeSelector } = handlers;
  return html`
    <div
      class="move-time-selector ${selectorData.columns === 1
        ? 'one-column'
        : ''}"
    >
      ${selectorData.label
        ? html`<label for="">${i18next.t(selectorData.label)}</label>`
        : null}
      <div class="selector">
        <div class="prev-button button" data-target="prev"></div>
        <div id=${selectorData.id} class="esios-toolbar-time">
          <div class="select-time-container">
            <div
              class=${selectorData.id === 'select_desglose_widget'
                ? 'esios-toolbar-time__mod-date option-selected'
                : `selected cu-select option-selected ${selectorData.id}`}
              data-target="hour"
              data-disabled="${selectorData.disabled}"
              style="${selectorData.disabled ? 'opacity: .5' : ''}"
              value="${selectorData.currentValue}"
              @click="${openTimeSelector}"
            >
              ${selectorData.currentQuantity
                ? selectorData.currentQuantity
                : ''}
              ${i18n.t(selectorData.currentLabel)}
              ${selectorData.currentComplement
                ? selectorData.currentComplement
                : ''}
            </div>
            <div class="select-time-options">
              ${selectorData.options.map((el) => {
                return html`
                  <div
                    class="option ${selectorData.currentValue === el.value
                      ? 'selected-opt'
                      : ''}"
                    data-disabled="${el.disabled}"
                    ?disabled="${el.disabled ? el.disabled : false}"
                    style="${el.disabled ? 'opacity: .5' : ''}"
                    value="${el.value}"
                  >
                    ${el.quantity
                      ? el.quantity + ' ' + i18n.t(el.label)
                      : i18n.t(el.label)}
                    ${el.complement ? el.complement : ''}
                  </div>
                `;
              })}
            </div>
          </div>
        </div>
        <div class="next-button button" data-target="next"></div>
      </div>
    </div>
  `;
};

export { templateSelects };
