import i18next from 'i18next';
import { html } from 'lit-html';
import moment from 'moment';
import { store } from '../../app.init';
import { getSplittedRoute, getUrlParams, setUrlParam } from '../../utils/url';
import { apiCall } from '../analysis/analysis.page';
import ServicesEmbedIndicators from './embed-indicators.services';

const EmbedIndicators = () => {
  const state = store.getState();
  const lang = state.exampleReducer.currentLang;

  let slug,
    analysisTitle = false;
  const { print } = getUrlParams();

  if (getSplittedRoute()[3] === i18next.t('routes.analysis')) {
    analysisTitle = true;
    const id = getSplittedRoute().pop();
    let info = {};

    const script = document.createElement('script');
    const scriptCss = document.createElement('script');
    const link = document.createElement('link');
    script.src = 'https://libs.cartocdn.com/cartodb.js/v3/3.14.6/cartodb.js';
    scriptCss.src =
      'https://libs.cartocdn.com/cartodb.js/v3/3.14.6/themes/css/cartodb.css';
    link.rel = 'stylesheet';
    link.href =
      'https://libs.cartocdn.com/cartodb.js/v3/3.14.6/themes/css/cartodb.css';
    document.head.appendChild(script);
    document.head.appendChild(scriptCss);
    document.head.appendChild(link);

    apiCall(id, lang, info, true);
  } else {
    slug = state.exampleReducer.routeParams.slug;
    if (slug === 'generacion-libre-co2' || slug === 'co2-free-generation') {
      if (!getUrlParams().date) {
        setUrlParam('date', moment().tz('Europe/Madrid').format('DD-MM-YYYY'));
      }
    }
    if (slug === 'spot-europa' || slug === 'spot-europa-map') {
      const script = document.createElement('script');
      const scriptCss = document.createElement('script');
      const link = document.createElement('link');
      script.src = 'https://libs.cartocdn.com/cartodb.js/v3/3.14.6/cartodb.js';
      scriptCss.src =
        'https://libs.cartocdn.com/cartodb.js/v3/3.14.6/themes/css/cartodb.css';
      link.rel = 'stylesheet';
      link.href =
        'https://libs.cartocdn.com/cartodb.js/v3/3.14.6/themes/css/cartodb.css';
      document.head.appendChild(script);
      document.head.appendChild(scriptCss);
      document.head.appendChild(link);

      setUrlParam('zoom', '4');
      setUrlParam('latlng', '49.781264058178344%2C2.724609375');
    }
    const servicesEmbedIndicators = ServicesEmbedIndicators();
    servicesEmbedIndicators.checkSlug(slug);
  }
  return html`
    <div
      class="esios-embed-indicators ${slug ? slug : ''} ${analysisTitle
        ? 'esios-analysis__info-chart grid-sm-17'
        : ''}"
    >
      ${analysisTitle
        ? html`<div id="analysis-info-title" class="esios-analysis__info-title">
            <span>...</span>
          </div>`
        : ''}
      <div id="analysis-form"></div>
      <div id="embed-container" class="esios-embed-indicators__container"></div>
      ${print
        ? html`<div
            id="button-fisicas"
            class="button-embed-${slug} hidden"
          ></div>`
        : ''}
    </div>
  `;
};

export default EmbedIndicators;
