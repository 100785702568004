'use strict';

import i18next from 'i18next';

let mercadosPreciosContent = (() => {
  let init = () => {};

  const contentForMyP = {
    listMarket: [
      {
        type: 'title',
        id: 2,
        title: 'structural_data',
      },
      {
        type: 'list',
        id: 1,
        text: 'participants_auction',
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.participants_auction'
          )}`,
      },
      {
        type: 'list',
        id: 2,
        text: 'programming_units',
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.programming_units'
          )}`,
      },
      {
        type: 'list',
        id: 3,
        text: 'market_subjects',
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t('market_subjects')}`,
      },
      {
        type: 'button',
        id: 2,
        label: 'gde_website_text',
        url: ' https://www.ree.es/es/clientes',
      },
    ],
    toolbar: [
      {
        id: 1,
        info: true,
        share: true,
        favorite: true,
        analysis: true,
      },
      {
        id: 2,
        info: true,
        share: true,
        favorite: true,
        analysis: true,
      },
      {
        id: 3,
        info: true,
        share: true,
        favorite: true,
        analysis: true,
      },
      {
        id: 4,
        info: true,
        share: true,
        favorite: true,
        analysis: true,
      },
      {
        id: 5,
        info: true,
        share: true,
        favorite: true,
        analysis: true,
      },
    ],
  };

  return {
    init: init,
    tableContent: (data) => tableContent(data),
    contentForMyP: contentForMyP,
  };
})();
export { mercadosPreciosContent };
