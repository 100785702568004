import i18n from 'i18next';
import { html } from 'lit-html';

const MyEsiosWidget36 = () => {
  return html`
    <div class="esios-gd__row">
      <div class="row">
        <div class="col-xs-12">
          <div class="esios-toolbar">
            <div class="esios-toolbar36"></div>
          </div>
        </div>
      </div>
    </div>
    <div id="widget36" class="graph-wrapper">
      <div id="spinner36" class="spinner-container">
        <div class="white-overlay"></div>
        <div class="spinner"></div>
      </div>
      <div
        id="nochar36"
        class="esios-chart esios-intercambios__no-data-graphic"
        style="display:none"
      >
        <div id="rect1" class="esios-pvpc__no-data">
          ${i18n.t('no-data-available')}
        </div>
      </div>
      <div id="map-main-container"></div>
    </div>
  `;
};

export default MyEsiosWidget36;
