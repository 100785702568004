import i18n from 'i18next';
import { html } from 'lit-html';
import { servicesgenerConsumo } from '../../../pages/generacion y consumo/generConsumo.service';
import { componentDocumentList } from '../document-list/document-list.component';

const moreSearch = () => {
  let filterForm = {
    ambit: '',
    category: '',
    process: '',
    action: '',
    query: '',
  };

  let renderComponentDocumentList = async (form) => {
    filterForm.ambit = form.ambit;
    filterForm.action = form.actions;
    filterForm.category = form.category;
    filterForm.process = form.process;
    filterForm.query = form.query;

    let documentListData = await servicesgenerConsumo.getArchives(filterForm);

    let newDLArray = {
      isLoading: documentListData.isLoading,
      data: documentListData.newIndicators,
      renderAgain: (form) => renderComponentDocumentList(form),
      onlyRefresh: () => renderComponentListAgain(),
    };
    componentDocumentList.render(newDLArray, '#document-list-myp');
  };

  let renderComponentListAgain = async (form) => {
    let newDLArray = {
      renderAgain: (form) => renderComponentDocumentList(form),
      onlyRefresh: () => renderComponentListAgain(),
    };

    componentDocumentList.render(newDLArray, '#document-list-myp');
  };

  let newDLArray = {
    renderAgain: (form) => renderComponentDocumentList(form),
    onlyRefresh: () => renderComponentListAgain(),
  };
  setTimeout(() => {
    componentDocumentList.render(newDLArray, '#document-list-myp');
  }, 50);

  return html`
  <aside class="esios-layout__subcontent esios-layout__subcontent--analysis">
    <div class="esios-layout__subcontent__wrapper">
        <div class="title">
          <h1 class="is-title-with-line">${i18n.t('more')}</h1>
        </div>
        <ul class="esios-also__list row" id="more-myp"></ul>
        <div id="document-list-myp"></div>
      </div>
    </div>
  </aside>
  `;
};

export default moreSearch;
