import { html } from 'lit-html';
import { componentLayout } from '../../components/layout/layout.component';
import backImage from '../../assets/images/header-default.jpg';

let templateEditUsuario = (user) => {
  return componentLayout.render(
    html`
      <div class="esios-usuarios">
        <form>
          <div class="row">
            <div class="col-6">
              <label>Nombre:</label>
              <input type="text" class="esios-usuarios__inputs-edit" />
            </div>
            <div class="col-6">
              <label>Apellido:</label>
              <input type="text" class="esios-usuarios__inputs-edit" />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label>Email:</label>
              <input type="text" class="esios-usuarios__inputs-edit" />
            </div>
            <div class="col-6">
              <label>Rol:</label>
              <input type="text" class="esios-usuarios__inputs-edit" />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <button>Actualizar</button>
            </div>
            <div class="col-6">
              <button>Cambiar contraseña</button>
            </div>
          </div>
        </form>
      </div>
    `,
    {
      image: backImage,
    }
  );
};

export { templateEditUsuario };
