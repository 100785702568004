import { html, render } from 'lit-html';

const Modal = (title, content) => {
  return html`
    <div id="esios-modal">
      <div class="esios-modal" style="display:flex;">
        <div class="esios-modal__container">
          <div class="esios-modal__close" @click=${onClose}>×</div>
          <div class="esios-modal__content">
            <h1>${title}</h1>
            ${typeof content === 'object' ? content : html([content])}
          </div>
        </div>
        <div class="esios-modal__background" @click=${onClose}></div>
      </div>
    </div>
  `;
  function onClose() {
    render('', document.getElementById(`esios-modal`));
  }
};

export default Modal;
