'use strict';
import i18next from 'i18next';
import { api } from '../../utils/api-interceptor';

let servicesUnidades = (() => {
  let init = (e) => llamarServicioUnidades(e);

  const llamarServicioUnidades = async (e) => {
    let data = await api(
      'static_pages?slug=unidades-fisicas&temporal=' +
        Math.floor(Math.random() * 1000)
    );
    return data;
  };

  // Obteniendo datos para la tabla Unidades Fisicas
  const getLang = () => {
    return i18next.t('routes.lng');
  };
  const getArchivesUnidades = async (e) => {
    let id;
    let data;
    if (i18next.t('routes.lng') === 'en') {
      id = 110;
    } else {
      id = 81;
    }
    let response = await api(`archives/${id}/download_json`);

    if (i18next.t('routes.lng') === 'en') {
      data = response.GenerationUnits;
    } else {
      data = response.UnidadesFisicas;
    }
    return data;
  };

  return {
    init: init,
    getLang: getLang,
    getArchivesUnidades: (params) => getArchivesUnidades(params),
  };
})();

export { servicesUnidades };
