import i18n from 'i18next';
import { isEmpty } from 'lodash';
import { getState } from '../../../utils/state';
import Buttons from './analysis-form-buttons';
import Compare from './analysis-form-compare';
import CompareTemp from './analysis-form-compare-temp';
import GeoId from './analysis-form-geo-id';
import GroupBy from './analysis-form-group-by';
import Help from './analysis-form-help';
import Levels from './analysis-form-levels';
import Period from './analysis-form-period';

export const sections = [
  'help',
  'period',
  'group-by',
  'compare',
  'compare-temp',
  'geo-id',
  'levels',
  'buttons',
];

export const idViews = {
  comparative: ['help', 'period', 'group-by', 'compare', 'geo-id', 'buttons'],
  temporal: ['help', 'period', 'group-by', 'compare-temp', 'buttons'],
  composition: ['help', 'period', 'buttons'],
  geographical: ['help', 'period', 'levels', 'buttons'],
};

export const mapViews = {
  help: Help,
  period: Period,
  'group-by': GroupBy,
  compare: Compare,
  'compare-temp': CompareTemp,
  'geo-id': GeoId,
  levels: Levels,
  buttons: Buttons,
};

export const ANALYSIS_GROUP_ID = {
  minutes5: 219,
  minutes10: 225,
  minutes15: 218,
  hour: 4,
  day: 3,
  month: 2,
  year: 1,
};

export const ANALYSIS_TRUNCS = {
  1: 'year',
  2: 'month',
  3: 'day',
  4: 'hour',
  150: 'day',
  218: 'minutes15',
  225: 'minutes10',
  219: 'minutes5',
};

export const getTrunc = (timeId) => {
  return ANALYSIS_TRUNCS[timeId];
};

export const UNIVERSE_ID = {
  NPS: 157, // Non peninsular system
  GEN_AND_CON: 155, // Generation and consumption
  DEMAND_MANAGEMENT: 158,
  MARKETS_AND_PRICES: 156,
};
export const ANALYSIS_GROUP_ORDER_ID = [219, 225, 218, 4, 3, 2, 1];

export function getCompareModel(compare = {}, universe = []) {
  const breakdown = i18n.t('breakdown');
  const nps = i18n.t('snp_indicators');
  const others = i18n.t('others');
  const losses = i18n.t('loss');
  const consLabel = i18n.t('consumption');
  const genLabel = i18n.t('generation');
  const exchaLabel = i18n.t('exchanges');
  const relevantLabel = i18n.t('relevant_indicators');

  const { analysis } = getState();
  let removeDesglose = analysis.indicator.magnitud.some(
    ({ id }) =>
      (id == 23 || id == 152 || id == 217) &&
      analysis.indicator.operation == 'DIV'
  );
  // This code is for non peninsular systems, but it breaks the logic
  if (universe[0].id == UNIVERSE_ID.NPS) {
    if (universe.find((i) => i.id === UNIVERSE_ID.NPS)) {
      if (
        compare?.desglose?.length > 0 &&
        !removeDesglose &&
        compare?.desglose != 'null'
      ) {
        return {
          [breakdown]: compare['desglose'],
          [nps]: compare['indicadores_de_universo'],
        };
      }
      return {
        [nps]: compare['indicadores_de_universo'],
      };
    }
  }
  let tempUniverse = universe[0].id;
  let result = {
    [breakdown]: compare['desglose'],
    [nps]: compare['indicadores_snp'],
    [relevantLabel]: compare['indicadores_relevantes'],
    [others]: {},
    [losses]: {},
  };

  universe = compare['indicadores_de_universo'];
  const vocabularyGroups = [5, 14];
  const vocabularySubGroups = [3, 4];

  for (const elem of universe) {
    if (elem.terms && elem.terms.length !== 0) {
      const group = elem.terms.find((i) => {
        return vocabularyGroups.includes(i.vocabulary_id);
      });
      let subGroup = elem.terms.filter((i) =>
        vocabularySubGroups.includes(i.vocabulary_id)
      );
      if (1 < subGroup.length) {
        subGroup = subGroup.reduce((p, c) =>
          p.vocabulary_id < c.vocabulary_id ? p : c
        );
      } else {
        subGroup = subGroup[0];
      }
      if (!group && subGroup) {
        // Other indicators
        result[others][subGroup.name] = result[others][subGroup.name] || [];
        result[others][subGroup.name].push(elem);
      } else if (group && !subGroup) {
        // Losses
        result[losses][elem.name] = elem;
      } else {
        // Group & Subgroup
        result[group.name] = result[group.name] || {};
        result[group.name][subGroup.name] =
          result[group.name][subGroup.name] || [];
        result[group.name][subGroup.name].push(elem);
      }
    } else {
      let tempCompareArr;
      if (compare.extrasnp) {
        tempCompareArr = compare.extrasnp;
        tempCompareArr = tempCompareArr.filter((el) => el.id == elem.id);
        result[nps] = result[nps] || [];
        result[nps].push(elem);
      } else {
        result[others][others] = result[others][others] || [];
        result[others][others].push(elem);
      }
    }
  }
  if (Object.keys(result[losses]).length === 0) {
    delete result[losses];
  }
  if (
    result[breakdown] === 'null' ||
    result[breakdown] === null ||
    removeDesglose
  ) {
    delete result[breakdown];
  }
  if (result[relevantLabel] === 'null' || result[relevantLabel] === null) {
    delete result[relevantLabel];
  }
  // This casuistry is for example for 847 indicator
  const grpIndicators = Object.keys(result);
  if (
    grpIndicators.length === 2 &&
    grpIndicators.includes(others) &&
    grpIndicators.includes(nps)
  ) {
    delete result[nps];
    const otherTypes = Object.keys(result[others]);
    const indicators = [];
    for (const type of otherTypes) {
      const indicatorsType = result[others][type];
      indicators.push(...indicatorsType);
    }
    result = [...indicators]
      .sort(sort('name'))
      .map((i) => ({ ...i, label: i.name }));
  }

  const keys = Object.keys(result);
  for (let key of keys) {
    if (isEmpty(result[key])) {
      delete result[key];
    }
  }

  // TODO: FOR LOSS
  let perdidas;
  if (tempUniverse == UNIVERSE_ID.DEMAND_MANAGEMENT) {
    perdidas = result[losses];
    delete result[nps];
    delete result[losses];

    // return result;
    return {
      ...result,
      ...perdidas,
    };
  }

  return result;
}

export function checkGroupBy(id, idDefault) {
  if (id != 1 && id != 2 && id != 3 && id != 4) {
    return ANALYSIS_TRUNCS[idDefault];
  } else {
    return ANALYSIS_TRUNCS[id];
  }
}

export function sort(key) {
  return (a, b) => {
    const aL = key ? a[key].toLowerCase() : a.toLowerCase();
    const bL = key ? b[key].toLowerCase() : b.toLowerCase();
    if (aL < bL) {
      return -1;
    } else if (bL < aL) {
      return 1;
    } else {
      return 0;
    }
  };
}
