'use strict';

import i18 from 'i18next';
import { html } from 'lit-html';
import imgOperation from '../../assets/images/header-titulares-operacion.jpg';
import Banner from '../../shared/components/banner/banner';
import { BreadCrumbs } from '../../shared/components/breadcrumbs/breadcrumbs.template';
import { sRender } from '../../utils/render';
import { getSplittedRoute } from '../../utils/url.js';
import { getDataOfFile } from './operation-news.service';

const OperationNew = () => {
  try {
    getDataOfFile().then(({ contents }) => {
      const titleToSearch = getSplittedRoute().pop();
      let { title = '', body = '' } =
        contents.find(({ slug }) => slug === titleToSearch) || {};
      let listNewsData = contents.filter((e) => {
        return e.slug !== titleToSearch;
      });
      sRender(NewContent, 'new-view', title, body);
      sRender(ListTitles, 'list-news', listNewsData);
      sRender(BreadCrumbs, 'breadcrumb', {}, {}, titleToSearch, title);
    });

    return html`
      ${Banner(imgOperation)}
      <div class="esios-layout__main" style="top: 0px!important">
        <div id="breadcrumb"></div>
        <div class="esios-new__container">
          <div id="new-view" class="esios-new__content"></div>
          <div class="esios-new__related">
            <aside>
              <h2 class="esios-new__aside-title">${i18.t('related_news')}</h2>
              <ul id="list-news" class="esios-new__list-news"></ul>
            </aside>
          </div>
        </div>
      </div>
      <div class="esios-layout__footer-image"></div>
    `;

    function NewContent(title, body) {
      document.title = `${title} | ${i18.t('page_subtitle')}`;
      var resultado = body.replace(
        'icon-widget-favorite">',
        'esios-icons"> <button class="esios-icons__buttons icons-favorite"></button>'
      );

      resultado = resultado.replace(
        'icon-widget-favorite is-selected">',
        'esios-icons"> <button class="esios-icons__buttons icons-favorite-selected"></button>'
      );

      return html`<h1 class="esios-new__title">${title}</h1>
        ${html([resultado])}`;
    }

    function ListTitles(listNewsData) {
      let orderNewsList = listNewsData.filter((a, i) => {
        return i <= 5 ? true : false;
      });
      orderNewsList.sort(function (a, b) {
        return new Date(a.updated_at) - new Date(b.updated_at);
      });
      return orderNewsList.map((e) => {
        return html`<li>
          <a href="/${i18.t('routes.lng')}/${i18.t('routes.news')}/${e.slug}"
            >${e.title}</a
          >
        </li>`;
      });
    }
  } catch (error) {}
};

export default OperationNew;
