'use strict';

/**
 * @description Mercados y precios Component
 * @returns {Object} Devuelve la instancia del componente
 */
import i18next from 'i18next';
import moment from 'moment-timezone';
import { store } from '../../app.init.js';
import { componentDocumentList } from '../../shared/components/document-list/document-list.component.js';
import { templateShareModal } from '../../shared/components/modal/modalShare.template.js';
import { componentRapidAccess } from '../../shared/components/rapid-access/rapid-access.component.js';
import { componentTabs } from '../../shared/components/tabs/tabs.component.js';
import { componentTimeSelector } from '../../shared/components/time-selector/time-selector.component.js';
import { componentToolbar } from '../../shared/components/toolbar/toolbar.component.js';
import {
  setMyPChangeUnitaryCostHour,
  setMyPChangeVolumeHour,
} from '../../state/actions.js';
import { dateSetTime } from '../../utils/dateFormat.js';
import { getUrlParams } from '../../utils/url.js';
import { Utils } from '../../utils/utils.js';
import { servicesIntercambiosInternacionales } from '../intercambios-internacionales/intercambiosInternacionales.services.js';
import { intercambiosInternacionalesContent } from '../intercambios-internacionales/intercambiosInternacionalesContent.js';
import { servicesMercadosPrecios } from './previsionesCircular.services.js';
import { templatePrevisionesCircular } from './previsionesCircular.template.js';
import { renderPrevisionesCircular } from './previsionesCircularRenders.js';

let componentPrevisionesCircular = (function () {
  let currentDate = moment().tz('Europe/Madrid').format('YYYY-MM-DD');
  let formatDate = currentDate.split('-');
  let fecha = {
    day: formatDate[2],
    month: formatDate[1],
    year: formatDate[0],
  };
  let volumenUTC1 = false;
  let costeUTC1 = false;
  let context = {};
  let handlers = {
    onChangeLetra: (event) => onChangeLetra(event),
  };

  const onChangeLetra = (event) => {
    const tabs = document.querySelectorAll('.tablinks');
    const tabContents = document.querySelectorAll('.tabcontent');

    // Ocultar todos los contenidos
    function hideTabContents() {
      tabContents.forEach((content) => {
        content.style.display = 'none';
      });
    }

    // Borrar el 'active' de todos los tabs
    function removeActiveClass() {
      tabs.forEach((tab) => {
        tab.classList.remove('active');
      });
    }

    // Para mostrar el contenido del tab
    function showTabContent(tab) {
      const tabContent = document.querySelector(`#${tab}`);
      if (tabContent) {
        tabContent.style.display = 'block';
      }
    }

    // Asignar el evento de clic a cada tab
    tabs.forEach((tab) => {
      tab.addEventListener('click', () => {
        const targetTab = tab.getAttribute('data-tab-target'); // Asume que cada tab tiene un atributo 'data-tab-target' que corresponde al id del contenido a mostrar
        hideTabContents();
        removeActiveClass();
        showTabContent(targetTab);
        switch (targetTab) {
          case 'TAB1':
            renderFacturacionGraphAgain();
            renderDesvioGraphAgain();
            renderFotovoltaicaGraphAgain();
            renderTermicaGraphAgain();
            break;
          case 'TAB2':
            renderDDemandaGraphAgain();
            renderDEolicaFotovoltaicaGraphAgain();
            renderIDDemandaGraphAgain();
            renderIDDemandaFotovoltaicaGraphAgain();
            renderDAnualDemandaGraphAgain();
            break;
          case 'TAB3':
            renderDemandaPrevistaPeninsularGraphAgain();
            renderDemandaPrevistaSNPGraphAgain();
            renderDemandaDiariaElectricaPeninsularGraphAgain();
            renderDemandaMensualElectricaPeninsularGraphAgain();
            renderDemandaSemanalElectricaPeninsularGraphAgain();
            break;
          case 'TAB4':
            renderPrevisionMaximaAnualPeninsularGraphAgain();
            renderGeneracionPrevistaGraphAgain();
            break;
          case 'TAB5':
            renderPrevisionMaximaAnualPeninsular2GraphAgain();
            renderPrevisionMinimaAnualPeninsularGraphAgain();
            renderPrevisionMaximaMensualPeninsularGraphAgain();
            renderPrevisionMinimaMensualPeninsularGraphAgain();
            renderMargenGeneracionGraphAgain();
            break;
          case 'TAB6':
            renderPrevisonDemandaResidualGraphAgain();
            break;
          default:
            renderFacturacionGraphAgain();
            renderDesvioGraphAgain();
            renderFotovoltaicaGraphAgain();
            renderTermicaGraphAgain();
            break;
        }
        tab.classList.add('active');
      });
    });

    // Ocultar todos los contenidos y mostrar el primer contenido
    hideTabContents();
    if (tabs.length > 0) {
      tabs[0].classList.add('active');
      showTabContent(tabs[0].getAttribute('data-tab-target'));
    }
  };

  let newDate = moment().tz('Europe/Madrid').format('DD-MM-YYYY');

  let volumeData;
  let unitarycostData;
  let auctionsData;
  let auctionsPortugalData;
  let desviosData;
  let facturationData;
  let pageTitle = '';
  let date = moment().tz('Europe/Madrid');
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let filterForm = {
    ambit: '',
    category: '',
    process: '',
    action: '',
    query: '',
  };

  let renderSuccess = false;
  let objMarket = [];
  let objToolbar = [];
  let documents;

  let buttons;

  // select_volumen-widget
  const selectTimeForVolume = {
    id: 'select_volumen',
    datetimeFor: 'volumeTime',
    currentHour: '00',
    currentMinutes: '00',
    optionsHours: Utils.getArrayOfHours(),
    optionsMinutes: [],
    changeTime: (hours, minutes) => changeTime(hours, minutes),
  };

  // select_cost-widget
  const selectTimeForCost = {
    id: 'select_cost',
    datetimeFor: 'volumeTime',
    currentHour: '00',
    currentMinutes: '00',
    optionsHours: Utils.getArrayOfHours(),
    optionsMinutes: [],
    changeTime: (value) => changeTime(value),
  };

  const changeTime = (value) => {
    const { id, newValue } = value;
    let hours = newValue.split(':')[0];
    let minutes = newValue.split(':')[1];

    if (id === selectTimeForCost.id) {
      if (hours > 2) {
        costeUTC1 = true;
      } else if (hours < 2) {
        costeUTC1 = false;
      }
      store.dispatch(setMyPChangeUnitaryCostHour(`${hours}:00`));
      renderFinalPriceEnergy();
    } else if (id === selectTimeForVolume.id) {
      if (hours > 2) {
        volumenUTC1 = true;
      } else if (hours < 2) {
        volumenUTC1 = false;
      }
      store.dispatch(setMyPChangeVolumeHour(`${hours}:00`));
      renderMarketProduction();
    }
  };

  let spinnerNone = (id, widgetId = false) => {
    document.getElementById(id).style.display = 'none';
  };

  let spinnerTrue = (id, widgetId = false) => {
    document.getElementById(id).style.display = 'flex';
  };

  const setParameter = (key, value) => {
    urlParams.set(key, value);
    history.pushState({}, '', window.location.pathname + '?' + urlParams);
  };

  let preRenderComponent = async () => {
    const state = store.getState();
    document.title = `${i18next.t('demand_renewable_forecasts')} | ${i18next.t(
      'page_subtitle'
    )}`;

    buttons = [
      {
        label: 'see_more_doc',
        url: `/${i18next.t('routes.lng')}/${i18next.t(
          'routes.documentation'
        )}?scope=${i18next
          .t('demand_renewable_forecasts')
          .replaceAll(' ', '+')}`,
      },
      {
        label: 'downloads_files',
        url: `/${i18next.t('routes.lng')}/${i18next.t('routes.downloads')}`,
      },
    ];
    documents = [
      {
        title: 'exchange_of_information_with_the_OS',
        link: `/${i18next.t('routes.lng')}/${i18next.t(
          'routes.documentation'
        )}/intercambio-de-informacion-con-el-os-liquidaciones`,
      },
      {
        title: 'resolution_of_technical_restrictions',
        link: `/${i18next.t('routes.lng')}/${i18next.t(
          'routes.documentation'
        )}/p-o-3-2-resolucion-de-restricciones-tecnicas`,
      },
      {
        title: 'resolution_of_tertiary_regulation',
        link: `/${i18next.t('routes.lng')}/${i18next.t(
          'routes.documentation'
        )}/p-o-7-3-regulacion-terciaria`,
      },
      {
        title: 'royal_decree',
        link: `/${i18next.t('routes.lng')}/${i18next.t(
          'routes.documentation'
        )}/real-decreto-216-2014-metodologia-calculo-precios-voluntarios-pequeno-consumidor`,
      },
    ];

    let volumeTime = state.exampleReducer.pages.myp.volumeHour;
    selectTimeForVolume.currentHour = volumeTime.split(':')[0];
    selectTimeForVolume.currentMinutes = '00';

    let unitaryCostTime = state.exampleReducer.pages.myp.unitaryHour;

    selectTimeForCost.currentHour = unitaryCostTime.split(':')[0];
    selectTimeForCost.currentMinutes = '00';

    // let tableTime = state.exampleReducer.pages.myp.tableHour;
    // selectTimeForTable.currentHour = tableTime.split(':')[0];
    // selectTimeForTable.currentMinutes = '00';
  };

  let renderComponent = async () => {
    const state = store.getState();
    let currDate = getUrlParams().date || moment().format('DD-MM-YYYY');
    let dayBefore = moment(currDate, 'DD-MM-YYYY')
      .subtract('1', 'day')
      .format('DD-MM-YYYY');
    objToolbar = [
      {
        id: 3,
        slug: i18next.t('routes.demand-or-forecasts'),
        info: true,
        share: true,
        favorite: true,
        favId: 544,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/544?vis=1&start_date=${currDate}T00%3A00&end_date=${currDate}T23%3A55&compare_start_date=${dayBefore}T00%3A00 `,
      },
      {
        id: 4,
        slug: i18next.t('routes.wind-forecast'),
        info: true,
        share: true,
        favorite: true,
        favId: 541,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/541?start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 5,
        slug: i18next.t('routes.photovoltaic-solar-forecast'),
        info: true,
        share: true,
        favorite: true,
        favId: 542,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/542?start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 6,
        slug: i18next.t('routes.solar-thermal-forecast'),
        info: true,
        share: true,
        favorite: true,
        favId: 543,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/543?start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 7,
        slug: i18next.t('routes.daily-forecast-d1-demand'),
        info: true,
        share: true,
        favorite: true,
        favId: 1775,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/1775?start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 8,
        slug: i18next.t('routes.daily-forecast-d1-wind-plus-photovoltaic'),
        info: true,
        share: true,
        favorite: true,
        favId: 10358,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/10358?start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 9,
        slug: i18next.t('routes.intraday-forecast-h3-demand'),
        info: true,
        share: true,
        favorite: true,
        favId: 1776,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/1776?start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 10,
        slug: i18next.t('routes.intraday-forecast-h3-more-photovoltaic-demand'),
        info: true,
        share: true,
        favorite: true,
        favId: 10359,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/10359?start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 11,
        slug: i18next.t('routes.annual-demand-forecast'),
        info: true,
        share: true,
        favorite: true,
        favId: 1774,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/1774?start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 12,
        slug: i18next.t('routes.demand-or-forecasts'),
        info: true,
        share: true,
        favorite: true,
        favId: 544,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/544?start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 13,
        slug: i18next.t('routes.snp-forecast-demand'),
        info: true,
        share: true,
        favorite: true,
        favId: 1742,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/1742?start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 14,
        slug: i18next.t('routes.daily-forecast-peninsular-electricity-demand'),
        info: true,
        share: true,
        favorite: true,
        favId: 460,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/460?start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 15,
        slug: i18next.t(
          'routes.monthly-forecast-peninsular-electricity-demand'
        ),
        info: true,
        share: true,
        favorite: true,
        favId: 461,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/461?start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 16,
        slug: i18next.t('routes.weekly-forecast-peninsular-electricity-demand'),
        info: true,
        share: true,
        favorite: true,
        favId: 603,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/603?start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 17,
        slug: i18next.t(
          'routes.maximum-annual-forecast-peninsular-electricity-demand'
        ),
        info: true,
        share: true,
        favorite: true,
        favId: 541,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/541?start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 18,
        slug: i18next.t('routes.solar-planned-generation'),
        info: true,
        share: true,
        favorite: true,
        favId: 10034,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/10034?start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 19,
        slug: i18next.t(
          'routes.maximum-annual-forecast-peninsular-electricity-demand'
        ),
        info: true,
        share: true,
        favorite: true,
        favId: 541,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/541?start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 20,
        slug: i18next.t(
          'routes.minimum-annual-forecast-peninsular-electricity-demand'
        ),
        info: true,
        share: true,
        favorite: true,
        favId: 621,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/621?start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 21,
        slug: i18next.t(
          'routes.maximum-monthly-forecast-peninsular-electricity-demand'
        ),
        info: true,
        share: true,
        favorite: true,
        favId: 604,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/604?start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 22,
        slug: i18next.t(
          'routes.minimum-monthly-forecast-peninsular-electricity-demand'
        ),
        info: true,
        share: true,
        favorite: true,
        favId: 619,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/619?start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 23,
        slug: i18next.t('routes.available-generation-margin-maximum-forecast'),
        info: true,
        share: true,
        favorite: true,
        favId: 622,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/622?start_date=${dateSetTime()}&geoids=`,
      },
      {
        id: 24,
        slug: i18next.t('routes.residual-demand-forecast'),
        info: true,
        share: true,
        favorite: true,
        favId: 10249,
        analysis: true,
        url: () =>
          `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.analysis'
          )}/10249?start_date=${dateSetTime()}&geoids=`,
      },
    ];

    let listMarket = [
      {
        type: 'title',
        id: 2,
        title: 'structural_data',
      },
      {
        type: 'list',
        id: 1,
        text: 'participants_auction',
        url: `/${i18next.t('routes.lng')}/${i18next.t(
          'routes.participants_auction'
        )}`,
      },
      {
        type: 'list',
        id: 2,
        text: 'programming_units',
        url: `/${i18next.t('routes.lng')}/${i18next.t(
          'routes.programming_units'
        )}`,
      },
      {
        type: 'list',
        id: 3,
        text: 'physical_units',
        url: `/${i18next.t('routes.lng')}/${i18next.t(
          'routes.physical_units'
        )}`,
      },
      {
        type: 'list',
        id: 4,
        text: 'market_subjects',
        url: `/${i18next.t('routes.lng')}/${i18next.t(
          'routes.market_subjects'
        )}`,
      },
      {
        type: 'button',
        id: 2,
        label: 'gde_website_text',
        url: 'https://www.gde.esios.ree.es/',
      },
    ];

    let renderTable = async () => {
      fecha.day = moment($('#datepicker').val(), 'DD / MM / YYYY').format('DD');
      fecha.month = moment($('#datepicker').val(), 'DD / MM / YYYY').format(
        'MM'
      );
      fecha.year = moment($('#datepicker').val(), 'DD / MM / YYYY').format(
        'YYYY'
      );

      let currentHour = moment().tz('Europe/Madrid').format('HH');
      let currentMinutes = moment().tz('Europe/Madrid').format('mm');
      auctionsData =
        await servicesIntercambiosInternacionales.getAuctionsDataFromWidget({
          lang: state.exampleReducer.currentLang,
          time: `${currentHour}:${currentMinutes}`,
          ...fecha,
        });

      auctionsPortugalData = await servicesIntercambiosInternacionales
        .getAuctionsPortugalDataFromWidget({
          lang: state.exampleReducer.currentLang,
          time: `${currentHour}:${currentMinutes}`,
          ...fecha,
        })
        .then((data) => {
          spinnerNone('spinner10');
          return data;
        });
      const tableData = auctionsData.indicators;
      const newTable = tableData.concat(auctionsPortugalData.indicators);
      setInfoModal(
        0,
        {
          ...auctionsData,
          indicators: newTable,
        },
        templateShareModal('explicit-auctions-results', window.location.search),
        'all'
      );
      let miArrayTab =
        intercambiosInternacionalesContent.tableContent(newTable);
      let tableContent1 = document.getElementById('tab-myp-content1');
      tableContent1.innerHTML = miArrayTab.tabsContent[0].content;
      let tableContent2 = document.getElementById('tab-myp-content2');
      tableContent2.innerHTML = miArrayTab.tabsContent[1].content;
      let tableContent3 = document.getElementById('tab-myp-content3');
      tableContent3.innerHTML = miArrayTab.tabsContent[2].content;
      let tableContent4 = document.getElementById('tab-myp-content4');
      tableContent4.innerHTML = miArrayTab.tabsContent[3].content;

      componentTabs.render(miArrayTab, '#tabwithcontent');
    };

    const updateChars = async (currentDay = true) => {
      const inputCalendar = document.querySelector('#datepicker');
      let divCalendar = document.querySelector('.is-day-selectable');
      //inputCalendar.setAttribute('disabled', 'true');
      /// divCalendar.classList.add('disabled');
      if (currentDay) {
        date = moment().tz('Europe/Madrid').format('DD/MM/YYYY');
      } else {
        date = $('#datepicker').val();
      }

      let datepickerDate = date.split('/');
      fecha.day = datepickerDate[0];
      fecha.month = datepickerDate[1];
      fecha.year = datepickerDate[2];

      setParameter('date', moment(date, 'DD/MM/YYYY').format('DD-MM-YYYY'));

      // NEW OBJTOOLBAR
      let currDate = getUrlParams().date || moment().format('DD-MM-YYYY');
      let dayBefore = moment(currDate, 'DD-MM-YYYY')
        .subtract('1', 'day')
        .format('DD-MM-YYYY');
      objToolbar = [
        {
          id: 3,
          slug: i18next.t('routes.wind-forecast'),
          info: true,
          share: true,
          favorite: true,
          favId: 544,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/544?vis=1&start_date=${currDate}T00%3A00&end_date=${currDate}T23%3A55&compare_start_date=${dayBefore}T00%3A00`,
        },
        {
          id: 4,
          slug: i18next.t('routes.wind-forecast_peninsular'),
          info: true,
          share: true,
          favorite: true,
          favId: 541,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/541?start_date=${dateSetTime()}&geoids=`,
        },
        {
          id: 5,
          slug: i18next.t('routes.photovoltaic-solar-forecast'),
          info: true,
          share: true,
          favorite: true,
          favId: 542,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/542?start_date=${dateSetTime()}&geoids=`,
        },
        {
          id: 6,
          slug: i18next.t('routes.solar-thermal-forecast'),
          info: true,
          share: true,
          favorite: true,
          favId: 543,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/543?start_date=${dateSetTime()}&geoids=`,
        },
        {
          id: 7,
          slug: i18next.t('routes.daily-forecast-d1-demand'),
          info: true,
          share: true,
          favorite: true,
          favId: 1775,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/1775?start_date=${dateSetTime()}&geoids=`,
        },
        {
          id: 8,
          slug: i18next.t('routes.daily-forecast-d1-wind-plus-photovoltaic'),
          info: true,
          share: true,
          favorite: true,
          favId: 10358,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/10358?start_date=${dateSetTime()}&geoids=`,
        },
        {
          id: 9,
          slug: i18next.t('routes.intraday-forecast-h3-demand'),
          info: true,
          share: true,
          favorite: true,
          favId: 1776,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/1776?start_date=${dateSetTime()}&geoids=`,
        },
        {
          id: 10,
          slug: i18next.t(
            'routes.intraday-forecast-h3-more-photovoltaic-demand'
          ),
          info: true,
          share: true,
          favorite: true,
          favId: 10359,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/10359?start_date=${dateSetTime()}&geoids=`,
        },
        {
          id: 11,
          slug: i18next.t('routes.annual-demand-forecast'),
          info: true,
          share: true,
          favorite: true,
          favId: 1774,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/1774?start_date=${dateSetTime()}&geoids=`,
        },
        {
          id: 12,
          slug: i18next.t('routes.demand-or-forecasts'),
          info: true,
          share: true,
          favorite: true,
          favId: 544,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/544?start_date=${dateSetTime()}&geoids=`,
        },
        {
          id: 13,
          slug: i18next.t('routes.snp-forecast-demand'),
          info: true,
          share: true,
          favorite: true,
          favId: 1742,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/1742?start_date=${dateSetTime()}&geoids=`,
        },
        {
          id: 14,
          slug: i18next.t(
            'routes.daily-forecast-peninsular-electricity-demand'
          ),
          info: true,
          share: true,
          favorite: true,
          favId: 460,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/460?start_date=${dateSetTime()}&geoids=`,
        },
        {
          id: 15,
          slug: i18next.t(
            'routes.monthly-forecast-peninsular-electricity-demand'
          ),
          info: true,
          share: true,
          favorite: true,
          favId: 461,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/461?start_date=${dateSetTime()}&geoids=`,
        },
        {
          id: 16,
          slug: i18next.t(
            'routes.weekly-forecast-peninsular-electricity-demand'
          ),
          info: true,
          share: true,
          favorite: true,
          favId: 603,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/603?start_date=${dateSetTime()}&geoids=`,
        },
        {
          id: 17,
          slug: i18next.t(
            'routes.maximum-annual-forecast-peninsular-electricity-demand'
          ),
          info: true,
          share: true,
          favorite: true,
          favId: 620,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/620?start_date=${dateSetTime()}&geoids=`,
        },
        {
          id: 18,
          slug: i18next.t('routes.solar-planned-generation'),
          info: true,
          share: true,
          favorite: true,
          favId: 10034,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/10034?start_date=${dateSetTime()}&geoids=`,
        },
        {
          id: 19,
          slug: i18next.t(
            'routes.maximum-annual-forecast-peninsular-electricity-demand'
          ),
          info: true,
          share: true,
          favorite: true,
          favId: 541,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/541?start_date=${dateSetTime()}&geoids=`,
        },
        {
          id: 20,
          slug: i18next.t(
            'routes.minimum-annual-forecast-peninsular-electricity-demand'
          ),
          info: true,
          share: true,
          favorite: true,
          favId: 621,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/621?start_date=${dateSetTime()}&geoids=`,
        },
        {
          id: 21,
          slug: i18next.t(
            'routes.maximum-monthly-forecast-peninsular-electricity-demand'
          ),
          info: true,
          share: true,
          favorite: true,
          favId: 604,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/604?start_date=${dateSetTime()}&geoids=`,
        },
        {
          id: 22,
          slug: i18next.t(
            'routes.minimum-monthly-forecast-peninsular-electricity-demand'
          ),
          info: true,
          share: true,
          favorite: true,
          favId: 619,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/619?start_date=${dateSetTime()}&geoids=`,
        },
        {
          id: 23,
          slug: i18next.t(
            'routes.available-generation-margin-maximum-forecast'
          ),
          info: true,
          share: true,
          favorite: true,
          favId: 622,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/622?start_date=${dateSetTime()}&geoids=`,
        },
        {
          id: 24,
          slug: i18next.t('routes.residual-demand-forecast'),
          info: true,
          share: true,
          favorite: true,
          favId: 10249,
          analysis: true,
          url: () =>
            `/${i18next.t('routes.lng')}/${i18next.t(
              'routes.analysis'
            )}/10249?start_date=${dateSetTime()}&geoids=`,
        },
      ];
      let shareModalObj = [
        {
          id: 2,
          slug: 'prevision-eolica',
          needSlug: false,
          size: {
            height: 596,
            width: '100%',
          },
        },
        {
          id: 3,
          slug: 'wind-forecast_peninsular',
        },
        {
          id: 4,
          slug: 'photovoltaic-solar-forecast',
        },
        {
          id: 5,
          slug: 'solar-thermal-forecast',
        },
        {
          id: 6,
          slug: 'daily-forecast-d1-demand',
        },
        {
          id: 7,
          slug: 'daily-forecast-d1-wind-plus-photovoltaic',
        },
        {
          id: 8,
          slug: 'intraday_forecast_H+3_demand',
        },
        {
          id: 9,
          slug: 'intraday_forecast_H+3_more_photovoltaic_demand',
        },
        {
          id: 10,
          slug: 'annual_demand_forecast',
        },
        {
          id: 11,
          slug: 'peninsular_forecast_demand',
        },
        {
          id: 12,
          slug: 'snp_forecast_demand',
        },
        {
          id: 13,
          slug: 'daily_forecast_of_peninsular_electricity_demand',
        },
        {
          id: 14,
          slug: 'monthly_forecast_of_peninsular_electricity_demand',
        },
        {
          id: 15,
          slug: 'weekly_forecast_of_peninsular_electricity_demand',
        },
        {
          id: 16,
          slug: 'maximum_annual_forecast_of_peninsular_electricity_demand',
        },
        {
          id: 17,
          slug: 'solar_planned_generación',
        },
        {
          id: 18,
          slug: 'minimum_annual_forecast_of_peninsular_electricity_demand',
        },
        {
          id: 19,
          slug: 'maximum_monthly_forecast_of_peninsular_electricity_demand',
        },
        {
          id: 20,
          slug: 'minimum_monthly_forecast_of_peninsular_electricity_demand',
        },
        {
          id: 21,
          slug: 'available_generation_margin_Maximum_forecast',
        },
        {
          id: 22,
          slug: 'residual_demand_forecast',
        },
      ];

      const ShowSpinner = (idRect, idSpinner) => {
        document.getElementById(idRect).classList.add('hidden');
        document.getElementById(idRect).style.display = 'flex';
      };
      ShowSpinner('mypNoDataRect12', 'spinner12');
      ShowSpinner('mypNoDataRect13', 'spinner13');
      ShowSpinner('mypNoDataRect14', 'spinner14');
      ShowSpinner('mypNoDataRect15', 'spinner15');
      ShowSpinner('mypNoDataRect16', 'spinner16');
      ShowSpinner('mypNoDataRect17', 'spinner17');
      ShowSpinner('mypNoDataRect18', 'spinner18');
      ShowSpinner('mypNoDataRect19', 'spinner19');
      ShowSpinner('mypNoDataRect20', 'spinner20');
      ShowSpinner('mypNoDataRect21', 'spinner21');
      ShowSpinner('mypNoDataRect22', 'spinner22');
      ShowSpinner('mypNoDataRect23', 'spinner23');
      ShowSpinner('mypNoDataRect24', 'spinner24');
      ShowSpinner('mypNoDataRect25', 'spinner25');
      ShowSpinner('mypNoDataRect26', 'spinner26');
      ShowSpinner('mypNoDataRect27', 'spinner27');
      ShowSpinner('mypNoDataRect28', 'spinner28');
      ShowSpinner('mypNoDataRect29', 'spinner29');
      ShowSpinner('mypNoDataRect30', 'spinner30');
      ShowSpinner('mypNoDataRect31', 'spinner31');
      ShowSpinner('mypNoDataRect32', 'spinner32');

      const tab1 = document.querySelector('[data-tab-target="TAB1"]');

      // Simular el clic en el TAB1
      if (tab1) {
        tab1.click();
      }
      // await renderFacturacionGraphAgain();
      // await renderDesvioGraphAgain();
      // await renderFotovoltaicaGraphAgain();
      // await renderTermicaGraphAgain();

      // await renderDDemandaGraphAgain();
      // await renderDEolicaFotovoltaicaGraphAgain();
      // await renderIDDemandaGraphAgain();
      // await renderIDDemandaFotovoltaicaGraphAgain();
      // await renderDAnualDemandaGraphAgain();

      // await renderDemandaPrevistaPeninsularGraphAgain();
      // await renderDemandaPrevistaSNPGraphAgain();
      // await renderDemandaDiariaElectricaPeninsularGraphAgain();
      // await renderDemandaMensualElectricaPeninsularGraphAgain();
      // await renderDemandaSemanalElectricaPeninsularGraphAgain();
      // await renderPrevisionMaximaAnualPeninsularGraphAgain();
      // //await renderGeneracionPrevistaSolarGraphAgain();
      // await renderPrevisionMaximaAnualPeninsular2GraphAgain();
      // await renderPrevisionMinimaAnualPeninsularGraphAgain();
      // await renderPrevisionMaximaMensualPeninsularGraphAgain();
      // await renderPrevisionMinimaMensualPeninsularGraphAgain();
      // await renderMargenGeneracionGraphAgain();
      // await renderPrevisonDemandaResidualGraphAgain();
      // await renderTable();

      inputCalendar.removeAttribute('disabled');
      divCalendar.classList.remove('disabled');
      return inputCalendar.value;
    };

    try {
      let loaded = {
        6: false,
        7: false,
        8: false,
        9: false,
      };

      const loadedCheck = (id) => {
        loaded[id] = true;
        let allLoaded = Object.values(loaded).some(
          (isLoaded) => isLoaded == false
        );

        let inputCalendar = document.querySelector('#datepicker');
        let divCalendar = document.querySelector('.is-day-selectable');
        inputCalendar.removeAttribute('disabled');
        divCalendar.classList.remove('disabled');
      };

      templatePrevisionesCircular(
        //context,
        handlers,
        pageTitle,
        objMarket,
        listMarket,
        documents,
        buttons
      );
      onChangeLetra(null);
      let inputCalendar = document.querySelector('#datepicker');
      let divCalendar = document.querySelector('.is-day-selectable');

      const RapidAccessData = [
        {
          id: '.mypPrevisionSemanalDemandaWidgetView',
          title: 'prevision-eolica',
        },
        {
          id: '.mypPrevisionEolicaWidgetView',
          title: 'deviations_cost_in_real_time',
        },
        {
          id: 'mypPrevisionFotovoltaicaWidgetView',
          tittle: 'photovoltaic-solar-forecast',
        },
        {
          id: 'myPrevisionSolarTerWidgetView',
          tittle: 'nn',
        },
        {
          id: 'myPrevisionDiariaD1Wiew',
          tittle: 'bb',
        },
        {
          id: 'myPrevisionIntraDiariaH3DemandaWiew',
          tittle: 'cc',
        },
        {
          id: 'myPrevisionIntraDiariaH3DemandaFotovolWiew',
          tittle: 'dd',
        },
        {
          id: 'myPrevisionDemandaAnualWiew',
          tittle: 'ee',
        },
        {
          id: 'myDemandaPrevistaPeninsularWiew',
          tittle: 'ff',
        },
        {
          id: 'myDemandaPrevistaSNPWiew',
          tittle: 'gg',
        },
        {
          id: 'myDemandaDiariaElectricaPeninsularWiew',
          tittle: 'hh',
        },
        {
          id: 'myDemandaMensualElectricaPeninsularWiew',
          tittle: 'ii',
        },
        {
          id: 'myDemandaSemanalElectricaPeninsularWiew',
          tittle: 'oo',
        },
        {
          id: 'myPrevisionMaximaAnualPeninsularWiew',
          tittle: 'uu',
        },
        {
          id: 'myGeneracionPrevistaSolarWiew',
          tittle: 'pp',
        },
        {
          id: 'myPrevisionMaximaAnualPeninsular2Wiew',
          tittle: 'll',
        },
        {
          id: 'myPrevisionMinimaAnualPeninsularWiew',
          tittle: 'rr',
        },
        {
          id: 'myPrevisionMaximaMensualPeninsularWiew',
          tittle: 'yy',
        },
        {
          id: 'myPrevisionMinimaMensualPeninsularWiew',
          tittle: 'ss',
        },
        {
          id: 'myMargenGeneracionWiew',
          tittle: 'qq',
        },
        {
          id: 'myPrevisonDemandaResidualWiew',
          tittle: 'ww',
        },
        {
          id: 'myPrevisionDiariaD1Wiew',
          tittle: 'tt',
        },
        {
          id: '.esios-layout__subcontent',
          title: 'more',
        },
      ];
      componentRapidAccess.render(RapidAccessData);

      if (urlParams.get('date')) {
        $('#datepicker').datepicker(
          'setDate',
          moment(urlParams.get('date'), 'DD-MM-YYYY').format('DD / MM / YYYY')
        );
      } else {
        setParameter(
          'date',
          moment(document.getElementById('datepicker').value, 'DD / MM / YYYY')
            .tz('Europe/Madrid')
            .format('DD-MM-YYYY')
        );
        $('#datepicker').datepicker(
          'setDate',
          moment(document.getElementById('datepicker').value, 'DD / MM / YYYY')
            .tz('Europe/Madrid')
            .format('DD / MM / YYYY')
        );
      }

      let appDate = moment(urlParams.get('date'), 'DD-MM-YYYY').format(
        'DD-MM-YYYY'
      );
      let todayDate = moment().tz('Europe/Madrid').format('DD-MM-YYYY');
      let volumeTime = state.exampleReducer.pages.myp.volumeHour;
      selectTimeForVolume.currentHour =
        appDate === todayDate ? volumeTime.split(':')[0] : '00';
      selectTimeForVolume.currentMinutes = '00';
      let unitaryCostTime = state.exampleReducer.pages.myp.unitaryHour;
      selectTimeForCost.currentHour =
        appDate === todayDate ? unitaryCostTime.split(':')[0] : '00';
      selectTimeForCost.currentMinutes = '00';

      $(document).on('change', '#datepicker', () => {
        updateChars(false);
      });

      Utils.clickHoy('datepicker', updateChars, true);

      let currentDate = moment(
        document.getElementById('datepicker').value,
        'DD / MM / YYYY'
      ).format('DD-MM-YYYY');

      fecha.day = moment($('#datepicker').val(), 'DD / MM / YYYY').format('DD');
      fecha.month = moment($('#datepicker').val(), 'DD / MM / YYYY').format(
        'MM'
      );
      fecha.year = moment($('#datepicker').val(), 'DD / MM / YYYY').format(
        'YYYY'
      );

      servicesMercadosPrecios
        .getDesvioDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget27').style.display = 'block';
          spinnerNone('spinner27', 'mypPrevisionEolicaWidgetView');
          setInfoModal(
            1,
            data,
            templateShareModal('wind-forecast', window.location.search, false, {
              height: 596,
              width: '100%',
            }),
            'all'
          );
          renderPrevisionesCircular.renderDesviosWidget(data);
          window.addEventListener('resize', () => {
            document.querySelector('#widget27 svg').remove();
            renderPrevisionesCircular.renderDesviosWidget(data);
          });
        })
        .finally(() => {
          loadedCheck(8);
        });

      servicesMercadosPrecios
        .getFotovoltaicaDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget28').style.display = 'block';
          spinnerNone('spinner28', 'mypPrevisionFotovoltaicaWidgetView');
          setInfoModal(
            2,
            data,
            templateShareModal(
              'photovoltaic-solar-forecast',
              window.location.search,
              false,
              {
                height: 596,
                width: '100%',
              }
            ),
            'all'
          );
          renderPrevisionesCircular.renderFotovoltaicaWidget(data);
          window.addEventListener('resize', () => {
            document.querySelector('#widget28 svg').remove();
            renderPrevisionesCircular.renderFotovoltaicaWidget(data);
          });
        })
        .finally(() => {
          loadedCheck(8);
        });

      servicesMercadosPrecios
        .getTermicaDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget29').style.display = 'block';
          spinnerNone('spinner29', 'myPrevisionSolarTerWidgetView');
          setInfoModal(
            3,
            data,
            templateShareModal(
              'solar-thermal-forecast',
              window.location.search,
              false,
              {
                height: 596,
                width: '100%',
              }
            ),
            'all'
          );
          renderPrevisionesCircular.renderTermicaWidget(data);
          window.addEventListener('resize', () => {
            document.querySelector('#widget29 svg').remove();
            renderPrevisionesCircular.renderTermicaWidget(data);
          });
        })
        .finally(() => {
          loadedCheck(8);
        });

      servicesMercadosPrecios
        .getDDemandaDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget30').style.display = 'block';
          spinnerNone('spinner30', 'myPrevisionDiariaD1Wiew');
          setInfoModal(
            4,
            data,
            templateShareModal(
              'daily-forecast-d1-demand',
              window.location.search,
              false,
              {
                height: 614,
                width: '100%',
              }
            ),
            'all'
          );

          renderPrevisionesCircular.renderPrevisionDiariaD1DemandaWidget(data);
          window.addEventListener('resize', () => {
            document.querySelector('#widget30 svg').remove();
            renderPrevisionesCircular.renderPrevisionDiariaD1DemandaWidget(
              data
            );
          });
        })
        .finally(() => {
          loadedCheck(9);
        });

      servicesMercadosPrecios
        .getDEolicaDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget31').style.display = 'block';
          spinnerNone('spinner31', 'myPrevisionDiariaD1EolicaWiew');
          setInfoModal(
            5,
            data,
            templateShareModal(
              'daily-forecast-d1-wind-plus-photovoltaic',
              window.location.search,
              false,
              {
                height: 614,
                width: '100%',
              }
            ),
            'all'
          );

          renderPrevisionesCircular.renderPrevisionDiariaD1EolicaWidget(data);
          window.addEventListener('resize', () => {
            document.querySelector('#widget31 svg').remove();
            renderPrevisionesCircular.renderPrevisionDiariaD1EolicaWidget(data);
          });
        })
        .finally(() => {
          loadedCheck(9);
        });

      servicesMercadosPrecios
        .getIDDemandaDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget32').style.display = 'block';
          spinnerNone('spinner32', 'myPrevisionIntraDiariaH3DemandaWiew');
          setInfoModal(
            6,
            data,
            templateShareModal(
              'intraday-forecast-h3-demand',
              window.location.search,
              false,
              {
                height: 614,
                width: '100%',
              }
            ),
            'all'
          );

          renderPrevisionesCircular.renderPrevisionIntraDiariaD1DemandaWidget(
            data
          );
          window.addEventListener('resize', () => {
            document.querySelector('#widget32 svg').remove();
            renderPrevisionesCircular.renderPrevisionIntraDiariaD1DemandaWidget(
              data
            );
          });
        })
        .finally(() => {
          loadedCheck(9);
        });

      servicesMercadosPrecios
        .getPDemandaAnualDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget12').style.display = 'block';
          spinnerNone('spinner12', 'myPrevisionDemandaAnualWiew');
          setInfoModal(
            8,
            data,
            templateShareModal(
              'annual-demand-forecast',
              window.location.search,
              false,
              {
                height: 614,
                width: '100%',
              }
            ),
            'all'
          );

          renderPrevisionesCircular.renderPrevisionDemandaAnualWidget(data);
          window.addEventListener('resize', () => {
            document.querySelector('#widget12 svg').remove();
            renderPrevisionesCircular.renderPrevisionDemandaAnualWidget(data);
          });
        })
        .finally(() => {
          loadedCheck(9);
        });

      servicesMercadosPrecios
        .getIDEolicaDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget33').style.display = 'block';
          spinnerNone(
            'spinner33',
            'myPrevisionIntraDiariaH3DemandaFotovolWiew'
          );
          setInfoModal(
            7,
            data,
            templateShareModal(
              'intraday-forecast-h3-more-photovoltaic-demand',
              window.location.search,
              false,
              {
                height: 614,
                width: '100%',
              }
            ),
            'all'
          );

          renderPrevisionesCircular.renderPrevisionIntraDiariaD1DemandaFotovoltaicaWidget(
            data
          );
          window.addEventListener('resize', () => {
            document.querySelector('#widget33 svg').remove();
            renderPrevisionesCircular.renderPrevisionIntraDiariaD1DemandaFotovoltaicaWidget(
              data
            );
          });
        })
        .finally(() => {
          loadedCheck(9);
        });

      servicesMercadosPrecios
        .getDemandaPrevistaPeninsularDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget13').style.display = 'block';
          spinnerNone('spinner13', 'myDemandaPrevistaPeninsularWiew');
          setInfoModal(
            9,
            data,
            templateShareModal(
              'demand-or-forecasts',
              window.location.search,
              false,
              {
                height: 614,
                width: '100%',
              }
            ),
            'all'
          );

          renderPrevisionesCircular.renderDemandaPrevistaPeninsularWidget(data);
          window.addEventListener('resize', () => {
            document.querySelector('#widget13 svg').remove();
            renderPrevisionesCircular.renderDemandaPrevistaPeninsularWidget(
              data
            );
          });
        })
        .finally(() => {
          loadedCheck(9);
        });

      servicesMercadosPrecios
        .getDemandaPrevistaSNPDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget14').style.display = 'block';
          spinnerNone('spinner14', 'myDemandaPrevistaSNPWiew');
          setInfoModal(
            10,
            data,
            templateShareModal(
              'snp-forecast-demand',
              window.location.search,
              false,
              {
                height: 614,
                width: '100%',
              }
            ),
            'all'
          );

          renderPrevisionesCircular.renderDemandaPrevistaSNPWidget(data);
          window.addEventListener('resize', () => {
            document.querySelector('#widget14 svg').remove();
            renderPrevisionesCircular.renderDemandaPrevistaSNPWidget(data);
          });
        })
        .finally(() => {
          loadedCheck(9);
        });

      servicesMercadosPrecios
        .getDemandaDiariaElectricaPeninsularDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget15').style.display = 'block';
          spinnerNone('spinner15', 'myDemandaDiariaElectricaPeninsularWiew');
          setInfoModal(
            11,
            data,
            templateShareModal(
              'daily-forecast-peninsular-electricity-demand',
              window.location.search,
              false,
              {
                height: 614,
                width: '100%',
              }
            ),
            'all'
          );

          renderPrevisionesCircular.renderDemandaDiariaElectricaPeninsularWidget(
            data
          );
          window.addEventListener('resize', () => {
            document.querySelector('#widget15 svg').remove();
            renderPrevisionesCircular.renderDemandaDiariaElectricaPeninsularWidget(
              data
            );
          });
        })
        .finally(() => {
          loadedCheck(9);
        });

      servicesMercadosPrecios
        .getDemandaMensualElectricaPeninsularDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget16').style.display = 'block';
          spinnerNone('spinner16', 'myDemandaMensualElectricaPeninsularWiew');
          setInfoModal(
            12,
            data,
            templateShareModal(
              'monthly-forecast-peninsular-electricity-demand',
              window.location.search,
              false,
              {
                height: 614,
                width: '100%',
              }
            ),
            'all'
          );

          renderPrevisionesCircular.renderDemandaMensualElectricaPeninsularWidget(
            data
          );
          window.addEventListener('resize', () => {
            document.querySelector('#widget16 svg').remove();
            renderPrevisionesCircular.renderDemandaMensualElectricaPeninsularWidget(
              data
            );
          });
        })
        .finally(() => {
          loadedCheck(9);
        });

      servicesMercadosPrecios
        .getDemandaSemanalElectricaPeninsularDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget17').style.display = 'block';
          spinnerNone('spinner17', 'myDemandaSemanalElectricaPeninsularWiew');
          setInfoModal(
            13,
            data,
            templateShareModal(
              'weekly-forecast-peninsular-electricity-demand',
              window.location.search,
              false,
              {
                height: 614,
                width: '100%',
              }
            ),
            'all'
          );

          renderPrevisionesCircular.renderDemandaSemanalElectricaPeninsularWidget(
            data
          );
          window.addEventListener('resize', () => {
            document.querySelector('#widget17 svg').remove();
            renderPrevisionesCircular.renderDemandaSemanalElectricaPeninsularWidget(
              data
            );
          });
        })
        .finally(() => {
          loadedCheck(9);
        });

      servicesMercadosPrecios
        .getPrevisionMaximaAnualPeninsularDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget18').style.display = 'block';
          spinnerNone('spinner18', 'myPrevisionMaximaAnualPeninsularWiew');
          setInfoModal(
            14,
            data,
            templateShareModal(
              'maximum-annual-forecast-peninsular-electricity-demand',
              window.location.search,
              false,
              {
                height: 614,
                width: '100%',
              }
            ),
            'all'
          );

          renderPrevisionesCircular.renderPrevisionMaximaAnualPeninsularWidget(
            data
          );
          window.addEventListener('resize', () => {
            document.querySelector('#widget18 svg').remove();
            renderPrevisionesCircular.renderPrevisionMaximaAnualPeninsularWidget(
              data
            );
          });
        })
        .finally(() => {
          loadedCheck(9);
        });

      servicesMercadosPrecios
        .getGeneracionPrevistaSolarDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget19').style.display = 'block';
          spinnerNone('spinner19', 'myGeneracionPrevistaSolarWiew');
          setInfoModal(
            15,
            data,
            templateShareModal(
              'solar-planned-generation',
              window.location.search,
              false,
              {
                height: 614,
                width: '100%',
              }
            ),
            'all'
          );

          renderPrevisionesCircular.renderGeneracionPrevistaSolarWidget(data);
          window.addEventListener('resize', () => {
            document.querySelector('#widget19 svg').remove();
            renderPrevisionesCircular.renderGeneracionPrevistaSolarWidget(data);
          });
        })
        .finally(() => {
          loadedCheck(9);
        });

      servicesMercadosPrecios
        .getPrevisionMaximaAnualPeninsular2DataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget20').style.display = 'block';
          spinnerNone('spinner20', 'myPrevisionMaximaAnualPeninsular2Wiew');
          setInfoModal(
            16,
            data,
            templateShareModal(
              'maximum-annual-forecast-peninsular-electricity-demand',
              window.location.search,
              false,
              {
                height: 614,
                width: '100%',
              }
            ),
            'all'
          );

          renderPrevisionesCircular.renderPrevisionMaximaAnualPeninsular2Widget(
            data
          );
          window.addEventListener('resize', () => {
            document.querySelector('#widget20 svg').remove();
            renderPrevisionesCircular.renderPrevisionMaximaAnualPeninsular2Widget(
              data
            );
          });
        })
        .finally(() => {
          loadedCheck(9);
        });

      servicesMercadosPrecios
        .getPrevisionMinimaAnualPeninsularDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget21').style.display = 'block';
          spinnerNone('spinner21', 'myPrevisionMinimaAnualPeninsularWiew');
          setInfoModal(
            17,
            data,
            templateShareModal(
              'minimum-annual-forecast-peninsular-electricity-demand',
              window.location.search,
              false,
              {
                height: 614,
                width: '100%',
              }
            ),
            'all'
          );

          renderPrevisionesCircular.renderPrevisionMinimaAnualPeninsularWidget(
            data
          );
          window.addEventListener('resize', () => {
            document.querySelector('#widget21 svg').remove();
            renderPrevisionesCircular.renderPrevisionMinimaAnualPeninsularWidget(
              data
            );
          });
        })
        .finally(() => {
          loadedCheck(9);
        });

      servicesMercadosPrecios
        .getPrevisionMaximaMensualPeninsularDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget22').style.display = 'block';
          spinnerNone('spinner22', 'myPrevisionMaximaMensualPeninsularWiew');
          setInfoModal(
            18,
            data,
            templateShareModal(
              'maximum-monthly-forecast-peninsular-electricity-demand',
              window.location.search,
              false,
              {
                height: 614,
                width: '100%',
              }
            ),
            'all'
          );

          renderPrevisionesCircular.renderPrevisionMaximaMensualPeninsularWidget(
            data
          );
          window.addEventListener('resize', () => {
            document.querySelector('#widget22 svg').remove();
            renderPrevisionesCircular.renderPrevisionMaximaMensualPeninsularWidget(
              data
            );
          });
        })
        .finally(() => {
          loadedCheck(9);
        });

      servicesMercadosPrecios
        .getPrevisionMinimaMensualPeninsularDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget23').style.display = 'block';
          spinnerNone('spinner23', 'myPrevisionMinimaMensualPeninsularWiew');
          setInfoModal(
            19,
            data,
            templateShareModal(
              'minimum-monthly-forecast-peninsular-electricity-demand',
              window.location.search,
              false,
              {
                height: 614,
                width: '100%',
              }
            ),
            'all'
          );

          renderPrevisionesCircular.renderPrevisionMinimaMensualPeninsularWidget(
            data
          );
          window.addEventListener('resize', () => {
            document.querySelector('#widget23 svg').remove();
            renderPrevisionesCircular.renderPrevisionMinimaMensualPeninsularWidget(
              data
            );
          });
        })
        .finally(() => {
          loadedCheck(9);
        });

      servicesMercadosPrecios
        .getMargenGeneracionDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget24').style.display = 'block';
          spinnerNone('spinner24', 'myMargenGeneracionWiew');
          setInfoModal(
            20,
            data,
            templateShareModal(
              'available-generation-margin-maximum-forecast',
              window.location.search,
              false,
              {
                height: 614,
                width: '100%',
              }
            ),
            'all'
          );

          renderPrevisionesCircular.renderMargenGeneracionWidget(data);
          window.addEventListener('resize', () => {
            document.querySelector('#widget24 svg').remove();
            renderPrevisionesCircular.renderMargenGeneracionWidget(data);
          });
        })
        .finally(() => {
          loadedCheck(9);
        });

      servicesMercadosPrecios
        .getPrevisonDemandaResidualDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget25').style.display = 'block';
          spinnerNone('spinner25', 'myPrevisonDemandaResidualWiew');
          setInfoModal(
            21,
            data,
            templateShareModal(
              'residual-demand-forecast',
              window.location.search,
              false,
              {
                height: 614,
                width: '100%',
              }
            ),
            'all'
          );

          renderPrevisionesCircular.renderPrevisonDemandaResidualWidget(data);
          window.addEventListener('resize', () => {
            document.querySelector('#widget25 svg').remove();
            renderPrevisionesCircular.renderPrevisonDemandaResidualWidget(data);
          });
        })
        .finally(() => {
          loadedCheck(9);
        });

      servicesMercadosPrecios
        .getFacturationDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget26').style.display = 'block';
          spinnerNone('spinner26', 'mypFacturacionWidgetView');
          setInfoModal(
            0,
            data,
            templateShareModal(
              'demand-or-forecasts',
              window.location.search,
              false,
              {
                height: 614,
                width: '100%',
              }
            ),
            'all'
          );
          renderPrevisionesCircular.renderFacturationWidget(data);
          window.addEventListener('resize', () => {
            document.querySelector('#widget26 svg').remove();
            renderPrevisionesCircular.renderFacturationWidget(data);
          });
        })
        .finally(() => {
          loadedCheck(9);
        });
      let moreData = await servicesMercadosPrecios.getMoreData({
        lang: state.exampleReducer.currentLang,
      });
      renderPrevisionesCircular.renderMoreDataWidget(moreData);
      componentTimeSelector.render(
        selectTimeForVolume,
        '#mypVolumenWidgetViewSelector'
      );
      componentTimeSelector.render(
        selectTimeForCost,
        '#mypCosteWidgetViewSelector'
      );

      let newDLArray = {
        renderAgain: (form) => renderComponentDocumentList(form),
      };

      componentDocumentList.render(newDLArray, '#document-list-myp');

      //renderTable();

      let modalContainer = document.getElementById('myp__modals');
      document.querySelectorAll('.share__modal').forEach((el) => {
        modalContainer.append(el);
      });
      document
        .querySelectorAll('.esios-market-prices__container .share__modal')
        .forEach((el) => {
          el.remove();
        });
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }

    document
      .querySelector('.esios-layout__wrap')
      ?.classList.add('esios-layout__wrap--previsiones-os');
    document
      .querySelector('.esios-layout__breadcrumbs')
      ?.classList.add('esios-layout__breadcrumbs--previsiones');
  };

  let setInfoModal = (id, data, html, updateShareModal = 'all') => {
    if (updateShareModal !== 'share' && data != undefined) {
      objToolbar[id].textModal = `<h2>${data.name}</h2>${data.description}`;
    }
    objToolbar[id].widgetData = data;
    objToolbar[id].shareModal = html;
    componentToolbar.render(objToolbar[id], '.esios-toolbar' + (id + 1), false);
  };

  let postRenderComponent = () => {
    let tabs = document.querySelectorAll('.ui-tabs-anchor');

    for (let tab of tabs) {
      tab.addEventListener('click', () => {
        let button = document.getElementById('calendar-button');
        let value = tab.innerHTML.trim();

        if (value.includes('FR')) {
          button.hash = '#fra';
        } else {
          button.hash = '#por';
        }
      });
    }
  };

  let init = async () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    await renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  let renderComponentDocumentList = async (form) => {
    filterForm.ambit = form.ambit;
    filterForm.action = form.actions;
    filterForm.category = form.category;
    filterForm.process = form.process;
    filterForm.query = form.query;
    let documentListData = await servicesMercadosPrecios.getArchives(
      filterForm
    );

    let newDLArray = {
      data: documentListData.newIndicators,
      renderAgain: (form) => renderComponentDocumentList(form),
    };
    componentDocumentList.render(newDLArray, '#document-list-myp');
  };

  let renderFotovoltaicaGraphAgain = async () => {
    const state = store.getState();

    try {
      spinnerTrue('spinner28', 'mypPrevisionFotovoltaicaWidgetView');
      desviosData = await servicesMercadosPrecios
        .getFotovoltaicaDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget28').style.display = 'block';
          spinnerNone('spinner28', 'mypPrevisionFotovoltaicaWidgetView');
          document
            .querySelector('#mypPrevisionFotovoltaicaWidgetView svg')
            .remove();
          renderPrevisionesCircular.renderFotovoltaicaWidget(data);

          window.addEventListener('resize', () => {
            document.querySelector('#widget28 svg').remove();
            renderPrevisionesCircular.renderFotovoltaicaWidget(data);
          });
          return data;
        });
      document.getElementById('mypNoDataRect28').removeAttribute('style');
      setInfoModal(
        2,
        desviosData,
        templateShareModal(
          'photovoltaic-solar-forecast',
          window.location.search,
          false,
          {
            height: 596,
            width: '100%',
          }
        ),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderTermicaGraphAgain = async () => {
    const state = store.getState();
    try {
      spinnerTrue('spinner29', 'myPrevisionSolarTerWidgetView');
      desviosData = await servicesMercadosPrecios
        .getTermicaDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget29').style.display = 'block';
          spinnerNone('spinner29', 'myPrevisionSolarTerWidgetView');
          document.querySelector('#myPrevisionSolarTerWidgetView svg').remove();
          renderPrevisionesCircular.renderTermicaWidget(data);

          window.addEventListener('resize', () => {
            document.querySelector('#widget29 svg').remove();
            renderPrevisionesCircular.renderTermicaWidget(data);
          });
          return data;
        });
      document.getElementById('mypNoDataRect29').removeAttribute('style');
      setInfoModal(
        3,
        desviosData,
        templateShareModal(
          'solar-thermal-forecast',
          window.location.search,
          false,
          {
            height: 596,
            width: '100%',
          }
        ),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderDesvioGraphAgain = async () => {
    const state = store.getState();

    try {
      spinnerTrue('spinner27', 'mypPrevisionEolicaWidgetView');
      desviosData = await servicesMercadosPrecios
        .getDesvioDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget27').style.display = 'block';
          spinnerNone('spinner27', 'mypPrevisionEolicaWidgetView');
          document.querySelector('#mypPrevisionEolicaWidgetView svg').remove();
          renderPrevisionesCircular.renderDesviosWidget(data);

          window.addEventListener('resize', () => {
            document.querySelector('#widget8 svg').remove();
            renderPrevisionesCircular.renderDesviosWidget(data);
          });
          return data;
        });
      document.getElementById('mypNoDataRect27').removeAttribute('style');
      setInfoModal(
        1,
        desviosData,
        templateShareModal('wind-forecast', window.location.search, false, {
          height: 596,
          width: '100%',
        }),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderFacturacionGraphAgain = async () => {
    const state = store.getState();
    selectTimeForCost.currentHour = '00';
    selectTimeForCost.currentMinutes = '00';
    try {
      spinnerTrue('spinner26', 'mypPrevisionSemanalDemandaWidgetView');
      facturationData = await servicesMercadosPrecios
        .getFacturationDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget26').style.display = 'block';
          spinnerNone('spinner26', 'mypPrevisionSemanalDemandaWidgetView');
          document
            .querySelector('#mypPrevisionSemanalDemandaWidgetView svg')
            .remove();
          renderPrevisionesCircular.renderFacturationWidget(data);

          window.addEventListener('resize', () => {
            document.querySelector('#widget26 svg').remove();
            renderPrevisionesCircular.renderFacturationWidget(data);
          });
          return data;
        });
      document.getElementById('mypNoDataRect26').removeAttribute('style');
      setInfoModal(
        0,
        facturationData,
        templateShareModal(
          'demand-or-forecasts',
          window.location.search,
          false,
          { height: 614, width: '100%' }
        ),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderDDemandaGraphAgain = async () => {
    const state = store.getState();
    selectTimeForCost.currentHour = '00';
    selectTimeForCost.currentMinutes = '00';
    try {
      spinnerTrue('spinner30', 'myPrevisionDiariaD1Wiew');
      facturationData = await servicesMercadosPrecios
        .getDDemandaDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget30').style.display = 'block';
          spinnerNone('spinner30', 'myPrevisionDiariaD1Wiew');
          document.querySelector('#myPrevisionDiariaD1Wiew svg').remove();
          renderPrevisionesCircular.renderPrevisionDiariaD1DemandaWidget(data);

          window.addEventListener('resize', () => {
            document.querySelector('#widget30 svg').remove();
            renderPrevisionesCircular.renderPrevisionDiariaD1DemandaWidget(
              data
            );
          });
          return data;
        });
      document.getElementById('mypNoDataRect30').removeAttribute('style');
      setInfoModal(
        4,
        facturationData,
        templateShareModal(
          'daily-forecast-d1-demand',
          window.location.search,
          false,
          { height: 614, width: '100%' }
        ),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderDEolicaFotovoltaicaGraphAgain = async () => {
    const state = store.getState();
    selectTimeForCost.currentHour = '00';
    selectTimeForCost.currentMinutes = '00';
    try {
      spinnerTrue('spinner31', 'myPrevisionDiariaD1EolicaWiew');
      facturationData = await servicesMercadosPrecios
        .getDEolicaDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget31').style.display = 'block';
          spinnerNone('spinner31', 'myPrevisionDiariaD1EolicaWiew');
          document.querySelector('#myPrevisionDiariaD1EolicaWiew svg').remove();
          renderPrevisionesCircular.renderPrevisionDiariaD1EolicaWidget(data);

          window.addEventListener('resize', () => {
            document.querySelector('#widget31 svg').remove();
            renderPrevisionesCircular.renderPrevisionDiariaD1EolicaWidget(data);
          });
          return data;
        });
      document.getElementById('mypNoDataRect31').removeAttribute('style');
      setInfoModal(
        5,
        facturationData,
        templateShareModal(
          'daily-forecast-d1-wind-plus-photovoltaic',
          window.location.search,
          false,
          { height: 614, width: '100%' }
        ),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderIDDemandaGraphAgain = async () => {
    const state = store.getState();
    selectTimeForCost.currentHour = '00';
    selectTimeForCost.currentMinutes = '00';
    try {
      spinnerTrue('spinner32', 'myPrevisionIntraDiariaH3DemandaWiew');
      facturationData = await servicesMercadosPrecios
        .getIDDemandaDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget32').style.display = 'block';
          spinnerNone('spinner32', 'myPrevisionIntraDiariaH3DemandaWiew');
          document
            .querySelector('#myPrevisionIntraDiariaH3DemandaWiew svg')
            .remove();
          renderPrevisionesCircular.renderPrevisionIntraDiariaD1DemandaWidget(
            data
          );

          window.addEventListener('resize', () => {
            document.querySelector('#widget32 svg').remove();
            renderPrevisionesCircular.renderPrevisionIntraDiariaD1DemandaWidget(
              data
            );
          });
          return data;
        });
      document.getElementById('mypNoDataRect32').removeAttribute('style');
      setInfoModal(
        6,
        facturationData,
        templateShareModal(
          'intraday-forecast-h3-demand',
          window.location.search,
          false,
          { height: 614, width: '100%' }
        ),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderIDDemandaFotovoltaicaGraphAgain = async () => {
    const state = store.getState();
    selectTimeForCost.currentHour = '00';
    selectTimeForCost.currentMinutes = '00';
    try {
      spinnerTrue('spinner33', 'myPrevisionIntraDiariaH3DemandaFotovolWiew');
      facturationData = await servicesMercadosPrecios
        .getIDEolicaDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget33').style.display = 'block';
          spinnerNone(
            'spinner33',
            'myPrevisionIntraDiariaH3DemandaFotovolWiew'
          );
          document
            .querySelector('#myPrevisionIntraDiariaH3DemandaFotovolWiew svg')
            .remove();
          renderPrevisionesCircular.renderPrevisionIntraDiariaD1DemandaFotovoltaicaWidget(
            data
          );

          window.addEventListener('resize', () => {
            document.querySelector('#widget33 svg').remove();
            renderPrevisionesCircular.renderPrevisionIntraDiariaD1DemandaFotovoltaicaWidget(
              data
            );
          });
          return data;
        });
      document.getElementById('mypNoDataRect33').removeAttribute('style');
      setInfoModal(
        7,
        facturationData,
        templateShareModal(
          'intraday-forecast-h3-more-photovoltaic-demand',
          window.location.search,
          false,
          { height: 614, width: '100%' }
        ),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderDAnualDemandaGraphAgain = async () => {
    const state = store.getState();
    selectTimeForCost.currentHour = '00';
    selectTimeForCost.currentMinutes = '00';
    try {
      spinnerTrue('spinner12', 'myPrevisionDemandaAnualWiew');
      facturationData = await servicesMercadosPrecios
        .getPDemandaAnualDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget12').style.display = 'block';
          spinnerNone('spinner12', 'myPrevisionDemandaAnualWiew');
          document.querySelector('#myPrevisionDemandaAnualWiew svg').remove();
          renderPrevisionesCircular.renderPrevisionDemandaAnualWidget(data);

          window.addEventListener('resize', () => {
            document.querySelector('#widget12 svg').remove();
            renderPrevisionesCircular.renderPrevisionDemandaAnualWidget(data);
          });
          return data;
        });
      document.getElementById('mypNoDataRect12').removeAttribute('style');
      setInfoModal(
        8,
        facturationData,
        templateShareModal(
          'annual-demand-forecast',
          window.location.search,
          false,
          { height: 614, width: '100%' }
        ),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderDemandaPrevistaPeninsularGraphAgain = async () => {
    const state = store.getState();
    selectTimeForCost.currentHour = '00';
    selectTimeForCost.currentMinutes = '00';
    try {
      spinnerTrue('spinner13', 'myDemandaPrevistaPeninsularWiew');
      facturationData = await servicesMercadosPrecios
        .getDemandaPrevistaPeninsularDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget13').style.display = 'block';
          spinnerNone('spinner13', 'myDemandaPrevistaPeninsularWiew');
          document
            .querySelector('#myDemandaPrevistaPeninsularWiew svg')
            .remove();
          renderPrevisionesCircular.renderDemandaPrevistaPeninsularWidget(data);

          window.addEventListener('resize', () => {
            document.querySelector('#widget13 svg').remove();
            renderPrevisionesCircular.renderDemandaPrevistaPeninsularWidget(
              data
            );
          });
          return data;
        });
      document.getElementById('mypNoDataRect13').removeAttribute('style');
      setInfoModal(
        9,
        facturationData,
        templateShareModal(
          'demand-or-forecast',
          window.location.search,
          false,
          { height: 614, width: '100%' }
        ),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderDemandaPrevistaSNPGraphAgain = async () => {
    const state = store.getState();
    selectTimeForCost.currentHour = '00';
    selectTimeForCost.currentMinutes = '00';
    try {
      spinnerTrue('spinner14', 'myDemandaPrevistaSNPWiew');
      facturationData = await servicesMercadosPrecios
        .getDemandaPrevistaSNPDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget14').style.display = 'block';
          spinnerNone('spinner14', 'myDemandaPrevistaSNPWiew');
          document.querySelector('#myDemandaPrevistaSNPWiew svg').remove();
          renderPrevisionesCircular.renderDemandaPrevistaSNPWidget(data);

          window.addEventListener('resize', () => {
            document.querySelector('#widget14 svg').remove();
            renderPrevisionesCircular.renderDemandaPrevistaSNPWidget(data);
          });
          return data;
        });
      document.getElementById('mypNoDataRect14').removeAttribute('style');
      setInfoModal(
        10,
        facturationData,
        templateShareModal(
          'snp-forecast-demand',
          window.location.search,
          false,
          { height: 614, width: '100%' }
        ),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderDemandaDiariaElectricaPeninsularGraphAgain = async () => {
    const state = store.getState();
    selectTimeForCost.currentHour = '00';
    selectTimeForCost.currentMinutes = '00';
    try {
      spinnerTrue('spinner15', 'myDemandaDiariaElectricaPeninsularWiew');
      facturationData = await servicesMercadosPrecios
        .getDemandaDiariaElectricaPeninsularDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget15').style.display = 'block';
          spinnerNone('spinner15', 'myDemandaDiariaElectricaPeninsularWiew');
          document
            .querySelector('#myDemandaDiariaElectricaPeninsularWiew svg')
            .remove();
          renderPrevisionesCircular.renderDemandaDiariaElectricaPeninsularWidget(
            data
          );

          window.addEventListener('resize', () => {
            document.querySelector('#widget15 svg').remove();
            renderPrevisionesCircular.renderDemandaDiariaElectricaPeninsularWidget(
              data
            );
          });
          return data;
        });
      document.getElementById('mypNoDataRect15').removeAttribute('style');
      setInfoModal(
        11,
        facturationData,
        templateShareModal(
          'daily-forecast-peninsular-electricity-demand',
          window.location.search,
          false,
          { height: 614, width: '100%' }
        ),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderDemandaMensualElectricaPeninsularGraphAgain = async () => {
    const state = store.getState();
    selectTimeForCost.currentHour = '00';
    selectTimeForCost.currentMinutes = '00';
    try {
      spinnerTrue('spinner16', 'myDemandaMensualElectricaPeninsularWiew');
      facturationData = await servicesMercadosPrecios
        .getDemandaMensualElectricaPeninsularDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget16').style.display = 'block';
          spinnerNone('spinner16', 'myDemandaMensualElectricaPeninsularWiew');
          document
            .querySelector('#myDemandaMensualElectricaPeninsularWiew svg')
            .remove();
          renderPrevisionesCircular.renderDemandaMensualElectricaPeninsularWidget(
            data
          );

          window.addEventListener('resize', () => {
            document.querySelector('#widget16 svg').remove();
            renderPrevisionesCircular.renderDemandaMensualElectricaPeninsularWidget(
              data
            );
          });
          return data;
        });
      document.getElementById('mypNoDataRect16').removeAttribute('style');
      setInfoModal(
        12,
        facturationData,
        templateShareModal(
          'monthly-forecast-peninsular-electricity-demand',
          window.location.search,
          false,
          { height: 614, width: '100%' }
        ),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderDemandaSemanalElectricaPeninsularGraphAgain = async () => {
    const state = store.getState();
    selectTimeForCost.currentHour = '00';
    selectTimeForCost.currentMinutes = '00';
    try {
      spinnerTrue('spinner17', 'myDemandaSemanalElectricaPeninsularWiew');
      facturationData = await servicesMercadosPrecios
        .getDemandaSemanalElectricaPeninsularDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget17').style.display = 'block';
          spinnerNone('spinner17', 'myDemandaSemanalElectricaPeninsularWiew');
          document
            .querySelector('#myDemandaSemanalElectricaPeninsularWiew svg')
            .remove();
          renderPrevisionesCircular.renderDemandaSemanalElectricaPeninsularWidget(
            data
          );

          window.addEventListener('resize', () => {
            document.querySelector('#widget17 svg').remove();
            renderPrevisionesCircular.renderDemandaSemanalElectricaPeninsularWidget(
              data
            );
          });
          return data;
        });
      document.getElementById('mypNoDataRect17').removeAttribute('style');
      setInfoModal(
        13,
        facturationData,
        templateShareModal(
          'weekly-forecast-peninsular-electricity-demand',
          window.location.search,
          false,
          { height: 614, width: '100%' }
        ),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderPrevisionMaximaAnualPeninsularGraphAgain = async () => {
    const state = store.getState();
    selectTimeForCost.currentHour = '00';
    selectTimeForCost.currentMinutes = '00';
    try {
      spinnerTrue('spinner18', 'myPrevisionMaximaAnualPeninsularWiew');
      facturationData = await servicesMercadosPrecios
        .getPrevisionMaximaAnualPeninsularDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget18').style.display = 'block';
          spinnerNone('spinner18', 'myPrevisionMaximaAnualPeninsularWiew');
          document
            .querySelector('#myPrevisionMaximaAnualPeninsularWiew svg')
            .remove();
          renderPrevisionesCircular.renderPrevisionMaximaAnualPeninsularWidget(
            data
          );

          window.addEventListener('resize', () => {
            document.querySelector('#widget18 svg').remove();
            renderPrevisionesCircular.renderPrevisionMaximaAnualPeninsularWidget(
              data
            );
          });
          return data;
        });
      document.getElementById('mypNoDataRect18').removeAttribute('style');
      setInfoModal(
        14,
        facturationData,
        templateShareModal(
          'maximum-annual-forecast-peninsular-electricity-demand',
          window.location.search,
          false,
          { height: 614, width: '100%' }
        ),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderGeneracionPrevistaGraphAgain = async () => {
    const state = store.getState();
    selectTimeForCost.currentHour = '00';
    selectTimeForCost.currentMinutes = '00';
    try {
      spinnerTrue('spinner19', 'myGeneracionPrevistaSolarWiew');
      facturationData = await servicesMercadosPrecios
        .getGeneracionPrevistaSolarDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget19').style.display = 'block';
          spinnerNone('spinner19', 'myGeneracionPrevistaSolarWiew');
          document.querySelector('#myGeneracionPrevistaSolarWiew svg').remove();
          renderPrevisionesCircular.renderGeneracionPrevistaSolarWidget(data);

          window.addEventListener('resize', () => {
            document.querySelector('#widget19 svg').remove();
            renderPrevisionesCircular.renderGeneracionPrevistaSolarWidget(data);
          });
          return data;
        });
      document.getElementById('mypNoDataRect19').removeAttribute('style');
      setInfoModal(
        15,
        facturationData,
        templateShareModal(
          'solar-planned-generation',
          window.location.search,
          false,
          { height: 614, width: '100%' }
        ),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderPrevisionMaximaAnualPeninsular2GraphAgain = async () => {
    const state = store.getState();
    selectTimeForCost.currentHour = '00';
    selectTimeForCost.currentMinutes = '00';
    try {
      spinnerTrue('spinner20', 'myPrevisionMaximaAnualPeninsular2Wiew');
      facturationData = await servicesMercadosPrecios
        .getPrevisionMaximaAnualPeninsular2DataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget20').style.display = 'block';
          spinnerNone('spinner20', 'myPrevisionMaximaAnualPeninsular2Wiew');
          document
            .querySelector('#myPrevisionMaximaAnualPeninsular2Wiew svg')
            .remove();
          renderPrevisionesCircular.renderPrevisionMaximaAnualPeninsular2Widget(
            data
          );

          window.addEventListener('resize', () => {
            document.querySelector('#widget20 svg').remove();
            renderPrevisionesCircular.renderPrevisionMaximaAnualPeninsular2Widget(
              data
            );
          });
          return data;
        });
      document.getElementById('mypNoDataRect20').removeAttribute('style');
      setInfoModal(
        16,
        facturationData,
        templateShareModal(
          'maximum-annual-forecast-peninsular-electricity-demand',
          window.location.search,
          false,
          { height: 614, width: '100%' }
        ),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderPrevisionMinimaAnualPeninsularGraphAgain = async () => {
    const state = store.getState();
    selectTimeForCost.currentHour = '00';
    selectTimeForCost.currentMinutes = '00';
    try {
      spinnerTrue('spinner21', 'myPrevisionMinimaAnualPeninsularWiew');
      facturationData = await servicesMercadosPrecios
        .getPrevisionMinimaAnualPeninsularDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget21').style.display = 'block';
          spinnerNone('spinner21', 'myPrevisionMinimaAnualPeninsularWiew');
          document
            .querySelector('#myPrevisionMinimaAnualPeninsularWiew svg')
            .remove();
          renderPrevisionesCircular.renderPrevisionMinimaAnualPeninsularWidget(
            data
          );

          window.addEventListener('resize', () => {
            document.querySelector('#widget21 svg').remove();
            renderPrevisionesCircular.renderPrevisionMinimaAnualPeninsularWidget(
              data
            );
          });
          return data;
        });
      document.getElementById('mypNoDataRect21').removeAttribute('style');
      setInfoModal(
        17,
        facturationData,
        templateShareModal(
          'minimum-annual-forecast-peninsular-electricity-demand',
          window.location.search,
          false,
          { height: 614, width: '100%' }
        ),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderPrevisionMaximaMensualPeninsularGraphAgain = async () => {
    const state = store.getState();
    selectTimeForCost.currentHour = '00';
    selectTimeForCost.currentMinutes = '00';
    try {
      spinnerTrue('spinner22', 'myPrevisionMaximaMensualPeninsularWiew');
      facturationData = await servicesMercadosPrecios
        .getPrevisionMaximaMensualPeninsularDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget22').style.display = 'block';
          spinnerNone('spinner22', 'myPrevisionMaximaMensualPeninsularWiew');
          document
            .querySelector('#myPrevisionMaximaMensualPeninsularWiew svg')
            .remove();
          renderPrevisionesCircular.renderPrevisionMaximaMensualPeninsularWidget(
            data
          );

          window.addEventListener('resize', () => {
            document.querySelector('#widget22 svg').remove();
            renderPrevisionesCircular.renderPrevisionMaximaMensualPeninsularWidget(
              data
            );
          });
          return data;
        });
      document.getElementById('mypNoDataRect22').removeAttribute('style');
      setInfoModal(
        18,
        facturationData,
        templateShareModal(
          'maximum-monthly-forecast-peninsular-electricity-demand',
          window.location.search,
          false,
          { height: 614, width: '100%' }
        ),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderPrevisionMinimaMensualPeninsularGraphAgain = async () => {
    const state = store.getState();
    selectTimeForCost.currentHour = '00';
    selectTimeForCost.currentMinutes = '00';
    try {
      spinnerTrue('spinner23', 'myPrevisionMinimaMensualPeninsularWiew');
      facturationData = await servicesMercadosPrecios
        .getPrevisionMinimaMensualPeninsularDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget23').style.display = 'block';
          spinnerNone('spinner23', 'myPrevisionMinimaMensualPeninsularWiew');
          document
            .querySelector('#myPrevisionMinimaMensualPeninsularWiew svg')
            .remove();
          renderPrevisionesCircular.renderPrevisionMinimaMensualPeninsularWidget(
            data
          );

          window.addEventListener('resize', () => {
            document.querySelector('#widget23 svg').remove();
            renderPrevisionesCircular.renderPrevisionMinimaMensualPeninsularWidget(
              data
            );
          });
          return data;
        });
      document.getElementById('mypNoDataRect23').removeAttribute('style');
      setInfoModal(
        19,
        facturationData,
        templateShareModal(
          'minimum-monthly-forecast-peninsular-electricity-demand',
          window.location.search,
          false,
          { height: 614, width: '100%' }
        ),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderMargenGeneracionGraphAgain = async () => {
    const state = store.getState();
    selectTimeForCost.currentHour = '00';
    selectTimeForCost.currentMinutes = '00';
    try {
      spinnerTrue('spinner24', 'myMargenGeneracionWiew');
      facturationData = await servicesMercadosPrecios
        .getMargenGeneracionDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget24').style.display = 'block';
          spinnerNone('spinner24', 'myMargenGeneracionWiew');
          document.querySelector('#myMargenGeneracionWiew svg').remove();
          renderPrevisionesCircular.renderMargenGeneracionWidget(data);

          window.addEventListener('resize', () => {
            document.querySelector('#widget24 svg').remove();
            renderPrevisionesCircular.renderMargenGeneracionWidget(data);
          });
          return data;
        });
      document.getElementById('mypNoDataRect24').removeAttribute('style');
      setInfoModal(
        20,
        facturationData,
        templateShareModal(
          'available-generation-margin-maximum-forecast',
          window.location.search,
          false,
          { height: 614, width: '100%' }
        ),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let renderPrevisonDemandaResidualGraphAgain = async () => {
    const state = store.getState();
    selectTimeForCost.currentHour = '00';
    selectTimeForCost.currentMinutes = '00';
    try {
      spinnerTrue('spinner25', 'myPrevisonDemandaResidualWiew');
      facturationData = await servicesMercadosPrecios
        .getPrevisonDemandaResidualDataFromWidget({
          lang: state.exampleReducer.currentLang,
          globalState: '00:00',
          ...fecha,
        })
        .then((data) => {
          document.getElementById('widget25').style.display = 'block';
          spinnerNone('spinner25', 'myPrevisonDemandaResidualWiew');
          document.querySelector('#myPrevisonDemandaResidualWiew svg').remove();
          renderPrevisionesCircular.renderPrevisonDemandaResidualWidget(data);

          window.addEventListener('resize', () => {
            document.querySelector('#widget25 svg').remove();
            renderPrevisionesCircular.renderPrevisonDemandaResidualWidget(data);
          });
          return data;
        });
      document.getElementById('mypNoDataRect25').removeAttribute('style');
      setInfoModal(
        21,
        facturationData,
        templateShareModal(
          'residual-demand-forecast',
          window.location.search,
          false,
          { height: 614, width: '100%' }
        ),
        'share'
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  return {
    render: init,
  };
})();

export { componentPrevisionesCircular };
