'use strict';

/**
 * @description About Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { store } from '../../app.init.js';
import i18n from 'i18next';
import { templateEmbed } from './embed.template.js';
import { render } from 'lit-html';
import { templateCalendario } from '../calendar/calendario.template.js';
import { componentCalendario } from '../calendar/calendario.component.js';

let componentEmbed = (function () {
  let context = {};
  let handlers = {};
  let renderSuccess = false;

  let preRenderComponent = (data) => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    document.title = `${i18n.t('about_title')} | ${i18n.t('page_subtitle')}`;
    context.name = state.exampleReducer.name;
  };

  let renderComponent = async (data) => {
    const state = store.getState();
    try {
      // Render the template to the document

      render(templateEmbed(data), document.querySelector('body'));

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = (data) => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = (data) => {
    //Call pre render component
    preRenderComponent(data);

    // Call render component
    renderComponent(data);

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent(data) : '';
  };

  return {
    render: init,
  };
})();

export { componentEmbed };
