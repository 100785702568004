'use strict';
import i18next from 'i18next';
import { html } from 'lit-html';
import { Utils } from '../../../utils/utils';

let templateDocumentList = (
  handlers,
  filterForm,
  selectorData,
  dataPaginated
) => {
  const { onQueryChange, onHide } = handlers;
  dataPaginated = dataPaginated
    .map((el) => {
      return {
        ...el,
        slug: Utils.removeAccents(el.name).toLowerCase(),
      };
    })
    .sort((a, b) => {
      if (a.slug < b.slug) {
        return -1;
      }
      if (b.slug < a.slug) {
        return 1;
      }
      return 0;
    });
  return html`<div id="c_document-list" class="esios-tabla mb-5">
    <!-- ? Filter -->
    <div class="esios-market-prices__form row">
      <div class="esios-tabla__search-container d-flex">
        <input
          class="esios-tabla__input"
          placeholder="${i18next.t('searched')}"
          type="text"
          @keyup="${onQueryChange}"
        />
        <button
          class="esios-document-list__button-remove hidden"
          @click="${onHide}"
        >
          &nbsp
        </button>
        <span class="search-filter"></span>
      </div>

      <div
        class="init-dropdown select-filter market-select-filter esios-document-list"
      >
        <div id="ambit-select"></div>
        <div id="category-select"></div>
        <div id="action-select"></div>
        <div id="proccess-select"></div>
      </div>
    </div>

    <!-- ? List of documents -->
    <div id="dl-spinner" class="spinner-container">
      <div class="spinner"></div>
    </div>
    <div class="esios-document-list">
      <ul id="dl-results" class="esios-also__list row">
        ${dataPaginated?.map((item) => {
          return html` <li class="esios-also__item col-xs-6">
            <span class="esios-also__icon-analysis"></span>
            <a
              href="/${i18next.t('routes.lng')}/${i18next.t(
                'routes.analysis'
              )}/${item.id}"
              >${item.name}</a
            >
          </li>`;
        })}
        <p id="no-dl-results" class="no-dl-results">
          ${i18next.t('no_search_result')}
        </p>
      </ul>
    </div>

    <!-- ? Pagination -->
    <div id="pagination" class="esios-market-prices__pagination">
      <div id="pagination-document-list" class="pagination-container"></div>
    </div>
  </div>`;
};

export { templateDocumentList };
