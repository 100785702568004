'use strict';
import { api } from '../../utils/api-interceptor';
import i18n from 'i18next';
import { Utils } from '../../utils/utils';

let servicesGlosario = (() => {
  // Obteniendo datos para la tabla

  const getDataGlosario = async () => {
    let dataT = await api(
      `glossaries?locale=${i18n.t('routes.lng')}&temporal=` +
        Math.floor(Math.random() * 1000)
    );
    const dataFinal = dataT.contents.map((el) => {
      return {
        ...el,
        orderSlug: Utils.removeAccents(el.title).toLowerCase(),
      };
    });
    return dataFinal;
  };

  return {
    getDataTable: () => getDataGlosario(),
  };
})();

export { servicesGlosario };
