'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';
import backImage from '../../assets/images/header-default.jpg';
import { componentLayout } from '../../components/layout/layout.component';

let templateAyudaFAQ = (contactData) => {
  const { dataHelp, dataFAQ } = contactData;

  return componentLayout.render(
    html`
      <div class="esios-help ">
        <h1 class="esios-help__title">
          <a href="#inicioAyuda">${dataHelp?.title}</a> ${i18n.t('and_short')}
          <a href="#inicioFAQ">${dataFAQ?.title}</a>
        </h1>
        <ul>
          <li>
            <p><a href="#inicioAyuda">Ayuda</a></p>
          </li>
          <li>
            <p><a href="#inicioFAQ">FAQ</a></p>
          </li>
        </ul>
        <h1 id="inicioAyuda" class="esios-help__title">${dataHelp?.title}</h1>
        ${html([dataHelp?.body])}
        <hr />
        <h1 id="inicioFAQ" class="esios-help__title">${dataFAQ?.title}</h1>
        ${html([dataFAQ?.body])}
      </div>
    `,
    {
      image: backImage,
      showFooterImage: true,
    }
  );
};

export { templateAyudaFAQ };
