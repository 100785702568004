import Handlebars from 'handlebars';
import i18n from 'i18next';
import { html, render } from 'lit-html';
import { store } from '../../../app.init';
import {
  getDecimal,
  getUnit,
} from '../../../shared/components/chart/chart-helpers';
import { setAnalysis } from '../../../state/actions';
import { getState } from '../../../utils/state';
import { getUrlParams, setUrlParam } from '../../../utils/url';
import { Utils } from '../../../utils/utils';

import geoComposited from '../geographical/queries/geo_composited';
import geoOperationDiv from '../geographical/queries/geo_operation_div';
import geoSimple from '../geographical/queries/geo_simple';
import levelGeoComposited from '../geographical/queries/level_geo_composited';
import levelGeoOperationDiv from '../geographical/queries/level_geo_operation_div';
import levelGeoSimple from '../geographical/queries/level_geo_simple';

const AnalysisGeographical = (analysis, indicatorInfo, sqlParams, data) => {
  const indicatorLevel = indicatorInfo.nivel[0].geo_nivel;

  // Getting global common state
  const state = getState();
  // Current language we are using
  const lang = state.currentLang;
  const { level, zoom, latlng, geoids } = getUrlParams();
  const currentLevel =
    state.analysis.level || level || indicatorInfo.nivel[0].geo_nivel;
  let indicatorName = '',
    geosArr = '';
  if (state?.analysis?.indicator) {
    geosArr = state.analysis.indicator.geos;
    indicatorName = state.analysis.indicator.name;
  }
  let foundGeo = geoids && geosArr.find((el) => el.geo_id == geoids)?.geo_name;
  let preLatLang = latlng
    ? latlng.includes(',')
      ? latlng.split(',')
      : latlng.split('%2C')
    : [40, -3];

  let defaults = {
    colors: [
      '#ffffcc',
      '#c7e9b4',
      '#7fcdbb',
      '#41b6c4',
      '#1d91c0',
      '#225ea8',
      '#0c2c84',
    ],
    map: {
      center: preLatLang,
      zoom: zoom || 6,
      maxZoom: 9,
      scrollWheelZoom: false,
    },
    tiles: {
      // url: 'https://cartocdn_{s}.global.ssl.fastly.net/base-light/{z}/{x}/{y}.png',
      url: `${window.location.origin}/images_temp/maps/{z}/{x}/{y}.png`,
    },
    sql: {
      user: 'maps',
      format: 'json',
      jsonp: true,
      sql_api_template: 'https://ree-admin.carto.com',
    },
    cartodb: {
      user_name: 'maps-admin',
      type: 'cartodb',
      cartodb_logo: true,
      maps_api_template: 'https://ree-admin.carto.com',
      sublayers: [],
    },
    indicatorId: indicatorInfo.id,
    composited: indicatorInfo.composited,
    unit: getUnit(indicatorInfo.magnitud[0].id),
    decimal: getDecimal(indicatorInfo.magnitud[0].id) || 1,
    title: foundGeo ? `${indicatorName} ${foundGeo}` : '',
    currentIndicator: indicatorInfo,
    currentGeoIds: indicatorInfo.geos,
    dates: [sqlParams.start_date, sqlParams.end_date],
  };
  cdb.core.Template.compilers = _.extend(cdb.core.Template.compilers, {
    handlebars: typeof Handlebars === 'undefined' ? null : Handlebars.compile,
  });

  // query for map data
  let query;
  if (currentLevel != indicatorLevel) {
    if (indicatorInfo.operation === 'DIV' && indicatorInfo.composited) {
      query = levelGeoOperationDiv(sqlParams);
    } else if (indicatorInfo.composited) {
      query = levelGeoComposited(sqlParams);
    } else {
      query = levelGeoSimple(sqlParams);
    }
  } else {
    if (indicatorInfo.operation === 'DIV' && indicatorInfo.composited) {
      query = geoOperationDiv(sqlParams);
    } else if (indicatorInfo.composited) {
      sqlParams.sons = [];
      query = geoComposited(sqlParams);
    } else {
      query = geoSimple(sqlParams);
    }
  }

  cartodb.SQL.prototype._host = () => 'https://ree-admin.carto.com/api/v2/sql';
  let cartodbMap = new cartodb.SQL(defaults.sql);
  if (geoids) {
    calculateBounds(
      geoids,
      function (bounds) {
        map.fitBounds(bounds);
      },
      cartodbMap
    );
  }

  const geoMapId = `geo-map-container-${indicatorInfo.id}`;
  document.getElementById(geoMapId).innerHTML = '';
  const mapWrp = document.getElementById(geoMapId);
  const newMap = document.createElement('div');
  newMap.classList.add('geo-map');
  newMap.setAttribute('id', 'geo-map-' + indicatorInfo.id);
  mapWrp.append(newMap);

  // TODO: CHECK THIS GEO_MAP
  let map = L.map('geo-map-' + indicatorInfo.id, defaults.map);

  let zoomTemp = map.getZoom();
  let centerTemp = map.getCenter();
  map.setView([centerTemp.lat, centerTemp.lng], zoomTemp);

  // map configuration
  L.tileLayer(defaults.tiles.url).addTo(map);
  // event that changes URL when map zooms
  map.on('zoomend, moveend', function () {
    let zoom = map.getZoom();
    let center = map.getCenter();
    let latlng = center.lat + ',' + center.lng;

    let params = {
      zoom: zoom,
      latlng: latlng,
    };
    if (!location.pathname.includes('esios')) {
      setUrlParam(params);
    }
  });
  let quantiles = calculateQuantiles(data);
  let quantileCartoCss = formatQuantileCartoCSS(quantiles);
  const options = _.clone(defaults.cartodb);
  options.sublayers = [
    {
      sql: query,
      cartocss: quantileCartoCss.cartoCss,
      interactivity: 'local_name, total',
    },
  ];

  cartodb
    .createLayer(map, options, { no_cdn: true })
    .addTo(map)
    .done(function (layer) {
      defaults.layer = layer;
      let tooltipInfo = `<div class="cartodb-popup">
      <a href="#close" class="cartodb-popup-close-button close">x</a>
       <div class="cartodb-popup-content-wrapper">
         <div class="cartodb-popup-content">
         <h2>{{content.data.local_name}}</h2>
         <p>{{{isnumber content.data.total ${defaults.decimal}}}} ${defaults.unit}</p>
         </div>
       </div>
       <div class="cartodb-popup-tip-container"></div>
    </div>`;
      Handlebars.registerHelper('isnumber', function (value, decimals) {
        value = typeof value === 'string' ? Number(value) : value;

        if (value === 0) {
          return '0';
        }

        if (_.isString(decimals)) {
          decimals = Number(decimals);
        }
        if (!_.isNumber(decimals)) {
          decimals = null;
        }

        if (_.isNumber(value) && !_.isNaN(value)) {
          return toNumber(value, decimals);
        } else {
          return '-';
        }
      });
      Handlebars.compile(tooltipInfo);
      let a = cdb.vis.Vis.addInfowindow(
        map,
        defaults.layer.getSubLayer(0),
        'local_name, total',
        {
          infowindowTemplate: tooltipInfo,
          templateType: 'handlebars',
        }
      );
      addLegend(quantiles, defaults.unit);

      let newZoom = map.getZoom();
      if (zoom) {
        newZoom = parseInt(zoom, 10);
      }

      if (latlng) {
        let newLatlng = latlng;
        if (!Array.isArray(newLatlng)) {
          newLatlng = latlng.split(',');
        }
        if (newLatlng[0] && newLatlng[1]) {
          newLatlng = L.latLng(newLatlng[0], newLatlng[1]);
          map.setView(newLatlng, newZoom);
        }
      }
    });
  return html``;

  function calculateQuantiles(geoData) {
    if (state.analysis && state.analysis.quantiles) {
      return state.analysis.quantiles;
    } else {
      const maxScale = 7;
      const data = geoData.rows.map((g) => g.total);
      const colors = defaults.colors.reverse();
      var numberOfQuantiles = data.length <= maxScale ? data.length : maxScale;
      let quantiles = [];
      if (numberOfQuantiles <= 1) {
        quantiles = [
          {
            originalValue: data[0],
            value: Math.floor(data[0] / 10) * 10,
            color: colors[0],
            sign: '>',
          },
        ];
        // Else calculing quantils
      } else {
        let quantilesTemp = calcQuantils(data, numberOfQuantiles - 1, {})
          .reverse()
          .map(function (q, i) {
            // Client wish round to 10
            // OLD: var quantilValue = q < 10 ? Math.round(q) : Math.floor(q / 10) * 10;
            var quantilValue = Math.floor(q / 10) * 10;
            return {
              originalValue: q,
              value: quantilValue,
            };
          });
        _.uniq(quantilesTemp, true, 'value').map((q, i) => {
          quantiles.push({ ...q, color: colors[i], sign: '>' });
        });
      }

      return quantiles;
    }
  }

  function toNumber(value, decimals) {
    var result = _.isNumber(value) ? value : Number(value);
    if (!_.isNumber(result) || _.isNaN(result)) {
      return value;
    }
    if (typeof decimals === 'undefined' || decimals === null) {
      decimals = 1;
    }
    if (lang === 'es') {
      return numberFormat(result, decimals, ',', '.');
    }
    return numberFormat(result, decimals, '.', ',');
  }

  function numberFormat(number, dec, dsep, tsep) {
    if (isNaN(number) || number == null) return '';

    number = number.toFixed(~~dec);
    tsep = typeof tsep == 'string' ? tsep : ',';

    var parts = number.split('.'),
      fnums = parts[0],
      decimals = parts[1] ? (dsep || '.') + parts[1] : '';

    return fnums.replace(/(\d)(?=(?:\d{3})+$)/g, '$1' + tsep) + decimals;
  }

  function calcQuantils(arr, num, opts) {
    let ascending = (a, b) => {
      return a - b;
    };
    var len = arr.length,
      qValues = new Array(num + 1),
      id,
      val;
    if (!opts.sorted) {
      arr = arr.slice();
      arr.sort(ascending);
    }
    // 0th quantile is the min:
    qValues[0] = arr[0];

    // Max defines the quantile upper bound:
    qValues[num] = arr[len - 1];

    // Get the quantiles...
    for (var i = 1; i < num; i++) {
      // Calculate the vector index marking the quantile:
      id = (len * i) / num - 1;

      // Is the index an integer?
      if (id === Math.floor(id)) {
        // Value is the average between the value at id and id+1:
        val = (arr[id] + arr[id + 1]) / 2.0;
      } else {
        // Round up to the next index:
        id = Math.ceil(id);
        val = arr[id];
      }
      qValues[i] = val;
    } // end FOR i
    return qValues;
  }
  function formatQuantileCartoCSS(quantiles) {
    let cartoCss = `#geo_def {
      polygon-fill: #ffffcc;
      polygon-opacity: 0.8;
      line-color: #ffffff;
      line-width: 1;
      line-opacity: 1;
    }`;
    // CSS logic for legend
    if (quantiles.length === 1) {
      cartoCss += '#geo_def {polygon-fill: ' + quantiles[0].color + ';}';
    } else {
      quantiles.forEach((quantile, index) => {
        if (index > 0) {
          cartoCss +=
            ' #geo_def [total >= ' +
            quantile.value +
            '][total < ' +
            quantiles[index - 1].value +
            '] {polygon-fill: ' +
            quantile.color +
            ';} ';
        } else {
          cartoCss +=
            '#geo_def [total >= ' +
            quantile.value +
            '] {polygon-fill: ' +
            quantile.color +
            ';}';
        }
      });
    }

    return {
      quantiles: quantiles,
      cartoCss: cartoCss,
    };
  }
  function getLegendTemplate(legendData, title, unit) {
    return html`
      <div class="widget-legend basic-legend">
        ${title ? html`<h3>${title}</h3>` : ''}
        <div class="is-flex-center">
          <div class="btn-filter">
            <button class="btn-lock-legend icon-lock-legend">
              ${i18n.t('geo_lock_legend')}
            </button>
            <div class="toggle-switch"><input type="checkbox" /></div>
            <button class="btn-unlock-legend icon-unlock-legend">
              ${i18n.t('geo_unlock_legend')}
            </button>
          </div>
        </div>
        <ul>
          ${legendData &&
          legendData.length > 0 &&
          legendData.map((elem) => {
            return html`
              <li class="legend-item item-content">
                <div class="item">
                  <span
                    class="legend-circle"
                    .style="background-color: ${elem.color};"
                  ></span
                  >${elem.name}
                </div>
                <div class="value is-number">
                  <!-- CHECK IF ALWAYS SHOULD BE ZERO -->
                  ${elem.sign} ${Utils.toNumber(elem.value, 0, 'comma')}
                  <span class="unit">${unit}</span>
                </div>
              </li>
            `;
          })}
        </ul>
      </div>
    `;
  }
  function addLegend(quantiles, unit) {
    let legend = document.querySelector('#geo-legend');

    var legendData = quantiles.map(function (q) {
      return {
        sign: q.sign,
        value: q.value,
        color: q.color,
        unit: unit ? unit : '',
      };
    });

    // var clickEventName = window.ontouchstart ? 'touchstart' : 'click';
    render(
      getLegendTemplate(legendData, defaults.title, defaults.unit),
      legend
    );

    legend.classList.remove('is-hidden');

    var $btnLockLegend = legend.querySelector('.btn-lock-legend');
    var $btnUnlockLegend = legend.querySelector('.btn-unlock-legend');
    var $switch = legend.querySelector('.toggle-switch');
    $switch.addEventListener('click', (e) => toggleLegend(e, quantiles));
    $btnLockLegend.addEventListener('click', function () {
      $switch.classList.add('locked');
      $btnLockLegend.style.opacity = 1;
      $btnUnlockLegend.style.opacity = 0.5;
      lockLegend(quantiles);
    });
    $btnUnlockLegend.addEventListener('click', function () {
      $switch.classList.remove('locked');
      $btnUnlockLegend.style.opacity = 1;
      $btnLockLegend.style.opacity = 0.5;
      unlockLegend();
    });

    if (state.analysis.quantiles) {
      $switch.classList.add('locked');
      $btnLockLegend.style.opacity = 1;
      $btnUnlockLegend.style.opacity = 0.5;
    } else {
      $switch.classList.remove('locked');
      $btnLockLegend.style.opacity = 0.5;
      $btnUnlockLegend.style.opacity = 1;
    }
  }

  function toggleLegend(ev, quantiles) {
    var $element = ev.currentTarget;
    let legend = document.querySelector('#geo-legend');

    var $btnLockLegend = legend.querySelector('.btn-lock-legend');
    var $btnUnlockLegend = legend.querySelector('.btn-unlock-legend');

    if (!$element.classList.contains('locked')) {
      $element.classList.add('locked');
      $btnLockLegend.style.opacity = 1;
      $btnUnlockLegend.style.opacity = 0.5;
      lockLegend(quantiles);
    } else {
      $element.classList.remove('locked');
      $btnUnlockLegend.style.opacity = 1;
      $btnLockLegend.style.opacity = 0.5;
      unlockLegend();
    }
  }
  function lockLegend(quantiles) {
    store.dispatch(
      setAnalysis({
        quantiles: quantiles,
      })
    );
  }
  function unlockLegend() {
    store.dispatch(
      setAnalysis({
        quantiles: null,
      })
    );
  }
  function calculateBounds(geoIds, callback, cartoDb) {
    var query = 'SELECT * FROM geo_def';
    if (geoIds) {
      query = query + ' WHERE cartodb_id IN (' + geoIds + ')';
    }

    cartoDb
      .getBounds(query)
      .done(
        callback && typeof callback === 'function' ? callback : function () {}
      );
  }
};

export default AnalysisGeographical;
