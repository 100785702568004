import i18n from 'i18next';
import { html } from 'lit-html';
import { getColour } from '../../../shared/components/chart/chart-helpers';

const TemplateEmbedIndicatorsHourlyBalances = () => {
  return html`
    <div id="widget39" class="graph-wrapper">
      <div id="spinner39" class="spinner-container">
        <div class="white-overlay"></div>
        <div class="spinner"></div>
      </div>
      <div
        id="nochar39"
        class="esios-chart esios-intercambios__no-data-graphic"
        style="display:none"
      >
        <div id="rect1" class="esios-pvpc__no-data">
          ${i18n.t('no-data-available')}
        </div>
      </div>
      <div
        id="chart-bar-line"
        class="chari-chart"
        style="min-height: initial!important;"
      ></div>
      <div class="legends esios-intercambios__container-data">
        <div
          class="legend esios-intercambios__label esios-intercambios__legend"
          style="display: flex; justify-content: space-between;"
        >
          <div>
            <span class="circle" style="background: ${getColour(0)}"></span>
            ${i18n.t('balances')} ${i18n.t('france')}
          </div>

          <div class="valcontainer legendbar-10015">
            <div class="value"></div>
            <small class="magnitud"></small>
          </div>
        </div>

        <div
          class="legend esios-intercambios__label esios-intercambios__legend"
          style="display: flex; justify-content: space-between;"
        >
          <div>
            <span class="circle" style="background: ${getColour(1)}"></span>
            ${i18n.t('balances')} ${i18n.t('portugal')}
          </div>

          <div class="valcontainer legendbar-10014">
            <div class="value"></div>
            <small class="magnitud"></small>
          </div>
        </div>

        <div
          class="legend esios-intercambios__label esios-intercambios__legend"
          style="display: flex; justify-content: space-between;"
        >
          <div>
            <span class="circle" style="background: ${getColour(2)}"></span>
            ${i18n.t('balances')} ${i18n.t('morocco')}
          </div>

          <div class="valcontainer legendbar-10016">
            <div class="value"></div>
            <small class="magnitud"></small>
          </div>
        </div>

        <div
          class="legend esios-intercambios__label esios-intercambios__legend"
          style="display: flex; justify-content: space-between;"
        >
          <div>
            <span class="circle" style="background: ${getColour(3)}"></span>
            ${i18n.t('balances')} ${i18n.t('andorra')}
          </div>

          <div class="valcontainer legendbar-10017">
            <div class="value"></div>
            <small class="magnitud"></small>
          </div>
        </div>

        <div
          class="legend esios-intercambios__label esios-intercambios__legend"
          style="display: flex; justify-content: space-between;"
        >
          <div>
            <span class="line" style="background: #000000"></span>
            ${i18n.t('total-balance')}
          </div>

          <div class="valcontainer legendbar-10027">
            <div class="value"></div>
            <small class="magnitud"></small>
          </div>
        </div>
      </div>
    </div>
  `;
};

export default TemplateEmbedIndicatorsHourlyBalances;
