import i18n from 'i18next';
import { html, render } from 'lit-html';
import moment from 'moment-timezone';
import { store } from '../../app.init';
import { datepickerComponent } from '../../shared/components/datepicker/datepicker.component';
import { Utils } from '../../utils/utils';
import { servicesnoPeninsulares } from '../noPeninsulares/noPeninsulares.services';

const TemplateEmbedIndicatorsMaps = async (data, image) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const dateUrl =
    urlParams.get('date') || moment(new Date()).format('DD-MM-YYYY');

  let finalDateURL = data[0]
    ? moment(data[0].datetime).tz('Europe/Madrid').format('DD / MM / YYYY')
    : dateUrl;

  const fixImageMaps = () => {
    const imgs = document.querySelectorAll('img[usemap]');
    imgs.forEach((img) => {
      if (!img.naturalHeight) {
        return;
      }
      const h = img.height / img.naturalHeight;
      const w = img.width / img.naturalWidth;
      const map = document.getElementsByName(img.useMap.slice(1))[0];
      if (!map) {
        return;
      }
      for (let i = 0; i < map.children.length; i++) {
        const area = map.children[i];
        const coords = area.coords;
        if (!area.originalCoords) {
          area.originalCoords = coords;
        } else {
          coords = area.originalCoords;
        }
        const split = coords.split(',');
        let fixed = '';
        split.forEach((coord, n) => {
          if (n !== 0) {
            fixed += ',';
          }
          fixed += n % 2 === 0 ? Number(coord) * w : Number(coord) * h;
        });
        area.coords = fixed;
      }
    });
  };
  $(window).on('resize', fixImageMaps);
  $(window).on('load', fixImageMaps);
  const display = (id) => {
    let popup = document.getElementById(id);
    if (popup.className === 'widget-area-tooltip') {
      popup.classList.remove('widget-area-tooltip');
      popup.classList.add('is-hidden');
    } else {
      popup.classList.remove('is-hidden');
      popup.classList.add('widget-area-tooltip');
      popup.style = 'left:55%; top:75%;';
    }
  };
  const follow = (id, ev = {}) => {
    let map = document.getElementById(id).style;
    map.display = 'block';
    map.top = ev.layerY + 175 + 'px';
    map.left = ev.layerX + 5 + 'px';
  };
  const getMapName = () => {
    const map = window.location.pathname.split('/')[3].split('?')[0];
    switch (map) {
      case 'baleares':
      case 'balearic-islands':
        return 'baleares';
      case 'canarias':
      case 'canary-islands':
        return 'canarias';
      case 'ceuta':
        return 'ceuta';
      case 'melilla':
        return 'melilla';
      default:
        break;
    }
  };

  const getMapCoords = () => {
    const name = getMapName();
    if (window.location.search.includes('print')) {
      switch (name) {
        case 'baleares':
          return html` <map name="embed-map">
            <area
              @mouseenter=${() => {
                display('map-popup');
              }}
              @mouseout=${() => {
                display('map-popup');
              }}
              @mousemove="${(ev) => follow('map-popup', ev)}}"
              shape="polygon"
              data-generacion="14610.658"
              data-demanda="14610.658"
              coords="129,81,184,53,213,87,172,124"
              data-name="${i18n.t('baleares').toUpperCase()}"
            />
            <area
              @mouseenter=${() => {
                display('map-popup');
              }}
              @mouseout=${() => {
                display('map-popup');
              }}
              @mousemove="${(ev) => follow('map-popup', ev)}}"
              shape="polygon"
              data-generacion="14610.658"
              data-demanda="14610.658"
              coords="247,74,210,59,224,43,255,57"
              data-name="${i18n.t('baleares').toUpperCase()}"
            />
            <area
              @mouseenter=${() => {
                display('map-popup');
              }}
              @mouseout=${() => {
                display('map-popup');
              }}
              @mousemove="${(ev) => follow('map-popup', ev)}}"
              shape="polygon"
              data-generacion="14610.658"
              data-demanda="14610.658"
              coords="94,112,76,131,92,158,109,155,103,114"
              data-name="${i18n.t('baleares').toUpperCase()}"
            />
          </map>`;
        case 'canarias':
          return html` <map name="embed-map">
            <area
              @mouseenter=${() => {
                display('map-popup-4');
              }}
              @mouseout=${() => {
                display('map-popup-4');
              }}
              @mousemove="${(ev) => follow('map-popup-4', ev)}}"
              shape="polygon"
              coords="28,43,38,75,53,69,48,39"
              data-generacion="625.823"
              data-demanda="625.823"
              data-name="La Palma"
            />
            <area
              @mouseenter=${() => {
                display('map-popup-3');
              }}
              @mouseout=${() => {
                display('map-popup-3');
              }}
              @mousemove="${(ev) => follow('map-popup-3', ev)}}"
              shape="polygon"
              coords="85,67,100,101,120,93,128,73,139,59,128,57"
              data-generacion="9738.324"
              data-demanda="9738.324"
              data-name="Tenerife"
            />
            <area
              @mouseenter=${() => {
                display('map-popup-1');
              }}
              @mouseout=${() => {
                display('map-popup-1');
              }}
              @mousemove="${(ev) => follow('map-popup-1', ev)}}"
              shape="polygon"
              coords="155,82,146,107,166,118,176,107,176,83"
              data-generacion="9328.904"
              data-demanda="9328.904"
              data-name="Gran Canaria"
            />
            <area
              @mouseenter=${() => {
                display('map-popup-5');
              }}
              @mouseout=${() => {
                display('map-popup-5');
              }}
              @mousemove="${(ev) => follow('map-popup-5', ev)}}"
              shape="polygon"
              coords="68,81,63,90,77,100,83,86"
              data-generacion="189.173"
              data-demanda="189.173"
              data-name="La Gomera"
            />
            <area
              @mouseenter=${() => {
                display('map-popup-6');
              }}
              @mouseout=${() => {
                display('map-popup-6');
              }}
              @mousemove="${(ev) => follow('map-popup-6', ev)}}"
              shape="polygon"
              coords="35,101,16,111,39,126,43,108"
              data-generacion="111.648"
              data-demanda="111.648"
              data-name="El Hierro"
            />
            <area
              @mouseenter=${() => {
                display('map-popup-2');
              }}
              @mouseout=${() => {
                display('map-popup-2');
              }}
              @mousemove="${(ev) => follow('map-popup-2', ev)}}"
              shape="polygon"
              coords="247,48,228,82,209,91,233,102,257,82,262,52"
              data-generacion="3958.586"
              data-demanda="3958.586"
              data-name="Lanzarote y Fuerteventura"
            />
            <area
              @mouseenter=${() => {
                display('map-popup-2');
              }}
              @mouseout=${() => {
                display('map-popup-2');
              }}
              @mousemove="${(ev) => follow('map-popup-2', ev)}}"
              shape="polygon"
              coords="248,42,270,4,283,26,271,46,254,47,249,47"
              data-generacion="3958.586"
              data-demanda="3958.586"
              data-name="Lanzarote y Fuerteventura"
            />
          </map>`;
        default:
          return '';
      }
    } else {
      switch (name) {
        case 'baleares':
          return html` <map name="embed-map">
            <area
              @mouseenter=${() => {
                display('map-popup');
              }}
              @mouseout=${() => {
                display('map-popup');
              }}
              @mousemove="${(ev) => follow('map-popup', ev)}}"
              shape="polygon"
              data-generacion="14610.658"
              data-demanda="14610.658"
              coords="193,122,276,80,319,130,257,186"
              data-name="${i18n.t('baleares').toUpperCase()}"
            />
            <area
              @mouseenter=${() => {
                display('map-popup');
              }}
              @mouseout=${() => {
                display('map-popup');
              }}
              @mousemove="${(ev) => follow('map-popup', ev)}}"
              shape="polygon"
              data-generacion="14610.658"
              data-demanda="14610.658"
              coords="370,112,314,89,335,65,382,86"
              data-name="${i18n.t('baleares').toUpperCase()}"
            />
            <area
              @mouseenter=${() => {
                display('map-popup');
              }}
              @mouseout=${() => {
                display('map-popup');
              }}
              @mousemove="${(ev) => follow('map-popup', ev)}}"
              shape="polygon"
              data-generacion="14610.658"
              data-demanda="14610.658"
              coords="142,168,114,196,138,236,164,233,154,170"
              data-name="${i18n.t('baleares').toUpperCase()}"
            />
          </map>`;
        case 'canarias':
          return html` <map name="embed-map">
            <area
              @mouseenter=${() => {
                display('map-popup-4');
              }}
              @mouseout=${() => {
                display('map-popup-4');
              }}
              @mousemove="${(ev) => follow('map-popup-4', ev)}}"
              shape="polygon"
              coords="42,64,57,113,79,103,72,59"
              data-generacion="625.823"
              data-demanda="625.823"
              data-name="La Palma"
            />
            <area
              @mouseenter=${() => {
                display('map-popup-3');
              }}
              @mouseout=${() => {
                display('map-popup-3');
              }}
              @mousemove="${(ev) => follow('map-popup-3', ev)}}"
              shape="polygon"
              coords="128,100,150,151,180,140,192,110,208,89,192,86"
              data-generacion="9738.324"
              data-demanda="9738.324"
              data-name="Tenerife"
            />
            <area
              @mouseenter=${() => {
                display('map-popup-1');
              }}
              @mouseout=${() => {
                display('map-popup-1');
              }}
              @mousemove="${(ev) => follow('map-popup-1', ev)}}"
              shape="polygon"
              coords="232,123,219,161,249,177,264,161,264,124"
              data-generacion="9328.904"
              data-demanda="9328.904"
              data-name="Gran Canaria"
            />
            <area
              @mouseenter=${() => {
                display('map-popup-5');
              }}
              @mouseout=${() => {
                display('map-popup-5');
              }}
              @mousemove="${(ev) => follow('map-popup-5', ev)}}"
              shape="polygon"
              coords="102,121,95,136,116,150,125,129"
              data-generacion="189.173"
              data-demanda="189.173"
              data-name="La Gomera"
            />
            <area
              @mouseenter=${() => {
                display('map-popup-6');
              }}
              @mouseout=${() => {
                display('map-popup-6');
              }}
              @mousemove="${(ev) => follow('map-popup-6', ev)}}"
              shape="polygon"
              coords="52,152,25,167,58,189,65,162"
              data-generacion="111.648"
              data-demanda="111.648"
              data-name="El Hierro"
            />
            <area
              @mouseenter=${() => {
                display('map-popup-2');
              }}
              @mouseout=${() => {
                display('map-popup-2');
              }}
              @mousemove="${(ev) => follow('map-popup-2', ev)}}"
              shape="polygon"
              coords="371,72,342,122,314,136,349,153,385,123,392,78"
              data-generacion="3958.586"
              data-demanda="3958.586"
              data-name="Lanzarote y Fuerteventura"
            />
            <area
              @mouseenter=${() => {
                display('map-popup-2');
              }}
              @mouseout=${() => {
                display('map-popup-2');
              }}
              @mousemove="${(ev) => follow('map-popup-2', ev)}}"
              shape="polygon"
              coords="371,63,404,7,423,39,406,69,381,71,373,71"
              data-generacion="3958.586"
              data-demanda="3958.586"
              data-name="Lanzarote y Fuerteventura"
            />
          </map>`;
        default:
          return '';
      }
    }
  };

  const mapName = getMapName();

  let titles;
  if (mapName === 'baleares') {
    const state = store.getState();
    const currentlyTime = Date.now();
    const currentlyDay = new Date(currentlyTime);
    const toDayDate = currentlyDay.toLocaleDateString();
    let formatDate = toDayDate.split('/');
    const [d, m, y] = formatDate;
    titles = await servicesnoPeninsulares.getTitles(
      state.exampleReducer.currentLang,
      12,
      `${y}-${m}-${d}`
    );
  }
  render(
    html` <div class="row">
        <div class="col-xs-6">
          <div class="esios-noPeninsulares__title">
            <h1>${i18n.t(mapName).toUpperCase()}</h1>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="esios-layout__breadcrumbs">
            <div
              class="mod-date mod-date-days is-day-selectable datepicker-mod"
            >
              <input
                id="datepicker"
                class="datepicker-btn"
                size="2"
                name="datepicker"
                value=${finalDateURL}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class=${'esios-noPeninsulares__wrapper row esios-embed-indicators__' +
        getMapName()}
      >
        <div class="esios-noPeninsulares__mainCol balear col-xs-6">
          <figure class="esios-noPeninsulares__image" id="image">
            <img src="${image}" usemap="#embed-map" />
          </figure>
          ${getMapCoords()}
          <div id="map-popup" class="is-hidden">
            <div class="tooltip-container">
              <div class="tooltip-header" style="border-color: #dbdbdb;">
                <div>${i18n.t(mapName)}</div>
              </div>
              <div class="tooltip-data">
                <div class="tooltip-row">
                  <div class="label">${i18n.t('generation')}</div>
                  <div class="is-small-number">
                    ${data?.length > 0 &&
                    Utils.toNumber(data[2]?.value, 3, 'comma') !== 0
                      ? Utils.toNumber(data[2]?.value, 3, 'comma')
                      : '-'}<span class="unit">MWh</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="map-popup-1" class="is-hidden">
            <div class="tooltip-container">
              <div class="tooltip-header" style="border-color: #dbdbdb;">
                <div>${data?.length > 0 && data[4]?.geo_name}</div>
              </div>
              <div class="tooltip-data">
                <div class="tooltip-row">
                  <div class="label">${i18n.t('generation')}</div>
                  <div class="is-small-number">
                    ${data?.length > 0 &&
                    Utils.toNumber(data[4]?.value, 3, 'comma') !== 0
                      ? Utils.toNumber(data[4]?.value, 3, 'comma')
                      : '-'}<span class="unit">MWh</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="map-popup-2" class="is-hidden">
            <div class="tooltip-container">
              <div class="tooltip-header" style="border-color: #dbdbdb;">
                <div>${data?.length > 0 && data[5]?.geo_name}</div>
              </div>
              <div class="tooltip-data">
                <div class="tooltip-row">
                  <div class="label">${i18n.t('generation')}</div>
                  <div class="is-small-number">
                    ${data?.length > 0 &&
                    Utils.toNumber(data[5]?.value, 3, 'comma') !== 0
                      ? Utils.toNumber(data[5]?.value, 3, 'comma')
                      : '-'}<span class="unit">MWh</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="map-popup-3" class="is-hidden">
            <div class="tooltip-container">
              <div class="tooltip-header" style="border-color: #dbdbdb;">
                <div>${data?.length > 0 && data[6]?.geo_name}</div>
              </div>
              <div class="tooltip-data">
                <div class="tooltip-row">
                  <div class="label">${i18n.t('generation')}</div>
                  <div class="is-small-number">
                    ${data?.length > 0 &&
                    Utils.toNumber(data[6]?.value, 3, 'comma') !== 0
                      ? Utils.toNumber(data[6]?.value, 3, 'comma')
                      : '-'}<span class="unit">MWh</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="map-popup-4" class="is-hidden">
            <div class="tooltip-container">
              <div class="tooltip-header" style="border-color: #dbdbdb;">
                <div>${data?.length > 0 && data[7]?.geo_name}</div>
              </div>
              <div class="tooltip-data">
                <div class="tooltip-row">
                  <div class="label">${i18n.t('generation')}</div>
                  <div class="is-small-number">
                    ${data?.length > 0 &&
                    Utils.toNumber(data[7]?.value, 3, 'comma') !== 0
                      ? Utils.toNumber(data[7]?.value, 3, 'comma')
                      : '-'}<span class="unit">MWh</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="map-popup-5" class="is-hidden">
            <div class="tooltip-container">
              <div class="tooltip-header" style="border-color: #dbdbdb;">
                <div>${data?.length > 0 && data[8]?.geo_name}</div>
              </div>
              <div class="tooltip-data">
                <div class="tooltip-row">
                  <div class="label">${i18n.t('generation')}</div>
                  <div class="is-small-number">
                    ${data?.length > 0 &&
                    Utils.toNumber(data[8]?.value, 3, 'comma') !== 0
                      ? Utils.toNumber(data[8]?.value, 3, 'comma')
                      : '-'}<span class="unit">MWh</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="map-popup-6" class="is-hidden">
            <div class="tooltip-container">
              <div class="tooltip-header" style="border-color: #dbdbdb;">
                <div>${data?.length > 0 && data[9]?.geo_name}</div>
              </div>
              <div class="tooltip-data">
                <div class="tooltip-row">
                  <div class="label">${i18n.t('generation')}</div>
                  <div class="is-small-number">
                    ${data?.length > 0 &&
                    Utils.toNumber(data[9]?.value, 3, 'comma') !== 0
                      ? Utils.toNumber(data[9]?.value, 3, 'comma')
                      : '-'}<span class="unit">MWh</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="esios-noPeninsulares__mainCol col-xs-6">
          <div class="row">
            <div class="col-xs-6">
              <div class="esios-noPeninsulares__label">
                ${i18n.t('average_price_generation').toUpperCase()}
              </div>
              <div class="esios-noPeninsulares__value">
                <div class="esios-noPeninsulares__small">
                  ${data?.length > 0 &&
                  Utils.toNumber(data[0]?.value, 2, 'comma') !== 0
                    ? Utils.toNumber(data[0]?.value, 2, 'comma')
                    : '-'}
                </div>
                <span class="esios-noPeninsulares__unit">€/MWh</span>
              </div>
            </div>
            <div class="col-xs-6">
              <div class="esios-noPeninsulares__label">
                ${i18n.t('generation').toUpperCase()}
              </div>
              <div class="esios-noPeninsulares__value">
                <div class="esios-noPeninsulares__small">
                  ${data?.length > 0 &&
                  Utils.toNumber(data[2]?.value, 3, 'comma') !== 0
                    ? Utils.toNumber(data[2]?.value, 3, 'comma')
                    : '-'}
                </div>
                <span class="esios-noPeninsulares__unit">MWh</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-6">
              <div class="esios-noPeninsulares__label">
                ${i18n.t('average_price_demand').toUpperCase()}
              </div>
              <div class="esios-noPeninsulares__value">
                <div class="esios-noPeninsulares__small">
                  ${data?.length > 0 &&
                  Utils.toNumber(data[1]?.value, 2, 'comma') !== 0
                    ? Utils.toNumber(data[1]?.value, 2, 'comma')
                    : '-'}
                </div>
                <span class="esios-noPeninsulares__unit">€/MWh</span>
              </div>
            </div>
            <div class="col-xs-6">
              <div class="esios-noPeninsulares__label">
                ${i18n.t('demand').toUpperCase()}
              </div>
              <div class="esios-noPeninsulares__value">
                <div class="esios-noPeninsulares__small">
                  ${data?.length > 0 &&
                  Utils.toNumber(data[3]?.value, 3, 'comma') !== 0
                    ? Utils.toNumber(data[3]?.value, 3, 'comma')
                    : '-'}
                </div>
                <span class="esios-noPeninsulares__unit">MWh</span>
              </div>
            </div>
          </div>
          ${mapName === 'baleares'
            ? html` <hr class="esios-noPeninsulares__hr-separator" />
                <div class="row">
                  <h3 class="esios-noPeninsulares__label">
                    ${i18n.t('generation_price_sea_mallorca').toUpperCase()}
                  </h3>
                  <div class="col-xs-6">
                    <div class="esios-noPeninsulares__little-label">
                      ${titles?.length > 0 ? titles[9].alias : '-'}
                    </div>
                    <div class="esios-noPeninsulares__value">
                      <div class="esios-noPeninsulares__small">
                        ${data?.length > 0 &&
                        Utils.toNumber(data[9]?.value, 2, 'comma') !== 0
                          ? Utils.toNumber(data[9]?.value, 2, 'comma')
                          : '-'}
                      </div>
                      <span class="esios-noPeninsulares__unit">€/MWh</span>
                    </div>
                  </div>
                  <div class="col-xs-6">
                    <div class="esios-noPeninsulares__little-label">
                      ${titles?.length > 0 ? titles[8].alias : '-'}
                    </div>
                    <div class="esios-noPeninsulares__value">
                      <div class="esios-noPeninsulares__small">
                        ${data?.length > 0 &&
                        Utils.toNumber(data[8]?.value, 2, 'comma') !== 0
                          ? Utils.toNumber(data[8]?.value, 2, 'comma')
                          : '-'}
                      </div>
                      <span class="esios-noPeninsulares__unit">€/MWh</span>
                    </div>
                  </div>
                </div>
                <div class="row esios-noPeninsulares__row-flex">
                  <h3 class="esios-noPeninsulares__label">
                    ${i18n.t('electric_link_programs').toUpperCase()}
                  </h3>
                  <div
                    class="col-xs-6 esios-noPeninsulares__peninsula-baleares"
                  >
                    <div class="esios-noPeninsulares__little-label">
                      ${titles?.length > 0 ? titles[10].alias : '-'}
                    </div>
                    <div class="esios-noPeninsulares__value">
                      <div class="esios-noPeninsulares__small">
                        ${data?.length > 0 &&
                        Utils.toNumber(data[10]?.value, 2, 'comma') !== 0 &&
                        Math.round(data[10]?.value) !== 0
                          ? Utils.toNumber(data[10]?.value, 2, 'comma')
                          : '-'}
                      </div>
                      <span class="esios-noPeninsulares__unit">MWh</span>
                    </div>
                  </div>
                  <div
                    class="col-xs-6 esios-noPeninsulares__peninsula-baleares"
                  >
                    <div class="esios-noPeninsulares__little-label">
                      ${titles?.length > 0 ? titles[11].alias : '-'}
                    </div>
                    <div class="esios-noPeninsulares__value">
                      <div class="esios-noPeninsulares__small">
                        ${data?.length > 0 &&
                        Utils.toNumber(data[11]?.value, 1, 'comma') !== 0
                          ? Utils.toNumber(data[11]?.value, 1, 'comma')
                          : '-'}
                      </div>
                      <span class="esios-noPeninsulares__unit">MWh</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <h3 class="esios-noPeninsulares__label">
                    ${i18n.t('planned_capacity').toUpperCase()}
                  </h3>
                  <div class="col-xs-6">
                    <div class="esios-noPeninsulares__little-label">
                      ${titles?.length > 0 ? titles[12].alias : '-'}
                    </div>
                    <div class="esios-noPeninsulares__value">
                      <div class="esios-noPeninsulares__small">
                        ${data?.length > 0 &&
                        Utils.toNumber(data[12]?.value, 1, 'comma') !== 0
                          ? Utils.toNumber(data[12]?.value, 1, 'comma')
                          : '-'}
                      </div>
                      <span class="esios-noPeninsulares__unit">MW</span>
                    </div>
                  </div>
                </div>`
            : ''}
        </div>
      </div>`,
    document.querySelector('#embed-container')
  );
  datepickerComponent.render();
};

export default TemplateEmbedIndicatorsMaps;
