'use strict';
import { html } from 'lit-html';
import { componentLayout } from '../../components/layout/layout.component';
import backImage from '../../assets/images/header-default.jpg';

let templateprevisionesDRenovable = (context, handlers, previData) => {
  const { title, body } = previData;

  return componentLayout.render(
    html`
      <div class="esios-previsiones ">
        <div class="grid-sm-23 grid-xs-24">
          <h1>${title}</h1>

          ${html([body])}
        </div>
      </div>
    `,
    {
      text: '',
      image: backImage,
      showBanner: true,
      showFooterImage: true,
    }
  );
};

export { templateprevisionesDRenovable };
