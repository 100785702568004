import i18n from 'i18next';
import { html } from 'lit-html';
import { store } from '../../../app.init';
import Select from '../../../shared/components/selects/select';
import { getState } from '../../../utils/state';
import { setAnalysis } from '../../../state/actions';
import { getUrlParams, setUrlParam } from '../../../utils/url';

const AnalysisFormLevels = () => {
  let disabled = false;
  const res = getValuesAndSelected();
  let values = res.values;
  let selected = res.selected;

  return html`
    <div class="analysis-levels">
      <div class="analysis-group-by esios-select">
        <div
          class="esios-analysis__widget-toolbar esios-analysis__widget-toolbar--flex"
        >
          <div class="toolbar-wrapper">
            <h3 class="analysis-geo-id__title">${i18n.t('levels')}</h3>
          </div>
        </div>
        ${Select({ disabled, values, selected, onClick: handleLevels })}
      </div>
    </div>
  `;
};

function handleLevels({ content }) {
  setUrlParam('level', content);
  store.dispatch(
    setAnalysis({
      levels: content,
    })
  );
}

function getValuesAndSelected() {
  let indicatorLevel = getState().analysis.indicator.nivel[0].geo_nivel;
  let currentLevel = getUrlParams().level
    ? getUrlParams().level
    : indicatorLevel;
  let values = [];
  for (var i = 0; i <= indicatorLevel; i++) {
    values.push({
      label: i18n.t('level_' + i),
      content: i,
      disabled: false,
    });
  }

  let selected = values[currentLevel];

  return {
    values,
    selected,
  };
}
export default AnalysisFormLevels;
