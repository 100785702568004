'use strict';
import { html } from 'lit-html';
import { componentLayout } from '../../components/layout/layout.component';
import backimage from '../../assets/images/header-default.jpg';

let templateOperacion = (contactData) => {
  const { title, body } = contactData;
  let path = window.location.href;
  var resultado;
  if (path.includes('#')) {
    resultado = body.replace('#gl_so', path + 'gl_so');
    resultado = resultado.replace('#nc_er', path + 'nc_er');
  } else {
    resultado = body.replace('#gl_so', path + '#gl_so');
    resultado = resultado.replace('#nc_er', path + '#nc_er');
  }

  return componentLayout.render(
    html`
      <div class="esios-operacion ">
        <div class="grid-sm-23 grid-xs-24">
          <h1 class="esios-operacion__title">${title}</h1>
          ${html([resultado])}
        </div>
      </div>
    `,
    {
      image: backimage,
      showFooterImage: true,
    }
  );
};

export { templateOperacion };
