'use strict';
/**
 * @description Help Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { store } from '../../app.init.js';
import i18n from 'i18next';
import moment from 'moment';
import { servicesResultados } from './resultados.services.js';
import { templateResultados } from './resultados.template.js';
import { servicesDocumentacion } from '../documentacion/documentacion.services.js';
import { componentDocumentList } from '../../shared/components/document-list/document-list.component.js';
import { servicesgenerConsumo } from '../generacion y consumo/generConsumo.service.js';

let componentResultados = (function () {
  let context = {};
  let renderSuccess = false;
  let quantity = '';
  let filterFormAll = {
    query: '',
    template: '',
  };

  let filterFormData = {
    ambit: '',
    category: '',
    process: '',
    action: '',
    query: '',
  };

  let filterFormDocument = {
    query: '',
    potencia: '',
    tipoproduccion: '',
    vinculacionsm: '',
  };

  let handlers = {
    onSearch: onSearch,
    search: (event) => search(event),
    handleClickButton: (event) => handleClickButton(event),
    onHide: (e) => onHide(e),
    ocultar: (event) => ocultar(event),
    onQueryChange: (event) => onQueryChange(event),
    onSelectChange: (index) => onSelectChange(index),
  };
  let data = [];
  let dataDocument = [];
  let dataOfSelected = [];
  let select = [];
  let filterSelected = 'Data';
  let filterContainerOpen = false;
  let tabDataReady = false;

  const selectElements = [
    {
      value: 'potencia',
      label: 'ambit',
    },
    {
      value: 'tipoproduccion',
      label: 'area',
    },
    {
      value: 'vinculacionsm',
      label: 'type',
    },
  ];

  const urlParams = new URLSearchParams(window.location.search);
  const setParameter = (key, value) => {
    urlParams.set(key, value);
    history.pushState({}, '', window.location.pathname + '?' + urlParams);
  };

  let preRenderComponent = () => {
    const querystring = window.location.search;
    const params = new URLSearchParams(querystring);
    let utf = querystring.includes('?utf8=✓&') ? '' : '?utf8=✓&';
    if (querystring.split('%E2%9C%93').length < 2) {
      history.pushState({}, '', window.location.pathname + utf + params);
    }
    filterFormAll.query = params.get('query') || '';
    filterFormAll.p1 = parseToBooleanDefaultTrue(params.get('p1'));
    filterFormAll.p2 = parseToBooleanDefaultFalse(params.get('p2'));
    filterSelected = 'Data';
    filterFormAll.template = filterFormAll.query;

    setParameter('p1', filterFormAll.p1);
    setParameter('p2', filterFormAll.p2);
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    document.title = `${i18n.t('search')} | ${i18n.t('page_subtitle')}`;
    context.name = state.exampleReducer.name;
  };

  let renderComponentData = async (form) => {
    filterFormData.ambit = form.ambit;
    filterFormData.action = form.actions;
    filterFormData.category = form.category;
    filterFormData.process = form.process;
    filterFormData.query = form.query;
    let documentListData = [];

    if (
      filterFormData.ambit != '' ||
      filterFormData.action != '' ||
      filterFormData.category != '' ||
      filterFormData.process != '' ||
      filterFormData.query != ''
    ) {
      documentListData = await servicesgenerConsumo.getArchives(filterFormData);
    } else {
      filterData([]);
    }
    let newDLArray = {
      isLoading: documentListData.isLoading,
      data: documentListData.newIndicators,
      renderAgain: (form) => renderComponentData(form),
      onlyRefresh: () => renderComponentListAgain(),
      isResultadosPage: true,
    };
    componentDocumentList.render(newDLArray, '#filterData');
  };

  let renderComponentListAgain = async (form) => {
    let newDLArray = {
      renderAgain: (form) => renderComponentData(form),
      onlyRefresh: () => renderComponentListAgain(),
    };
    try {
      componentDocumentList.render(newDLArray, '#filterData');
    } catch (e) {}
  };

  let newDLArray = {
    renderAgain: (form) => renderComponentData(form),
    onlyRefresh: () => renderComponentListAgain(),
  };

  const getSelect = (taxonomy_terms, id) => {
    const select = taxonomy_terms.filter(
      (taxonomy_termsItem) => taxonomy_termsItem.vocabulary_id === id
    );
    return select;
  };

  const parseToBooleanDefaultTrue = (string) =>
    string == 'false' || string == false ? false : true;
  const parseToBooleanDefaultFalse = (string) =>
    string == 'true' || string == true ? true : false;

  const getAmbitText = (taxonomy_terms) => {
    const ambito = taxonomy_terms.filter(
      (taxonomy_termsItem) => taxonomy_termsItem.vocabulary_id === 20
    );
    let ambitoText = '';
    ambito.forEach((ambitoItem) => {
      ambitoText += ambitoItem.name + ', ';
    });
    return ambitoText.substring(0, ambitoText.length - 2);
  };

  const getAreaText = (taxonomy_terms) => {
    const areas = taxonomy_terms.filter(
      (taxonomy_termsItem) => taxonomy_termsItem.vocabulary_id === 22
    );
    let areaText = '';
    areas.forEach((areaItem) => {
      areaText += areaItem.name + ', ';
    });
    return areaText.substring(0, areaText.length - 2);
  };

  const getTipoText = (taxonomy_terms) => {
    const tipos = taxonomy_terms.filter(
      (taxonomy_termsItem) => taxonomy_termsItem.vocabulary_id === 21
    );
    let tipoText = '';
    tipos.forEach((areaItem) => {
      tipoText += areaItem.name + ', ';
    });
    return tipoText.substring(0, tipoText.length - 2);
  };
  const filterData = (dataFiltered) => {
    // ! Check this
    if (dataFiltered.length == 0) {
      renderAll(dataFiltered);
      return;
    }
    let filteredParse = dataOfSelected.filter(({ id }) =>
      dataFiltered.some((data) => id == data.id)
    );
    renderAll(filteredParse);
  };

  const filterDataDocument = () => {
    let filtered = dataDocument.filter((item) =>
      item?.title
        ?.toLowerCase()
        ?.includes(filterFormDocument?.query?.toLowerCase())
    );

    const filterQuery = (handler, parameter) => {
      let filter = dataDocument.filter((item) =>
        handler(item[parameter])
          .toLowerCase()
          .includes(filterFormDocument.query.toLowerCase())
      );
      filtered = [...filter, ...filtered];
    };

    filterQuery(getAmbitText, 'taxonomy_terms');
    filterQuery(getAreaText, 'taxonomy_terms');
    filterQuery(getTipoText, 'taxonomy_terms');

    let filterQueryDate = dataDocument.filter((item) => {
      let date = moment(item.published_at, 'YYYY/MM/DD').format('DD/MM/YYYY');
      return date.includes(filterFormDocument.query);
    });

    filtered = [...filterQueryDate, ...filtered];

    filtered = filtered.filter((item) =>
      getAmbitText(item.taxonomy_terms)
        .toLowerCase()
        .includes(filterFormDocument.potencia.toLowerCase())
    );

    filtered = filtered.filter((item) =>
      getAreaText(item.taxonomy_terms)
        .toLowerCase()
        .includes(filterFormDocument.tipoproduccion.toLowerCase())
    );

    filtered = filtered.filter((item) =>
      getTipoText(item.taxonomy_terms)
        .toLowerCase()
        .includes(filterFormDocument.vinculacionsm.toLowerCase())
    );
    var hash = {};
    filtered = filtered.filter(function (current) {
      var exists = !hash[current.id];
      hash[current.id] = true;
      return exists;
    });

    let filteredParse = dataOfSelected.filter(({ id }) =>
      filtered.some((docu) => id == docu.id)
    );
    renderAll(filteredParse);
  };

  const onQueryChange = (event) => {
    filterFormDocument.query = event.target.value;
    if (event.target.value.length > 0)
      document
        .querySelector('.esios-documentacion__boton-remove')
        .classList.remove('hidden');
    else
      document
        .querySelector('.esios-documentacion__boton-remove')
        .classList.add('hidden');
    filterDataDocument();
  };

  const searchDataUnidades = () => {
    return servicesDocumentacion.getArchivesDocumentacion().then((respData) => {
      // WORK IN PROGRESS
      dataDocument = respData;

      const filters = {
        ambit: [],
        area: [],
        type: [],
      };

      let filterAttrs = [];

      const addFilterAttrs = (handlerParameter, value, id) => {
        filterAttrs = [
          {
            label: i18n.t('taxonomy_terms'),
            handler: getSelect,
            handlerParameter,
            value,
            id,
          },
          ...filterAttrs,
        ];
      };

      addFilterAttrs(21, 'type', 3);
      addFilterAttrs(22, 'area', 2);
      addFilterAttrs(20, 'ambit', 1);

      const createFilterModel = (data, filters, filterAttrs) => {
        data.forEach((element) => {
          for (const attr of filterAttrs) {
            const value = attr.handler(
              element[attr.label],
              attr.handlerParameter
            );

            value.map(({ name }) => {
              if (!filters[attr.value].some((e) => e.value === name)) {
                filters[attr.value].push({ label: name, value: name });
              }
            });
          }
        });
        const result = filterAttrs.map((at) => {
          const orderedF = filters[at.value].sort((a, b) => {
            const comma2Float = (label) =>
              label.split('.').join('').split(',').join('.');

            const comma2FloatA = comma2Float(a.label);
            const comma2FloatB = comma2Float(b.label);

            if (isNaN(comma2FloatA) || isNaN(comma2FloatB)) {
              if (a.label < b.label) {
                return -1;
              }
              return 1;
            } else {
              if (Number(comma2FloatA) < Number(comma2FloatB)) {
                return -1;
              }
              return 1;
            }
          });

          return {
            id: at.id,
            label: '',
            options: [{ label: 'show_all', value: '' }, ...orderedF],
          };
        });
        return result;
      };

      select = createFilterModel(dataDocument, filters, filterAttrs);

      return respData;
    });
  };

  const onSelectChange = (index) => {
    const cE = selectElements[index];
    return (event) => {
      filterFormDocument[cE.value] = event.target.value;
      let selected = document.querySelectorAll('.ui-selectmenu-text');
      filterFormDocument[cE.value] === ''
        ? (selected[index].innerHTML = i18n.t(cE.label))
        : filterFormDocument[cE.value];
      filterDataDocument();
    };
  };

  const ocultar = (event) => {
    document.querySelector('.esios-documentacion__tabla__input').value = '';
    document
      .querySelector('.esios-documentacion__boton-remove')
      .classList.add('hidden');
    filterFormDocument.query = '';
    filterDataDocument();
  };

  const search = async (event) => {
    let removeBtn = document.querySelector('.esios-resultados__button-remove');
    let loupe = document.querySelector('.esios-resultados__search-filter');

    filterFormAll.template = event.target.value;
    if (event.target.value.length > 0) {
      removeBtn.classList.remove('hidden');
      loupe.classList.add('hidden');
    } else {
      removeBtn.classList.add('hidden');
      loupe.classList.remove('hidden');
    }
  };

  const onHide = (e) => {
    document
      .querySelector('.esios-resultados__button-remove')
      .classList.add('hidden');
    document
      .querySelector('.esios-resultados__search-filter')
      .classList.remove('hidden');
    document.querySelector('.esios-resultados__buscador').value = '';
    data = [];
    document.getElementById('btn-todo').click();
    renderAll();
  };

  const onSearch = async () => {
    await searchData();
    renderAll();
  };

  function spinner() {
    const spinnerDisplayer = document.querySelector('.spinner-displayer');
    const btn = document.getElementById('btn');

    btn.addEventListener('click', () => {
      spinnerDisplayer.classList.add('loading');
    });
  }
  function truncateString(string, limit) {
    if (string.length > limit) {
      return string.substring(0, limit) + '...';
    } else {
      return string;
    }
  }
  function stripTags(text) {
    if (text) {
      return text.replace(/(<([^>]+)>)/gi, '');
    } else {
      return '';
    }
  }

  const removeClassActiveButton = () => {
    const listItems = document.querySelectorAll('.esios-resultados__btn');
    listItems.forEach((item) => item.classList.remove('btn-selected'));
  };

  const displayFilters = (filterData, filterDocument) => {
    document.getElementById('filterData').style.display = filterData;
    document.getElementById('filterDocument').style.display = filterDocument;
  };

  const resetSelect = (id) => {
    $(id).prop('selectedIndex', 0);
    $(id).selectmenu('refresh');
  };

  const handleClickButton = (e) => {
    if (filterSelected == 'Documentation') {
      ocultar();
      resetSelect('.ree-select-potencia');
      resetSelect('.ree-select-tipoproduccion');
      resetSelect('.ree-select-vinculacionsm');

      filterFormDocument = {
        query: '',
        potencia: '',
        tipoproduccion: '',
        vinculacionsm: '',
      };
    }

    if (filterSelected == 'Data' && tabDataReady) {
      componentDocumentList.resetSelects();
    }

    removeClassActiveButton();
    e.target.classList.add('btn-selected');
    filterSelected = e.target.value;
    if (filterSelected == 'Data' || filterSelected == 'Data offer') {
      displayFilters('block', 'none');
    } else if (filterSelected == 'Documentation') {
      displayFilters('none', 'flex');
    } else {
      displayFilters('none', 'none');
    }
    dataOfSelected = [];
    dataOfSelected = data.map((i) => i);
    if (filterSelected) {
      dataOfSelected = dataOfSelected.filter((type) =>
        type.type.includes(filterSelected)
      );
    }
    renderAll(dataOfSelected);
  };

  const formatWord = (word) => {
    return word.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  const getUrlGlosario = (tittle) => {
    let word = tittle.substring(0, 1);
    word = formatWord(word);
    let url = `/${i18n.t('routes.lng')}/${i18n.t(
      'routes.glossary'
    )}#letter${word}`;
    return url;
  };

  const getUrlDocument = (slug) => {
    return `/${i18n.t('routes.lng')}/${i18n.t('routes.documentation')}/${slug}`;
  };

  const getUrlPage = (url, slug) => {
    let arrayOfURLs = [
      i18n.t('routes.about'),
      i18n.t('routes.legal'),
      i18n.t('routes.physical_units'),
      i18n.t('routes.documentation'),
      i18n.t('routes.analysis'),
      i18n.t('routes.snp'),
      i18n.t('routes.gyc'),
      i18n.t('routes.help'),
      i18n.t('routes.gd'),
      i18n.t('routes.glossary'),
      i18n.t('routes.tabla'),
      i18n.t('routes.ayuda-preguntas-frecuentes'),
      i18n.t('routes.auctions_calendar'),
      i18n.t('routes.balance'),
      i18n.t('routes.contact'),
      i18n.t('routes.faq'),
      i18n.t('routes.lumios'),
      i18n.t('routes.myp'),
      i18n.t('routes.previsiones'),
      i18n.t('routes.other_links'),
      i18n.t('routes.pvpc'),
      i18n.t('routes.market_subjects'),
      i18n.t('routes.programming_units'),
      i18n.t('routes.participants_auction'),
      i18n.t('routes.myesios'),
    ];
    if (!arrayOfURLs.includes(slug)) {
      if (slug.includes(i18n.t('routes.tabla'))) {
        return url.replace('/static_pages', '');
      }
      return url.replace('/static_pages', `/${i18n.t('routes.page')}`);
    } else {
      return url.replace('/static_pages', '');
    }
  };

  const getUrlCurves = (id, widgetId) => {
    const parseUrlById = {
      601: '&direction=down',
      635: '&direction=up',
      678: '&direction=up',
      679: '&direction=down',
      629: '',
      1098: '&direction=export&type=yearly&border=France',
      1097: '&direction=import&type=yearly&border=France',
      1673: '&direction=export&type=yearly&border=Portugal',
      1672: '&direction=import&type=yearly&border=Portugal',
      1090: '&direction=export&type=daily&border=France',
      1089: '&direction=import&type=daily&border=France',
      1092: '&direction=export&type=intraday1&border=France',
      1091: '&direction=import&type=intraday1&border=France',
      1094: '&direction=export&type=intraday2&border=France',
      1093: '&direction=import&type=intraday2&border=France',
      1096: '&direction=export&type=monthly&border=France',
      1095: '&direction=import&type=monthly&border=France',
      1148: '&direction=import',
      1149: '&direction=export',
      1669: '&direction=export&type=monthly&border=Portugal',
      1668: '&direction=import&type=monthly&border=Portugal',
      1671: '&direction=export&type=quarterly&border=Portugal',
      1670: '&direction=import&type=quarterly&border=Portugal',
      671: '&direction=down',
      670: '&direction=up',
    };
    let url = `/${i18n.t('routes.lng')}/${i18n.t(
      'routes.supply_curves'
    )}?id=${widgetId}${parseUrlById[id] ? parseUrlById[id] : ''}`;
    return url;
  };
  const getUrlAnalysis = (url) => {
    let isAnalysis = url.includes('analisis');
    let idAnalysis = isAnalysis ? url.split('/').pop() : '';
    let newUrl = isAnalysis
      ? `/${i18n.t('routes.lng')}/${i18n.t('routes.analysis')}/${idAnalysis}`
      : url;
    return newUrl;
  };

  const getUrlNews = (url) => {
    return url.replace('/news', `/${i18n.t('routes.news')}`);
  };

  const searchData = async () => {
    filterFormAll.template = filterFormAll.query || '';
    filterFormAll.query = filterFormAll.query?.replace(/\s+/g, '+');
    filterFormAll.query = filterFormAll.query?.trimStart();
    filterFormAll.query = filterFormAll.query?.trimEnd();
    return await servicesResultados
      .getResults({
        query: filterFormAll.query,
        completeWord: filterFormAll.p1,
        onlyDesciption: filterFormAll.p2,
      })
      .then((respData) => {
        data = respData.sort((a, b) => {
          if (a.title.toLowerCase() < b.title.toLowerCase()) {
            return -1;
          }
          if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        quantity = respData.length;
        data.map(
          async ({ body, type, title, slug, url, widget_id, id }, index) => {
            if (type == 'Glossary') {
              data[index].url = getUrlGlosario(title);
            }
            if (type == 'Documentation') {
              data[index].url = getUrlDocument(slug);
            }
            if (type == 'StaticPage') {
              data[index].url = getUrlPage(url, slug);
            }
            if (type == 'Data offer') {
              data[index].url = getUrlCurves(id, widget_id);
            }
            if (type == 'Data') {
              data[index].url = getUrlAnalysis(url, id, widget_id);
            }
            if (type == 'News') {
              data[index].url = getUrlNews(url, id, widget_id);
            }
            let bodyParse = stripTags(body);
            data[index].body = truncateString(bodyParse, 175);
          }
        );
        return servicesResultados;
      });
  };

  let renderAll = (altData = {}) => {
    templateResultados(
      context,
      handlers,
      filterFormAll,
      quantity,
      altData || data,
      select,
      selectElements,
      filterFormDocument
    );
  };

  const resetHandler = () => {
    let container = document.querySelector('.header');
    let btn = document.getElementById('btn-filter');
    let btnClone = btn.cloneNode(true);
    btn.remove();
    if (filterContainerOpen) {
      btnClone.className = 'filter-off';
      filterContainerOpen = false;
    } else {
      btnClone.className = 'filter-on';
      filterContainerOpen = true;
    }
    container.appendChild(btnClone);
  };

  const showContainer = (show) => {
    resetHandler();
    if (show) {
      document.getElementById('allFilters').style.display = 'flex';
      document.getElementById('btn-filter').addEventListener('click', () => {
        showContainer(false);
      });
    } else {
      document.getElementById('allFilters').style.display = 'none';
      document.getElementById('btn-filter').addEventListener('click', () => {
        showContainer(true);
      });
    }
  };

  const initParam = (id, type) => {
    document.getElementById(id).checked = filterFormAll[id];
    document.getElementById(id).addEventListener('change', function () {
      setParameter(id, this[type]);
    });
  };

  const createSelect = (selects) => {
    selects.map(({ className, evento }) => {
      $(className).selectmenu({
        appendTo: '.esios-documentacion__wrap2',
      });

      $(className).on('selectmenuchange', function (event, ui) {
        evento(event);
      });
    });
  };

  let renderComponent = async () => {
    templateResultados(
      context,
      handlers,
      filterFormAll,
      quantity,
      data,
      select,
      selectElements,
      filterFormDocument
    );
    await searchData();

    try {
      templateResultados(
        context,
        handlers,
        filterFormAll,
        quantity,
        data,
        select,
        selectElements,
        filterFormDocument
      );

      setTimeout(() => {
        componentDocumentList.render(newDLArray, '#filterData');
      }, 50);

      document
        .querySelector('.esios-resultados form')
        .addEventListener('submit', function (e) {
          e.preventDefault();
          setParameter('query', filterFormAll.template);
          window.location.reload();
        });
      document.querySelector('#app').classList.add('search-page');

      document.getElementById('btn-filter').addEventListener('click', () => {
        showContainer(true);
      });
      initParam('p1', 'checked');
      initParam('p2', 'checked');
      filterDataDocument();

      document.querySelector(`button[value="${filterSelected}"]`).click();
      await searchDataUnidades();

      const selects = [
        {
          className: '.ree-select-potencia',
          evento: handlers.onSelectChange(0),
        },
        {
          className: '.ree-select-tipoproduccion',
          evento: handlers.onSelectChange(1),
        },
        {
          className: '.ree-select-vinculacionsm',
          evento: handlers.onSelectChange(2),
        },
      ];

      document.getElementById('filterDocument').style.opacity = '1';
      document.getElementById('filterDocument').style.pointerEvents = 'all';
      createSelect(selects);

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
    document.querySelector('.esios-header__mod-rapid-access')
      ? (this.style.display = 'none')
      : '';
    tabDataReady = true;
  };

  let postRenderComponent = async () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
    await searchData();
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();
    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
    filterData,
  };
})();

export { componentResultados };
