import { html } from 'lit-html';

let templateListPrintView = (data, columns) => {
  return html` <ul class="esios-list-print-view">
    ${data.map((i) => {
      return html` <li class="esios-list-print-view__item">
        <table>
          <tbody>
            ${columns.map((j) => {
              return html` <tr>
                <th>${j.name}</th>
                <td>${i[j.field]}</td>
              </tr>`;
            })}
          </tbody>
        </table>
      </li>`;
    })}
  </ul>`;
};

export { templateListPrintView };
