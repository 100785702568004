'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';

let templateToolbar = (obToolbar, handlers, updateDate) => {
  // let HOST = window.location.host;
  // let URL = `${HOST}/${i18n.t('routes.lng')}`;
  var path = window.location.href;
  path = path.replace('#', '');
  const { onClickJSON, onClickCSV, onClickExcel } = handlers;
  return html`
    <div class="esios-toolbar__component">
      <div class="esios-toolbar__wrapper">
        ${obToolbar.info === true
          ? html`
              <span class="esios-toolbar__items">
                <button
                  id="${'buttonModal' + obToolbar.id}"
                  type="button"
                  class="esios-toolbar__buttons toolbar-info"
                ></button>
                <span
                  class="esios-toolbar__title title-info"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  >${i18n.t('more_info').toUpperCase()}</span
                >
              </span>
            `
          : ''}
        ${obToolbar.share === true
          ? html`
              <span class="esios-toolbar__items">
                <button
                  id="esios-toolbar__buttons${obToolbar.id}"
                  class="esios-toolbar__buttons toolbar-share"
                ></button>
                <span class="esios-toolbar__title title-share"
                  >${i18n.t('share').toUpperCase()}</span
                >
                <div
                  class="esios-toolbar__share hidden"
                  id="esios-toolbar__list${obToolbar.id}"
                >
                  <ul class="esios-toolbar__list">
                    <li class="esios-toolbar__item">
                      <i class="fas fa-download"></i>
                      <ul>
                        <li>
                          <a
                            href="#"
                            id="mydivcsv"
                            @click="${(e) =>
                              onClickCSV(obToolbar, updateDate, e)}"
                            >CSV |</a
                          >
                        </li>
                        <li>
                          <a
                            href="#"
                            id="mydivjson"
                            @click="${(e) =>
                              onClickJSON(obToolbar, updateDate, e)}"
                            >JSON |</a
                          >
                        </li>
                        <li>
                          <a
                            href="#"
                            id="mydivexcel"
                            @click="${(e) =>
                              onClickExcel(obToolbar, updateDate, e)}"
                            >Excel</a
                          >
                        </li>
                      </ul>
                    </li>
                    <li
                      class="esios-toolbar__item"
                      id="buttonShareModal${obToolbar.id}"
                      @click=${(e) => e.preventDefault()}
                    >
                      <i class="fas fa-share-alt"></i>
                      <a
                        href="${`/${i18n.t('routes.lng')}/${i18n.t(
                          'routes.embed'
                        )}/${obToolbar?.slug}${
                          obToolbar?.params ? +'?' + obToolbar?.params : ''
                        }`}"
                        class="ancle"
                        >${i18n.t('share')}</a
                      >
                    </li>
                    <li class="esios-toolbar__item print">
                      <i class="fas fa-print"></i>
                      <a
                        class="ancle"
                        href="${`/${i18n.t('routes.lng')}/${i18n.t(
                          'routes.embed'
                        )}/${obToolbar?.slug}?print=true${
                          obToolbar?.params ? '&' + obToolbar?.params : ''
                        }`}"
                        target="_blank"
                      >
                        ${i18n.t('print')}
                      </a>
                    </li>
                  </ul>
                </div>
              </span>
            `
          : ''}
        ${obToolbar.favorite === true
          ? html`
              <span
                class="esios-toolbar__items"
                id="${'buttonFavContainer' + obToolbar.id}"
              >
                <button
                  id="${'buttonFav' + obToolbar.id}"
                  class="esios-toolbar__buttons toolbar-favorite"
                ></button>
                <span class="esios-toolbar__title title-fav"
                  >${i18n.t('add_to_fav').toUpperCase()}</span
                >
              </span>
            `
          : ''}
        ${obToolbar.analysis === true
          ? html`
              <span class="esios-toolbar__items">
                <a target="_self" href="${obToolbar.url()}">
                  <button
                    class="esios-toolbar__buttons toolbar-analysis"
                  ></button>
                </a>
                <span class="esios-toolbar__title title-analysis"
                  >${i18n.t('see_analysis').toUpperCase()}</span
                >
              </span>
            `
          : ''}
      </div>
      <div id="${'modal' + obToolbar.id}" class="share__modal"></div>
      <div id="${'modalFav' + obToolbar.id}" class="share__modal"></div>
      <div id="${'share-modal' + obToolbar.id}" class="share__modal"></div>
    </div>
  `;
};

export { templateToolbar };
