'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';
import backImage from '../../assets/images/header-sujetos-mercado.jpg';
import { componentLayout } from '../../components/layout/layout.component';
import { templateBoxMarket } from '../../shared/components/box-market/box-market.template';
import { templateBoxMarketColumn } from '../../shared/components/box-market-column/box-market-column.template';

let templateSujetosMercado = (
  contactData,
  { objMarket, listMarket },
  handlers,
  select,
  searchForm,
  paginationValues,
  pagination,
  lang
) => {
  const { title, body } = contactData;
  const {
    onQueryChange,
    onSmChange,
    onTotalChange,
    onPageChange,
    onAmbitChange,
    onAreaChange,
    onDateInitChange,
    onDateEndChange,
    onHide,
    ocultar,
  } = handlers;
  return componentLayout.render(
    html`
      <div class="esios-smercado init-dropdown esios-smercado__dropdown">
        <h1 class="esios-smercado__title">${title || ''}</h1>
        ${html([body]) || ''}
        <div id="print-list"></div>

        <div class="esios-smercado__div-header hidden">
          <button
            class="esios-smercado__button-remove hidden"
            @click="${onHide}"
          >
            &nbsp
          </button>

          <input
            class="esios-tabla__input esios-smercado__search"
            placeholder="${i18n.t('go_search')}"
            type="text"
            @keyup="${onQueryChange}"
          />

          <div class="esios-smercado__div-select">
            <select
              name="sujetos"
              class="esios-smercado__select"
              value="${searchForm.type}"
              @change="${onSmChange}"
            >
              <option class="option" value="" selected disabled hidden>
                ${i18n.t('BRP-Type')}
              </option>
              ${select[0]?.options?.map(
                (item) =>
                  html`<option class="option" value="${item.value}">
                    ${i18n.t(item.label)}
                  </option>`
              )}
            </select>
          </div>
        </div>

        <div id="spinner1" class="spinner-container">
          <div class="spinner"></div>
        </div>
        <div id="table"></div>

        <div id="pagination" class="esios-pagination">
          <div class="esios-pagination__results hidden">
            <span class="esios-pagination__txt esios-pagination__arrow">
              ${i18n.t('showing')}
              <select
                class="esios-pagination__select"
                value="${pagination.pagination}"
                @change="${onTotalChange}"
              >
                ${paginationValues.map(
                  (item) => html`<option value="${item}">${item}</option>`
                )}
              </select>
            </span>
            <span class="esios-pagination__txt"
              >${i18n.t('text_results')} ${pagination.total}</span
            >
          </div>
          ${pagination.totalPages > 0
            ? html`<div
                id="pagination-sujetos"
                class="esios-pagination__paginator"
              ></div>`
            : ''}
        </div>

        <div id="sujeto-mercado-buttons" class="esios-smercado__buttons"></div>
      </div>
    `,
    {
      image: backImage,
      showBanner: true,
    },
    html``,
    {
      title: `${i18n.t('more')}`,
      subcontent: html`
        <div class="esios-documents">
          <div class="grid-xs-12 documents-container-download">
            <div class="mod-documents-download">
              ${templateBoxMarketColumn(listMarket)}
              <div class="mod-buttons">
                ${lang == 'es'
                  ? html` <a
                      class="mod-buttons__btn"
                      href="https://www.ree.es/es/clientes"
                      target="_blank"
                      >${i18n.t('gde_website_text')}</a
                    >`
                  : ''}
              </div>
            </div>
          </div>
        </div>
      `,
    }
  );
};

export { templateSujetosMercado };
