'use strict';

/**
 * @description Help Component
 * @returns {Object} Devuelve la instancia del componente
 */
import i18n from 'i18next';
import { html, render } from 'lit-html';
import moment from 'moment';
import { store } from '../../app.init.js';
import headerDefault from '../../assets/images/header-default.jpg';
import Banner from '../../shared/components/banner/banner.js';
import { BreadCrumbs } from '../../shared/components/breadcrumbs/breadcrumbs.template';
import { datepickerComponent } from '../../shared/components/datepicker/datepicker.component.js';
import { componentModal } from '../../shared/components/modal/modal.component.js';
import { templateShareModal } from '../../shared/components/modal/modalShare.template.js';
import { componentToolbar } from '../../shared/components/toolbar/toolbar.component.js';
import { api } from '../../utils/api-interceptor.js';
import { charts } from '../../utils/options-charts.js';
import { sRender } from '../../utils/render.js';
import { getState } from '../../utils/state.js';
import { getUrlParams } from '../../utils/url';
import { Utils } from '../../utils/utils.js';
import { getTabsForAnalysis } from '../analysis/analysis-tabs.js';
import AnalysisToolbar from '../analysis/analysis-toolbar.js';
import { ChartLogic } from '../analysis/chart/analysis-chart.js';
import { getCompareDate } from '../analysis/form/analysis-form-period.js';
import { servicesgenerConsumo } from '../generacion y consumo/generConsumo.service.js';
import { servicesHome } from '../home/home.services.js';
import { servicesnoPeninsulares } from '../noPeninsulares/noPeninsulares.services.js';
import MyEsiosChart from './chart/myesios-chart.js';
import servicesMyEsiosChart from './chart/myesios-chart.service.js';
import MyEsiosNoFav from './chart/myesios-nofav.js';
import MyEsiosModal from './my-esios-modal.js';
import servicesMyEsios from './my-esios.services.js';
import { servicesIntercambiosInternacionales } from '../intercambios-internacionales/intercambiosInternacionales.services';
import { gestionDemandaService } from '../gestion-demanda/gestionDemanda.service.js';

const compareCallsCache = {};

const MyEsios = () => {
  const state = store.getState();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let date;

  let first = false;
  let valuesDefault = [];
  let contentToSort = [];
  let allFavourites = [];

  const initMyEsios = () => {
    servicesMyEsiosChart;
    try {
      servicesMyEsios.getFavourites().then(({ favourites }) => {
        favourites = favourites.filter(
          (item) => item.uri !== '/24' && item.uri.charAt(1) !== '/'
        );
        allFavourites = favourites;
        if (favourites.length == 0) {
          document
            .querySelector('.esios-gd__wrap')
            .classList.add('esios-gd__wrap--myesios');
          sRender(MyEsiosNoFav, 'myesiosContainer');
        }
        let buttonHeader = document.getElementById('buttonHeader');
        buttonHeader.innerHTML = i18n.t('order_data');
        favourites.map((fav) => (fav.sucess = false));

        let date;

        if (urlParams.get('date')) {
          date = urlParams.get('date');
          document.getElementById('datepicker').value = moment(
            date,
            'DD-MM-YYYY'
          ).format('DD / MM / YYYY');
        } else {
          date = moment(
            document.querySelector('#datepicker').value,
            'DD/MM/YYYY'
          ).format('DD-MM-YYYY');
          setParameter('date', date);
        }
        favourites.map((favourite, i) => {
          if (favourite.favourite_type === 'analysis') {
            drawAnalysisFavorite(favourite, date);
            api(
              `/indicators${
                favourite.uri.split('?')[0]
              }?values=false&locale=${i18n.t('routes.lng')}`
            ).then(({ indicator }) => {
              allFavourites[i].name = indicator.name;
              allFavourites[i].sucess = true;
              allSucess(allFavourites) ? renderModal(allFavourites) : '';
            });
          } else {
            const id = getId(favourite.uri);
            createDiv(id);
            render(
              MyEsiosChart(id),
              document.querySelector('.esios-myesios__widget' + id)
            );
            getDataAndDraw(favourite, i, date);
          }
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getData = async (id, date) => {
    let dateForCalc = moment().tz('Europe/Madrid');
    let sameDate = true;
    date == dateForCalc.format('DD / MM / YYYY') ||
    date == dateForCalc.format('DD/MM/YYYY') + '' ||
    date == dateForCalc.format('DD-MM-YYYY')
      ? (sameDate = true)
      : (sameDate = false);

    let currentHour = sameDate ? dateForCalc.format('HH') : '00';

    let minutes = Math.floor(dateForCalc.format('mm') / 10) * 10;
    minutes = minutes <= 10 ? '00' : minutes - 10;
    let currentMinutes = `${sameDate ? minutes : '00'}`;

    if (id == 12 || id == 13 || id == 14 || id == 15) {
      return servicesnoPeninsulares.dataNumber(
        state.exampleReducer.currentLang,
        id,
        date
      );
    }
    if (id == 25) {
      return servicesHome.getDataOtherIndicators(id, {
        lang: state.exampleReducer.currentLang,
        globalState: `${currentHour}:${currentMinutes}:00`,
        date,
      });
    }
    if (id == 1 || id == 4) {
      return servicesgenerConsumo.getDataFromWidget({
        id,
        lang: state.exampleReducer.currentLang,
        minutes: currentMinutes,
        hours: currentHour,
        globalState: `${currentHour}:${currentMinutes}:00`,
        date,
      });
    }
    if (id == 3) {
      return servicesgenerConsumo.getDataFromWidget({
        lang: state.exampleReducer.currentLang,
        date,
        id,
        globalState: state.exampleReducer,
      });
    }
    if (id == 36 || id == 37 || id == 41) {
      return servicesIntercambiosInternacionales.getDataFromWidget({
        id,
        date,
      });
    }
    if (id == 10) {
      let data = await servicesgenerConsumo.getDataFromWidget({
        lang: state.exampleReducer.currentLang,
        date,
        id,
        globalState: state.exampleReducer,
      });

      return servicesgenerConsumo
        .getDataFromWidget({
          lang: state.exampleReducer.currentLang,
          date,
          id: '35',
          globalState: state.exampleReducer,
        })
        .then((datos) => {
          return {
            ...data,
            indicators: data.indicators.concat(datos.indicators),
          };
        });
    }
    if (id == 38) {
      return servicesIntercambiosInternacionales.getDataFromWidget({
        id: 38,
        date,
      });
    }
    if (id == 23) {
      return gestionDemandaService.getDesgloseDataFromWidget({
        lang: state.exampleReducer.currentLang,
        globalState: state.exampleReducer,
        date,
        hours: currentHour,
        minutes: currentMinutes,
      });
    }

    return servicesMyEsios.getDataFromWidget(id, {
      lang: state.exampleReducer.currentLang,
      date,
      globalState: state.exampleReducer,
    });
  };

  const getDataAndDraw = (
    favourite,
    i,
    date = moment().tz('Europe/Madrid').format('DD-MM-YYYY')
  ) => {
    const id = getId(favourite.uri);
    const spinnerId = 'spinner' + id;
    !!document.getElementById(spinnerId)
      ? Utils.spinnerStatus(spinnerId, true)
      : '';

    getData(id, date)
      .then((data) => {
        if (id != 12 && id != 13 && id != 14 && id != 15 && id != 24) {
          !!document.getElementById(spinnerId)
            ? Utils.spinnerStatus(spinnerId, false)
            : '';
        }

        !!document.getElementById('title' + id)
          ? (document.getElementById('title' + id).innerHTML = data.name)
          : '';

        data.indicators?.every(
          (indicator) => indicator.print_values?.length == 0
        )
          ? showNoData(true, id)
          : showNoData(false, id);

        drawWidget(id, i, data, date, favourite.uri);
      })
      .catch((error) => {
        !!document.getElementById(spinnerId)
          ? Utils.spinnerStatus(spinnerId, true)
          : '';
        showNoData(true, id);
      });
  };

  const drawWidget = (
    id,
    i,
    data,
    date = moment().tz('Europe/Madrid').format('DD-MM-YYYY'),
    uri
  ) => {
    drawToolbar(id, data, uri);
    allFavourites[i].sucess = true;
    if (id == 12 || id == 13 || id == 14 || id == 15) {
      allFavourites[i].name = data[0].name;
    } else {
      allFavourites[i].name = data.name;
    }
    if (id == 10) {
      charts[id](data, { currentHour: '00', currentMinutes: '00' });
    } else if (id == 40) {
      charts[id]({ data, currentDate: date });
    } else {
      charts[id] ? charts[id](data, date) : '';
    }
    servicesMyEsiosChart.postRenderChart(id, data);
    allSucess(allFavourites) ? renderModal(allFavourites) : '';
  };

  initMyEsios();

  const renderModal = (favourites) => {
    componentModal.render(MyEsiosModal(favourites), 'buttonHeader', 'modal');

    $('#typeOrder').selectmenu({
      appendTo: '.select-typeOrder',
    });

    $('#typeOrder').on('selectmenuchange', function (_, ui) {
      sortByType(ui.item.value);
    });

    $('#sortable').sortable({});

    let closeModal = () => {
      let modal = document.getElementById('modal-buttonHeader');
      let body = document.getElementsByTagName('body')[0];

      modal.style.display = 'none';
      body.style.position = 'inherit';
      body.style.height = 'auto';
      body.style.overflow = 'visible';
    };

    document.getElementById('btnApply').addEventListener('click', () => {
      let items = $('#sortable').sortable('toArray');
      let putRequest = items.reduce((request, item, order) => {
        request = [
          ...request,
          { id: parseInt(item.split('order')[1]), order, abc: false },
        ];
        return request;
      }, []);

      servicesMyEsios.changeOrder(JSON.stringify(putRequest)).then(() => {
        closeModal();
        location.reload(true);
      });
    });

    document.getElementById('btnCancel').onclick = () => closeModal();

    let sortByType = (type) => {
      if (!first) {
        first = true;
        $('#sortable')
          .sortable('toArray')
          .map((id) => {
            contentToSort = [
              ...contentToSort,
              { id, value: document.getElementById(id).innerText },
            ];
          });
        valuesDefault = [...contentToSort];
      }
      if (type == 'alpha') {
        $('#sortable').sortable('disable');
        contentToSort.sort((a, b) =>
          a.value > b.value ? 1 : b.value > a.value ? -1 : 0
        );
        reset();
        contentToSort.forEach(({ value, id }) => {
          return draw(
            id,
            value,
            'esios-myesios__item esios-myesios__order-manual ui-sortable-handle'
          );
        });
        $('#sortable').sortable();
      }

      if (type == 'manual') {
        $('#sortable').sortable('enable');
        reset();
        valuesDefault.forEach(({ value, id }) =>
          draw(id, value, 'esios-myesios__item icon-drag ui-sortable-handle')
        );
        $('#sortable').sortable();
      }
    };
  };

  const reset = () => {
    let ul = document.getElementById('sortable');
    ul.innerHTML = '';
  };

  const draw = (id, value, allClass) => {
    let ul = document.getElementById('sortable');
    let li = document.createElement('li');
    li.appendChild(document.createTextNode(value));
    li.setAttribute('id', id);
    li.setAttribute('class', allClass);
    ul.appendChild(li);
  };

  const allSucess = (favourites) => {
    let sucess = true;
    favourites.forEach((fav) => {
      if (!fav.sucess) {
        sucess = false;
      }
    });
    if (sucess == true) {
      document
        .getElementById('buttonHeader')
        .setAttribute('style', 'pointer-events: all; opacity: 1;');
    }
    return sucess;
  };

  const getId = (uri) => {
    return uri.split('?')[0].slice(1);
  };

  const createDiv = (id) => {
    const div = document.createElement('div');
    div.setAttribute('id', id);
    div.setAttribute(
      'class',
      'esios-myesios__widget' + id + ' esios-myesios__widget-container'
    );
    document.querySelector('.esios-myesios__container').appendChild(div);
  };

  const getDateToCalendar = () => {
    let date = localStorage.getItem('bread-crumbs-date');
    if (!date) {
      const currentDate = new Date();
      let day = currentDate.getDate();
      let month = currentDate.getMonth() + 1;
      const year = currentDate.getFullYear();
      // add 0s if day or month are less than 10
      if (day < 10) day = '0' + day;
      if (month < 10) month = '0' + month;

      date = `${day}/${month}/${year}`;
    }
    return date;
  };

  const drawToolbar = (id, data, uri) => {
    let toolBarOption;

    let textModal = `
      <div class="esios-noPeninsulares__title">
        <h1>${data.name?.toUpperCase()}</h1>
      </div>
    ${data.description}`;

    let dataToUrl = getDateToCalendar().replaceAll('/', '-');
    if (id == 19 || id == 26) {
      toolBarOption = {
        id,
        slug: data.slug,
        info: true,
        share: true,
        favorite: true,
        favId: id,
        uri,
        textModal,
        myEsios: true,
      };
    } else {
      data.slug = getSlug(id, data.slug);

      toolBarOption = {
        id,
        slug: data.slug,
        info: true,
        share: true,
        favorite: true,
        favId: id,
        uri,
        analysis: true,
        textModal,
        myEsios: true,
        url: () => data.urlAnalysis + '&start_date=' + dataToUrl + 'T00:00',
      };
    }
    let shareData = window.location.search;
    toolBarOption.shareModal = !!data.slug
      ? templateShareModal(data.slug, shareData, true)
      : '';
    toolBarOption.widgetData = data;
    toolBarOption.datetime = urlParams.get('date')
      ? moment(urlParams.get('date'), 'DD-MM-YYYY')
          .tz('Europe/Madrid')
          .format('YYYY-MM-DD_HH_mm')
      : moment().tz('Europe/Madrid').format('YYYY-MM-DD_HH_mm');
    if (id != 12 && id != 13 && id != 14 && id != 15) {
      componentToolbar.render(toolBarOption, '.esios-toolbar' + id, false);
    }
  };

  const showNoData = (show, id) => {
    if (id == 17 || id == 18 || id == 21) {
      let type = show ? 'block' : 'none';
      let action = show ? 'add' : 'remove';

      document.querySelector(
        '#widget' + id + ' .esios-myesios__no-data'
      ).style.display = type;

      document
        .getElementById('widget' + id)
        .classList[action]('esios-myesios__no-data-graphic');
    }
  };

  setTimeout(() => {
    document
      .querySelector('.esios-layout__banner-image')
      .classList.add('.esios-layout__banner-image--myesios');
    document
      .querySelector('.esios-layout__breadcrumbs__wrapper')
      .classList.add('esios-layout__breadcrumbs');
    document
      .querySelector('.row.t-margin-0.t-max-height')
      .classList.add('row.t-margin-0.t-max-height--myesios');
    document
      .querySelector('.esios-layout__breadcrumbs__button')
      .classList.add('esios-layout__breadcrumbs__button--myesios');
    document
      .querySelector('.esios-layout__breadcrumbs')
      .classList.add('esios-layout__breadcrumbs--myesios');
    document
      .querySelector('.esios-layout__extended.row')
      .classList.add('esios-layout__extended.row--myesios');
    document
      .querySelector(
        '.esios-layout__related-content.esios-layout__related-content--gd'
      )
      .classList.add('esios-layout__related-content--myesios');
    document
      .querySelector('.esios-layout__extended')
      .classList.add('esios-layout__extended--myesios');
    document
      .querySelector('.esios-footer__wrapper')
      .classList.add('esios-footer__wrapper--myesios');
    datepickerComponent.render();

    $(document).on('change', async function (e) {
      updateChars();
    });

    document.getElementById('datepicker').addEventListener('click', () => {
      document
        .getElementsByClassName('ui-datepicker-current')[0]
        .addEventListener('click', () => {
          document.getElementById('datepicker').value =
            moment().format('DD / MM / YYYY');
          updateChars();
        });
    });
  }, 100);

  const updateChars = () => {
    const currentDatetime = moment(
      document.querySelector('#datepicker').value,
      'DD/MM/YYYY'
    ).format('DD-MM-YYYY');
    setParameter('date', currentDatetime);

    allFavourites.map((favourite, i) => {
      if (favourite.favourite_type === 'analysis') {
        drawAnalysisFavorite(favourite, currentDatetime);
      } else {
        let idWidget = getId(favourite.uri);
        if (idWidget == 1) {
          document.querySelector('#char1 .gen-bar svg')?.remove();
          document.querySelector('#char1 .piePotencia svg')?.remove();
          document.querySelector('#char1 .piePotencia .legend')?.remove();
          getDataAndDraw(favourite, i, currentDatetime);
        } else if (idWidget == 23) {
          document
            .querySelector('#widget23 .legend-potencial-pie ul')
            ?.remove();
          document.querySelector('#widget23 .piePotencia svg')?.remove();
          document.querySelector('#widget23 .chartLegend')?.remove();
          getDataAndDraw(favourite, i, currentDatetime);
        } else if (idWidget == 24) {
          document.querySelector('#widget24 svg')?.remove();
          document
            .getElementById('balance-graph')
            .setAttribute('style', 'background-color: white !important');
          document
            .getElementById('balancesNoDataRect')
            .setAttribute('style', 'visibility: hidden');
          getDataAndDraw(favourite, i, currentDatetime);
        } else if (idWidget == 7) {
          let svgs = document.querySelectorAll(`#char${idWidget} svg`);
          for (let svg of svgs) {
            svg.remove();
          }
          getDataAndDraw(favourite, i, currentDatetime);
        } else {
          let svgs = document.querySelectorAll(`#widget${idWidget} svg`);
          for (let svg of svgs) {
            svg.remove();
          }
          getDataAndDraw(favourite, i, currentDatetime);
        }
      }
    });
  };

  const setParameter = (key, value) => {
    urlParams.set(key, value);
    history.pushState({}, '', window.location.pathname + '?' + urlParams);
  };

  return html`
    ${Banner(headerDefault)}
    <div class="esios-layout__main esios-layout__main-myEsios">
      ${BreadCrumbs(
        { calendar: true, date: getDateToCalendar() },
        { button: true }
      )}
      <div class="esios-layout__extended row esios-myesios">
        <div
          class="esios-layout__related-content esios-layout__related-content--gd"
        >
          <div class="esios-gd__wrap">
            <div id="breadcrumb"></div>
            <div id="myesiosContainer" class="esios-myesios__container"></div>
          </div>
        </div>
        <div id="modal"></div>
      </div>
    </div>
  `;
};

export function drawAnalysisFavorite(favourite, currentDatetime) {
  try {
    const uri = favourite.uri;
    let params = getUrlParams(uri);
    const { currentLang } = getState();
    const infoCall = api(
      `/indicators${uri.split('?')[0]}?values=false&locale=${currentLang}`
    );
    const favsDate = moment(currentDatetime, 'DD-MM-YYYY').toDate();
    const today = moment(new Date(), 'DD/MM/YYYY')
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .toDate();
    const diff = favsDate.getTime() - today.getTime();
    const { url, params: newParams } = getUriDiff(favourite, diff);
    params = newParams;
    const indicatorCall = api(`/indicators${url}`);
    const id = getId(favourite.uri);
    createDiv(id);
    const apiCalls = [infoCall, indicatorCall];
    let allCompares = [];
    if (params.compare_indicators) {
      allCompares = params.compare_indicators.split(',');
      let compareCall = compareCallsCache[id];
      if (!compareCall) {
        compareCall = api(`compare-groups/${id}?locale=${currentLang}`);
      }
      apiCalls.push(compareCall);
    }
    infoCall.then((info) => {
      render(
        html`${AnalysisTitle(info.indicator)}
          ${AnalysisToolbar(info.indicator, false, uri)}
          <div class="esios-analysis__info-chart">
            <div id="modalFav-${info.indicator.id}"></div>
          </div>`,
        document.querySelector('.esios-myesios__widget' + id)
      );
    });

    Promise.all(apiCalls).then((responses) => {
      const [info, { indicator }, cIndicator] = responses;
      let indicators;
      if (cIndicator) {
        compareCallsCache[id] = cIndicator;
        const { indicadores_snp, indicadores_de_universo } =
          cIndicator.indicator;
        indicators = [...indicadores_snp, ...indicadores_de_universo].filter(
          (i) => allCompares.includes(String(i.id))
        );
      }
      const vis = Number(params.vis) || 1;
      const tabs = getTabsForAnalysis(indicator);
      const tab = tabs[vis - 1] || tabs[tabs.length - 1];

      if (vis == 3) {
        const script = document.createElement('script');
        const scriptCss = document.createElement('script');
        const link = document.createElement('link');
        script.src =
          'https://libs.cartocdn.com/cartodb.js/v3/3.14.6/cartodb.js';
        scriptCss.src =
          'https://libs.cartocdn.com/cartodb.js/v3/3.14.6/themes/css/cartodb.css';
        link.rel = 'stylesheet';
        link.href =
          'https://libs.cartocdn.com/cartodb.js/v3/3.14.6/themes/css/cartodb.css';
        document.head.appendChild(script);
        document.head.appendChild(scriptCss);
        document.head.appendChild(link);
      }

      params.compare_end_date =
        params.compare_end_date || getCompareEndDate(params);
      const analysis = {
        indicator: info.indicator,
        vis,
        tabSelected: { index: vis - 1, label: tab },
        ...params,
        groupBy: params.groupby,
        indicators,
        compare_indicators: params.compare_indicators,
      };
      render(
        html`${AnalysisTitle(info.indicator)}
          ${AnalysisToolbar(info.indicator, false, uri)}
          <div class="esios-analysis__info-chart">
            <div id="modalFav-${info.indicator.id}"></div>
            ${ChartLogic(analysis, info)}
          </div>`,
        document.querySelector('.esios-myesios__widget' + id)
      );
    });
  } catch (error) {
    console.error(error);
  }
}
function getSlug(id, valueDefault) {
  if (id == 38) {
    return 'balance-programmed-interconections';
  } else if (id == 39) {
    return 'hourly-balances-border';
  } else if (id == 40) {
    return 'spot-europa';
  } else if (id == 41) {
    return 'saldo-frontera';
  }
  return valueDefault;
}

function getId(uri) {
  return uri.split('?')[0].slice(1);
}

function createDiv(id) {
  const div = document.createElement('div');
  div.setAttribute('id', id);
  div.setAttribute(
    'class',
    'esios-myesios__widget' + id + ' esios-myesios__widget-container'
  );
  document.querySelector('.esios-myesios__container').appendChild(div);
}

function AnalysisTitle(indicator) {
  return html`<h2 class="esios-home__title esios-myesios__title">
    ${indicator.name}
  </h2>`;
}

function getCompareEndDate({ start_date, end_date, compare_start_date }) {
  return getCompareDate(start_date, end_date, compare_start_date);
}
function getUriDiff(favourite, diff) {
  let urlParse;
  let urlChanged = false;
  let toRemovePosition;

  if (favourite.uri.charAt(0) == '/') {
    toRemovePosition = favourite.uri.indexOf('?');
    urlParse = favourite.uri.slice(toRemovePosition + 1, favourite.uri.length);
    urlChanged = true;
  }

  const url = new URLSearchParams(urlParse);

  const dateUrlFormat = 'DD-MM-YYYYTHH:mm';
  const newStartDate = moment(url.get('start_date'), dateUrlFormat).add(
    diff,
    'milliseconds'
  );
  const newEndDate = moment(url.get('end_date'), dateUrlFormat).add(
    diff,
    'milliseconds'
  );
  const newCompareStartDate = moment(
    url.get('compare_start_date'),
    dateUrlFormat
  ).add(diff, 'milliseconds');
  url.set('start_date', newStartDate.format(dateUrlFormat));
  url.set('end_date', newEndDate.format(dateUrlFormat));
  url.set('compare_start_date', newCompareStartDate.format(dateUrlFormat));

  let urlString = url
    .toString()
    .split('%2F')
    .join('/')
    .split('%3F')
    .join('?')
    .split('%3A')
    .join(':');

  const params = getUrlParams(urlString);

  if (urlChanged) {
    let idToUrl = favourite.uri.slice(0, toRemovePosition + 1);
    urlString = idToUrl + urlString;
  }

  return {
    url: urlString,
    params,
  };
}

export default MyEsios;
