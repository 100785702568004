import { html } from 'lit-html';
import i18n from 'i18next';
import { templateToolbarTime } from '../../../../shared/components/toolbarTime/toolbarTime.template';

const MyEsiosWidget20 = () => {
  return html`
    <div class="homeBalanceWidgetView" id="char20">
      <div class="esios-toolbar col-12">
        <div class="esios-toolbar20"></div>
        ${templateToolbarTime('measured-generation')}
      </div>
      <div class="row">
        <div
          id="widget20"
          class="gm-graphic"
          style="display: flex;align-items: center;"
        >
          <div id="spinner20" class="spinner-container">
            <div class="spinner"></div>
          </div>
          <div
            id="rect20"
            class="esios-home__no-data-container_cuadrado"
            style="display: none; z-index: 1;"
          >
            ${i18n.t('no-data-available')}
          </div>
          <div class="esios-pie-graph chart" id="chart">
            <div class="chartLegend"></div>
          </div>
          <div id="bar" class="percentBar"></div>
        </div>
        <div id="widget20Data" class="gm-graphic-indicators"></div>
      </div>
    </div>
  `;
};

export default MyEsiosWidget20;
