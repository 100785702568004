import { html } from 'lit-html';
import i18n from 'i18next';
import myesios1 from '../../../assets/images/es-my-esios-empty-1.png';
import myesios2 from '../../../assets/images/es-my-esios-empty-2.png';

const MyEsiosNoFav = () => {
  return html`
    <div>
      <h1 class="esios-home__title esios-myesios__title">
        ${html([i18n.t('my_esios')])}
      </h1>
      ${html([i18n.t('my_esios_welcome_1')])}
      <img alt="Es my esios empty 1" src="${myesios1}" />
      ${html([i18n.t('my_esios_welcome_2')])}
      <img alt="Es my esios empty 2" src="${myesios2}" />
    </div>
  `;
};

export default MyEsiosNoFav;
