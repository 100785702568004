'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';

let templateModalDownload = (modalDownload) => {
  return html`
    <h2 class='esios-pvpc__modal-title'>${i18n.t(
      'download_pvpc'
    )}</h2 class='esios-pvpc__modal-title'>
    <p class='esios-pvpc__modal-text'>${i18n.t(
      'select_publication_download'
    )}</p>
    <div class="row">
      <div class="col-sm-3">
        <ul class="esios-pvpc__tabs">
          ${modalDownload.map(({ id, active }) => {
            return html`
              <li
                id="tab${id}"
                class="esios-pvpc__tab ${active
                  ? 'esios-pvpc__tab--active'
                  : ''}"
                data-tab="${id}"
              >
                <p>${i18n.t('pvpc_publication_' + id)}</p>
              </li>
            `;
          })}
        </ul>
      </div>
      <div id="downloadCalendar" class="esios-pvpc__calendar col-sm-5"></div>
      <div class="col-sm-4">
        <div class="esios-pvpc__container-text">
          <p id="textModalDownload"></p>
          <a
            id="btnDownload"
            class="esios-pvpc__btn esios-btn__icon esios-btn__icon--download"
            >${i18n.t('download')}</a
          >
        </div>
      </div>
    </div>
  `;
};

export { templateModalDownload };
