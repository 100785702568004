'use strict';
import i18next from 'i18next';
import moment from 'moment-timezone';
import { api } from '../../utils/api-interceptor';

let servicesSupplyCurves = (() => {
  let init = () => {};

  const processIntraday = (intraday) => {
    return (
      i18next.t(intraday.slice(0, -1)).slice(0, -1) +
      'a' +
      ` ${intraday.slice(intraday.length - 1, intraday.length)}`
    );
  };

  const getSupplyCurvesDataFromWidget = async (params) => {
    let lng = i18next.t('routes.lng');
    const { date, id, dir, type, border } = params;
    const newDate = moment(date, 'YYYY-MM-DDTHH:mm')
      .tz('Europe/Madrid')
      .format();
    let itemAlias;
    let arrAlias = [];
    let newBorder = '';
    let newtype = '';

    if (border === 'Portugal') {
      newBorder = `${i18next.t('PT')}-${i18next.t('ES')}`;
    } else if (border === 'France') {
      newBorder = `${i18next.t('FR')}-${i18next.t('ES')}`;
    }

    if (type) {
      if (type.includes('intraday')) {
        if (i18next.t('routes.lng') === 'es') {
          newtype = processIntraday(type);
        } else {
          newtype = i18next.t('intraday');
        }
      } else if (type === 'daily') {
        if (i18next.t('routes.lng') === 'es') {
          newtype = ' ' + i18next.t('daily').slice(0, -1) + 'a';
        } else {
          newtype = i18next.t('daily');
        }
      } else {
        newtype = i18next.t(type);
      }
    }

    return api(`widgets/${id}?datetime=${newDate}&locale=${lng}`).then(
      (data) => {
        const indicators = data.widget.indicators;
        let filteredConsult = indicators.filter((el) => {
          itemAlias = el.alias;
          if (dir) {
            if (el.direction === dir) {
              return api(`${el.url}&locale=${lng}`);
            }

            if (
              el.name.includes(newBorder) &&
              el.name.includes(i18next.t(dir).toLowerCase()) &&
              el.name.includes(newtype.toLowerCase())
            ) {
              return api(`${el.url}&locale=${lng}`);
            }
          } else {
            return api(`${el.url}&locale=${lng}`);
          }
        });
        return Promise.all(
          filteredConsult.map((el) => {
            arrAlias.push(el.alias);
            return api(`${el.url}&locale=${lng}`);
          })
        ).then((data) => {
          const indicators = data.map((ele, index) => {
            const values = ele.indicator.values.map((el) => {
              return {
                precio: el.price,
                assignation_sum: el.assignation_sum,
                offer_sum: el.offer_sum,
                offer: el.offer,
                assignation: el.assignation,
                datetime: el.datetime,
                order: el.orden,
              };
            });

            return {
              values,
              alias: arrAlias[index],
              name: ele.indicator.name,
              short_name: ele.indicator.short_name,
              step: ele.indicator.step_type,
              id: ele.indicator.id,
            };
          });

          return {
            indicators,
          };
        });
      }
    );
  };

  return {
    init: init,
    getSupplyCurvesDataFromWidget: (params) =>
      getSupplyCurvesDataFromWidget(params),
  };
})();

export { servicesSupplyCurves };
