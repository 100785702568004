import i18next from 'i18next';
import { html } from 'lit-html';

const TemplateEmbedIndicatorsEnergy = () => {
  return html`
    <div
      class="offset-md-1 col-xs-6 mypCosteWidgetView"
      id="mypCosteWidgetView"
    >
      <div id="spinner7" class="spinner-container">
        <div class="spinner"></div>
      </div>
      <h2 class="esios-market-prices__subtitle">
        ${i18next.t('unitary_cost')} €/MWh
      </h2>
      <div class="row">
        <div id="mypGraphic2" class="chari-chart large col-xs-6"></div>
        <div
          id="mypNoDataRect7"
          class="esios-home__no-data-container_cuadrado hidden"
        >
          ${i18next.t('no-data-available')}
        </div>
        <div class="volume-leyends offset-1 col-xs-5"></div>
      </div>

      <div class="col-xs-24 col-sm-24 mt-5">
        <div class="widget-summary table-summary"></div>
      </div>
    </div>
  `;
};

export default TemplateEmbedIndicatorsEnergy;
