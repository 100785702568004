import i18n from 'i18next';
import { componentModal } from '../../shared/components/modal/modal.component';
import { templateModalFav } from '../../shared/components/modal/modalFav.template';
import { api } from '../../utils/api-interceptor';
import { renderModal } from '../analysis/analysis.page';

const servicesLogin = () => {
  return {
    validateSession,
    tokenIsGood,
  };
};

export function tokenIsGood() {
  if (localStorage.getItem('myUserEntity')) {
    let token = JSON.parse(localStorage.getItem('myUserEntity'))?.token;
    api('validate-session?authentication_token=' + token).then((data) => {
      if (!data.success) {
        localStorage.removeItem('myUserEntity');
      }
    });
  }
}

export function validateSession() {
  let token;
  if (localStorage.getItem('myUserEntity')) {
    token = JSON.parse(localStorage.getItem('myUserEntity'))?.token;
  }
  return token;
}

let storedFavourites;

export function FavMe({ id, type }, cb) {
  const token = validateSession();
  const getFavs = () =>
    api(
      'favourites?authentication_token=' +
        token +
        '&rdn=' +
        Math.floor(Math.random() * 1000)
    ).then(({ favourites }) => {
      storedFavourites = favourites;
      if (type) {
        FavLogic({ id, type }, cb);
      } else {
        cb(storedFavourites.some((i) => i.uri.includes(`/${id}`)));
      }
    });

  if (token) {
    if (!storedFavourites || !type) {
      getFavs();
    } else {
      FavLogic({ id, type }, cb);
    }
  } else {
    cb(null);
  }
}

export function FavLogic({ id, type }, cb) {
  let uri = `/${id}`;

  const favourite = storedFavourites.findIndex(
    (fav) => fav.uri.split('?')[0] == uri
  );
  const token = validateSession();

  if (favourite === -1) {
    const isAnalysis = window.location.pathname
      .split('/')
      .includes(i18n.t('routes.analysis'));
    if (isAnalysis) {
      uri += window.location.search;
    }
    api('favourites', {
      method: 'POST',
      body: JSON.stringify({
        uri,
        authentication_token: token,
        favourite_type: type,
        order: 0,
      }),
    }).then((res) => {
      storedFavourites.push(res.favourite);
      cb(res);
    });
  } else {
    const deleted = storedFavourites.splice(favourite, 1)[0];

    let handlerCloseModal = () => {
      let modal = document.getElementById('modal-modalFav' + id);
      let body = document.getElementsByTagName('body')[0];
      modal.style.display = 'none';
      body.style.position = 'inherit';
      body.style.height = 'auto';
      body.style.overflow = 'visible';
    };

    let handlerDelete = () => {
      handlerCloseModal();
      api('favourites/' + deleted.id + '?authentication_token=' + token, {
        method: 'DELETE',
      }).then((data) => {
        cb(data);
        const isMiEsios = window.location.pathname
          .split('/')
          .includes(i18n.t('routes.myesios'));

        if (isMiEsios) {
          location.reload(true);
        }
      });
    };

    componentModal.render(
      templateModalFav(handlerDelete, handlerCloseModal, id),
      'modalFav' + id,
      `modalFav-${id}`,
      false
    );
  }
}

export default servicesLogin;
