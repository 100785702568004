'use strict';
import { html } from 'lit-html';

let templatePaginator = (pagination, onPageChange) => {
  return html`
    <button
      type="button"
      class="esios-pagination__buttonLeftArrow"
      @click=${() => onPageChange(pagination.page - 1)}
      ?disabled=${pagination.page === 1}
      class="button"
    ></button>
    ${pagination.page >= 2
      ? html`<button
          type="button"
          class="esios-pagination__buttonNumber"
          @click=${() => onPageChange(1)}
        >
          <span>1</span>
        </button>`
      : ''}
    ${pagination.page > 2
      ? html`<span class="esios-pagination__ellipsis">...</span>
          <button
            type="button"
            class="esios-pagination__buttonNumber"
            @click=${() => onPageChange(pagination.page - 1)}
          >
            <span>${pagination.page - 1}</span>
          </button>`
      : ''}
    <button
      type="button"
      class="esios-pagination__buttonNumber esios-pagination__buttonNumber--active"
      @click=${() => onPageChange(pagination.page)}
    >
      <span>${pagination.page}</span>
    </button>

    ${pagination.page < pagination.totalPages
      ? html`<button
          type="button"
          class="esios-pagination__buttonNumber"
          @click=${() => onPageChange(pagination.page + 1)}
        >
          <span>${pagination.page + 1}</span>
        </button>`
      : ''}
    ${pagination.page < pagination.totalPages &&
    pagination.page !== pagination.totalPages - 1
      ? html` ${pagination.page !== pagination.totalPages - 2
            ? html`<span class="esios-pagination__ellipsis"> ... </span>`
            : ''}
          <button
            type="button"
            class="esios-pagination__buttonNumber"
            @click=${() => onPageChange(pagination.totalPages)}
          >
            <span>${pagination.totalPages}</span>
          </button>`
      : ''}
    <button
      class="esios-pagination__buttonRightArrow"
      @click=${() => onPageChange(pagination.page + 1)}
      ?disabled=${pagination.totalPages === pagination.page}
      class="button"
    ></button>
  `;
};

export { templatePaginator };
