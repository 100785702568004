'use strict';
import moment from 'moment';
import { store } from '../../app.init';
import { api } from './../../utils/api-interceptor';
import { API_URL } from '../../environment.json';
import i18n from 'i18next';

let servicespvpc = (() => {
  const getData = async (id) => {
    const state = store.getState();
    let lng = state.exampleReducer.currentLang;

    return await api(`widgets/${id}?locale=` + lng);
  };

  const dateFormated = (day, hour) =>
    moment(`${day} ${hour}`, 'DD/MM/YYYY HH').toDate();

  const parseDataToGraph = (values, propertiesToExport) => {
    return values.reduce((acc, value, index) => {
      Object.keys(propertiesToExport).forEach((propertie) => {
        let x = dateFormated(value.Dia, value.Hora);

        if (values.length == 25 && index > 2) {
          let substract1Day = new Date(
            moment(x).subtract('1', 'hours').format('YYYY-MM-DDTHH:mm')
          );
          let newDate = (substract1Day + '').replace('GMT+0200', 'GMT+0100');
          x = moment(newDate).toDate();
        }
        value[propertie]
          ? (acc[propertie] = [
              ...acc[propertie],
              {
                x,
                h: value.Hora,
                y: parseFloat(value[propertie].replace(',', '.')),
              },
            ])
          : (acc[propertie] = [
              ...acc[propertie],
              { x, h: value.Hora, y: '--' },
            ]);

        return acc;
      });
      return acc;
    }, propertiesToExport);
  };

  const getIndicatorOfDay = async (propertiesToExport, day, firstLoad) => {
    let date = day ? day : moment().format('YYYY-MM-DD');
    let url = `archives/70/download_json?locale=${i18n.t('routes.lng')}${
      firstLoad ? '' : '&date=' + date
    }`;
    const data = await api(url);
    return parseDataToGraph(data.PVPC, propertiesToExport);
  };

  const getNameOfFile = (file) => {
    let name = 'file';
    switch (file) {
      case '71':
        name = '71-PVPC_DEETALLE_DD-';
        break;
      case '80':
        name = '80-pvpcdesglosehorario-';
        break;
      case '79':
        name = '79-preciovoluntariopconsumidor-';
        break;
      case '78':
        name = '78-perfilconsumo-';
        break;
    }
    let datetime = moment().format('YYYY-MM-DD_HH:mm');
    return name + datetime;
  };

  const downloadFile = async ({ file, format }, date) => {
    let urlToDownload = `/archives/${file}/download?date=${date}`;
    let name = getNameOfFile(file);
    download(urlToDownload, name, format);
  };

  function download(str, fileName, ext) {
    let link = document.createElement('a');
    let sUrl = document.location.href;

    fileName =
      fileName || sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
    fileName = fileName + '.' + (ext || 'txt');
    link.setAttribute('download', fileName);
    link.href = API_URL + str;
    document.body.appendChild(link);
    link.click(); //Dispatching click event.
    document.body.removeChild(link);
  }

  return {
    getIndicatorOfDay,
    getData: (id) => getData(id),
    downloadFile: ({ file, format }, date) =>
      downloadFile({ file, format }, date),
  };
})();

export { servicespvpc };
