'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';
import { componentLayout } from '../../components/layout/layout.component';
import backImage from '../../assets/images/header-glosario.jpg';
import loupe from '../../assets/images/loupe.svg';

let templateGlosario = (
  handlers,
  paginationValues,
  pagination,
  filterForm,
  lettersGlosario
) => {
  const {
    onChangeLetra,
    onTotalChange,
    onPageChange,
    onQueryChange,
    onSearch,
  } = handlers;

  const totalPages = (pagination) => {
    let arr = [];
    for (let init = 0; init < pagination.totalPages; init++) {
      arr.push(init);
    }
    return arr;
  };

  return componentLayout.render(
    html`
      <div class="esios-glosario">
        <div class="grid-sm-24">
          <h1 class="esios-glosario__title">${i18n.t('glossary')}</h1>
        </div>

        <div class="esios-glosario__letterselection">
          ${lettersGlosario.split('').map((el) => {
            return html`
              <div @click="${onChangeLetra}">
                <a
                  href="/${i18n.t('routes.lng')}/${i18n.t(
                    'routes.glossary'
                  )}#letter${el}"
                  value="${el}"
                  class="selectletra"
                  >${el}</a
                >
              </div>
            `;
          })}
        </div>
        <div class="esios-tablaGlosario">
          <input
            id="searchGlosario"
            class="esios-tabla__input glosario-buscar"
            placeholder="${i18n.t('go_search')}"
            type="text"
            @keyup="${onQueryChange}"
          />
          <span class="search-filter">
            <img src="${loupe}" alt="Logo" />
          </span>
          <div id="spinner" class="spinner-container">
            <div class="spinner"></div>
          </div>
          <div class="styleRow">
            <div id="glosario-table"></div>
          </div>

          <div id="pagination" class="esios-pagination">
            <div class="esios-pagination__results">
              <span class="esios-pagination__txt esios-pagination__arrow">
                ${i18n.t('showing')}
                <select
                  class="esios-pagination__select"
                  value="${pagination.pagination}"
                  @change="${onTotalChange}"
                >
                  ${paginationValues.map(
                    (item) => html`<option value="${item}">${item}</option>`
                  )}
                </select>
              </span>
              <span class="esios-pagination__txt"
                >${i18n.t('no_text_results')}</span
              >
            </div>
            ${pagination.totalPages > 0
              ? html`<div
                  id="pagination-glosario"
                  class="esios-pagination__paginator"
                ></div>`
              : ''}
          </div>
        </div>
      </div>
      <div id="button-glosario"></div>
    `,
    {
      image: backImage,
      showFooterImage: true,
    }
  );
};

export { templateGlosario };
