'use strict';
import { html } from 'lit-html';
import { templateBoxDocument } from '../../shared/components/box-documents/box-documents.template';
import i18n from 'i18next';
import { store } from '../../app.init.js';
import { componentLayout } from '../../components/layout/layout.component';
import backImage from '../../assets/images/header-snp.jpg';
import balearesImage from '../../assets/images/snp-baleares.jpeg';
import canaryImage from '../../assets/images/snp-canarias.jpeg';
import ceutaImage from '../../assets/images/snp-ceuta.jpeg';
import melillaImage from '../../assets/images/snp-melilla.jpeg';
import { Utils } from '../../utils/utils';

let templatenoPeninsulares = (
  snpData,
  documents,
  snpMoreData,
  titles,
  buttons,
  balearNumbers,
  canaryNumbers,
  melillaNumbers,
  ceutaNumbers,
  toDraw = 'all'
) => {
  window.onload = follow;
  const state = store.getState();
  const lang = state.exampleReducer.currentLang;
  const data = snpData ? snpData.body.split('\r\n') : snpData;
  const display = (id) => {
    let popup = document.getElementById(id);
    if (popup.className === 'widget-area-tooltip') {
      popup.classList.remove('widget-area-tooltip');
      popup.classList.add('is-hidden');
    } else {
      popup.classList.remove('is-hidden');
      popup.classList.add('widget-area-tooltip');
      popup.style = 'left:55%; top:75%;';
    }
  };
  const follow = (id, ev = {}) => {
    let map = document.getElementById(id).style;
    map.display = 'block';
    map.top = ev.layerY + 175 + 'px';
    map.left = ev.layerX + 5 + 'px';
  };

  let titlePage = html`
    <h1 class="esios-noPeninsulares__mainTitle">
      ${i18n.t('costs_generation_demand').toUpperCase()}
    </h1>
  `;

  let baleares = html`
    <div class="esios-noPeninsulares__wrapper row">
      <div class="esios-noPeninsulares__mainCol balear col-xs-6">
        <figure class="esios-noPeninsulares__image" id="baleares">
          <img
            alt=${i18n.t('non_peninsular_systems_balearic')}
            src="${balearesImage}"
            usemap="#snp-baleares"
          />
        </figure>
        <map name="snp-baleares">
          <area
            @mouseenter=${() => {
              display('map-popup');
            }}
            @mouseout=${() => {
              display('map-popup');
            }}
            @mousemove="${(ev) => follow('map-popup', ev)}}"
            shape="polygon"
            data-generacion="14610.658"
            data-demanda="14610.658"
            coords="218,138,310,90,359,147,290,210"
            data-name="${i18n.t('baleares').toUpperCase()}"
          />
          <area
            @mouseenter=${() => {
              display('map-popup');
            }}
            @mouseout=${() => {
              display('map-popup');
            }}
            @mousemove="${(ev) => follow('map-popup', ev)}}"
            shape="polygon"
            data-generacion="14610.658"
            data-demanda="14610.658"
            coords="416,126,354,100,377,74,430,97"
            data-name="${i18n.t('baleares').toUpperCase()}"
          />
          <area
            @mouseenter=${() => {
              display('map-popup');
            }}
            @mouseout=${() => {
              display('map-popup');
            }}
            @mousemove="${(ev) => follow('map-popup', ev)}}"
            shape="polygon"
            data-generacion="14610.658"
            data-demanda="14610.658"
            coords="159,189,128,221,155,266,184,262,173,192"
            data-name="${i18n.t('baleares').toUpperCase()}"
          />
        </map>
        <div id="map-popup" class="is-hidden">
          <div class="tooltip-container">
            <div class="tooltip-header" style="border-color: #dbdbdb;">
              <div>${i18n.t('baleares')}</div>
            </div>
            <div class="tooltip-data">
              <div class="tooltip-row">
                <div class="label">${i18n.t('generation')}</div>
                <div class="is-small-number">
                  ${balearNumbers?.length > 0 &&
                  Utils.toNumber(balearNumbers[2].value, 3, 'comma') !== 0
                    ? Utils.toNumber(balearNumbers[2].value, 3, 'comma')
                    : '-'}<span class="unit">MWh</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="esios-noPeninsulares__mainCol col-xs-6">
        <div
          class="esios-noPeninsulares__title esios-noPeninsulares__title--mb"
        >
          <h1>${i18n.t('baleares').toUpperCase()}</h1>
        </div>
        <div class="row">
          <div class="esios-toolbar col-6">
            <div class="esios-toolbar12"></div>
          </div>
          <div class="col-6 esios-noPeninsulares__date-container">
            <div class="mod-date mod-date-days is-day-selectable">
              <span class="esios-noPeninsulares__date baleares"></span>
            </div>
          </div>
        </div>
        ${balearNumbers !== undefined
          ? html`
        <div class="row">
          <div class="col-xs-6">
            <div class="esios-noPeninsulares__label">${
              data ? data[0] : ''
            }</div>
            <div class="esios-noPeninsulares__value">
              <div class="esios-noPeninsulares__small">
                ${
                  balearNumbers?.length > 0 &&
                  Utils.toNumber(balearNumbers[0].value, 2, 'comma') !== 0
                    ? Utils.toNumber(balearNumbers[0].value, 2, 'comma')
                    : '-'
                }
              </div>
              <span class="esios-noPeninsulares__unit">€/MWh</span>
            </div>
          </div>
          <div class="col-xs-6">
            <div class="esios-noPeninsulares__label">
              ${i18n.t('generation').toUpperCase()}
            </div>
            <div class="esios-noPeninsulares__value">
              <div class="esios-noPeninsulares__small">
                ${
                  balearNumbers?.length > 0 &&
                  Utils.toNumber(balearNumbers[2].value, 3, 'comma') !== 0
                    ? Utils.toNumber(balearNumbers[2].value, 3, 'comma')
                    : '-'
                }
              </div>
              <span class="esios-noPeninsulares__unit">MWh</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6">
            <div class="esios-noPeninsulares__label">${
              data ? data[1] : ''
            }</div>
            <div class="esios-noPeninsulares__value">
              <div class="esios-noPeninsulares__small">
                ${
                  balearNumbers?.length > 0 &&
                  Utils.toNumber(balearNumbers[1].value, 2, 'comma') !== 0
                    ? Utils.toNumber(balearNumbers[1].value, 2, 'comma')
                    : '-'
                }
              </div>
              <span class="esios-noPeninsulares__unit">€/MWh</span>
            </div>
          </div>
          <div class="col-xs-6">
            <div class="esios-noPeninsulares__label">
              ${i18n.t('demand').toUpperCase()}
            </div>
            <div class="esios-noPeninsulares__value">
              <div class="esios-noPeninsulares__small">
                ${
                  balearNumbers?.length > 0 &&
                  Utils.toNumber(balearNumbers[3].value, 3, 'comma') !== 0
                    ? Utils.toNumber(balearNumbers[3].value, 3, 'comma')
                    : '-'
                }
              </div>
              <span class="esios-noPeninsulares__unit">MWh</span>
            </div>
          </div>
          <hr class='esios-noPeninsulares__show-mobile' style='display: none;'>
        </div>
        <hr class="esios-noPeninsulares__hr-separator" />
        <div class="row">
          <h3 class="esios-noPeninsulares__label">
            ${i18n.t('generation_price_sea_mallorca').toUpperCase()}
          </h3>
          <div class="col-xs-6">
            <div class="esios-noPeninsulares__little-label">
              ${titles?.length > 0 ? titles[9].alias : '-'}
            </div>
            <div class="esios-noPeninsulares__value">
              <div class="esios-noPeninsulares__small">
                ${
                  balearNumbers?.length > 0 &&
                  Utils.toNumber(balearNumbers[9].value, 2, 'comma') !== 0
                    ? Utils.toNumber(balearNumbers[9].value, 2, 'comma')
                    : '-'
                }
              </div>
              <span class="esios-noPeninsulares__unit">€/MWh</span>
            </div>
          </div>
          <div class="col-xs-6">
            <div class="esios-noPeninsulares__little-label">
              ${titles?.length > 0 ? titles[8].alias : '-'}
            </div>
            <div class="esios-noPeninsulares__value">
              <div class="esios-noPeninsulares__small">
                ${
                  balearNumbers?.length > 0 &&
                  Utils.toNumber(balearNumbers[8].value, 2, 'comma') !== 0
                    ? Utils.toNumber(balearNumbers[8].value, 2, 'comma')
                    : '-'
                }
              </div>
              <span class="esios-noPeninsulares__unit">€/MWh</span>
            </div>
          </div>
        </div>
        <div class="row esios-noPeninsulares__row-flex">
          <h3 class="esios-noPeninsulares__label">
            ${i18n.t('electric_link_programs').toUpperCase()}
          </h3>
          <div class="col-xs-6 esios-noPeninsulares__peninsula-baleares">
            <div class="esios-noPeninsulares__little-label">
              ${titles?.length > 0 ? titles[10].alias : '-'}
            </div>
            <div class="esios-noPeninsulares__value">
              <div class="esios-noPeninsulares__small">
                ${
                  balearNumbers?.length > 0 &&
                  Utils.toNumber(balearNumbers[10].value, 2, 'comma') !== 0 &&
                  Math.round(balearNumbers[10].value) !== 0
                    ? Utils.toNumber(balearNumbers[10].value, 2, 'comma')
                    : '-'
                }
              </div>
              <span class="esios-noPeninsulares__unit">MWh</span>
            </div>
          </div>
          <div class="col-xs-6 esios-noPeninsulares__peninsula-baleares">
            <div class="esios-noPeninsulares__little-label">
              ${titles?.length > 0 ? titles[11].alias : '-'}
            </div>
            <div class="esios-noPeninsulares__value">
              <div class="esios-noPeninsulares__small">
                ${
                  balearNumbers?.length > 0 &&
                  Utils.toNumber(balearNumbers[11].value, 1, 'comma') !== 0
                    ? Utils.toNumber(balearNumbers[11].value, 1, 'comma')
                    : '-'
                }
              </div>
              <span class="esios-noPeninsulares__unit">MWh</span>
            </div>
          </div>
        </div>
        <div class="row">
          <h3 class="esios-noPeninsulares__label">
            ${i18n.t('planned_capacity').toUpperCase()}
          </h3>
          <div class="col-xs-6">
            <div class="esios-noPeninsulares__little-label">
              ${titles?.length > 0 ? titles[12].alias : '-'}
            </div>
            <div class="esios-noPeninsulares__value">
              <div class="esios-noPeninsulares__small">
                ${
                  balearNumbers?.length > 0 &&
                  Utils.toNumber(balearNumbers[12].value, 1, 'comma') !== 0
                    ? Utils.toNumber(balearNumbers[12].value, 1, 'comma')
                    : '-'
                }
              </div>
              <span class="esios-noPeninsulares__unit">MW</span>
            </div>
          </div>
        </div>
      </div>`
          : html` <div id="spinner12" class="spinner-container">
              <div class="spinner"></div>
            </div>`}
      </div>
    </div>
  `;

  let canarias = html`
    <div class="esios-noPeninsulares__wrapper-canarias row">
      <div class="esios-noPeninsulares__mainCol col-xs-6">
        <figure class="esios-noPeninsulares__image" id="canarias">
          <img
            alt=${i18n.t('non_peninsular_systems_canary')}
            src="${canaryImage}"
            usemap="#snp-canarias"
          />
          <map name="snp-canarias">
            <area
              @mouseenter=${() => {
                display('map4');
              }}
              @mouseout=${() => {
                display('map4');
              }}
              @mousemove="${(ev) => follow('map4', ev)}}"
              shape="polygon"
              coords="47,72,64,127,89,116,81,67"
              data-generacion="625.823"
              data-demanda="625.823"
              data-name="La Palma"
            />
            <area
              @mouseenter=${() => {
                display('map3');
              }}
              @mouseout=${() => {
                display('map3');
              }}
              @mousemove="${(ev) => follow('map3', ev)}}"
              shape="polygon"
              coords="144,113,169,170,203,157,216,124,234,100,217,96"
              data-generacion="9738.324"
              data-demanda="9738.324"
              data-name="Tenerife"
            />
            <area
              @mouseenter=${() => {
                display('map1');
              }}
              @mouseout=${() => {
                display('map1');
              }}
              @mousemove="${(ev) => follow('map1', ev)}}"
              shape="polygon"
              coords="261,138,246,181,280,199,297,181,297,139"
              data-generacion="9328.904"
              data-demanda="9328.904"
              data-name="Gran Canaria"
            />
            <area
              @mouseenter=${() => {
                display('map5');
              }}
              @mouseout=${() => {
                display('map5');
              }}
              @mousemove="${(ev) => follow('map5', ev)}}"
              shape="polygon"
              coords="115,136,107,152,131,168,141,144"
              data-generacion="189.173"
              data-demanda="189.173"
              data-name="La Gomera"
            />
            <area
              @mouseenter=${() => {
                display('map6');
              }}
              @mouseout=${() => {
                display('map6');
              }}
              @mousemove="${(ev) => follow('map6', ev)}}"
              shape="polygon"
              coords="59,171,28,188,66,212,74,182"
              data-generacion="111.648"
              data-demanda="111.648"
              data-name="El Hierro"
            />
            <area
              @mouseenter=${() => {
                display('map2');
              }}
              @mouseout=${() => {
                display('map2');
              }}
              @mousemove="${(ev) => follow('map2', ev)}}"
              shape="polygon"
              coords="417,81,384,137,353,153,392,172,433,138,442,87"
              data-generacion="3958.586"
              data-demanda="3958.586"
              data-name="Lanzarote y Fuerteventura"
            />
            <area
              @mouseenter=${() => {
                display('map2');
              }}
              @mouseout=${() => {
                display('map2');
              }}
              @mousemove="${(ev) => follow('map2', ev)}}"
              shape="polygon"
              coords="418,70,454,7,476,44,456,77,429,79,420,79"
              data-generacion="3958.586"
              data-demanda="3958.586"
              data-name="Lanzarote y Fuerteventura"
            />
          </map>
          <div id="map1" class="is-hidden">
            <div class="tooltip-container">
              <div class="tooltip-header" style="border-color: #dbdbdb;">
                <div>${canaryNumbers ? canaryNumbers[4].geo_name : ``}</div>
              </div>
              <div class="tooltip-data">
                <div class="tooltip-row">
                  <div class="label">${i18n.t('generation')}</div>
                  <div class="is-small-number">
                    ${canaryNumbers
                      ? Utils.toNumber(canaryNumbers[4].value, 3, 'comma')
                      : `-`}<span class="unit">MWh</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="map2" class="is-hidden">
            <div class="tooltip-container">
              <div class="tooltip-header" style="border-color: #dbdbdb;">
                <div>${canaryNumbers ? canaryNumbers[5].geo_name : ``}</div>
              </div>
              <div class="tooltip-data">
                <div class="tooltip-row">
                  <div class="label">${i18n.t('generation')}</div>
                  <div class="is-small-number">
                    ${canaryNumbers
                      ? Utils.toNumber(canaryNumbers[5].value, 3, 'comma')
                      : ``}<span class="unit">MWh</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="map3" class="is-hidden">
            <div class="tooltip-container">
              <div class="tooltip-header" style="border-color: #dbdbdb;">
                <div>${canaryNumbers ? canaryNumbers[6].geo_name : ``}</div>
              </div>
              <div class="tooltip-data">
                <div class="tooltip-row">
                  <div class="label">${i18n.t('generation')}</div>
                  <div class="is-small-number">
                    ${canaryNumbers
                      ? Utils.toNumber(canaryNumbers[6].value, 3, 'comma')
                      : ``}<span class="unit">MWh</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="map4" class="is-hidden">
            <div class="tooltip-container">
              <div class="tooltip-header" style="border-color: #dbdbdb;">
                <div>${canaryNumbers ? canaryNumbers[7].geo_name : ``}</div>
              </div>
              <div class="tooltip-data">
                <div class="tooltip-row">
                  <div class="label">${i18n.t('generation')}</div>
                  <div class="is-small-number">
                    ${canaryNumbers
                      ? Utils.toNumber(canaryNumbers[7].value, 3, 'comma')
                      : ``}<span class="unit">MWh</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="map5" class="is-hidden">
            <div class="tooltip-container">
              <div class="tooltip-header" style="border-color: #dbdbdb;">
                <div>${canaryNumbers ? canaryNumbers[8].geo_name : ``}</div>
              </div>
              <div class="tooltip-data">
                <div class="tooltip-row">
                  <div class="label">${i18n.t('generation')}</div>
                  <div class="is-small-number">
                    ${canaryNumbers
                      ? Utils.toNumber(canaryNumbers[8].value, 3, 'comma')
                      : ``}<span class="unit">MWh</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="map6" class="is-hidden">
            <div class="tooltip-container">
              <div class="tooltip-header" style="border-color: #dbdbdb;">
                <div>${canaryNumbers ? canaryNumbers[9].geo_name : ``}</div>
              </div>
              <div class="tooltip-data">
                <div class="tooltip-row">
                  <div class="label">${i18n.t('generation')}</div>
                  <div class="is-small-number">
                    ${canaryNumbers
                      ? Utils.toNumber(canaryNumbers[9].value, 3, 'comma')
                      : ``}<span class="unit">MWh</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </figure>
      </div>
      <div class="esios-noPeninsulares__mainCol col-xs-6">
        <div
          class="esios-noPeninsulares__title esios-noPeninsulares__title--mb"
        >
          <h1>${i18n.t('canarias').toUpperCase()}</h1>
        </div>
        <div class="row">
          <div class="esios-toolbar col-6">
            <div class="esios-toolbar13"></div>
          </div>
          <div class="col-6 esios-noPeninsulares__date-container">
            <div class="mod-date mod-date-days is-day-selectable">
              <span class="esios-noPeninsulares__date canarias"></span>
            </div>
          </div>
        </div>
        ${canaryNumbers !== undefined
          ? html` <div class="row">
                <div class="col-xs-6">
                  <div class="esios-noPeninsulares__label">
                    ${data ? data[0] : ''}
                  </div>
                  <div class="esios-noPeninsulares__value">
                    <div class="esios-noPeninsulares__small">
                      ${canaryNumbers?.length > 0 &&
                      Utils.toNumber(canaryNumbers[0].value, 2, 'comma') !== 0
                        ? Utils.toNumber(canaryNumbers[0].value, 2, 'comma')
                        : '-'}
                    </div>
                    <span class="esios-noPeninsulares__unit">€/MWh</span>
                  </div>
                </div>
                <div class="col-xs-6">
                  <div class="esios-noPeninsulares__label">
                    ${i18n.t('generation').toUpperCase()}
                  </div>
                  <div class="esios-noPeninsulares__value">
                    <div class="esios-noPeninsulares__small">
                      ${canaryNumbers?.length > 0 &&
                      Utils.toNumber(canaryNumbers[2].value, 3, 'comma') !== 0
                        ? Utils.toNumber(canaryNumbers[2].value, 3, 'comma')
                        : '-'}
                    </div>
                    <span class="esios-noPeninsulares__unit">MWh</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-6">
                  <div class="esios-noPeninsulares__label">
                    ${data ? data[1] : ''}
                  </div>
                  <div class="esios-noPeninsulares__value">
                    <div class="esios-noPeninsulares__small">
                      ${canaryNumbers?.length > 0 &&
                      Utils.toNumber(canaryNumbers[1].value, 2, 'comma') !== 0
                        ? Utils.toNumber(canaryNumbers[1].value, 2, 'comma')
                        : '-'}
                    </div>
                    <span class="esios-noPeninsulares__unit">€/MWh</span>
                  </div>
                </div>
                <div class="col-xs-6">
                  <div class="esios-noPeninsulares__label">
                    ${i18n.t('demand').toUpperCase()}
                  </div>
                  <div class="esios-noPeninsulares__value">
                    <div class="esios-noPeninsulares__small">
                      ${canaryNumbers?.length > 0 &&
                      Utils.toNumber(canaryNumbers[3].value, 3, 'comma') !== 0
                        ? Utils.toNumber(canaryNumbers[3].value, 3, 'comma')
                        : '-'}
                    </div>
                    <span class="esios-noPeninsulares__unit">MWh</span>
                  </div>
                </div>
              </div>`
          : html` <div id="spinner13" class="spinner-container">
              <div class="spinner"></div>
            </div>`}
      </div>
    </div>
  `;
  let colWidth = toDraw == 'all' ? 6 : 12;

  let ceuta = html`
    <div class="ceuta row">
      <div class="esios-noPeninsulares__title esios-noPeninsulares__title--mb">
        <h1 id="ceuta">${i18n.t('ceuta').toUpperCase()}</h1>
      </div>
      <div class="esios-toolbar">
        <div class="esios-toolbar col-6">
          <div class="esios-toolbar14"></div>
        </div>
        <div class="col-6 esios-noPeninsulares__date-container">
          <div class="mod-date mod-date-days is-day-selectable">
            <span class="esios-noPeninsulares__date ceuta"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="ceuta row">
      ${ceutaNumbers !== undefined
        ? html`<figure class="esios-noPeninsulares__image-margin">
              <img
                alt=${i18n.t('non_peninsular_systems_ceuta')}
                src="${ceutaImage}"
              />
            </figure>
            <div class="row">
              <div class="col-xs-6">
                <div class="esios-noPeninsulares__label">
                  ${data ? data[0] : ''}
                </div>
                <div class="esios-noPeninsulares__value">
                  <div class="esios-noPeninsulares__small">
                    ${ceutaNumbers?.length > 0 &&
                    Utils.toNumber(ceutaNumbers[0].value, 2, 'comma') !== 0
                      ? Utils.toNumber(ceutaNumbers[0].value, 2, 'comma')
                      : '-'}
                  </div>
                  <span class="esios-noPeninsulares__unit">€/MWh</span>
                </div>
              </div>
              <div class="col-xs-6">
                <div class="esios-noPeninsulares__label">
                  ${i18n.t('generation').toUpperCase()}
                </div>
                <div class="esios-noPeninsulares__value">
                  <div class="esios-noPeninsulares__small">
                    ${ceutaNumbers?.length > 0 &&
                    Utils.toNumber(ceutaNumbers[2].value, 3, 'comma') !== 0
                      ? Utils.toNumber(ceutaNumbers[2].value, 3, 'comma')
                      : '-'}
                  </div>
                  <span class="esios-noPeninsulares__unit">MWh</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-6">
                <div class="esios-noPeninsulares__label">
                  ${data ? data[1] : ''}
                </div>
                <div class="esios-noPeninsulares__value">
                  <div class="esios-noPeninsulares__small">
                    ${ceutaNumbers?.length > 0 &&
                    Utils.toNumber(ceutaNumbers[1].value, 2, 'comma') !== 0
                      ? Utils.toNumber(ceutaNumbers[1].value, 2, 'comma')
                      : '-'}
                  </div>
                  <span class="esios-noPeninsulares__unit">€/MWh</span>
                </div>
              </div>
              <div class="col-xs-6">
                <div class="esios-noPeninsulares__label">
                  ${i18n.t('demand').toUpperCase()}
                </div>
                <div class="esios-noPeninsulares__value">
                  <div class="esios-noPeninsulares__small">
                    ${ceutaNumbers?.length > 0 &&
                    Utils.toNumber(ceutaNumbers[3].value, 3, 'comma') !== 0
                      ? Utils.toNumber(ceutaNumbers[3].value, 3, 'comma')
                      : '-'}
                  </div>
                  <span class="esios-noPeninsulares__unit">MWh</span>
                </div>
              </div>
            </div>`
        : html` <figure class="esios-noPeninsulares__image-margin">
              <img
                alt=${i18n.t('non_peninsular_systems_ceuta')}
                src="${ceutaImage}"
              />
            </figure>
            <div id="spinner14" class="spinner-container">
              <div class="spinner"></div>
            </div>`}
    </div>
  `;
  let melilla = html`
    <div class="row">
      <div class="esios-noPeninsulares__title esios-noPeninsulares__title--mb">
        <h1 id="melilla">${i18n.t('melilla').toUpperCase()}</h1>
      </div>
      <div class="esios-toolbar">
        <div class="esios-toolbar col-6">
          <div class="esios-toolbar15"></div>
        </div>
        <div class="col-6 esios-noPeninsulares__date-container">
          <div class="mod-date mod-date-days is-day-selectable">
            <span class="esios-noPeninsulares__date melilla"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      ${melillaNumbers !== undefined
        ? html`<figure class="esios-noPeninsulares__image-margin">
              <img
                alt=${i18n.t('non_peninsular_systems_melilla')}
                src="${melillaImage}"
              />
            </figure>
            <div class="row">
              <div class="col-xs-6">
                <div class="esios-noPeninsulares__label">
                  ${data ? data[0] : ''}
                </div>
                <div class="esios-noPeninsulares__value">
                  <div class="esios-noPeninsulares__small">
                    ${melillaNumbers?.length > 0 &&
                    Utils.toNumber(melillaNumbers[0].value, 2, 'comma') !== 0
                      ? Utils.toNumber(melillaNumbers[0].value, 2, 'comma')
                      : '-'}
                  </div>
                  <span class="esios-noPeninsulares__unit">€/MWh</span>
                </div>
              </div>
              <div class="col-xs-6">
                <div class="esios-noPeninsulares__label">
                  ${i18n.t('generation').toUpperCase()}
                </div>
                <div class="esios-noPeninsulares__value">
                  <div class="esios-noPeninsulares__small">
                    ${melillaNumbers?.length > 0 &&
                    Utils.toNumber(melillaNumbers[2].value, 3, 'comma') !== 0
                      ? Utils.toNumber(melillaNumbers[2].value, 3, 'comma')
                      : '-'}
                  </div>
                  <span class="esios-noPeninsulares__unit">MWh</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-6">
                <div class="esios-noPeninsulares__label">
                  ${data ? data[1] : ''}
                </div>
                <div class="esios-noPeninsulares__value">
                  <div class="esios-noPeninsulares__small">
                    ${melillaNumbers?.length > 0 &&
                    Utils.toNumber(melillaNumbers[1].value, 2, 'comma') !== 0
                      ? Utils.toNumber(melillaNumbers[1].value, 2, 'comma')
                      : '-'}
                  </div>
                  <span class="esios-noPeninsulares__unit">€/MWh</span>
                </div>
              </div>
              <div class="col-xs-6">
                <div class="esios-noPeninsulares__label">
                  ${i18n.t('demand').toUpperCase()}
                </div>
                <div class="esios-noPeninsulares__value">
                  <div class="esios-noPeninsulares__small">
                    ${melillaNumbers?.length > 0 &&
                    Utils.toNumber(melillaNumbers[3].value, 3, 'comma') !== 0
                      ? Utils.toNumber(melillaNumbers[3].value, 3, 'comma')
                      : '-'}
                  </div>
                  <span class="esios-noPeninsulares__unit">MWh</span>
                </div>
              </div>
            </div>`
        : html` <figure class="esios-noPeninsulares__image-margin">
              <img
                alt=${i18n.t('non_peninsular_systems_melilla')}
                src="${melillaImage}"
              />
            </figure>
            <div id="spinner15" class="spinner-container">
              <div class="spinner"></div>
            </div>`}
    </div>
  `;

  let vertidostnp =
    snpMoreData != null ? snpMoreData.filter((item) => item.id === 2068) : null;
  // Eliminar el objeto con id 202 & 203 de la lista
  snpMoreData =
    snpMoreData != null ? snpMoreData.filter((item) => item.id !== 2068) : null;
  let htmlParam1 = html`
    ${toDraw == 'all' ? titlePage : ''}
    ${toDraw == '12' || toDraw == 'all' ? baleares : ''}
    ${toDraw == 'all'
      ? html`<hr class="esios-noPeninsulares__hr-separator--hidden" />`
      : ''}
    ${toDraw == '13' || toDraw == 'all' ? canarias : ''}
    ${toDraw == 'all'
      ? html`<hr class="esios-noPeninsulares__hr-separator--bottom" />`
      : ''}
    <div class="esios-noPeninsulares__wrapper row">
      <div class="esios-noPeninsulares__mainCol ceuta__col  col-xs-${colWidth}">
        ${toDraw == '14' || toDraw == 'all' ? ceuta : ''}
      </div>
      ${toDraw == 'all'
        ? html`<hr class="esios-noPeninsulares__hr-melilla" />`
        : ''}
      <div class="esios-noPeninsulares__mainCol-melilla col-xs-${colWidth}">
        ${toDraw == '15' || toDraw == 'all' ? melilla : ''}
      </div>
    </div>
    ${toDraw == 'all'
      ? html`<hr class="esios-noPeninsulares__hr-separator--bottom" />`
      : ''}
    <div class="esios-noPeninsulares__wrapper row">
      <h1 class="esios-noPeninsulares__mainTitle">
        ${i18n.t('title-pours').toUpperCase()}
      </h1>
      ${vertidostnp?.map(
        (item) => html`
          <div class="esios-document__container-buttonsnp">
            <a
              href="${item.url}"
              download
              class="esios-btn esios-btn--document esios-btn__icon esios-btn__icon--download"
              >${i18n.t('text-pours')}</a
            >
          </div>
        `
      )}
    </div>
  `;
  let htmlParam2 = { image: backImage };
  let htmlParam3 = html``;
  let htmlParam4 = {
    title: `${i18n.t('more')}`,
    subcontent: snpMoreData
      ? html` <ul class="esios-also__list row" id="more">
            ${snpMoreData?.map(
              (item) => html`
                <li class="esios-also__item col-xs-6 col-12">
                  <span class="esios-also__icon-arrow"></span>
                  <a href="${item.url}">${item.alias}</a>
                </li>
              `
            )}
            <li class="esios-also__item col-xs-6 col-12">
              <span class="esios-also__icon-link"></span>
              <a
                href="/${lang}/${i18n.t('routes.page')}/${i18n.t(
                  'routes.interruptibility_snp'
                )}"
                >${i18n.t('interruptibility_snp')}</a
              >
            </li>
          </ul>

          <div id="document-list-myp"></div>

          ${templateBoxDocument(documents, buttons, false)}`
      : html`<div id="spinner1" class="spinner-container">
          <div class="spinner"></div>
        </div>`,
  };
  return toDraw == 'all'
    ? componentLayout.render(htmlParam1, htmlParam2, htmlParam3, htmlParam4)
    : htmlParam1;
};

export { templatenoPeninsulares };
