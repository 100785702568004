import i18n from 'i18next';
import { html } from 'lit-html';
import { templateToolbarTime } from '../../../../shared/components/toolbarTime/toolbarTime.template';

const MyEsiosWidget22 = () => {
  return html`
    <div class="esios-gd__col1 col-xs-9">
      <div class="esios-gd__widget widget-gd-demanda" id="gdDemandaWidgetView">
        <div class="esios-gd__row">
          <div class="grid-xs-24 grid-sm-24">
            <h1 class="esios-gd__main-title"></h1>
            <div class="esios-gd__widget-toolbar">
              <div class="esios-toolbar">
                <!-- toolbar -->
                <div class="esios-toolbar22"></div>
                ${templateToolbarTime('timer1')}
              </div>
            </div>
          </div>
        </div>
        <div class="esios-gd__row esios-gd__row--left" id="gd-first">
          <div class="esios-gd__row-left_1 col-xs-3">
            <div class="esios-gd__widget-summary gd-summary" id="indicadores">
              <div
                class="esios-gd__widget-summary-item esios-gd__is-number--first"
              >
                <div class="esios-gd__value">
                  <div class="esios-gd__is-number">
                    <div class="value"></div>
                    <small class="esios-gd__unit esios-gd__unit--first"
                      >MW</small
                    >
                  </div>
                </div>
                <div class="esios-gd__label"></div>
              </div>
              <div
                class="esios-gd__widget-summary-item esios-gd__is-number--second"
              >
                <div class="esios-gd__value">
                  <div class="esios-gd__is-number">
                    <div class="value"></div>
                    <span class="esios-gd__unit">tCO2</span>
                  </div>
                </div>
                <div class="esios-gd__label"></div>
              </div>
            </div>
          </div>

          <div id="dmGraphic" class="col-xs-9 row">
            <div
              id="dmNoDataRect"
              class="esios-home__no-data-container_cuadrado hidden"
              style="margin: 225px 130px; z-index: 5;"
            >
              ${i18n.t('no-data-available')}
            </div>
            <div id="spinner22" class="spinner-container">
              <div class="spinner"></div>
            </div>
            <div id="widget22">
              <div id="chari-chart" class="chari-chart demanda"></div>
            </div>
            <div id="widget22Data" class="grid-xs-24">
              <div
                class="esios-home__widget-legend highlighted-legend widget-legend visual-legend in-line"
              >
                <ul></ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `;
};

export default MyEsiosWidget22;
