'use strict';
import { html } from 'lit-html';
import backImage from '../../assets/images/header-default.jpg';
import { componentLayout } from '../../components/layout/layout.component';

let templatePoliticaPrivacidad = (contactData) => {
  const { title, body } = contactData;

  return componentLayout.render(
    html`
      <div class="esios-pp">
        <h1 class="esios-pp__title esios-pp__title--primary-title">${title}</h1>
        ${html([body])}
      </div>
    `,
    {
      image: backImage,
      showBanner: true,
      showFooterImage: true,
    }
  );
};

export { templatePoliticaPrivacidad };
