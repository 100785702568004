import i18n from 'i18next';
import { html } from 'lit-html';

const TemplateEmbedIndicatorsBreakdown = () => {
  return html`
    <div class="esios-gd__col2 col-xs-3">
      <div id="spinner23" class="spinner-container">
        <div class="spinner"></div>
      </div>
      <aside class="esios-gd__aside_chart" id="idAside">
        <div
          class="esios-gd__widget widget-gd-desglose"
          id="gdDesgloseWidgetView"
        >
          <div
            id="breakdownGraphic"
            class="esios-gd__row graph_gd"
            style="background-size:cover"
          >
            <div style="display:flex; justify-content:center;">
              <div
                id="dmNoDataRect2"
                class="esios-home__no-data-container_cuadrado hidden"
              >
                ${i18n.t('no-data-available')}
              </div>
            </div>
            <div
              class="chari-chart gen-convencional piePotencia dibujoDemanda"
            ></div>

            <div class="widget-legend basic-legend legend-potencial-pie "></div>
          </div>
        </div>
      </aside>
    </div>
  `;
};

export default TemplateEmbedIndicatorsBreakdown;
