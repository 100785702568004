'use strict';
import { html } from 'lit-html';
import i18n from 'i18next';

let templateLayoutBluebuttons = () => {
  return html`
    <aside class="esios-layoutbuttons">
      <div class="esios-layoutbuttons__content">
        <a href="#" class="btn-download icon-download">
          ${i18n.t('export_to_json')}</a
        >
        <a href="#" class="btn-download icon-download">
          ${i18n.t('export_to_csv')}</a
        >
        <a href="#" class="btn-download icon-download">
          ${i18n.t('export_to_excel')}</a
        >
        <a href="#" class="btn-print is-xs-hidden icon-print"
          >${i18n.t('print')}</a
        >
      </div>
    </aside>
  `;
};

export { templateLayoutBluebuttons };
