import { html } from 'lit-html';
import i18n from 'i18next';

const MyEsiosWidget3 = (indicator = false) => {
  return html`
    <div class="grid-xs-12 grid-sm-16 grid-lg-20">
      <div class="widget-toolbar">
        <div class="esios-toolbar col-12">
          <div class="esios-toolbar3"></div>
          <div id="free-gen-timeSelector"></div>
        </div>
      </div>
    </div>
    <div class="grid-md-5 grid-sm-6 grid-xs-24 div-generacion">
      <div id="widget3">
        <div class="widget widget-3" id="gycCo2WidgetView">
          <div class="aside">
            <div class="grid-xs-12 grid-sm-8 grid-lg-4">
              <div id="free-gen-timeSelector"></div>
            </div>

            <div class="grid-xs-24 grid-sm-24">
              ${!indicator
                ? html`
                    <div id="spinner3" class="spinner-container">
                      <div class="spinner"></div>
                    </div>
                  `
                : ''}
              <div class="widget-custom">
                <div class="widget-summary"></div>

                <hr />

                <div class="widget-summary aside-summary"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `;
};

export default MyEsiosWidget3;
