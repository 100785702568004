import { html } from 'lit-html';
import i18n from 'i18next';

const MyEsiosWidget4 = () => {
  document.addEventListener('click', (evt) => {
    const list = document.querySelectorAll('.btn-legend-popup');
    let targetElement = evt.target; // clicked element

    do {
      if (targetElement == list[0] || targetElement == list[1]) {
        return;
      }
      // Go up the DOM.
      targetElement = targetElement.parentNode;
    } while (targetElement);

    // Do something useful here.
    let legend = document.querySelectorAll('.box-legend-popup');
    legend.forEach((item) => item.classList.add('invisible'));
  });

  const handleClick = (e) => {
    const classList = [...e.target.nextElementSibling.classList]; // se dirige con nextElementSibling al vecino mas cercano hacia abajo
    if (classList.find((item) => item === 'invisible')) {
      let legend = document.querySelectorAll('.box-legend-popup');
      legend.forEach((item) => item.classList.add('invisible'));
      e.target.nextElementSibling.classList.remove('invisible');
    } else {
      e.target.nextElementSibling.classList.add('visible');
    }
  };

  return html`
    <div id="layout-extended">
      <div class="esios-related-content">
        <div class="wrap">
          <div class="gyc-balance grid-sm-24 grid-xs-24">
            <div class="widget widget-4" id="gycBalanceWidgetView">
              <div class="row">
                <div class="grid-xs-24 grid-sm-24">
                  <div class="esios-toolbar col-24">
                    <div class="esios-toolbar4"></div>
                    <div id="electric-balance-time"></div>
                  </div>
                </div>

                <div class="grid-xs-24 grid-sm-24">
                  <div class="widget-custom">
                    <div id="spinner4" class="spinner-container">
                      <div class="spinner"></div>
                    </div>
                    <div class="mod-tabs">
                      <div id="programs" class="widget-gyc-balance">
                        <div id="widget4" class="full-width row is-stretch">
                          <div class="grid-xs-24 grid-sm-8 is-stretch">
                            <div class="mod-tab-subpanel" id="generacion">
                              <div class="title">
                                <p class="sum">${i18n.t('generation')}</p>
                              </div>
                              <div class="summary-resume"></div>

                              <div id="genConWidget2" class="content">
                                <div
                                  id="graphic2"
                                  class="widget-chart chari-chart dibujo"
                                ></div>
                                <div
                                  id="rectNoData2"
                                  class="rect-no-data hidden"
                                  style="width:65%"
                                >
                                  ${i18n.t('no-data-available')}
                                </div>
                              </div>
                              <div class="mod-legend-popup">
                                <div
                                  class="btn-legend-popup"
                                  @click=${handleClick}
                                >
                                  ${i18n.t('legend')}
                                </div>

                                <div class="box-legend-popup left invisible ">
                                  <div
                                    class="eb-left widget-legend basic-legend"
                                  ></div>
                                </div>
                              </div>
                              <div class="summary-resume last"></div>
                            </div>
                            <p class="sum-mob"></p>
                          </div>

                          <div class="grid-xs-24 grid-sm-8 is-stretch">
                            <div class="mod-tab-subpanel" id="interconexiones">
                              <div class="title">
                                <p class="equal">
                                  ${i18n.t('interconections')}
                                </p>
                              </div>
                              <div class="summary-resume"></div>
                              <div class="content content-middle">
                                <div class=" basic-summary"></div>
                              </div>
                              <div class="summary-resume last"></div>
                            </div>
                            <p class="equal-mob"></p>
                          </div>

                          <div class="grid-xs-24 grid-sm-8 is-stretch">
                            <div
                              class="mod-tab-subpanel last-item"
                              id="consumo"
                            >
                              <div class="title">
                                <p>${i18n.t('consumption')}</p>
                              </div>
                              <div class="summary-resume"></div>

                              <div
                                id="genConWidget3"
                                class="content content-consumo"
                              >
                                <div
                                  id="graphic3"
                                  class="widget-chart chari-chart dibujoR"
                                ></div>
                                <div
                                  id="rectNoData3"
                                  class="rect-no-data hidden"
                                  style="width:65%"
                                >
                                  ${i18n.t('no-data-available')}
                                </div>
                              </div>
                              <div class="mod-legend-popup">
                                <div
                                  class="btn-legend-popup right"
                                  @click=${handleClick}
                                >
                                  ${i18n.t('legend')}
                                </div>
                                <div class="box-legend-popup right  invisible">
                                  <div
                                    class="eb-rigth widget-legend basic-legend"
                                  ></div>
                                </div>
                              </div>
                              <div class="summary-resume last"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="grid-xs-24">
                      <div class="mod-action-buttons">
                        <a
                          href="/${i18n.t('routes.lng')}/balance"
                          class="btn-info"
                        >
                          ${i18n.t('more_info')}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `;
};

export default MyEsiosWidget4;
