'use strict';
import i18n from 'i18next';
import { render } from 'lit-html';
import moment from 'moment-timezone';
import { store } from '../../app.init.js';
import { getColour } from '../../shared/components/chart/chart-helpers.js';
import { componentDocumentList } from '../../shared/components/document-list/document-list.component.js';
import { templateShareModal } from '../../shared/components/modal/modalShare.template.js';
import { componentTabs } from '../../shared/components/tabs/tabs.component.js';
import { componentTimeSelector } from '../../shared/components/time-selector/time-selector.component.js';
import { componentToolbar } from '../../shared/components/toolbar/toolbar.component.js';
import { dateSetTime } from '../../utils/dateFormat.js';
import { charts } from '../../utils/options-charts.js';
import { getUrlParams } from '../../utils/url.js';
import { Utils } from '../../utils/utils.js';
import {
  calculateHoursPerDay,
  renderBalanceGraph,
} from '../balance/balance.component.js';
import { gestionDemandaService } from '../gestion-demanda/gestionDemanda.service.js';
import { servicesIntercambiosInternacionales } from './intercambiosInternacionales.services.js';
import { templateIntercambiosInternacionales } from './intercambiosInternacionales.template.js';
import { intercambiosInternacionalesContent } from './intercambiosInternacionalesContent.js';
import MapaIntercambiosInternacionales from './mapa.intercambios-internacionales.js';
import { api } from '../../utils/api-interceptor.js';

let componentIntercambiosInternacionales = (function () {
  console.log = () => {};
  console.warn = () => {};
  let dateInherited = dateSetTime();
  dateInherited = dateInherited.split('T');
  let currentDate = dateInherited[0];
  // let currentDate = moment().tz('Europe/Madrid').format('DD-MM-YYYY');
  let formatDate = currentDate.split('-');
  let fecha = {
    day: formatDate[2],
    month: formatDate[1],
    year: formatDate[0],
  };
  let handlers = {
    handleSelect: (e) => handleSelect(e),
  };
  let date;
  let objToolbar, auctionsData, auctionsPortugalData;

  const selectTimeForMercadeSpot = {
    id: 'select_mercade_spot',
    datetimeFor: 'volumeTime',
    currentHour: '00',
    currentMinutes: '00',
    optionsHours: Utils.getArrayOfHours(),
    optionsMinutes: [],
    changeTime: (hours, minutes) => changeTime(hours, minutes),
  };

  const changeTime = (value) => {
    const { id, newValue } = value;
    let hours = newValue.split(':')[0];
    let minutes = newValue.split(':')[1];
    if (id === selectTimeForMercadeSpot.id) {
      showOrHidden('spinner2', 'flex');
      selectTimeForMercadeSpot.currentHour = hours;
      selectTimeForMercadeSpot.currentMinutes = minutes;
      componentTimeSelector.render(
        selectTimeForMercadeSpot,
        '#intMercadeSpotSelector'
      );
      renderWidget40Map({ hours, minutes });
    }
  };

  const currentRoute = window.location.pathname;
  let lang = currentRoute.split('/')[1];

  let filterForm = {
    ambit: '',
    category: '',
    process: '',
    action: '',
    query: '',
  };

  let handleSelect = (e) => {
    document.querySelectorAll('#widget47 .multi-bar').forEach((el) => {
      el.classList.add('hidden');
    });
    document.querySelectorAll('#widget47 .legends').forEach((el) => {
      el.classList.add('hidden');
    });

    let graph = document.querySelector(
      `#widget47 #area-graph-${parseInt(e.value)}`
    );
    let legends = document.querySelector(
      `#widget47 #area-graph-${parseInt(e.value)}-legends`
    );
    graph.classList.remove('hidden');
    legends.classList.remove('hidden');
  };
  let setInfoModal = (id, data, html, updateShareModal = 'all') => {
    if (updateShareModal !== 'share') {
      objToolbar[id].textModal = `<h2>${data.name}</h2>${data.description}`;
    }
    objToolbar[id].widgetData = data;
    objToolbar[id].shareModal = html;
    componentToolbar.render(objToolbar[id], '.esios-toolbar' + (id + 1), false);
  };
  let spinnerNone = (id, widgetId = false) => {
    document.getElementById(id).style.display = 'none';
  };

  let spinnerTrue = (id, widgetId = false) => {
    document.getElementById(id).style.display = 'flex';
  };

  const updateDateTime = (id, date) => {
    document.getElementById(id).innerHTML = date;
  };

  let renderComponent = async () => {
    const script = document.createElement('script');
    const scriptCss = document.createElement('script');
    const link = document.createElement('link');
    document.title = `${
      i18n.t('exchanges_i').charAt(0).toUpperCase() +
      i18n.t('exchanges_i').slice(1)
    } | ${i18n.t('page_subtitle')}`;
    script.src = 'https://libs.cartocdn.com/cartodb.js/v3/3.14.6/cartodb.js';
    scriptCss.src =
      'https://libs.cartocdn.com/cartodb.js/v3/3.14.6/themes/css/cartodb.css';
    link.rel = 'stylesheet';
    link.href =
      'https://libs.cartocdn.com/cartodb.js/v3/3.14.6/themes/css/cartodb.css';
    document.head.appendChild(script);
    document.head.appendChild(scriptCss);
    document.head.appendChild(link);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const setParameter = (key, value) => {
      urlParams.set(key, value);
      history.pushState({}, '', window.location.pathname + '?' + urlParams);
    };

    const changeDate = () => {
      if (date == null) {
        date = $('#datepicker').val();
        date = moment(date, 'DD/MM/YYYY').format('DD-MM-YYYY');
      }

      let [tempDay, tempMonth, tempYear] = date.split('-');
      let beforeDate = moment(date, 'DD-MM-YYYY')
        .subtract('1', 'month')
        .tz('Europe/Madrid')
        .format('DD-MM-YYYY');

      let [bDay, bMonth, bYear] = beforeDate.split('-');
      let beforeYear = moment(date, 'DD-MM-YYYY')
        .subtract('1', 'year')
        .tz('Europe/Madrid')
        .format('DD-MM-YYYY');
      objToolbar = [
        {
          id: 0,
          slug: i18n.t('routes.spot-europa'),
          info: true,
          share: true,
          favorite: true,
          favId: 40,
          analysis: true,
          url: () =>
            `/${i18n.t('routes.lng')}/${i18n.t(
              'routes.analysis'
            )}/600?vis=1&start_date=${bDay}-${bMonth}-${bYear}T00%3A00&end_date=${tempDay}-${tempMonth}-${tempYear}T23%3A55&groupby=hour`,
        },
        {
          id: 1,
          slug: i18n.t('routes.hourly-balances-border'),
          info: true,
          share: true,
          favorite: true,
          favId: 39,
          analysis: true,
          url: () =>
            `/${i18n.t('routes.lng')}/${i18n.t(
              'routes.analysis'
            )}/10014?compare_indicators=10015%2C10016%2C10017&start_date=${beforeDate}T00%3A00&geoids=&vis=1&end_date=${date}T23%3A55&groupby=hour&geoids=&vis=1`,
        },
        {
          id: 2,
          slug: i18n.t('routes.balance-programmed-interconections'),
          info: true,
          share: true,
          favorite: true,
          favId: 38,
          analysis: true,
          url: () =>
            `/${i18n.t('routes.lng')}/${i18n.t(
              'routes.analysis'
            )}/10014?compare_indicators=10015%2C10016%2C10017&start_date=${beforeDate}T00%3A00&geoids=&vis=1&end_date=${date}T23%3A55&groupby=day`,
        },
        {
          id: 3,
          slug: i18n.t('routes.spot-europa-map'),
          info: true,
          share: true,
          favorite: true,
          favId: 40,
          analysis: true,
          url: () =>
            `/${i18n.t('routes.lng')}/${i18n.t(
              'routes.analysis'
            )}/600?vis=1&start_date=${bDay}-${bMonth}-${bYear}T00%3A00&end_date=${tempDay}-${tempMonth}-${tempYear}T23%3A55&groupby=day`,
        },
        {
          id: 4,
          slug: i18n.t('routes.explicit-auctions-results'),
          info: true,
          share: true,
          favorite: true,
          favId: 10,
          analysis: true,
          url: () =>
            `/${i18n.t('routes.lng')}/${i18n.t(
              'routes.analysis'
            )}/1077?compare_indicators=1078%2C1079%2C1080%2C1642%2C1643%2C1644%2C1645&start_date=${beforeYear}T00:00&end_date=$${tempDay}-${tempMonth}-${tempYear}T00:00&geoids=&groupby=month&vis=1`,
        },
      ];

      setParameter('date', date);
      showOrHidden('spinner1', 'flex');
      showOrHidden('spinner2', 'flex');
      renderTable();
      renderWidget40();
      renderWidget40Map();
      renderSPMap();
      renderSPGraph();
    };

    if (!urlParams.get('latlng')) {
      setParameter('latlng', '47.989921667414194%2C2.373046875');
    }

    if (!urlParams.get('date')) {
      setParameter('date', currentDate);
    }

    if (!urlParams.get('zoom')) {
      setParameter('zoom', '3');
    }
    try {
      const state = store.getState();
      const lang = state.exampleReducer.currentLang;

      let buttons = [
        {
          label: 'see_more_doc',
          url: `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.documentation'
          )}?scope=${i18n.t('exchanges_i').replaceAll(' ', '+')}`,
        },
        {
          label: 'downloads_files',
          url: `/${i18n.t('routes.lng')}/${i18n.t('routes.downloads')}`,
        },
      ];
      let documents = [
        {
          title:
            'circular-2-2014-de-12-de-marzo-de-la-comision-nacional-de-los-mercados-y-la-competencia-cnmc',
          link: `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.documentation'
          )}/circular-2-2014-de-12-de-marzo-de-la-comision-nacional-de-los-mercados-y-la-competencia-cnmc`,
        },
        {
          title:
            'reglas-armonizadas-de-asignacion-de-capacidad-en-el-largo-plazo-harmonised-allocation-rules-for-long-term-transmission-rights-en-aplicacion-del-articulo-51-de-la-gl-fca',
          link: `https://api.esios.ree.es/documents/365/download?locale=es`,
        },
        {
          title: 'swe-ccm-amendment-january-2022',
          link: `https://api.esios.ree.es/documents/664/download?locale=es`,
        },
        {
          title:
            'p-o-14-6-liquidacion-de-intercambios-internacionales-no-realizados-por-sujetos-del-mercado',
          link: `https://www.esios.ree.es/es/documentacion/po-40-intercambios-internacionales`,
        },
        {
          title: 'pro_regionales_diario_reglas',
          link: `https://api.esios.ree.es/documents/631/download?locale=es`,
        },
      ];

      let listMarket = [
        {
          type: 'title',
          id: 2,
          title: 'structural_data',
        },
        {
          type: 'list',
          id: 1,
          text: 'participants_auction',
          url: `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.participants_auction'
          )}`,
        },
        {
          type: 'list',
          id: 2,
          text: 'programming_units',
          url: `/${i18n.t('routes.lng')}/${i18n.t('routes.programming_units')}`,
        },
        {
          type: 'list',
          id: 3,
          text: 'physical_units',
          url: `/${i18n.t('routes.lng')}/${i18n.t('routes.physical_units')}`,
        },
        {
          type: 'list',
          id: 4,
          text: 'market_subjects',
          url: `/${i18n.t('routes.lng')}/${i18n.t('routes.market_subjects')}`,
        },
      ];

      let renderComponentDocumentList = async (form) => {
        filterForm.ambit = form.ambit;
        filterForm.action = form.actions;
        filterForm.category = form.category;
        filterForm.process = form.process;
        filterForm.query = form.query;
        let documentListData = await gestionDemandaService.getArchives(
          filterForm
        );

        let newDLArray = {
          data: documentListData.newIndicators,
          renderAgain: (form) => renderComponentDocumentList(form),
        };
        componentDocumentList.render(newDLArray, '#document-list-ii');
      };

      await templateIntercambiosInternacionales(
        handlers,
        buttons,
        documents,
        listMarket,
        lang
      );

      componentTimeSelector.render(
        selectTimeForMercadeSpot,
        '#intMercadeSpotSelector'
      );

      let newDLArray = {
        renderAgain: (form) => renderComponentDocumentList(form),
      };
      componentDocumentList.render(newDLArray, '#document-list-ii');

      let updateChars = async (currentDay = true) => {
        if (currentDay) {
          date = moment().tz('Europe/Madrid').format('DD-MM-YYYY');
        } else {
          date = $('#datepicker').val();
          date = moment(date, 'DD/MM/YYYY').format('DD-MM-YYYY');
        }

        setParameter('date', moment(date, 'DD-MM-YYYY').format('DD-MM-YYYY'));
        selectTimeForMercadeSpot.currentHour = '00';
        selectTimeForMercadeSpot.currentMinutes = '00';
        changeDate();
      };

      document
        .querySelector('.esios-layout__wrap')
        .classList.add('esios-layout__wrap--intercambios');
      document
        .querySelector('.esios-layout__breadcrumbs__wrapper')
        .classList.add('layout__breadcrumbs__wrapper--intercambios');
      document
        .querySelector('.esios-layout__breadcrumbs')
        .classList.add('esios-layout__breadcrumbs--intercambios');
      $(document).on('change', '#datepicker', () => {
        updateChars(false);
      });
      Utils.clickHoy('datepicker', updateChars, true);

      if (urlParams.get('date')) {
        date = urlParams.get('date');
        document.getElementById('datepicker').value = moment(
          date,
          'DD-MM-YYYY'
        ).format('DD / MM / YYYY');
      } else {
        date = moment().tz('Europe/Madrid').format('DD-MM-YYYY');
      }
      let [tempDay, tempMonth, tempYear] = date.split('-');
      let beforeDate = moment(date, 'DD-MM-YYYY')
        .subtract('1', 'month')
        .tz('Europe/Madrid')
        .format('DD-MM-YYYY');

      let [bDay, bMonth, bYear] = beforeDate.split('-');
      let beforeYear = moment(date, 'DD-MM-YYYY')
        .subtract('1', 'year')
        .tz('Europe/Madrid')
        .format('DD-MM-YYYY');
      objToolbar = [
        {
          id: 0,
          slug: i18n.t('routes.spot-europa'),
          info: true,
          share: true,
          favorite: true,
          favId: 40,
          analysis: true,
          url: () =>
            `/${i18n.t('routes.lng')}/${i18n.t(
              'routes.analysis'
            )}/600?vis=1&start_date=${bDay}-${bMonth}-${bYear}T00%3A00&end_date=${tempDay}-${tempMonth}-${tempYear}T23%3A55&groupby=hour`,
        },
        {
          id: 1,
          slug: i18n.t('routes.hourly-balances-border'),
          info: true,
          share: true,
          favorite: true,
          favId: 39,
          analysis: true,
          url: () =>
            `/${i18n.t('routes.lng')}/${i18n.t(
              'routes.analysis'
            )}/10014?compare_indicators=10015%2C10016%2C10017&start_date=${beforeDate}T00%3A00&geoids=&vis=1&end_date=${date}T23%3A55&groupby=hour&geoids=&vis=1`,
        },
        {
          id: 2,
          slug: i18n.t('routes.balance-programmed-interconections'),
          info: true,
          share: true,
          favorite: true,
          favId: 38,
          analysis: true,
          url: () =>
            `/${i18n.t('routes.lng')}/${i18n.t(
              'routes.analysis'
            )}/10014?compare_indicators=10015%2C10016%2C10017&start_date=${beforeDate}T00%3A00&geoids=&vis=1&end_date=${date}T23%3A55&groupby=day`,
        },
        {
          id: 3,
          slug: i18n.t('routes.spot-europa-map'),
          info: true,
          share: true,
          favorite: true,
          favId: 40,
          analysis: true,
          url: () =>
            `/${i18n.t('routes.lng')}/${i18n.t(
              'routes.analysis'
            )}/600?vis=1&start_date=${bDay}-${bMonth}-${bYear}T00%3A00&end_date=${tempDay}-${tempMonth}-${tempYear}T23%3A55&groupby=day`,
        },
        {
          id: 4,
          slug: i18n.t('routes.explicit-auctions-results'),
          info: true,
          share: true,
          favorite: true,
          favId: 10,
          analysis: true,
          url: () =>
            `/${i18n.t('routes.lng')}/${i18n.t(
              'routes.analysis'
            )}/1077?compare_indicators=1078%2C1079%2C1080%2C1642%2C1643%2C1644%2C1645&start_date=${beforeYear}T00:00&end_date=$${tempDay}-${tempMonth}-${tempYear}T00:00&geoids=&groupby=month&vis=1`,
        },
      ];

      renderWidget40();
      renderWidget40Map();
      renderSPMap();
      renderSPGraph();

      let currentDate = moment(
        document.getElementById('datepicker').value,
        'DD / MM / YYYY'
      ).format('DD-MM-YYYY');

      fecha.day = moment($('#datepicker').val(), 'DD / MM / YYYY').format('DD');
      fecha.month = moment($('#datepicker').val(), 'DD / MM / YYYY').format(
        'MM'
      );
      fecha.year = moment($('#datepicker').val(), 'DD / MM / YYYY').format(
        'YYYY'
      );

      renderTable();
    } catch (error) {
      console.error(error);
    }
  };

  const renderTable = async () => {
    const state = store.getState();
    const lang = state.exampleReducer.currentLang;

    spinnerTrue('spinner10');
    document.getElementById('mypSubastasWidgetView').style.opacity = '0.5';

    let currentHour = moment().tz('Europe/Madrid').format('HH');
    let currentMinutes = '00';

    let [day, month, year] =
      getUrlParams().date?.split('-') ||
      moment().tz('Europe/Madrid').format('DD-MM-YYYY');
    let newFecha = {
      day,
      month,
      year,
    };

    auctionsData = await servicesIntercambiosInternacionales
      .getAuctionsDataFromWidget({
        lang,
        time: `${currentHour}:${currentMinutes}`,
        ...newFecha,
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return [];
      });

    auctionsPortugalData = await servicesIntercambiosInternacionales
      .getAuctionsPortugalDataFromWidget({
        lang,
        time: `${currentHour}:${currentMinutes}`,
        ...newFecha,
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        spinnerNone('spinner10');
        return [];
      })
      .catch((error) => {
        document.getElementById('calendar-button').classList.add('hidden');
      });
    if (auctionsData?.indicators && auctionsPortugalData?.indicators) {
      document.getElementById('calendar-button').classList.remove('hidden');
      document
        .querySelector('#mypSubastasWidgetView .content')
        .classList.remove('esios-intercambios__no-data-graphic');
      document
        .querySelector('#mypSubastasWidgetView .esios-pvpc__no-data')
        .classList.add('hidden');

      const tableData = auctionsData.indicators;
      const newTable = tableData.concat(auctionsPortugalData.indicators);
      setInfoModal(
        4,
        {
          ...auctionsData,
          indicators: newTable,
        },
        templateShareModal(
          'percentage-of-renewable-energy-that-cannot-be-integrated',
          window.location.search
        ),
        'all'
      );

      let miArrayTab =
        intercambiosInternacionalesContent.tableContent(newTable);
      componentTabs.render(miArrayTab, '#tabwithcontent');
      let tableContent1 = document.getElementById('tab-myp-content1');
      tableContent1.innerHTML = miArrayTab.tabsContent[0].content;
      let tableContent2 = document.getElementById('tab-myp-content2');
      tableContent2.innerHTML = miArrayTab.tabsContent[1].content;
      let tableContent3 = document.getElementById('tab-myp-content3');
      tableContent3.innerHTML = miArrayTab.tabsContent[2].content;
      let tableContent4 = document.getElementById('tab-myp-content4');
      tableContent4.innerHTML = miArrayTab.tabsContent[3].content;

      document.querySelector('.esios-tab li a').click();
    } else {
      document.getElementById('calendar-button').classList.add('hidden');
      document
        .querySelector('#mypSubastasWidgetView .esios-pvpc__no-data')
        .classList.remove('hidden');
      document
        .querySelector('#mypSubastasWidgetView .content')
        .classList.add('esios-intercambios__no-data-graphic');
    }
    spinnerNone('spinner10');
    document.getElementById('mypSubastasWidgetView').style.opacity = '1';
  };
  const renderColumnSelected = (selected, agg) => {
    let selectedFor25h = selected;
    selected = parseInt(selected);
    let select = selected;
    document.querySelectorAll('.ui-tabs-panel').forEach((e) => {
      e.style.display = 'none';
    });
    if (agg === 'hour') {
      document.querySelector('.selector-title').innerText = i18n
        .t('hour')
        .toUpperCase();

      let { date } = getUrlParams();
      if (!date) {
        date = moment().format('DD-MM-YYYY');
      }
      let numberHours = calculateHoursPerDay(moment(date, 'DD-MM-YYYY'));
      if (numberHours == 23 && selected > 4) {
        select = select + 1;
      }
      if (
        (numberHours == 25 && selectedFor25h == '2b') ||
        (numberHours == 25 && selected > 2)
      ) {
        selected = selected + 1;
      }
      if (select <= 5) {
        document.getElementById('table-0').style.display = 'block';
      } else if (select > 5 && select <= 11) {
        selected = select - 6;
        document.getElementById('table-1').style.display = 'block';
      } else if (select > 11 && select <= 17) {
        selected = select - 12;
        document.getElementById('table-2').style.display = 'block';
      } else if (select > 17 && select <= 23) {
        selected = select - 18;
        document.getElementById('table-3').style.display = 'block';
      }
    } else if (agg === 'day') {
      document.querySelector('.selector-title').innerText = i18n
        .t('day')
        .toUpperCase();

      let { date } = getUrlParams();
      if (!date) {
        date = moment().format('DD-MM-YYYY');
      }
      let numberOfDays = moment(date, 'DD-MM-YYYY').daysInMonth();
      if (select <= 7) {
        document.getElementById('table-0').style.display = 'block';
      } else if (select > 7 && select <= 15) {
        selected = select - 8;
        document.getElementById('table-1').style.display = 'block';
      } else if (select > 15 && select <= 23) {
        selected = select - 16;
        document.getElementById('table-2').style.display = 'block';
      } else if (select > 23 && select <= numberOfDays) {
        selected = select - 24;
        document.getElementById('table-3').style.display = 'block';
      }
    } else if (agg === 'month') {
      document.querySelector('.selector-title').innerText = i18n
        .t('month')
        .toUpperCase();

      let { date } = getUrlParams();
      if (!date) {
        date = moment().format('DD-MM-YYYY');
      }
      if (select <= 5) {
        document.getElementById('table-0').style.display = 'block';
      } else if (select > 5 && select <= 12) {
        selected = select - 6;
        document.getElementById('table-1').style.display = 'block';
      }
    }
    document.querySelectorAll('table .column-with-data').forEach((e) => {
      e.style.display = 'none';
    });
    document.querySelectorAll('.coltable-' + selected).forEach((e) => {
      e.style.display = 'initial';
      e.classList.add('val_numbers');
    });
  };

  const showOrHidden = (id, displayType) => {
    if (document.getElementById(id)) {
      document.getElementById(id).style.display = displayType;
    }
  };

  const showNoData = (data, idContainer, idRect) => {
    if (
      data.indicators?.every(
        (indicator) =>
          indicator.print_values?.length == 0 || indicator.print_values == null
      )
    ) {
      showOrHidden(idRect, 'block');
      document
        .getElementById('geo-map-container-600')
        .classList.add('esios-intercambios__no-data-graphic');
      document.querySelector('#char40 svg')?.remove();
      document
        .getElementById('char40Container')
        ?.classList.add('esios-intercambios__no-data-graphic');
      if (document.getElementById('char40Data')) {
        document.getElementById('char40Data').classList.add('hidden');
      }

      if (idContainer == 'map40') {
        showOrHidden('geo-legends', 'none');
      }
    } else {
      showOrHidden(idRect, 'none');
      document
        .getElementById('char40Container')
        ?.classList.remove('esios-intercambios__no-data-graphic');
      document
        .getElementById('geo-map-container-600')
        .classList.remove('esios-intercambios__no-data-graphic');
      if (document.getElementById('char40Data')) {
        document.getElementById('char40Data').classList.remove('hidden');
      }

      if (document.querySelector('#mypSubastasWidgetView .content')) {
        document
          .querySelector('#mypSubastasWidgetView .content')
          .classList.remove('esios-intercambios__no-data-graphic');
      }

      document
        .getElementById(idContainer)
        .classList.remove('esios-intercambios__no-data-graphic');
    }
  };

  const showOrHideMapLegends = (data, id) => {
    if (data.indicators[0]?.values.length === 0) {
      document.getElementById(id).classList.add('hidden');
    } else if (data.length === 0) {
      document.getElementById(id).classList.add('hidden');
    } else {
      document.getElementById(id).classList.remove('hidden');
    }
  };

  const renderWidget40 = () => {
    servicesIntercambiosInternacionales
      .getDataFromWidget({
        id: 40,
        date,
      })
      .then(async (data) => {
        document.querySelector('#char40 svg')
          ? document.querySelector('#char40 svg').remove()
          : null;

        setInfoModal(
          0,
          data,
          templateShareModal('spot-europa', window.location.search),
          'all'
        );

        let chart = await charts[40]({ data, currentDate: date });
        if (data.indicators[0]?.values.length === 0) {
          if (document.getElementById('char40Data')) {
            document.getElementById('char40Data').classList.add('hidden');
          }
          if (document.getElementById('char40Container')) {
            document
              .getElementById('char40Container')
              .classList.add('esios-intercambios__no-data-graphic');
          }

          if (document.querySelector('#char40Container #rect1')) {
            document.querySelector('#char40Container #rect1').style.display =
              'block';
          }

          if (document.getElementById('char40')) {
            document.getElementById('char40').classList.add('aspect-16');
          }
        } else {
          if (document.getElementById('char40Data')) {
            document.getElementById('char40Data').classList.remove('hidden');
          }

          if (document.querySelector('#char40Container')) {
            document
              .querySelector('#char40Container')
              .classList.remove('esios-intercambios__no-data-graphic');
          }
          if (document.querySelector('#char40Container #rect1')) {
            document.querySelector('#char40Container #rect1').style.display =
              'none';
          }
          if (document.getElementById('char40')) {
            document.getElementById('char40').classList.remove('aspect-16');
          }
        }

        document.querySelector('#char40Container #spinner1').style.display =
          'none';
      })
      .catch(() => {
        document.querySelector('#char40Container #spinner1').style.display =
          'none';
        if (document.getElementById('char40Data')) {
          document.getElementById('char40Data').classList.add('hidden');
        }
        if (document.getElementById('char40Container')) {
          document
            .getElementById('char40Container')
            .classList.add('esios-intercambios__no-data-graphic');
        }
        if (document.getElementById('char40')) {
          document.getElementById('char40').classList.add('aspect-16');
        }

        if (document.querySelector('#char40Container #rect1')) {
          document.querySelector('#char40Container #rect1').style.display =
            'block';
        }
      });
  };

  const renderWidget40Map = (newDate = false) => {
    let { hours, minutes } = newDate;

    if (!hours) {
      hours = '00';
    }
    if (!minutes) {
      minutes = '00';
    }
    servicesIntercambiosInternacionales
      .getDataFromWidget({
        id: 40,
        date,
        hours,
        minutes,
        trunc: 'day',
      })
      .then(async (data) => {
        setInfoModal(
          3,
          data,
          templateShareModal('spot-europa-map', window.location.search),
          'all'
        );

        let dateParse = data.indicators[0]?.values[0]?.x
          ? moment(`${data.indicators[0]?.values[0]?.x}`)
              .tz('Europe/Madrid')
              .format('DD / MM / YYYY')
          : '00:00';
        updateDateTime('mercade-spot-map', dateParse);

        let chart = await charts[40]({
          data,
          currentDate: `${date}T${hours}`,
          slug: 'spot-europa-map',
        });

        if (data.indicators[0]?.values.length === 0) {
          if (document.getElementById('geo-legend')) {
            document.getElementById('geo-legend').classList.add('hidden');
          }
          if (document.getElementById('geo-map-container-600')) {
            document
              .getElementById('geo-map-container-600')
              .classList.add('esios-intercambios__no-data-graphic');
          }

          if (document.querySelector('#map40 #rect2')) {
            document.querySelector('#map40 #rect2').style.display = 'block';
          }
        } else {
          if (document.getElementById('geo-legend')) {
            document.getElementById('geo-legend').classList.remove('hidden');
          }

          if (document.querySelector('#geo-map-container-600')) {
            document
              .querySelector('#geo-map-container-600')
              .classList.remove('esios-intercambios__no-data-graphic');
          }
          if (document.querySelector('#map40 #rect2')) {
            document.querySelector('#map40 #rect2').style.display = 'none';
          }
        }

        document.querySelector('#map40 #spinner2').style.display = 'none';
      })
      .catch(() => {
        if (document.getElementById('geo-legend')) {
          document.getElementById('geo-legend').classList.add('hidden');
        }
        if (document.getElementById('geo-map-container-600')) {
          document
            .getElementById('geo-map-container-600')
            .classList.add('esios-intercambios__no-data-graphic');
        }

        if (document.querySelector('#map40 #rect2')) {
          document.querySelector('#map40 #rect2').style.display = 'block';
        }

        document.querySelector('#map40 #spinner2').style.display = 'none';
      });
  };

  const renderSPMap = () => {
    document.getElementById('spinner38').style.display = 'flex';
    servicesIntercambiosInternacionales
      .getDataFromWidget({
        id: 38,
        date,
      })
      .then(async (data) => {
        setInfoModal(
          2,
          data,
          templateShareModal(
            'balance-programmed-interconections',
            window.location.search
          ),
          'all'
        );
        let dateParse =
          moment(`${data.indicators[0]?.values[0]?.x}`)
            .tz('Europe/Madrid')
            .format('DD / MM / YYYY') || '-- / -- / --';
        updateDateTime('horario-frontera-map', dateParse);
        if (data.indicators[0]?.values.length === 0) {
          document.getElementById('char38').style.display = 'flex';
          document.getElementById('horario-frontera-map').innerHTML = '00:00';

          if (document.getElementById('map-wrapper')) {
            document.getElementById('map-wrapper').classList.add('aspect-16');
          }
        } else {
          if (document.getElementById('map-wrapper')) {
            document
              .getElementById('map-wrapper')
              .classList.remove('aspect-16');
          }

          document.getElementById('char38').style.display = 'none';
        }

        render(
          MapaIntercambiosInternacionales(data),
          document.getElementById('map-main-container')
        );
        document.getElementById('spinner38').style.display = 'none';
      })
      .catch((error) => {
        if (document.getElementById('map-wrapper')) {
          document.getElementById('map-wrapper').classList.add('aspect-16');
        }

        document.getElementById('spinner38').style.display = 'none';
        document.getElementById('char38').style.display = 'flex';
        document.getElementById('horario-frontera-map').innerHTML = '00:00';
        document.querySelector(
          '.esios-intercambios__no-data-graphic'
        ).style.display = 'flex';
        document.querySelector(
          '.esios-intercambios__no-data-graphic'
        ).style.width = '100%';
        document.querySelector(
          '.esios-intercambios__no-data-graphic'
        ).style.height = '100%';
      });
  };
  const renderSPGraph = () => {
    document.getElementById('spinner39').style.display = 'flex';
    let isMobile = Utils.isMobileDevice();
    servicesIntercambiosInternacionales
      .getDataFromWidget({
        id: 39,
        date,
      })
      .then(async (data) => {
        let opts = {
          type: 'multi',
          special: true,
          fit: true,
          multibar: true,
          margin: { top: 20, right: 0, bottom: 40, left: 50 },
          trail: {
            enabled: true,
            parseStep: function (date) {
              return moment(date).startOf('hour').toDate();
            },
            initXValue: function () {
              let now = moment().tz('Europe/Madrid').format('DD-MM-YYYY');
              if (currentDate !== now) {
                return lastDate.at(-1);
              } else {
                return moment().tz('Europe/Madrid').format();
              }
            },
          },
          lineInterpolation: 'linear',
          elem: '#chart-bar-line',
          height: isMobile ? 380 : 500,
          date: date,
          yticks: 10,
          left: {
            label: 'MWh',
          },
          bottom: {
            label: 'hours',
            prefix: '0',
          },
          magnitud: 'MWh',
        };
        let valh = calculateHoursPerDay(date);
        if (valh !== 25) {
          valh = 24;
        }
        let preData = {
          ...data,
          indicators: data.indicators.map((el) => {
            return {
              ...el,
              values: fillSimpleVals(valh),
            };
          }),
        };
        let dataOrdered = Utils.orderObjectsArrayByKey(
          preData.indicators,
          [10015, 10014, 10016, 10017, 10027],
          'id'
        );
        dataOrdered.map((el, index) => {
          let finded = data.indicators.filter((or) => or.id === el.id)[0]
            ?.values;

          let existDoubleTwo = false;
          finded.forEach((val) => {
            // let hour = parseInt(moment(val.x).tz('Europe/Madrid').format('HH'));
            let hour = existDoubleTwo
              ? parseInt(moment(val.x).tz('Europe/Madrid').format('HH')) + 1
              : parseInt(moment(val.x).tz('Europe/Madrid').format('HH'));
            if (hour == 2 && valh === 25) {
              existDoubleTwo = true;
            }
            el.values[hour] = {
              ...el.values[hour],
              value: val.y,
              y: val.y,
              color: getColour(index),
              name: el.name,
            };
          });
        });

        preData.indicators.map((el, index) => {
          return {
            ...el,
            color: getColour(index),
            name: i18n.t('balances') + ' ' + el.short_name,
            shortName: i18n.t('balances') + ' ' + el.short_name,
            demanda: false,
            legendtype: 'circle',
          };
        });

        // Pendiente de agregar linea de saldo total
        preData.indicators.push({
          id: 10027,
          name: 'Saldo total por frontera',
          shortName: 'Saldo total por frontera',
          color: '#333333',
          legendtype: 'line',
          demanda: true,
          values: fillSimpleVals(valh),
          print_values: fillPrintVals(valh, date),
        });
        preData.indicators.map((elem, index) => {
          if (index !== 4) {
            preData.indicators[4].values = preData.indicators[4]?.values.map(
              (el, ind) => {
                return {
                  ...el,
                  y: el.y + elem.values[ind].y,
                  value: el.y + elem.values[ind].y,
                };
              }
            );
            preData.indicators[4].print_values =
              preData.indicators[4]?.print_values.map((el, index) => {
                return {
                  ...el,
                  y: preData.indicators[4]?.values[index].y,
                  value: preData.indicators[4]?.values[index].value,
                };
              });
          }
        });
        if (data.indicators[0]?.values.length === 0 || data.length === 0) {
          document.getElementById('char39').style.display = 'flex';
          document.getElementById('mercade-spot-map').innerHTML = '00:00';
          document
            .querySelector('#widget39 #chart-bar-line')
            .classList.add('hidden');

          if (document.querySelector('.graph-wrapper')) {
            document.querySelector('.graph-wrapper').classList.add('aspect-16');
          }
        } else {
          if (document.querySelector('.graph-wrapper')) {
            document
              .querySelector('.graph-wrapper')
              .classList.remove('aspect-16');
          }
          document.getElementById('char39').style.display = 'none';
          document
            .querySelector('#widget39 #chart-bar-line')
            .classList.remove('hidden');
        }
        let newData = {
          ...data,
          indicators: preData.indicators,
        };

        renderBalanceGraph(preData.indicators, i18n.t('hour'), opts);
        setInfoModal(
          1,
          newData,
          templateShareModal('hourly-balances-border', window.location.search),
          'all'
        );

        document.getElementById('spinner39').style.display = 'none';
      })
      .catch((error) => {
        if (document.querySelector('.graph-wrapper')) {
          document.querySelector('.graph-wrapper').classList.add('aspect-16');
        }
        document
          .querySelector('#widget39 #chart-bar-line')
          .classList.add('hidden');
        document.getElementById('spinner39').style.display = 'none';
        document.getElementById('char39').style.display = 'flex';
      });
  };

  return {
    render: renderComponent,
  };
})();

export { componentIntercambiosInternacionales };

export const fillSimpleVals = (rangeNumber) => {
  let arr = [];
  for (let i = 0; i < rangeNumber; i++) {
    arr.push({
      x: i,
      order: i,
      y: null,
      value: null,
      color: '#333333',
      name: '',
    });
  }
  return arr;
};

export const fillPrintVals = (rangeNumber, date) => {
  if (date == undefined) {
    date = moment().format('DD-MM-YYYY');
  }
  let arr = [];
  let dateSplit = date?.split('-');
  let utcTime = moment(date, 'DD-MM-YYYY').tz('Europe/Madrid').format();
  for (let i = 0; i < rangeNumber; i++) {
    arr.push({
      datetime:
        `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}T${
          i < 10 ? '0' + i : i
        }:00:00.000+` + utcTime.split('+')[1],
      datetime_utc: '2022-02-04T23:00:00Z',
      geo_id: null,
      geo_name: null,
      tz_time: '2022-02-04T23:00:00.000Z',
      value: 0,
    });
  }
  return arr;
};

export const calculateSaldo = (data, filter, color = '#333333') => {
  let tempArr = [];
  let finalObj;
  data.map((el) => {
    if (filter.includes(el.id)) {
      tempArr.push(el);
    }
  });
  tempArr.map((el) => {
    finalObj = {
      ...el,
      values: el.values.map((val, ind) => {
        return {
          ...val,
          y: tempArr[0].values[ind].y + tempArr[1].values[ind].y,
          value: tempArr[0].values[ind].y + tempArr[1].values[ind].y,
          color,
        };
      }),
    };
  });
  return finalObj;
};

export const calculateSaldoMi = (data, color = '#333333') => {
  let finalObj;

  data.map((el) => {
    finalObj = {
      ...el,
      values: el.values.map((val, ind) => {
        return {
          ...val,
          y: data[0].values[ind].y - data[1].values[ind].y,
          value: data[0].values[ind].y - data[1].values[ind].y,
          color,
        };
      }),
    };
  });
  return finalObj;
};

export const calculateSaldoMulti = (data, filter, color = '#333333') => {
  let tempArr = [];
  let finalObj;
  data.map((el) => {
    if (filter.includes(el.id)) {
      tempArr.push(el);
    }
  });
  tempArr.map((el) => {
    finalObj = {
      ...el,
      values: el.values.map((val, ind) => {
        return {
          ...val,
          y:
            tempArr[0].values[ind].y +
            tempArr[1].values[ind].y +
            tempArr[2].values[ind].y +
            tempArr[3].values[ind].y +
            tempArr[4].values[ind].y +
            tempArr[5].values[ind].y +
            tempArr[6].values[ind].y,
          value:
            tempArr[0].values[ind].y +
            tempArr[1].values[ind].y +
            tempArr[2].values[ind].y +
            tempArr[3].values[ind].y +
            tempArr[4].values[ind].y +
            tempArr[5].values[ind].y +
            tempArr[6].values[ind].y,
          color,
        };
      }),
    };
  });
  return finalObj;
};

export const processPreData = (data) => {
  let dataFrancia = [],
    dataPortugal = [],
    dataAndorra = [],
    dataMarruecos = [];
  let date = getUrlParams()?.date
    ? getUrlParams()?.date
    : moment().tz('Europe/Madrid').format('DD-MM-YYYY');
  let valh = calculateHoursPerDay(date);
  if (valh !== 25) {
    valh = 24;
  }

  let preData = {
    ...data,
    indicators: data.indicators.map((el) => {
      return {
        ...el,
        values: fillSimpleVals(valh),
      };
    }),
  };
  preData.indicators.map((el, index) => {
    let finded = data.indicators.filter((or) => or.id === el.id)[0]?.values;

    let existDoubleTwo = false;
    finded.forEach((val, ind) => {
      let hour = existDoubleTwo
        ? parseInt(moment(val.x).tz('Europe/Madrid').format('HH')) + 1
        : parseInt(moment(val.x).tz('Europe/Madrid').format('HH'));
      if (hour == 2 && valh === 25) {
        existDoubleTwo = true;
      }
      preData.indicators[index].values[hour] = {
        ...preData.indicators[index].values[hour],
        value: val.y,
        y: val.y,
        color: getColour(index),
        name: el.name,
        extra: true,
      };
    });
  });
  preData.indicators.map((el, index) => {
    return {
      ...el,
      color: getColour(index),
      name: el.short_name,
      shortName: el.short_name,
      demanda: false,
      legendtype: 'circle',
    };
  });

  preData.indicators.find((el) => {
    if (el.short_name.toLowerCase().includes('francia') || el.id === 10026) {
      dataFrancia.push(el);
    }
    if (el.short_name.toLowerCase().includes('portugal') || el.id === 10026) {
      dataPortugal.push(el);
    }
    if (el.short_name.toLowerCase().includes('marruecos') || el.id === 10026) {
      dataMarruecos.push(el);
    }
    if (el.short_name.toLowerCase().includes('andorra') || el.id === 10026) {
      dataAndorra.push(el);
    }
  });
  return {
    dFrancia: dataFrancia,
    dPortugal: dataPortugal,
    dAndorra: dataAndorra,
    dMarruecos: dataMarruecos,
  };
};

export const proccessData = (
  dataFrancia,
  dataPortugal,
  dataMarruecos,
  dataAndorra
) => {
  let newPortugal = [],
    newFrancia = [],
    newMarruecos = [],
    newAndorra = [];

  dataFrancia.map((el) => {
    if (el.id === 10026) {
      newFrancia.push({
        ...el,
        color: '#333333',
        legendtype: 'line',
        demanda: true,
      });
    } else if (el.id === 488) {
      //import
      newFrancia.push({
        ...el,
        color: '#333333',
        legendtype: 'line',
        area0: true,
      });
    } else if (el.id === 492) {
      //export
      newFrancia.push({
        ...el,
        color: '#333333',
        legendtype: 'line',
        area1: true,
        values: el.values.map((val) => {
          return {
            ...val,
            y: val.y * -1,
            value: val.y * -1,
          };
        }),
      });
    } else if (el.id === 10275) {
      // MIC
      newFrancia.push({
        ...el,
        values: el.values.map((val) => {
          return {
            ...val,
            color: getColour(2),
          };
        }),
      });
    }
  });
  // largo plazo
  newFrancia.push(calculateSaldo(dataFrancia, [1105, 1106], getColour(0)));
  // MD
  newFrancia.push(calculateSaldo(dataFrancia, [28, 32], getColour(1)));
  // RR
  newFrancia.push(calculateSaldo(dataFrancia, [535, 536], getColour(3)));
  // ACB
  newFrancia.push(calculateSaldo(dataFrancia, [564, 565], getColour(4)));

  dataPortugal.map((el) => {
    if (el.id === 10026) {
      newPortugal.push({
        ...el,
        color: '#333333',
        legendtype: 'line',
        demanda: true,
      });
    } else if (el.id === 489) {
      // imp
      newPortugal.push({
        ...el,
        color: '#333333',
        legendtype: 'line',
        area0: true,
      });
    } else if (el.id === 493) {
      // exp
      newPortugal.push({
        ...el,
        color: '#333333',
        legendtype: 'line',
        area1: true,
        values: el.values.map((val) => {
          return {
            ...val,
            y: val.y * -1,
            value: val.y * -1,
          };
        }),
      });
    } else if (el.id === 10276) {
      // MIC
      newPortugal.push({
        ...el,
        values: el.values.map((val) => {
          return {
            ...val,
            color: getColour(2),
          };
        }),
      });
    }
  });
  //MI
  newPortugal.push(
    calculateSaldoMi(
      [
        calculateSaldoMulti(
          dataPortugal,
          [10115, 10116, 10117, 10118, 10119, 10120, 10121]
        ),
        calculateSaldo(dataPortugal, [29, 33]),
      ],
      getColour(5)
    )
  );
  // MD
  newPortugal.push(calculateSaldo(dataPortugal, [29, 33], getColour(1)));
  // RR
  newPortugal.push(calculateSaldo(dataPortugal, [539, 540], getColour(3)));
  // ACB
  newPortugal.push(calculateSaldo(dataPortugal, [566, 567], getColour(4)));

  dataMarruecos.map((el) => {
    if (el.id === 10026) {
      newMarruecos.push({
        ...el,
        color: '#333333',
        legendtype: 'line',
        demanda: true,
      });
    } else if (el.id === 490) {
      // imp
      newMarruecos.push({
        ...el,
        color: '#333333',
        legendtype: 'line',
        area0: true,
      });
    } else if (el.id === 494) {
      // exp
      newMarruecos.push({
        ...el,
        color: '#333333',
        legendtype: 'line',
        area1: true,
        values: el.values.map((val) => {
          return {
            ...val,
            y: val.y * -1,
            value: val.y * -1,
          };
        }),
      });
    }
  });

  // MI
  newMarruecos.push(
    calculateSaldoMi(
      [
        calculateSaldoMulti(
          dataMarruecos,
          [10124, 10125, 10126, 10127, 10128, 10129, 10130]
        ),
        calculateSaldo(dataMarruecos, [30, 34]),
      ],
      getColour(5)
    )
  );
  // MD
  newMarruecos.push(calculateSaldo(dataMarruecos, [30, 34], getColour(1)));

  dataAndorra.map((el) => {
    if (el.id === 10026) {
      newAndorra.push({
        ...el,
        color: '#333333',
        legendtype: 'line',
        demanda: true,
      });
    } else if (el.id === 491) {
      // imp
      newAndorra.push({
        ...el,
        color: '#333333',
        legendtype: 'line',
        area0: true,
      });
    } else if (el.id === 495) {
      // exp
      newAndorra.push({
        ...el,
        color: '#333333',
        legendtype: 'line',
        area1: true,
        values: el.values.map((val) => {
          return {
            ...val,
            y: val.y * -1,
            value: val.y * -1,
          };
        }),
      });
    }
  });

  // MD
  newAndorra.push(calculateSaldo(dataAndorra, [31, 35], getColour(1)));
  return [newFrancia, newPortugal, newMarruecos, newAndorra];
};
