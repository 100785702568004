'use strict';
import i18next from 'i18next';
import moment from 'moment-timezone';
import { api } from '../../utils/api-interceptor';
import { getState } from '../../utils/state';
import { Utils } from '../../utils/utils';

let gestionDemandaService = (() => {
  let init = () => {};

  const getStaticPageData = async () => {
    let data = await api(
      `static_pages?slug=${i18next.t('routes.gd')}&temporal=` +
        Math.floor(Math.random() * 1000),
      {
        hasLang: true,
      }
    );
    return data.content;
  };

  const getDataTable = async () => {
    let dataobject = [];

    let id;
    let data;
    try {
      if (i18next.t('routes.lng') === 'en') {
        id = 186;
      } else {
        id = 185;
      }

      const response = await api(`archives/${id}/download_json`);

      if (i18next.t('routes.lng') === 'en') {
        data = response.ServiceActivations;
      } else {
        data = response.ActivacionesDelServicio;
      }
      if (data) {
        dataobject = Object.values(data);
        return dataobject.length;
      } else {
        return 0;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return 0;
    }
  };

  const getStaticPageDataForTab = async (num) => {
    let urlEndpoint = '';
    if (num == 1) {
      urlEndpoint = 'static_pages/normative-scope-active-demand?temporal=';
    } else if (num == 2) {
      urlEndpoint =
        'static_pages/auction-caracteristics-active-demand?temporal=';
    } else if (num == 3) {
      urlEndpoint = 'static_pages/participants-guide-active-demand?temporal=';
    } else if (num == 4) {
      urlEndpoint = 'static_pages/normative-scope?temporal=';
    } else if (num == 5) {
      urlEndpoint = 'static_pages/auction-caracteristics?temporal=';
    } else if (num == 6) {
      urlEndpoint = 'static_pages/participants-guide?temporal=';
    }

    let data = await api(`${urlEndpoint}` + Math.floor(Math.random() * 1000), {
      hasLang: true,
    });
    return data.content;
  };

  const getArchives = async (params) => {
    const { ambit, category, action, process, query } = params;

    const urlApi = `indicators?${
      ambit !== '' ? `taxonomy_ids[]=${ambit}&` : ''
    }${category !== '' ? `taxonomy_ids[]=${category}&` : ''}${
      action !== '' ? `taxonomy_ids[]=${action}&` : ''
    }${process !== '' ? `taxonomy_ids[]=${process}&` : ''}locale=${i18next.t(
      'routes.lng'
    )}${query ? `&text=${query}` : ''}`;

    let loading;
    let newIndicators;
    try {
      loading = true;
      const data = await api(urlApi);
      if (!ambit && !query && !category && !process && !action) {
        return {
          newIndicators: [],
          isLoading: loading,
        };
      }
      newIndicators = data.indicators
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
        .map((indicator) => {
          return {
            name: indicator.name,
            id: indicator.id,
          };
        });
      loading = false;
      return {
        isLoading: loading,
        newIndicators,
      };
    } catch (error) {}
  };

  const getInterruptibleDataFromWidget = async (params) => {
    const { hours, minutes, date, lang } = params;
    let newDate;

    newDate = moment(date, 'DD-MM-YYYY')
      .hours(hours ? hours : '00')
      .minutes(minutes ? minutes : '00')
      .seconds(0)
      .format();

    return api(`widgets/22?locale=${lang}&datetime=${newDate}`).then((data) => {
      const indicators = data.widget.indicators;
      const slug = data.widget.id;
      const description = data.widget.description;
      const name = data.widget.name;
      const magnituds = [];
      const alias = [];
      const indicatorName = [];
      const shortName = [];
      return Promise.all(
        indicators.map((el) => {
          magnituds.push(el.display_options);
          alias.push(el.alias);
          shortName.push(el.short_name);
          indicatorName.push(el.name);
          return api(`${el.url}&locale=${lang}`);
        })
      ).then((data) => {
        data = data.map((el) => {
          return {
            ...el,
            values:
              el.indicator.values.length !== 0
                ? fillValues(el.indicator.values)
                : [],
          };
        });
        const indicators = data.map((ele, index) => {
          const values = ele.indicator.values.map((el) => {
            return {
              x: new Date(el.datetime),
              y: el.value === 0 ? null : el.value,
            };
          });
          return {
            values,
            print_values: ele.indicator.values,
            time: ele.indicator.tiempo[0]?.id,
            alias: alias[index],
            display_options: magnituds[index],
            composited: ele.indicator?.composited,
            magnitud: ele.indicator?.magnitud,
            disaggregated: ele.indicator?.disaggregated,
            short_name: shortName[index],
            name: indicatorName[index],
            step: ele.indicator.step_type,
            id: ele.indicator.id,
            geo_id: ele.indicator.geos[0]?.geo_id,
          };
        });

        return {
          description,
          name,
          indicators,
          slug,
        };
      });
    });
  };

  const getDesgloseDataFromWidget = async (params) => {
    const { hours, minutes, date, lang } = params;
    let newDate;
    newDate = moment(date, 'DD-MM-YYYY')
      .tz('Europe/Madrid')
      .hours(hours ? hours : '00')
      .minutes(minutes ? minutes : '00')
      .seconds('00')
      .format();

    return api(`widgets/23?locale=${lang}&datetime=${newDate}`).then((data) => {
      const indicators = data.widget.indicators.filter((el) => el.url !== null);
      let urlAnalysis = Utils.getUrlAnalysis(data.widget.main_indicators);
      const slug = data.widget.id;
      const description = data.widget.description;
      const name = data.widget.name;
      const magnituds = [];
      const indicatorName = [];
      const shortName = [];
      const alias = [];
      return Promise.all(
        indicators.map((el) => {
          magnituds.push(el.display_options);
          alias.push(el.alias);
          shortName.push(el.short_name);
          indicatorName.push(el.name);
          return api(`${el.url}&locale=${lang}`);
        })
      ).then((data) => {
        const indicators = data.map((ele, index) => {
          const values = ele.indicator.values.map((el) => {
            return {
              x: new Date(el.datetime),
              y: el.value,
              geo_name: el.geo_name,
              geo_id: el.geo_id,
            };
          });

          return {
            values,
            print_values: ele.indicator.values,
            time: ele.indicator.tiempo[0]?.id,
            alias: alias[index],
            display_options: magnituds[index],
            composited: ele.indicator?.composited,
            magnitud: ele.indicator?.magnitud,
            disaggregated: ele.indicator?.disaggregated,
            short_name: shortName[index],
            name: indicatorName[index],
            step: ele.indicator.step_type,
            id: ele.indicator.id,
            geo_id: ele.indicator.geos[0]?.geo_id,
            geo_name: ele.indicator.geos[0]?.geo_name,
          };
        });
        return {
          description,
          name,
          indicators,
          slug,
          urlAnalysis,
        };
      });
    });
  };

  const getSNPDataFromWidget = async (params) => {
    const { hours, minutes, date, lang } = params;
    let newDate;

    newDate = moment(date, 'DD-MM-YYYY')
      .hours(hours ? hours : '00')
      .minutes(minutes ? minutes : '00')
      .seconds(0)
      .format();

    return api(`widgets/34?locale=${lang}&datetime=${newDate}`).then((data) => {
      const indicators = data.widget.indicators.filter((el) => el.url !== null);
      const description = data.widget.description;
      const slug = data.widget.id;
      const name = data.widget.name;
      const magnituds = [];
      const alias = [];
      const indicatorName = [];
      const shortName = [];
      return Promise.all(
        indicators.map((el) => {
          magnituds.push(el.display_options);
          alias.push(el.alias);
          shortName.push(el.short_name);
          indicatorName.push(el.name);
          return api(`${el.url}&locale=${lang}`);
        })
      ).then((data) => {
        const indicators = data.map((ele, index) => {
          const values = ele.indicator.values.map((el) => {
            return {
              x: new Date(el.datetime),
              y: el.value,
            };
          });

          return {
            values,
            print_values: ele.indicator.values,
            time: ele.indicator.tiempo[0].id,
            alias: alias[index],
            display_options: magnituds[index],
            composited: ele.indicator?.composited,
            magnitud: ele.indicator?.magnitud,
            disaggregated: ele.indicator?.disaggregated,
            short_name: shortName[index],
            name: indicatorName[index],
            step: ele.indicator.step_type,
            id: ele.indicator.id,
            geo_id: ele.indicator.geos[0]?.geo_id,
            geo_name: ele.indicator.geos[0]?.geo_name,
          };
        });
        return {
          description,
          name,
          indicators,
          slug,
        };
      });
    });
  };

  const getTabsDataFromWidget = async (params) => {
    const { hours, minutes, date, lang } = params;
    let newDate;

    newDate = moment(date, 'DD-MM-YYYY')
      .hours(hours ? hours : '00')
      .minutes(minutes ? minutes : '00')
      .seconds(0)
      .format();

    return api(`widgets/27?locale=${lang}&datetime=${newDate}`).then((data) => {
      const indicators = data.widget.indicators.filter((el) => el.url !== null);
      const description = data.widget.description;
      const name = data.widget.name;
      const slug = data.widget.id;
      const magnituds = [];
      const alias = [];
      return Promise.all(
        indicators.map((el) => {
          magnituds.push(el.display_options);
          alias.push(el.alias);
          return api(`${el.url}&locale=${lang}`);
        })
      ).then((data) => {
        const indicators = data.map((ele, index) => {
          const values = ele.indicator.values.map((el) => {
            return {
              x: new Date(el.datetime),
              y: el.value,
            };
          });

          return {
            values,
            alias: alias[index],
            display_options: magnituds[index],
            composited: ele.indicator?.composited,
            magnitud: ele.indicator?.magnitud,
            disaggregated: ele.indicator?.disaggregated,
            short_name: ele.indicator.short_name,
            step: ele.indicator.step_type,
            id: ele.indicator.id,
            geo_id: ele.indicator.geos[0]?.geo_id,
            geo_name: ele.indicator.geos[0]?.geo_name,
          };
        });
        return {
          description,
          name,
          indicators,
          slug,
        };
      });
    });
  };

  return {
    init: init,
    getArchives: (params) => getArchives(params),
    getInterruptibleDataFromWidget: (params) =>
      getInterruptibleDataFromWidget(params),
    getDesgloseDataFromWidget: (params) => getDesgloseDataFromWidget(params),
    getSNPDataFromWidget: (params) => getSNPDataFromWidget(params),
    getTabsDataFromWidget: (params) => getTabsDataFromWidget(params),
    getStaticPageDataForTab: (num) => getStaticPageDataForTab(num),
    getStaticPageData: getStaticPageData,
    getDataTable: (e) => getDataTable(e),
  };
})();

export { gestionDemandaService };

export const fillValues = (arr) => {
  let lastPos = arr.at(-1);
  let lastPosDatetime = parseInt(lastPos.datetime.split('T')[1].split(':')[0]);
  let lastPosDatetimeMinutes = parseInt(
    `${lastPos.datetime.split('T')[1].split(':')[1]}`[0]
  );

  if (lastPosDatetime < 24) {
    let diff = 24 - lastPosDatetime;
    var h = lastPosDatetime;
    for (let i = 0; i < diff; i++) {
      for (let j = 0; j < 6; j++) {
        if (h === h + i && lastPosDatetimeMinutes < j) {
          arr.push({
            ...lastPos,
            value: null,
            datetime: `${lastPos.datetime.split('T')[0]}T${
              h + i < 10 ? '0' + (h + i) : h + i
            }:${j}0:00.000+${lastPos.datetime.split('+').at(-1)}`,
          });
        } else if (h !== h + i) {
          arr.push({
            ...lastPos,
            value: null,
            datetime: `${lastPos.datetime.split('T')[0]}T${
              h + i < 10 ? '0' + (h + i) : h + i
            }:${j}0:00.000+${lastPos.datetime.split('+').at(-1)}`,
          });
        }
      }
    }
  }
  return arr;
};

// ! TODO: VALIDATE FOR ANOTHER GROUP BY
export const fillValuesForAnalysis = (arr) => {
  let { analysis } = getState();
  let groupby = analysis.groupby;
  if (groupby == 'day' || groupby == 'month' || groupby == 'year') {
    return arr;
  }
  arr = arr.map((element) => {
    let startDate = new Date(element.values[0]?.x);
    let endDate = new Date(element.values.at(-1)?.x);
    let dateArr = []; //Array where dates will be stored
    //Logic for getting rest of the dates between two dates("startDate" to "EndDate")
    while (startDate <= endDate) {
      let found = element.values.find(
        (el) => new Date(el.x).getTime() == new Date(startDate).getTime()
      );

      dateArr.push({
        id: startDate,
        x: startDate,
        y: found?.y || found?.y == 0 ? found.y : null,
      });

      let newDate;
      let groupbyIndicator = element.tiempo[0]?.id;
      if (groupby == 'hour' || groupbyIndicator == 4) {
        newDate = new Date(Utils.dateAdd(startDate, 'minute', 60));
      } else if (groupbyIndicator == 225 || groupbyIndicator == 154) {
        newDate = new Date(Utils.dateAdd(startDate, 'minute', 10));
      } else if (groupbyIndicator == 218) {
        newDate = new Date(Utils.dateAdd(startDate, 'minute', 15));
      } else if (groupbyIndicator == 219) {
        newDate = new Date(Utils.dateAdd(startDate, 'minute', 5));
      }
      startDate = newDate;
    }

    return {
      ...element,
      id: element.id,
      values: dateArr,
    };
  });
  return arr;
};
