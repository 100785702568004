'use strict';
import i18n from 'i18next';

let templateChart21 = () => {
  return `
    <ul>
        <li class="legend-item" id="demanda-programada" data-id="545">
            <div class="item">
            <span
                class="legend-circle"
                style="background-color: #74BA05;"
            ></span>
            <span class="label"
                >${i18n.t('consumption_demanda')}</span
            >
            </div>
            <div class="value is-number">
            <span
                id="demand-evolution-consumption"
                class="caption is-medium"
                ></span
            >
            <span class="unit">GWh</span>
            </div>
        </li>
        <li class="legend-item" id="demanda-prevista" data-id="544">
            <div class="item">
            <span
                class="legend-circle"
                style="background: #FF8000;"
            ></span
            ><span class="label">${i18n.t('b_c_demand')}</span>
            </div>
            <div class="value is-number">
            <span id="demand-evolution-bc" class="caption is-medium"
                ></span
            >
            <span class="unit">GWh</span>
            </div>
        </li>
    </ul>
  `;
};

export { templateChart21 };
