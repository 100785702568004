'use strict';

import i18next from 'i18next';
import moment from 'moment-timezone';
import { DEMAND_COLORS } from '../../shared/enum/demandColors';
import { Utils } from '../../utils/utils';

let gestionDemandaRenderContent = (() => {
  let init = () => {};

  const renderInterruptibleWidget = async (data, currentDate = false) => {
    const { name } = data;
    const indicators = data.indicators;
    const dataLength = indicators[0].values.length;
    const timeID = indicators[0].time;
    const indicatorsId = document.querySelector('#indicadores');
    const graphColors = ['rgb(221, 221, 221)', 'rgb(0, 162, 216)', '#FFC182'];
    const lastDate = [];
    indicators[0].values.length === 0
      ? (indicatorsId.hidden = true)
      : (indicatorsId.hidden = false);
    if (dataLength != 0) {
      document.querySelector('.esios-gd__main-title--int').innerHTML = name;
      const parsedData = indicators
        .sort((a, b) => a.id - b.id)
        .map((el, index) => {
          if (index === 0) {
            el.values.map((date) => {
              if (date.y !== null) {
                lastDate.push(moment(date.x).tz('Europe/Madrid').format());
              }
            });
          }
          if (index === 2) {
            return {
              id: el.id,
              alias: el.alias,
              short_name: el.short_name,
              type: 'constant',
              color: graphColors[index],
              strokeWidth: 2,
              cteAxis: 'y',
              value: el.values.length > 0 ? el.values[0].y : undefined,
            };
          }
          if (index === 1) {
            return {
              id: el.id,
              alias: el.alias,
              short_name: el.short_name,
              type: 'line',
              values: el.values.map((val) => {
                return {
                  ...val,
                  y: val.y === null ? null : val.y / 2,
                };
              }),
              dots: false,
              interpolation: 'linear',
              color: graphColors[index],
              fill: graphColors[index],
              opacity: index === 0 ? 0.5 : 1,
              areaOpacity: index === 0 ? 0.5 : 1,
            };
          }
          return {
            id: el.id,
            alias: el.alias,
            short_name: el.short_name,
            type: 'line',
            values: el.values,
            dots: false,
            interpolation: 'linear',
            color: graphColors[index],
            fill: graphColors[index],
            opacity: index === 0 ? 0.8 : 1,
            areaOpacity: index === 0 ? 0.8 : 1,
          };
        });
      if (
        moment(parsedData[0].values.at(-1).x).format('HH:mm') ==
        moment(parsedData[0].values.at(-2).x).format('HH:mm')
      ) {
        parsedData[0].values.pop();
      }
      if (
        moment(parsedData[1].values.at(-1).x).format('HH:mm') ==
        moment(parsedData[1].values.at(-2).x).format('HH:mm')
      ) {
        parsedData[1].values.pop();
      }
      const testParsedData = [...parsedData];
      const newDataParsed = [
        {
          type: 'area',
          stacking: false,
          data: [parsedData[0], parsedData[1]],
        },
        parsedData[2],
        {
          ...parsedData[0],
          id: parsedData[0].id + 'border',
        },
        {
          ...parsedData[1],
          id: parsedData[1].id + 'border',
        },
      ];

      var options = {
        target: d3.select('#gdDemandaWidgetView .chari-chart').node(),
        margin: '40 40 25 45',
        trail: {
          enabled: true,
          parseStep: function (date) {
            date = moment(date).toDate();
            var minutes = date.getUTCMinutes();
            minutes = Math.floor(minutes / 10) * 10;
            date.setUTCMinutes(minutes, 0, 0);
            return date;
          },
          initXValue: function () {
            return lastDate.at(-1);
          },
        },
        xaxis: {
          scale: 'time',
          ticks: [d3.time.hours, 1],
          bottom: {
            label: i18next.t('hours'),
            tickFormat: function (d, i) {
              var date = moment(d).tz('Europe/Madrid');
              var hour = date.hours();
              if (i % 2) {
                return '';
              } else {
                return hour < 10 ? '0' + hour : hour;
              }
            },
          },
        },
        yaxis: {
          fit: true,
          fullGrid: true,
          ticks: [7],
          left: {
            label: 'MW',
            tickFormat: function (d) {
              if (!d) {
                return;
              }
              return Math.ceil(d * 2).toFixed(0);
            },
          },
          right: {
            label: 'tCO2',
            enabled: true,
            tickFormat: function (d) {
              if (!d) {
                return;
              }

              return Math.ceil((d / 2) * 2).toFixed(0);
            },
          },
        },
      };
      var chart = new Charicharts.Chart(options, newDataParsed);
      document.querySelector('#dmGraphic .trail line').setAttribute('y1', '50');

      let graphInterrumpibilidadLegendsC = document.querySelector(
        '#gdDemandaWidgetView .widget-legend.in-line.visual-legend ul'
      );

      graphInterrumpibilidadLegendsC.innerHTML = '';

      const graphColorsEyes = ['#DDDDDD', '#00A2D8', '#FFC182'];
      testParsedData.map((el, index) => {
        graphInterrumpibilidadLegendsC.innerHTML += `
      <li class="legend-item">
            <div class="item">
              <span
                class="legend-icon-eye icon-eye"
                data-id="${el.id}"
                style="background-color: ${graphColorsEyes[index]};"></span>
              <span class="label"> ${el.alias}</span>
            </div>
        </li>`;
      });
      chart.on('Trail/moved', (parsedData, date) => {
        parsedData = [testParsedData[0], testParsedData[1]];
        const graphTime = document.querySelector(
          '#gdDemandaWidgetView .mod-date'
        );
        graphTime.innerHTML = testParsedData[0].values[0].x
          ? moment(date).tz('Europe/Madrid').format('HH:mm')
          : '00:00';

        testParsedData.map((el) => {
          el.values?.map((val) => {
            if (
              moment(val.x).format('HH:mm') === moment(date).format('HH:mm')
            ) {
              if (el.id === 847) {
                document.querySelector(
                  '.esios-gd__is-number--first .value'
                ).innerHTML =
                  val.y === null ? '-' : Utils.toNumber(val.y * 2, 1, 'comma');
                document.querySelector(
                  '.esios-gd__is-number--first .esios-gd__label'
                ).innerHTML = el.alias;
              }
              if (el.id === 835) {
                document.querySelector(
                  '.esios-gd__is-number--second .value'
                ).innerHTML =
                  val.y === null ? '0' : Utils.toNumber(val.y, 1, 'comma');
                document.querySelector(
                  '.esios-gd__is-number--second .esios-gd__label'
                ).innerHTML = el.alias;
              }
            }
          });
        });
      });
    }
    const rectNoData = document.getElementById('dmNoDataRect');
    const rectNoData2 = document.getElementById('dmNoDataRect2');
    const wid3 = document.getElementById('breakdownGraphic');
    if (dataLength != 0) {
      let moveThis = document.getElementById('area-847border');
      document.querySelector('#gdDemandaWidgetView .series').append(moveThis);
      wid3.classList.remove('no-data-graphic');
      document.querySelector('.no-data-graphic-demand').classList.add('hidden');
      rectNoData.classList.add('hidden');
      rectNoData2.classList.add('hidden');
    } else {
      wid3.classList.add('no-data-graphic');
      document
        .querySelector('.no-data-graphic-demand')
        .classList.remove('hidden');
      rectNoData.classList.remove('hidden');
      rectNoData2.classList.remove('hidden');
      document.querySelector('#chari-chart svg')?.classList.add('hidden');
    }

    const demandaGmain = document.querySelector(
      '#gdDemandaWidgetView svg .g-main'
    );
  };

  const renderPie = (data, selectedOption) => {
    const { indicators, name } = data;
    let pieDataRender;
    if (document.querySelector('.esios-gd__main-title--aside')) {
      document.querySelector('.esios-gd__main-title--aside').innerHTML = name;
    }
    var color = d3.scale.category10();
    if (selectedOption === 'ccaa') {
      pieDataRender = indicators
        .filter((elemnt, index) => {
          if (index === 0) {
            return elemnt;
          }
        })
        .map((el) => {
          return el.values.map((val, ind) => {
            return {
              id: val.geo_id,
              slug: 'CCAA',
              geo_id: val.geo_id,
              geo_name: val.geo_name,
              composited: el.composited,
              alias: val.geo_name,
              name: val.geo_name,
              value: val.y,
              color: DEMAND_COLORS[val.geo_id] || color(val.geo_id),
            };
          });
        })[0];
    } else {
      pieDataRender = indicators
        .filter((elemnt, index) => {
          if (index >= 3) {
            return elemnt;
          }
        })
        .map((el, ind) => {
          return el.values.length > 0
            ? el.values.map((val) => {
                return {
                  id: el.id,
                  slug: 'SECTORS',
                  geo_id: el.id,
                  geo_name: val.geo_name,
                  composited: el.composited,
                  alias: el.alias,
                  name: el.short_name,
                  value: val.y ? val.y : 0,
                  color: DEMAND_COLORS[el.id] || color(el.id),
                };
              })[0]
            : {
                id: '',
                slug: '',
                geo_id: '',
                geo_name: '',
                composited: '',
                alias: '',
                name: '',
                value: 0,
                color: '',
              };
        });
    }

    /** CONFIGURACIÓN BASE PARA CREAR EL PIE */
    let configPie = {
      target: d3.select('#gdDesgloseWidgetView .piePotencia').node(),
      innerArrow: true,
      innerRadius: 0.6,
      hoverFade: 1,
    };

    /** INSTANCIA Y CREACIÓN DEL PIE */
    let pie = new Charicharts.Pie(configPie, pieDataRender);
    /* CREACIÓN DEL TEXTO CENTRAL EN EL PIE  */
    const piePotenciaHtml = document.querySelector(
      '#gdDesgloseWidgetView .piePotencia'
    );
    piePotenciaHtml.insertAdjacentHTML(
      'beforeend',
      ` 
      <div class="chartLegend">
          <h3 class="totalTitlePie text"></h3>
          <div class="totalValuePie"></div>
        <h3 class="titlePie titlePie--gd text"></h3>
        <div class="valuePie">
        </div>
      </div>`
    );
    /** OBTENEMOS LOS ELEMENTOS DEL TITULO Y EL VALOR PARA ACTUALZIARLOS CADA VEZ QUE REALIZEMOS UN HOVER POR EL PIE */
    let pieTitleHtml = document.querySelector(
      '#gdDesgloseWidgetView .piePotencia>.chartLegend>.titlePie'
    );
    let pieValueHtml = document.querySelector(
      '#gdDesgloseWidgetView .piePotencia>.chartLegend>.valuePie'
    );
    let pieTotalTitleHtml = document.querySelector(
      '#gdDesgloseWidgetView .piePotencia>.chartLegend>.totalTitlePie'
    );
    let pieTotalValueHtml = document.querySelector(
      '#gdDesgloseWidgetView .piePotencia>.chartLegend>.totalValuePie'
    );
    let sumSectors = 0;
    pieDataRender.map((el) => {
      sumSectors += el.value;
    });
    if (selectedOption === 'ccaa') {
      pieTotalTitleHtml.innerHTML = i18next.t('ccaa_total');
      pieTotalValueHtml.innerHTML = `${Utils.toNumber(
        sumSectors,
        1,
        'comma'
      )}<span class="unit">MW</span>`;
      pieTitleHtml.innerHTML = pieDataRender[0]?.geo_name || '';
      pieValueHtml.innerHTML = `${Utils.toNumber(
        sumSectors,
        1,
        'comma'
      )} <span class="unit">MW</span>`;
    } else {
      pieTotalTitleHtml.innerHTML = i18next.t('sectors_total');
      pieTotalValueHtml.innerHTML = `${Utils.toNumber(
        sumSectors,
        1,
        'comma'
      )} <span class="unit">MW</span>`;
      hoverPie(pieTitleHtml, pieValueHtml, pie);
    }
    /**CREACIÓN DE LEYENDA */
    let ulLegend = d3
      .select('#gdDesgloseWidgetView .legend-potencial-pie')
      .append('ul')
      .attr('class', 'legend')
      .style('margin-top', '30px');

    let keys = ulLegend
      .selectAll('.key')
      .data(pieDataRender)
      .enter()
      .append('li')
      .attr('class', 'legend-item');

    keys
      .html((item, index) =>
        item.value !== 0
          ? `
          <div class="text-content">
            <span class="legend-circle" 
              style="background: ${DEMAND_COLORS[item.id] || color(item.id)};">
            </span>
            ${item.name}
          </div>
          <div class="value is-number">
              ${Utils.toNumber(item.value, 1, 'comma')}
              <span class="unit">MW</span>
          </div>`
          : ``
      )
      .on('mouseover', function (d) {
        if (pie.innerArrow) {
          pie.innerArrow.moveTo(d.id);
        }
      });
    var aspect = 100 / 100,
      chart = d3.select('#gdDesgloseWidgetView .piePotencia');

    d3.select(window).on('resize', function () {
      var targetWidth = chart.node().getBoundingClientRect().width;
      chart.attr('width', targetWidth);
      chart.attr('height', targetWidth / aspect);
    });
    const dataLength = indicators[0]?.values?.length;
    if (dataLength == 0 || dataLength == undefined) {
      document
        .querySelector('#breakdownGraphic .chartLegend')
        .classList.add('hidden');
      document
        .querySelector('#gdDesgloseWidgetView .dibujoDemanda svg')
        ?.remove();
    } else {
      document
        .querySelector('#breakdownGraphic .chartLegend')
        .classList.remove('hidden');
    }
  };

  const hoverPie = (title, value, pie) => {
    /** FUNCION PORPIA DEL PIE PARA REALIZAR EL HOVER Y ACTUALIZAR EL TEXTO CENTRAL */
    pie.on('Pie-piece/mouseover', function (info) {
      title.innerText = info.data.name;
      value.innerHTML = `
      ${Utils.toNumber(info.value.toFixed(1), 1)}
       <span class="unit">MW</span>
      `;
    });
  };

  const renderTabsWidget = async (data) => {
    const { name } = data;
    document.querySelector('.esios-gd__main-title--tabs').innerHTML = name;
  };

  const renderSNPWidget = async (data) => {
    const { name } = data;
    document.querySelector('.snp-interrumptible-title').innerHTML = name;
  };

  return {
    init: init,
    renderInterruptibleWidget: (data, currentDate) =>
      renderInterruptibleWidget(data, currentDate),
    renderPie: (data, selectedOption) => renderPie(data, selectedOption),
    renderTabsWidget: (data) => renderTabsWidget(data),
    renderSNPWidget: (data) => renderSNPWidget(data),
  };
})();

export { gestionDemandaRenderContent };
