'use strict';

/**
 * @description Header Component
 * @returns {Object} Devuelve la instancia del componente
 */

import i18n from 'i18next';

let datepickerComponent = (function () {
  let renderSuccess = false;

  let duplicateSelects;
  let selectHandler;
  let optionsHandler1;
  let optionsHandler2;

  let select1;
  let select2;
  let idDatePicker;

  let preRenderComponent = () => {};

  let renderComponent = (numCalendar = '') => {
    idDatePicker = 'datepicker' + numCalendar;
    $('#' + idDatePicker).datepicker({
      firstDay: 1,
      dateFormat: 'dd / mm / yy',
      altFormat: 'dd / mm / yy',
      monthNames: [
        i18n.t('january'),
        i18n.t('february'),
        i18n.t('march'),
        i18n.t('april'),
        i18n.t('ma'),
        i18n.t('june'),
        i18n.t('july'),
        i18n.t('august'),
        i18n.t('september'),
        i18n.t('october'),
        i18n.t('november'),
        i18n.t('december'),
      ],
      monthNamesShort: [
        i18n.t('jan'),
        i18n.t('feb'),
        i18n.t('mar'),
        i18n.t('apr'),
        i18n.t('may'),
        i18n.t('june'),
        i18n.t('july'),
        i18n.t('aug'),
        i18n.t('sep'),
        i18n.t('oct'),
        i18n.t('nov'),
        i18n.t('dec'),
      ],
      dayNames: [
        i18n.t('sunday'),
        i18n.t('monday'),
        i18n.t('tuesday'),
        i18n.t('wednesday'),
        i18n.t('thursday'),
        i18n.t('friday'),
        i18n.t('saturday'),
      ],
      dayNamesShort: [
        i18n.t('sun'),
        i18n.t('mon'),
        i18n.t('tue'),
        i18n.t('wed'),
        i18n.t('thu'),
        i18n.t('fri'),
        i18n.t('sat'),
      ],
      dayNamesMin: [
        i18n.t('Su'),
        i18n.t('M'),
        i18n.t('T'),
        i18n.t('W'),
        i18n.t('Th'),
        i18n.t('F'),
        i18n.t('S'),
      ],
      weekHeader: i18n.t('week'),
      autoSize: true,
      constrainInput: true,
      maxDate: null,
      changeMonth: true,
      changeYear: true,
      showButtonPanel: true,
      todayBtn: true,
      currentText: i18n.t('today'),
      beforeShow: function (input, inst) {
        var height = $(input).height();
        var offset;
        document.getElementById('ui-datepicker-div').dataset.calendar =
          numCalendar;

        if ($(input).parent().hasClass('its-parent-is-fixed')) {
          offset = {
            top: $(input).height(),
            left: $(input).offset().left,
          };
        } else {
          offset = $(input).offset();
        }

        window.setTimeout(function () {
          const docWid = $(document).width();
          const inpWid = $(input).width();
          const difference = docWid - offset.left;
          const calenWid = inst.dpDiv.width();

          //Creamos un SPAN para el triángulo del input
          $('<span>', {
            id: 'triangle',
            class: 'ui-datepicker__triangle',
            title: '',
          }).appendTo('#ui-datepicker-div');

          //Si el input está desde la mitad del documento a la derecha
          if (offset.left >= docWid / 2) {
            inst.dpDiv.css({
              left: docWid - (difference - inpWid + calenWid) + 'px',
              top: offset.top + height + 4 + 'px',
            });

            $('#triangle').css({
              position: 'absolute',
              height: '8px',
              width: '32px',
              'background-color': 'color(white)',
              top: 0 + 'px',
              left: calenWid - inpWid / 2 + 'px',
            });

            //Si el input está desde la mitad del documento a la izquierda o centrado
          } else {
            inst.dpDiv.css({
              left: docWid - difference + 'px',
              top: offset.top + height + 4 + 'px',
            });

            $('#triangle').css({
              position: 'absolute',
              height: '8px',
              width: '32px',
              'background-color': 'color(white)',
              top: 0 + 'px',
              right: calenWid - inpWid / 2 + 'px',
            });
          }
        }, 1);

        $(input).parent('.mod-date').addClass('is-active');
      },
      onClose: function () {
        //Disparamos evento change manualmente (corresponde con eventlistener al input en la pagina)
        const inp = document.querySelector('#' + idDatePicker);
        if ('createEvent' in document) {
          var event = document.createEvent('HTMLEvents');
          event.initEvent('change', false, true); // onchange event
          inp.dispatchEvent(event);
        } else {
          inp.fireEvent('change'); // only for backward compatibility (older browsers)
        }
        localStorage.setItem('bread-crumbs-date', inp.value);
        $(this).parent('.mod-date').removeClass('is-active');
      },
    });
    document.getElementById(idDatePicker)?.addEventListener('click', () => {
      idDatePicker = 'datepicker' + numCalendar;
      let calendar = document.getElementById(idDatePicker);
      let topModDate = calendar.getBoundingClientRect().top + 40;
      let topModDateDoc =
        calendar.getBoundingClientRect().top +
        document.documentElement.scrollTop +
        40;
      if (
        (calendar && calendar.classList.contains('datepicker-abs')) ||
        (calendar && window.innerWidth > 540)
      ) {
        $('#ui-datepicker-div').css({
          position: 'absolute',
          top: topModDateDoc + 'px',
        });
      } else if (calendar) {
        $('#ui-datepicker-div').css({
          position: 'fixed',
          top: topModDate + 'px',
        });
      }
      $('#datepicker').datepicker('show');
    });

    document.querySelector('#' + idDatePicker).classList.add('hasDatePicker');
    // Call Events
    if (!document.getElementById('ui-datepicker-div').dataset.events) {
      document.getElementById('ui-datepicker-div').dataset.events = true;
      eventsCall();
    }
  };

  let postRenderComponent = () => {};
  const eventsCall = () => {
    select1 = document.querySelector('.ui-datepicker-month');
    select2 = document.querySelector('.ui-datepicker-year');
    document.addEventListener(
      'click',
      function (event) {
        if (event.target.matches('.hasDatepicker')) {
          document.getElementById('ui-datepicker-div').dataset.current =
            event.target.id;
        }
        const id = document.getElementById('ui-datepicker-div').dataset.current;
        // Event click today
        if (event.target.matches('.ui-datepicker-current')) {
          $('#' + id).datepicker('setDate', new Date());
          $('#' + id).datepicker('hide');
        }
        // Events Selector
        else if (event.target.matches('.ui-datepicker-month')) {
          selectHandler('#selBGU_chzn', '#selMQT_chzn');
        } else if (event.target.matches('.ui-datepicker-year')) {
          selectHandler('#selMQT_chzn', '#selBGU_chzn');
        }

        // Event options
        else if (event.target.matches('#selBGU_chzn li')) {
          optionsHandler1(event.target, id);
        } else if (event.target.matches('#selMQT_chzn li')) {
          optionsHandler2(event.target, id);
        } else if (
          event.target.matches('.ui-datepicker-prev') ||
          event.target.matches('.ui-datepicker-next')
        ) {
        }
        // Event click outside selector
        else if (event.target != select1 && event.target != select2) {
          $('#selBGU_chzn').css('display', 'none');
          $('#selMQT_chzn').css('display', 'none');
        }
      },
      false
    );
  };

  selectHandler = (selectorFirst, selectorSecond) => {
    $(selectorFirst).css('display') == 'none'
      ? $(selectorFirst).css('display', 'block')
      : $(selectorFirst).css('display', 'none');

    $(selectorSecond).css('display', 'none');
  };

  optionsHandler1 = (elem, id) => {
    //Añadimos clase result-selected a opción del ul, y se la quitamos al resto
    $('#ul1 li').each(function () {
      $(this).removeClass('result-selected');
    });

    elem.classList.add('result-selected');

    //Ponemos la opción selected en select
    $('option', $('.ui-datepicker-month')).each(function (index, element) {
      element.value == elem.value
        ? element.setAttribute('selected', 'selected')
        : element.removeAttribute('selected');
      $('.ui-datepicker-month').value = elem.value;
    });

    $('#selBGU_chzn').css('display', 'none');
    $('#' + id).datepicker(
      'setDate',
      new Date(
        $('.ui-datepicker-year').val(),
        $('.ui-datepicker-month').val(),
        1
      )
    );
    duplicateSelects();
  };

  optionsHandler2 = (elem, id) => {
    //Añadimos clase result-selected a opción del ul, y se la quitamos al resto
    $('#ul2 li').each(function () {
      $(this).removeClass('result-selected');
    });
    elem.classList.add('result-selected');

    //Ponemos la opción selected en select
    $('option', $('.ui-datepicker-year')).each(function (index, element) {
      element.value == elem.value
        ? element.setAttribute('selected', 'selected')
        : element.removeAttribute('selected');

      $('.ui-datepicker-year').value = elem.value;
    });
    $('#selMQT_chzn').css('display', 'none');

    $('#' + id).datepicker(
      'setDate',
      new Date(
        $('.ui-datepicker-year').val(),
        $('.ui-datepicker-month').val(),
        1
      )
    );
    duplicateSelects();
  };

  duplicateSelects = () => {
    if (!document.getElementById('selBGU_chzn')) {
      //DUPLICATE SELECT OPTIONS
      select1 = document.querySelector('.ui-datepicker-month');
      select2 = document.querySelector('.ui-datepicker-year');
      //CREAMOS DIVS Y UL
      $('<div>', {
        id: 'selBGU_chzn',
        class: 'chzn-container chzn-container-single',
        title: '',
        style: 'display: none;', //<=======
      }).appendTo('.ui-datepicker-title');

      $('<ul>', {
        id: 'ul1',
        class: 'chzn-results',
        title: '',
      }).appendTo('#selBGU_chzn');

      $('<div>', {
        id: 'selMQT_chzn',
        class: 'chzn-container chzn-container-single',
        title: '',
        style: 'display: none;', //<=======
      }).appendTo('.ui-datepicker-title');

      $('<ul>', {
        id: 'ul2',
        class: 'chzn-results',
        title: '',
      }).appendTo('#selMQT_chzn');

      //CREAMOS LI, OCULTAMOS OPCIONES DEL SELECT ORIGINAL
      $('option', select1).each(function (index, element) {
        element.hidden = true;

        let classLI = 'active-result';

        if (element.selected == true) {
          classLI = 'active-result result-selected';
        }

        $('<li>', {
          id: 'selJL0_chzn_o_' + index,
          class: classLI,
          title: '',
          value: element.value,
          text: element.text,
        }).appendTo('#ul1');
      });

      $('option', select2).each(function (index, element) {
        element.hidden = true;

        let classLI = 'active-result';

        if (element.selected == true) {
          classLI = 'active-result result-selected';
        }

        $('<li>', {
          id: 'sel4GB_chzn_o_' + index,
          class: classLI,
          title: '',
          value: element.value,
          text: element.text,
        }).appendTo('#ul2');
      });
    }
  };

  let init = (numCalendar) => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent(numCalendar);

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: (numCalendar) => init(numCalendar),
  };
})();

export { datepickerComponent };
