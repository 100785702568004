export const getId = () => {
  return (Math.random() + 1).toString(36).substring(7);
};

export const isEmpty = (obj = {}) => {
  return Object.keys(obj).length === 0;
};

export const pushNew = (list, elem) => {
  if (!list.includes(elem)) {
    list.push(elem);
  }
  return list;
};

export const removeRepeated = (list) => {
  const result = [];
  for (let i = 0; i < list.length; i++) {
    pushNew(result, list[i]);
  }
  return result;
};

export const noAccutes = (str) => {
  return str?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const sanitizeNTilde = (str) => str.split('?').join('ñ');
