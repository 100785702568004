'use strict';
import i18n from 'i18next';
import moment from 'moment-timezone';
import { store } from '../../app.init.js';
import { getColour } from '../../shared/components/chart/chart-helpers.js';
import { templateShareModal } from '../../shared/components/modal/modalShare.template.js';
import { componentSelects } from '../../shared/components/selects/selects.component.js';
import { componentToolbar } from '../../shared/components/toolbar/toolbar.component.js';
import { dateSetTime } from '../../utils/dateFormat.js';
import { getUrlParams } from '../../utils/url.js';
import { Utils } from '../../utils/utils.js';
import {
  calculateHoursPerDay,
  getArrayOfHoursForBalance,
  renderBalanceGraph,
} from '../balance/balance.component.js';
import { gestionDemandaService } from '../gestion-demanda/gestionDemanda.service.js';
import { componentTab } from '../tab/tab.component.js';
import { servicesSaldoFronteras } from './saldoFronteras.services.js';
import { templateSaldoFronteras } from './saldoFronteras.template.js';
import { saldoFronterasContent } from './saldoFronterasContent.js';

let componentSaldoFronteras = (function () {
  let dateInherited = dateSetTime();
  dateInherited = dateInherited.split('T');
  let currentDate = dateInherited[0];
  console.log = () => {};
  console.warn = () => {};

  let handlers = {
    handleSelect: (e) => handleSelect(e),
  };
  let tableIdValues = [
    {
      label: 'france',
      value: 0,
    },
    {
      label: 'portugal',
      value: 1,
    },
    {
      label: 'morocco',
      value: 2,
    },
    {
      label: 'andorra',
      value: 3,
    },
  ];
  let date;
  let objToolbar, auctionsData, auctionsPortugalData;
  let tabs,
    selectedCountry = 0;
  const selectMobileSelector = {
    id: 'mobile__selector_id',
    currentLabel: '0-1 H',
    currentValue: '0',
    label: '',
    options: getArrayOfHoursForBalance('hour'),
    columns: 1,
    onlySelect: true,
    changeTime: (value) => changeTimeFromSelector(value),
  };
  let filterForm = {
    ambit: '',
    category: '',
    process: '',
    action: '',
    query: '',
  };

  const changeTimeFromSelector = (value) => {
    const { id, newValue } = value;

    if (selectMobileSelector.id === id) {
      selectMobileSelector.options.map((el) => {
        if (el.value === newValue) {
          selectMobileSelector.currentLabel = el.label;
          selectMobileSelector.currentValue = el.value;
          let tableWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
          if (tableWidth <= 540) {
            renderColumnSelected(el.value, 'hour');
          }

          componentSelects.render(selectMobileSelector, '#mobile__selector');
        }
      });
    }
  };
  let handleSelect = (e) => {
    document.querySelectorAll('#widget41 .col-9').forEach((el) => {
      el.style.display = 'none';
    });
    document.querySelector('#widget41 .chart-' + e.value).style.display =
      'block';
    document.querySelectorAll('#widget41 .multi-bar').forEach((el) => {
      el.classList.add('hidden');
    });
    document.querySelectorAll('#widget41 .legends').forEach((el) => {
      el.classList.add('hidden');
    });

    showTable(e.value);
    let tableWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (tableWidth <= 540) {
      renderColumnSelected(0, 'hour');
    }

    let graph = document.querySelector(
      `#widget41 #area-graph-${parseInt(e.value)}`
    );
    let legends = document.querySelector(
      `#widget41 #area-graph-${parseInt(e.value)}-legends`
    );
    graph.classList.remove('hidden');
    legends.classList.remove('hidden');
  };
  let setInfoModal = (id, data, html, updateShareModal = 'all') => {
    if (updateShareModal !== 'share') {
      objToolbar[id].textModal = `<h2>${data.name}</h2>${data.description}`;
    }
    objToolbar[id].widgetData = data;
    objToolbar[id].shareModal = html;
    componentToolbar.render(objToolbar[id], '.esios-toolbar' + (id + 1), false);
  };

  let renderComponent = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    document.title = `${
      i18n.t('saldo-frontera').charAt(0).toUpperCase() +
      i18n.t('saldo-frontera').slice(1)
    } | ${i18n.t('page_subtitle')}`;
    const setParameter = (key, value) => {
      urlParams.set(key, value);
      history.pushState({}, '', window.location.pathname + '?' + urlParams);
    };

    const changeDate = async () => {
      date = $('#datepicker').val();
      date = moment(date, 'DD/MM/YYYY').format('DD-MM-YYYY');
      setParameter('date', date);
      await renderSPMultipleGraph();
      let tableWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (tableWidth <= 540) {
        renderColumnSelected(0, 'hour');
      }
    };

    const showOrHidden = (id, displayType) => {
      if (document.getElementById(id)) {
        document.getElementById(id).style.display = displayType;
      }
    };

    const showNoData = (data, idContainer, idRect) => {
      if (
        data.indicators.every(
          (indicator) =>
            indicator.print_values?.length == 0 ||
            indicator.print_values == null
        )
      ) {
        showOrHidden(idRect, 'block');
        document
          .getElementById(idContainer)
          .classList.add('esios-intercambios__no-data-graphic');
        if (idContainer == 'map45') {
          showOrHidden('geo-legends', 'none');
        }
      } else {
        showOrHidden(idRect, 'none');
        document
          .getElementById(idContainer)
          .classList.remove('esios-intercambios__no-data-graphic');
      }
    };

    if (!urlParams.get('date')) {
      setParameter('date', currentDate);
    }

    try {
      const state = store.getState();
      const lang = state.exampleReducer.currentLang;

      let buttons = [
        {
          label: 'see_more_doc',
          url: `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.documentation'
          )}?scope=${i18n.t('exchanges_i').replaceAll(' ', '+')}`,
        },
        {
          label: 'downloads_files',
          url: `/${i18n.t('routes.lng')}/${i18n.t('routes.downloads')}`,
        },
      ];

      let documents = [
        {
          title: 'exchange_of_information_with_the_OS',
          link: `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.documentation'
          )}/intercambio-de-informacion-con-el-os-liquidaciones`,
        },
        {
          title: 'resolution_of_technical_restrictions',
          link: `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.documentation'
          )}/p-o-3-2-resolucion-de-restricciones-tecnicas`,
        },
        {
          title: 'resolution_of_tertiary_regulation',
          link: `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.documentation'
          )}/p-o-7-3-regulacion-terciaria`,
        },
        {
          title: 'royal_decree',
          link: `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.documentation'
          )}/real-decreto-216-2014-metodologia-calculo-precios-voluntarios-pequeno-consumidor`,
        },
      ];

      let listMarket = [
        {
          type: 'title',
          id: 2,
          title: 'structural_data',
        },
        {
          type: 'list',
          id: 1,
          text: 'participants_auction',
          url: `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.participants_auction'
          )}`,
        },
        {
          type: 'list',
          id: 2,
          text: 'programming_units',
          url: `/${i18n.t('routes.lng')}/${i18n.t('routes.programming_units')}`,
        },
        {
          type: 'list',
          id: 3,
          text: 'physical_units',
          url: `/${i18n.t('routes.lng')}/${i18n.t('routes.physical_units')}`,
        },
        {
          type: 'list',
          id: 4,
          text: 'market_subjects',
          url: `/${i18n.t('routes.lng')}/${i18n.t('routes.market_subjects')}`,
        },
      ];

      let renderComponentDocumentList = async (form) => {
        filterForm.ambit = form.ambit;
        filterForm.action = form.actions;
        filterForm.category = form.category;
        filterForm.process = form.process;
        filterForm.query = form.query;
        let documentListData = await gestionDemandaService.getArchives(
          filterForm
        );

        let newDLArray = {
          data: documentListData.newIndicators,
          renderAgain: (form) => renderComponentDocumentList(form),
        };
        // componentDocumentList.render(newDLArray, '#document-list-ii');
      };

      await templateSaldoFronteras(handlers, buttons, documents, listMarket);

      let newDLArray = {
        renderAgain: (form) => renderComponentDocumentList(form),
      };
      // componentDocumentList.render(newDLArray, '#document-list-ii');
      document
        .querySelector('.esios-layout__wrap')
        .classList.add('esios-layout__wrap--intercambios');
      document
        .querySelector('.esios-layout__breadcrumbs__wrapper')
        .classList.add('layout__breadcrumbs__wrapper--intercambios');
      document
        .querySelector('.esios-layout__breadcrumbs')
        .classList.add('esios-layout__breadcrumbs--intercambios');
      $(document).on('change', '#datepicker', () => {
        selectMobileSelector.currentLabel = '0-1 h';
        selectMobileSelector.currentValue = 0;
        changeDate();
      });

      if (urlParams.get('date')) {
        date = urlParams.get('date');
        if (document.getElementById('datepicker')) {
          document.getElementById('datepicker').value = moment(
            date,
            'DD-MM-YYYY'
          ).format('DD / MM / YYYY');
        }
      } else {
        date = moment().tz('Europe/Madrid').format('DD-MM-YYYY');
      }
      objToolbar = [
        {
          id: 1,
          slug: i18n.t('routes.saldo-frontera'),
          info: true,
          share: true,
          favorite: true,
          favId: 41,
          analysis: true,
          url: () =>
            `/${i18n.t('routes.lng')}/${i18n.t(
              'routes.analysis'
            )}/10026?start_date=${date}T00:00&geoids=`,
        },
      ];
      // )}/490?compare_indicators=491,488,489,492,493,494,495,1105,1106,28,29,30,31,32,33,34,35,1429,1428,1427,1434,1433,1432,1431,1430,10278,10277,10276,10275,10107,10110,10112,10115,10117,10120,10125,10127,10130,10136,10138,10109,10111,10116,10118,10119,10121,10106,10108,10124,10126,10128,10129,10133,10134,10137,10139,10140,536,535,539,540,566,567,564,565,10026&start_date=${date}T00:00&geoids=`,
      componentToolbar.render(objToolbar[0], '.esios-toolbar1', false);
      await renderSPMultipleGraph();

      let tableWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (tableWidth <= 540) {
        renderColumnSelected(0, 'hour');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderSPMultipleGraph = async () => {
    document.getElementById('spinner41').style.display = 'flex';
    document.getElementById('spinner41table').style.display = 'flex';
    let selectedVal = document.querySelector('.esios-select span').textContent;
    let tableID = tableIdValues.filter(
      (el) => i18n.t(el.label) == selectedVal
    )[0].value;

    await servicesSaldoFronteras
      .getDataFromWidget({
        id: 41,
        date,
      })
      .then(async (data) => {
        let dataFrancia = [],
          dataPortugal = [],
          dataMarruecos = [],
          dataAndorra = [];
        let opts = saldoFronterasContent.optsForMultipleGraph(date);
        let { dFrancia, dPortugal, dMarruecos, dAndorra } =
          processPreData(data);
        dataFrancia = dFrancia;
        dataPortugal = dPortugal;
        dataMarruecos = dMarruecos;
        dataAndorra = dAndorra;

        let finalArray = proccessData(
          dataFrancia,
          dataPortugal,
          dataMarruecos,
          dataAndorra
        );

        setInfoModal(
          0,
          data,
          templateShareModal('saldo-frontera', window.location.search),
          'all'
        );
        // hide data
        if (data.indicators[0].values.length === 0) {
          document.getElementById('char41').style.display = 'flex';
          document
            .querySelector('.esios-balance__row-table')
            .classList.add('hidden');
          document
            .querySelector('.esios-intercambios__row')
            .classList.add('hidden');
        } else {
          document.getElementById('char41').style.display = 'none';
          document
            .querySelector('.esios-intercambios__row')
            .classList.remove('hidden');
          document
            .querySelector('.esios-balance__row-table')
            .classList.remove('hidden');
          opts.forEach((opt, index) => {
            document.querySelector(`${opt.elem}-legends`).innerHTML = `
            ${opt.legends
              .map(
                (el) =>
                  `<div class="legend">
                <span
                  class="${el.type}"
                  style="background: ${el.color}">
                </span>
                ${i18n.t(`${el.name}`)}
              </div>`
              )
              .join('')}
            `;
            if (index == 0) {
              let orderArr = [
                1106,
                10104,
                'intraday',
                536,
                565,
                2109,
                2110,
                488,
                492,
                // , 10026
              ];

              finalArray[index] = Utils.orderObjectsArrayByKey(
                finalArray[index],
                orderArr,
                'id'
              );
            } else if (index == 1) {
              let orderArr = [
                10113,
                'intraday',
                540,
                567,
                2111,
                2112,
                489,
                493,
                // 10026,
              ];

              finalArray[index] = Utils.orderObjectsArrayByKey(
                finalArray[index],
                orderArr,
                'id'
              );
            } else if (index == 2) {
              let orderArr = [
                10122,
                'intraday',
                2113,
                2114,
                490,
                494,
                // 10026
              ];

              finalArray[index] = Utils.orderObjectsArrayByKey(
                finalArray[index],
                orderArr,
                'id'
              );
            } else if (index == 3) {
              let orderArr = [
                10131,
                'intraday',
                2115,
                2116,
                491,
                495,
                // 10026
              ];

              finalArray[index] = Utils.orderObjectsArrayByKey(
                finalArray[index],
                orderArr,
                'id'
              );
            }
            console.log(finalArray[index]);
            renderBalanceGraph(finalArray[index], i18n.t('hour'), opt);
            let renderTabFunc;
            finalArray[index] = finalArray[index].map((el) => {
              if (el?.area1) {
                return {
                  ...el,
                  values: el.values.map((val) => {
                    return {
                      ...val,
                      y: val.y * -1,
                      value: val.value * -1,
                    };
                  }),
                };
              } else {
                return el;
              }
            });
            // if (index == 0) {
            //   let orderArr = [
            //     1106,
            //     10104,
            //     'intraday',
            //     536,
            //     565,
            //     488,
            //     492,
            //     // , 10026
            //   ];

            //   finalArray[index] = Utils.orderObjectsArrayByKey(
            //     finalArray[index],
            //     orderArr,
            //     'id'
            //   );
            // } else if (index == 1) {
            //   let orderArr = [
            //     10113,
            //     'intraday',
            //     540,
            //     567,
            //     489,
            //     493,
            //     // 10026,
            //   ];

            //   finalArray[index] = Utils.orderObjectsArrayByKey(
            //     finalArray[index],
            //     orderArr,
            //     'id'
            //   );
            // } else if (index == 2) {
            //   let orderArr = [
            //     10122,
            //     'intraday',
            //     490,
            //     494,
            //     // 10026
            //   ];

            //   finalArray[index] = Utils.orderObjectsArrayByKey(
            //     finalArray[index],
            //     orderArr,
            //     'id'
            //   );
            // } else if (index == 3) {
            //   let orderArr = [
            //     10131,
            //     'intraday',
            //     491,
            //     495,
            //     // 10026
            //   ];

            //   finalArray[index] = Utils.orderObjectsArrayByKey(
            //     finalArray[index],
            //     orderArr,
            //     'id'
            //   );
            // }
            finalArray[index] = finalArray[index].map((el, index) => {
              return {
                ...el,
                opts: opt.legends[index],
              };
            });
            // finalArray[index] = finalArray[index].filter(el => el.id !== 10026);
            renderTabFunc = updateTable(finalArray[index]);
            componentTab.render(renderTabFunc, `#tabwithcontent${index}`);
          });
        }

        document.getElementById('spinner41').style.display = 'none';
        document.getElementById('spinner41table').style.display = 'none';
        showTable(tableID);
        componentSelects.render(selectMobileSelector, '#mobile__selector');
      })
      .catch((error) => {
        document.getElementById('char41').style.display = 'flex';
        document.getElementById('spinner41').style.display = 'none';
        document.getElementById('spinner41table').style.display = 'none';
        document
          .querySelector('.esios-balance__row-table')
          .classList.add('hidden');
        document
          .querySelector('.esios-intercambios__row')
          .classList.add('hidden');
      });
  };

  return {
    render: renderComponent,
  };
})();

export { componentSaldoFronteras };

export const renderColumnSelected = (selected, agg) => {
  let tableIdValues = [
    {
      label: 'france',
      value: 0,
    },
    {
      label: 'portugal',
      value: 1,
    },
    {
      label: 'morocco',
      value: 2,
    },
    {
      label: 'andorra',
      value: 3,
    },
  ];
  let selectedFor25h = selected;
  selected = parseInt(selected);
  let select = selected;
  let selectedVal = document.querySelector('.esios-select span').textContent;
  let tableID = tableIdValues.filter((el) => i18n.t(el.label) == selectedVal)[0]
    .value;

  document.querySelectorAll('.ui-tabs-panel').forEach((e) => {
    e.style.display = 'none';
  });
  if (agg === 'hour') {
    document.querySelector('.selector-title').innerText = i18n
      .t('hour')
      .toUpperCase();

    let { date } = getUrlParams();
    if (!date) {
      date = moment().format('DD-MM-YYYY');
    }
    let numberHours = calculateHoursPerDay(moment(date, 'DD-MM-YYYY'));
    if (numberHours == 23 && selected > 4) {
      select = select + 1;
    }
    if (
      (numberHours == 25 && selectedFor25h == '2b') ||
      (numberHours == 25 && selected > 2)
    ) {
      selected = selected + 1;
    }
    if (select <= 5) {
      document.querySelector(
        `#tabwithcontent${tableID} #table-0`
      ).style.display = 'block';
    } else if (select > 5 && select <= 11) {
      selected = select - 6;
      document.querySelector(
        `#tabwithcontent${tableID} #table-1`
      ).style.display = 'block';
    } else if (select > 11 && select <= 17) {
      selected = select - 12;
      document.querySelector(
        `#tabwithcontent${tableID} #table-2`
      ).style.display = 'block';
    } else if (select > 17 && select <= 23) {
      selected = select - 18;
      document.querySelector(
        `#tabwithcontent${tableID} #table-3`
      ).style.display = 'block';
    }
  } else if (agg === 'day') {
    document.querySelector('.selector-title').innerText = i18n
      .t('day')
      .toUpperCase();

    let { date } = getUrlParams();
    if (!date) {
      date = moment().format('DD-MM-YYYY');
    }
    let numberOfDays = moment(date, 'DD-MM-YYYY').daysInMonth();
    if (select <= 7) {
      document.querySelector(
        `#tabwithcontent${tableID} #table-0`
      ).style.display = 'block';
    } else if (select > 7 && select <= 15) {
      selected = select - 8;
      document.querySelector(
        `#tabwithcontent${tableID} #table-1`
      ).style.display = 'block';
    } else if (select > 15 && select <= 23) {
      selected = select - 16;
      document.querySelector(
        `#tabwithcontent${tableID} #table-2`
      ).style.display = 'block';
    } else if (select > 23 && select <= numberOfDays) {
      selected = select - 24;
      document.querySelector(
        `#tabwithcontent${tableID} #table-3`
      ).style.display = 'block';
    }
  } else if (agg === 'month') {
    document.querySelector('.selector-title').innerText = i18n
      .t('month')
      .toUpperCase();

    let { date } = getUrlParams();
    if (!date) {
      date = moment().format('DD-MM-YYYY');
    }
    if (select <= 5) {
      document.querySelector(
        `#tabwithcontent${tableID} #table-0`
      ).style.display = 'block';
    } else if (select > 5 && select <= 12) {
      selected = select - 6;
      document.querySelector(
        `#tabwithcontent${tableID} #table-1`
      ).style.display = 'block';
    }
  }
  document.querySelectorAll('table .column-with-data').forEach((e) => {
    e.style.display = 'none';
  });
  document.querySelectorAll('.coltable-' + selected).forEach((e) => {
    e.style.display = 'initial';
    e.classList.add('val_numbers');
  });
};

export const fillSimpleVals = (rangeNumber) => {
  let arr = [];
  for (let i = 0; i < rangeNumber; i++) {
    arr.push({
      x: i,
      order: i,
      y: null,
      value: null,
      color: '#333333',
      name: '',
    });
  }
  return arr;
};

export const showTable = (val) => {
  [0, 1, 2, 3].forEach((el) => {
    document.getElementById(`tabwithcontent${el}`).classList.add('hidden');
  });

  document.getElementById(`tabwithcontent${val}`).classList.remove('hidden');
  document.querySelectorAll(`#tabwithcontent${val} .mod-tabs ul a`)[0]?.click();
};

export const updateTable = (dataElement) => {
  let firstHours = ['0-1', '1-2', '2-3', '3-4', '4-5', '5-6'];
  let secondHours = ['6-7', '7-8', '8-9', '9-10', '10-11', '11-12'];
  let thirdHours = ['12-13', '13-14', '14-15', '15-16', '16-17', '17-18'];
  let fourthHours = ['18-19', '19-20', '20-21', '21-22', '22-23', '23-24'];
  let firstHoursTemp = [0, 1, 2, 3, 4, 5];
  let secondHoursTemp = [6, 7, 8, 9, 10, 11];
  let thirdHoursTemp = [12, 13, 14, 15, 16, 17];
  let fourtHoursTemp = [18, 19, 20, 21, 22, 23];
  let date = getUrlParams()?.date
    ? getUrlParams()?.date
    : moment().tz('Europe/Madrid').format('DD-MM-YYYY');

  let val23h = calculateHoursPerDay(date);
  if (val23h === 23) {
    firstHours = ['0-1', '1-2', '3-4', '4-5', '5-6'];
    firstHoursTemp = [0, 1, 3, 4, 5];
  }
  if (val23h === 25) {
    firstHours = ['0-1', '1-2', '2-2b', '2b-3', '3-4', '4-5', '5-6'];
    firstHoursTemp = [0, 1, 2, 3, 4, 5, 6];
    secondHoursTemp = [7, 8, 9, 10, 11, 12];
    thirdHoursTemp = [13, 14, 15, 16, 17, 18];
    fourtHoursTemp = [19, 20, 21, 22, 23, 24];
  }

  let miArrayTab = [
    {
      text: `0 - 6 H`,
      content: `<div>
          <table>
          <tbody>
              <tr class="esios-balance__tabs-row table-title-row">
                <td class="esios-balance__tabs-row-title">
                  <div class="esios-balance__tabs-title">
                    <p>${i18n.t('hour')}</p>
                  </div>
                </td>
                ${firstHours
                  .map(
                    (hour) => `
                  <td>
                    <div class="esios-balance__tabs-column-title ${hour}h">
                      <p>${hour} H</p>
                    </div>
                  </td>
                `
                  )
                  .join('')}
              </tr>
              ${dataElement
                .map((element, i) => {
                  return `
                <tr class="esios-balance__tabs-row">
                  <td class='esios-balance__tabs-row-title'>
                  ${i18n.t(element?.opts?.name || '')}
                  </td>
                  ${firstHoursTemp
                    .map((item, ind) => {
                      return `<td
                  class='esios-balance__tabs-row-value column-with-data coltable-${ind}'
                  >${
                    Utils.toNumber(
                      dataElement[i].values[item]?.value,
                      1,
                      'comma'
                    ) || '-'
                  }</td>`;
                    })
                    .join('')}
                </tr>`;
                })
                .join('')}
            </tbody>
          </table>
        </div>`,
    },
    {
      text: `6 - 12 H`,
      content: `<div>
      <table>
       <tbody>
        <tr class="esios-balance__tabs-row table-title-row">
          <td class="esios-balance__tabs-row-title">
            <div class="esios-balance__tabs-title">
              <p>${i18n.t('hour')}</p>
            </div>
          </td>
          ${secondHours
            .map(
              (hour) => `
                  <td>
                    <div class="esios-balance__tabs-column-title">
                      <p>${hour} H</p>
                    </div>
                  </td>
          `
            )
            .join('')}
        </tr>
        ${dataElement
          .map((element, i) => {
            return `
                <tr class="esios-balance__tabs-row">
                  <td class='esios-balance__tabs-row-title'>${i18n.t(
                    element?.opts?.name || ''
                  )}</td>
                  ${secondHoursTemp
                    .map((item, ind) => {
                      return `<td
                  class='esios-balance__tabs-row-value column-with-data coltable-${ind}'
                  >${
                    Utils.toNumber(
                      dataElement[i].values[item]?.value,
                      1,
                      'comma'
                    ) || '-'
                  }</td>`;
                    })
                    .join('')}
                </tr>`;
          })
          .join('')}
        </tbody>
      </table>
    </div>`,
    },
    {
      text: `12 - 18 H`,
      content: `<div>
      <table>
       <tbody>
        <tr class="esios-balance__tabs-row table-title-row">
          <td class="esios-balance__tabs-row-title">
            <div class="esios-balance__tabs-title">
              <p>${i18n.t('hour')}</p>
            </div>
          </td>
          ${thirdHours
            .map(
              (hour) => `
                  <td>
                    <div class="esios-balance__tabs-column-title">
                      <p>${hour} H</p>
                    </div>
                  </td>
          `
            )
            .join('')}
        </tr>
        ${dataElement
          .map((element, i) => {
            return `
                <tr class="esios-balance__tabs-row">
                  <td class='esios-balance__tabs-row-title'>${i18n.t(
                    element?.opts?.name || ''
                  )}</td>
                  ${thirdHoursTemp
                    .map((item, ind) => {
                      return `<td
                  class='esios-balance__tabs-row-value column-with-data coltable-${ind}'
                  >${
                    Utils.toNumber(
                      dataElement[i].values[item]?.value,
                      1,
                      'comma'
                    ) || '-'
                  }</td>`;
                    })
                    .join('')}
                </tr>`;
          })
          .join('')}
        </tbody>
      </table>
    </div>`,
    },
    {
      text: `18 - 24 H`,
      content: `<div>
      <table>
       <tbody>
        <tr class="esios-balance__tabs-row table-title-row">
          <td class="esios-balance__tabs-row-title">
            <div class="esios-balance__tabs-title">
              <p>${i18n.t('hour')}</p>
            </div>
          </td>
          ${fourthHours
            .map(
              (hour) => `
                  <td>
                    <div class="esios-balance__tabs-column-title">
                      <p>${hour} H</p>
                    </div>
                  </td>
          `
            )
            .join('')}
        </tr>

        ${dataElement
          .map((element, i) => {
            return `
                <tr class="esios-balance__tabs-row">
                  <td class='esios-balance__tabs-row-title'>${i18n.t(
                    element?.opts?.name || ''
                  )}</td>
                  ${fourtHoursTemp
                    .map((item, ind) => {
                      return `<td
                  class='esios-balance__tabs-row-value column-with-data coltable-${ind}'
                  >${
                    Utils.toNumber(
                      dataElement[i].values[item]?.value,
                      1,
                      'comma'
                    ) || '-'
                  }</td>`;
                    })
                    .join('')}
                </tr>`;
          })
          .join('')}

        </tbody>
      </table>
    </div>`,
    },
  ];
  return miArrayTab;
};

export const fillPrintVals = (rangeNumber, date) => {
  let arr = [];
  let dateSplit = date?.split('-');
  let utcTime = moment(date, 'DD-MM-YYYY').tz('Europe/Madrid').format();
  for (let i = 0; i < rangeNumber; i++) {
    arr.push({
      datetime:
        `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}T${
          i < 10 ? '0' + i : i
        }:00:00.000+` + utcTime.split('+')[1],
      datetime_utc: '2022-02-04T23:00:00Z',
      geo_id: null,
      geo_name: null,
      tz_time: '2022-02-04T23:00:00.000Z',
      value: 0,
    });
  }
  return arr;
};

export const processSaldo = (data, filter, color = '#333333') => {
  let tempArr = [];
  let finalObj;
  data.map((el) => {
    if (filter.includes(el.id)) {
      tempArr.push(el);
    }
  });
  tempArr.map((el) => {
    finalObj = {
      ...el,
      values: el.values.map((val, ind) => {
        return {
          ...val,
          y: tempArr[0].values[ind].y || 0,
          value: tempArr[0].values[ind].y || 0,
          color,
        };
      }),
    };
    return finalObj;
  });
  return finalObj;
};

export const calculateSaldo = (data, filter, color = '#333333') => {
  let tempArr = [];
  let finalObj;
  data.map((el) => {
    if (filter.includes(el.id)) {
      tempArr.push(el);
    }
  });
  tempArr.map((el) => {
    finalObj = {
      ...el,
      values: el.values.map((val, ind) => {
        return {
          ...val,
          y: tempArr[0].values[ind].y + tempArr[1].values[ind].y,
          value: tempArr[0].values[ind].y + tempArr[1].values[ind].y,
          color,
        };
      }),
    };
    return finalObj;
  });
  return finalObj;
};

export const calculateMD = (data, filter, color = '#333333') => {
  let tempArr = [];
  let finalObj;
  data.map((el) => {
    if (filter.includes(el.id)) {
      tempArr.push(el);
    }
  });
  tempArr.map((el) => {
    finalObj = {
      ...el,
      values: el.values.map((val, ind) => {
        return {
          ...val,
          y:
            tempArr[2].values[ind].y +
            Math.abs(tempArr[0].values[ind].y - tempArr[1].values[ind].y),
          value:
            tempArr[2].values[ind].value +
            Math.abs(
              tempArr[0].values[ind].value - tempArr[1].values[ind].value
            ),
          color,
        };
      }),
    };
    return finalObj;
  });
  return finalObj;
};

export const calculateLP = (data, filter, color = '#333333') => {
  let tempArr = [];
  let finalObj;
  data.map((el) => {
    if (filter.includes(el.id)) {
      tempArr.push(el);
    }
  });
  tempArr.map((el) => {
    finalObj = {
      ...el,
      values: el.values.map((val, ind) => {
        return {
          ...val,
          y:
            tempArr[0].values[ind].y != null
              ? tempArr[0].values[ind].y
              : -(-tempArr[1].values[ind].y),
          value:
            tempArr[0].values[ind].y != null
              ? tempArr[0].values[ind].y
              : -(-tempArr[1].values[ind].y),
          color,
        };
      }),
    };
    return finalObj;
  });
  return finalObj;
};

export const calculateRR = (data, filter, color = '#333333') => {
  let tempArr = [];
  let finalObj;
  data.map((el) => {
    if (filter.includes(el.id)) {
      tempArr.push(el);
    }
  });
  tempArr.map((el) => {
    finalObj = {
      ...el,
      values: el.values.map((val, ind) => {
        return {
          ...val,
          y:
            tempArr[1].values[ind].y != null && tempArr[0].values[ind].y != null
              ? tempArr[0].values[ind].y - tempArr[1].values[ind].y
              : tempArr[1].values[ind].y != null
              ? tempArr[1].values[ind].y
              : tempArr[0].values[ind].y != null
              ? -tempArr[0].values[ind].y
              : 1,
          value:
            tempArr[1].values[ind].y != null && tempArr[0].values[ind].y != null
              ? tempArr[0].values[ind].y - tempArr[1].values[ind].y
              : tempArr[1].values[ind].y != null
              ? tempArr[1].values[ind].y
              : tempArr[0].values[ind].y != null
              ? -tempArr[0].values[ind].y
              : 1,
          color,
        };
      }),
    };
    return finalObj;
  });
  return finalObj;
};

export const calculateACB = (data, filter, color = '#333333') => {
  let tempArr = [];
  let finalObj;
  data.map((el) => {
    if (filter.includes(el.id)) {
      tempArr.push(el);
    }
  });
  tempArr.map((el) => {
    finalObj = {
      ...el,
      values: el.values.map((val, ind) => {
        return {
          ...val,
          y:
            tempArr[0].values[ind].y != null && tempArr[1].values[ind].y != null
              ? tempArr[1].values[ind].y - tempArr[0].values[ind].y
              : tempArr[0].values[ind].y != null
              ? tempArr[0].values[ind].y
              : tempArr[1].values[ind].y != null
              ? -tempArr[1].values[ind].y
              : 0,
          value:
            tempArr[0].values[ind].y != null && tempArr[1].values[ind].y != null
              ? tempArr[1].values[ind].y - tempArr[0].values[ind].y
              : tempArr[0].values[ind].y != null
              ? tempArr[0].values[ind].y
              : tempArr[1].values[ind].y != null
              ? -tempArr[1].values[ind].y
              : 0,
          color,
        };
      }),
    };
    return finalObj;
  });
  return finalObj;
};

export const calculateSUPPORTEXPORT = (data, filter, color = '#717171') => {
  let tempArr = [];
  let finalObj;
  data.map((el) => {
    if (filter.includes(el.id)) {
      tempArr.push(el);
    }
  });
  tempArr.map((el) => {
    finalObj = {
      ...el,
      values: el.values.map((val, ind) => {
        return {
          ...val,
          y: tempArr[0].values[ind].y,
          value: tempArr[0].values[ind].y,
          color,
        };
      }),
    };
    return finalObj;
  });
  return finalObj;
};

export const calculateSUPPORTIMPORT = (data, filter, color = '#717171') => {
  let tempArr = [];
  let finalObj;
  data.map((el) => {
    if (filter.includes(el.id)) {
      tempArr.push(el);
    }
  });
  tempArr.map((el) => {
    finalObj = {
      ...el,
      values: el.values.map((val, ind) => {
        return {
          ...val,
          y: tempArr[0].values[ind].y,
          value: tempArr[0].values[ind].y,
          color,
        };
      }),
    };
    return finalObj;
  });
  return finalObj;
};

export const calculateSaldoMi = (data, color = '#333333') => {
  let finalObj;
  data.map((el) => {
    finalObj = {
      ...el,
      id: el.id + 'mi',
      values: el.values.map((val, ind) => {
        return {
          ...val,
          y: data[0].values[ind].y - data[1].values[ind].y,
          value: data[0].values[ind].y - data[1].values[ind].y,
          color,
        };
      }),
    };
  });
  return finalObj;
};

export const calculateSaldoIntradiary = (data, filter, color = '#333333') => {
  let tempArr = [];
  let finalObj;
  data.map((el) => {
    if (filter.includes(el.id)) {
      tempArr.push(el);
    }
  });
  tempArr.reverse();
  tempArr.map((el) => {
    finalObj = {
      ...el,
      id: 'intraday',
      values: el.values.map((val, ind) => {
        return {
          ...val,
          y: tempArr[0].values[ind].y - tempArr[1].values[ind].y,
          value:
            tempArr[0].values[ind].value != null
              ? tempArr[0].values[ind].value - tempArr[1].values[ind].value
              : null,
          color,
        };
      }),
    };
    return finalObj;
  });
  return finalObj;
};
// export const calculateSaldoIntradiary = (data, color = '#333333') => {
//   let finalObj;
//   data.map((el) => {
//     finalObj = {
//       ...el,
//       id: 'intraday',
//       values: el.values.map((val, ind) => {
//         return {
//           ...val,
//           y: data[0].values[ind].y - data[1].values[ind].y,
//           value: data[0].values[ind].y - data[1].values[ind].y,
//           color,
//         };
//       }),
//     };
//   });
//   return finalObj;
// };

export const calculateSaldoMulti = (data, filter, color = '#333333') => {
  let tempArr = [];
  let finalObj;
  data.map((el) => {
    if (filter.includes(el.id)) {
      tempArr.push(el);
    }
  });
  tempArr.map((el) => {
    finalObj = {
      ...el,
      values: el.values.map((val, ind) => {
        return {
          ...val,
          y:
            tempArr[0].values[ind].y +
            tempArr[1].values[ind].y +
            tempArr[2].values[ind].y +
            tempArr[3].values[ind].y +
            tempArr[4].values[ind].y +
            tempArr[5].values[ind].y +
            tempArr[6].values[ind].y,
          value:
            tempArr[0].values[ind].y +
            tempArr[1].values[ind].y +
            tempArr[2].values[ind].y +
            tempArr[3].values[ind].y +
            tempArr[4].values[ind].y +
            tempArr[5].values[ind].y +
            tempArr[6].values[ind].y,
          color,
        };
      }),
    };
  });
  return finalObj;
};

export const calculateLastSaldoMulti = (data, filter, color = '#333333') => {
  let tempArr = [];
  let finalObj;
  data.map((el) => {
    if (filter.includes(el.id)) {
      tempArr.push(el);
    }
  });

  let tempVals = [];
  for (let ind = 0; ind < tempArr[0].values.length; ind++) {
    for (let index = 0; index < tempArr.length; index++) {
      const element = tempArr[index];
      tempVals[ind] = {
        ...element.values[ind],
        value: null,
        y: 0,
        color,
      };

      if (element.values[ind]?.y) {
        tempVals[ind] = {
          ...element.values[ind],
          color,
          checkName: element.name,
        };
      }
    }
  }
  finalObj = {
    ...tempArr[0],
    values: tempVals,
  };
  return finalObj;
};

export const processPreData = (data) => {
  let dataFrancia = [],
    dataPortugal = [],
    dataAndorra = [],
    dataMarruecos = [];

  let date = getUrlParams()?.date
    ? getUrlParams()?.date
    : moment().tz('Europe/Madrid').format('DD-MM-YYYY');
  let hoursInDay = calculateHoursPerDay(date);
  if (hoursInDay !== 25) {
    hoursInDay = 24;
  }
  let preData = {
    ...data,
    indicators: data.indicators.map((el) => {
      return {
        ...el,
        values: fillSimpleVals(hoursInDay),
      };
    }),
  };

  preData.indicators.map((el, index) => {
    let finded = data.indicators.filter((or) => or.id === el.id)[0]?.values;

    let existDoubleTwo = false;
    finded.forEach((val, ind) => {
      let hour = existDoubleTwo
        ? parseInt(moment(val.x).tz('Europe/Madrid').format('HH')) + 1
        : parseInt(moment(val.x).tz('Europe/Madrid').format('HH'));
      if (hour == 2 && hoursInDay === 25) {
        existDoubleTwo = true;
      }

      preData.indicators[index].values[hour] = {
        ...preData.indicators[index].values[hour],
        value: val.y,
        y: val.y,
        color: getColour(index),
        name: el.name,
        extra: true,
      };
    });
  });
  preData.indicators.map((el, index) => {
    return {
      ...el,
      color: getColour(index),
      name: el.short_name,
      shortName: el.short_name,
      demanda: false,
      legendtype: 'circle',
    };
  });

  preData.indicators.find((el) => {
    if (
      el.short_name.toLowerCase().includes('francia') ||
      el.short_name.toLowerCase().includes('france') ||
      el.id === 10026
    ) {
      dataFrancia.push(el);
    }
    if (el.short_name.toLowerCase().includes('portugal') || el.id === 10026) {
      dataPortugal.push(el);
    }
    if (
      el.short_name.toLowerCase().includes('marruecos') ||
      el.short_name.toLowerCase().includes('morocco') ||
      el.id === 10026
    ) {
      dataMarruecos.push(el);
    }
    if (el.short_name.toLowerCase().includes('andorra') || el.id === 10026) {
      dataAndorra.push(el);
    }
  });

  return {
    dFrancia: dataFrancia,
    dPortugal: dataPortugal,
    dAndorra: dataAndorra,
    dMarruecos: dataMarruecos,
  };
};

export const proccessData = (
  dataFrancia,
  dataPortugal,
  dataMarruecos,
  dataAndorra
) => {
  let newPortugal = [],
    newFrancia = [],
    newMarruecos = [],
    newAndorra = [];

  dataFrancia.map((el) => {
    // if (el.id === 10026) {
    //   newFrancia.push({
    //     ...el,
    //     color: '#333333',
    //     legendtype: 'line',
    //     demanda: true,
    //   });
    // } else
    if (el.id === 488) {
      //import
      newFrancia.push({
        ...el,
        color: '#333333',
        legendtype: 'line',
        area0: true,
      });
    } else if (el.id === 492) {
      //export
      newFrancia.push({
        ...el,
        color: '#333333',
        legendtype: 'line',
        area1: true,
        values: el.values.map((val) => {
          return {
            ...val,
            y: val.y * -1,
            value: val.y * -1,
          };
        }),
      });
    } else if (el.id === 10275) {
      // MIC
      // newFrancia.push({
      //   ...el,
      //   values: el.values.map((val) => {
      //     return {
      //       ...val,
      //       color: getColour(2),
      //     };
      //   }),
      // });
    }
  });

  //INTRADAY
  // let intradiary = calculateLastSaldoMulti(
  //   dataFrancia,
  //   [10106, 10107, 10108, 10109, 10110, 10111, 10112]
  // );

  // newFrancia.push(
  //   calculateSaldoIntradiary(
  //     [intradiary, processSaldo(dataFrancia, [10104])],
  //     getColour(2)
  //   )
  // );
  newFrancia.push(
    calculateSaldoIntradiary(dataFrancia, [10275, 10104], getColour(2))
  );

  // largo plazo
  newFrancia.push(calculateLP(dataFrancia, [1106, 1105], getColour(0)));
  // MD
  // newFrancia.push(processSaldo(dataFrancia, [10104], getColour(1)));
  newFrancia.push(calculateMD(dataFrancia, [1106, 1105, 10104], getColour(1)));
  // RR
  newFrancia.push(calculateRR(dataFrancia, [536, 535], getColour(3)));
  // ACB
  newFrancia.push(calculateACB(dataFrancia, [565, 564], getColour(4)));

  // SUPPORT IMPORT
  newFrancia.push(calculateSUPPORTIMPORT(dataFrancia, [2109], getColour(5)));
  // SUPPORT EXPOT
  newFrancia.push(calculateSUPPORTEXPORT(dataFrancia, [2110], getColour(6)));

  newFrancia = newFrancia.filter(Boolean);

  dataPortugal.map((el) => {
    // if (el.id === 10026) {
    //   newPortugal.push({
    //     ...el,
    //     color: '#333333',
    //     legendtype: 'line',
    //     demanda: true,
    //   });
    // } else
    if (el.id === 489) {
      // imp
      newPortugal.push({
        ...el,
        color: '#333333',
        legendtype: 'line',
        area0: true,
      });
    } else if (el.id === 493) {
      // exp
      newPortugal.push({
        ...el,
        color: '#333333',
        legendtype: 'line',
        area1: true,
        values: el.values.map((val) => {
          return {
            ...val,
            y: val.y * -1,
            value: val.y * -1,
          };
        }),
      });
    } else if (el.id === 10276) {
      // MIC
      // newPortugal.push({
      //   ...el,
      //   values: el.values.map((val) => {
      //     return {
      //       ...val,
      //       color: getColour(2),
      //     };
      //   }),
      // });
    }
  });

  //INTRADAY
  // newPortugal.push(
  //   calculateSaldoIntradiary(
  //     [
  //       calculateLastSaldoMulti(
  //         dataPortugal,
  //         [10115, 10116, 10117, 10118, 10119, 10120, 10121]
  //       ),
  //       processSaldo(dataPortugal, [10113]),
  //     ],
  //     getColour(2)
  //   )
  // );
  newPortugal.push(
    calculateSaldoIntradiary(dataPortugal, [10276, 10113], getColour(2))
  );

  //MI
  // newPortugal.push(
  //   calculateSaldoMi(
  //     [
  //       calculateSaldoMulti(
  //         dataPortugal,
  //         [10115, 10116, 10117, 10118, 10119, 10120, 10121]
  //       ),
  //       processSaldo(dataPortugal, [10113]),
  //     ],
  //     getColour(5)
  //   )
  // );
  // MD
  newPortugal.push(processSaldo(dataPortugal, [10113], getColour(1)));
  // RR
  newPortugal.push(calculateRR(dataPortugal, [540, 539], getColour(3)));
  // ACB
  newPortugal.push(calculateACB(dataPortugal, [567, 566], getColour(4)));
  // SUPPORT IMPORT
  newPortugal.push(calculateSUPPORTIMPORT(dataPortugal, [2111], getColour(5)));
  // SUPPORT EXPOT
  newPortugal.push(calculateSUPPORTEXPORT(dataPortugal, [2112], getColour(6)));

  newPortugal = newPortugal.filter(Boolean);

  dataMarruecos.map((el) => {
    // if (el.id === 10026) {
    //   newMarruecos.push({
    //     ...el,
    //     color: '#333333',
    //     legendtype: 'line',
    //     demanda: true,
    //   });
    // } else
    if (el.id === 490) {
      // imp
      newMarruecos.push({
        ...el,
        color: '#333333',
        legendtype: 'line',
        area0: true,
      });
    } else if (el.id === 494) {
      // exp
      newMarruecos.push({
        ...el,
        color: '#333333',
        legendtype: 'line',
        area1: true,
        values: el.values.map((val) => {
          return {
            ...val,
            y: val.y * -1,
            value: val.y * -1,
          };
        }),
      });
    }
  });

  //INTRADAY
  // newMarruecos.push(
  //   calculateSaldoIntradiary(
  //     [
  //       calculateLastSaldoMulti(
  //         dataMarruecos,
  //         [10124, 10125, 10126, 10127, 10128, 10129, 10130]
  //       ),
  //       processSaldo(dataMarruecos, [10122]),
  //     ],
  //     getColour(2)
  //   )
  // );
  newMarruecos.push(
    calculateSaldoIntradiary(dataMarruecos, [10277, 10122], getColour(2))
  );

  // MI
  // newMarruecos.push(
  //   calculateSaldoMi(
  //     [
  //       calculateSaldoMulti(
  //         dataMarruecos,
  //         [10124, 10125, 10126, 10127, 10128, 10129, 10130]
  //       ),
  //       processSaldo(dataMarruecos, [10122]),
  //     ],
  //     getColour(5)
  //   )
  // );
  // MD
  newMarruecos.push(processSaldo(dataMarruecos, [10122], getColour(1)));
  // SUPPORT IMPORT
  newMarruecos.push(
    calculateSUPPORTIMPORT(dataMarruecos, [2113], getColour(5))
  );
  // SUPPORT EXPOT
  newMarruecos.push(
    calculateSUPPORTEXPORT(dataMarruecos, [2114], getColour(6))
  );

  newMarruecos = newMarruecos.filter(Boolean);

  dataAndorra.map((el) => {
    // if (el.id === 10026) {
    //   newAndorra.push({
    //     ...el,
    //     color: '#333333',
    //     legendtype: 'line',
    //     demanda: true,
    //   });
    // } else
    if (el.id === 491) {
      // imp
      newAndorra.push({
        ...el,
        color: '#333333',
        legendtype: 'line',
        area0: true,
      });
    } else if (el.id === 495) {
      // exp
      newAndorra.push({
        ...el,
        color: '#333333',
        legendtype: 'line',
        area1: true,
        values: el.values.map((val) => {
          return {
            ...val,
            y: val.y * -1,
            value: val.y * -1,
          };
        }),
      });
    }
  });

  //INTRADAY
  // newAndorra.push(
  //   calculateSaldoIntradiary(
  //     [
  //       calculateLastSaldoMulti(
  //         dataAndorra,
  //         [10133, 10134, 10136, 10137, 10138, 10139, 10140]
  //       ),
  //       processSaldo(dataAndorra, [10131]),
  //     ],
  //     getColour(2)
  //   )
  // );
  newAndorra.push(
    calculateSaldoIntradiary(dataAndorra, [10278, 10131], getColour(2))
  );

  // MD
  newAndorra.push(processSaldo(dataAndorra, [10131], getColour(1)));
  // SUPPORT IMPORT
  newAndorra.push(calculateSUPPORTIMPORT(dataAndorra, [2115], getColour(5)));
  // SUPPORT EXPOT
  newAndorra.push(calculateSUPPORTEXPORT(dataAndorra, [2116], getColour(6)));

  newAndorra = newAndorra.filter(Boolean);

  return [newFrancia, newPortugal, newMarruecos, newAndorra];
};
