'use strict';

/**
 * @description No encontrada Component
 * @returns {Object} Devuelve la instancia del componente
 */

import i18n from 'i18next';
import { render } from 'lit-html';
import { store } from '../../app.init.js';
import { templatenoEncontrada } from './noEncontrada.template';

let componentnoEncontrada = (function () {
  let context = {};
  let handlers = {};
  let renderSuccess = false;

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    document.title = `${i18n.t('page_dont_exist')} | ${i18n.t(
      'page_subtitle'
    )}`;
    context.name = state.exampleReducer.name;
  };

  let renderComponent = async () => {
    const state = store.getState();
    try {
      render(
        // Define a template
        templatenoEncontrada(context, handlers),
        document.querySelector('body')
      );
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentnoEncontrada };
