import { Utils } from "../../utils/utils";

var scrollEvent = window.ontouchstart ? "touchmove" : "scroll";

if (!Utils.isMobileDevice() || Utils.isTablet()) {
  document.addEventListener(scrollEvent, function doParalaxEffect() {
    let banner = document.getElementById("indicatorCaption");

    var scroll = getScroll(),
      translateY,
      opacity;

    if (scroll < (window.innerHeight / 2).toFixed(0) && banner) {
      translateY = (scroll / 3) * 2;
      opacity = scroll <= 0 ? 1 : (20 / scroll).toFixed(2);

      banner.style["-webkit-transform"] =
        "translate3d(0," + translateY + "px, 0)";
      banner.style["-ms-transform"] =
        "translate3d(0," + translateY + "px, 0)";
      banner.style["-o-transform"] =
        "translate3d(0," + translateY + "px, 0)";
      banner.style["transform"] =
        "translate3d(0," + translateY + "px, 0)";
      banner.style.opacity = opacity;
    }
  });
}

function getScroll() {
  return window.pageYOffset;
}
