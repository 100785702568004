'use strict';
import { timeHours } from 'd3';
import i18next from 'i18next';
import moment from 'moment-timezone';
import { api } from '../../utils/api-interceptor';
import { getBreadCrumbsDate } from '../../utils/dateFormat';
import { Utils } from '../../utils/utils';

let servicesMercadosPrecios = (() => {
  let init = () => {};

  const getArchives = async (params) => {
    const { ambit, category, action, process, query } = params;

    const urlApi = `indicators?${
      ambit !== '' ? `taxonomy_ids[]=${ambit}&` : ''
    }${category !== '' ? `taxonomy_ids[]=${category}&` : ''}${
      action !== '' ? `taxonomy_ids[]=${action}&` : ''
    }${process !== '' ? `taxonomy_ids[]=${process}&` : ''}locale=${i18next.t(
      'routes.lng'
    )}${query ? `&text=${query}` : ''}`;

    let loading;
    let newIndicators;
    try {
      loading = true;
      const data = await api(urlApi);
      if (!ambit && !query && !category && !process && !action) {
        return {
          newIndicators: [],
          isLoading: loading,
        };
      }
      newIndicators = data.indicators
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
        .map((indicator) => {
          return {
            name: indicator.name,
            id: indicator.id,
          };
        });
      loading = false;
      return {
        isLoading: loading,
        newIndicators,
      };
    } catch (error) {}
  };

  const getVolumenDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year, volumenUTC1 = false } = params;

    if (globalState === '00:00') {
      return api(
        `widgets/6?locale=${lang}&datetime=${
          year.replace(/ /g, '') +
          '-' +
          month.replace(/ /g, '') +
          '-' +
          day.replace(/ /g, '')
        }`
      ).then((data) => {
        const indicators = data.widget.indicators;
        const slug = data.widget.id;
        const description = data.widget.description;
        const name = data.widget.name;
        const magnituds = [];
        const alias = [];
        const indicatorName = [];
        const shortName = [];
        return Promise.all(
          indicators.map((el) => {
            magnituds.push(el.display_options);
            alias.push(el.alias);
            shortName.push(el.short_name);
            indicatorName.push(el.name);
            return api(`${el.url}&locale=${lang}`);
          })
        ).then((data) => {
          const indicators = data.map((ele, index) => {
            const values = ele.indicator.values.map((el) => {
              return {
                x: new Date(el.datetime),
                y: el.value,
              };
            });

            return {
              values,
              print_values: ele.indicator.values,
              time: ele.indicator.tiempo[0]?.id,
              alias: alias[index],
              display_options: magnituds[index],
              composited: ele.indicator?.composited,
              magnitud: ele.indicator?.magnitud,
              disaggregated: ele.indicator?.disaggregated,
              short_name: shortName[index],
              name: indicatorName[index],
              step: ele.indicator.step_type,
              id: ele.indicator.id,
              geo_id: ele.indicator.geos[0]?.geo_id,
              geo_name: ele.indicator.geos[0]?.geo_name,
            };
          });

          return {
            description,
            name,
            indicators,
            slug,
          };
        });
      });
    } else {
      let timeInHours;
      typeof globalState == 'object'
        ? (timeInHours = globalState.pages.myp.volumeHour)
        : (timeInHours = globalState);

      let date = `${day}-${month}-${year}`;
      newDate = moment(date, 'DD-MM-YYYY')
        .tz('Europe/Madrid')
        .hours(timeInHours.split(':')[0])
        .minutes(timeInHours.split(':')[1])
        .seconds('00')
        .format();

      let hoursOfDay = Utils.getHoursOfDay(date, 'DD-MM-YYYY');
      if (hoursOfDay == 25) {
        if (timeInHours.split(':')[0] == '02') {
          if (volumenUTC1) {
            newDate = newDate.replace('+02:00', '+01:00');
          }
        }
      }

      return api(`widgets/6?locale=${lang}&datetime=${newDate}`).then(
        (data) => {
          const indicators = data.widget.indicators;
          const slug = data.widget.id;
          const description = data.widget.description;
          const name = data.widget.name;
          const magnituds = [];
          const alias = [];
          const indicatorName = [];
          const shortName = [];
          return Promise.all(
            indicators.map((el) => {
              magnituds.push(el.display_options);
              alias.push(el.alias);
              shortName.push(el.short_name);
              indicatorName.push(el.name);
              return api(`${el.url}&locale=${lang}`);
            })
          ).then((data) => {
            const indicators = data.map((ele, index) => {
              const values = ele.indicator.values.map((el) => {
                return {
                  x: new Date(el.datetime),
                  y: el.value,
                };
              });
              return {
                values,
                print_values: ele.indicator.values,
                time: ele.indicator.tiempo[0]?.id,
                alias: alias[index],
                display_options: magnituds[index],
                composited: ele.indicator?.composited,
                magnitud: ele.indicator?.magnitud,
                disaggregated: ele.indicator?.disaggregated,
                short_name: shortName[index],
                name: indicatorName[index],
                step: ele.indicator.step_type,
                id: ele.indicator.id,
                geo_id: ele.indicator.geos[0]?.geo_id,
                geo_name: ele.indicator.geos[0]?.geo_name,
              };
            });

            return {
              description,
              name,
              indicators,
              slug,
            };
          });
        }
      );
    }
  };
  const getUnitaryCostDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year, costeUTC1 = false } = params;

    if (globalState === '00:00') {
      return api(
        `widgets/7?locale=${lang}&datetime=${
          year.replace(/ /g, '') +
          '-' +
          month.replace(/ /g, '') +
          '-' +
          day.replace(/ /g, '')
        }`
      ).then((data) => {
        const indicators = data.widget.indicators;
        const slug = data.widget.id;
        const description = data.widget.description;
        const name = data.widget.name;
        const magnituds = [];
        const alias = [];
        const indicatorName = [];
        const shortName = [];
        return Promise.all(
          indicators.map((el) => {
            magnituds.push(el.display_options);
            alias.push(el.alias);
            shortName.push(el.short_name);
            indicatorName.push(el.name);
            return api(`${el.url}&locale=${lang}`);
          })
        ).then((data) => {
          const indicators = data.map((ele, index) => {
            const values = ele.indicator.values.map((el) => {
              return {
                x: new Date(el.datetime),
                y: el.value,
              };
            });

            return {
              values,
              print_values: ele.indicator.values,
              time: ele.indicator.tiempo[0]?.id,
              alias: alias[index],
              display_options: magnituds[index],
              composited: ele.indicator?.composited,
              magnitud: ele.indicator?.magnitud,
              disaggregated: ele.indicator?.disaggregated,
              short_name: shortName[index],
              name: indicatorName[index],
              step: ele.indicator.step_type,
              id: ele.indicator.id,
              geo_id: ele.indicator.geos[0]?.geo_id,
              geo_name: ele.indicator.geos[0]?.geo_name,
            };
          });

          return {
            description,
            name,
            indicators,
            slug,
          };
        });
      });
    } else {
      let timeInHours;
      typeof globalState == 'object'
        ? (timeInHours = globalState.pages.myp.volumeHour)
        : (timeInHours = globalState);

      let date = `${day}-${month}-${year}`;
      newDate = moment(date, 'DD-MM-YYYY')
        .tz('Europe/Madrid')
        .hours(timeInHours.split(':')[0])
        .minutes(timeInHours.split(':')[1])
        .seconds('00')
        .format();

      let hoursOfDay = Utils.getHoursOfDay(date, 'DD-MM-YYYY');
      if (hoursOfDay == 25) {
        if (timeInHours.split(':')[0] == '02') {
          if (costeUTC1) {
            newDate = newDate.replace('+02:00', '+01:00');
          }
        }
      }
      return api(`widgets/7?locale=${lang}&datetime=${newDate}`).then(
        (data) => {
          const indicators = data.widget.indicators;
          const slug = data.widget.id;
          const description = data.widget.description;
          const name = data.widget.name;
          const magnituds = [];
          const alias = [];
          const indicatorName = [];
          const shortName = [];
          return Promise.all(
            indicators.map((el) => {
              magnituds.push(el.display_options);
              alias.push(el.alias);
              shortName.push(el.short_name);
              indicatorName.push(el.name);
              return api(`${el.url}&locale=${lang}`);
            })
          ).then((data) => {
            const indicators = data.map((ele, index) => {
              const values = ele.indicator.values.map((el) => {
                return {
                  x: new Date(el.datetime),
                  y: el.value,
                };
              });

              return {
                values,
                print_values: ele.indicator.values,
                time: ele.indicator.tiempo[0]?.id,
                alias: alias[index],
                display_options: magnituds[index],
                composited: ele.indicator?.composited,
                magnitud: ele.indicator?.magnitud,
                disaggregated: ele.indicator?.disaggregated,
                short_name: shortName[index],
                name: indicatorName[index],
                step: ele.indicator.step_type,
                id: ele.indicator.id,
                geo_id: ele.indicator.geos[0]?.geo_id,
                geo_name: ele.indicator.geos[0]?.geo_name,
              };
            });

            return {
              description,
              name,
              indicators,
              slug,
            };
          });
        }
      );
    }
  };

  const getDesvioDataFromWidget = async (params) => {
    const { lang, day, month, year } = params;
    const newDate = moment()
      .tz('Europe/Madrid')
      .year(year)
      .month(month - 1)
      .date(day)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .format();

    return api(`widgets/8?locale=${lang}&datetime=${newDate}`).then((data) => {
      const indicators = data.widget.indicators;
      const slug = data.widget.id;
      const description = data.widget.description;
      const name = data.widget.name;
      const magnituds = [];
      const alias = [];
      const indicatorName = [];
      const shortName = [];
      return Promise.all(
        indicators.map((el) => {
          magnituds.push(el.display_options);
          alias.push(el.alias);
          shortName.push(el.short_name);
          indicatorName.push(el.name);
          return api(`${el.url}&locale=${lang}`);
        })
      ).then((data) => {
        const indicators = data.map((ele, index) => {
          const values = ele.indicator.values.map((el) => {
            return {
              x: new Date(el.datetime),
              y: el.value,
            };
          });

          return {
            values,
            print_values: ele.indicator.values,
            time: ele.indicator.tiempo[0]?.id,
            alias: alias[index],
            display_options: magnituds[index],
            composited: ele.indicator?.composited,
            magnitud: ele.indicator?.magnitud,
            disaggregated: ele.indicator?.disaggregated,
            short_name: shortName[index],
            name: indicatorName[index],
            step: ele.indicator.step_type,
            id: ele.indicator.id,
            geo_id: ele.indicator.geos[0]?.geo_id,
            geo_name: ele.indicator.geos[0]?.geo_name,
          };
        });

        return {
          description,
          name,
          indicators,
          slug,
        };
      });
    });
  };

  const getFacturationDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year } = params;

    if (globalState === '00:00') {
      return api(
        `widgets/9?locale=${lang}&datetime=${
          year.replace(/ /g, '') +
          '-' +
          month.replace(/ /g, '') +
          '-' +
          day.replace(/ /g, '')
        }`
      ).then((data) => {
        const indicators = data.widget.indicators;
        const slug = data.widget.id;
        const description = data.widget.description;
        const name = data.widget.name;
        const magnituds = [];
        const alias = [];
        const indicatorName = [];
        const shortName = [];
        return Promise.all(
          indicators.map((el) => {
            magnituds.push(el.display_options);
            alias.push(el.alias);
            shortName.push(el.short_name);
            indicatorName.push(el.name);
            return api(`${el.url}&locale=${lang}`);
          })
        ).then((data) => {
          const indicators = data.map((ele, index) => {
            const values = ele.indicator.values.map((el) => {
              return {
                x: new Date(el.datetime),
                y: el.value,
              };
            });

            return {
              values,
              print_values: ele.indicator.values,
              time: ele.indicator.tiempo[0]?.id,
              alias: alias[index],
              display_options: magnituds[index],
              composited: ele.indicator?.composited,
              magnitud: ele.indicator?.magnitud,
              disaggregated: ele.indicator?.disaggregated,
              short_name: shortName[index],
              name: indicatorName[index],
              step: ele.indicator.step_type,
              id: ele.indicator.id,
              geo_id: ele.indicator.geos[0]?.geo_id,
              geo_name: ele.indicator.geos[0]?.geo_name,
            };
          });

          return {
            description,
            name,
            indicators,
            slug,
          };
        });
      });
    } else {
      let timeInHours;
      typeof globalState == 'object'
        ? (timeInHours = globalState.pages.myp.volumeHour)
        : (timeInHours = globalState);
      const timezone = new Date().getTimezoneOffset() / 60;
      let newDate =
        year +
        '-' +
        month +
        '-' +
        day +
        'T' +
        timeInHours.split(':')[0] +
        ':00:00' +
        (timezone <= 0 ? '+' + -1 * timezone : -1 * timezone) +
        ':00';
      return api(`widgets/9?locale=${lang}&datetime=${newDate}`).then(
        (data) => {
          const indicators = data.widget.indicators;
          const slug = data.widget.id;
          const description = data.widget.description;
          const name = data.widget.name;
          const magnituds = [];
          const alias = [];
          const indicatorName = [];
          const shortName = [];
          return Promise.all(
            indicators.map((el) => {
              magnituds.push(el.display_options);
              alias.push(el.alias);
              shortName.push(el.short_name);
              indicatorName.push(el.name);
              return api(`${el.url}&locale=${lang}`);
            })
          ).then((data) => {
            const indicators = data.map((ele, index) => {
              const values = ele.indicator.values.map((el) => {
                return {
                  x: new Date(el.datetime),
                  y: el.value,
                };
              });

              return {
                values,
                print_values: ele.indicator.values,
                time: ele.indicator.tiempo[0]?.id,
                alias: alias[index],
                display_options: magnituds[index],
                composited: ele.indicator?.composited,
                magnitud: ele.indicator?.magnitud,
                disaggregated: ele.indicator?.disaggregated,
                print_values: ele.indicator.values,
                time: ele.indicator.tiempo[0]?.id,
                step: ele.indicator.step_type,
                id: ele.indicator.id,
                geo_id: ele.indicator.geos[0]?.geo_id,
                geo_name: ele.indicator.geos[0]?.geo_name,
              };
            });

            return {
              description,
              name,
              indicators,
              slug,
            };
          });
        }
      );
    }
  };

  const getErniDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year } = params;
    let newmonth = month;
    let intmonth = parseInt(month.trim(), 10);
    let intyear = parseInt(year.trim(), 10);
    if (globalState === '00:00') {
      // Obtener el mes actual
      const currentDate = new Date();
      const currentMonth = (currentDate.getMonth() + 1)
        .toString()
        .padStart(2, '0');
      const currentYear = currentDate.getFullYear();
      // Comparar y restar un mes si es el mismo mes
      if (currentMonth === month) {
        let previousMonth = (parseInt(month) - 1).toString().padStart(2, '0');

        // Si el resultado es 0 (es decir, era enero), ajustar a diciembre del año anterior
        if (previousMonth === '00') {
          previousMonth = '12';
        }

        newmonth = previousMonth;
      }
      if (intmonth > currentDate.getMonth() + 1 && currentYear === intyear) {
        let previousMonth = (parseInt(currentMonth) - 1)
          .toString()
          .padStart(2, '0');

        // Si el resultado es 0 (es decir, era enero), ajustar a diciembre del año anterior
        if (previousMonth === '00') {
          previousMonth = '12';
        }

        newmonth = previousMonth;
      }
      return api(
        `widgets/42?locale=${lang}&datetime=${
          year.replace(/ /g, '') +
          '-' +
          newmonth.replace(/ /g, '') +
          '-' +
          '01'.replace(/ /g, '')
        }`
      ).then((data) => {
        const indicators = data.widget.indicators;
        const slug = data.widget.id;
        const description = data.widget.description;
        const name = data.widget.name;
        const magnituds = [];
        const alias = [];
        const indicatorName = [];
        const shortName = [];
        return Promise.all(
          indicators.map((el) => {
            magnituds.push(el.display_options);
            alias.push(el.alias);
            shortName.push(el.short_name);
            indicatorName.push(el.name);
            return api(`${el.url}&locale=${lang}`);
          })
        ).then((data) => {
          const indicators = data.map((ele, index) => {
            const values = ele.indicator.values.map((el) => {
              return {
                x: new Date(el.datetime),
                y: el.value,
              };
            });

            return {
              values,
              print_values: ele.indicator.values,
              time: ele.indicator.tiempo[0]?.id,
              alias: alias[index],
              display_options: magnituds[index],
              composited: ele.indicator?.composited,
              magnitud: ele.indicator?.magnitud,
              disaggregated: ele.indicator?.disaggregated,
              short_name: shortName[index],
              name: indicatorName[index],
              step: ele.indicator.step_type,
              id: ele.indicator.id,
              geo_id: ele.indicator.geos[0]?.geo_id,
              geo_name: ele.indicator.geos[0]?.geo_name,
            };
          });

          return {
            description,
            name,
            indicators,
            slug,
          };
        });
      });
    } else {
      let timeInHours;
      typeof globalState == 'object'
        ? (timeInHours = globalState.pages.myp.volumeHour)
        : (timeInHours = globalState);
      const timezone = new Date().getTimezoneOffset() / 60;
      let intmonth = parseInt(month.trim(), 10);
      let intyear = parseInt(year.trim(), 10);
      // Obtener el mes actual
      const currentDate = new Date();
      const currentMonth = (currentDate.getMonth() + 1)
        .toString()
        .padStart(2, '0');
      const currentYear = currentDate.getFullYear();
      // Comparar y restar un mes si es el mismo mes
      if (currentMonth === month) {
        let previousMonth = (parseInt(month) - 1).toString().padStart(2, '0');

        // Si el resultado es 0 (es decir, era enero), ajustar a diciembre del año anterior
        if (previousMonth === '00') {
          previousMonth = '12';
        }

        newmonth = previousMonth;
      }
      if (month > currentDate.getMonth() + 1 && currentYear === intyear) {
        let previousMonth = (parseInt(currentMonth) - 1)
          .toString()
          .padStart(2, '0');

        // Si el resultado es 0 (es decir, era enero), ajustar a diciembre del año anterior
        if (previousMonth === '00') {
          previousMonth = '12';
        }

        newmonth = previousMonth;
      }
      let newDate =
        year +
        '-' +
        newmonth +
        '-' +
        day +
        'T' +
        timeInHours.split(':')[0] +
        ':00:00' +
        (timezone <= 0 ? '+' + -1 * timezone : -1 * timezone) +
        ':00';
      return api(`widgets/42?locale=${lang}&datetime=${newDate}`).then(
        (data) => {
          const indicators = data.widget.indicators;
          const slug = data.widget.id;
          const description = data.widget.description;
          const name = data.widget.name;
          const magnituds = [];
          const alias = [];
          const indicatorName = [];
          const shortName = [];
          return Promise.all(
            indicators.map((el) => {
              magnituds.push(el.display_options);
              alias.push(el.alias);
              shortName.push(el.short_name);
              indicatorName.push(el.name);
              return api(`${el.url}&locale=${lang}`);
            })
          ).then((data) => {
            const indicators = data.map((ele, index) => {
              const values = ele.indicator.values.map((el) => {
                return {
                  x: new Date(el.datetime),
                  y: el.value,
                };
              });

              return {
                values,
                print_values: ele.indicator.values,
                time: ele.indicator.tiempo[0]?.id,
                alias: alias[index],
                display_options: magnituds[index],
                composited: ele.indicator?.composited,
                magnitud: ele.indicator?.magnitud,
                disaggregated: ele.indicator?.disaggregated,
                print_values: ele.indicator.values,
                time: ele.indicator.tiempo[0]?.id,
                step: ele.indicator.step_type,
                id: ele.indicator.id,
                geo_id: ele.indicator.geos[0]?.geo_id,
                geo_name: ele.indicator.geos[0]?.geo_name,
              };
            });

            return {
              description,
              name,
              indicators,
              slug,
            };
          });
        }
      );
    }
  };

  const getAuctionsDataFromWidget = async (params) => {
    const { lang, time, day, month, year } = params;
    if (time === '00:00') {
      return api(
        `widgets/10?locale=${lang}&datetime=${
          year.replace(/ /g, '') +
          '-' +
          month.replace(/ /g, '') +
          '-' +
          day.replace(/ /g, '')
        }`
      ).then((data) => {
        const indicators = data.widget.indicators;
        const slug = data.widget.id;
        const description = data.widget.description;
        const name = data.widget.name;
        const magnituds = [];
        const alias = [];
        const indicatorName = [];
        const shortName = [];
        return Promise.all(
          indicators.map((el) => {
            magnituds.push(el.display_options);
            alias.push(el.alias);
            shortName.push(el.short_name);
            indicatorName.push(el.name);
            return api(`${el.url}&locale=${lang}`);
          })
        ).then((data) => {
          const indicators = data.map((ele, index) => {
            const values = ele.indicator.values.map((el) => {
              return {
                x: new Date(el.datetime),
                y: el.value,
              };
            });

            return {
              values,
              print_values: ele.indicator.values,
              time: ele.indicator.tiempo[0]?.id,
              alias: alias[index],
              display_options: magnituds[index],
              composited: ele.indicator?.composited,
              magnitud: ele.indicator?.magnitud,
              disaggregated: ele.indicator?.disaggregated,
              short_name: shortName[index],
              name: indicatorName[index],
              step: ele.indicator.step_type,
              id: ele.indicator.id,
              geo_id: ele.indicator.geos[0]?.geo_id,
              geo_name: ele.indicator.geos[0]?.geo_name,
            };
          });

          return {
            description,
            name,
            indicators,
            slug,
          };
        });
      });
    } else {
      let timeInHours = time;
      const timezone = new Date().getTimezoneOffset() / 60;
      let newDate =
        year.replace(/ /g, '') +
        '-' +
        month.replace(/ /g, '') +
        '-' +
        day.replace(/ /g, '') +
        'T' +
        timeInHours.split(':')[0] +
        ':00:00' +
        (timezone <= 0 ? '+' + -1 * timezone : -1 * timezone) +
        ':00';
      return api(`widgets/10?locale=${lang}&datetime=${newDate}`).then(
        (data) => {
          const indicators = data.widget.indicators;
          const slug = data.widget.id;
          const description = data.widget.description;
          const name = data.widget.name;
          const magnituds = [];
          const alias = [];
          const indicatorName = [];
          const shortName = [];
          return Promise.all(
            indicators.map((el) => {
              magnituds.push(el.display_options);
              alias.push(el.alias);
              shortName.push(el.short_name);
              indicatorName.push(el.name);
              return api(`${el.url}&locale=${lang}`);
            })
          ).then((data) => {
            const indicators = data.map((ele, index) => {
              const values = ele.indicator.values.map((el) => {
                return {
                  x: new Date(el.datetime),
                  y: el.value,
                };
              });

              return {
                values,
                print_values: ele.indicator.values,
                time: ele.indicator.tiempo[0]?.id,
                alias: alias[index],
                display_options: magnituds[index],
                composited: ele.indicator?.composited,
                magnitud: ele.indicator?.magnitud,
                disaggregated: ele.indicator?.disaggregated,
                short_name: shortName[index],
                name: indicatorName[index],
                step: ele.indicator.step_type,
                id: ele.indicator.id,
                geo_id: ele.indicator.geos[0]?.geo_id,
                geo_name: ele.indicator.geos[0]?.geo_name,
              };
            });

            return {
              description,
              name,
              indicators,
              slug,
            };
          });
        }
      );
    }
  };

  const getAuctionsPortugalDataFromWidget = async (params) => {
    const { lang, day, month, year, time } = params;

    const timeInHours = time;
    const newDate = moment()
      .tz('Europe/Madrid')
      .year(year)
      .month(month - 1)
      .date(day)
      .hours(timeInHours?.split(':')[0] || '00')
      .minutes('00')
      .seconds(0)
      .format();
    return api(`widgets/35?locale=${lang}&datetime=${newDate}`).then((data) => {
      const indicators = data.widget.indicators;
      const slug = data.widget.id;
      const description = data.widget.description;
      const name = data.widget.name;
      const magnituds = [];
      const alias = [];
      const indicatorName = [];
      const shortName = [];
      return Promise.all(
        indicators.map((el) => {
          magnituds.push(el.display_options);
          alias.push(el.alias);
          shortName.push(el.short_name);
          indicatorName.push(el.name);
          return api(`${el.url}&locale=${lang}`);
        })
      ).then((data) => {
        const indicators = data.map((ele, index) => {
          const values = ele.indicator.values.map((el) => {
            return {
              x: new Date(el.datetime),
              y: el.value,
            };
          });

          return {
            values,
            print_values: ele.indicator.values,
            time: ele.indicator.tiempo[0]?.id,
            alias: alias[index],
            display_options: magnituds[index],
            composited: ele.indicator?.composited,
            magnitud: ele.indicator?.magnitud,
            disaggregated: ele.indicator?.disaggregated,
            short_name: shortName[index],
            name: indicatorName[index],
            step: ele.indicator.step_type,
            id: ele.indicator.id,
            geo_id: ele.indicator.geos[0]?.geo_id,
            geo_name: ele.indicator.geos[0]?.geo_name,
          };
        });

        return {
          description,
          name,
          indicators,
          slug,
        };
      });
    });
  };

  const getDataFromWidget = async (id, e) => {
    return api(`widgets/${id}?locale=${e}`).then((data) => {
      const indicators = data.widget.indicators;
      const slug = data.widget.id;
      const description = data.widget.description;
      const name = data.widget.name;
      const magnituds = [];
      const alias = [];
      const indicatorName = [];
      const shortName = [];
      return Promise.all(
        indicators.map((el) => {
          magnituds.push(el.display_options);
          alias.push(el.alias);
          shortName.push(el.short_name);
          indicatorName.push(el.name);
          return api(`${el.url}&locale=${e}`);
        })
      ).then((data) => {
        const indicators = data.map((ele, index) => {
          const values = ele.indicator.values.map((el) => {
            return {
              x: new Date(el.datetime),
              y: el.value,
            };
          });

          return {
            values,
            time: ele.indicator.tiempo[0].id,
            alias: alias[index],
            display_options: magnituds[index],
            composited: ele.indicator?.composited,
            magnitud: ele.indicator?.magnitud,
            disaggregated: ele.indicator?.disaggregated,
            short_name: shortName[index],
            name: indicatorName[index],
            step: ele.indicator.step_type,
            id: ele.indicator.id,
            geo_id: ele.indicator.geos[0]?.geo_id,
            geo_name: ele.indicator.geos[0]?.geo_name,
          };
        });

        return {
          description,
          name,
          indicators,
          slug,
        };
      });
    });
  };

  const getMoreData = async (params) => {
    const { lang } = params;
    return api(`widgets/11?locale=${lang}`).then((response) => {
      const data = response.widget.indicators.map((el) => {
        return {
          id: el.id,
          name: el.alias,
          url: el.url,
        };
      });
      return data;
    });
  };

  return {
    init: init,
    getArchives: (params) => getArchives(params),
    getDataFromWidget: (id, e) => getDataFromWidget(id, e),
    getVolumenDataFromWidget: (params) => getVolumenDataFromWidget(params),
    getDesvioDataFromWidget: (params) => getDesvioDataFromWidget(params),
    getUnitaryCostDataFromWidget: (params) =>
      getUnitaryCostDataFromWidget(params),
    getMoreData: (params) => getMoreData(params),
    getFacturationDataFromWidget: (params) =>
      getFacturationDataFromWidget(params),
    getAuctionsDataFromWidget: (params) => getAuctionsDataFromWidget(params),
    getErniDataFromWidget: (params) => getErniDataFromWidget(params),
    getAuctionsPortugalDataFromWidget: (params) =>
      getAuctionsPortugalDataFromWidget(params),
  };
})();

export { servicesMercadosPrecios };
