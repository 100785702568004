'use strict';
import { html } from 'lit-html';
import { store } from '../../../app.init';
import upArrow from '../../../assets/images/up-arrow.svg';
import downArrow from '../../../assets/images/down-arrow.svg';

import i18n from 'i18next';

let templateTimeSelector = (handlers, selectorData) => {
  const { openTimeSelector, openTimeHoursSelector } = handlers;

  return html`
    <div id=${selectorData.id} class="esios-toolbar-time">
      <div class="esios-toolbar-time__mod-date" @click="${openTimeSelector}">
        ${selectorData.currentHour}:${selectorData.fixedMinutes
          ? selectorData.fixedMinutes
          : selectorData.currentMinutes}
      </div>
      <div class="esios-toolbar-time__selector">
        <div class="select-time-container">
          <div
            id="hours"
            data-target="hours"
            class="hours custom-select"
            style="${!selectorData.fixedHour &&
            selectorData.optionsHours.length === 0
              ? 'display: none;'
              : ''}"
          >
            <div
              class="selected"
              data-target="hour"
              value="${selectorData.fixedHour
                ? selectorData.fixedHour
                : selectorData.currentHour}"
              @click="${openTimeHoursSelector}"
            >
              ${selectorData.fixedHour
                ? selectorData.fixedHour
                : selectorData.currentHour}
            </div>
            ${!selectorData.fixedHour
              ? html` <div class="select-time-options">
                  ${selectorData.optionsHours.map((el) => {
                    return html`
                      <div class="option" value="${el.value}">${el.label}</div>
                    `;
                  })}
                </div>`
              : null}
          </div>
          <div
            id="minutes"
            data-target="minutes"
            class="minutes custom-select"
            style="${!selectorData.fixedMinutes &&
            selectorData.optionsMinutes.length === 0
              ? 'display: none'
              : ''}"
          >
            <div
              class="selected"
              data-target="minutes"
              value="${selectorData.fixedMinutes
                ? selectorData.fixedMinutes
                : selectorData.currentMinutes}"
              @click="${openTimeHoursSelector}"
              style="cursor: pointer; ${selectorData.fixedMinutes
                ? 'opacity: .5;'
                : ''}"
            >
              ${selectorData.fixedMinutes
                ? selectorData.fixedMinutes
                : selectorData.currentMinutes}
            </div>
            ${!selectorData.fixedMinutes
              ? html` <div class="select-time-options">
                  ${selectorData.optionsMinutes.map((el) => {
                    return html`
                      <div class="option" value="${el.value}">${el.label}</div>
                    `;
                  })}
                </div>`
              : null}
          </div>
        </div>
      </div>
    </div>
  `;
};

export { templateTimeSelector };
