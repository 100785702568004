'use strict';
import i18next from 'i18next';

let templateChart1 = () => {
  return `
  <div id="spinner1" class="spinner-container" style="display: none;">
    <div class="spinner"></div>
  </div>
    <div class="widget-summary gyc-summary">
    <div class="widget-summary-item dispIndi"></div>

    <div class="widget-summary-item instalada">
        <div class="widget-summary-bar gen-bar "></div>

        <span></span>
    </div>
    </div>

    <div id="rectPiePotencia" class="no-data-piePotencia no-data-graphic">
    <div
        class="rect-no-data"
        style="margin-top:26%; width: 89%; margin-right: 3px; margin-left: 3px"
      >
        ${i18next.t('no-data-available')}
      </div>
    </div>
    <div class="chari-chart gen-convencional piePotencia">
    </div>

    <div class="legend-potencial-pie ">
        
    </div>
  `;
};

export { templateChart1 };
