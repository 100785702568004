'use strict';

import i18next from 'i18next';
import { api } from './../../utils/api-interceptor';

let servicesSujetosMercado = (() => {
  let lang = '';

  let init = (e) => llamarServicio(e);
  const llamarServicio = async (e) => {
    lang = e;
    const urlApi =
      `static_pages?slug=${i18next.t('routes.market_subjects')}&temporal=` +
      Math.floor(Math.random() * 1000);

    try {
      const data = await api(urlApi, { hasLang: true });
      return data.content;
    } catch (error) {}
  };

  const getDataTable = async () => {
    let id;
    let data;
    if (i18next.t('routes.lng') === 'en') {
      id = 112;
    } else {
      id = 83;
    }
    const response = await api(`archives/${id}/download_json`);

    if (i18next.t('routes.lng') === 'en') {
      data = response.BalanceResponsibleParties;
    } else {
      data = response.SujetosMercado;
    }
    return data;
  };

  return {
    init: (e) => init(e),
    getData: (e) => getDataTable(e),
  };
})();

export { servicesSujetosMercado };
