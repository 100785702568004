import { html } from 'lit-html';

const If = (condition, component, ...params) => {
  if (condition) {
    return html`${component(...params)}`;
  }
  return null;
};

export default If;
