import { render } from "lit-html";
import { store } from "../../app.init";
import { templateEditUsuario } from "./edit-usuarios.template";

let componentEditUsuario = (function () {
	let context = {};
	let handlers = {};
	let renderSuccess = false;
	
	let preRenderComponent = () => {
		// Asi accedemos a la propiedad del store
		const state = store.getState();
		context.name = state.exampleReducer.name
	};

	let renderComponent = async () => {
		const state = store.getState();
        const user= {
            id: 1,
            name: 'Juan Quintero',
            email: 'juan.quintero@meloinvento.com',
            role: 'admin'
        }
		try {
			// Render the template to the document
			templateEditUsuario();
			renderSuccess = true;
		} catch (error) {
			renderSuccess = false;
		}

	};

	let postRenderComponent = () => {
		const state = store.getState();
		context.name = state.exampleReducer.name
	}



	let init = () => {
		//Call pre render component
		preRenderComponent();

		// Call render component
		renderComponent();

		// Call post render component if it has been rendered
		renderSuccess ? postRenderComponent() : ''
	}


	return {
		render: init
	};
})();

export { componentEditUsuario };