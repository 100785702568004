'use strict';

/**
 * @description Dashboard Component
 * @returns {Object} Devuelve la instancia del componente
 */

import i18n from 'i18next';
import { store } from '../../app.init.js';
import { servicesCodigoConexion } from './codigos_conexion.services.js';
import { templateCodigosConexion } from './codigos_conexion.template.js';
import { componentRapidAccess } from '../../shared/components/rapid-access/rapid-access.component.js';

let componentCodigosConexion = (function () {
  let context = {};
  let handlers = { casa: 'casa' };
  let renderSuccess = false;

  //Accordion
  let setAccordion = (anchor) => {
    $(anchor).accordion({
      active: false,
      collapsible: true,
      icons: false,
      heightStyle: 'content',
    });
  };

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    document.title = `${i18n.t('information_network_codes')} | ${i18n.t(
      'page_subtitle'
    )}`;
    context.name = state.exampleReducer.name;
  };

  let renderComponent = async () => {
    try {
      // Render the template to the document
      const state = store.getState();
      let codigosConexionData = await servicesCodigoConexion.init(
        state.exampleReducer.currentLang
      );

      templateCodigosConexion(context, handlers, codigosConexionData);

      document.getElementById('defaultOpen').removeAttribute('onclick');
      document
        .querySelector('#tab > div > button:nth-child(4)')
        .removeAttribute('onclick');
      document
        .querySelector('#general_information_tab > button')
        .removeAttribute('onclick');
      document
        .querySelector('#general_information_tab > div.tabVertTit > button')
        .removeAttribute('onclick');
      document
        .querySelector('#compliance_monotoring_tab > button')
        .removeAttribute('onclick');
      document
        .querySelector('#compliance_monotoring_tab > div.tabVertTit > button')
        .removeAttribute('onclick');

      function openTab(evt, tabName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName('tabcontent');
        var x = window.matchMedia('(max-width: 540px)');
        for (i = 0; i < tabcontent.length; i++) {
          if (x.matches) {
            tabcontent[i].style.display = 'block';
          } else {
            tabcontent[i].style.display = 'none';
          }
        }
        tablinks = document.getElementsByClassName('tablinks');
        for (i = 0; i < tablinks.length; i++) {
          tablinks[i].className = tablinks[i].className.replace(' active', '');
        }
        document.getElementById(tabName).style.display = 'block';
        evt.currentTarget.className += ' active';
      }
      function openTabVert(evt, tabName) {
        var i, tabcontent, tablinks;
        var flag = document.getElementById(tabName).style.maxHeight;
        tablinks = document.getElementsByClassName('tablinksVert');
        for (i = 0; i < tablinks.length; i++) {
          tablinks[i].className = tablinks[i].className.replace(' active', '');
        }
        if (flag === '0px' || flag === '') {
          document.getElementById(tabName).style.maxHeight = '20000px';
          evt.currentTarget.className += ' active';
        } else {
          document.getElementById(tabName).style.maxHeight = '0px';
        }
      }
      document
        .getElementById('defaultOpen')
        .addEventListener('click', function handleClick(event) {
          openTab(event, 'general_information_tab');
        });

      document
        .querySelector('#tab > div > button:nth-child(4)')
        .addEventListener('click', function handleClick(event) {
          openTab(event, 'compliance_monotoring_tab');
        });

      document
        .querySelector('#general_information_tab > button')
        .addEventListener('click', function handleClick(event) {
          openTabVert(event, 'general_information_tab_vert');
        });

      document
        .querySelector('#general_information_tab > div.tabVertTit > button')
        .addEventListener('click', function handleClick(event) {
          openTabVert(event, 'general_information_tab_vert');
        });

      document
        .querySelector('#compliance_monotoring_tab > button')
        .addEventListener('click', function handleClick(event) {
          openTab(event, 'compliance_monotoring_tab');
        });

      document
        .querySelector('#compliance_monotoring_tab > div.tabVertTit > button')
        .addEventListener('click', function handleClick(event) {
          openTab(event, 'compliance_monotoring_tab');
        });

      document
        .querySelector('#compliance_monotoring_tab > div.tabVertTit > button')
        .addEventListener('click', function handleClick(event) {
          openTabVert(event, 'compliance_monotoring_tab_vert');
        });

      document.getElementById('defaultOpen').click();

      let anchor = document.getElementById('accordion');
      if (anchor) {
        setAccordion(anchor);
      }

      const RapidAccessData = [
        {
          id: '#gen-info',
          title: 'Información general',
        },
        {
          id: '#contactos',
          title: 'Contacto',
        },
        {
          id: '#propuestas',
          title: 'Propuestas de procediminetos de operación',
        },
        {
          id: '#textos',
          title: 'Textos de los códigos de red de conexión europeos',
        },
        {
          id: '#conformidad-sup',
          title: 'Información general',
        },
        {
          id: '#implementacion-proc',
          title: 'Proceso de implementación nacional',
        },
        {
          id: '#otros-hitos',
          title: 'Otros hitos del proceso de implementación de los CRC',
        },
      ];
      componentRapidAccess.render(RapidAccessData);

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = () => {};

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentCodigosConexion };
