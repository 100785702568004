'use strict';

import { render } from 'lit-html';
import { templateButtons } from './buttons.template.js';
import { servicesButtons } from './buttons.services.js';

let componentButtons = (function () {
  /* 
  COMPONENTE BUTTONS (COMO USARLO)
  componentButtons.render(buttons, parent)
  primer parametro:   (un array con los botones o boton que quieres mostrar)
  tercer parametro:   (el id del div donde queremos que muestre el/los boton/es)

  el primer parametro (array de objetos) cada objeto serán las carateristicas del boton que quieras
  ej. si quiero un boton solo y que sea exportar en json seria tal que: [{title:'EXPORTAR JSON',action:'exportJSON',data:'https://urlDeLaApiDondeSeEncuentraElArchivo'}]
  ej. si quiero un boton solo y que ejecute una funcion que hayas hecho seria tal que: [{title:'ACCIONAR',action:'handler',data:funcionSinEjecutar}]
  ej. si quiero tres botones uno para exportar csv otro para exportar a excel y un ultimo que imprima la página seria tal que:
  [{title:'EXPORTAR CSV',action:'exportCSV',data:'https://urlDeLaApiDondeSeEncuentraElArchivo'},
  {title:'EXPORTAR EXCEL',action:'exportExcel',data:'https://urlDeLaApiDondeSeEncuentraElArchivo'},
  {title:'IMPRIMIR',action:'print'}]

  ejemplo de uso en un template
----------------------------------------
  <div id='buttons'>
    ${componentButtons.render([
    {title:'EXPORTAR JSON',action:'exportJSON',data:'archives/83/download_json?locale=es'},
    {title:'EXPORTAR CSV',action:'exportCSV',data:'archives/83/download_json?locale=es'},
    {title:'EXPORTAR EXCEL',action:'exportExcel',data:'archives/83/download_json?locale=es'},
    {title:'DESCARGAR',action:'download',data:'documents/162/download?locale=es'},
    {title:'IMPRIMIR',action:'print'},
    {title:'ACCIONAR',action:'click',data:funcionSinEjecutar},
    {title:'REDIRECCIONAR', action:'redirect', data:'https://www.esios.ree.es/es/sujetos-del-mercado'}
    ],'#buttons')
    }
  </div>
*/

  let renderSuccess = false;
  let preRenderComponent = () => {};

  let renderComponent = (buttons, parent) => {
    try {
      // Render the template to the document
      setTimeout(() => {
        const container = document.querySelector(parent);
        if (container) {
          render(
            // Define a template
            templateButtons(buttons, servicesButtons),
            container
          );
          renderSuccess = true;
        }
      }, 500);
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = () => {};

  let init = (buttons, parent) => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent(buttons, parent);

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: (buttons, parent) => init(buttons, parent),
  };
})();

export { componentButtons };
