'use strict';
import i18next from 'i18next';
import { html } from 'lit-html';
import backImage from '../../assets/images/header-mercados-precios.jpg';
import { componentLayout } from '../../components/layout/layout.component';
import { templateBoxDocument } from '../../shared/components/box-documents/box-documents.template';
import { templateBoxMarket } from '../../shared/components/box-market/box-market.template';
import { templateToolbarTime } from '../../shared/components/toolbarTime/toolbarTime.template';

let templatePrevisionesCircular = (
  handlers,
  objMarket,
  listMarket,
  documents,
  buttons
) => {
  const { onChangeLetra } = handlers;
  return componentLayout.render(
    html`
      <div class="esios-market-prices">
        <div class="esios-market-prices__container wrap">
          <div class="row mb-0">
            <h1 class="esios-market-prices__previsiones">
              ${i18next.t('demand_renewable_forecasts_OS')}
            </h1>
            <br />
            <!-----------------------------COMIENZA EL MENÚ------------------------------->
            <br />
            <div class="tab">
              <button
                class="tablinks active"
                data-tab-target="TAB1"
                @click="${onChangeLetra}"
              >
                ${i18next.t('home')}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="white"
                  class="bi bi-house svg_tab1"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z"
                  />
                </svg>
              </button>
              <button
                class="tablinks"
                data-tab-target="TAB2"
                @click="${onChangeLetra}"
              >
                ${i18next.t('cnmccircular')}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="white"
                  class="bi bi-journal-text svg_tab2"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5"
                  />
                  <path
                    d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"
                  />
                  <path
                    d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"
                  />
                </svg>
              </button>
              <button
                class="tablinks"
                data-tab-target="TAB3"
                @click="${onChangeLetra}"
              >
                ${i18next.t('demand')}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="white"
                  class="bi bi-x-diamond svg_tab3"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M7.987 16a1.526 1.526 0 0 1-1.07-.448L.45 9.082a1.531 1.531 0 0 1 0-2.165L6.917.45a1.531 1.531 0 0 1 2.166 0l6.469 6.468A1.526 1.526 0 0 1 16 8.013a1.526 1.526 0 0 1-.448 1.07l-6.47 6.469A1.526 1.526 0 0 1 7.988 16zM7.639 1.17 4.766 4.044 8 7.278l3.234-3.234L8.361 1.17a.51.51 0 0 0-.722 0zM8.722 8l3.234 3.234 2.873-2.873c.2-.2.2-.523 0-.722l-2.873-2.873zM8 8.722l-3.234 3.234 2.873 2.873c.2.2.523.2.722 0l2.873-2.873zM7.278 8 4.044 4.766 1.17 7.639a.511.511 0 0 0 0 .722l2.874 2.873z"
                  />
                </svg>
              </button>
              <button
                class="tablinks"
                data-tab-target="TAB4"
                @click="${onChangeLetra}"
              >
                ${i18next.t('wind_solar')}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="white"
                  class="bi bi-sun svg_tab4"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"
                  />
                </svg>
              </button>
              <button
                class="tablinks"
                data-tab-target="TAB5"
                @click="${onChangeLetra}"
              >
                Máx./min.
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="white"
                  class="bi bi-plus-slash-minus svg_tab5"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="m1.854 14.854 13-13a.5.5 0 0 0-.708-.708l-13 13a.5.5 0 0 0 .708.708ZM4 1a.5.5 0 0 1 .5.5v2h2a.5.5 0 0 1 0 1h-2v2a.5.5 0 0 1-1 0v-2h-2a.5.5 0 0 1 0-1h2v-2A.5.5 0 0 1 4 1m5 11a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5A.5.5 0 0 1 9 12"
                  />
                </svg>
              </button>
              <button
                class="tablinks"
                data-tab-target="TAB6"
                @click="${onChangeLetra}"
              >
                ${i18next.t('other_provisions')}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="white"
                  class="bi bi-pie-chart svg_tab6"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M7.5 1.018a7 7 0 0 0-4.79 11.566L7.5 7.793V1.018zm1 0V7.5h6.482A7.001 7.001 0 0 0 8.5 1.018zM14.982 8.5H8.207l-4.79 4.79A7 7 0 0 0 14.982 8.5M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8"
                  />
                </svg>
              </button>
            </div>
            <!-----------------------------FIN DEL MENÚ------------------------------->

            <!----------------------------- INCIO DE CONTENIDO INCIO -------------------------->
            <div id="TAB1" class="tabcontent">
              <div class="mp-fifteen-graphs row mb-0">
                <div
                  class="col-sm-6 col-xs-5 mypPrevisionSemanalDemandaWidgetView"
                  id="mypPrevisionSemanalDemandaWidgetView"
                >
                  <h1 class="esios-market-prices__previsiones">
                    ${i18next.t('weekly_demand_forecast')}
                  </h1>

                  <div class="esios-toolbar col-12">
                    <div class="esios-toolbar1"></div>
                    ${templateToolbarTime('volumen')}
                  </div>
                  <div id="spinner26" class="spinner-container">
                    <div class="overlay"></div>
                    <div class="spinner"></div>
                  </div>
                  <div id="widget26">
                    <div
                      id="mypGraphic26"
                      class="chari-chart aspect myp-aspect"
                    >
                      <div
                        id="mypNoDataRect26"
                        class="esios-home__no-data-container_cuadrado hidden"
                      >
                        ${i18next.t('no-data-available')}
                      </div>
                    </div>
                    <ul class="esios-listEnergyItems__list">
                      <div class="row"></div>
                    </ul>
                  </div>
                </div>
                <div
                  class="col-sm-6 col-xs-5 mypPrevisionEolicaWidgetView"
                  id="mypPrevisionEolicaWidgetView"
                >
                  <h1 class="esios-market-prices__previsiones">
                    ${i18next.t('wind_forecast')}
                  </h1>
                  <div class="esios-toolbar col-12" style="z-index: 1">
                    <div class="esios-toolbar2"></div>
                    ${templateToolbarTime('volumen')}
                  </div>
                  <div id="spinner27" class="spinner-container">
                    <div class="overlay"></div>
                    <div class="spinner"></div>
                  </div>
                  <div id="widget27">
                    <div
                      id="mypGraphic27"
                      class="chari-chart aspect myp-aspect"
                    >
                      <div
                        id="mypNoDataRect27"
                        class="esios-home__no-data-container_cuadrado hidden"
                      >
                        ${i18next.t('no-data-available')}
                      </div>
                    </div>
                    <ul class="esios-listEnergyItems__list">
                      <div class="row"></div>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="mp-sixteen-graphs row mb-0 border-top">
                <div
                  class="col-sm-6 col-xs-5 mypPrevisionFotovoltaicaWidgetView"
                  id="mypPrevisionFotovoltaicaWidgetView"
                >
                  <h1 class="esios-market-prices__previsiones">
                    ${i18next.t('photovoltaic_solar_forecast')}
                  </h1>
                  <div class="esios-toolbar col-12">
                    <div class="esios-toolbar3"></div>
                    ${templateToolbarTime('volumen')}
                  </div>
                  <div id="spinner28" class="spinner-container">
                    <div class="overlay"></div>
                    <div class="spinner"></div>
                  </div>
                  <div id="widget28">
                    <div
                      id="mypGraphic28"
                      class="chari-chart aspect myp-aspect"
                    >
                      <div
                        id="mypNoDataRect28"
                        class="esios-home__no-data-container_cuadrado hidden"
                      >
                        ${i18next.t('no-data-available')}
                      </div>
                    </div>
                    <ul class="esios-listEnergyItems__list">
                      <div class="row"></div>
                    </ul>
                  </div>
                </div>
                <div
                  class="col-sm-6 col-xs-5 myPrevisionSolarTerWidgetView"
                  id="myPrevisionSolarTerWidgetView"
                >
                  <h1 class="esios-market-prices__previsiones">
                    ${i18next.t('solar_thermal_forecast')}
                  </h1>
                  <div class="esios-toolbar col-12" style="z-index: 1">
                    <div class="esios-toolbar4"></div>
                    ${templateToolbarTime('volumen')}
                  </div>
                  <div id="spinner29" class="spinner-container">
                    <div class="overlay"></div>
                    <div class="spinner"></div>
                  </div>
                  <div id="widget29">
                    <div
                      id="mypGraphic29"
                      class="chari-chart aspect myp-aspect"
                    >
                      <div
                        id="mypNoDataRect29"
                        class="esios-home__no-data-container_cuadrado hidden"
                      >
                        ${i18next.t('no-data-available')}
                      </div>
                    </div>
                    <ul class="esios-listEnergyItems__list">
                      <div class="row"></div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <!----------------------------- FIN DE CONTENIDO INCIO -------------------------->

            <!----------------------------- INICIO DE CONTENIDO CNMC -------------------------->
            <div id="TAB2" class="tabcontent">
              <p class="titulo-cabecera" style="color:#444">
                ${i18next.t('cnmc')}
              </p>

              <div class="mp-four-graphs row mb-0">
                <div
                  class="col-sm-6 col-xs-5 myPrevisionDiariaD1Wiew"
                  id="myPrevisionDiariaD1Wiew"
                >
                  <h1 class="esios-market-prices__previsiones">
                    ${i18next.t('daily_forecast_D+1_demand')}
                  </h1>
                  <div class="esios-toolbar col-12">
                    <div class="esios-toolbar5"></div>
                    ${templateToolbarTime('volumen')}
                  </div>
                  <div id="spinner30" class="spinner-container">
                    <div class="overlay"></div>
                    <div class="spinner"></div>
                  </div>
                  <div id="widget30">
                    <div
                      id="mypGraphic30"
                      class="chari-chart aspect myp-aspect"
                    >
                      <div
                        id="mypNoDataRect30"
                        class="esios-home__no-data-container_cuadrado hidden"
                      >
                        ${i18next.t('no-data-available')}
                      </div>
                    </div>
                    <ul class="esios-listEnergyItems__list">
                      <div class="row"></div>
                    </ul>
                  </div>
                </div>
                <div
                  class="col-sm-6 col-xs-5 myPrevisionDiariaD1EolicaWiew"
                  id="myPrevisionDiariaD1EolicaWiew"
                >
                  <h1 class="esios-market-prices__previsiones">
                    ${i18next.t('daily-forecast-d1-wind-plus-photovoltaic')}
                  </h1>
                  <div class="esios-toolbar col-12" style="z-index: 1">
                    <div class="esios-toolbar6"></div>
                    ${templateToolbarTime('volumen')}
                  </div>
                  <div id="spinner31" class="spinner-container">
                    <div class="overlay"></div>
                    <div class="spinner"></div>
                  </div>
                  <div id="widget31">
                    <div
                      id="mypGraphic31"
                      class="chari-chart aspect myp-aspect"
                    >
                      <div
                        id="mypNoDataRect31"
                        class="esios-home__no-data-container_cuadrado hidden"
                      >
                        ${i18next.t('no-data-available')}
                      </div>
                    </div>
                    <ul class="esios-listEnergyItems__list">
                      <div class="row"></div>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="mp-five-graphs row mb-0 border-top">
                <div
                  class="col-sm-6 col-xs-5 myPrevisionIntraDiariaH3DemandaWiew"
                  id="myPrevisionIntraDiariaH3DemandaWiew"
                >
                  <h1 class="esios-market-prices__previsiones">
                    ${i18next.t('intraday_forecast_H+3_demand')}
                  </h1>
                  <div class="esios-toolbar col-12">
                    <div class="esios-toolbar7"></div>
                    ${templateToolbarTime('volumen')}
                  </div>
                  <div id="spinner32" class="spinner-container">
                    <div class="overlay"></div>
                    <div class="spinner"></div>
                  </div>
                  <div id="widget32">
                    <div
                      id="mypGraphic32"
                      class="chari-chart aspect myp-aspect"
                    >
                      <div
                        id="mypNoDataRect32"
                        class="esios-home__no-data-container_cuadrado hidden"
                      >
                        ${i18next.t('no-data-available')}
                      </div>
                    </div>
                    <ul class="esios-listEnergyItems__list">
                      <div class="row"></div>
                    </ul>
                  </div>
                </div>
                <div
                  class="col-sm-6 col-xs-5 myPrevisionIntraDiariaH3DemandaFotovolWiew"
                  id="myPrevisionIntraDiariaH3DemandaFotovolWiew"
                >
                  <h1 class="esios-market-prices__previsiones">
                    ${i18next.t(
                      'intraday_forecast_H+3_more_photovoltaic_demand'
                    )}
                  </h1>
                  <div class="esios-toolbar col-12" style="z-index: 1">
                    <div class="esios-toolbar8"></div>
                    ${templateToolbarTime('volumen')}
                  </div>
                  <div id="spinner33" class="spinner-container">
                    <div class="overlay"></div>
                    <div class="spinner"></div>
                  </div>
                  <div id="widget33">
                    <div
                      id="mypGraphic33"
                      class="chari-chart aspect myp-aspect"
                    >
                      <div
                        id="mypNoDataRect33"
                        class="esios-home__no-data-container_cuadrado hidden"
                      >
                        ${i18next.t('no-data-available')}
                      </div>
                    </div>
                    <ul class="esios-listEnergyItems__list">
                      <div class="row"></div>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="mp-six-graphs row mb-0 border-top">
                <div
                  class="col-sm-6 col-xs-5 myPrevisionDemandaAnualWiew"
                  id="myPrevisionDemandaAnualWiew"
                >
                  <h1 class="esios-market-prices__previsiones">
                    ${i18next.t('annual_demand_forecast')}
                  </h1>
                  <div class="esios-toolbar col-12">
                    <div class="esios-toolbar9"></div>
                    ${templateToolbarTime('volumen')}
                  </div>
                  <div id="spinner12" class="spinner-container">
                    <div class="overlay"></div>
                    <div class="spinner"></div>
                  </div>
                  <div id="widget12">
                    <div
                      id="mypGraphic12"
                      class="chari-chart aspect myp-aspect"
                    >
                      <div
                        id="mypNoDataRect12"
                        class="esios-home__no-data-container_cuadrado hidden"
                      >
                        ${i18next.t('no-data-available')}
                      </div>
                    </div>
                    <ul class="esios-listEnergyItems__list">
                      <div class="row"></div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <!----------------------------- INICIO DE CONTENIDO DEMANDA -------------------------->
            <div id="TAB3" class="tabcontent">
              <div class="mp-seven-graphs row mb-0">
                <div
                  class="col-sm-6 col-xs-5 myDemandaPrevistaPeninsularWiew"
                  id="myDemandaPrevistaPeninsularWiew"
                >
                  <h1 class="esios-market-prices__previsiones">
                    ${i18next.t('peninsular_forecast_demand')}
                  </h1>
                  <div class="esios-toolbar col-12">
                    <div class="esios-toolbar10"></div>
                    ${templateToolbarTime('volumen')}
                  </div>
                  <div id="spinner13" class="spinner-container">
                    <div class="overlay"></div>
                    <div class="spinner"></div>
                  </div>
                  <div id="widget13">
                    <div
                      id="mypGraphic13"
                      class="chari-chart aspect myp-aspect"
                    >
                      <div
                        id="mypNoDataRect13"
                        class="esios-home__no-data-container_cuadrado hidden"
                      >
                        ${i18next.t('no-data-available')}
                      </div>
                    </div>
                    <ul class="esios-listEnergyItems__list">
                      <div class="row"></div>
                    </ul>
                  </div>
                </div>
                <div
                  class="col-sm-6 col-xs-5 myDemandaPrevistaSNPWiew"
                  id="myDemandaPrevistaSNPWiew"
                >
                  <h1 class="esios-market-prices__previsiones">
                    ${i18next.t('snp_forecast_demand')}
                  </h1>
                  <div class="esios-toolbar col-12" style="z-index: 1">
                    <div class="esios-toolbar11"></div>
                    ${templateToolbarTime('volumen')}
                  </div>
                  <div id="spinner14" class="spinner-container">
                    <div class="overlay"></div>
                    <div class="spinner"></div>
                  </div>
                  <div id="widget14">
                    <div
                      id="mypGraphic14"
                      class="chari-chart aspect myp-aspect"
                    >
                      <div
                        id="mypNoDataRect14"
                        class="esios-home__no-data-container_cuadrado hidden"
                      >
                        ${i18next.t('no-data-available')}
                      </div>
                    </div>
                    <ul class="esios-listEnergyItems__list">
                      <div class="row"></div>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="mp-eight-graphs row mb-0 border-top">
                <div
                  class="col-sm-6 col-xs-5 myDemandaDiariaElectricaPeninsularWiew"
                  id="myDemandaDiariaElectricaPeninsularWiew"
                >
                  <h1 class="esios-market-prices__previsiones">
                    ${i18next.t(
                      'daily_forecast_of_peninsular_electricity_demand'
                    )}
                  </h1>
                  <div class="esios-toolbar col-12">
                    <div class="esios-toolbar12"></div>
                    ${templateToolbarTime('volumen')}
                  </div>
                  <div id="spinner15" class="spinner-container">
                    <div class="overlay"></div>
                    <div class="spinner"></div>
                  </div>
                  <div id="widget15">
                    <div
                      id="mypGraphic15"
                      class="chari-chart aspect myp-aspect"
                    >
                      <div
                        id="mypNoDataRect15"
                        class="esios-home__no-data-container_cuadrado hidden"
                      >
                        ${i18next.t('no-data-available')}
                      </div>
                    </div>
                    <ul class="esios-listEnergyItems__list">
                      <div class="row"></div>
                    </ul>
                  </div>
                </div>
                <div
                  class="col-sm-6 col-xs-5 myDemandaMensualElectricaPeninsularWiew"
                  id="myDemandaMensualElectricaPeninsularWiew"
                >
                  <h1 class="esios-market-prices__previsiones">
                    ${i18next.t(
                      'monthly_forecast_of_peninsular_electricity_demand'
                    )}
                  </h1>
                  <div class="esios-toolbar col-12" style="z-index: 1">
                    <div class="esios-toolbar13"></div>
                    ${templateToolbarTime('volumen')}
                  </div>
                  <div id="spinner16" class="spinner-container">
                    <div class="overlay"></div>
                    <div class="spinner"></div>
                  </div>
                  <div id="widget16">
                    <div
                      id="mypGraphic16"
                      class="chari-chart aspect myp-aspect"
                    >
                      <div
                        id="mypNoDataRect16"
                        class="esios-home__no-data-container_cuadrado hidden"
                      >
                        ${i18next.t('no-data-available')}
                      </div>
                    </div>
                    <ul class="esios-listEnergyItems__list">
                      <div class="row"></div>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="mp-nine-graphs row mb-0 border-top">
                <div
                  class="col-sm-6 col-xs-5 myDemandaSemanalElectricaPeninsularWiew"
                  id="myDemandaSemanalElectricaPeninsularWiew"
                >
                  <h1 class="esios-market-prices__previsiones">
                    ${i18next.t(
                      'weekly_forecast_of_peninsular_electricity_demand'
                    )}
                  </h1>
                  <div class="esios-toolbar col-12">
                    <div class="esios-toolbar14"></div>
                    ${templateToolbarTime('volumen')}
                  </div>
                  <div id="spinner17" class="spinner-container">
                    <div class="overlay"></div>
                    <div class="spinner"></div>
                  </div>
                  <div id="widget17">
                    <div
                      id="mypGraphic17"
                      class="chari-chart aspect myp-aspect"
                    >
                      <div
                        id="mypNoDataRect17"
                        class="esios-home__no-data-container_cuadrado hidden"
                      >
                        ${i18next.t('no-data-available')}
                      </div>
                    </div>
                    <ul class="esios-listEnergyItems__list">
                      <div class="row"></div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <!----------------------------- INICIO DE CONTENIDO ECOLICA/SOLAR -------------------------->
            <div id="TAB4" class="tabcontent">
              <div class="mp-ten-graphs row mb-0">
                <div
                  class="col-sm-6 col-xs-5 myPrevisionMaximaAnualPeninsularWiew"
                  id="myPrevisionMaximaAnualPeninsularWiew"
                >
                  <h1 class="esios-market-prices__previsiones">
                    ${i18next.t('wind_forecast')}
                  </h1>
                  <div class="esios-toolbar col-12">
                    <div class="esios-toolbar15"></div>
                    ${templateToolbarTime('volumen')}
                  </div>
                  <div id="spinner18" class="spinner-container">
                    <div class="overlay"></div>
                    <div class="spinner"></div>
                  </div>
                  <div id="widget18">
                    <div
                      id="mypGraphic18"
                      class="chari-chart aspect myp-aspect"
                    >
                      <div
                        id="mypNoDataRect18"
                        class="esios-home__no-data-container_cuadrado hidden"
                      >
                        ${i18next.t('no-data-available')}
                      </div>
                    </div>
                    <ul class="esios-listEnergyItems__list">
                      <div class="row"></div>
                    </ul>
                  </div>
                </div>
                <div
                  class="col-sm-6 col-xs-5 myGeneracionPrevistaSolarWiew"
                  id="myGeneracionPrevistaSolarWiew"
                >
                  <h1 class="esios-market-prices__previsiones">
                    ${i18next.t('solar_planned_generación')}
                  </h1>
                  <div class="esios-toolbar col-12" style="z-index: 1">
                    <div class="esios-toolbar16"></div>
                    ${templateToolbarTime('volumen')}
                  </div>
                  <div id="spinner19" class="spinner-container">
                    <div class="overlay"></div>
                    <div class="spinner"></div>
                  </div>
                  <div id="widget19">
                    <div
                      id="mypGraphic19"
                      class="chari-chart aspect myp-aspect"
                    >
                      <div
                        id="mypNoDataRect19"
                        class="esios-home__no-data-container_cuadrado hidden"
                      >
                        ${i18next.t('no-data-available')}
                      </div>
                    </div>
                    <ul class="esios-listEnergyItems__list">
                      <div class="row"></div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <!----------------------------- INICIO DE CONTENIDO MAX/MIN -------------------------->
            <div id="TAB5" class="tabcontent">
              <div class="mp-eleven-graphs row mb-0">
                <div
                  class="col-sm-6 col-xs-5 myPrevisionMaximaAnualPeninsular2Wiew"
                  id="myPrevisionMaximaAnualPeninsular2Wiew"
                >
                  <h1 class="esios-market-prices__previsiones">
                    ${i18next.t(
                      'maximum_annual_forecast_of_peninsular_electricity_demand'
                    )}
                  </h1>
                  <div class="esios-toolbar col-12">
                    <div class="esios-toolbar17"></div>
                    ${templateToolbarTime('volumen')}
                  </div>
                  <div id="spinner20" class="spinner-container">
                    <div class="overlay"></div>
                    <div class="spinner"></div>
                  </div>
                  <div id="widget20">
                    <div
                      id="mypGraphic20"
                      class="chari-chart aspect myp-aspect"
                    >
                      <div
                        id="mypNoDataRect20"
                        class="esios-home__no-data-container_cuadrado hidden"
                      >
                        ${i18next.t('no-data-available')}
                      </div>
                    </div>
                    <ul class="esios-listEnergyItems__list">
                      <div class="row"></div>
                    </ul>
                  </div>
                </div>
                <div
                  class="col-sm-6 col-xs-5 myPrevisionMinimaAnualPeninsularWiew"
                  id="myPrevisionMinimaAnualPeninsularWiew"
                >
                  <h1 class="esios-market-prices__previsiones">
                    ${i18next.t(
                      'minimum_annual_forecast_of_peninsular_electricity_demand'
                    )}
                  </h1>
                  <div class="esios-toolbar col-12" style="z-index: 1">
                    <div class="esios-toolbar18"></div>
                    ${templateToolbarTime('volumen')}
                  </div>
                  <div id="spinner21" class="spinner-container">
                    <div class="overlay"></div>
                    <div class="spinner"></div>
                  </div>
                  <div id="widget21">
                    <div
                      id="mypGraphic21"
                      class="chari-chart aspect myp-aspect"
                      style="margin-top:2px !important"
                    >
                      <div
                        id="mypNoDataRect21"
                        class="esios-home__no-data-container_cuadrado hidden"
                      >
                        ${i18next.t('no-data-available')}
                      </div>
                    </div>
                    <ul class="esios-listEnergyItems__list">
                      <div class="row"></div>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="mp-twelve-graphs row mb-0 border-top">
                <div
                  class="col-sm-6 col-xs-5 myPrevisionMaximaMensualPeninsularWiew"
                  id="myPrevisionMaximaMensualPeninsularWiew"
                >
                  <h1 class="esios-market-prices__previsiones">
                    ${i18next.t(
                      'maximum_monthly_forecast_of_peninsular_electricity_demand'
                    )}
                  </h1>
                  <div class="esios-toolbar col-12">
                    <div class="esios-toolbar19"></div>
                    ${templateToolbarTime('volumen')}
                  </div>
                  <div id="spinner22" class="spinner-container">
                    <div class="overlay"></div>
                    <div class="spinner"></div>
                  </div>
                  <div id="widget22">
                    <div
                      id="mypGraphic22"
                      class="chari-chart aspect myp-aspect"
                    >
                      <div
                        id="mypNoDataRect22"
                        class="esios-home__no-data-container_cuadrado hidden"
                      >
                        ${i18next.t('no-data-available')}
                      </div>
                    </div>
                    <ul class="esios-listEnergyItems__list">
                      <div class="row"></div>
                    </ul>
                  </div>
                </div>
                <div
                  class="col-sm-6 col-xs-5 myPrevisionMinimaMensualPeninsularWiew"
                  id="myPrevisionMinimaMensualPeninsularWiew"
                >
                  <h1 class="esios-market-prices__previsiones">
                    ${i18next.t(
                      'minimum_monthly_forecast_of_peninsular_electricity_demand'
                    )}
                  </h1>
                  <div class="esios-toolbar col-12" style="z-index: 1">
                    <div class="esios-toolbar20"></div>
                    ${templateToolbarTime('volumen')}
                  </div>
                  <div id="spinner23" class="spinner-container">
                    <div class="overlay"></div>
                    <div class="spinner"></div>
                  </div>
                  <div id="widget23">
                    <div
                      id="mypGraphic23"
                      class="chari-chart aspect myp-aspect"
                    >
                      <div
                        id="mypNoDataRect23"
                        class="esios-home__no-data-container_cuadrado hidden"
                      >
                        ${i18next.t('no-data-available')}
                      </div>
                    </div>
                    <ul class="esios-listEnergyItems__list">
                      <div class="row"></div>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="mp-thirteen-graphs row mb-0 border-top">
                <div
                  class="col-sm-6 col-xs-5 myMargenGeneracionWiew"
                  id="myMargenGeneracionWiew"
                >
                  <h1 class="esios-market-prices__previsiones">
                    ${i18next.t('available_generation_margin_Maximum_forecast')}
                  </h1>
                  <div class="esios-toolbar col-12">
                    <div class="esios-toolbar21"></div>
                    ${templateToolbarTime('volumen')}
                  </div>
                  <div id="spinner24" class="spinner-container">
                    <div class="overlay"></div>
                    <div class="spinner"></div>
                  </div>
                  <div id="widget24">
                    <div
                      id="mypGraphic24"
                      class="chari-chart aspect myp-aspect"
                    >
                      <div
                        id="mypNoDataRect24"
                        class="esios-home__no-data-container_cuadrado hidden"
                      >
                        ${i18next.t('no-data-available')}
                      </div>
                    </div>
                    <ul class="esios-listEnergyItems__list">
                      <div class="row"></div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <!----------------------------- INICIO DE CONTENIDO OTRAS PREVISIONES -------------------------->
            <div id="TAB6" class="tabcontent">
              <div class="mp-fourteen-graphs row mb-0 border-top">
                <div
                  class="col-sm-6 col-xs-5 myPrevisonDemandaResidualWiew"
                  id="myPrevisonDemandaResidualWiew"
                >
                  <h1 class="esios-market-prices__previsiones">
                    ${i18next.t('residual_demand_forecast')}
                  </h1>
                  <div class="esios-toolbar col-12">
                    <div class="esios-toolbar22"></div>
                    ${templateToolbarTime('volumen')}
                  </div>
                  <div id="spinner25" class="spinner-container">
                    <div class="overlay"></div>
                    <div class="spinner"></div>
                  </div>
                  <div id="widget25">
                    <div
                      id="mypGraphic25"
                      class="chari-chart aspect myp-aspect"
                    >
                      <div
                        id="mypNoDataRect25"
                        class="esios-home__no-data-container_cuadrado hidden"
                      >
                        ${i18next.t('no-data-available')}
                      </div>
                    </div>
                    <ul class="esios-listEnergyItems__list">
                      <div class="row"></div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="myp__modals"></div>
    `,
    {
      text: '',
      image: backImage,
      showBanner: true,
      showFooterImage: true,
    }
  );
};

export { templatePrevisionesCircular };
