'use strict';
import i18next from 'i18next';
import { html } from 'lit-html';
import { gestionDemandaService } from './gestionDemanda.service';

let gestionDemandaTabContent = (() => {
  let init = () => {};

  const returnTabContent = (tab, data = '', activations) => {
    if (tab === 1) {
      return `
      <div class="esios-gd__secondary-values">
      ${data}
      </div>`;
    } else if (tab === 2) {
      return `
      <div class="esios-gd__secondary-values">
      ${data}
      </div>`;
    } else if (tab === 3) {
      return `
      <div class="esios-gd__secondary-values">
      <p>${data}</p>
      
      <div>
        <strong>${i18next.t(
          'tab3-gd-content2'
        )}<strong class='nActivations'>${activations}</strong>${i18next.t(
        'tab3-gd-content3'
      )}</strong>
      <div>
      <button type="button" class="esios-boxdoc__button">
        <a
          class="esios-boxdoc__btn-info"
          href="${i18next.t('routes.lng')}/${i18next.t(
        'routes.service_activation'
      )}"
          target="_self"
          >${i18next.t('tab3-gd-content4')}
        </a>
      </button>
      </div>`;
    } else if (tab == '3-1') {
      return `
      <div style="margin-top: 5px;">
        <strong>${i18next.t(
          'tab3-gd-content2'
        )}<strong class='nActivations'>${activations}</strong>${i18next.t(
        'tab3-gd-content3'
      )}</strong>
      </div>
      <div style="display: flex; justify-content: center;">
        <button type="button" class="esios-boxdoc__button">
          <a
            class="esios-boxdoc__btn-info"
            href="${i18next.t('routes.lng')}/${i18next.t(
        'routes.service_activation'
      )}"
            target="_self"
            >${i18next.t('tab3-gd-content4')}
          </a>
        </button>
      </div>`;
    } else if (tab == '3-2') {
      return `
      <div class="esios-gd__secondary-values">
        <p>${i18next.t('tab3-gd-content1')}</p>
      </div>`;
    } else if (tab === 4) {
      return `
        <div class="esios-gd__secondary-values">
          ${data}
        </div>
      `;
    } else if (tab === 5) {
      return `
        <div  class="esios-gd__secondary-values">
          ${data}
        </div>
      `;
    } else if (tab === 6) {
      return `
        <div class="esios-gd__secondary-values">
          ${data}
        </div>
      `;
    } else if (tab === 'docs') {
      return [
        {
          title: 'Orden SND-260-2020',
          link: `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.documentation'
          )}/orden-snd-260-2020`,
        },
        {
          title: 'Resolución 18275 Procedimiento Operación 15.2',
          link: `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.documentation'
          )}/resolucion-18275-procedimiento-operacion-15-2`,
        },
        {
          title: 'Guía de la prestación del servicio de interrumpibilidad',
          link: `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.documentation'
          )}/guia-de-la-prestacion-del-servicio-de-interrumpibilidad`,
        },
        {
          title: 'Texto consolidado Orden IET 2013-2013',
          link: `/${i18next.t('routes.lng')}/${i18next.t(
            'routes.documentation'
          )}/texto-consolidado-orden-iet-2013-2013`,
        },
      ];
    }
  };

  return {
    init: init,
    returnTabContent: returnTabContent,
  };
})();

export { gestionDemandaTabContent };
