'use strict';

import i18n from 'i18next';
import moment from 'moment-timezone';
import { TIME_VALUES } from '../shared/enum/time-values';
let Utils = (function () {
  let init = () => {
    //Code example
  };

  /**
   * Adds time to a date. Modelled after MySQL DATE_ADD function.
   * Example: dateAdd(new Date(), 'minute', 30)  //returns 30 minutes from now.
   *
   * @param date  Date to start with
   * @param interval  One of: year, quarter, month, week, day, hour, minute, second
   * @param units  Number of units of the given interval to add.
   */
  const dateAdd = (date, interval, units) => {
    if (!(date instanceof Date)) return undefined;
    var ret = new Date(date); //don't change original date
    var checkRollover = function () {
      if (ret.getDate() != date.getDate()) ret.setDate(0);
    };
    switch (String(interval).toLowerCase()) {
      case 'year':
        ret.setFullYear(ret.getFullYear() + units);
        checkRollover();
        break;
      case 'quarter':
        ret.setMonth(ret.getMonth() + 3 * units);
        checkRollover();
        break;
      case 'month':
        ret.setMonth(ret.getMonth() + units);
        checkRollover();
        break;
      case 'week':
        ret.setDate(ret.getDate() + 7 * units);
        break;
      case 'day':
        ret.setDate(ret.getDate() + units);
        break;
      case 'hour':
        ret.setTime(ret.getTime() + units * 3600000);
        break;
      case 'minute':
        ret.setTime(ret.getTime() + units * 60000);
        break;
      case 'second':
        ret.setTime(ret.getTime() + units * 1000);
        break;
      default:
        ret = undefined;
        break;
    }
    return ret;
  };

  /**
   * Detecting if the device is a mobile or a tablet
   */
  const isMobileDevice = () => {
    return /(iPhone|iPod|iPad|(?=.*\bAndroid\b)(?=.*\bMobile\b)|Android|(?=.*\bAndroid\b)(?=.*\bSD4930UR\b)|(?=.*\bAndroid\b)(?=.*\b(?:KFOT|KFTT|KFJWI|KFJWA|KFSOWI|KFTHWI|KFTHWA|KFAPWI|KFAPWA|KFARWI|KFASWI|KFSAWI|KFSAWA)\b)|IEMobile|(?=.*\bWindows\b)(?=.*\bARM\b)|BlackBerry|BB10|Opera Mini|(CriOS|Chrome)(?=.*\bMobile\b)|(?=.*\bFirefox\b)(?=.*\bMobile\b)|(?:Nexus 7|BNTV250|Kindle Fire|Silk|GT-P1000))/i.test(
      navigator.userAgent
    );
  };
  const isTablet = () => {
    return (
      !!navigator.userAgent.match(
        /iPad|iPhone|iPod|Android|BlackBerry|webOS/i
      ) &&
      screen.width > 540 &&
      screen.width <= 1280
    );
  };
  function toSpainDate(date) {
    var spainOffset = moment(date).tz('Europe/Madrid').utcOffset() * 60 * 1000;
    var userOffset = date.getTimezoneOffset() * 60 * 1000;
    return new Date(date.getTime() + userOffset + spainOffset);
  }
  function removeAccents(str) {
    return str?.normalize('NFD').replace(/[\u0300-\u036f-.]/g, '');
  }

  function generateRandomId(length = 5) {
    return Array(length)
      .fill()
      .map(() =>
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.charAt(
          Math.random() * 62
        )
      )
      .join('');
  }

  const createFilterModel = (data, filters, filterAttrs) => {
    data.forEach((element) => {
      for (const attr of filterAttrs) {
        const value = element[attr.label];

        if (!filters[attr.value].some((e) => e.value === value)) {
          filters[attr.value].push({ label: value, value });
        }
      }
    });
    const result = filterAttrs.map((at) => {
      const orderedF = filters[at.value].sort((a, b) => {
        const comma2FloatA = a.label.split('.').join('').split(',').join('.');
        const comma2FloatB = b.label.split('.').join('').split(',').join('.');

        if (isNaN(comma2FloatA) || isNaN(comma2FloatB)) {
          if (a.label < b.label) {
            return -1;
          }
          return 1;
        } else {
          if (Number(comma2FloatA) < Number(comma2FloatB)) {
            return -1;
          }
          return 1;
        }
      });

      return {
        id: at.id,
        label: '',
        options: [{ label: 'show_all', value: '' }, ...orderedF],
      };
    });
    return result;
  };

  /**
   * Transform a number to commas format
   * @param  {String} value
   * @param  {Number} decimals  Number of decimals
   * @param  {String} type  Could be a "comma" or a "dot"
   */
  function toNumber(value, decimals = 0, type = 'comma', numZero = false) {
    let strToNumber;
    let newValue;
    if (value == 0.0) {
      if (numZero) {
        return value;
      }
    }

    if (value !== 0) {
      if (value !== false && value !== true && !isNaN(parseFloat(value))) {
        strToNumber = Number(value);

        if (strToNumber === 0) {
          return '-';
        }

        if (strToNumber !== null && strToNumber !== undefined) {
          newValue = value;
        }
      } else {
        return '-';
      }
    }

    let lang = i18n.language;
    if (type === 'comma') {
      if (lang === 'es') {
        newValue = new Intl.NumberFormat('de-DE', {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        }).format(value);
      } else {
        newValue = new Intl.NumberFormat('en-ES', {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        }).format(value);
      }
    } else if (type === 'dot') {
      if (lang === 'es') {
        newValue = new Intl.NumberFormat('en-ES', {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        }).format(value);
      } else {
        newValue = new Intl.NumberFormat('de-DE', {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        }).format(value);
      }
    }
    return newValue;
  }

  const getArrayOfHours = () => {
    let hoursArray = [];

    for (let index = 0; index < 24; index++) {
      hoursArray.push({
        label: `${index < 10 ? `0${index}` : `${index}`}`,
        value: `${index < 10 ? `0${index}` : `${index}`}`,
      });
    }

    return hoursArray;
  };

  const getArrayOfMinutes = (step = 10) => {
    let minutesArray = [];

    for (let index = 0; index * step < 60; index++) {
      minutesArray.push({
        label: `${index * step < 10 ? '0' : ''}${index * step}`,
        value: `${index * step < 10 ? '0' : ''}${index * step}`,
      });
    }
    return minutesArray;
  };

  const getArrayOfMonths = () => {
    let monthsArray = [
      {
        label: 'january',
        value: '01',
      },
      {
        label: 'february',
        value: '02',
      },
      {
        label: 'march',
        value: '03',
      },
      {
        label: 'april',
        value: '04',
      },
      {
        label: 'may',
        value: '05',
      },
      {
        label: 'june',
        value: '06',
      },
      {
        label: 'july',
        value: '07',
      },
      {
        label: 'august',
        value: '08',
      },
      {
        label: 'september',
        value: '09',
      },
      {
        label: 'october',
        value: '10',
      },
      {
        label: 'november',
        value: '11',
      },
      {
        label: 'december',
        value: '12',
      },
    ];

    return monthsArray;
  };

  const getHoursOfDay = (day, format) => {
    let m = moment(day, format);
    let a = moment(m).startOf('day');
    let b = moment(m).add(1, 'day').startOf('day');
    return b.diff(a, 'hours'); // 23, 24, 25, etc.
  };

  const getArrayOfYears = () => {
    const years = [];
    const dateStart = moment('2010-01-01');
    const dateEnd = moment().add(20, 'y');
    while (dateEnd.diff(dateStart, 'years') >= 0) {
      years.push({
        label: dateStart.format('YYYY'),
        value: dateStart.format('YYYY'),
      });
      dateStart.add(1, 'year');
    }
    return years;
  };

  const getUrlAnalysis = (mainIndicators) => {
    if (mainIndicators.length == 0) return '';
    let url = `/${i18n.t('routes.lng')}/${i18n.t('routes.analysis')}/`;
    mainIndicators.forEach(({ id }, index) => {
      url =
        index == 0
          ? url + id
          : index == 1
          ? url + '?compare_indicators=' + id
          : url + ',' + id;
    });
    return url;
  };

  const changeStyles = (data) => {
    const { show, hide } = data;
    hide.forEach((el) => {
      if (document.querySelector(el)) {
        document.querySelector(el).style.display = 'none';
      }
    });
    show.forEach((el) => {
      let values = el.split('#');
      if (document.querySelector(values[0])) {
        if (values.length > 1) {
          document.querySelector(values[0]).style.gridColumn = values[1];
          document.querySelector(values[0]).style.display = 'block';
        } else {
          document.querySelector(el).style.display = 'block';
        }
      }
    });
  };

  const getTimeValue = (timeID) => {
    let timeValue = TIME_VALUES[timeID];
    return timeValue ? timeValue : 0;
  };

  const spinnerStatus = (id, show) =>
    (document.getElementById(id).style.display = show ? 'flex' : 'none');

  const noDataStatus = (id, show) => {
    if (!!document.getElementById(id)) {
      document.getElementById(id).style.display = show ? 'block' : 'none';
    }
  };

  const setNoDataStatus = (data, id) => {
    if (!!document.getElementById(id)) {
      if (
        data.indicators?.every(
          (indicator) => indicator.print_values.length == 0
        )
      ) {
        Utils.noDataStatus('rect' + id, true);
        document.querySelector('#widget' + id).classList.add('no-data-graphic');
        if (!!document.querySelector('#widget' + id + ' > svg')) {
          document
            .querySelector('#widget' + id + ' > svg')
            .classList.add('hidden');
        }
      } else {
        Utils.noDataStatus('rect' + id, false);
        document
          .querySelector('#widget' + id)
          .classList.remove('no-data-graphic');
        if (!!document.querySelector('#widget' + id + ' > svg')) {
          document
            .querySelector('#widget' + id + ' > svg')
            .classList.remove('hidden');
        }
      }
    }
  };

  const showAndHideGraphLines = (widgetId, eyeClassList) => {
    const ulList = document.querySelector(eyeClassList);
    ulList.addEventListener('click', (e) => {
      let itemId = e.target.getAttribute('data-id');

      if (
        document.querySelector(`${widgetId} .series #serie-${itemId}`) !== null
      ) {
        document
          .querySelector(`${widgetId} .series #serie-${itemId}`)
          .classList.toggle('hidden');
      } else {
        document
          .querySelector(`${widgetId} .series #area-${itemId}`)
          .classList.toggle('hidden');
      }

      e.target.classList.toggle('disabled');
    });
  };

  const showAndHideGraphLinesEyes = (widgetId, allEyes) => {
    const eyes = document.querySelectorAll(allEyes);
    eyes.forEach((eye) => {
      if (eye.getAttribute('listener')) {
        eye.classList.remove('disabled');
        return;
      }
      eye.addEventListener('click', (e) => {
        let itemId = e.target.getAttribute('data-id');
        if (
          document.querySelector(`${widgetId} .series #serie-${itemId}`) !==
          null
        ) {
          document
            .querySelector(`${widgetId} .series #serie-${itemId}`)
            ?.classList.toggle('hidden');
          document
            .querySelector(`${widgetId} .series #area-${itemId}`)
            ?.classList.toggle('hidden');
          // not used in gd
          document
            .querySelector(`${widgetId} .series #serie-${itemId}border`)
            ?.classList.toggle('hidden');
          document
            .querySelector(`${widgetId} .series #area-${itemId}border`)
            ?.classList.toggle('hidden');
        } else {
          document
            .querySelector(`${widgetId} .series #area-${itemId}`)
            ?.classList.toggle('hidden');
          document
            .querySelector(`${widgetId} .series #area-${itemId}border`)
            ?.classList.toggle('hidden');
        }
        e.target.classList.toggle('disabled');
      });
      eye.setAttribute('listener', true);
    });
  };

  const datesMatch = (currentDate) => {
    let now = moment().tz('Europe/Madrid').format('DD-MM-YYYY');
    return now === currentDate ? true : false;
  };

  const dateToUTCString = (date) =>
    new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
      )
    ).toISOString();

  const renderDatePickers = (datepikersArr, maxDate, minDate) => {
    datepikersArr.forEach((el) =>
      $(`#${el}`).datepicker({
        firstDay: 1,
        dateFormat: 'dd/mm/yy',
        altFormat: 'dd / mm / yy',
        monthNames: [
          i18n.t('january'),
          i18n.t('february'),
          i18n.t('march'),
          i18n.t('april'),
          i18n.t('ma'),
          i18n.t('june'),
          i18n.t('july'),
          i18n.t('august'),
          i18n.t('september'),
          i18n.t('october'),
          i18n.t('november'),
          i18n.t('december'),
        ],
        monthNamesShort: [
          i18n.t('jan'),
          i18n.t('feb'),
          i18n.t('mar'),
          i18n.t('apr'),
          i18n.t('may'),
          i18n.t('june'),
          i18n.t('july'),
          i18n.t('aug'),
          i18n.t('sep'),
          i18n.t('oct'),
          i18n.t('nov'),
          i18n.t('dec'),
        ],
        dayNames: [
          i18n.t('sunday'),
          i18n.t('monday'),
          i18n.t('tuesday'),
          i18n.t('wednesday'),
          i18n.t('thursday'),
          i18n.t('friday'),
          i18n.t('saturday'),
        ],
        dayNamesShort: [
          i18n.t('sun'),
          i18n.t('mon'),
          i18n.t('tue'),
          i18n.t('wed'),
          i18n.t('thu'),
          i18n.t('fri'),
          i18n.t('sat'),
        ],
        dayNamesMin: [
          i18n.t('Su'),
          i18n.t('M'),
          i18n.t('T'),
          i18n.t('W'),
          i18n.t('Th'),
          i18n.t('F'),
          i18n.t('S'),
        ],
        weekHeader: i18n.t('week'),
        autoSize: true,
        constrainInput: true,
        minDate,
        maxDate,
        changeMonth: true,
        changeYear: true,
        showButtonPanel: true,
        currentText: i18n.t('today'),
        beforeShow: function (input, inst) {
          var height = $(input).height();
          var offset;

          if ($(input).parent().hasClass('its-parent-is-fixed')) {
            offset = {
              top: $(input).height(),
              left: $(input).offset().left,
            };
          } else {
            offset = $(input).offset();
          }

          window.setTimeout(function () {
            inst.dpDiv.css({
              top: offset.top + height + 4 + 'px',
              left: offset.left + 'px',
            });
          }, 1);

          $(input).parent('.mod-date').addClass('is-active');
        },
        onClose: function () {
          $(this).parent('.mod-date').removeClass('is-active');
        },
      })
    );
  };

  const clickHoy = (idDatepicker, handler, parameter) => {
    document.getElementById(idDatepicker).addEventListener('click', () => {
      let addHandlerToButton = () => {
        document
          .getElementsByClassName('ui-datepicker-current')[0]
          .addEventListener('click', () => {
            setTimeout(() => {
              handler(parameter);
            }, 100);
          });

        document
          .getElementsByClassName('ui-datepicker-prev')[0]
          .addEventListener('click', () => {
            addHandlerToButton();
          });

        document
          .getElementsByClassName('ui-datepicker-next')[0]
          .addEventListener('click', () => {
            addHandlerToButton();
          });
      };

      //assign on change PREV
      document
        .getElementsByClassName('ui-datepicker-prev')[0]
        .addEventListener('click', () => {
          addHandlerToButton();
        });

      //assign on change NEXT
      document
        .getElementsByClassName('ui-datepicker-next')[0]
        .addEventListener('click', () => {
          addHandlerToButton();
        });

      //assign event to btn HOY
      addHandlerToButton();

      //assign on change MONTH
      // $('.ui-datepicker-month').on('selectmenuchange', (event) => {
      // })

      // document
      //   .getElementsByClassName('ui-datepicker-month')[0]
      //   .addEventListener('change', () => {
      //     addHandlerToButton()
      //   });

      //assign on change YEAR
      // document
      //   .getElementsByClassName('ui-datepicker-year')[0]
      //   .addEventListener('change', () => {
      //     addHandlerToButton()
      //   });
    });
  };

  const orderObjectsArrayByKey = (array, orderArr, key) => {
    const itemPositions = {};
    for (const [index, id] of orderArr.entries()) {
      itemPositions[id] = index;
    }

    const arrOrdered = array.sort(
      (a, b) => itemPositions[a[key]] - itemPositions[b[key]]
    );

    return arrOrdered;
  };
  const renderGraphInResize = (
    identificator,
    options,
    data,
    type = 'Chart'
  ) => {
    // validate if data is equals to zero

    window.addEventListener('resize', () => {
      document.querySelector(identificator).remove();
      new Charicharts[type](options, data);
    });
  };

  return {
    init: init,
    isMobileDevice: isMobileDevice,
    isTablet: isTablet,
    getArrayOfHours: getArrayOfHours,
    getArrayOfMinutes: getArrayOfMinutes,
    getArrayOfMonths: getArrayOfMonths,
    getArrayOfYears: getArrayOfYears,
    renderGraphInResize: (identificator, options, data, type) =>
      renderGraphInResize(identificator, options, data, type),
    removeAccents: (str) => removeAccents(str),
    getTimeValue: (timeID) => getTimeValue(timeID),
    showAndHideGraphLines: (widgetId, eyeClassList) =>
      showAndHideGraphLines(widgetId, eyeClassList),
    showAndHideGraphLinesEyes,
    changeStyles: (data) => changeStyles(data),
    toSpainDate: (date) => toSpainDate(date),
    toNumber: (value, decimals, type, numZero) =>
      toNumber(value, decimals, type, numZero),
    generateRandomId: (length) => generateRandomId(length),
    dateToUTCString: (date) => dateToUTCString(date),
    createFilterModel: (data, filters, filterAttrs) =>
      createFilterModel(data, filters, filterAttrs),
    renderDatePickers: (datepikersArr, maxDate, minDate) =>
      renderDatePickers(datepikersArr, maxDate, minDate),
    spinnerStatus: (id, show) => spinnerStatus(id, show),
    noDataStatus: (id, show) => noDataStatus(id, show),
    setNoDataStatus: (data, id) => setNoDataStatus(data, id),
    datesMatch: (currentDate) => datesMatch(currentDate),
    orderObjectsArrayByKey: (array, orderArr, key) =>
      orderObjectsArrayByKey(array, orderArr, key),
    getUrlAnalysis,
    getHoursOfDay,
    clickHoy,
    dateAdd,
  };
})();

export { Utils };
