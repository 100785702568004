'use strict';
import i18n from 'i18next';
import statsImg from '../../assets/images/stats.svg';

let templateChart18 = () => {
  return `
    <div
    class="esios-home__widget-legend highlighted-legend visual-legend"
    >
    <ul class="interactive-legend">
        <li
        class="legend-item"
        id="termino-de-facturacion-de-energia-activa-del-pvpc-20td1001"
        data-id="1001"
        >
        <div class="item">
            <span
            class="icon-eye"
            style="background-color: #FFCF09;"
            data-id="10018741"
            ></span
            ><span class="label" id="title-pvpc">PVPC</span>
        </div>
        <div class=" value is-number">
            <span id="mp-pvpc" class="caption is-medium"
            >-</span
            >
            <span class="unit">€/MWh</span>
            <a
            href="/${i18n.t('routes.lng')}/${i18n.t('routes.analysis')}/1001"
            class="esios-toolbar__buttons toolbar-analysis"
            >
            <img
              style="vertical-align: middle"
              src="${statsImg}"
            />
            </a>
        </div>
        </li>
        <li
        class="legend-item"
        id="precio-mercado-spot-diario3"
        data-id="3"
        >
        <div class="item">
            <span
            class="icon-eye"
            style="background-color: #DF4A32;"
            data-id="6003"
            ></span
            ><span class="label" id="title-esp">Mercado SPOT España</span>
        </div>
        <div class=" value is-number">
            <span id="mp-spot-spain" class="caption is-medium"
            >-</span
            ><span class="unit">€/MWh</span>
            <a
            href="/${i18n.t('routes.lng')}/${i18n.t(
    'routes.analysis'
  )}/600?geoids=3"
            class="esios-toolbar__buttons toolbar-analysis"
            >
            <img
              style="vertical-align: middle"
              src="${statsImg}"
            />
            </a>
        </div>
        </li>
        <li
        class="legend-item"
        id="precio-mercado-spot-diario2"
        data-id="2"
        >
        <div class="item">
            <span
            class="icon-eye"
            style="background-color: #00A1DA;"
            data-id="6002"
            ></span
            ><span class="label" id="title-fra">Mercado SPOT Francia</span>
        </div>
        <div class=" value is-number">
            <span id="mp-spot-france" class="caption is-medium"
            >-</span
            ><span class="unit">€/MWh</span>
            <a
            href="/${i18n.t('routes.lng')}/${i18n.t(
    'routes.analysis'
  )}/600?geoids=2"
            class="esios-toolbar__buttons toolbar-analysis"
            >
            <img
              style="vertical-align: middle"
              src="${statsImg}"
            />
            </a>
        </div>
        </li>
        <li
        class="legend-item"
        id="precio-mercado-spot-diario1"
        data-id="1"
        >
        <div class="item">
            <span
            class="icon-eye"
            style="background-color: #74BA05;"
            data-id="6001"
            ></span
            ><span class="label" id="title-por">Mercado SPOT Portugal</span>
        </div>
        <div class=" value is-number">
            <span
            id="mp-spot-portugal"
            class="caption is-medium"
            >-</span
            ><span class="unit">€/MWh</span>
            <a
            href="/${i18n.t('routes.lng')}/${i18n.t(
    'routes.analysis'
  )}/600?geoids=1"
            class="esios-toolbar__buttons toolbar-analysis"
            >
            <img
              style="vertical-align: middle"
              src="${statsImg}"
            />
            </a>
        </div>
        </li>
    </ul>
    </div>
    </div>
    </div>
    <hr />

    <div>
      <div
        class="esios-home__widget-legend graph-legend highlighted-legend visual-legend"
        style="min-height: 0px;">
        <ul class="simple-legend">
          <li
            class="legend-item"
            id="termino-de-facturacion-de-energia-activa-del-pvpc-20td1001"
            data-id="1001"
          >
            <div class="item">
              <span class="label">RESTRICCIONES DIARIO</span>
            </div>
            <div class=" value is-number">
              <span id="mp-constraints" class="caption is-medium"
                >-</span
              ><span class="unit">€/MWh</span>
              <a
                href="/${i18n.t('routes.lng')}/${i18n.t(
    'routes.analysis'
  )}/600?geoids=1"
                class="esios-toolbar__buttons toolbar-analysis"
              >
              </a>
            </div>
          </li>
          <li
            class="legend-item"
            id="precio-mercado-spot-diario1"
            data-id="1"
          >
            <div class="item">
              <span class="label"
                >PRECIO DE PAGO DESVÍOS A BAJAR</span
              >
            </div>
            <div class=" value is-number">
              <span id="mp-downward" class="caption is-medium"
                >-</span
              ><span class="unit">€/MWh</span>
              <a
                href="/${i18n.t('routes.lng')}/${i18n.t(
    'routes.analysis'
  )}/600?geoids=1"
                class="esios-toolbar__buttons toolbar-analysis"
              >
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  `;
};

export { templateChart18 };
