'use strict';

/**
 * @description Dashboard Component
 * @returns {Object} Devuelve la instancia del componente
 */

import i18n from 'i18next';
import { render } from 'lit-html';
import _ from 'underscore';
import moment from 'moment';
import { store } from '../../app.init.js';
import { componentButtons } from '../../shared/components/buttons/buttons.component.js';
import { datepickerComponent } from '../../shared/components/datepicker/datepicker.component.js';
import { componentPaginator } from '../../shared/components/paginator/paginator.component.js';
import { getUrlParams, setUrlParam } from '../../utils/url.js';
import { templateTable } from '../table/table.template.js';
import { servicesDescargas } from './descargas.services.js';
import { templateDescargas } from './descargas.template.js';

let componentDescargas = (function () {
  let context = {};
  let handlers = {
    onPageChange: (page) => onPageChange(page),
    onTotalChange: (event) => onTotalChange(event),
    onQueryChange: (event) => onQueryChange(event),
    onTypeSearchChange: (event) => onTypeSearchChange(event),
    onSearch: () => onSearch(),
    ocultar: (event) => ocultar(event),
    onSelectChange: (index) => onSelectChange(index),
  };
  let pageTitle = '';
  let renderSuccess = false;
  let listMarket;
  let parameterType;
  let parameterDateStart;
  let parameterDateEnd;
  let selectDefault = {
    id: 3,
    label: '',
    options: [
      { label: 'publication', value: 'publicacion' },
      { label: 'data', value: 'datos' },
    ],
  };
  let select = [selectDefault];

  // tres filtros
  let searchForm = {};
  let urlParams;

  let filterForm = {
    query: '',
    ambit: '',
    area: '',
  };
  let data = [];
  let dataPaginated = [];

  let paginationValues = [25, 50, 100];
  let pagination = {
    total: 0,
    page: 1,
    totalPages: 1,
    pagination: paginationValues[0],
  };

  const currentRoute = window.location.pathname;
  let lang = currentRoute.split('/')[1];

  // Definir sus columnas
  const columns = [
    {
      title: 'document',
      dataIndex: 'slug',
      defaultSortOrder: 'asc',
      sortDirections: ['asc', 'desc'],
      sorter: (datalist, order) => {
        const sorted = [...datalist].sort((a, b) => {
          if (order === 'asc') {
            if (a.slug < b.slug) {
              return -1;
            }
            if (a.slug > b.slug) {
              return 1;
            }
            return 0;
          } else if (order === 'desc') {
            if (a.slug > b.slug) {
              return -1;
            }
            if (a.slug < b.slug) {
              return 1;
            }
            return 0;
          }
        });
        data = sorted;
        filterData();
      },
    },
    {
      title: 'scope',
      dataIndex: 'taxonomy_terms',
      defaultSortOrder: 'asc',
      sortDirections: ['asc', 'desc'],
      sorter: (datalist, order) => {
        const sorted = [...datalist].sort((a, b) => {
          const ataxonomy = getAmbitText(a.taxonomy_terms);
          const btaxonomy = getAmbitText(b.taxonomy_terms);
          if (order === 'asc') {
            if (ataxonomy < btaxonomy) {
              return -1;
            }
            if (ataxonomy > btaxonomy) {
              return 1;
            }
            return 0;
          } else if (order === 'desc') {
            if (ataxonomy > btaxonomy) {
              return -1;
            }
            if (ataxonomy < btaxonomy) {
              return 1;
            }
            return 0;
          }
        });
        data = sorted;
        filterData();
      },
      render: (taxonomy_terms, rowData) => {
        return getAmbitText(taxonomy_terms);
      },
    },
    {
      title: 'area',
      dataIndex: 'taxonomy_terms',
      defaultSortOrder: 'asc',
      sortDirections: ['asc', 'desc'],
      sorter: (datalist, order) => {
        const sorted = [...datalist].sort((a, b) => {
          const aArea = getAreaText(a.taxonomy_terms);
          const bArea = getAreaText(b.taxonomy_terms);
          if (order === 'asc') {
            if (aArea < bArea) {
              return -1;
            }
            if (aArea > bArea) {
              return 1;
            }
            return 0;
          } else if (order === 'desc') {
            if (aArea > bArea) {
              return -1;
            }
            if (aArea < bArea) {
              return 1;
            }
            return 0;
          }
        });
        data = sorted;
        filterData();
      },
      render: (taxonomy_terms, rowData) => {
        return getAreaText(taxonomy_terms);
      },
    },
    {
      title: 'validity_date',
      dataIndex: 'date_times',
      defaultSortOrder: 'asc',
      sortDirections: ['asc', 'desc'],
      sorter: (datalist, order) => {
        const sorted = [...datalist].sort((a, b) => {
          let aDate = getDataDate(a.date_times).split('-');
          let bDate = getDataDate(b.date_times).split('-');

          if (order === 'asc') {
            aDate = aDate[0];
            bDate = bDate[0];
            if (
              moment(bDate, 'DD-MM-YYYY').isAfter(moment(aDate, 'DD-MM-YYYY'))
            ) {
              return -1;
            }
            if (
              moment(bDate, 'DD-MM-YYYY').isBefore(moment(aDate, 'DD-MM-YYYY'))
            ) {
              return 1;
            }
            return 0;
          } else if (order === 'desc') {
            aDate = aDate.length === 2 ? aDate[1] : aDate[0];
            bDate = bDate.length === 2 ? bDate[1] : bDate[0];
            if (
              moment(aDate, 'DD-MM-YYYY').isAfter(moment(bDate, 'DD-MM-YYYY'))
            ) {
              return -1;
            }
            if (
              moment(aDate, 'DD-MM-YYYY').isBefore(moment(bDate, 'DD-MM-YYYY'))
            ) {
              return 1;
            }
            return 0;
          }
        });
        data = sorted;
        filterData();
      },
      render: (date_times, rowData) => {
        return getDataDate(date_times);
      },
    },
    {
      title: 'updated_at',
      dataIndex: 'publication_date',
      defaultSortOrder: 'asc',
      sortDirections: ['asc', 'desc'],
      sorter: (datalist, order) => {
        const sorted = [...datalist].sort((a, b) => {
          let aDate = getDataDate(a.publication_date).split('-');
          let bDate = getDataDate(b.publication_date).split('-');
          if (order === 'asc') {
            aDate = aDate[0];
            bDate = bDate[0];
            if (
              moment(bDate, 'DD-MM-YYYY').isAfter(moment(aDate, 'DD-MM-YYYY'))
            ) {
              return -1;
            }
            if (
              moment(bDate, 'DD-MM-YYYY').isBefore(moment(aDate, 'DD-MM-YYYY'))
            ) {
              return 1;
            }
            return 0;
          } else if (order === 'desc') {
            aDate = aDate.length === 2 ? aDate[1] : aDate[0];
            bDate = bDate.length === 2 ? bDate[1] : bDate[0];
            if (
              moment(aDate, 'DD-MM-YYYY').isAfter(moment(bDate, 'DD-MM-YYYY'))
            ) {
              return -1;
            }
            if (
              moment(aDate, 'DD-MM-YYYY').isBefore(moment(bDate, 'DD-MM-YYYY'))
            ) {
              return 1;
            }
            return 0;
          }
        });
        data = sorted;
        filterData();
      },
      render: (publication_date, rowData) => {
        return getDataDate(publication_date);
      },
    },
  ];
  // Fin Definir columnas

  const selectElements = [
    {
      value: 'ambit',
      label: 'scope',
    },
    {
      value: 'area',
      label: 'area',
    },
  ];

  // paginacion funciones
  const onPageChange = (page) => {
    pagination.page = page;
    pagination.totalPages = Math.ceil(data.length / pagination.pagination);
    filterData();
  };

  const onTotalChange = (event) => {
    pagination.page = 1;
    pagination.pagination = event.target.value;
    pagination.totalPages = Math.ceil(data.length / pagination.pagination);
    filterData();
  };

  const ocultar = (event) => {
    pagination.page = 1;
    document.querySelector('.esios-tabla__input--search').value = '';
    document
      .querySelector('.esios-tabla__boton-remove')
      .classList.add('hidden');
    filterForm.query = '';
    filterData();
  };

  // fin paginacion
  // filtros funciones de filtrado abajo

  const onQueryChange = (event) => {
    pagination.page = 1;
    filterForm.query = event.target.value;
    if (event.target.value.length > 0)
      document
        .querySelector('.esios-tabla__boton-remove')
        .classList.remove('hidden');
    else
      document
        .querySelector('.esios-tabla__boton-remove')
        .classList.add('hidden');
    filterData();
  };

  const onSelectChange = (index) => {
    const cE = selectElements[index];
    return (event) => {
      filterForm[cE.value] = event.target.value;
      let selected = document.querySelectorAll('.ui-selectmenu-text');
      filterForm[cE.value] === ''
        ? (selected[index + 1].innerHTML = i18n.t(cE.label))
        : filterForm[cE.value];
      pagination.page = 1;
      filterData();
    };
  };

  const formatDate = (date) => moment(date, 'YYYY/MM/DD').format('DD/MM/YYYY');
  // fin de funciones de filtrado

  const filterData = () => {
    let filtered = data.filter((item) =>
      item.name.toLowerCase().includes(filterForm.query.toLowerCase())
    );

    const addFilter = (data) => {
      filtered = [...data, ...filtered];
    };

    const filterQuery = (handler, parameter) => {
      let filter = data.filter((item) =>
        handler(item[parameter])
          .toLowerCase()
          .includes(filterForm.query.toLowerCase())
      );
      addFilter(filter);
    };

    filterQuery(getAmbitText, 'taxonomy_terms');
    filterQuery(getAreaText, 'taxonomy_terms');

    let filterQueryDate = (parameter) =>
      data.filter((item) => {
        let date = getDataDate(item[parameter]);
        return date.includes(filterForm.query);
      });

    addFilter(filterQueryDate('date_times'));
    addFilter(filterQueryDate('publication_date'));

    var hash = {};
    filtered = filtered.filter(function (current) {
      var exists = !hash[current.id];
      hash[current.id] = true;
      return exists;
    });

    filtered = filtered.filter((item) =>
      getAmbitText(item.taxonomy_terms)
        .toLowerCase()
        .includes(filterForm.ambit.toLowerCase())
    );
    filtered = filtered.filter((item) =>
      getAreaText(item.taxonomy_terms)
        .toLowerCase()
        .includes(filterForm.area.toLowerCase())
    );

    dataPaginated = filtered.slice(
      (pagination.page - 1) * pagination.pagination,
      pagination.page * pagination.pagination
    );
    pagination.total = filtered.length;
    pagination.totalPages = Math.ceil(filtered.length / pagination.pagination);
    renderAll();
    renderTable();
    exportButtons();
  };

  // filtros de busqueda por fecha, arriba

  const onTypeSearchChange = (event) => {
    searchForm.query = event.target.value;
  };

  const showSpinner = (show) => {
    let table = 'flex';
    let spinner = 'none';
    if (show) {
      table = 'none';
      spinner = 'flex';
    }
    document.getElementById('download-table').style.display = table;
    document.getElementById('spinner1').style.display = spinner;
  };

  const resetSort = (type = 'reset') => {
    document
      .querySelectorAll('table > thead .esios-table__cell--sort')
      .forEach((orderItem, i) => {
        i != 0
          ? (orderItem.className = 'esios-table__cell--sort')
          : (orderItem.className = `esios-table__cell--sort ${
              type === 'search_by_date' ? 'desc' : 'asc'
            }`);
      });
  };

  // buscar por fecha
  const onSearch = () => {
    pagination.page = 1;
    showSpinner(true);
    resetSort('search_by_date');
    document.querySelector('.ree-select-ambit').value = '';
    document.querySelector('.ree-select-area').value = '';
    $('.ree-select-ambit').selectmenu('refresh');
    $('.ree-select-area').selectmenu('refresh');
    filterForm = {
      query: '',
      ambit: '',
      area: '',
    };
    searchData();
    ocultar();
  };

  const getAmbitText = (taxonomy_terms) => {
    const ambito = taxonomy_terms.filter(
      (taxonomy_termsItem) => taxonomy_termsItem.vocabulary_id === 20
    );
    let ambitoText = '';
    ambito.forEach((ambitoItem) => {
      ambitoText += ambitoItem.name + ', ';
    });
    return ambitoText.substring(0, ambitoText.length - 2);
  };

  const getAreaText = (taxonomy_terms) => {
    const areas = taxonomy_terms.filter(
      (taxonomy_termsItem) => taxonomy_termsItem.vocabulary_id === 22
    );
    let areaText = '';
    areas.forEach((areaItem) => {
      areaText += areaItem.name + ', ';
    });
    return areaText.substring(0, areaText.length - 2);
  };

  const getSelect = (taxonomy_terms, id) => {
    const select = taxonomy_terms.filter(
      (taxonomy_termsItem) => taxonomy_termsItem.vocabulary_id === id
    );
    return select;
  };

  const getDataDate = (dates) => {
    let date = '';
    dates.forEach((dateItem) => {
      if (date == '') {
        date = formatDate(dateItem);
      } else date += ' - ' + formatDate(dateItem);
    });
    return date;
  };
  let buttons = [
    {
      label: 'ACCESO A INFORMACIÓN PARA CLIENTES',
      url: 'https://www.ree.es/es/clientes',
    },
  ];
  let preRenderComponent = async () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState(searchForm);
    document.title = `${i18n.t('downloads')} | ${i18n.t('page_subtitle')}`;
    context.name = state.exampleReducer.name;

    listMarket = [
      {
        type: 'title',
        id: 2,
        title: 'structural_data',
      },
      {
        type: 'list',
        id: 1,
        text: 'participants_auction',
        url: `/${i18n.t('routes.lng')}/${i18n.t(
          'routes.participants_auction'
        )}`,
      },
      {
        type: 'list',
        id: 2,
        text: 'programming_units',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.programming_units')}`,
      },
      {
        type: 'list',
        id: 3,
        text: 'physical_units',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.physical_units')}`,
      },
      {
        type: 'list',
        id: 4,
        text: 'market_subjects',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.market_subjects')}`,
      },
    ];
  };

  // get archives trae por fecha API

  const searchData = async () => {
    let { date_type, start_date, end_date } = getUrlParams();

    if (start_date) {
      start_date = moment(start_date, 'DD-MM-YYYY').format('DD / MM / YYYY');
    }
    if (end_date) {
      end_date = moment(end_date, 'DD-MM-YYYY').format('DD / MM / YYYY');
    }

    searchForm.date_init =
      start_date || document.getElementById('datepicker1').value;
    searchForm.date_end =
      end_date || document.getElementById('datepicker2').value;
    const dateInitUTC = moment(
      searchForm.date_init,
      'DD / MM / YYYY'
    ).toISOString();
    const dateEndUTC = moment(
      searchForm.date_end,
      'DD / MM / YYYY'
    ).toISOString();
    return await servicesDescargas
      .getArchives({
        type: date_type || searchForm.type,
        date_init: dateInitUTC,
        date_end: dateEndUTC,
      })
      .then((respData) => {
        data = respData;
        pagination.total = respData.length;
        pagination.totalPages = Math.ceil(
          respData.length / pagination.pagination
        );

        const filters = {
          ambit: [],
          area: [],
        };

        let filterAttrs = [];

        const addFilterAttrs = (handlerParameter, value, id) => {
          filterAttrs = [
            {
              label: i18n.t('taxonomy_terms'),
              handler: getSelect,
              handlerParameter,
              value,
              id,
            },
            ...filterAttrs,
          ];
        };

        addFilterAttrs(22, 'area', 2);
        addFilterAttrs(20, 'ambit', 1);

        const createFilterModel = (data, filters, filterAttrs) => {
          data.forEach((element) => {
            for (const attr of filterAttrs) {
              const value = attr.handler(
                element[attr.label],
                attr.handlerParameter
              );

              value.map(({ name }) => {
                if (!filters[attr.value].some((e) => e.value === name)) {
                  filters[attr.value].push({ label: name, value: name });
                }
              });
            }
          });
          const result = filterAttrs.map((at) => {
            const orderedF = filters[at.value].sort((a, b) => {
              const comma2Float = (label) =>
                label.split('.').join('').split(',').join('.');

              const comma2FloatA = comma2Float(a.label);
              const comma2FloatB = comma2Float(b.label);

              if (isNaN(comma2FloatA) || isNaN(comma2FloatB)) {
                if (a.label < b.label) {
                  return -1;
                }
                return 1;
              } else {
                if (Number(comma2FloatA) < Number(comma2FloatB)) {
                  return -1;
                }
                return 1;
              }
            });
            return {
              id: at.id,
              label: '',
              options: [{ label: 'show_all', value: '' }, ...orderedF],
            };
          });

          return result;
        };

        select = createFilterModel(data, filters, filterAttrs);
        select = [...select, selectDefault];

        filterData();
        if (respData) {
          showSpinner(false);
        } else {
          return respData;
        }

        $('.ree-select-ambit').selectmenu('refresh');
        $('.ree-select-area').selectmenu('refresh');
      });
  };

  const exportButtons = () => {
    const dataCsv = data.map((i) => {
      return [
        i.id,
        i.download.name,
        i.download.url,
        _.where(i['taxonomy_terms'], { vocabulary_id: 20 })
          .map((el) => el.name)
          .join(', '),
        _.where(i['taxonomy_terms'], { vocabulary_id: 22 })
          .map((el) => el.name)
          .join(', '),
        _.where(i['taxonomy_terms'], { vocabulary_id: 21 })
          .map((el) => el.name)
          .join(', '),
        i.archive_type,
        i.publication_date,
        i.date_times,
      ];
    });
    dataCsv.unshift([0, 1, 2, 3, 4, 5, 6, 7, 8]);

    let jsonData = parseToJSON(data);

    const fileName =
      'export_' +
      i18n.t('routes.downloads') +
      '_' +
      moment().format('YYYY-MM-DD_HH_mm');
    const buttonsArray = [
      {
        title: 'EXPORTAR JSON',
        action: 'exportJSON',
        url: '#',
        data: jsonData,
        docName: fileName,
      },
      {
        title: 'EXPORTAR CSV',
        action: 'exportCSV',
        url: '#',
        data: dataCsv,
        docName: fileName,
      },
      {
        title: 'EXPORTAR EXCEL',
        action: 'exportExcel',
        url: '#',
        data: dataCsv,
      },
      { title: 'IMPRIMIR', action: 'print', url: '#' },
    ];
    componentButtons.render(buttonsArray, '#button-download');
  };

  const setParameter = (key, value) => {
    urlParams.set(key, value);
    history.pushState({}, '', window.location.pathname + '?' + urlParams);
  };

  let renderComponent = async () => {
    urlParams = new URLSearchParams(window.location.search);
    parameterType = urlParams.get('date_type') || 'publicacion';
    parameterDateStart =
      urlParams.get('start_date') ||
      moment().tz('Europe/Madrid').format('DD-MM-YYYY');
    parameterDateEnd =
      urlParams.get('end_date') ||
      moment().tz('Europe/Madrid').format('DD-MM-YYYY');

    setParameter('date_type', parameterType);
    setParameter('start_date', parameterDateStart);
    setParameter('end_date', parameterDateEnd);

    searchForm = {
      type: parameterType,
      date_init: parameterDateStart,
      date_end: parameterDateEnd,
    };

    try {
      templateDescargas(
        context,
        handlers,
        pageTitle,
        select,
        paginationValues,
        pagination,
        filterForm,
        searchForm,
        buttons,
        listMarket,
        selectElements,
        lang
      );
      document
        .querySelector('.esios-layout__main')
        .classList.add('esios-layout__main--descargas');
      document
        .querySelector('.esios-pagination__results')
        .classList.add('esios-pagination__results--descargas');
      document
        .querySelector('.esios-pagination__paginator')
        .classList.add('esios-pagination__paginator--descargas');
      document
        .querySelector('.esios-tabla__buttons')
        .classList.add('esios-tabla__buttons--descargas');
      document
        .querySelector('.row.is-strech')
        .classList.add('row.is-strech--descargas');
      /* document
        .querySelector('.mod-documents-download')
        .classList.add('mod-documents-download--descargas');*/
      document
        .querySelector('.esios-documents')
        .classList.add('esios-docs-descargas');

      document
        .querySelector('#esios-layout-banner')
        .classList.add('ly-descargas');

      await searchData();
      filterData();
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
    document
      .querySelector('.esios-footer')
      .classList.add('esios-footer--descargas');

    const selects = [
      {
        className: '.ree-select-ambit',
        evento: handlers.onSelectChange(0),
      },
      {
        className: '.ree-select-area',
        evento: handlers.onSelectChange(1),
      },
    ];

    const createSelect = (selects) => {
      selects.map(({ className, evento }) => {
        $(className).selectmenu({
          appendTo: '.esios-tabla__select',
        });

        $(className).on('selectmenuchange', function (event, ui) {
          evento(event);
        });
      });
    };
    createSelect(selects);

    $('.ree-select-publication').selectmenu({
      appendTo: '.init-dropdown',
    });

    if (searchForm.type == 'publicacion' || searchForm.type == 'datos') {
      document.querySelector('.ree-select-publication').value = searchForm.type;
      $('.ree-select-publication').selectmenu('refresh');
    }

    $('.ree-select-publication').on('selectmenuchange', (event) => {
      setParameter('date_type', event.target.value);
      searchForm.type == event.target.value;
    });

    datepickerComponent.render('1');
    datepickerComponent.render('2');

    let dateInitParse = moment(searchForm.date_init, 'DD-MM-YYYY').format(
      'DD / MM / YYYY'
    );
    let dateEndParse = moment(searchForm.date_end, 'DD-MM-YYYY').format(
      'DD / MM / YYYY'
    );
    $('#datepicker1').datepicker('setDate', dateInitParse);
    $('#datepicker2').datepicker('setDate', dateEndParse);

    $('#datepicker1').datepicker('option', 'maxDate', dateInitParse);
    $('#datepicker2').datepicker('option', 'minDate', dateEndParse);

    $('#datepicker1').change(function () {
      let currentDate = document.getElementById('datepicker1').value;
      let currentDateParse = moment(currentDate, 'DD / MM / YYYY').format(
        'DD-MM-YYYY'
      );
      searchForm.date_init = currentDateParse;
      setParameter('start_date', currentDateParse);
      $('#datepicker2').datepicker('option', 'minDate', currentDate);
    });

    $('#datepicker2').change(function () {
      let currentDate = document.getElementById('datepicker2').value;
      let currentDateParse = moment(currentDate, 'DD / MM / YYYY').format(
        'DD-MM-YYYY'
      );
      searchForm.date_end = currentDateParse;
      setParameter('end_date', currentDateParse);
      $('#datepicker1').datepicker('option', 'maxDate', currentDate);
    });

    let modDateList = document.querySelectorAll('.mod-date');
    modDateList.forEach((el) => {
      el.classList.add('mod-date--input-datepicker');
    });
  };

  let renderTable = () => {
    const tableElement = document.getElementById('download-table');
    render(templateTable(columns, dataPaginated, data), tableElement);
    componentPaginator.render(pagination, onPageChange, 'pagination-descargas');
  };

  let renderAll = () => {
    templateDescargas(
      context,
      handlers,
      pageTitle,
      select,
      paginationValues,
      pagination,
      filterForm,
      searchForm,
      listMarket,
      selectElements,
      lang
    );
    exportButtons();
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentDescargas };

export function parseToJSON(data) {
  return _.map(data, function (archive) {
    return {
      id: archive.id,
      name: archive.name,
      url: archive.download.url,
      scopes: _.where(archive['taxonomy_terms'], { vocabulary_id: 20 }),
      areas: _.where(archive['taxonomy_terms'], { vocabulary_id: 22 }),
      subjects: _.where(archive['taxonomy_terms'], { vocabulary_id: 21 }),
      type: archive['archive_type'],
      publicationDate: archive['publication_date'],
      date: archive['date_times'],
    };
  });
}
