'use strict';

/**
 * @description Help Component
 * @returns {Object} Devuelve la instancia del componente
 */

import i18n from 'i18next';
import { render } from 'lit-html';
import { store } from '../../app.init.js';
import { Utils } from '../../utils/utils.js';
import { componentButtons } from '../../shared/components/buttons/buttons.component.js';
import { componentListPrintView } from '../../shared/components/list-print-view/list-print-view.component.js';
import { templateTable } from '../table/table.template.js';
import { servicesSujetosMercado } from './sujetosmercado.services.js';
import { templateSujetosMercado } from './sujetosmercado.template.js';
import moment from 'moment';
import { componentPaginator } from '../../shared/components/paginator/paginator.component.js';
let componentSujetosMercado = (function () {
  let context = {};
  let renderSuccess = false;
  let contactData = '';
  let handlers = {
    onPageChange: (page) => onPageChange(page),
    onTotalChange: (event) => onTotalChange(event),
    onQueryChange: (event) => onQueryChange(event),
    onSmChange: (event) => onSmChange(event),
    onHide: (event) => onHide(event),
    ocultar: (event) => ocultar(event),
  };
  let select = [];
  let objMarket;
  let listMarket;
  let lng = window.location.pathname.split('/')[1];

  let columns;

  const searchForm = 'BUSCAR';

  let data = [];

  let filterForm = {
    query: '',
    sm: '',
  };
  let dataPaginated = [];

  let paginationValues = [25, 50, 100];
  let pagination = {
    total: 0,
    page: 1,
    totalPages: 1,
    pagination: paginationValues[0],
  };

  const currentRoute = window.location.pathname;
  let lang = currentRoute.split('/')[1];

  const getData = () => {
    return servicesSujetosMercado.getData().then((respData) => {
      data = Object.values(respData);
      pagination.total = data.length;
      pagination.totalPages = Math.ceil(data.length / pagination.pagination);

      const filters = {
        smType: [],
      };

      const filterAttrs = [
        { label: i18n.t('BRP-Type'), value: 'smType', id: 1 },
      ];
      select = Utils.createFilterModel(respData, filters, filterAttrs);
      if (respData) {
        document.getElementById('spinner1').style.display = 'none';
      } else {
        return respData;
      }
    });
  };

  const onPageChange = (page) => {
    pagination.page = page;
    pagination.totalPages = Math.ceil(data.length / pagination.pagination);
    filterData();
  };

  const onTotalChange = (event) => {
    pagination.page = 1;
    pagination.pagination = event.target.value;
    pagination.totalPages = Math.ceil(data.length / pagination.pagination);
    filterData();
  };

  const ocultar = (event) => {
    document.querySelector('.esios-tabla__input esios-smercado__search').value =
      '';
    document
      .querySelector('.esios-smercado__boton-remove')
      .classList.add('hidden');
    filterForm.query = '';
    filterData();
  };

  const filterData = () => {
    let filtered = data.filter((item) => {
      return item[i18n.t('name')]
        .toLowerCase()
        .includes(filterForm.query.toLowerCase());
    });

    const filterQuery = (column) => {
      let filter = data.filter((item) =>
        item[i18n.t(column)]
          .toLowerCase()
          .includes(filterForm.query.toLowerCase())
      );
      filtered = [...filter, ...filtered];
    };

    const filterSelect = (select, attribute) => {
      filtered = filtered.filter((item) => {
        if (filterForm[attribute] === '') {
          return item[i18n.t(select)].toLowerCase().includes('');
        } else {
          return (
            item[i18n.t(select)].toLowerCase() ===
            filterForm[attribute].toLowerCase()
          );
        }
      });
    };

    filterQuery('subject-code');
    filterQuery('eic-code');
    filterQuery('BRP-Type');
    filterSelect('BRP-Type', 'sm');

    var hash = {};
    filtered = filtered.filter(function (current) {
      var exists = !hash[current[i18n.t('subject-code')]];
      hash[current[i18n.t('subject-code')]] = true;
      return exists;
    });

    dataPaginated = filtered.slice(
      (pagination.page - 1) * pagination.pagination,
      pagination.page * pagination.pagination
    );
    const columns = [
      { name: i18n.t('name'), field: i18n.t('name') },
      { name: i18n.t('subject-code'), field: i18n.t('subject-code') },
      { name: i18n.t('eic-code'), field: i18n.t('eic-code') },
      { name: i18n.t('BRP-Type'), field: i18n.t('BRP-Type') },
    ];
    componentListPrintView.render('print-list', dataPaginated, columns);
    pagination.total = filtered.length;
    pagination.totalPages = Math.ceil(filtered.length / pagination.pagination);
    renderAll();
    renderTable();
  };

  const onHide = (event) => {
    document.querySelector('.esios-tabla__input').value = '';
    document
      .querySelector('.esios-smercado__button-remove')
      .classList.add('hidden');
    filterForm.query = '';
    filterData();
  };

  let renderTable = () => {
    const tableElement = document.getElementById('table');
    render(templateTable(columns, dataPaginated, data), tableElement);
    componentPaginator.render(pagination, onPageChange, 'pagination-sujetos');
  };

  let renderAll = () => {
    templateSujetosMercado(
      contactData,
      { objMarket, listMarket },
      handlers,
      select,
      searchForm,
      paginationValues,
      pagination,
      lang
    );
    document
      .getElementById('esios-layout-banner')
      .classList.add('hideForPrint');
  };

  const onQueryChange = (event) => {
    filterForm.query = event.target.value;
    if (event.target.value.length > 0)
      document
        .querySelector('.esios-smercado__button-remove')
        .classList.remove('hidden');
    else
      document
        .querySelector('.esios-smercado__button-remove')
        .classList.add('hidden');
    filterData();
  };

  const onSmChange = (event) => {
    filterForm.sm = event.target.value;
    let selected = document.querySelector('.ui-selectmenu-text');
    filterForm.sm === ''
      ? (selected.innerHTML = i18n.t('BRP-Type'))
      : filterForm.sm;
    filterData();
  };

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();

    document.title = `${i18n.t('market_subjects_title')} | ${i18n.t(
      'page_subtitle'
    )}`;
    context.name = state.exampleReducer.name;
    columns = [
      {
        title: 'name',
        dataIndex: i18n.t('name'),
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            if (order === 'asc') {
              if (a[i18n.t('name')] < b[i18n.t('name')]) {
                return -1;
              }
              if (a[i18n.t('name')] > b[i18n.t('name')]) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (a[i18n.t('name')] > b[i18n.t('name')]) {
                return -1;
              }
              if (a[i18n.t('name')] < b[i18n.t('name')]) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
      {
        title: 'subject-code',
        dataIndex: i18n.t('subject-code'),
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            if (order === 'asc') {
              if (a[i18n.t('subject-code')] < b[i18n.t('subject-code')]) {
                return -1;
              }
              if (a[i18n.t('subject-code')] > b[i18n.t('subject-code')]) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (a[i18n.t('subject-code')] > b[i18n.t('subject-code')]) {
                return -1;
              }
              if (a[i18n.t('subject-code')] < b[i18n.t('subject-code')]) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
      {
        title: 'eic-code',
        dataIndex: [i18n.t('eic-code')],
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            if (order === 'asc') {
              if (a[i18n.t('eic-code')] < b[i18n.t('eic-code')]) {
                return -1;
              }
              if (a[i18n.t('eic-code')] > b[i18n.t('eic-code')]) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (a[i18n.t('eic-code')] > b[i18n.t('eic-code')]) {
                return -1;
              }
              if (a[i18n.t('eic-code')] < b[i18n.t('eic-code')]) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
      {
        title: 'BRP-Type',
        dataIndex: i18n.t('BRP-Type'),
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            if (order === 'asc') {
              if (a[i18n.t('BRP-Type')] < b[i18n.t('BRP-Type')]) {
                return -1;
              }
              if (a[i18n.t('BRP-Type')] > b[i18n.t('BRP-Type')]) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (a[i18n.t('BRP-Type')] > b[i18n.t('BRP-Type')]) {
                return -1;
              }
              if (a[i18n.t('BRP-Type')] < b[i18n.t('BRP-Type')]) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
    ];

    objMarket = [
      {
        type: 'title',
        id: 1,
        title: 'how_to_become_a_balance_responsible_party',
      },
      {
        type: 'button',
        id: 1,
        label: 'guides_for_admission',
        url: `/${i18n.t('routes.lng')}/${i18n.t(
          'routes.documentation'
        )}?scope=${i18n.t('routes.structural_data')}`,
      },
      {
        type: 'button',
        id: 2,
        label: 'gde_website_text',
        url: 'https://www.ree.es/es/clientes',
      },
      {
        type: 'text',
        id: 1,
        content: 'market_subject_wannabe_description',
      },
    ];

    listMarket = [
      {
        type: 'title',
        id: 2,
        title: 'structural_data',
      },
      {
        type: 'list',
        id: 1,
        text: 'participants_auction',
        url: `/${i18n.t('routes.lng')}/${i18n.t(
          'routes.participants_auction'
        )}`,
      },
      {
        type: 'list',
        id: 2,
        text: 'programming_units',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.programming_units')}`,
      },
      {
        type: 'list',
        id: 3,
        text: 'physical_units',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.physical_units')}`,
      },
      {
        type: 'list',
        id: 4,
        text: 'market_subjects',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.market_subjects')}`,
      },
    ];
  };

  let renderComponent = async () => {
    const state = store.getState();
    try {
      templateSujetosMercado(
        {
          title: i18n.t('balance-responsible-parties'),
          body: `<p>${i18n.t('b-r-p_desc')}</p>`,
        },
        { objMarket, listMarket },
        handlers,
        select,
        searchForm,
        paginationValues,
        pagination,
        lang
      );
      // Render the template to the document
      contactData = await servicesSujetosMercado.init(
        state.exampleReducer.currentLang
      );
      await getData();

      templateSujetosMercado(
        contactData,
        { objMarket, listMarket },
        handlers,
        select,
        searchForm,
        paginationValues,
        pagination,
        lang
      );

      document
        .querySelector('.esios-pagination__results')
        .classList.remove('hidden');
      document
        .querySelector('.esios-smercado__div-header')
        .classList.remove('hidden');
      document
        .querySelector('.esios-layout__print-logo')
        .classList.add('sujetos');
      document
        .getElementById('esios-layout-banner')
        .classList.add('hideForPrint');

      filterData();
      const dataCsv = data.map((i) => {
        return [
          i[i18n.t('name')],
          i[i18n.t('subject-code')],
          i[i18n.t('eic-code')],
          i[i18n.t('BRP-Type')],
        ];
      });
      dataCsv.unshift([
        i18n.t('name'),
        i18n.t('subject-code'),
        i18n.t('eic-code'),
        i18n.t('BRP-Type'),
      ]);
      const fileName =
        'export_' +
        i18n.t('routes.market_subjects') +
        '_' +
        moment().format('YYYY-MM-DD_HH_mm');
      const buttonsArray = [
        {
          title: 'EXPORTAR JSON',
          action: 'exportJSON',
          data: data.map((i) => ({
            [i18n.t('name')]: i[i18n.t('name')],
            [i18n.t('subject-code')]: i[i18n.t('subject-code')],
            [i18n.t('eic-code')]: i[i18n.t('eic-code')],
            [i18n.t('BRP-Type')]: i[i18n.t('BRP-Type')],
          })),
          docName: fileName,
        },
        {
          title: 'EXPORTAR CSV',
          action: 'exportCSV',
          data: dataCsv,
          docName: fileName,
        },
        {
          title: 'EXPORTAR EXCEL',
          action: 'exportExcel',
          data: dataCsv,
        },
        { title: 'IMPRIMIR', action: 'print', url: '#' },
      ];

      componentButtons.render(buttonsArray, '#sujeto-mercado-buttons');
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
    const selects = [
      {
        className: '.esios-smercado__select',
        evento: handlers.onSmChange,
      },
    ];

    const createSelect = (selects) => {
      selects.map(({ className, evento }) => {
        $(className).selectmenu({
          appendTo: '.esios-smercado__div-header',
        });
        $(className).on('selectmenuchange', function (event, ui) {
          evento(event);
        });
      });
    };

    createSelect(selects);

    document
      .querySelector('.esios-table')
      .classList.add('esios-table--sujetomercado');
    document
      .querySelector('.row.is-strech')
      .classList.add('row.is-strech--sujetomercado');

    document
      .querySelector('.esios-footer')
      .classList.add('esios-footer--sujetomercado');
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
    document
      .querySelector('.esios-btn__container')
      .classList.add('esios-btn__container--balance');
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentSujetosMercado };
