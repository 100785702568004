'use strict';

/**
 * @description Select Component
 * @returns {Object} Devuelve la instancia del componente
 */
import { render } from 'lit-html';
import { store } from '../../../app.init';
import { componentResultados } from '../../../pages/resultados/resultados.component';
import { Utils } from '../../../utils/utils';
import { componentPaginator } from '../paginator/paginator.component';
import { componentSelects } from '../selects/selects.component';
import { templateDocumentList } from './document-list.template';
import * as translationEn from '../../../assets/translations/en.json';
import * as translationEs from '../../../assets/translations/es.json';

let componentDocumentList = (function () {
  let context = {};
  let handlers = {
    onPageChange: (page) => onPageChange(page),
    onQueryChange: (event) => onQueryChange(event),
    onAmbitChange: (event) => onAmbitChange(event),
    onHide: (event) => onHide(event),
  };

  let ambitValue = '';
  let categoryValue = '';
  let actionValue = '';
  let processValue = '';
  let queryValue = '';
  let renderDLAgain;
  let renderSuccess = false;
  let isResultadosPage = false;
  let pageRes = false;

  let defaultCategoryArrayOptions = [
    { label: 'show_all', value: '' },
    { label: 'Allocations', value: '38' },
    { label: 'Imbalances', value: '36' },
    { label: 'Availability', value: '26' },
    { label: 'Unavailability', value: '27' },
    { label: 'Aggregated_measures', value: '193' },
    {
      label: 'Measures_by_hourly_discrimination',
      value: '191',
    },
    { label: 'Measures_by_interconnetion', value: '195' },
    { label: 'Measures_by_voltage_level', value: '192' },
    { label: 'measures_by_access_tariff', value: '34' },
    { label: 'Measures_by_production_type', value: '194' },
    { label: 'Offers', value: '33' },
    { label: 'Other_measures', value: '196' },
    { label: 'other_programs', value: '208' },
    { label: 'Installed_power', value: '28' },
    { label: 'Forecasts', value: '30' },
    { label: 'P48_programs', value: '31' },
    { label: 'PBF_programs', value: '197' },
    { label: 'PHF1_programs', value: '199' },
    { label: 'PHF2_programs', value: '200' },
    { label: 'PHF3_programs', value: '201' },
    { label: 'PHF4_programs', value: '202' },
    { label: 'PHF5_programs', value: '203' },
    { label: 'PHF6_programs', value: '204' },
    { label: 'PHF7_programs', value: '205' },
    { label: 'PHFC_programs', value: '213' },
    { label: 'PVP_programs', value: '198' },
    { label: 'Requirements', value: '32' },
    { label: 'Telemeasurements', value: '37' },
    { label: 'Real_time', value: '189' },
  ];

  let generacionCategoryArrayOptions = [
    { label: 'show_all', value: '' },
    { label: 'Disponibilidades', value: '26' },
    { label: 'Indisponibilidades', value: '27' },
    { label: 'Aggregated_measures', value: '193' },
    {
      label: 'Medidas por discriminación horaria',
      value: '191',
    },
    { label: 'Measures_by_interconnetion', value: '195' },
    { label: 'Measures_by_voltage_level', value: '192' },
    { label: 'measures_by_access_tariff', value: '34' },
    { label: 'Measures_by_production_type', value: '194' },
    { label: 'Other_measures', value: '196' },
    { label: 'other_programs', value: '208' },
    { label: 'Installed_power', value: '28' },
    { label: 'Forecasts', value: '30' },
    { label: 'P48_programs', value: '31' },
    { label: 'PBF_programs', value: '197' },
    { label: 'PHF1_programs', value: '199' },
    { label: 'PHF2_programs', value: '200' },
    { label: 'PHF3_programs', value: '201' },
    { label: 'PHF4_programs', value: '202' },
    { label: 'PHF5_programs', value: '203' },
    { label: 'PHF6_programs', value: '204' },
    { label: 'PHF7_programs', value: '205' },
    { label: 'PVP_programs', value: '198' },
    { label: 'Telemeasurements', value: '37' },
    { label: 'Real_time', value: '189' },
  ];

  let gestionDemandaCategoryArrayOptions = [{ label: 'show_all', value: '' }];
  let mercadosPreciosCategoryArrayOptions = [
    { label: 'show_all', value: '' },
    { label: 'Allocations', value: '38' },
    { label: 'Imbalances', value: '36' },
    { label: 'Offers', value: '33' },
    { label: 'Other_measures', value: '196' },
    { label: 'other_programs', value: '208' },
    { label: 'Requirements', value: '32' },
  ];
  let sistemasNoPeninsularesCategoryArrayOptions = [
    { label: 'show_all', value: '' },
    { label: 'Aggregated_measures', value: '193' },
    { label: 'Other_measures', value: '196' },
    { label: 'other_programs', value: '208' },
    { label: 'Forecasts', value: '30' },
  ];

  let defaultActionArrayOptions = [
    { label: 'action', value: '' },
    { label: 'process', value: '' },
  ];

  let generacionActionArrayOptions = [
    { label: 'show_all', value: '' },
    { label: 'consumption', value: '40' },
    { label: 'generation', value: '39' },
    { label: 'exchanges', value: '145' },
    { label: 'loss', value: '146' },
  ];

  let defaultProcessArrayOptions = [{ label: 'show_all', value: '' }];

  let mercadosProcessArrayOptions = [
    { label: 'show_all', value: '' },
    { label: 'balit', value: '95' },
    { label: 'Secondary_reserve', value: '91' },
    { label: 'Bilateral_contracts', value: '93' },
    { label: 'Imbalances', value: '161' },
    { label: 'Replacement_Reserves', value: '86' },
    { label: 'Balance_security_energies', value: '230' },
    { label: 'Supply_security', value: '90' },
    { label: 'Continuous_intraday_market', value: '214' },
    { label: 'Day_ahead_market', value: '149' },
    { label: 'Intraday_market', value: '84' },
    { label: 'pvpc', value: '96' },
    { label: 'Capacity_payments', value: '94' },
    { label: 'Upwards_reserve_power', value: '92' },
    { label: 'Last_resort_suppliers_price', value: '207' },
    { label: 'Competitive_suppliers_price', value: '206' },
    { label: 'Electric_system_consumers_price', value: '160' },
    { label: 'Tertiary_reserve', value: '87' },
    { label: 'Technical_constraints_day_ahead_market', value: '85' },
    { label: 'Intraday_security', value: '147' },
    { label: 'Real_time_security', value: '148' },
    { label: 'Explicit_auctions', value: '88' },
    { label: 'Secondary_reserve_energy', value: '89' },
    { label: 'active_demand_response', value: '236' },
  ];

  let exchanges_iCategoryArrayOptions = [
    { label: 'show_all', value: '' },
    { label: 'capacity-and-utilization', value: '521' },
    { label: 'economic-management', value: '522' },
    { label: 'explicit_auct', value: '523' },
  ];
  let exchanges_iActionArrayOptions = [
    { label: 'show_all', value: '' },
    { label: 'france', value: '528' },
    { label: 'portugal', value: '525' },
    { label: 'morocco', value: '526' },
    { label: 'andorra', value: '527' },
  ];

  let exchanges_iProcessArrayOptions = [
    { label: 'show_all', value: '' },
    { label: 'long_term', value: '529' },
    { label: 'daily', value: '530' },
    { label: 'intraday', value: '531' },
    { label: 'Cross_border_balancing_services', value: '532' },
    { label: 'Real_time', value: '533' },
  ];

  let filterForm = {
    ambit: ambitValue,
    category: categoryValue,
    actions: actionValue,
    process: processValue,
    query: queryValue,
  };

  const selectAmbit = {
    id: 'select_ambit',
    currentLabel: 'ambit',
    currentValue: '',
    label: '',
    options: [
      { label: 'show_all', value: '' },
      { label: 'generation_and_consumption', value: '155' },
      { label: 'demand_management', value: '158' },
      { label: 'markets_and_prices', value: '156' },
      { label: 'non_peninsular_systems', value: '157' },
      { label: 'exchanges_i', value: '520' },
    ],
    columns: 1,
    onlySelect: true,
    changeTime: (value) => changeTime(value),
  };

  const selectCategory = {
    id: 'select_category',
    currentLabel: 'status',
    currentValue: '',
    label: '',
    options: defaultCategoryArrayOptions,
    columns: 1,
    onlySelect: true,
    changeTime: (value) => changeTime(value),
  };

  const selectAction = {
    id: 'select_action',
    currentLabel: 'action',
    currentValue: '',
    label: '',
    options: defaultActionArrayOptions,
    columns: 1,
    onlySelect: true,
    disabled: true,
    changeTime: (value) => changeTime(value),
  };

  const selectProccess = {
    id: 'select_proccess',
    currentLabel: 'process',
    currentValue: '',
    label: '',
    options: defaultProcessArrayOptions,
    columns: 1,
    onlySelect: true,
    disabled: true,
    changeTime: (value) => changeTime(value),
  };

  const changeTime = (value) => {
    Utils.spinnerStatus('dl-spinner', true);
    document.getElementById('dl-results').style.display = 'none';
    document.getElementById('pagination').style.display = 'none';
    const { id, newValue } = value;
    if (selectAmbit.id === id) {
      selectAmbit.options.map((el) => {
        if (el.value === `${newValue}`) {
          selectAmbit.currentLabel = el.value === '' ? 'ambit' : el.label;
          selectAmbit.currentValue = el.value;
          onAmbitChange(el.value);
        }
        componentSelects.render(selectAmbit, '#ambit-select');
      });
      return;
    }
    if (selectCategory.id === id) {
      selectCategory.options.map((el) => {
        if (el.value === `${newValue}`) {
          selectCategory.currentLabel = el.value === '' ? 'status' : el.label;
          selectCategory.currentValue = el.value;
          onCategoryChange(el.value);
        }
        componentSelects.render(selectCategory, '#category-select');
      });
      return;
    }
    if (selectAction.id === id) {
      selectAction.options.map((el) => {
        if (el.value === `${newValue}`) {
          selectAction.currentLabel = el.value === '' ? 'action' : el.label;
          selectAction.currentValue = el.value;
          onActionChange(el.value);
        }
        componentSelects.render(selectAction, '#action-select');
      });
      return;
    }
    if (selectProccess.id === id) {
      selectProccess.options.map((el) => {
        if (el.value === `${newValue}`) {
          selectProccess.currentLabel = el.value === '' ? 'process' : el.label;
          selectProccess.currentValue = el.value;
          onProccessChange(el.value);
        }
        componentSelects.render(selectProccess, '#proccess-select');
      });
      return;
    }
  };

  let data = [];
  let dataPaginated = [];
  let paginationValues = [50, 100];
  let pagination = {
    total: 0,
    page: 1,
    totalPages: 1,
    pagination: paginationValues[0],
  };

  const onHide = (event) => {
    filterForm.query = '';
    document.querySelector('.esios-tabla__input').value = '';
    document
      .querySelector('.esios-document-list__button-remove')
      .classList.add('hidden');
    document.querySelector('.search-filter').classList.remove('hidden');
    data = [];
    dataPaginated = [];
    renderDLAgain(filterForm);
    filterData();
  };

  // paginacion funciones
  const onPageChange = (page) => {
    pagination.page = page;
    pagination.totalPages = Math.ceil(data.length / pagination.pagination);
    document
      .getElementById('c_document-list')
      .scrollIntoView({ behavior: 'smooth' });
    renderDLAgain(filterForm);
  };

  // fin paginacion
  // filtros funciones de filtrado abajo
  const onQueryChange = (event) => {
    let removeBtn = document.querySelector(
      '.esios-document-list__button-remove'
    );
    let loupe = document.querySelector('.search-filter');

    if ((event.keyCode >= 65 && event.keyCode <= 90) || event.keyCode === 8) {
      Utils.spinnerStatus('dl-spinner', true);
      document.getElementById('dl-results').style.display = 'none';
      queryValue = event.target.value;
      filterForm.query = event.target.value ? event.target.value : '';

      if (queryValue.length > 3 || queryValue.length === 0) {
        renderDLAgain(filterForm);
      }
    }

    if (event.target.value.length > 0) {
      removeBtn.classList.remove('hidden');
      loupe.classList.add('hidden');
    } else {
      removeBtn.classList.add('hidden');
      loupe.classList.remove('hidden');
    }
  };

  const resetSelects = () => {
    ambitValue = '';
    categoryValue = '';
    actionValue = '';
    processValue = '';
    selectCategory.options = defaultCategoryArrayOptions;
    selectAction.options = defaultActionArrayOptions;
    selectProccess.options = defaultProcessArrayOptions;
    selectAmbit.currentLabel = 'ambit';
    selectCategory.currentLabel = 'status';
    selectAction.currentLabel = 'action';
    selectProccess.currentLabel = 'process';
    selectAmbit.currentValue = '';
    selectCategory.currentValue = '';
    selectAction.currentValue = '';
    selectProccess.currentValue = '';
    selectAction.disabled = true;
    selectProccess.disabled = true;

    const selectsForRender = [
      {
        selectContent: selectAmbit,
        tag: '#ambit-select',
      },
      {
        selectContent: selectCategory,
        tag: '#category-select',
      },
      {
        selectContent: selectAction,
        tag: '#action-select',
      },
      {
        selectContent: selectProccess,
        tag: '#proccess-select',
      },
    ];

    selectsForRender.forEach((el) =>
      componentSelects.render(el.selectContent, el.tag)
    );
  };

  function translateArray(array, translation) {
    return array.map((opcion) => {
      if (translation.routes && translation.routes[opcion.label]) {
        opcion.label = translation.routes[opcion.label];
      } else if (translation[opcion.label]) {
        opcion.label = translation[opcion.label];
      }
      return opcion;
    });
  }

  function orderArray(array) {
    return array.slice().sort((a, b) => {
      if (a.label === 'Show all' || a.label === 'Mostrar todo') {
        return -1;
      } else if (b.label === 'Show all' || b.label === 'Mostrar todo') {
        return 1;
      } else {
        return a.label.localeCompare(b.label);
      }
    });
  }

  const onAmbitChange = (event) => {
    ambitValue = event !== '' ? event : '';
    categoryValue = '';
    actionValue = '';
    processValue = '';
    queryValue = '';
    selectCategory.options = defaultCategoryArrayOptions;
    selectAction.options = defaultActionArrayOptions;
    selectProccess.options = defaultProcessArrayOptions;

    selectCategory.currentLabel = 'status';
    selectAction.currentLabel = 'action';
    selectProccess.currentLabel = 'process';
    selectCategory.currentValue = '';
    selectAction.currentValue = '';
    selectProccess.currentValue = '';

    if (ambitValue === '155') {
      selectCategory.options = generacionCategoryArrayOptions;
      selectAction.options = generacionActionArrayOptions;
      selectProccess.options = defaultProcessArrayOptions;
      selectAction.disabled = false;
      selectProccess.disabled = true;
    } else if (ambitValue === '158') {
      selectCategory.options = gestionDemandaCategoryArrayOptions;
      selectAction.options = defaultActionArrayOptions;
      selectProccess.options = defaultProcessArrayOptions;
      selectAction.disabled = true;
      selectProccess.disabled = true;
    } else if (ambitValue == '156') {
      const state = store.getState();
      const lang = state.exampleReducer.currentLang;
      if (lang == 'en') {
        mercadosProcessArrayOptions = translateArray(
          mercadosProcessArrayOptions,
          translationEn
        );
        mercadosProcessArrayOptions = orderArray(mercadosProcessArrayOptions);
      } else {
        mercadosProcessArrayOptions = translateArray(
          mercadosProcessArrayOptions,
          translationEs
        );
        mercadosProcessArrayOptions = orderArray(mercadosProcessArrayOptions);
      }
      selectCategory.options = mercadosPreciosCategoryArrayOptions;
      selectAction.options = defaultActionArrayOptions;
      selectProccess.options = mercadosProcessArrayOptions;
      selectAction.disabled = true;
      selectProccess.disabled = false;
    } else if (ambitValue == '157') {
      selectCategory.options = sistemasNoPeninsularesCategoryArrayOptions;
      selectAction.options = defaultActionArrayOptions;
      selectProccess.options = defaultProcessArrayOptions;

      selectAction.disabled = true;
      selectProccess.disabled = true;
    } else if (ambitValue == '520') {
      selectCategory.options = exchanges_iCategoryArrayOptions;
      selectAction.options = exchanges_iActionArrayOptions;
      selectProccess.options = exchanges_iProcessArrayOptions;

      selectAction.disabled = false;
      selectProccess.disabled = false;
    } else {
      selectCategory.options = defaultCategoryArrayOptions;
      selectAction.options = defaultActionArrayOptions;
      selectProccess.options = defaultProcessArrayOptions;
      selectAction.disabled = true;
      selectProccess.disabled = true;
    }

    filterForm.ambit = event;
    filterForm.category = '';
    filterForm.actions = '';
    filterForm.process = '';

    pagination.page = 1;
    renderDLAgain(filterForm);
  };

  const onCategoryChange = (event) => {
    categoryValue = event !== '' ? event : '';
    filterForm.category = categoryValue;
    renderDLAgain(filterForm);
    filterData();
  };

  const onActionChange = (event) => {
    actionValue = event !== '' ? event : '';
    filterForm.actions = actionValue;
    renderDLAgain(filterForm);
    filterData();
  };
  const onProccessChange = (event) => {
    processValue = event !== '' ? event : '';
    filterForm.process = processValue;
    renderDLAgain(filterForm);
    filterData();
  };

  const filterData = (data) => {
    if (data) {
      if (isResultadosPage) {
        componentResultados.filterData(data);
      } else {
        dataPaginated = data.slice(
          (pagination.page - 1) * pagination.pagination,
          pagination.page * pagination.pagination
        );
        pagination.total = data.length;
        pagination.totalPages = Math.ceil(data.length / pagination.pagination);
        componentPaginator.render(
          pagination,
          onPageChange,
          'pagination-document-list'
        );
      }
    }
  };

  let preRenderComponent = (selectorData, documentElement) => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    context.name = state.exampleReducer.name;
    renderDLAgain = selectorData.renderAgain;
  };

  let renderComponent = async (selectorData, documentElement) => {
    try {
      filterData(selectorData.data);
      render(
        //  Define a template
        templateDocumentList(handlers, filterForm, selectorData, dataPaginated),
        document.querySelector(documentElement)
      );
      Utils.spinnerStatus('dl-spinner', false);
      document.getElementById('dl-results').style.display = 'flex';
      document.getElementById('pagination-document-list').style.display =
        'flex';
      document.getElementById('no-dl-results').style.display = 'none';

      const selectsForRender = [
        {
          selectContent: selectAmbit,
          tag: '#ambit-select',
        },
        {
          selectContent: selectCategory,
          tag: '#category-select',
        },
        {
          selectContent: selectAction,
          tag: '#action-select',
        },
        {
          selectContent: selectProccess,
          tag: '#proccess-select',
        },
      ];

      selectsForRender.forEach((el) =>
        componentSelects.render(el.selectContent, el.tag)
      );
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }

    let paginator = document.querySelector('#pagination');
    let noResult = document.getElementById('no-dl-results');
    if (dataPaginated.length === 0) {
      paginator.style.display = 'none';
      if (
        filterForm.ambit !== '' ||
        filterForm.category !== '' ||
        filterForm.actions !== '' ||
        filterForm.process !== '' ||
        filterForm.query !== ''
      ) {
        noResult.style.display = 'flex';
      }
    } else {
      paginator.style.display = 'flex';
      noResult.style.display = 'none';
    }
  };

  let postRenderComponent = (selectorData, documentElement) => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
    componentPaginator.render(
      pagination,
      onPageChange,
      'pagination-document-list'
    );
  };

  let init = (selectorData, documentElement) => {
    if (selectorData.isResultadosPage) {
      isResultadosPage = true;
    }
    //Call pre render component
    preRenderComponent(selectorData, documentElement);

    // Call render component
    renderComponent(selectorData, documentElement);

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent(selectorData, documentElement) : '';
  };

  return {
    render: (selectorData, documentElement) =>
      init(selectorData, documentElement),
    resetSelects,
  };
})();

export { componentDocumentList };
