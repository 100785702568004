import i18n from 'i18next';
import { html } from 'lit-html';
import moment from 'moment';
import { store } from '../../../app.init';
import { templateShareModal } from '../../../shared/components/modal/modalShare.template';
import { componentSelects } from '../../../shared/components/selects/selects.component';
import { componentToolbar } from '../../../shared/components/toolbar/toolbar.component';
import { getUrlParams } from '../../../utils/url';
import { Utils } from '../../../utils/utils';
import {
  calculateHoursPerDay,
  categories,
  getArrayOfHoursForBalance,
  renderGraph,
  validateDateAndAgg,
} from '../../balance/balance.component';
import { balanceObjects } from '../../balance/balance.content';
import { servicesBalance } from '../../balance/balance.services';
import { componentTab } from '../../tab/tab.component';

const TemplateEmbedIndicatorsProgramBalance = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let itemSelected = getUrlParams()?.program || 'P48',
    selectedAgg,
    selectedDate,
    miArrayTabYear,
    miArrayTab,
    miArrayTabMonth,
    dataValue = [1, 2, 3, 4, 5, 6],
    sndTabValue = [7, 8, 9, 10, 11, 12],
    dayFirstTabValue = [0, 1, 2, 3, 4, 5],
    daySecondTabValue = [6, 7, 8, 9, 10, 11],
    daythirdTabValue = [12, 13, 14, 15, 16, 17],
    dayfourthTabValue = [18, 19, 20, 21, 22, 23],
    monthFirstTabValue = [0, 1, 2, 3, 4, 5, 6, 7],
    monthSecondTabValue = [8, 9, 10, 11, 12, 13, 14, 15],
    monthThirdTabValue = [16, 17, 18, 19, 20, 21, 22, 23],
    monthFourthTabValue = [24, 25, 26, 27, 28, 29, 30],
    data,
    sortedList,
    wordFound,
    finalItems,
    numberOfDaysInMonth,
    tabs,
    finalArray,
    arrayOfElements,
    selectedProgram,
    programs = balanceObjects.programs,
    categories = balanceObjects.categories,
    totalCategories = balanceObjects.totalCategories,
    rangeDate = [
      { alias: 'hour', name: 'daily_f', selected: '' },
      { alias: 'day', name: 'monthly', selected: '' },
      { alias: 'month', name: 'yearly', selected: '' },
    ],
    obToolbar = [
      {
        id: '24',
        slug: i18n.t('routes.scheduled-generation-balance'),
        params: 'program=P48&agg=hour',
        info: true,
        share: true,
        favorite: true,
        favId: 24,
        analysis: true,
        url: () =>
          `/${i18n.t('routes.lng')}/${i18n.t(
            'routes.analysis'
          )}/10027?compare_indicators=10141,10142,10143,10144,10145,10146,10147,10148,10149,10279&start_date=${moment(
            document.getElementById('datepicker').value,
            'DD / MM / YYYY'
          ).format('DD-MM-YYYYTHH:mm')}&geoids=`,
        textModal: ``,
      },
    ];

  // mobile__selector
  const selectMobileSelector = {
    id: 'mobile__selector_id',
    currentLabel: '0-1 H',
    currentValue: '0',
    label: '',
    options: [],
    columns: 1,
    onlySelect: true,
    changeTime: (value) => changeTimeFromSelector(value),
  };

  const changeTimeFromSelector = (value) => {
    const { id, newValue } = value;

    if (selectMobileSelector.id === id) {
      selectMobileSelector.options.map((el) => {
        if (el.value === newValue) {
          selectMobileSelector.currentLabel = el.label;
          selectMobileSelector.currentValue = el.value;
          renderColumnSelected(el.value, urlParams.get('agg'));
          componentSelects.render(selectMobileSelector, '#mobile__selector');
        }
      });

      return;
    }
  };

  const onStateHandler = () => {
    let list = document.getElementById('list_programs');
    let listTwo = document.getElementById('list_selections');

    if (list.className === 'esios-select__selectableHidden') {
      list.classList.remove('esios-select__selectableHidden');
      list.classList.add('esios-select__selectable');
      listTwo.classList.add('esios-select__selectableHidden');
    } else {
      list.classList.remove('esios-select__selectable');
      list.classList.add('esios-select__selectableHidden');
    }
  };
  const onToogleHandler = () => {
    let listTwo = document.getElementById('list_selections');
    let list = document.getElementById('list_programs');

    if (listTwo.className === 'esios-select__selectableHidden') {
      listTwo.classList.remove('esios-select__selectableHidden');
      listTwo.classList.add('esios-select__selectable');
      list.classList.add('esios-select__selectableHidden');
    } else {
      listTwo.classList.remove('esios-select__selectable');
      listTwo.classList.add('esios-select__selectableHidden');
    }
  };

  let setInfoModal = (id, data, html, updateShareModal = 'all') => {
    if (updateShareModal !== 'share') {
      obToolbar[id].textModal = `<h2>${data.name}</h2>${data.description}`;
    }
    data.indicators.sort((a, b) => a.id - b.id);
    obToolbar[id].widgetData = data;
    obToolbar[id].shareModal = html;
    obToolbar[id].uri = '/' + obToolbar[id].id + '?' + obToolbar[id]?.params;
    obToolbar[id].myEsios = true;
    componentToolbar.render(obToolbar[0], '.esios-toolbar24');
  };

  const updateMobileSelectorOpts = (aggURL) => {
    let dateDatepicker = moment(
      document.getElementById('datepicker').value,
      'DD / MM / YYYY'
    ).format('DD-MM-YYYY');
    setParameter('date', dateDatepicker);
    selectMobileSelector.options = getArrayOfHoursForBalance(aggURL);
    componentSelects.render(selectMobileSelector, '#mobile__selector');
  };

  const setParameter = (key, value) => {
    urlParams.set(key, value);
    history.pushState({}, '', window.location.pathname + '?' + urlParams);
  };

  const calculateTotalGeneration = (maxArrVal) => {
    const newArrr = {
      composited: true,
      disaggregated: false,
      id: 90000,
      name: `${i18n.t('total_generation')}`,
      short_name: `${i18n.t('total_generation')}`,
      values: [],
    };

    for (let quantityFormat = 0; quantityFormat < maxArrVal; quantityFormat++) {
      let totalGene = [];
      totalGene[quantityFormat] = 0;
      for (
        let indicatorCounter = 0;
        indicatorCounter < 12;
        indicatorCounter++
      ) {
        totalGene[quantityFormat] +=
          finalArray[indicatorCounter].values[quantityFormat] === undefined
            ? 0
            : finalArray[indicatorCounter].values[quantityFormat].value;
      }
      newArrr.values.push({
        value: totalGene[quantityFormat],
      });
    }

    finalArray.splice(12, 0, newArrr);

    finalArray = finalArray.map((el) => {
      if (balanceObjects.categories[18].indicators.includes(el.id)) {
        return {
          ...el,
          short_name: `${i18n.t('programs_adjustment')}`,
        };
      } else return el;
    });
  };

  const updateTabDay = (
    dataElement,
    dayFirstTabValue,
    daySecondTabValue,
    daythirdTabValue,
    dayfourthTabValue
  ) => {
    let { date } = getUrlParams();
    let rangeNum = date
      ? calculateHoursPerDay(moment(date, 'DD-MM-YYYY'))
      : calculateHoursPerDay(moment().format('DD-MM-YYYY'));

    let firstHours =
      rangeNum === 25
        ? ['0-1', '1-2', '2-2B', '2B-3', '3-4', '4-5', '5-6']
        : ['0-1', '1-2', '2-3', '3-4', '4-5', '5-6'];
    let secondHours = ['6-7', '7-8', '8-9', '9-10', '10-11', '11-12'];
    let thirdHours = ['12-13', '13-14', '14-15', '15-16', '16-17', '17-18'];
    let fourthHours = ['18-19', '19-20', '20-21', '21-22', '22-23', '23-24'];
    arrayOfElements = categories.map((el) => el.name);
    arrayOfElements.splice(18, 0, totalCategories[1].alias);
    finalArray = [];
    let tempFinalArr = [];
    selectedProgram = selectedProgram || getUrlParams().program || 'P48';
    let tempIdOrderProgram = balanceObjects.programs.filter(
      (el) => el.name === selectedProgram
    );
    let idOrderArr = tempIdOrderProgram[0].indicators;
    let filterGraph = idOrderArr.filter((el, index) => index < 19);
    const orderedBarData = Utils.orderObjectsArrayByKey(
      dataElement,
      idOrderArr,
      'id'
    );
    finalArray = orderedBarData;
    tempFinalArr = orderedBarData;

    let hoursArray = Utils.getArrayOfHours();
    if (rangeNum === 25) {
      hoursArray.push({ label: '24', value: '24' });
      dayFirstTabValue = [0, 1, 2, 3, 4, 5, 6];
      daySecondTabValue = [7, 8, 9, 10, 11, 12];
      daythirdTabValue = [13, 14, 15, 16, 17, 18];
      dayfourthTabValue = [19, 20, 21, 22, 23, 24];
    }

    let tempArr = tempFinalArr.map((el) => {
      return {
        ...el,
        values: hoursArray.map(() => {
          return undefined;
        }),
      };
    });

    let doubleSameHour;
    for (let i = 0; i < finalArray.length; i++) {
      doubleSameHour = false;
      for (let x = 0; x < finalArray[i].values.length; x++) {
        let hour = parseInt(
          moment(finalArray[i].values[x].datetime)
            .tz('Europe/Madrid')
            .format('HH')
        );

        if (hour > 2) {
          doubleSameHour = true;
        }

        let nextHour = parseInt(
          moment(finalArray[i]?.values[x + 1]?.datetime)
            .tz('Europe/Madrid')
            .format('HH')
        );

        tempArr[i].values.splice(
          rangeNum == 25 && hour >= 2 && doubleSameHour ? hour + 1 : hour,
          1,
          finalArray[i].values[x]
        );

        if (hour == nextHour) {
          doubleSameHour = true;
        }
      }
    }
    finalArray = tempArr;
    calculateTotalGeneration(rangeNum === 23 ? rangeNum + 1 : rangeNum);

    if (rangeNum === 23) {
      firstHours = firstHours.filter((el) => el !== '2-3');
      dayFirstTabValue = dayFirstTabValue.filter((el) => el !== 2);
    }

    miArrayTab = [
      {
        text: `0 - 6 H`,
        content: `<div>
            <table>
            <tbody>
                <tr class="esios-balance__tabs-row table-title-row">
                  <td class="esios-balance__tabs-row-title">
                    <div class="esios-balance__tabs-title">
                      <p>${i18n.t('hour')}</p>
                    </div>
                  </td>
                  ${firstHours
                    .map(
                      (hour) => `
                    <td>
                      <div class="esios-balance__tabs-column-title ${hour}h">
                        <p>${hour} H</p>
                      </div>
                    </td>
                  `
                    )
                    .join('')}
                </tr>
              ${finalArray
                .map((element, i) => {
                  return `
                <tr  ${
                  element.short_name.trim().toLowerCase() ===
                    i18n.t('interconnections_balance').toLowerCase() ||
                  element.short_name == 'Demanda Peninsular' ||
                  element.short_name == `${i18n.t('total_generation')}`
                    ? "class ='esios-balance__tabs-row tab-border-bottom'"
                    : "class='esios-balance__tabs-row'"
                }>
                  <td  ${
                    element.short_name.trim().toLowerCase() ===
                      i18n.t('interconnections_balance').toLowerCase() ||
                    element.short_name == 'Demanda Peninsular' ||
                    element.short_name == `${i18n.t('total_generation')}`
                      ? `class='esios-balance__tabs-row-title tab-row-bold'`
                      : `class='esios-balance__tabs-row-title'`
                  }>${
                    element.id === 10196
                      ? i18n.t('programs_adjustment')
                      : element.id == 10186
                      ? i18n.t('interconnections_balance')
                      : element.short_name
                  }</td>
                  ${dayFirstTabValue
                    .map(
                      (item, ind) =>
                        `<td  ${
                          element.id == 10186 ||
                          element.short_name == 'Demanda Peninsular' ||
                          element.short_name == `${i18n.t('total_generation')}`
                            ? `class='esios-balance__tabs-row-title tab-row-weigth column-with-data coltable-${ind}'`
                            : `class='esios-balance__tabs-row-value column-with-data coltable-${ind}'`
                        }>${
                          finalArray[i].values[item]?.value == undefined ||
                          finalArray[i].values[item]?.value == null ||
                          finalArray[i].values[item]?.value == 0
                            ? '-'
                            : Utils.toNumber(
                                finalArray[i].values[item].value,
                                1,
                                'comma'
                              )
                        }</td>`
                    )
                    .join('')}
                </tr>`;
                })
                .join('')}
              </tbody>
            </table>
          </div>`,
      },
      {
        text: `6 - 12 H`,
        content: `<div>
        <table>
         <tbody>
          <tr class="esios-balance__tabs-row">
            <td class="esios-balance__tabs-row-title">
              <div class="esios-balance__tabs-title">
                <p>${i18n.t('hour')}</p>
              </div>
            </td>
            ${secondHours
              .map(
                (hour) => `
                    <td>
                      <div class="esios-balance__tabs-column-title">
                        <p>${hour} H</p>
                      </div>
                    </td>
            `
              )
              .join('')}
          </tr>
          ${finalArray
            .map((element, i) => {
              return `
            <tr  ${
              element.short_name.trim().toLowerCase() ===
                i18n.t('interconnections_balance').toLowerCase() ||
              element.short_name == 'Demanda Peninsular' ||
              element.short_name == `${i18n.t('total_generation')}`
                ? "class ='esios-balance__tabs-row tab-border-bottom'"
                : "class='esios-balance__tabs-row'"
            }>
              <td  ${
                element.short_name.trim().toLowerCase() ===
                  i18n.t('interconnections_balance').toLowerCase() ||
                element.short_name == 'Demanda Peninsular' ||
                element.short_name == `${i18n.t('total_generation')}`
                  ? "class='esios-balance__tabs-row-title tab-row-bold'"
                  : "class='esios-balance__tabs-row-title'"
              }>${
                element.id === 10196
                  ? i18n.t('programs_adjustment')
                  : element.id == 10186
                  ? i18n.t('interconnections_balance')
                  : element.short_name
              }</td>
              ${daySecondTabValue
                .map(
                  (item, ind) =>
                    `<td  ${
                      element.id == 10186 ||
                      element.short_name == 'Demanda Peninsular' ||
                      element.short_name == `${i18n.t('total_generation')}`
                        ? `class='esios-balance__tabs-row-title tab-row-weigth  column-with-data coltable-${ind}'`
                        : `class='esios-balance__tabs-row-value  column-with-data coltable-${ind}'`
                    }>
                  ${
                    finalArray[i].values[item]?.value == undefined ||
                    finalArray[i].values[item]?.value == null ||
                    finalArray[i].values[item]?.value == 0
                      ? '-'
                      : Utils.toNumber(
                          finalArray[i].values[item].value,
                          1,
                          'comma'
                        )
                  }</td>`
                )
                .join('')}
            </tr>`;
            })
            .join('')}
          </tbody>
        </table>
      </div>`,
      },
      {
        text: `12 - 18 H`,
        content: `<div>
        <table>
         <tbody>
          <tr class="esios-balance__tabs-row">
            <td class="esios-balance__tabs-row-title">
              <div class="esios-balance__tabs-title">
                <p>${i18n.t('hour')}</p>
              </div>
            </td>
            ${thirdHours
              .map(
                (hour) => `
                    <td>
                      <div class="esios-balance__tabs-column-title">
                        <p>${hour} H</p>
                      </div>
                    </td>
            `
              )
              .join('')}
          </tr>
          ${finalArray
            .map((element, i) => {
              return `
            <tr  ${
              element.short_name.trim().toLowerCase() ===
                i18n.t('interconnections_balance').toLowerCase() ||
              element.short_name == 'Demanda Peninsular' ||
              element.short_name == `${i18n.t('total_generation')}`
                ? "class ='esios-balance__tabs-row tab-border-bottom'"
                : "class='esios-balance__tabs-row'"
            }>
              <td ${
                element.short_name.trim().toLowerCase() ===
                  i18n.t('interconnections_balance').toLowerCase() ||
                element.short_name == 'Demanda Peninsular' ||
                element.short_name == `${i18n.t('total_generation')}`
                  ? "class='esios-balance__tabs-row-title tab-row-bold'"
                  : "class='esios-balance__tabs-row-title'"
              }>${
                element.id === 10196
                  ? i18n.t('programs_adjustment')
                  : element.id == 10186
                  ? i18n.t('interconnections_balance')
                  : element.short_name
              }</td>
              ${daythirdTabValue
                .map(
                  (item, ind) =>
                    `<td  ${
                      element.id == 10186 ||
                      element.short_name == 'Demanda Peninsular' ||
                      element.short_name == `${i18n.t('total_generation')}`
                        ? `class='esios-balance__tabs-row-title tab-row-weigth  column-with-data coltable-${ind}'`
                        : `class='esios-balance__tabs-row-value  column-with-data coltable-${ind}'`
                    }>${
                      finalArray[i].values[item]?.value == undefined ||
                      finalArray[i].values[item]?.value == null ||
                      finalArray[i].values[item]?.value == 0
                        ? '-'
                        : Utils.toNumber(
                            finalArray[i].values[item].value,
                            1,
                            'comma'
                          )
                    }</td>`
                )
                .join('')}
            </tr>`;
            })
            .join('')}
          </tbody>
        </table>
      </div>`,
      },
      {
        text: `18 - 24 H`,
        content: `<div>
        <table>
         <tbody>
          <tr class="esios-balance__tabs-row">
            <td class="esios-balance__tabs-row-title">
              <div class="esios-balance__tabs-title">
                <p>${i18n.t('hour')}</p>
              </div>
            </td>
            ${fourthHours
              .map(
                (hour) => `
                    <td>
                      <div class="esios-balance__tabs-column-title">
                        <p>${hour} H</p>
                      </div>
                    </td>
            `
              )
              .join('')}
          </tr>
          ${finalArray
            .map((element, i) => {
              return `
            <tr  ${
              element.short_name.trim().toLowerCase() ===
                i18n.t('interconnections_balance').toLowerCase() ||
              element.short_name == 'Demanda Peninsular' ||
              element.short_name == `${i18n.t('total_generation')}`
                ? "class ='esios-balance__tabs-row tab-border-bottom'"
                : "class='esios-balance__tabs-row'"
            }>
              <td  ${
                element.short_name.trim().toLowerCase() ===
                  i18n.t('interconnections_balance').toLowerCase() ||
                element.short_name == 'Demanda Peninsular' ||
                element.short_name == `${i18n.t('total_generation')}`
                  ? "class='esios-balance__tabs-row-title tab-row-bold'"
                  : "class='esios-balance__tabs-row-title'"
              }>${
                element.id === 10196
                  ? i18n.t('programs_adjustment')
                  : element.id == 10186
                  ? i18n.t('interconnections_balance')
                  : element.short_name
              }</td>
              ${dayfourthTabValue
                .map(
                  (item, ind) =>
                    `<td  ${
                      element.id == 10186 ||
                      element.short_name == 'Demanda Peninsular' ||
                      element.short_name == `${i18n.t('total_generation')}`
                        ? `class='esios-balance__tabs-row-title tab-row-weigth column-with-data coltable-${ind}'`
                        : `class='esios-balance__tabs-row-value column-with-data coltable-${ind}'`
                    }>${
                      finalArray[i].values[item]?.value == undefined ||
                      finalArray[i].values[item]?.value == null ||
                      finalArray[i].values[item]?.value == 0
                        ? '-'
                        : Utils.toNumber(
                            finalArray[i].values[item].value,
                            1,
                            'comma'
                          )
                    }</td>`
                )
                .join('')}
            </tr>`;
            })
            .join('')}
          </tbody>
        </table>
      </div>`,
      },
    ];
    return miArrayTab;
  };

  const renderTabs = async () => {
    const state = store.getState();
    const inputCalendar = document.querySelector('#datepicker');
    let dateSelected = inputCalendar.value.trim();
    let formatDate = dateSelected.split('/');
    let [d, m, y] = formatDate;
    let contentPrograms = document
      .getElementById('selectOne')
      .getAttribute('value');

    let contentSelections = document
      .getElementById('selectTwo')
      .getAttribute('value');
    programs.map(async (item, i) => {
      if (itemSelected == item.name) {
        selectedProgram = item.id;
      }
    });

    const exportData = await servicesBalance.exportData(
      state.exampleReducer.currentLang,
      `${y.trim()}-${m.trim()}-${d.trim()}`
    );

    programs.map(async (item) => {
      if (contentPrograms == item.name) {
        if (
          contentSelections == i18n.t('daily_f').toUpperCase() ||
          contentSelections == 'hour'
        ) {
          tabs = await servicesBalance
            .dataTabs(
              state.exampleReducer.currentLang,
              item.indicators.map((item) => item),
              'hour',
              `${y.trim()}-${m.trim()}-${d.trim()}`
            )
            .then((data) => {
              let dataForExport = {
                indicators: data.map((el) => {
                  return {
                    ...el,
                    print_values: el.values,
                  };
                }),
                ...exportData,
              };
              setInfoModal(
                0,
                dataForExport,
                templateShareModal(
                  'scheduled-generation-balance',
                  window.location.search,
                  false,
                  {
                    height: 1985,
                    width: '100%',
                  }
                ),
                'share'
              );
              return data;
            });
          componentTab.render(
            updateTabDay(
              tabs,
              dayFirstTabValue,
              daySecondTabValue,
              daythirdTabValue,
              dayfourthTabValue
            ),
            '#tabwithcontent'
          );
          componentSelects.render(selectMobileSelector, '#mobile__selector');
          const selectedColumn = document
            .querySelector('.mobile__selector_id')
            .getAttribute('value');
          let tableWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
          if (tableWidth <= 540) {
            renderColumnSelected(selectedColumn, selectedAgg);
          }
          let tabTemp = document.querySelector('.mod-tabs ul li a');
          tabTemp.click();
        } else if (
          contentSelections == i18n.t('monthly').toUpperCase() ||
          contentSelections == 'day'
        ) {
          tabs = await servicesBalance
            .dataTabs(
              state.exampleReducer.currentLang,
              item.indicators.map((item) => item),
              'day',
              `${y.trim()}-${m.trim()}-${d.trim()}`
            )
            .then((data) => {
              let dataForExport = {
                indicators: data.map((el) => {
                  return {
                    ...el,
                    print_values: el.values,
                  };
                }),
                ...exportData,
              };
              setInfoModal(
                0,
                dataForExport,
                templateShareModal(
                  'scheduled-generation-balance',
                  window.location.search,
                  false,
                  {
                    height: 1985,
                    width: '100%',
                  }
                ),
                'share'
              );
              return data;
            });
          componentTab.render(
            updateTabMonth(
              tabs,
              monthFirstTabValue,
              monthSecondTabValue,
              monthThirdTabValue,
              getArrayOfFourTabDays([24, 25, 26, 27, 28, 29, 30], 1)
            ),
            '#tabwithcontent'
          );
          componentSelects.render(selectMobileSelector, '#mobile__selector');
          const selectedColumn = document
            .querySelector('.mobile__selector_id')
            .getAttribute('value');
          let tableWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
          if (tableWidth <= 540) {
            renderColumnSelected(selectedColumn, selectedAgg);
          }
          let tabTemp = document.querySelector('.mod-tabs ul li a');
          tabTemp.click();
        } else if (
          contentSelections == i18n.t('yearly').toUpperCase() ||
          contentSelections == 'month'
        ) {
          tabs = await servicesBalance
            .dataTabs(
              state.exampleReducer.currentLang,
              item.indicators.map((item) => item),
              'month',
              `${y.trim()}-${m.trim()}-${d.trim()}`
            )
            .then((data) => {
              let dataForExport = {
                indicators: data.map((el) => {
                  return {
                    ...el,
                    print_values: el.values,
                  };
                }),
                ...exportData,
              };
              setInfoModal(
                0,
                dataForExport,
                templateShareModal(
                  'scheduled-generation-balance',
                  window.location.search,
                  false,
                  {
                    height: 1985,
                    width: '100%',
                  }
                ),
                'share'
              );
              return data;
            });
          componentTab.render(
            updateTabYear(tabs, dataValue, sndTabValue),
            '#tabwithcontent'
          );
          componentSelects.render(selectMobileSelector, '#mobile__selector');
          const selectedColumn = document
            .querySelector('.mobile__selector_id')
            .getAttribute('value');
          let tableWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
          if (tableWidth <= 540) {
            renderColumnSelected(selectedColumn, selectedAgg);
          }
          let tabTemp = document.querySelector('.mod-tabs ul li a');
          tabTemp.click();
        }
      }
    });
  };

  const updateTabYear = (dataElement, dataValue, sndTabValue) => {
    let firstYear = ['january', 'february', 'march', 'april', 'may', 'june'];
    let secondYear = [
      'july',
      'august',
      'september',
      'october',
      'november',
      'december',
    ];
    arrayOfElements = categories.map((el) => el.name);
    arrayOfElements.splice(18, 0, totalCategories[1].alias);
    finalArray = [];
    let tempFinalArr = [];
    selectedProgram = selectedProgram || getUrlParams().program || 'P48';
    let tempIdOrderProgram = balanceObjects.programs.filter(
      (el) => el.name === selectedProgram
    );
    let idOrderArr = tempIdOrderProgram[0].indicators;
    let filterGraph = idOrderArr.filter((el, index) => index < 19);
    const orderedBarData = Utils.orderObjectsArrayByKey(
      dataElement,
      idOrderArr,
      'id'
    );
    finalArray = orderedBarData;
    tempFinalArr = orderedBarData;
    let arrForLoop = Array.from(Array(12).keys());
    let tempArr = tempFinalArr.map((el) => {
      return {
        ...el,
        values: arrForLoop.map(() => {
          return undefined;
        }),
      };
    });

    for (let i = 0; i < finalArray.length; i++) {
      for (let x = 0; x < finalArray[i].values.length; x++) {
        tempArr[i].values.splice(
          parseInt(
            moment(finalArray[i].values[x].datetime)
              .tz('Europe/Madrid')
              .format('MM')
          ) - 1,
          1,
          finalArray[i].values[x]
        );
      }
    }

    finalArray = tempArr;
    calculateTotalGeneration(12);

    miArrayTabYear = [
      {
        text: `${i18n.t('january')} - ${i18n.t('june')}`,
        content: `
        <div>
          <table>
          <tbody>
            <tr class="esios-balance__tabs-row">
              <td class="esios-balance__tabs-row-title">
                <div class="esios-balance__tabs-title">
                      <p>${i18n.t('month')}</p>
                    </div>
                  </td>
                  ${firstYear
                    .map(
                      (year) => `
                    <td>
                    <div class="esios-balance__tabs-column-title">
                        <p>${i18n.t(year)}</p>
                      </div>
                    </td>
                  `
                    )
                    .join('')}
                </tr>
                ${finalArray
                  .map((element, i) => {
                    return `
                  <tr  ${
                    element.short_name.trim().toLowerCase() ===
                      i18n.t('interconnections_balance').toLowerCase() ||
                    element.short_name == 'Demanda Peninsular' ||
                    element.short_name == `${i18n.t('total_generation')}`
                      ? "class='esios-balance__tabs-row tab-border-bottom'"
                      : "class='esios-balance__tabs-row'"
                  }>
                  <td  ${
                    element.short_name.trim().toLowerCase() ===
                      i18n.t('interconnections_balance').toLowerCase() ||
                    element.short_name == 'Demanda Peninsular' ||
                    element.short_name == `${i18n.t('total_generation')}`
                      ? "class='esios-balance__tabs-row-title tab-row-bold'"
                      : "class='esios-balance__tabs-row-title'"
                  }>${
                      element.id === 10196
                        ? i18n.t('programs_adjustment')
                        : element.id == 10186
                        ? i18n.t('interconnections_balance')
                        : element.short_name
                    }</td>
                    ${dataValue
                      .map(
                        (item) =>
                          `<td  ${
                            element.id == 10186 ||
                            element.short_name == 'Demanda Peninsular' ||
                            element.short_name ==
                              `${i18n.t('total_generation')}`
                              ? "class='esios-balance__tabs-row-title tab-row-weigth'"
                              : "class='esios-balance__tabs-row-value'"
                          }>${
                            finalArray[i].values[item - 1]?.value ==
                              undefined ||
                            finalArray[i].values[item - 1]?.value == null ||
                            finalArray[i].values[item - 1]?.value == 0
                              ? '-'
                              : Utils.toNumber(
                                  finalArray[i].values[item - 1].value,
                                  1,
                                  'comma'
                                )
                          }</td>`
                      )
                      .join('')}
                  </tr>`;
                  })
                  .join('')}
              </tbody>
            </table>
          </div>`,
      },
      {
        text: `${i18n.t('july')} - ${i18n.t('december')}`,
        content: `<div>
        <table>
        <tbody>
        <tr class="esios-balance__tabs-row">
          <td class="esios-balance__tabs-row-title">
            <div class="esios-balance__tabs-title">
                <p>${i18n.t('month')}</p>
              </div>
            </td>
            ${secondYear
              .map(
                (year) => `
              <td>
              <div class="esios-balance__tabs-column-title">
                  <p>${i18n.t(year)}</p>
                </div>
              </td>
            `
              )
              .join('')}
          </tr>
          </thead>
          <tbody>
          ${finalArray
            .map((element, i) => {
              return `
            <tr  ${
              element.short_name.trim().toLowerCase() ===
                i18n.t('interconnections_balance').toLowerCase() ||
              element.short_name == 'Demanda Peninsular' ||
              element.short_name == `${i18n.t('total_generation')}`
                ? "class ='esios-balance__tabs-row tab-border-bottom'"
                : "class='esios-balance__tabs-row'"
            }>
                  <td  ${
                    element.short_name.trim().toLowerCase() ===
                      i18n.t('interconnections_balance').toLowerCase() ||
                    element.short_name == 'Demanda Peninsular' ||
                    element.short_name == `${i18n.t('total_generation')}`
                      ? "class='esios-balance__tabs-row-title tab-row-bold'"
                      : "class='esios-balance__tabs-row-title'"
                  }>${
                element.id === 10196
                  ? i18n.t('programs_adjustment')
                  : element.id == 10186
                  ? i18n.t('interconnections_balance')
                  : element.short_name
              }</td>
              ${sndTabValue
                .map(
                  (item) =>
                    `<td  ${
                      element.id == 10186 ||
                      element.short_name == 'Demanda Peninsular' ||
                      element.short_name == `${i18n.t('total_generation')}`
                        ? "class='esios-balance__tabs-row-title tab-row-weigth'"
                        : "class='esios-balance__tabs-row-value'"
                    }>${
                      finalArray[i].values[item - 1]?.value == undefined ||
                      finalArray[i].values[item - 1]?.value == null ||
                      finalArray[i].values[item - 1]?.value == 0
                        ? '-'
                        : Utils.toNumber(
                            finalArray[i].values[item - 1].value,
                            1,
                            'comma'
                          )
                    }</td>`
                )
                .join('')}
            </tr>`;
            })
            .join('')}
          </tbody>
        </table>
      </div>`,
      },
    ];
    return miArrayTabYear;
  };

  let renderGraphAgain = async () => {
    const state = store.getState();
    const time = Date.now();
    const today = new Date(time);
    const dateOfToday = today.toLocaleDateString();
    let setFormatDate = dateOfToday.split('/');
    const [day, month, year] = setFormatDate;
    let selectedProgram = urlParams.get('program') || 'P48';
    selectedAgg = urlParams.get('agg') || 'hour';
    let selectedDate = moment(
      document.getElementById('datepicker').value,
      'DD / MM / YYYY'
    ).format('YYYY-MM-DD');
    document.getElementById('selectOne').setAttribute('value', selectedProgram);
    document.getElementById('selectTwo').setAttribute('value', selectedAgg);

    try {
      let shareData = window.location.search;
      let programSelected = programs.filter((item) => {
        if (item.name === selectedProgram) {
          return item;
        }
      })[0].indicators;

      let graphData = await servicesBalance
        .dataTabs(
          state.exampleReducer.currentLang,
          programSelected,
          selectedAgg,
          selectedDate
        )
        .then((data) => {
          return data;
        });
      let selectedPrograms = programs.filter((item) => {
        if (item.name === selectedProgram) {
          return item;
        }
      })[0];

      // Renderizamos la gráfica
      document.querySelector('#balance-graph svg')?.remove();
      await renderGraph(
        graphData,
        selectedPrograms,
        categories,
        selectedAgg,
        selectedProgram
      );

      let spinnerNone = (id) => {
        document.getElementById(id).style.display = 'none';
        document.querySelector('#tabwithcontent').classList.remove('not-show');
        document
          .querySelector('.esios-balance__container')
          .classList.remove('is-charging');
      };

      spinnerNone('spinner24');
      if (graphData?.every((indicator) => indicator.values.length == 0)) {
        document.querySelector('#balance-graph svg')?.remove();
        document.querySelector('#balance-graph')?.classList.add('noShowWidget');

        document
          .getElementById('balance-graph')
          .setAttribute('style', 'background-color: #f7f7f7 !important');
        document
          .getElementById('balancesNoDataRect')
          .setAttribute('style', 'display: flex; visibility: inherit');
      } else {
        document
          .querySelector('#balance-graph')
          ?.classList.remove('noShowWidget');

        document
          .getElementById('balance-graph')
          .setAttribute('style', 'background-color: white !important');
        document
          .getElementById('balancesNoDataRect')
          .setAttribute('style', 'visibility: hidden');
      }
      let dataForExport = graphData.map((el) => {
        return { ...el, print_values: el.values };
      });

      setInfoModal(
        0,
        {
          indicators: dataForExport,
          name: 'BALANCE DE GENERACIÓN PROGRAMADA',
          slug: 'balance-generacion-programada',
        },
        templateShareModal('scheduled-generation-balance', shareData),
        'share'
      );
    } catch (error) {
      console.error(error);
    } finally {
      Utils.spinnerStatus('spinner24', false);
      document.querySelector(`#char24`).style.opacity = '1';
    }
  };

  const renderColumnSelected = (selected, agg) => {
    let selectedFor25h = selected;
    selected = parseInt(selected);
    let select = selected;
    document.querySelectorAll('.ui-tabs-panel').forEach((e) => {
      e.style.display = 'none';
    });
    if (agg === 'hour') {
      document.querySelector('.selector-title').innerText = 'HORA';

      let { date } = getUrlParams();
      if (!date) {
        date = moment().format('DD-MM-YYYY');
      }
      let numberHours = calculateHoursPerDay(moment(date, 'DD-MM-YYYY'));
      if (numberHours == 23 && selected > 4) {
        select = select + 1;
      }
      if (
        (numberHours == 25 && selectedFor25h == '2b') ||
        (numberHours == 25 && selected > 2)
      ) {
        selected = selected + 1;
      }
      if (select <= 5) {
        document.getElementById('table-0').style.display = 'block';
      } else if (select > 5 && select <= 11) {
        selected = select - 6;
        document.getElementById('table-1').style.display = 'block';
      } else if (select > 11 && select <= 17) {
        selected = select - 12;
        document.getElementById('table-2').style.display = 'block';
      } else if (select > 17 && select <= 23) {
        selected = select - 18;
        document.getElementById('table-3').style.display = 'block';
      }
    }

    document.querySelectorAll('table .column-with-data').forEach((e) => {
      e.style.display = 'none';
    });
    document.querySelectorAll('.coltable-' + selected).forEach((e) => {
      e.style.display = 'initial';
      e.classList.add('val_numbers');
    });
  };

  const getArrayOfFourTabDays = (arr, diff = 0) => {
    let numDaysTemp = moment(
      document.getElementById('datepicker').value,
      'DD / MM / YYYY'
    ).daysInMonth();
    return arr.filter((el) => el <= numDaysTemp - diff && el);
  };

  const updateTabMonth = (
    dataElement,
    monthFirstTabValue,
    monthSecondTabValue,
    monthThirdTabValue,
    monthFourthTabValue
  ) => {
    let firstMonth = [1, 2, 3, 4, 5, 6, 7, 8];
    let secondMonth = [9, 10, 11, 12, 13, 14, 15, 16];
    let thirdMonth = [17, 18, 19, 20, 21, 22, 23, 24];
    let fourthMonth = getArrayOfFourTabDays([25, 26, 27, 28, 29, 30, 31]);

    arrayOfElements = categories.map((el) => el.name);
    arrayOfElements.splice(18, 0, totalCategories[1].alias);
    finalArray = [];
    let tempFinalArr = [];
    selectedProgram = selectedProgram || getUrlParams().program || 'P48';
    let tempIdOrderProgram = balanceObjects.programs.filter(
      (el) => el.name === selectedProgram
    );
    let idOrderArr = tempIdOrderProgram[0].indicators;
    let filterGraph = idOrderArr.filter((el, index) => index < 19);
    const orderedBarData = Utils.orderObjectsArrayByKey(
      dataElement,
      idOrderArr,
      'id'
    );
    finalArray = orderedBarData;
    tempFinalArr = orderedBarData;
    let numDaysTemp = moment(
      document.getElementById('datepicker').value,
      'DD / MM / YYYY'
    ).daysInMonth();
    let arrForLoop = Array.from(Array(numDaysTemp).keys());
    let tempArr = tempFinalArr.map((el) => {
      return {
        ...el,
        values: arrForLoop.map(() => {
          return undefined;
        }),
      };
    });
    for (let i = 0; i < finalArray.length; i++) {
      for (let x = 0; x < finalArray[i].values.length; x++) {
        tempArr[i].values.splice(
          parseInt(
            moment(finalArray[i].values[x].datetime)
              .tz('Europe/Madrid')
              .format('DD')
          ) - 1,
          1,
          finalArray[i].values[x]
        );
      }
    }

    finalArray = tempArr;
    calculateTotalGeneration(numDaysTemp);

    miArrayTabMonth = [
      {
        text: `1-8 D`,
        content: `<div>
            <table>
              <tbody>
                <tr class="esios-balance__tabs-row">
                  <td class="esios-balance__tabs-row-title">
                    <div class="esios-balance__tabs-title">
                      <p>${i18n.t('day')}</p>
                    </div>
                  </td>
                  ${firstMonth
                    .map(
                      (hour) => `
                    <td>
                      <div class="esios-balance__tabs-column-title">
                        <p>${hour}</p>
                      </div>
                    </td>
                  `
                    )
                    .join('')}
                </tr>
              ${finalArray
                .map((element, i) => {
                  return `
                <tr ${
                  element.short_name.trim().toLowerCase() ===
                    i18n.t('interconnections_balance').toLowerCase() ||
                  element.short_name == 'Demanda Peninsular' ||
                  element.short_name == `${i18n.t('total_generation')}`
                    ? "class ='esios-balance__tabs-row tab-border-bottom'"
                    : "class='esios-balance__tabs-row'"
                }>
                  <td ${
                    element.short_name.trim().toLowerCase() ===
                      i18n.t('interconnections_balance').toLowerCase() ||
                    element.short_name == 'Demanda Peninsular' ||
                    element.short_name == `${i18n.t('total_generation')}`
                      ? "class='esios-balance__tabs-row-title tab-row-bold'"
                      : "class='esios-balance__tabs-row-title'"
                  }>${
                    element.id === 10196
                      ? i18n.t('programs_adjustment')
                      : element.id == 10186
                      ? i18n.t('interconnections_balance')
                      : element.short_name
                  }</td>
                  ${monthFirstTabValue
                    .map(
                      (item) =>
                        `<td ${
                          element.id == 10186 ||
                          element.short_name == 'Demanda Peninsular' ||
                          element.short_name == `${i18n.t('total_generation')}`
                            ? "class='esios-balance__tabs-row-title tab-row-weigth'"
                            : "class='esios-balance__tabs-row-value'"
                        }>${
                          finalArray[i].values[item]?.value == undefined ||
                          finalArray[i].values[item]?.value == null ||
                          finalArray[i].values[item]?.value == 0
                            ? '-'
                            : Utils.toNumber(
                                finalArray[i].values[item].value,
                                1,
                                'comma'
                              )
                        }</td>`
                    )
                    .join('')}
                </tr>`;
                })
                .join('')}
              </tbody>
            </table>
          </div>`,
      },
      {
        text: `9-16 D`,
        content: `<div>
        <table>
          <tbody>
          <tr class="esios-balance__tabs-row">
            <td class="esios-balance__tabs-row-title">
              <div class="esios-balance__tabs-title">
                <p>${i18n.t('day')}</p>
              </div>
            </td>
            ${secondMonth
              .map(
                (hour) => `
                    <td>
                      <div class="esios-balance__tabs-column-title">
                        <p>${hour}</p>
                      </div>
                    </td>
            `
              )
              .join('')}
          </tr>
          
          ${finalArray
            .map((element, i) => {
              return `
            <tr ${
              element.short_name.trim().toLowerCase() ===
                i18n.t('interconnections_balance').toLowerCase() ||
              element.short_name == 'Demanda Peninsular' ||
              element.short_name == `${i18n.t('total_generation')}`
                ? "class ='esios-balance__tabs-row tab-border-bottom'"
                : "class='esios-balance__tabs-row'"
            }>
                <td ${
                  element.short_name.trim().toLowerCase() ===
                    i18n.t('interconnections_balance').toLowerCase() ||
                  element.short_name == 'Demanda Peninsular' ||
                  element.short_name == `${i18n.t('total_generation')}`
                    ? "class='esios-balance__tabs-row-title tab-row-bold'"
                    : "class='esios-balance__tabs-row-title'"
                }>${
                element.id === 10196
                  ? i18n.t('programs_adjustment')
                  : element.id == 10186
                  ? i18n.t('interconnections_balance')
                  : element.short_name
              }</td>
              ${monthSecondTabValue
                .map(
                  (item) =>
                    `<td  ${
                      element.id == 10186 ||
                      element.short_name == 'Demanda Peninsular' ||
                      element.short_name == `${i18n.t('total_generation')}`
                        ? "class='esios-balance__tabs-row-title tab-row-weigth'"
                        : "class='esios-balance__tabs-row-value'"
                    }>${
                      finalArray[i].values[item]?.value == undefined ||
                      finalArray[i].values[item]?.value == null ||
                      finalArray[i].values[item]?.value == 0
                        ? '-'
                        : Utils.toNumber(
                            finalArray[i].values[item].value,
                            1,
                            'comma'
                          )
                    }</td>`
                )
                .join('')}
            </tr>`;
            })
            .join('')}
          </tbody>
        </table>
      </div>`,
      },
      {
        text: `17-24 D`,
        content: `<div>
        <table>
          <tbody>
           <tr class="esios-balance__tabs-row">
            <td class="esios-balance__tabs-row-title">
              <div class="esios-balance__tabs-title">
                <p>${i18n.t('day')}</p>
              </div>
            </td>
            ${thirdMonth
              .map(
                (hour) => `
                    <td>
                    <div class="esios-balance__tabs-column-title">
                        <p>${hour}</p>
                      </div>
                    </td>
            `
              )
              .join('')}
          </tr>
          ${finalArray
            .map((element, i) => {
              return `
            <tr ${
              element.short_name.trim().toLowerCase() ===
                i18n.t('interconnections_balance').toLowerCase() ||
              element.short_name == 'Demanda Peninsular' ||
              element.short_name == `${i18n.t('total_generation')}`
                ? "class ='esios-balance__tabs-row tab-border-bottom'"
                : "class='esios-balance__tabs-row'"
            }>
                <td ${
                  element.short_name.trim().toLowerCase() ===
                    i18n.t('interconnections_balance').toLowerCase() ||
                  element.short_name == 'Demanda Peninsular' ||
                  element.short_name == `${i18n.t('total_generation')}`
                    ? "class='esios-balance__tabs-row-title tab-row-bold'"
                    : "class='esios-balance__tabs-row-title'"
                }>${
                element.id === 10196
                  ? i18n.t('programs_adjustment')
                  : element.id == 10186
                  ? i18n.t('interconnections_balance')
                  : element.short_name
              }</td>
              ${monthThirdTabValue
                .map(
                  (item) =>
                    `<td  ${
                      element.id == 10186 ||
                      element.short_name == 'Demanda Peninsular' ||
                      element.short_name == `${i18n.t('total_generation')}`
                        ? "class='esios-balance__tabs-row-title tab-row-weigth'"
                        : "class='esios-balance__tabs-row-value'"
                    }>${
                      finalArray[i].values[item]?.value == undefined ||
                      finalArray[i].values[item]?.value == null ||
                      finalArray[i].values[item]?.value == 0
                        ? '-'
                        : Utils.toNumber(
                            finalArray[i].values[item].value,
                            1,
                            'comma'
                          )
                    }</td>`
                )
                .join('')}
            </tr>`;
            })
            .join('')}
          </tbody>
        </table>
      </div>`,
      },
      {
        text: `25-${numDaysTemp} D`,
        content: `<div>
        <table>
          <tbody>
           <tr class="esios-balance__tabs-row">
            <td class="esios-balance__tabs-row-title">
              <div class="esios-balance__tabs-title">
                <p>${i18n.t('day')}</p>
              </div>
            </td>
            ${fourthMonth
              .map(
                (hour) => `
                    <td>
                    <div class="esios-balance__tabs-column-title">
                        <p>${hour}</p>
                      </div>
                    </td>
            `
              )
              .join('')}
          </tr>
          ${finalArray
            .map((element, i) => {
              return `
            <tr ${
              element.short_name.trim().toLowerCase() ===
                i18n.t('interconnections_balance').toLowerCase() ||
              element.short_name == 'Demanda Peninsular' ||
              element.short_name == `${i18n.t('total_generation')}`
                ? "class ='esios-balance__tabs-row tab-border-bottom'"
                : "class='esios-balance__tabs-row'"
            }>
                <td ${
                  element.short_name.trim().toLowerCase() ===
                    i18n.t('interconnections_balance').toLowerCase() ||
                  element.short_name == 'Demanda Peninsular' ||
                  element.short_name == `${i18n.t('total_generation')}`
                    ? "class='esios-balance__tabs-row-title tab-row-bold'"
                    : "class='esios-balance__tabs-row-title'"
                }>${
                element.id === 10196
                  ? i18n.t('programs_adjustment')
                  : element.id == 10186
                  ? i18n.t('interconnections_balance')
                  : element.short_name
              }</td>
              ${monthFourthTabValue
                .map(
                  (item) =>
                    `<td  ${
                      element.id == 10186 ||
                      element.short_name == 'Demanda Peninsular' ||
                      element.short_name == `${i18n.t('total_generation')}`
                        ? "class='esios-balance__tabs-row-title tab-row-weigth'"
                        : "class='esios-balance__tabs-row-value'"
                    }>${
                      finalArray[i].values[item]?.value == undefined ||
                      finalArray[i].values[item]?.value == null ||
                      finalArray[i].values[item]?.value == 0
                        ? '-'
                        : Utils.toNumber(
                            finalArray[i].values[item].value,
                            1,
                            'comma'
                          )
                    }</td>`
                )
                .join('')}
            </tr>`;
            })
            .join('')}
          </tbody>
        </table>
      </div>`,
      },
    ];
    return miArrayTabMonth;
  };

  const onSelectHandler = async (e) => {
    document.querySelector(`#char24`).style.opacity = '0.5';
    document.getElementById('spinner24').style.cssText = `
    position: absolute;
    top: 50%;
    left: 45%;
    background: white;
    z-index:1;
    opacity: .7;`;
    itemSelected = e.target.innerText;
    itemSelected = itemSelected.trim();

    if (
      itemSelected ==
        i18n.t('daily_f').charAt(0).toUpperCase() +
          i18n.t('daily_f').slice(1) ||
      itemSelected ==
        i18n.t('monthly').charAt(0).toUpperCase() +
          i18n.t('monthly').slice(1) ||
      itemSelected ==
        i18n.t('yearly').charAt(0).toUpperCase() + i18n.t('yearly').slice(1)
    ) {
      selectedAgg =
        selectedAgg === undefined
          ? getUrlParams().agg
          : e.target.getAttribute('value');
      setParameter('agg', selectedAgg);
      if (selectedAgg === 'hour') {
        itemSelected =
          i18n.t('daily_f').charAt(0).toUpperCase() +
          i18n.t('daily_f').slice(1);
      } else if (selectedAgg === 'day') {
        itemSelected =
          i18n.t('monthly').charAt(0).toUpperCase() +
          i18n.t('monthly').slice(1);
      } else if (selectedAgg === 'month') {
        itemSelected =
          i18n.t('yearly').charAt(0).toUpperCase() + i18n.t('yearly').slice(1);
      }
      updateMobileSelectorOpts(selectedAgg);
      onToogleHandler();
      let spanSelectTwo = document.getElementById('selectTwo');
      spanSelectTwo.innerHTML = itemSelected.toUpperCase();
      spanSelectTwo.setAttribute('value', itemSelected.toUpperCase());
      renderTabs();
    }
    renderGraphAgain();
  };

  const onSelectHandlerTemp = async (e) => {
    document.querySelector(`#char24`).style.opacity = '0.5';
    document.getElementById('spinner24').style.cssText = `
    position: absolute;
    top: 50%;
    left: 45%;
    background: white;
    z-index:1;
    opacity: .7;`;
    itemSelected = e.target.innerText;
    itemSelected = itemSelected.trim();

    selectedProgram =
      selectedProgram === undefined ? getUrlParams().program : itemSelected;

    programs.map(async (item, i) => {
      if (selectedProgram == item.name) {
        setParameter('program', item.name);
        itemSelected = item.name;
        selectedProgram = item.name;
        let spanSelectOne = document.getElementById('selectOne');
        spanSelectOne.innerHTML = selectedProgram;
        spanSelectOne.setAttribute('value', selectedProgram);
        renderTabs();
      }
    });

    renderGraphAgain();
  };

  const isMiEsios = () => {
    if (window.location.pathname.includes(i18n.t('routes.myesios'))) {
      return true;
    }
    return false;
  };
  return html`
    ${isMiEsios()
      ? html`<div class="col-xs-12">
          <div class="esios-toolbar">
            <div class="esios-toolbar24"></div>
          </div>
        </div>`
      : html``}
    <div id="spinner24" class="spinner-container">
      <div class="spinner"></div>
    </div>
    <div class="esios-balance">
      <div class="esios-balance__container row">
        <div class="row">
          <div class="esios-balance__wrap col-lg-3 col-md-3 col-xs-3">
            <div class="esios-select">
              <span for="${i18n.t('program')}" class="esios-select__label"
                >${i18n.t('program').toUpperCase()}</span
              >
              <button
                id="select_One"
                class="esios-select__selected"
                @click=${onStateHandler}
              >
                <span
                  class="esios-select__text"
                  value="${itemSelected !== (undefined || null)
                    ? itemSelected.toUpperCase()
                    : programs[0].name}"
                  id="selectOne"
                >
                  ${itemSelected !== undefined || null
                    ? itemSelected.toUpperCase()
                    : programs[0].name}
                </span>
                <span class="esios-select__arrow">
                  <i class="fas fa-sort-down" id=""></i>
                </span>
              </button>
              <ul
                id="list_programs"
                name="${i18n.t('programs')}"
                class="esios-select__selectableHidden"
                @click=${onStateHandler}
              >
                ${programs?.map(
                  (item) => html` <li
                    class="esios-select__value"
                    @click="${onSelectHandlerTemp}"
                  >
                    ${i18n.t(item.name).toUpperCase()}
                  </li>`
                )}
              </ul>
            </div>
            <div class="esios-select">
              <span for="${i18n.t('selection')}" class="esios-select__label"
                >${i18n.t('selection').toUpperCase()}</span
              >
              <button
                id="select_Two"
                class="esios-select__selected"
                @click=${onToogleHandler}
              >
                <span
                  class="esios-select__text"
                  value="${rangeDate[0].alias}"
                  id="selectTwo"
                >
                  ${i18n.t(rangeDate[0].name).toUpperCase()}
                </span>
                <span class="esios-select__arrow">
                  <i class="fas fa-sort-down" id=""></i>
                </span>
              </button>
              <ul
                id="list_selections"
                name="${i18n.t('selection')}"
                class="esios-select__selectableHidden"
              >
                ${rangeDate?.map(
                  (item, i) => html` <li
                    id="changeRange${i + 1}"
                    class="esios-select__value"
                    @click="${onSelectHandler}"
                    value="${item.alias}"
                  >
                    ${i18n.t(item.name).charAt(0).toUpperCase() +
                    i18n.t(item.name).slice(1)}
                  </li>`
                )}
              </ul>
            </div>
            <div class="esios-balance__listEnergyDesktop">
              <div class="esios-listEnergyItems">
                <div class="esios-listEnergyItems__wrap">
                  <ul class="esios-listEnergyItems__list"></ul>
                </div>
              </div>
            </div>
          </div>
          <div class="esios-balance__wrap col-lg-9 col-xs-9">
            <div>
              <div
                id="balancesNoDataRect"
                class="balances-noDataRect"
                style="display:none;"
              >
                ${i18n.t('no_data_dates')}
              </div>
              <div
                class="esios-balance__graph chari-chart no-data-graphic-balances"
                id="balance-graph"
              ></div>
            </div>
          </div>
          <div class="esios-balance__listEnergyMobile">
            <div class="esios-listEnergyItems">
              <div class="esios-listEnergyItems__wrap">
                <ul class="esios-listEnergyItems__list"></ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="medium">
        <div class="selector-title">HORA</div>
        <div class="selector-container">
          <div id="mobile__selector"></div>
        </div>
      </div>

      <div class="row">
        <div id="tabwithcontent"></div>
      </div>
    </div>
  `;
};

export default TemplateEmbedIndicatorsProgramBalance;
