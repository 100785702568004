import { html } from 'lit-html';
import { getId } from '../../../utils/core';
import { getHoursList, getMinutesList } from '../../../utils/dateFormat';
import { sRender } from '../../../utils/render';
import Select from '../selects/select';

const TimeSelector = ({
  hours = getHoursList(),
  minutes = getMinutesList(5),
  value = {},
  onChange = () => {},
  minutesDisabled = false,
} = {}) => {
  const id = getId();
  let showTimeSelector = false;
  value.hour = value.hour || hours[0];
  value.minute = value.minute || minutes[0];
  return html`
    <div class="esios-toolbar-time">
      <div
        class="esios-toolbar-time__mod-date"
        tabindex="0"
        @keypress=${toggleTimeSelector}
        @focusout=${toggleTimeSelector}
        @click="${toggleTimeSelector}"
      >
        <span id="current-hour-${id}"> ${value.hour} : ${value.minute} </span>
      </div>
      <div id="time-select-${id}" class="esios-toolbar-time__select"></div>
    </div>
  `;
  function toggleTimeSelector(e = null) {
    if (
      !e ||
      (e.type == 'keypress' && e.charCode == 13) ||
      e.type == 'click' ||
      (showTimeSelector &&
        e.srcElement.nextElementSibling !=
          e.relatedTarget?.parentElement.parentElement.parentElement
            .parentElement.parentElement)
    ) {
      showTimeSelector = !showTimeSelector;
      const content = showTimeSelector ? SelectTimeContainer : '';
      sRender(content, `time-select-${id}`);
    }
  }
  function SelectTimeContainer() {
    const selectHoursOps = {
      values: hours,
      selected: value.hour,
      onClick: handleTimeChange('hour'),
      onFocusOut: (e) => toggleTimeSelector(),
    };
    const selectMinutesOps = {
      values: minutes,
      disabled: minutesDisabled,
      selected: value.minute,
      onClick: handleTimeChange('minute'),
      onFocusOut: (e) => toggleTimeSelector(),
    };
    return html` <div class="select-time-container">
      <div
        class="hours custom-select"
        style="${hours.length === 0 ? 'display: none;' : ''}"
      >
        ${Select(selectHoursOps)}
      </div>
      <div
        class="minutes custom-select"
        style="${minutes.length === 0 ? 'display: none' : ''}"
      >
        ${Select(selectMinutesOps)}
      </div>
    </div>`;
  }
  function handleTimeChange(type) {
    return (selected) => {
      value[type] = selected.label;
      onChange(value);
      toggleTimeSelector();
      sRender(`${value.hour} : ${value.minute}`, `current-hour-${id}`);
    };
  }
};

export default TimeSelector;
