import i18n from 'i18next';
import { html, render } from 'lit-html';
import { store } from '../../../app.init';
import Select from '../../../shared/components/selects/select';
import { setAnalysis } from '../../../state/actions';
import { getUrlParams, setUrlParam } from '../../../utils/url';
import AnalysisTitle from '../analysis-title';

const AnalysisFormGeoId = ({ indicator }) => {
  let values = [];

  if (indicator.geos.length <= 1) {
    return html``;
  }

  if (indicator) {
    values = indicator.geos.map((i) => ({
      label: i.geo_name,
      ...i,
    }));
    values.sort(sortMe);
  }
  const { geoids = '' } = getUrlParams();
  const geoIdList = geoids.split(',').map((i) => Number(i));
  const selected = values.find((i) => geoIdList.includes(Number(i.geo_id)));
  const placeholder = i18n.t('select_geo');
  const disabled = values.length === 0;
  return html`
    <div class="analysis-geo-id esios-select">
      <h3 class="analysis-geo-id__title">${i18n.t('analyse')}</h3>
      ${Select({
        disabled,
        placeholder,
        values,
        selected,
        deletable: true,
        onDelete: deleteGeoId,
        onClick: handleGeoId,
      })}
    </div>
  `;
  function deleteGeoId() {
    setUrlParam('geoids', '');
    store.dispatch(setAnalysis({ geoids: undefined }));

    let selectedGeo = getUrlParams()?.geoids;
    let geoName = indicator?.geos.find(
      (el) => el.geo_id == selectedGeo
    )?.geo_name;
    geoName = geoName ? geoName : '';
    render(
      AnalysisTitle(`${indicator.name} ${geoName}`),
      document.getElementById('analysis-info-title')
    );
  }
  function handleGeoId(geo) {
    const geoids = geo.geo_id;
    setUrlParam('geoids', geoids);
    store.dispatch(setAnalysis({ geoids }));

    let selectedGeo = getUrlParams()?.geoids;
    let geoName = indicator?.geos.find(
      (el) => el.geo_id == selectedGeo
    )?.geo_name;
    geoName = geoName ? geoName : '';
    render(
      AnalysisTitle(`${indicator.name} ${geoName}`),
      document.getElementById('analysis-info-title')
    );
  }
  function sortMe(a, b) {
    if (a.geo_name < b.geo_name) {
      return -1;
    } else if (b.geo_name < a.geo_name) {
      return 1;
    } else {
      return 0;
    }
  }
};

export default AnalysisFormGeoId;
