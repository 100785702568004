import i18n from 'i18next';
import { html } from 'lit-html';
import { componentLayout } from '../../components/layout/layout.component';
import backImage from '../../assets/images/header-default.jpg';

let templateLogin = (loginFacebook) => {
  return componentLayout.render(
    html`
      <div class="esios-login">
        <div class="row">
          <div class="col-sm-12">
            <h1>${i18n.t('my_esios')}</h1>
            <p>${i18n.t('my_esios_description')}</p>
          </div>
        </div>
        <div id="spinner" class="spinner-container" style="display: none;">
          <div class="spinner"></div>
        </div>
        <div class="row">
          <div class="col-sm-6 offset-sm-3">
            <div class="esios-login__container">
              <h1>${i18n.t('log_in')}</h1>
              <p>${i18n.t('my_esios_login_desc')}</p>
              <div>
                <button
                  class="esios-login__fb-button"
                  @click=${() => FB.login()}
                >
                  ${i18n.t('log_in_with')} Facebook
                </button>
                <button id="google-auth" class="esios-login__google-button">
                  ${i18n.t('log_in_with')} Google
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    `,
    {
      text: '',
      image: backImage,
      showBanner: true,
    }
  );
};

export { templateLogin };
