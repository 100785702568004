import { render } from 'lit-html';
import { store } from '../../app.init.js';
import { componentBread } from '../../shared/components/breadcrumbs/breadcrumbs.component.js';
import { componentLayoutExtended } from '../../shared/components/layoutExtended/layoutExtended.component.js';
import { componentLayoutMain } from '../../shared/components/layoutMain/layoutMain.component.js';
import { componentBanner } from '../banner/banner.component.js';
import { componentSlider } from '../slider/slider.component.js';
import { templateLayout } from './layout.template.js';

let componentLayout = (function () {
  let context = {};
  let handlers = {};
  let renderSuccess = false;

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let renderComponent = async (data) => {
    try {
      // Render the template to the document
      render(
        // Define a template
        templateLayout(data),
        document.querySelector('#app')
      );
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = (children, data, template, subcontentData) => {
    data.select = data.select ? true : false;
    const state = store.getState();
    let pathname = state.exampleReducer.pathname;
    componentSlider.render(data.showSlider);
    componentBread.render(data);
    componentBanner.render(pathname, data);
    componentLayoutExtended.render(template, subcontentData);
    componentLayoutMain.render(children);
    context.name = state.exampleReducer.name;
  };

  let init = (children, data, template, subcontentData) => {
    //Call pre render component

    preRenderComponent();

    // Call render component
    renderComponent(data);

    // Call post render component if it has been rendered
    renderSuccess
      ? postRenderComponent(children, data, template, subcontentData)
      : '';
  };

  return {
    render: (children, data, template, subcontentData) =>
      init(children, data, template, subcontentData),
  };
})();

export { componentLayout };
