import i18n from 'i18next';
import { html } from 'lit-html';
import { templateToolbarTime } from '../../../shared/components/toolbarTime/toolbarTime.template';

const TemplateEmbedIndicatorsSpotEuropaMap = () => {
  return html` ${!window.location.pathname.includes('insertado') &&
    !window.location.pathname.includes('embed')
      ? html` <div class="esios-toolbar esios-intercambios__col">
          <div id="esios-toolbar40" class="esios-toolbar40"></div>
          ${templateToolbarTime('mercade-spot')}
        </div>`
      : ''}
    <div class="esios-intercambios esios-intercambios__row">
      <div
        id="map40"
        style="width: 100%"
        class="esios-analysis__info-chart esios-intercambios__container"
      >
        <div id="spinner40-2" style="display: flex;" class="spinner-container">
          <div class="overlay"></div>
          <div class="spinner"></div>
        </div>
        <div style="display:none" id="rect2" class="esios-pvpc__no-data">
          ${i18n.t('no-data-available')}
        </div>

        <div id="geo-map-container-600"></div>
        <div id="geo-legend" class="mod-analysis-map-legend is-hidden"></div>
      </div>
    </div>`;
};

export default TemplateEmbedIndicatorsSpotEuropaMap;
