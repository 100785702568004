'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';
import backImage from '../../assets/images/header-sujetos-mercado.jpg';
import { componentLayout } from '../../components/layout/layout.component';
import { templateBoxMarket } from '../../shared/components/box-market/box-market.template';

let templateActivation = (
  contactData,
  { objMarket, listMarket },
  handlers,
  select,
  searchForm,
  paginationValues,
  pagination
) => {
  const { title, body } = contactData;
  const {
    onQueryChange,
    onSmChange,
    onTotalChange,
    onPageChange,
    onAmbitChange,
    onAreaChange,
    onDateInitChange,
    onDateEndChange,
    onHide,
    ocultar,
  } = handlers;
  return componentLayout.render(
    html`
      <div class="esios-smercado init-dropdown esios-smercado__dropdown">
        <h1 class="esios-smercado__title">${title || ''}</h1>
        ${html([body]) || ''}
        <div id="print-list"></div>

        <div id="spinner1" class="spinner-container">
          <div class="spinner"></div>
        </div>
        <div id="table" class="tabla--activation"></div>

        <div id="pagination" class="esios-pagination">
          <div class="esios-pagination__results hidden">
            <span class="esios-pagination__txt esios-pagination__arrow">
              ${i18n.t('showing')}
              <select
                class="esios-pagination__select"
                value="${pagination.pagination}"
                @change="${onTotalChange}"
              >
                ${paginationValues.map(
                  (item) => html`<option value="${item}">${item}</option>`
                )}
              </select>
            </span>
            <span class="esios-pagination__txt"
              >${i18n.t('text_results')} ${pagination.total}</span
            >
          </div>
          ${pagination.totalPages > 0
            ? html`<div
                id="pagination-sujetos"
                class="esios-pagination__paginator"
              ></div>`
            : ''}
        </div>

        <div id="sujeto-mercado-buttons" class="esios-smercado__buttons"></div>
      </div>
    `,
    {
      image: backImage,
      showBanner: true,
    },
    html``,
    {
      title: i18n.t('more'),
      subcontent: html`
        <div class="esios-smercado__box-market row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            ${templateBoxMarket(objMarket)}
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            ${templateBoxMarket(listMarket)}
          </div>
        </div>
      `,
    }
  );
};

export { templateActivation };
