'use strict';
import { html } from 'lit-html';
import backImage from '../../assets/images/header-default.jpg';
import { templateLayout } from '../../components/layout/layout.template';
import { store } from '../../app.init';
import { setNameAction } from '../../state/actions';
import i18n from 'i18next';
import { componentLayout } from '../../components/layout/layout.component';

let templateAyuda = (context, handlers, contactData) => {
  const { title, body } = contactData;
  return componentLayout.render(
    html`
      <div class="esios-help ">
        <h1 class="esios-help__title">${title}</h1>
        ${html([body])}
      </div>
    `,
    {
      image: backImage,
      showFooterImage: true,
    }
  );
};

export { templateAyuda };
