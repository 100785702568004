'use strict';
import { html } from 'lit-html';

let templatenoEncontrada = (context, handlers) => {
  return html`
    <div class="esios-error">
      <div class="grid-xs-24 grid-sm-16">
        <h1 class="esios-error__title">LA PAGINA SOLICITADA NO EXISTE</h1>
        <p class="esios-error__text">
          Para más información por favor visite nuestro
          <a href="/es/mapa-del-sitio"> mapa del sitio </a>
          o utilice el recurso de búsqueda.
        </p>
        <p class="esios-error__text">
          También puede contactar con nosotros a través de
          <a
            href="mailto:soportesios@ree.es?subject=Consulta desde la Web Publica"
          >
            soportesios@ree.es
          </a>
        </p>

        <a class="btn" href="es/"> volver a inicio </a>
      </div>
    </div>
  `;
};

export { templatenoEncontrada };
