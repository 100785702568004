'use strict';
import { api } from '../../utils/api-interceptor';
import i18next from 'i18next';

let servicesAnalysisLanding = (() => {
  let init = () => {};

  const getArchives = async (params) => {
    const { ambit, category, action, process, query } = params;

    const urlApi = `indicators?${
      ambit !== '' ? `taxonomy_ids[]=${ambit}&` : ''
    }${category !== '' ? `taxonomy_ids[]=${category}&` : ''}${
      action !== '' ? `taxonomy_ids[]=${action}&` : ''
    }${process !== '' ? `taxonomy_ids[]=${process}&` : ''}locale=${i18next.t(
      'routes.lng'
    )}${query ? `&text=${query}` : ''}`;

    let newIndicators;
    try {
      loading = true;
      const data = await api(urlApi);

      if (!ambit && !query && !category && !process && !action) {
        return {
          newIndicators: [],
        };
      }
      newIndicators = data.indicators
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
        .map((indicator) => {
          return {
            name: indicator.name,
            id: indicator.id,
          };
        });
      loading = false;
      return {
        newIndicators,
      };
    } catch (error) {}
  };

  return {
    init: init,
    getArchives: (params) => getArchives(params),
  };
})();

export { servicesAnalysisLanding };
