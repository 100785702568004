import { html } from 'lit-html';
import { ABORT, store } from '../../../app.init';
import Chart from '../../../shared/components/chart/chart';
import {
  getDecimal,
  getUnit,
} from '../../../shared/components/chart/chart-helpers';
import { api } from '../../../utils/api-interceptor';
import { isEmpty, sanitizeNTilde } from '../../../utils/core';
import { sRender } from '../../../utils/render';
import { getState } from '../../../utils/state';
import { getSplittedRoute, getUrlParams } from '../../../utils/url';
import { exportDataProccess } from '../form/analysis-form-buttons';
import { getTrunc, mapViews } from '../form/analysis-form.service';
import { getCustomTime } from './analysis-compo-chart';
import { AnalysisSummary } from './analysis-summary';
import { setAnalysis } from '../../../state/actions';

const cachedCalls = {};
let isPendingPromise = null;

const ComparativeTemporalChart = (analysis, info) => {
  // This is the way to get the ID
  // const id = getSplittedRoute().pop();
  const id = info.indicator.id;
  const params = getServiceParams(info.indicator, false, analysis);
  const secondTempParams = getServiceParams(info.indicator, true, analysis);
  const idDOM = `analysis-ct-chart-${id}`;
  const chartDOM = document.getElementById(idDOM);
  const vis = analysis.tabSelected.label;
  const cachedUrls = [];
  let magnitudVal = getDecimal(analysis.indicator.magnitud[0].id);
  const options = {
    topEnabled: vis === 'temporal',
    decimalNumbers: magnitudVal,
  };
  let chart;
  if (chartDOM) {
    // we have to apply this kind of solution because of weird chart behaviour
    chartDOM.style.display = 'none';
  }
  let compareIndicators = analysis.indicators || [];
  let indicatorsCalls = [];
  let mainIndicatorCall = cachedCalls[`${id}${params}`];
  let secondaryIndicatorCall = cachedCalls[`${id}${secondTempParams}`];
  if (isPendingPromise !== null) {
    ABORT.abort();
    ABORT.new();
  }
  isPendingPromise = `${id}${secondTempParams}`;
  if (!mainIndicatorCall) {
    mainIndicatorCall = api(`indicators/${id}${params}`, {
      signal: ABORT.signal,
    });
  }
  indicatorsCalls.push(mainIndicatorCall);
  cachedUrls.push(`${id}${params}`);

  if (vis === 'temporal') {
    if (!secondaryIndicatorCall) {
      secondaryIndicatorCall = api(`indicators/${id}${secondTempParams}`, {
        signal: ABORT.signal,
      });
    }
    indicatorsCalls.push(secondaryIndicatorCall);
    cachedUrls.push(`${id}${secondTempParams}`);
  }

  const isBreakdown = compareIndicators[0] === 'breakdown';

  if (vis === 'comparative') {
    if (isBreakdown) {
      compareIndicators.shift();
    }
    for (let cIndicator of compareIndicators) {
      let params = '';
      if (!isEmpty(cIndicator) && cIndicator.id == 686) {
        const regex = /geo_ids\[\]=\d+&?/g;
        params = getServiceParams(cIndicator, false, analysis, '2').replace(
          regex,
          ''
        );
        params = params.replace(/&$/, '');
      } else {
        params = getServiceParams(cIndicator, false, analysis, '2');
      }
      const urlConfig = `${cIndicator.id}${params}`;
      let iCall = cachedCalls[urlConfig];
      if (!iCall) {
        iCall = api(`indicators/${urlConfig}`, { signal: ABORT.signal });
      }
      cachedUrls.push(urlConfig);
      indicatorsCalls.push(iCall);
    }
  }

  Promise.all(indicatorsCalls)
    .then((dataSet) => {
      let indicators = [];
      //sRender(mapViews['geo-id'], `analysis-form-geo-id`, dataSet[0]);

      for (let i = 0; i < dataSet.length; i++) {
        const data = dataSet[i];
        if (data.indicator.geos == '' && i > 0) {
          data.indicator.geos = dataSet[i - 1].indicator.geos;
        }
        if (data !== null) {
          cachedCalls[cachedUrls[i]] = data;
          // In the case of the temporary tab, it's necessary to change the value of the id because it's the same
          data.indicator.excelId = data.indicator.id;
          if (vis === 'temporal' && i === 1) {
            data.indicator.id += 'temp';
          }
          const lines = getAllChartLines({ ...data.indicator });
          indicators.push(lines);
        }
      }
      compareIndicators = indicators;
      if (chartDOM) {
        chartDOM.style.display = 'block';
      }

      const chartValues = [];
      for (let indicator of indicators) {
        chartValues.push(...indicator.values);
      }
      (compareIndicators.length > 1 || !analysis?.compare_indicators) &&
        sRender(
          Chart,
          idDOM,
          chartValues,
          `analysis-${id}`,
          {
            onStep,
            chartEmitter,
            isBreakdown,
            ...options,
          },
          analysis
        );
      exportDataProccess('#analysis-btn', chartValues, analysis);
    })
    .catch((err) => {
      const mute = err;
    });
  return html`
    <h2 class="info-chart-title">${getCustomTime(analysis)}</h2>

    <div class="spinner-container" id="spco">
      <div class="spinner"></div>
    </div>
    <div id="${idDOM}"></div>
    <div id="analysis-ct-summary-${id}"></div>
  `;

  function getAllChartLines(indicator) {
    const values = [...indicator.values];
    if (values.length === 0) {
      // TODO: NEWSIOSWP-890: CHECK THIS getXY function, modify date.
      // Date is returning last day of month, check if its only for groupby: month
      if (indicator.geos.length != 0) {
        const hasGeoName = indicator.geos[0].geo_name;
        const results = {};
        if (!hasGeoName) {
          indicator.values = [getXY(indicator)];
          return indicator;
        }
        const newIndicatorValues = [];
        const indicatorLabel = indicator.name;
        const indicatorId = indicator.id;
        const values = '';
        const name = `${indicatorLabel} ${hasGeoName}`;
        const onlyname = `${indicatorLabel}`;
        const id = `${indicatorId}-${hasGeoName}`;
        const geoLabel = hasGeoName;
        newIndicatorValues.push(
          getXY({ ...indicator, onlyname, name, id, geoLabel })
        );
        indicator.values = newIndicatorValues;
        return indicator;
      } else {
        return { ...indicator, values: [getXY(indicator)] };
      }
    }
    const hasGeoName = indicator.values[0].geo_name;
    const results = {};
    if (!hasGeoName) {
      indicator.values = [getXY(indicator)];
      return indicator;
    } else {
      for (let value of values) {
        const geoName = sanitizeNTilde(value.geo_name);
        results[geoName] = results[geoName] || [];
        results[geoName].push(value);
      }
    }
    const charts = Object.keys(results);
    const newIndicatorValues = [];
    const indicatorLabel = indicator.name;
    const indicatorId = indicator.id;
    for (let chart of charts) {
      const values = results[chart];
      const name = `${indicatorLabel} ${chart}`;
      const onlyname = `${indicatorLabel}`;
      const id = `${indicatorId}-${chart}`;
      const geoLabel = chart;
      newIndicatorValues.push(
        getXY({ ...indicator, onlyname, name, id, values, geoLabel })
      );
    }
    indicator.values = newIndicatorValues;

    return indicator;
  }
  function getXY(indicator) {
    const values = indicator.values;
    const magnitud = indicator.magnitud;
    const unit = getUnit(magnitud[0].id);
    // TODO: VALIDATE STEP FOR GRAPHIC
    // const interpolation = indicator.step_type === 'step' ? 'step' : '';
    const interpolation = indicator.step_type === 'step' ? 'step-after' : '';
    // const interpolation = indicator.step_type === 'step' ? 'step-before' : '';
    // const interpolation = indicator.step_type === 'step' ? 'step-forward' : '';
    // const interpolation = indicator.step_type === 'step' ? 'step-backward' : '';
    return {
      ...indicator,
      interpolation,
      unit,
      values: values.map((v) => ({
        x: new Date(v.datetime),
        y: v.value,
      })),
    };
  }
  function chartEmitter(currentChart) {
    chart = currentChart;
  }
  function onStep(data, date) {
    sRender(
      AnalysisSummary,
      `analysis-ct-summary-${id}`,
      data,
      chart,
      analysis,
      info,
      date
    );
  }
};

export default ComparativeTemporalChart;

export function getServiceParams(
  indicator,
  compare = false,
  analysis,
  val = '1'
) {
  // Getting global common state
  const state = getState();
  const currentLang = state.currentLang;
  analysis = analysis || state.analysis;

  // changed  analysis.groupBy
  const groupBy = analysis.groupby;
  const startDate = analysis.start_date;
  const endDate = analysis.end_date;
  const compareStartDate = analysis.compare_start_date;
  const compareEndDate = analysis.compare_end_date;
  const geo = analysis.geoids;
  const getOffset = (dateOffset) => {
    const [date, hour] = dateOffset.split('T');
    const [d, m, y] = date.split('-');
    let offset = new Date(`${y}-${m}-${d} ${hour}`).getTimezoneOffset() / 60;
    const sign = offset < 0 ? '+' : '-';
    offset = offset < 0 ? offset * -1 : offset;
    offset = offset < 10 ? '0' + offset : offset;

    return [offset, sign];
  };
  const [offsetStart, signStart] = getOffset(startDate);
  const [offsetEnd, signEnd] = getOffset(endDate);
  const sDate = `${getParamsDate(
    compare ? compareStartDate : startDate
  )}${`:00${signStart}${offsetStart}:00`}`;
  const eDate = `${getParamsDate(
    compare ? compareEndDate : endDate
  )}${`:00${signEnd}${offsetEnd}:00`}`;

  const timeAgg = getTimeAgg(indicator, analysis);
  const timeTrunc = getTimeTrunc(groupBy, indicator, analysis);
  let id = getSplittedRoute().pop();
  // Comparing id with indiator Id for geoid
  //const geoIds = id == indicator.id ? getGeoId(geo) : '';
  const geoIds = getGeoId(geo);
  let url = `?start_date=${sDate}&end_date=${eDate}${timeAgg}${geoIds}&geo_agg=sum${timeTrunc}&locale=${currentLang}`;
  url = url.split(':').join('%3A').split('+').join('%2B');
  return url;
}
// ! Check this for 25 and 23 hours
export function getTimeTrunc(groupBy, indicator, analysis = '') {
  if (!analysis) {
    const state = getState();
    analysis = state.analysis;
  }
  const vis = analysis.tabSelected.label;
  let id = getSplittedRoute().pop();
  const timeId = indicator.tiempo[0].id;
  let result = '';
  // ! CHANGED // CHECKtime
  // if (groupBy === getTrunc(timeId) && indicator.id !== parseInt(id)) {
  //   result = `&time_trunc=${groupBy}`;
  // }
  if (
    groupBy !== 'minutes15' &&
    groupBy !== 'minutes10' &&
    groupBy !== 'minutes5'
  ) {
    result = `&time_trunc=${groupBy}`;
  } else {
    result = '';
  }

  if (vis === 'composition') {
    result = '';
  }

  return result;
}

export function getTimeAgg(indicator, analysis) {
  let timeAgg = '';
  let timeIdVal = indicator.tiempo[0].id;
  if (timeIdVal === 154) {
    timeIdVal = 225;
  }
  const magnitude = `${indicator.magnitud[0].id}`;
  const temporal = timeIdVal;
  const params = getUrlParams();
  const geoIds = params.geoids ? params.geoids.split(',').length : 0;
  // const geoIds = indicator.geos ? indicator.geos.length : 0;
  const groupBy = analysis.groupby;
  let isSameTimeAgg = getTrunc(temporal) === groupBy;
  var firstGroup = /23|152|211/; // Precio, Coeficiente, Precio emisión CO2
  var secondGroup = /20|21|22|153|209/; // Potencia, Capacidad, Tension, Numero, tCO2
  var thirdGroup = /13|24/; // Energia, Coste

  const vis = analysis.tabSelected.label;
  if (vis === 'composition') {
    isSameTimeAgg = false;
  }
  if (geoIds === 0 && isSameTimeAgg) {
    // When it doesn't have geoids and is the same time agg
    timeAgg = '';
  } else if (geoIds > 1 && isSameTimeAgg) {
    // When it has more than one geoid and is has the default time agg
    if (firstGroup.test(magnitude)) {
      timeAgg = 'avg';
    } else if (secondGroup.test(magnitude)) {
      timeAgg = 'sum';
    } else if (thirdGroup.test(magnitude)) {
      timeAgg = 'sum';
    }
  } else if (geoIds === 0 && !isSameTimeAgg) {
    // When it doesn't have any geoids and it has a different time agg

    if (firstGroup.test(magnitude)) {
      timeAgg = 'avg';
    } else if (secondGroup.test(magnitude)) {
      timeAgg = 'avg';
    } else if (thirdGroup.test(magnitude)) {
      timeAgg = 'sum';
    }

    // ? Check this, we should change this
    if (
      analysis.indicator.composited &&
      analysis.tabSelected.label !== 'composition' &&
      analysis.tabSelected.label !== 'comparative'
    ) {
      timeAgg = '';
    }
  } else if (geoIds >= 1 && !isSameTimeAgg) {
    // When it has geoids and it has a different time agg
    if (firstGroup.test(magnitude)) {
      timeAgg = 'avg';
    } else if (secondGroup.test(magnitude)) {
      timeAgg = 'avg';
    } else if (thirdGroup.test(magnitude)) {
      timeAgg = 'sum';
    }
  }

  timeAgg = timeAgg !== '' ? `&time_agg=${timeAgg}` : '';

  let idsAVGIncluded = [10300, 10301, 10302, 10303, 10304, 1483];
  if (idsAVGIncluded.includes(indicator.id)) {
    timeAgg = `&time_agg=avg`;
  }
  timeAgg = groupBy.includes('minutes') ? '' : timeAgg;
  return timeAgg;
}

function getGeoId(geo) {
  const state = getState();
  let analysis = state.analysis;

  let result = '';
  if (geo) {
    result = `&geo_ids[]=${geo}`;
  }
  const vis = analysis.tabSelected.label;
  // ? only for composition
  if (vis === 'composition') {
    result = `&geo_ids=`;
    if (getUrlParams().geoids) {
      result = `&geo_ids[]=${getUrlParams().geoids}`;
    }
  }
  if (!geo && vis === 'comparative') {
    result = `&geo_ids=${geo ? geo : ''}`;
  }
  return result;
}

export function getParamsDate(date) {
  const [d, m, y, h] = date.split(/T|-/);
  return `${y}-${m}-${d}T${h}`;
}

export function getProcessedValues(values, analysis) {
  if (values[0].values.length === 0) {
    values = fillEmptyValues([...values], analysis);
  }
  if (analysis.tabSelected.label === 'temporal') {
    let pos = 0;
    let sameQuantity = false;
    if (values[0].values.length === values[1].values.length) {
      sameQuantity = true;
    }

    values = values.map((el, mainIndex) => {
      return mainIndex !== pos
        ? {
            ...el,
            values: el.values.map((el, index) => {
              return {
                ...el,
                x: sameQuantity
                  ? values[pos].values[index]?.x
                  : getParsedDate(
                      new Date(el.x).getTime() +
                        getDiff(
                          analysis.start_date,
                          analysis.compare_start_date
                        )
                    ),
                z: el.x,
              };
            }),
          }
        : el;
    });
    return values;
  } else {
    return values;
  }
}

export const getHigherArr = (arr, key) => {
  const higherArr = arr
    .sort((a, b) => {
      return a[key].length - b[key].length;
    })
    .reverse()[0];
  const higherId = higherArr.id;
  return { higherArr, higherId };
};

const fillEmptyValues = (arr, analysis) => {
  return arr.map((el) => {
    if (el.values.length === 0) {
      return {
        ...el,
        values: arr[1].values.map((val) => {
          return {
            ...val,
            x: getParsedDate(
              new Date(val.x).getTime() +
                getDiff(analysis.start_date, analysis.compare_start_date)
            ),
            y: undefined,
          };
        }),
      };
    } else {
      return el;
    }
  });
};

export function getParsedDate(milliseconds) {
  const days = new Date(milliseconds).getDate();
  const months = new Date(milliseconds).getMonth() + 1;
  const years = new Date(milliseconds).getFullYear();
  const hours = new Date(milliseconds).getHours();
  const minutes = new Date(milliseconds).getMinutes();

  return new Date(
    `${years}-${months < 10 ? `0${months}` : months}-${
      days < 10 ? `0${days}` : days
    }T${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    }:00`
  );
}

const getDiff = (startDate, compareDate) => {
  let startDT = startDate.split('T')[0].split('-');
  let startT = startDate.split('T')[1].split(':');
  let compareDT = compareDate.split('T')[0].split('-');
  let compareT = compareDate.split('T')[1].split(':');
  startDate = `${startDT[2]}-${startDT[1]}-${startDT[0]} ${startT[0]}:${startT[1]}`;
  compareDate = `${compareDT[2]}-${compareDT[1]}-${compareDT[0]} ${compareT[0]}:${compareT[1]}`;
  let diff = new Date(startDate).getTime() - new Date(compareDate).getTime();
  diff = diff < 0 ? diff * -1 : diff;
  return diff;
};
