import { html } from 'lit-html';

const mapArrow = ({ type, value }) => {
  let values = value.split('');
  if (values.includes('.')) {
    value = value.replaceAll('.', '');
  }
  if (values.includes(',')) {
    value = value.replaceAll(',', '.');
  }

  if (type === 'francia') {
    return html` <?xml version="1.0" encoding="UTF-8"?>
      <svg
        width="164px"
        height="108px"
        viewBox="0 0 164 108"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <title>Flecha intercambios por frontera-Francia</title>
        <defs>
          <linearGradient
            x1="0%"
            y1="69.503764%"
            x2="100%"
            y2="30.496236%"
            id="linearGradient-1"
          >
            <stop stop-color="#21A1C4" offset="0%"></stop>
            <stop stop-color="#004D80" offset="52.076049%"></stop>
            <stop stop-color="#1F497D" offset="100%"></stop>
          </linearGradient>
          <linearGradient
            x1="50%"
            y1="37.4141165%"
            x2="50%"
            y2="100%"
            id="linearGradient-2"
          >
            <stop stop-color="#002A46" offset="0%"></stop>
            <stop stop-color="#006088" offset="100%"></stop>
          </linearGradient>
          <linearGradient
            x1="50%"
            y1="22.1017264%"
            x2="50%"
            y2="100%"
            id="linearGradient-3"
          >
            <stop stop-color="#006088" offset="0%"></stop>
            <stop stop-color="#3385AD" offset="100%"></stop>
          </linearGradient>
        </defs>
        <g
          id="🔁-Intercambios-Internacionales"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="✅Físico--todas-las-fronteras---Infografía"
            transform="translate(-978.000000, -445.000000)"
          >
            <g
              id="Flecha-intercambios-por-frontera-Francia"
              transform="translate(978.210910, 445.112298)"
            >
              <g
                id="Group-23"
                transform="translate(11.026082, 8.000000)"
                stroke="url(#linearGradient-1)"
                stroke-linecap="round"
                stroke-width="4"
              >
                <path
                  d="M0,89 C0,89 39.1413037,2 142.500305,0"
                  id="Path-5"
                ></path>
              </g>
              <g
                id="Group-2"
                class="up"
                style="${value > 0 ? 'display: none;' : 'display: flex;'}"
                transform="translate(155.526082, 8.500000) rotate(-1.000000) translate(-155.526082, -8.500000) translate(149.026082, 1.000000)"
              >
                <polygon
                  id="Triangle-Copy-7"
                  fill="url(#linearGradient-2)"
                  transform="translate(4.500000, 7.500000) rotate(90.000000) translate(-4.500000, -7.500000) "
                  points="4.5 3 12 12 -3 12"
                ></polygon>
                <polygon
                  id="Triangle-Copy-6"
                  fill="url(#linearGradient-3)"
                  transform="translate(9.000000, 7.500000) rotate(-270.000000) translate(-9.000000, -7.500000) "
                  points="9 4 15 11 3 11"
                ></polygon>
              </g>
              <g
                id="Group-25-Copy"
                class="down"
                style="${value > 0 ? 'display: flex;' : 'display: none;'}"
                transform="translate(10.500000, 97.497433) rotate(119.000000) translate(-10.500000, -97.497433) translate(4.000000, 89.997433)"
              >
                <polygon
                  id="Triangle-Copy-7"
                  fill="url(#linearGradient-2)"
                  transform="translate(4.500000, 7.500000) rotate(90.000000) translate(-4.500000, -7.500000) "
                  points="4.5 3 12 12 -3 12"
                ></polygon>
                <polygon
                  id="Triangle-Copy-6"
                  fill="url(#linearGradient-3)"
                  transform="translate(9.000000, 7.500000) rotate(-270.000000) translate(-9.000000, -7.500000) "
                  points="9 4 15 11 3 11"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </svg>`;
  } else if (type === 'andorra') {
    return html`<?xml version="1.0" encoding="UTF-8"?>
      <svg
        width="85px"
        height="79px"
        viewBox="0 0 85 79"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <title>Flecha intercambios por frontera-Andorra</title>
        <defs>
          <linearGradient
            x1="22.7975055%"
            y1="100%"
            x2="77.2024945%"
            y2="0%"
            id="linearGradient-1"
          >
            <stop stop-color="#5898BC" offset="0%"></stop>
            <stop stop-color="#004D80" offset="52.076049%"></stop>
            <stop stop-color="#005F8A" offset="100%"></stop>
          </linearGradient>
          <linearGradient
            x1="50%"
            y1="37.4141165%"
            x2="50%"
            y2="100%"
            id="linearGradient-2"
          >
            <stop stop-color="#002A46" offset="0%"></stop>
            <stop stop-color="#006088" offset="100%"></stop>
          </linearGradient>
          <linearGradient
            x1="50%"
            y1="22.1017264%"
            x2="50%"
            y2="100%"
            id="linearGradient-3"
          >
            <stop stop-color="#006088" offset="0%"></stop>
            <stop stop-color="#3385AD" offset="100%"></stop>
          </linearGradient>
        </defs>
        <g
          id="🔁-Intercambios-Internacionales"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="✅Físico--todas-las-fronteras---Infografía"
            transform="translate(-1084.000000, -507.000000)"
          >
            <g
              id="Flecha-intercambios-por-frontera-Andorra"
              transform="translate(1126.738442, 546.385058) rotate(7.000000) translate(-1126.738442, -546.385058) translate(1088.738442, 513.385058)"
            >
              <g
                id="Group-4"
                transform="translate(37.461783, 33.198251) rotate(18.000000) translate(-37.461783, -33.198251) translate(16.461783, 5.698251)"
                stroke="url(#linearGradient-1)"
                stroke-linecap="round"
                stroke-width="4"
              >
                <path
                  d="M0.921905353,54.7596494 C0.921905353,54.7596494 31.264257,40.2755996 41.2333932,0.107221006"
                  id="Path-5"
                ></path>
              </g>
              <g
                id="Group-2"
                class="up"
                style="${value > 0 ? 'display: none;' : 'display: flex;'}"
                transform="translate(66.200295, 12.500000) rotate(-59.000000) translate(-66.200295, -12.500000) translate(59.700295, 5.000000)"
              >
                <polygon
                  id="Triangle-Copy-7"
                  fill="url(#linearGradient-2)"
                  transform="translate(4.500000, 7.500000) rotate(90.000000) translate(-4.500000, -7.500000) "
                  points="4.5 3 12 12 -3 12"
                ></polygon>
                <polygon
                  id="Triangle-Copy-6"
                  fill="url(#linearGradient-3)"
                  transform="translate(9.000000, 7.500000) rotate(-270.000000) translate(-9.000000, -7.500000) "
                  points="9 4 15 11 3 11"
                ></polygon>
              </g>
              <g
                id="Group-2"
                class="down"
                style="${value > 0 ? 'display: flex;' : 'display: none;'}"
                transform="translate(7.500000, 53.020398) rotate(173.000000) translate(-7.500000, -53.020398) translate(1.000000, 45.520398)"
              >
                <polygon
                  id="Triangle-Copy-7"
                  fill="url(#linearGradient-2)"
                  transform="translate(4.500000, 7.500000) rotate(90.000000) translate(-4.500000, -7.500000) "
                  points="4.5 3 12 12 -3 12"
                ></polygon>
                <polygon
                  id="Triangle-Copy-6"
                  fill="url(#linearGradient-3)"
                  transform="translate(9.000000, 7.500000) rotate(-270.000000) translate(-9.000000, -7.500000) "
                  points="9 4 15 11 3 11"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </svg>`;
  } else if (type === 'portugal') {
    return html`<?xml version="1.0" encoding="UTF-8"?>
      <svg
        width="120px"
        height="87px"
        viewBox="0 0 120 87"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <title>Flecha intercambios por frontera-Portugal</title>
        <defs>
          <linearGradient
            x1="20.0220669%"
            y1="100%"
            x2="79.9779331%"
            y2="0%"
            id="linearGradient-1"
          >
            <stop stop-color="#5898BC" offset="0%"></stop>
            <stop stop-color="#004D80" offset="52.076049%"></stop>
            <stop stop-color="#005F8A" offset="100%"></stop>
          </linearGradient>
          <linearGradient
            x1="50%"
            y1="37.4141165%"
            x2="50%"
            y2="100%"
            id="linearGradient-2"
          >
            <stop stop-color="#002A46" offset="0%"></stop>
            <stop stop-color="#006088" offset="100%"></stop>
          </linearGradient>
          <linearGradient
            x1="50%"
            y1="22.1017264%"
            x2="50%"
            y2="100%"
            id="linearGradient-3"
          >
            <stop stop-color="#006088" offset="0%"></stop>
            <stop stop-color="#3385AD" offset="100%"></stop>
          </linearGradient>
        </defs>
        <g
          id="🔁-Intercambios-Internacionales"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="✅Físico--todas-las-fronteras---Infografía"
            transform="translate(-720.000000, -601.000000)"
          >
            <g
              id="Flecha-intercambios-por-frontera-Portugal"
              transform="translate(720.533415, 603.759075)"
            >
              <g
                id="Group-4-Copy"
                transform="translate(58.545994, 41.000000) rotate(-100.000000) translate(-58.545994, -41.000000) translate(24.545994, -2.000000)"
                stroke="url(#linearGradient-1)"
                stroke-linecap="round"
                stroke-width="4"
              >
                <path
                  d="M0.850987433,85.8380744 C0.850987433,85.8380744 36.8409615,77.2716063 57.3299937,31.6009185 C61.362351,22.6126693 64.7943185,12.1872824 67.2333932,0.107221007"
                  id="Path-5"
                ></path>
              </g>
              <g
                id="Group-2"
                class="up"
                style="${value > 0 ? 'display: none;' : 'display: flex;'}"
                transform="translate(7.500000, 15.822648) rotate(-179.000000) translate(-7.500000, -15.822648) translate(1.000000, 8.322648)"
              >
                <polygon
                  id="Triangle-Copy-7"
                  fill="url(#linearGradient-2)"
                  transform="translate(4.500000, 7.500000) rotate(90.000000) translate(-4.500000, -7.500000) "
                  points="4.5 3 12 12 -3 12"
                ></polygon>
                <polygon
                  id="Triangle-Copy-6"
                  fill="url(#linearGradient-3)"
                  transform="translate(9.000000, 7.500000) rotate(-270.000000) translate(-9.000000, -7.500000) "
                  points="9 4 15 11 3 11"
                ></polygon>
              </g>
              <g
                id="Group-2"
                class="down"
                style="${value > 0 ? 'display: flex;' : 'display: none;'}"
                transform="translate(108.627425, 69.975156) rotate(63.000000) translate(-108.627425, -69.975156) translate(102.127425, 62.475156)"
              >
                <polygon
                  id="Triangle-Copy-7"
                  fill="url(#linearGradient-2)"
                  transform="translate(4.500000, 7.500000) rotate(90.000000) translate(-4.500000, -7.500000) "
                  points="4.5 3 12 12 -3 12"
                ></polygon>
                <polygon
                  id="Triangle-Copy-6"
                  fill="url(#linearGradient-3)"
                  transform="translate(9.000000, 7.500000) rotate(-270.000000) translate(-9.000000, -7.500000) "
                  points="9 4 15 11 3 11"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </svg>`;
  } else if (type === 'marruecos') {
    return html`<?xml version="1.0" encoding="UTF-8"?>
      <svg
        width="124px"
        height="143px"
        viewBox="0 0 124 143"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <title>Flecha intercambios por frontera-Marruecos</title>
        <defs>
          <linearGradient
            x1="27.8406455%"
            y1="100%"
            x2="72.1593545%"
            y2="0%"
            id="linearGradient-1"
          >
            <stop stop-color="#5898BC" offset="0%"></stop>
            <stop stop-color="#004D80" offset="52.076049%"></stop>
            <stop stop-color="#005F8A" offset="100%"></stop>
          </linearGradient>
          <linearGradient
            x1="50%"
            y1="37.4141165%"
            x2="50%"
            y2="100%"
            id="linearGradient-2"
          >
            <stop stop-color="#002A46" offset="0%"></stop>
            <stop stop-color="#006088" offset="100%"></stop>
          </linearGradient>
          <linearGradient
            x1="50%"
            y1="22.1017264%"
            x2="50%"
            y2="100%"
            id="linearGradient-3"
          >
            <stop stop-color="#006088" offset="0%"></stop>
            <stop stop-color="#3385AD" offset="100%"></stop>
          </linearGradient>
        </defs>
        <g
          id="🔁-Intercambios-Internacionales"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="✅Físico--todas-las-fronteras---Infografía"
            transform="translate(-710.000000, -723.000000)"
          >
            <g
              id="Flecha-intercambios-por-frontera-Marruecos"
              transform="translate(710.092826, 725.861547)"
            >
              <g
                id="Group-4"
                transform="translate(61.995239, 69.000000) rotate(6.000000) translate(-61.995239, -69.000000) translate(15.495239, 5.000000)"
                stroke="url(#linearGradient-1)"
                stroke-linecap="round"
                stroke-width="4"
              >
                <path
                  d="M6.8023599,123.80728 C6.8023599,123.80728 10.3994308,43.4706435 86.1799559,4.57210579"
                  id="Path-5"
                  transform="translate(46.491158, 64.189693) rotate(-174.000000) translate(-46.491158, -64.189693) "
                ></path>
              </g>
              <g
                id="Group-2"
                class="up"
                style="${value > 0 ? 'display: flex;' : 'display: none;'}"
                transform="translate(113.407174, 19.638453) rotate(-72.000000) translate(-113.407174, -19.638453) translate(106.907174, 12.138453)"
              >
                <polygon
                  id="Triangle-Copy-7"
                  fill="url(#linearGradient-2)"
                  transform="translate(4.500000, 7.500000) rotate(90.000000) translate(-4.500000, -7.500000) "
                  points="4.5 3 12 12 -3 12"
                ></polygon>
                <polygon
                  id="Triangle-Copy-6"
                  fill="url(#linearGradient-3)"
                  transform="translate(9.000000, 7.500000) rotate(-270.000000) translate(-9.000000, -7.500000) "
                  points="9 4 15 11 3 11"
                ></polygon>
              </g>
              <g
                id="Group-2"
                class="down"
                style="${value > 0 ? 'display: none;' : 'display: flex;'}"
                transform="translate(8.500000, 120.099271) rotate(-197.000000) translate(-8.500000, -120.099271) translate(2.000000, 112.599271)"
              >
                <polygon
                  id="Triangle-Copy-7"
                  fill="url(#linearGradient-2)"
                  transform="translate(4.500000, 7.500000) rotate(90.000000) translate(-4.500000, -7.500000) "
                  points="4.5 3 12 12 -3 12"
                ></polygon>
                <polygon
                  id="Triangle-Copy-6"
                  fill="url(#linearGradient-3)"
                  transform="translate(9.000000, 7.500000) rotate(-270.000000) translate(-9.000000, -7.500000) "
                  points="9 4 15 11 3 11"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </svg>`;
  }
};

export default mapArrow;
