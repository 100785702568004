import i18n from 'i18next';
import { html } from 'lit-html';
import moment from 'moment';
import { store } from '../../../app.init';
import cIf from '../../../shared/components/conditional';
import DatePicker from '../../../shared/components/datepicker/datepicker';
import Select from '../../../shared/components/selects/select';
import TimeSelector from '../../../shared/components/time-selector/time-selector';
import { setAnalysis } from '../../../state/actions';
import {
  getMonths,
  getYearsBase,
  getYearsPlus,
  parseDate,
} from '../../../utils/dateFormat';
import { sRender } from '../../../utils/render';
import { getState, getSubscriber } from '../../../utils/state';
import { getUrlParams, setUrlParam } from '../../../utils/url';
import { ANALYSIS_GROUP_ID, checkGroupBy } from './analysis-form.service';

import { api } from '../../../utils/api-interceptor';
import AnalysisFormGroupBy from './analysis-form-group-by';
import AnalysisFormMoveDate from './analysis-form-move-date';

let toggleDates = false;

const AnalysisFormPeriod = ({ indicator }) => {
  let { start_date, end_date, groupby } = getUrlParams();
  let [dStart, mStart, yStart, hStart, mmStart] = start_date.split(/-|T|:|%3A/);
  let [dEnd, mEnd, yEnd, hEnd, mmEnd] = end_date.split(/-|T|:|%3A/);
  const months = getMonths();
  const years = getYearsBase();
  const yearsArrEnd = getYearsPlus();

  let groupBy = ANALYSIS_GROUP_ID[groupby];

  if (!groupBy && indicator) {
    let timeIdVal = indicator.tiempo[0].id;
    if (timeIdVal === 154) {
      timeIdVal = 225;
    }
    groupBy = timeIdVal;
  }

  getSubscriber(handleGroup);

  return html`
    <div class="analysis-period">
      <h3 class="analysis-period__title analysis-period__title--mb">
        ${i18n.t('period')}
      </h3>
      <div
        id="analysis-period-container"
        class="analysis-period-container esios-select esios-date-link"
      >
        ${PeriodContainer()}
      </div>
    </div>
  `;

  function PeriodContainer() {
    const { analysis: state } = getState();
    start_date = state.start_date || start_date;
    end_date = state.end_date || end_date;
    toggleDates = state.toggle || toggleDates;

    [dStart, mStart, yStart, hStart, mmStart] = start_date.split(/-|T|:|%3A/);
    [dEnd, mEnd, yEnd, hEnd, mmEnd] = end_date.split(/-|T|:|%3A/);

    const showCalendar = [
      ANALYSIS_GROUP_ID.minutes5,
      ANALYSIS_GROUP_ID.minutes10,
      ANALYSIS_GROUP_ID.minutes15,
      ANALYSIS_GROUP_ID.hour,
      ANALYSIS_GROUP_ID.day,
    ];
    const showHour = [
      ANALYSIS_GROUP_ID.minutes5,
      ANALYSIS_GROUP_ID.minutes10,
      ANALYSIS_GROUP_ID.minutes15,
      ANALYSIS_GROUP_ID.hour,
    ];
    const showMonth = [ANALYSIS_GROUP_ID.month];
    const showYear = [ANALYSIS_GROUP_ID.month, ANALYSIS_GROUP_ID.year];
    let startVals;

    if (toggleDates) {
      startVals = {
        id: 'start-date',
        isAnalysis: true,
        value: `${dStart} / ${mStart} / ${yStart}`,
        onSelected: handleDate('start_date', start_date),
      };
    } else {
      startVals = {
        id: 'start-date',
        isAnalysis: true,
        value: `${dStart} / ${mStart} / ${yStart}`,
        onSelected: handleDate('start_date', start_date),
        maxDate: new Date(
          moment(end_date.split('T')[0], 'DD-MM-YYYY').format('MM / DD / YYYY')
        ),
      };
    }

    let timeIndicator = state.groupby;
    return html`
      <div
        class=${groupBy === ANALYSIS_GROUP_ID.year
          ? 'esios-date-link__row esios-date-link__row--year'
          : 'esios-date-link__row'}
      >
        <span class="esios-date-link__label">${i18n.t('start')}</span>
        <div
          class=${groupBy === ANALYSIS_GROUP_ID.month
            ? 'esios-date-link__date-wrapper esios-date-link__date-wrapper--month'
            : groupBy === ANALYSIS_GROUP_ID.day
            ? 'esios-date-link__date-wrapper esios-date-link__date-wrapper--day ${groupBy}'
            : 'esios-date-link__date-wrapper ${groupBy}'}
        >
          ${cIf(showCalendar.includes(groupBy), DatePicker, startVals)}
          ${cIf(showMonth.includes(groupBy), Select, {
            values: months,
            selected: months[Number(mStart - 1)],
            onClick: handleMonth('start_date', start_date),
          })}
          <div class="esios-date-link__icon-cal"></div>
        </div>
        <div class="esios-date-link__time-wrapper">
          ${cIf(showHour.includes(groupBy), TimeSelector, {
            value: { hour: hStart, minute: mmStart },
            onChange: handleTime('start_date', start_date),
            minutesDisabled:
              timeIndicator === 'hour' || timeIndicator === 'hora',
          })}
          ${cIf(showYear.includes(groupBy), Select, {
            values: years,
            selected: yStart,
            onClick: handleYear('start_date', start_date),
          })}
        </div>
      </div>
      <button
        class="esios-date-link__btn ${toggleDates
          ? 'esios-date-link__btn--toggle'
          : ''}"
        @click=${handleToogleDate}
      ></button>
      <div
        class=${groupBy === ANALYSIS_GROUP_ID.year
          ? 'esios-date-link__row esios-date-link__row--year'
          : 'esios-date-link__row'}
      >
        <span class="esios-date-link__label">${i18n.t('end')}</span>
        <div
          class=${groupBy === ANALYSIS_GROUP_ID.month
            ? 'esios-date-link__date-wrapper esios-date-link__date-wrapper--month'
            : groupBy === ANALYSIS_GROUP_ID.day
            ? 'esios-date-link__date-wrapper esios-date-link__date-wrapper--day'
            : 'esios-date-link__date-wrapper'}
        >
          ${cIf(showCalendar.includes(groupBy), DatePicker, {
            id: 'end-date',
            isAnalysis: true,
            value: `${dEnd} / ${mEnd} / ${yEnd}`,
            onSelected: handleDate('end_date', end_date),
            minDate: new Date(
              moment(start_date.split('T')[0], 'DD-MM-YYYY').format(
                'MM / DD / YYYY'
              )
            ),
          })}
          ${cIf(showMonth.includes(groupBy), Select, {
            values: months,
            selected: months[Number(mEnd - 1)],
            onClick: handleMonth('end_date', end_date),
          })}
          <div class="esios-date-link__icon-cal"></div>
        </div>
        <div class="esios-date-link__time-wrapper">
          ${cIf(showHour.includes(groupBy), TimeSelector, {
            value: { hour: hEnd, minute: mmEnd },
            onChange: handleTime('end_date', end_date),
            minutesDisabled:
              timeIndicator === 'hour' || timeIndicator === 'hora',
          })}
          ${cIf(showYear.includes(groupBy), Select, {
            values: yearsArrEnd,
            selected: yEnd,
            onClick: handleYear('end_date', end_date),
          })}
        </div>
      </div>
      <div id="analysis-form-move">
        ${AnalysisFormMoveDate(toggleDates, indicator)}
      </div>
    `;
  }

  function handleToogleDate({ currentTarget }) {
    toggleDates = !toggleDates;
    store.dispatch(setAnalysis({ toggle: toggleDates }));
  }

  function handleGroup() {
    const type = getUrlParams().groupby;
    groupBy = ANALYSIS_GROUP_ID[type];
    sRender(PeriodContainer, 'analysis-period-container');
  }

  function handleDate(type, chosenDate) {
    const { analysis: state } = getState();
    let { start_date, end_date, compare_start_date, vis, groupby } =
      getUrlParams();
    let compare_end_date = getCompareDate(
      state.start_date,
      state.end_date,
      compare_start_date
    );
    api(
      `indicators/${state.indicator.id}?values=false&start_date=${
        start_date
          ? start_date
          : moment().tz('Europe/Madrid').format('DD-MM-YYYY')
      }&locale=${i18n.t('routes.lng')}`
    ).then((data) => {
      let indicator = data[0] != undefined ? data[0].indicator : data.indicator;
      let newGeos =
        data[0] != undefined ? data[0].indicator.geos : data.indicator.geos;
      indicator = {
        ...indicator,
        geos: newGeos,
      };

      let timeIdVal;
      if (indicator.tiempo[0].id === 223) {
        timeIdVal = 4;
      } else {
        timeIdVal = indicator.tiempo[0].id;
      }
      if (timeIdVal === 154) {
        timeIdVal = 225;
      }
      // TODO: Check if we can change this function
      let groupBy = checkGroupBy(
        ANALYSIS_GROUP_ID[getUrlParams().groupby],
        timeIdVal
      );

      setUrlParam('groupby', groupBy);
      if (groupby != groupBy) {
        window.location.reload();
      }
      // TODO: This should being optimized, it renders too many times
      if (vis == 1 || vis == 2) {
        sRender(AnalysisFormGroupBy, 'analysis-form-group-by', { indicator });
      }
    });

    // A value object will be displayed everytime we change the date.
    return ({ day, month, year }) => {
      const date = `${day}-${month}-${year}`;
      let newDate = chosenDate.split('T');
      newDate[0] = date;
      newDate = newDate.join('T');
      if (toggleDates) {
        // If toggle is on we have to change all dates with the same difference between the new and older selected date
        let datePreChange = new Date(
          parseDate(getUrlParams()[type]).split('T').join(' ')
        );
        let dateDifference =
          datePreChange - new Date(parseDate(newDate).split('T').join(' '));

        let currentCompareStartDate = new Date(
          parseDate(getUrlParams().compare_start_date).split('T').join(' ')
        );
        let newCompareStartDate = new Date(
          currentCompareStartDate.getTime() - dateDifference
        );
        let momentCompareStartDate = moment(newCompareStartDate)
          .tz('Europe/Madrid')
          .format('DD-MM-YYYYTHH:mm');

        let otherType = '';
        type == 'start_date'
          ? (otherType = 'end_date')
          : (otherType = 'start_date');
        let otherTypeCurrentDate = new Date(
          parseDate(getUrlParams()[otherType]).split('T').join(' ')
        );
        let newOtherTypeDate = new Date(
          otherTypeCurrentDate.getTime() - dateDifference
        );
        let momentOtherTypeDate = moment(newOtherTypeDate)
          .tz('Europe/Madrid')
          .format('DD-MM-YYYYTHH:mm');

        let newState = {
          compare_start_date: momentCompareStartDate,
        };

        newState[type] = newDate;
        newState[otherType] = momentOtherTypeDate;

        setUrlParam(newState);
        store.dispatch(
          setAnalysis({
            ...newState,
            compare_end_date: getCompareDate(
              start_date,
              end_date,
              momentCompareStartDate,
              'end_date'
            ),
          })
        );
      } else {
        setUrlParam(type, newDate);
        if (type === 'start_date') {
          $('#end-date').datepicker(
            'option',
            'minDate',
            new Date(moment(newDate.split('T'), 'DD-MM-YYYY'))
          );
          compare_start_date = getCompareDate(
            newDate,
            end_date,
            compare_end_date,
            type
          );

          setUrlParam('compare_start_date', compare_start_date);
          store.dispatch(
            setAnalysis({
              [type]: newDate,
              compare_start_date,
            })
          );
        }
        if (type === 'end_date') {
          $('#start-date').datepicker(
            'option',
            'maxDate',
            new Date(moment(newDate.split('T'), 'DD-MM-YYYY'))
          );
          const endDate = new Date(moment(newDate.split('T'), 'DD-MM-YYYY'));
          const startDate = new Date(
            moment($('#start-date').val(), 'DD / MM / YYYY')
          );
          const difference = endDate.getTime() - startDate.getTime();
          const compareEnd = new Date(
            new Date(
              moment($('#compare-start-date').val(), 'DD / MM / YYYY')
            ).getTime() + difference
          );
          if ($('#compare-start-date').val()) {
            // ! CHECK IF THIS AFFECT TIME SELECT // SETTING 23:55 by default
            store.dispatch(
              setAnalysis({
                [type]: newDate,
                compare_end_date: moment(compareEnd)
                  .hours('23')
                  .minutes('55')
                  .format('DD-MM-YYYYTHH:mm'),
              })
            );
          } else {
            store.dispatch(
              setAnalysis({
                [type]: newDate,
              })
            );
          }
        }
      }
    };
  }

  function handleTime(type, chosenDate) {
    // Here we can manage the current hour selected
    return (value) => {
      let { analysis } = getState();
      const [date, hours, minutes] = chosenDate.split(/T|:/);
      const newDate = `${date}T${value.hour}:${value.minute}`;
      setUrlParam(type, newDate);

      let tStartDate, tEndDate, tcompareStartDate, tcompareEndDate;
      tcompareStartDate = analysis.compare_start_date;

      if (type == 'start_date') {
        tStartDate = newDate;
        tEndDate = analysis.end_date;
      } else {
        tEndDate = newDate;
        tStartDate = analysis.start_date;
      }

      let startH = moment(tStartDate, 'DD-MM-YYYYTHH:mm').format('HH');
      let startMin = moment(tStartDate, 'DD-MM-YYYYTHH:mm').format('mm');
      tcompareStartDate = moment(tcompareStartDate, 'DD-MM-YYYYTHH:mm')
        .hours(startH)
        .minutes(startMin)
        .format('DD-MM-YYYYTHH:mm');

      // TODO: CALCULATE COMPARESTARTDATE
      tcompareEndDate = getCompareDate(
        tStartDate,
        tEndDate,
        tcompareStartDate,
        'end_date'
      );
      setUrlParam({
        [type]: newDate,
        compare_start_date: tcompareStartDate,
        compare_end_date: tcompareEndDate,
      });
      store.dispatch(
        setAnalysis({
          [type]: newDate,
          compare_start_date: tcompareStartDate,
          compare_end_date: tcompareEndDate,
        })
      );
    };
  }

  function handleMonth(type, chosenDate) {
    const { analysis: state } = getState();
    let compare_end_date = state.compare_end_date;
    let compare_start_date = state.compare_start_date;
    // Here we can manage the current month selected
    return ({ index }) => {
      let newDate = chosenDate.split('-');
      const newMonth = index + 1 < 10 ? '0' + (index + 1) : '' + (index + 1);
      newDate[1] = newMonth;
      newDate = newDate.join('-');
      if (type === 'end_date') {
        let parts = newDate.split(/[-T:]/);
        // Crea un nuevo objeto de fecha
        let date = new Date(parts[2], parts[1] - 1, 1, parts[3], parts[4]);
        // Obtiene el año, mes y día en el formato deseado
        let formattedDate =
          date.getFullYear() +
          '-' +
          ('0' + (date.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + date.getDate()).slice(-2);
        let fecha = new Date(formattedDate);
        // Obtener el año y mes de la fecha proporcionada
        let año = fecha.getFullYear();
        let mes = fecha.getMonth();
        // Crear un nuevo objeto Date para el primer día del siguiente mes
        let primerDiaMesSiguiente = new Date(año, mes + 1, 1);

        // Restar un día al primer día del siguiente mes para obtener el último día del mes actual
        let ultimoDiaMes = new Date(primerDiaMesSiguiente - 1);
        // Obtener el día del mes
        let dia = ultimoDiaMes.getDate();
        newDate = chosenDate.split('-');
        newDate[0] = dia;
        newDate[1] = newMonth;
        newDate = newDate.join('-');
      }

      setUrlParam(type, newDate);

      if (type === 'start_date') {
        compare_start_date = getCompareDate(
          newDate,
          end_date,
          compare_end_date,
          'start_date'
        );
        store.dispatch(
          setAnalysis({
            [type]: newDate,
            compare_start_date,
          })
        );
      }
      if (type === 'end_date') {
        compare_end_date = getCompareDate(
          start_date,
          newDate,
          compare_start_date,
          'end_date'
        );
        store.dispatch(
          setAnalysis({
            [type]: newDate,
            compare_end_date,
          })
        );
      }
    };
  }

  function handleYear(type, chosenDate) {
    const { analysis: state } = getState();
    let compare_end_date = state.compare_end_date;
    let compare_start_date = state.compare_start_date;
    // Here we can manage the current year selected
    return ({ label }) => {
      let [day, month, , time] = chosenDate.split(/T|-/);
      // Convertimos el mes
      const monthNumber = parseInt(month, 10) - 1;
      // Creamos una nueva fecha al comienzo del siguiente mes
      const nextMonthDate = new Date(
        new Date(label).getFullYear(),
        monthNumber + 1,
        1
      );
      // Restamos 1 día a esa fecha para obtener el último día del mes actual
      const lastDayOfMonth = new Date(nextMonthDate - 1);
      // Obtenemos el día del último día del mes
      const lastDay = lastDayOfMonth.getDate();
      // Modificamos la variable day
      if (type === 'end_date') {
        day = lastDay.toString().padStart(2, '0'); // Convertimos a string y añadimos cero si es necesario
      }
      const newDate = `${day}-${month}-${label}T${time}`;
      setUrlParam(type, newDate);
      if (type === 'start_date') {
        compare_start_date = getCompareDate(
          newDate,
          end_date,
          compare_end_date,
          'start_date'
        );
        store.dispatch(
          setAnalysis({
            [type]: newDate,
            compare_start_date,
          })
        );
        setUrlParam('compare_start_date', compare_start_date);
      }
      if (type === 'end_date') {
        compare_end_date = getCompareDate(
          start_date,
          newDate,
          compare_start_date,
          'end_date'
        );
        store.dispatch(
          setAnalysis({
            [type]: newDate,
            compare_end_date,
          })
        );
        setUrlParam('compare_end_date', compare_end_date);
      }
    };
  }
};

export default AnalysisFormPeriod;

export const getCompareDate = (start_date, end_date, compare_date, type) => {
  const dateTemplate = 'DD-MM-YYYYTHH:mm';
  let fixedDiff = moment.duration(
    moment(moment(start_date, dateTemplate).format()).diff(
      moment(end_date, dateTemplate).format()
    )
  );
  fixedDiff = fixedDiff < 0 ? fixedDiff.asHours() * -1 : fixedDiff.asHours();
  let compareDate;
  if (type === 'start_date') {
    compareDate = moment(compare_date, dateTemplate)
      .subtract(fixedDiff, 'hours')
      .format(dateTemplate);
  } else {
    compareDate = moment(compare_date, dateTemplate)
      .add(fixedDiff, 'hours')
      .format(dateTemplate);
  }
  return compareDate;
};

// compare two dates
export function compare(dateTimeA, dateTimeB) {
  var momentA = moment(dateTimeA, 'DD / MM / YYYY');
  var momentB = moment(dateTimeB, 'DD / MM / YYYY');
  if (momentA > momentB) return 1;
  else if (momentA < momentB) return -1;
  else return 0;
}
