import { html } from 'lit-html';
import { sRender } from '../../../utils/render';
import { getState, getSubscriber } from '../../../utils/state';
import ComparativeTemporalChart from './analysis-comp-temp-chart';
import CompositionChart from './analysis-compo-chart';
import GeoChart from './analysis-geo-chart';

const AnalysisChart = (info) => {
  const { analysis } = getState();

  getSubscriber(() => {
    const { analysis } = getState();
    sRender(ChartLogic, 'analysis-chart', analysis, info);
  });

  return html`<div id="analysis-chart">${ChartLogic(analysis, info)}</div> `;
};

export function ChartLogic(analysis = {}, info) {
  if (analysis.tabSelected) {
    // 0 - Comparative, 1 - Temporal, 2 - Composition, 3 - Geo
    const view = analysis.tabSelected.label;
    const components = {
      comparative: ComparativeTemporalChart,
      temporal: ComparativeTemporalChart,
      composition: CompositionChart,
      geographical: GeoChart,
    };

    return html`<section class="esios-analysis__info-chart-section">
      ${components[view](analysis, info)}
    </section>`;
  }

  return html` <div>Loading</div> `;
}
export default AnalysisChart;
