'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';
import backImage from '../../assets/images/header-default.jpg';
import { componentLayout } from '../../components/layout/layout.component';

let templateCodigosConexion = (context, handlers, codigosConexionData) => {
  const { title, body } = codigosConexionData;

  return componentLayout.render(
    html`
      <div class="esios-Redmercado">
        <div class="grid-sm-23 grid-xs-24">
          <h1 class="esios-Redmercado__title">${title}</h1>
          ${html([body])}
        </div>
      </div>
    `,
    {
      image: backImage,
      showBanner: true,
      showFooterImage: true,
    }
  );
};

export { templateCodigosConexion };
