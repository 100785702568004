'use strict';

/**
 * @description Glosario Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { render } from 'lit-html';
import { templateGlosario } from './glosario.template.js';
import { store } from '../../app.init.js';
import { servicesGlosario } from './glosario.services.js';
import { templateTable } from '../table/table.template.js';
import moment from 'moment';
import i18n from 'i18next';
import { componentButtons } from '../../shared/components/buttons/buttons.component.js';
import { Utils } from '../../utils/utils.js';
import { componentPaginator } from '../../shared/components/paginator/paginator.component.js';

let componentGlosario = (function () {
  let seleccionletra = 'A';

  let context = {};
  let handlers = {
    onChangeLetra: (event) => onChangeLetra(event),
    onPageChange: (event) => onPageChange(event),
    onTotalChange: (event) => onTotalChange(event),
    onQueryChange: (event) => onQueryChange(event),
    onSearch: () => onSearch(),
  };

  const onChangeLetra = (event) => {
    pagination.page = 1;
    let [letterUrl, hasLetter] = getLetterUrl();
    seleccionletra = event.target.getAttribute('value');
    if (seleccionletra === letterUrl) {
      return;
    }
    FormData.query = '';
    document.getElementById('searchGlosario').value = '';
    initFilterData();
    const letra = document.querySelectorAll('.selectletra');
    letra.forEach((el) => {
      el.classList.remove('active');
    });
    event.target.classList.add('active');
  };
  let lettersGlosario = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  let renderSuccess = false;

  let searchForm = {
    type: 'publicacion',
    date_init: moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD'),
    date_end: moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD'),
  };
  let filterForm = {
    query: '',
    ambit: '',
    area: '',
  };
  let data = [];
  let dataPaginated = [];

  let paginationValues = [10, 20, 50, 100];
  let pagination = {
    total: 0,
    page: 1,
    totalPages: 1,
    pagination: paginationValues[0],
  };
  // Definir sus columnas
  const columns = [
    {
      title: 'term',
      dataIndex: ['title'],
      defaultSortOrder: 'asc',
      sortDirections: ['asc', 'desc'],
      sorter: (datalist, order) => {
        const sorted = [...datalist].sort((a, b) => {
          if (order === 'asc') {
            if (a['orderSlug'] < b['orderSlug']) {
              return -1;
            } else if (a['orderSlug'] > b['orderSlug']) {
              return 1;
            } else {
              return 0;
            }
          } else if (order === 'desc') {
            if (a['orderSlug'] > b['orderSlug']) {
              return -1;
            } else if (a['orderSlug'] < b['orderSlug']) {
              return 1;
            } else {
              return 0;
            }
          }
        });
        data = sorted;
        pagination.page = 1;
        filterData();
      },
    },
    {
      title: 'definition',
      dataIndex: ['body'],
      defaultSortOrder: 'asc',
      sortDirections: ['asc', 'desc'],
      sorter: (datalist, order) => {
        const sorted = [...datalist].sort((a, b) => {
          if (order === 'asc') {
            if (a['body'] < b['body']) {
              return -1;
            }
            if (a['body'] > b['body']) {
              return 1;
            }
            return 0;
          } else if (order === 'desc') {
            if (a['body'] > b['body']) {
              return -1;
            }
            if (a['body'] < b['body']) {
              return 1;
            }
            return 0;
          }
        });
        data = sorted;
        pagination.page = 1;
        filterData();
      },
    },
  ];
  // Fin Definir columnas

  // paginacion funciones
  const onPageChange = (page) => {
    pagination.page = page;
    pagination.totalPages = Math.ceil(data.length / pagination.pagination);
    filterData();
  };

  const onTotalChange = (event) => {
    pagination.page = 1;
    pagination.pagination = event.target.value;
    pagination.totalPages = Math.ceil(data.length / pagination.pagination);
    filterData();
    orderOnChangeTotal();
  };

  const orderOnChangeTotal = () => {
    let orderTypes = document.querySelectorAll('.esios-table__cell--sort');
    orderTypes.forEach((column) => {
      if (column.classList.contains('asc')) {
        column.click();
        column.click();
      }
      if (column.classList.contains('desc')) {
        column.click();
        column.click();
      }
    });
  };

  // fin paginacion
  // filtros funciones de filtrado abajo

  const onQueryChange = (event) => {
    filterForm.query = event.target.value;

    const search = (length, handler, select) => {
      if (filterForm.query.length == length || filterForm.query.length >= 3) {
        selectLetter(select);
        handler();
      }
    };

    const selectLetter = (select) => {
      const letras = document.querySelectorAll('.selectletra');
      letras.forEach((el) => {
        if (el.textContent == seleccionletra) {
          select ? el.classList.add('active') : el.classList.remove('active');
        }
      });
    };

    search(0, initFilterData, true);
    search(3, filterData, false);
  };

  // fin de funciones de filtrado

  const filterData = () => {
    let letter = seleccionletra;
    if (filterForm.query.length >= 3) letter = '';
    let filtered = data.filter((item) =>
      item['title'].charAt(0).toLowerCase() == seleccionletra.toLowerCase() ||
      letter == ''
        ? true
        : false
    );

    let queryText = (text) => {
      return text.toLowerCase().includes(filterForm.query.toLowerCase());
    };

    let putBold = (text) => {
      let search = filterForm.query;
      if (search != '') {
        let regEx = new RegExp(search, 'ig');
        let textSpliter = text.split(regEx);
        let textToBold = [...text.matchAll(regEx)];
        let finalText = textSpliter.reduce((textWithBold, item, index) => {
          if (index - 1 != textToBold.length) {
            textWithBold += `<b>${textToBold[index - 1][0]}</b>${item}`;
            return textWithBold;
          } else return (textWithBold += item);
        });
        return finalText;
      } else return text;
    };

    let changeText = (title, body, item) => {
      let itemParse = { ...item };
      title ? (itemParse['title'] = putBold(item['title'])) : '';
      body ? (itemParse['body'] = putBold(item['body'])) : '';
      return itemParse;
    };

    filtered = filtered.filter((item) => {
      let bodyResult = queryText(item['body']);
      let titleResult = queryText(item['title']);
      return titleResult ? titleResult : bodyResult;
    });

    filtered = filtered.map((item) => {
      let title = queryText(item['title']);
      let body = queryText(item['body']);
      let itemParse = changeText(title, body, item);
      return itemParse;
    });
    dataPaginated = filtered.slice(
      (pagination.page - 1) * pagination.pagination,
      pagination.page * pagination.pagination
    );

    pagination.total = filtered.length;
    pagination.totalPages = Math.ceil(filtered.length / pagination.pagination);
    renderAll();
    renderTable();
  };

  const getfilteredData = () => {
    let letter = seleccionletra;
    if (filterForm.query.length >= 3) letter = '';
    let filtered = data.filter((item) =>
      item['title'].charAt(0).toLowerCase() == seleccionletra.toLowerCase() ||
      letter == ''
        ? true
        : false
    );

    let queryText = (text) => {
      return text.toLowerCase().includes(filterForm.query.toLowerCase());
    };

    let putBold = (text) => {
      let search = filterForm.query;
      if (search != '') {
        let regEx = new RegExp(search, 'ig');
        let textSpliter = text.split(regEx);
        let textToBold = [...text.matchAll(regEx)];
        let finalText = textSpliter.reduce((textWithBold, item, index) => {
          if (index - 1 != textToBold.length) {
            textWithBold += `<b>${textToBold[index - 1][0]}</b>${item}`;
            return textWithBold;
          } else return (textWithBold += item);
        });
        return finalText;
      } else return text;
    };

    let changeText = (title, body, item) => {
      let itemParse = { ...item };
      title ? (itemParse['title'] = putBold(item['title'])) : '';
      body ? (itemParse['body'] = putBold(item['body'])) : '';
      return itemParse;
    };

    filtered = filtered.filter((item) => {
      let bodyResult = queryText(item['body']);
      let titleResult = queryText(item['title']);
      return titleResult ? titleResult : bodyResult;
    });

    filtered = filtered.map((item) => {
      let title = queryText(item['title']);
      let body = queryText(item['body']);
      let itemParse = changeText(title, body, item);
      return itemParse;
    });
    return filtered;
  };

  // filtrado en inicio
  const initFilterData = () => {
    let filtered = data.filter((item) => {
      let bodyResult = item['body']
        .toLowerCase()
        .includes(filterForm.query.toLowerCase());
      let titleResult = item['title']
        .toLowerCase()
        .includes(filterForm.query.toLowerCase());
      if (titleResult) {
        return titleResult;
      } else if (titleResult && bodyResult) {
        return titleResult, bodyResult;
      } else {
        return bodyResult;
      }
    });
    if (seleccionletra.length > 0) {
      filtered = data.filter(
        (item) =>
          item['title'].charAt(0).toLowerCase() === seleccionletra.toLowerCase()
      );
    }
    filtered.sort((a, b) => {
      if (a['orderSlug'] < b['orderSlug']) {
        return -1;
      } else if (a['orderSlug'] > b['orderSlug']) {
        return 1;
      } else {
        return 0;
      }
    });
    dataPaginated = filtered.slice(
      (pagination.page - 1) * pagination.pagination,
      pagination.page * pagination.pagination
    );
    pagination.total = filtered.length;
    pagination.totalPages = Math.ceil(filtered.length / pagination.pagination);

    renderAll();
    renderTable();
  };

  // filtros de busqueda por fecha, arriba

  // buscar por fecha
  const onSearch = () => {
    searchDataUnidades();
  };

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState(searchForm);
    document.title = `${i18n.t('glossary')} | ${i18n.t('page_subtitle')}`;
    context.name = state.exampleReducer.name;
    $(function () {
      if (Utils.isMobileDevice()) {
        document.querySelector('.esios-layout__breadcrumbs').style.display =
          'none';
      }
    });
  };

  // get archives trae por fecha API
  const searchDataUnidades = () => {
    return servicesGlosario.getDataTable().then((respData) => {
      data = respData;
      document.getElementById('spinner').style.display = 'none';
      pagination.total = respData.length;
      pagination.totalPages = Math.ceil(
        respData.length / pagination.pagination
      );

      filterData();
      return respData;
    });
  };

  const getLetterUrl = () => {
    let url = window.location.href;
    let hasLetter = url.includes('#letter');
    let position = url.indexOf('#letter');
    return [url.substring(position + 7, position + 8), hasLetter];
  };
  let renderComponent = async () => {
    try {
      // Render the template to the document

      // Define a template
      templateGlosario(
        handlers,
        paginationValues,
        pagination,
        filterForm,
        lettersGlosario
      );

      let [letterUrl, hasLetter] = getLetterUrl();
      if (letterUrl != '' && hasLetter) {
        seleccionletra = letterUrl;
      } else {
        seleccionletra = 'A';
      }
      let letters = document.querySelectorAll(
        '.esios-glosario__letterselection .selectletra'
      );
      letters.forEach((letter) => {
        if (letter.innerText == seleccionletra) {
          letter.classList.add('active');
        }
      });
      await searchDataUnidades();
      initFilterData();
      orderOnChangeTotal();

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
    document
      .querySelector('.esios-layout__main')
      .classList.add('esios-layout__main--glossary');
    document
      .querySelector('.esios-footer')
      .classList.add('esios-footer--glossary');

    document.querySelector('#app').classList.add('app--glossary');
  };

  let renderTable = () => {
    const tableElement = document.getElementById('glosario-table');
    render(templateTable(columns, dataPaginated, data), tableElement);
    componentPaginator.render(pagination, onPageChange, 'pagination-glosario');
    let datfiltered = getfilteredData();
    const dataCsv = datfiltered.map((i) => {
      return [i['title'], i['body']];
    });
    dataCsv.unshift(['Término', 'Definición']);
    const fileName =
      'export_' +
      i18n.t('routes.glossary') +
      '_' +
      moment().format('YYYY-MM-DD_HH_mm');
    const buttonsArray = [
      {
        title: 'EXPORTAR JSON',
        action: 'exportJSON',
        data: datfiltered.map((i) => [i.title, i.body]),
        docName: fileName,
      },
      {
        title: 'EXPORTAR CSV',
        action: 'exportCSV',
        data: dataCsv,
        docName: fileName,
      },
      { title: 'IMPRIMIR', action: 'print', url: '#' },
    ];
    componentButtons.render(buttonsArray, '#button-glosario');
  };

  let renderAll = () => {
    templateGlosario(
      handlers,
      paginationValues,
      pagination,
      filterForm,
      lettersGlosario
    );
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentGlosario };
