'use strict';
import i18next from 'i18next';
import moment from 'moment-timezone';
import { api } from '../../utils/api-interceptor';
import { Utils } from '../../utils/utils';
import { fillValues } from '../gestion-demanda/gestionDemanda.service';

let servicesHome = (() => {
  let init = () => {};

  const getDataOtherIndicators = async (id, params) => {
    const { lang, date, globalState, change } = params;
    const dateFormat = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const customDates = [10033, 847];
    let calculatedDateMin = dateFormat;
    let calculatedDateHour = dateFormat;

    let currentHours = moment().tz('Europe/Madrid').format('HH');
    let currentMinutes = moment().tz('Europe/Madrid').format('mm');
    let selectedHours = globalState.split(':')[0];
    let selectedMinutes = globalState.split(':')[1];
    let substractMinutes;

    const today = moment().tz('Europe/Madrid').format('YYYY-MM-DD');
    if (dateFormat === today) {
      let minutes;

      if (globalState) {
        minutes =
          Math.floor(
            moment(globalState, 'HH:mm').tz('Europe/Madrid').format('mm') / 10
          ) * 10;
      }

      if (selectedHours >= currentHours || selectedMinutes >= currentMinutes) {
        substractMinutes = 10;
        minutes =
          Math.floor(moment().tz('Europe/Madrid').format('mm') / 10) * 10;
      }
      calculatedDateMin = moment(date, 'DD/MM/YYYY')
        .tz('Europe/Madrid')
        .hours(
          `${
            globalState
              ? globalState.split(':')[0]
              : moment().tz('Europe/Madrid').format('HH')
          }`
        )
        .minutes(minutes)
        .subtract(substractMinutes, 'minutes')
        .format('YYYY-MM-DDTHH:mm');

      calculatedDateHour = moment()
        .tz('Europe/Madrid')
        .hours(
          `${
            globalState
              ? globalState.split(':')[0]
              : moment().tz('Europe/Madrid').format('HH')
          }`
        )
        .minutes('00')
        .format('YYYY-MM-DDTHH:mm');
    }

    let timezoneOff = moment(`${dateFormat}`, 'YYYY-MM-DD')
      .tz('Europe/Madrid')
      .hours(globalState.split(':')[0])
      .minutes(globalState.split(':')[1])
      .subtract('1', 'seconds')
      .format('Z');
    let preURL = `widgets/${id}?datetime=${
      dateFormat + 'T' + globalState + ':00' + timezoneOff
    }&locale=${lang}`
      .replaceAll(':', '%3A')
      .replaceAll('+', '%2B');

    return api(preURL).then((data) => {
      const slug = data.widget.id;
      const indicators = data.widget.indicators.filter((el) => el.url !== null);
      const description = data.widget.description;
      const name = data.widget.name;
      const magnituds = [];
      const alias = [];
      const indicatorName = [];
      const shortName = [];
      let dateForCurrent = '';
      let urlAnalysis = Utils.getUrlAnalysis(data.widget.main_indicators);

      return Promise.allSettled(
        indicators.map((el) => {
          magnituds.push(el.display_options);
          alias.push(el.alias);
          shortName.push(el.short_name);
          indicatorName.push(el.name);
          if (dateFormat === today) {
            dateForCurrent = customDates.includes(el.id)
              ? `&datetime=${calculatedDateMin}`
              : `&datetime=${calculatedDateHour}`;
          }
          return api(`${el.url.substring(1)}&locale=${lang}`);
        })
      ).then((data) => {
        const indicators = data.map(({ value }, index) => {
          let values;
          if (value.status) {
            return {
              values: [{ x: 0, y: 0 }],
              alias: '',
              display_options: null,
              composited: false,
              magnitud: [{}],
              disaggregated: false,
              short_name: '',
              step: 'linear',
              id: 1293,
              geo_id: undefined,
            };
          } else {
            values = value.indicator.values.map((el) => {
              return {
                x: el.datetime,
                y: el.value,
              };
            });
          }

          return {
            values,
            print_values: value.indicator.values,
            alias: alias[index],
            time: value.indicator.tiempo[0]?.id,
            display_options: magnituds[index],
            composited: value.indicator?.composited,
            magnitud: value.indicator?.magnitud,
            disaggregated: value.indicator?.disaggregated,
            short_name: shortName[index],
            name: indicatorName[index],
            step: value.indicator.step_type,
            id: value.indicator.id,
            geo_id: value.indicator.geos[0]?.geo_id,
          };
        });

        return {
          description,
          name,
          indicators,
          slug,
          urlAnalysis,
        };
      });
    });
  };

  const getDataFromWidget = async (id, params) => {
    const { lang, date, globalState, dateFormat } = params;
    const timeInHours = globalState.pages.home.indicatorsTime;
    const customDates = [10033, 1293];
    let calculatedDateMin;
    let calculatedDateHour;
    let minutes;
    let newDate, newDatetime;

    newDate = date
      ? moment(date, 'DD-MM-YYYY')
          .hours(timeInHours.split(':')[0] ? timeInHours.split(':')[0] : '00')
          .minutes(timeInHours.split(':')[1] ? timeInHours.split(':')[1] : '00')
          .seconds('00')
          .format()
      : '';

    newDatetime = date
      ? moment(date, 'DD-MM-YYYY').tz('Europe/Madrid').format()
      : '';

    minutes = Math.floor(moment().tz('Europe/Madrid').format('mm') / 10) * 10;

    calculatedDateMin = moment(date)
      .tz('Europe/Madrid')
      .minutes(minutes)
      .subtract('10', 'minutes')
      .format('YYYY-MM-DDTHH:mm');

    calculatedDateHour = moment(date)
      .tz('Europe/Madrid')
      .hours(`${moment().tz('Europe/Madrid').format('HH')}`)
      .minutes('00')
      .format('YYYY-MM-DDTHH:mm');

    if (id == '26' || id == '25') newDatetime = true;
    if (
      (id == '6' || id == '7') &&
      window.location.pathname.includes(i18next.t('routes.embed'))
    )
      newDatetime = true;
    if (
      id == '20' &&
      window.location.pathname.includes(i18next.t('routes.embed'))
    )
      newDatetime = true;
    if (
      id == '24' &&
      window.location.pathname.includes(i18next.t('routes.embed'))
    )
      newDatetime = true;

    if (
      id === 544 ||
      id === 541 ||
      id === 542 ||
      id === 543 ||
      id === 1775 ||
      id === 10358 ||
      id === 1776 ||
      id === 10359 ||
      id === 1774 ||
      id === 1742 ||
      id === 460 ||
      id === 461 ||
      id === 603 ||
      id === 620 ||
      id === 10034 ||
      id === 621 ||
      id === 604 ||
      id === 619 ||
      id === 622 ||
      id === 10249
    ) {
      let startdate;
      if (id === 1774 || id === 622) {
        startdate = new Date(dateFormat ? dateFormat : newDatetime);
        startdate.setFullYear(startdate.getFullYear() - 5);
        startdate = startdate.toISOString();
      } else if (id === 461 || id === 621 || id === 620) {
        startdate = new Date(dateFormat ? dateFormat : newDatetime);
        startdate.setMonth(startdate.getMonth() - 12);
        startdate = startdate.toISOString();
      } else if (id === 603 || id === 604 || id === 619) {
        startdate = new Date(dateFormat ? dateFormat : newDatetime);
        startdate.setDate(startdate.getDate() - 7);
        startdate = startdate.toISOString();
      } else {
        startdate = dateFormat ? dateFormat : newDatetime;
      }
      return api(
        `indicators/${id}?${
          newDatetime
            ? `start_date=${startdate}&end_date=${
                dateFormat.replace('T00:00:00', 'T23:59:59')
                  ? dateFormat.replace('T00:00:00', 'T23:59:59')
                  : newDatetime.replace('T00:00:00', 'T23:59:59')
              }&`
            : ``
        }locale=${lang}`
      ).then((data) => {
        const values = data.indicator.values.map((el) => {
          return {
            x: new Date(el.datetime),
            y: el.value,
          };
        });

        const name = data.indicator.name;
        const description = '';
        const slug = data.indicator.short_name;

        const indicators = {
          values: values,
          print_values: data.indicator.values,
          alias: data.indicator.short_name,
          time: data.indicator.tiempo[0].id,
          composited: data.indicator?.composited,
          magnitud: data.indicator.magnitud[0].id,
          disaggregated: data.indicator.disaggregated,
          short_name: data.indicator.short_name,
          name: data.indicator.name,
          step: data.indicator.step_type,
          id: data.indicator.id,
          geo_id: data.indicator.geos[0]?.geo_id,
        };

        return {
          description,
          name,
          indicators,
          slug,
        };
      });
    } else {
      return api(
        `widgets/${id}?${
          newDatetime
            ? `datetime=${dateFormat ? dateFormat : newDatetime}&`
            : ``
        }locale=${lang}`
      ).then((data) => {
        const slug = data.widget.id;
        const indicators = data.widget.indicators.filter(
          (el) => el.url !== null
        );
        const description = data.widget.description;
        const name = data.widget.name;
        const magnituds = [];
        const alias = [];
        const indicatorName = [];
        const shortName = [];
        let dateForCurrent;
        let urlAnalysis = Utils.getUrlAnalysis(data.widget.main_indicators);

        return Promise.allSettled(
          indicators.map((el) => {
            magnituds.push(el.display_options);
            alias.push(el.alias);
            shortName.push(el.short_name);
            indicatorName.push(el.short_name);
            if (parseInt(id) === 26 && !el.url.includes('datetime')) {
              dateForCurrent = customDates.includes(el.id)
                ? `&datetime=${calculatedDateMin}`
                : `&datetime=${calculatedDateHour}`;
            }
            return api(
              `${el.url.substring(1)}&locale=${lang}${
                parseInt(id) == 26 && dateForCurrent ? dateForCurrent : ''
              }`
            );
          })
        ).then((data) => {
          if (id === 22 || id === '22') {
            data = data.map((el) => {
              return {
                ...el,
                value: {
                  indicator: {
                    ...el.value.indicator,
                    values:
                      el.value.indicator.values.length !== 0
                        ? fillValues(el.value.indicator.values)
                        : [],
                  },
                },
              };
            });
          }
          const indicators = data.map(({ value }, index) => {
            let values;
            if (value.status) {
              return {
                values: [{ x: 0, y: 0 }],
                alias: '',
                display_options: null,
                composited: false,
                magnitud: [{}],
                disaggregated: false,
                short_name: '',
                step: 'linear',
                id: 1293,
                geo_id: undefined,
              };
            } else {
              values = value.indicator.values.map((el) => {
                const date = new Date(el.datetime);
                return {
                  x: date,
                  y: el.value,
                };
              });
            }

            return {
              values,
              print_values: value.indicator.values,
              alias: alias[index],
              time: value.indicator.tiempo[0]?.id,
              display_options: magnituds[index],
              composited: value.indicator?.composited,
              magnitud: value.indicator?.magnitud,
              disaggregated: value.indicator?.disaggregated,
              short_name: shortName[index],
              name: indicatorName[index],
              step: value.indicator.step_type,
              id: value.indicator.id,
              geo_id: value.indicator.geos[0]?.geo_id,
            };
          });

          return {
            description,
            name,
            indicators,
            slug,
            urlAnalysis,
          };
        });
      });
    }
  };

  return {
    init: init(),
    getDataFromWidget: (id, e) => getDataFromWidget(id, e),
    getDataOtherIndicators: (id, e) => getDataOtherIndicators(id, e),
  };
})();

export { servicesHome };
