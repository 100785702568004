'use strict';

/**
 * @description Footer Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { render, html } from 'lit-html';
import { Utils } from '../../../utils/utils';
import { templateTable } from './table.template';

let componentTable = (function () {
	let renderSuccess = false;
	const tableId = Utils.generateRandomId();
	let preRenderComponent = () => {};

	let renderComponent = (id, columns, elements) => {

		const columnsElements = html`<tr>
            ${columns.map(i => html`
                <th>${i}</th>
            `)}
            <th>Acciones</th>
        </tr>`
        const elementsLit = html`
            ${elements.map(i => html`<tr>
                ${Object.keys(i).map(key => html`<td>${i[key]}</td>`)}
                <td><a>Ver</a><a class="esios-usuarios__edit">Editar</a><a @click=${() => alert('Borrar')}>Eliminar</a></td>
            </tr>`)}
        `
		try{
			// Render the template to the document
			render(
				// Define a template
				templateTable(columnsElements, elementsLit, tableId), 
				document.querySelector(id)
			)
			renderSuccess = true;
		}catch(error){
			renderSuccess = false;
		}
	
	};

	let postRenderComponent = () => {
		$(tableId).DataTable();
	}

	let init = (id, columns, elements) => {
		
		//Call pre render component
		preRenderComponent();

		// Call render component
		renderComponent(id, columns, elements);

		// Call post render component if it has been rendered
		renderSuccess ? postRenderComponent() : ''
	}
	
	
	return {
		render: (id, columns, elements) => init(id, columns, elements)
	};
})();

export { componentTable };