const geoComposited = (data) => {
  return `with indicator_relation_values as (SELECT v.datetime,
       r.father_id indicator_id,
       v.geo_id,
       CASE WHEN (composite_operation_id = 1) THEN SUM(v.value * r.factor)
            WHEN (composite_operation_id = 2) THEN
                SUM (CASE WHEN (sign(r.factor) = 1)
                     THEN v.value * r.factor END) /
                SUM (CASE WHEN (sign(r.factor) = -1)
                     THEN -greatest(v.value,0) * r.factor END)
            ELSE 0
            END
        AS value
FROM indicator_values v,
     indicator_relations r,
     indicators i
WHERE i.id_indicator=${data.id}
  AND v.indicator_id = r.son_id
  AND r.father_id = i.id_indicator
  AND v.datetime >= to_timestamp('${data.start_date}', 'YYYY-MM-DD HH24:MI:SS')
  AND v.datetime <= to_timestamp('${data.end_date}', 'YYYY-MM-DD HH24:MI:SS')
GROUP BY v.datetime,
         r.father_id,
         v.geo_id,
         i.composite_operation_id

), t as (

  SELECT ${data.agg}(indicator_relation_values.value) AS total,
    indicator_relation_values.geo_id
  FROM indicator_relation_values
  GROUP BY indicator_relation_values.geo_id

), j as (

  SELECT geo_def.cartodb_id,
    geo_def.the_geom_webmercator,
    geo_def.name AS name,
    (SELECT ROUND(${data.geo_agg}(total)::numeric, ${data.decimals}) FROM t WHERE geo_id = cartodb_id limit 1) as total,
    to_timestamp('${data.datetime}', 'YYYY-MM-DD HH24:MI:SS') AS stamp
  FROM geo_def
  WHERE nivel = ${data.nivel}
    AND the_geom_webmercator && !bbox! ${data.where_geoids}

)

SELECT j.*, k.name as local_name FROM j JOIN GEOLOCALIZATION_TRANSLATIONS k ON k.geolocalization_id=j.cartodb_id WHERE j.total IS NOT NULL AND k.locale='${data.lang}'`;
};

export default geoComposited;
