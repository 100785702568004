'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';
import leftArrow from '../../assets/images/left-arrow.svg';
import rightArrow from '../../assets/images/right-arrow.svg';
import { componentLayout } from '../../components/layout/layout.component';
import { templateBoxMarket } from '../../shared/components/box-market/box-market.template';
import { templateLayoutBluebuttons } from '../../shared/components/layoutBluebuttons/layoutBluebuttons.template';
import backImage from '../../assets/images/header-habilitados.jpg';
import { templateBoxMarketColumn } from '../../shared/components/box-market-column/box-market-column.template';

let templatehabilitado = (
  context,
  handlers,
  listDocumen,
  listMarket,
  paginationValues,
  pagination,
  lang
) => {
  const { onTotalChange, onPageChange, onQueryChange, ocultar } = handlers;

  return componentLayout.render(
    html`
      <div class="esios-habilitado ">
        <div class="grid-sm-24">
          <h1 class="esios-habilitado__title">
            ${i18n.t('participants_auction')}
          </h1>
          <p>${i18n.t('list_of_participants_auction')}</p>
          <div id="print-list"></div>
        </div>
      </div>

      <div class="esios-tablaHabilitado">
        <input
          class="esios-tabla__input habilitado-buscar hidden"
          placeholder="${i18n.t('go_search')}"
          type="text"
          @keyup="${onQueryChange}"
        />
        <button
          class="esios-habilitado__boton-remove hidden"
          @click="${ocultar}"
        >
          &nbsp
        </button>
        <div id="spinner1" class="spinner-container">
          <div class="spinner"></div>
        </div>
        <div id="habilitados-table"></div>
      </div>
      <div
        id="pagination"
        class="esios-pagination esios-pagination--habilitado"
      >
        <div class="esios-pagination__results hidden">
          <span class="esios-pagination__txt esios-pagination__arrow">
            ${i18n.t('showing')}
            <select
              class="esios-pagination__select"
              value="${pagination.pagination}"
              @change="${onTotalChange}"
            >
              ${paginationValues.map(
                (item) => html`<option value="${item}">${item}</option>`
              )}
            </select>
          </span>
          <span class="esios-pagination__txt"
            >${i18n.t('text_results')} ${pagination.total}</span
          >
        </div>
        ${pagination.totalPages > 0
          ? html`<div
              id="pagination-habilitados"
              class="esios-pagination__paginator"
            ></div>`
          : ''}
      </div>

      <div
        id="participantes-habilitados-buttons"
        class="esios-smercado__buttons"
      ></div>
    `,
    {
      image: backImage,
      showBanner: true,
    },

    html``,
    {
      title: `${i18n.t('more')}`,
      subcontent: html`
        <div class="esios-documents">
          <div class="grid-xs-12 documents-container-download">
            <div class="mod-documents-download">
              ${templateBoxMarketColumn(listMarket)}
              <div class="mod-buttons">
                ${lang == 'es'
                  ? html` <a
                      class="mod-buttons__btn"
                      href="https://www.ree.es/es/clientes"
                      target="_blank"
                      >${i18n.t('gde_website_text')}</a
                    >`
                  : ''}
              </div>
            </div>
          </div>
        </div>
      `,
    }
  );
};

export { templatehabilitado };
