'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';

let templateModalFav = (handlerFav, handlerCloseModal) => {
  return html`
    <h2 class='esios-pvpc__modal-title'>${i18n.t(
      'remove_from_my_esios'
    )}</h2 class='esios-pvpc__modal-title'>
    <p class='esios-pvpc__modal-text'>${i18n.t(
      'confirm_remove_from_my_esios'
    )}</p>
    <div class="esios-modal__buttons">
      <button class='esios-modal__button esios-modal__button--cancel' @click=${handlerCloseModal}>${i18n.t(
    'cancel'
  )}</button>
      <button class='esios-modal__button esios-modal__button--confirm' @click=${handlerFav}>${i18n.t(
    'apply'
  )}</button>
    </div>
  `;
};

export { templateModalFav };
