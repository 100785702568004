import i18n from 'i18next';
import { html } from 'lit-html';
import Chart from '../../../shared/components/chart/chart';
import { api } from '../../../utils/api-interceptor';
import { sRender } from '../../../utils/render';
import { getState } from '../../../utils/state';
import { getUrlParams } from '../../../utils/url';
import { Utils } from '../../../utils/utils';
import { exportDataProccess } from '../form/analysis-form-buttons';
import { getCompareDate } from '../form/analysis-form-period';
import { getServiceParams } from './analysis-comp-temp-chart';
import { AnalysisCompoSummary, hoverPie } from './analysis-summary';

const cachedCalls = {};

const CompositionChart = (analysis, info) => {
  // const id = getSplittedRoute().pop();
  const id = info.indicator.id;
  const params = getServiceParams(info.indicator, false, analysis);
  const idDOM = 'analysis-comp-chart';
  const idInnerContent = 'analysis-comp-innerContent';
  const idSummary = 'analysis-comp-summary';
  const chartDOM = document.getElementById(idDOM);
  const innerDOM = document.getElementById(idInnerContent);

  let options = {
    typeChart: 'Pie',
    innerArrow: true,
    innerRadius: 0.64,
    hoverFade: 1,
  };
  const cachedUrls = [];

  let chart;
  if (chartDOM) {
    chartDOM.style.display = 'none';
    innerDOM.style.display = 'none';
  }

  const indicatorsCalls = [];
  let mainIndicatorCall = cachedCalls[`${id}${params}`];
  if (!mainIndicatorCall) {
    mainIndicatorCall = api(`compare-groups/${id}?${getURLLocaleLanguage()}`);
  }
  indicatorsCalls.push(mainIndicatorCall);

  const chartValues = [];
  Promise.all(indicatorsCalls)
    .then((dataSet) => {
      return dataSet[0].indicator.desglose;
    })
    .then(async (indicatorsSet) => {
      Promise.all(
        indicatorsSet.map((indicator, i) => {
          // cachedCalls[cachedUrls[i]] = indicator;
          return api(`${indicator.url}${params}`);
        })
      ).then((indi) => {
        indi.map((ind) => {
          const { indicator } = ind;
          chartValues.push({
            ...indicator,
            value: indicator.values.length > 0 ? indicator.values[0].value : 0,
          });
        });
        if (chartDOM) {
          chartDOM.style.display = 'block';
          innerDOM.style.display = 'block';
        }
        sRender(
          Chart,
          idDOM,
          chartValues,
          'main-chart',
          {
            onStep,
            chartEmitter,
            ...options,
          },
          analysis
        );
        let sumArr = [];
        chartValues.map((el) => {
          let counter = 0;
          el.values.forEach((element) => {
            counter += element.value;
          });

          sumArr.push(counter);
          return counter;
        });
        let finSum = 0;
        sumArr.map((el) => {
          finSum += el;
        });

        if (finSum === 0) {
          let noData = `<div class="widget-nodata">
            <div>${i18n.t('no_data')}</div>
          </div> `;
          document.querySelector('.pieComposition').innerHTML = noData;
          document
            .querySelector('.pieComposition')
            .classList.add('pieComposition--noData');
        }
        exportDataProccess('#analysis-btn', chartValues, analysis);
      });
    });

  return html`
    <h2 class="info-chart-title">${getCustomTime(analysis)}</h2>
    <div id="spinner-comp" class="spinner-container">
      <div class="spinner"></div>
    </div>
    <div class="row">
      <div class="col-md-6 pieComposition">
        <div id="analysis-comp-chart" class=""></div>
        <div id="analysis-comp-innerContent" class="innerContent">
          <div class="chartLegend">
            <h3 id="comp-innerContent-title" class="titlePie"></h3>
            <div id="comp-innerContent-value" class="valuePie"></div>
          </div>
        </div>
      </div>
      <div id="analysis-comp-summary" class="col-md-6"></div>
    </div>
  `;

  function getURLLocaleLanguage() {
    const { currentLang } = getState();
    return `locale=${currentLang}`;
  }

  function chartEmitter(currentChart) {
    chart = currentChart;
  }
  function onStep(data) {
    AnalysisCompoSummary(idSummary, data, chart, idInnerContent);
    hoverPie(chart);
  }
};

export default CompositionChart;

export function getCustomTime(analysis) {
  let { groupby = analysis.groupby } = getUrlParams();
  let {
    start_date,
    end_date,
    compare_start_date,
    compare_end_date,
    tabSelected,
  } = analysis;

  compare_end_date = getCompareDate(start_date, end_date, compare_start_date);

  const startDate = start_date.split('T')[0];
  const endDate = end_date.split('T')[0];
  const startTime = start_date.split('T')[1];
  const endTime = end_date.split('T')[1];
  return `
  ${
    tabSelected.label === 'composition'
      ? `${i18n.t('sum_from')}`
      : `${i18n.t('from_the')}`
  }  
  ${startDate} ${i18n.t('at')} ${startTime} ${i18n.t(
    'to_the'
  )} ${endDate} ${i18n.t('at')} ${endTime}
  ${
    tabSelected.label === 'temporal'
      ? `${i18n.t('and_short')} ${i18n.t('from_the')} ${
          compare_start_date.split('T')[0]
        } ${i18n.t('at')} ${start_date.split('T')[1]} ${i18n.t('to_the')} ${
          compare_end_date.split('T')[0]
        } ${i18n.t('at')} ${end_date.split('T')[1]} ${i18n.t(
          'groupby'
        )} ${i18n.t(groupby)}`
      : ''
  }
    ${
      tabSelected.label === 'comparative'
        ? `${i18n.t('groupby')} ${i18n.t(groupby)}`
        : ''
    }`;
}
