'use strict';

/**
 * @description Select Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { render } from 'lit-html';
import { store } from '../../../app.init';
import { templateSelects } from './selects.template';

let componentSelects = (function () {
  let context = {};
  let handlers = {
    openTimeSelector: (event) => openTimeSelector(event),
  };
  let ID = '';
  let changeDatetime;
  let renderSuccess = false;

  const openTimeSelector = (e) => {
    ID = e.target.parentElement.parentElement.id;
    const select = document.querySelector(`#${ID} .select-time-container`);
    let selectValue = document.querySelector(`#${ID} .option-selected`);
    let selectBtns = document.getElementsByClassName('cu-select');
    for (
      let selectCounter = 0;
      selectCounter < selectBtns.length;
      selectCounter++
    ) {
      const element = selectBtns[selectCounter];
      element.parentNode.classList.remove('active');
    }

    window.onclick = (e) => {
      if (
        selectValue.getAttribute('data-disabled') !== 'true' ||
        !selectValue
      ) {
        if (e.target.matches('.option-selected')) {
          if (e.target.parentElement.classList.value.includes('active')) {
            e.target.parentElement.classList.remove('active');
          } else {
            e.target.parentElement.classList.add('active');
          }
        }
        if (
          !e.target.matches('.esios-toolbar-time__mod-date') &&
          !e.target.matches('.option-selected')
        ) {
          if (select.classList.contains('active')) {
            select.classList.remove('active');
          }

          if (e.target.matches(`#${ID} .option`)) {
            if (e.target.getAttribute('data-disabled') === 'true') {
              return;
            }

            let newValue = selectValue;

            newValue = e.target.getAttribute('value');
            changeDatetime({
              newValue,
              id: ID,
            });
          }
        }
      }
    };
  };

  let preRenderComponent = (selectorData, documentElement) => {
    // Asi accedemos a la propiedad del store
    ID = selectorData.id;
    changeDatetime = selectorData.changeTime;
  };

  let renderComponent = (selectorData, documentElement) => {
    ID = selectorData.id;
    try {
      render(
        //  Define a template
        templateSelects(handlers, selectorData),
        document.querySelector(documentElement)
      );

      if (selectorData.onlySelect === true) {
        let selectorContainer = document.querySelector(`#${ID}`).parentElement;
        let prevBtn = document.querySelector(`#${ID}`).parentElement
          .children[0];
        let mainSelect = document.querySelector(`#${ID}`).parentElement
          .children[1];
        let nextBtn = document.querySelector(`#${ID}`).parentElement
          .children[2];
        selectorContainer.style.gridTemplateColumns = '1fr';
        prevBtn.style.display = 'none';
        nextBtn.style.display = 'none';
        mainSelect.style.gridColumns = '1/3';
      }
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = (selectorData, documentElement) => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
    ID = selectorData.id;
  };

  let init = (selectorData, documentElement) => {
    //Call pre render component
    preRenderComponent(selectorData, documentElement);

    // Call render component
    renderComponent(selectorData, documentElement);

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent(selectorData, documentElement) : '';
  };

  return {
    render: (selectorData, documentElement) =>
      init(selectorData, documentElement),
  };
})();

export { componentSelects };
