import i18n from 'i18next';
import { html, render } from 'lit-html';
import moment from 'moment';
import { store } from '../../app.init';
import { datepickerComponent } from '../../shared/components/datepicker/datepicker.component';
import { componentTimeSelector } from '../../shared/components/time-selector/time-selector.component';
import { templateToolbarTime } from '../../shared/components/toolbarTime/toolbarTime.template';
import { charts } from '../../utils/options-charts';
import { getUrlParams } from '../../utils/url';
import { Utils } from '../../utils/utils';
import { servicesHome } from '../home/home.services';
import TemplateEmbedIndicatorsTotalBalanceProgrammedInterconection from './embed-indicators-complex-graphs/embed-indicators-balance-programmed-interconection.template';
import TemplateEmbedIndicatorsBalance from './embed-indicators-complex-graphs/embed-indicators-balance.template';
import TemplateEmbedIndicatorsBilling from './embed-indicators-complex-graphs/embed-indicators-billing.template';
import TemplateEmbedIndicatorsErni from './embed-indicators-complex-graphs/embed-indicators-erni.template';
import TemplateEmbedIndicatorsDemanda from './embed-indicators-complex-graphs/embed-indicators-demanda.template';
import TemplateEmbedIndicatorsPrevisionEolicaPen from './embed-indicators-complex-graphs/embed-indicators-prevision-eolica-pen.template';
import TemplateEmbedIndicatorsDailyForecastD1Demand from './embed-indicators-complex-graphs/embed-indicators-daily-forecast-d1-demand.template';
import TemplateEmbedIndicatorsDailyForecastD1WindPlusPhotovoltaic from './embed-indicators-complex-graphs/embed-indicators-daily-forecast-d1-wind-plus-photovoltaic.template';
import TemplateEmbedIndicatorsIntradayForecastH3Demand from './embed-indicators-complex-graphs/embed-indicators-intraday-forecast-h3-demand.template';
import TemplateEmbedIndicatorsIntradayForecastH3MorePhotovoltaicDemand from './embed-indicators-complex-graphs/embed-indicators-intraday-forecast-h3-more-photovoltaic-demand.template';
import TemplateEmbedIndicatorsAnnualDemandForecast from './embed-indicators-complex-graphs/embed-indicators-annual-demand-forecast.template';
import TemplateEmbedIndicatorsSNPForecastDemand from './embed-indicators-complex-graphs/embed-indicators-snp-forecast-demand.template';
import TemplateEmbedIndicatorsDailyForecastPeninsularElectricityDemand from './embed-indicators-complex-graphs/embed-indicators-daily-forecast-peninsular-electricity-demand.template';
import TemplateEmbedIndicatorsMonthlyForecastPeninsularElectricityDemand from './embed-indicators-complex-graphs/embed-indicators-monthly-forecast-peninsular-electricity-demand.template';
import TemplateEmbedIndicatorsWeeklyForecastPeninsularElectricityDemand from './embed-indicators-complex-graphs/embed-indicators-weekly-forecast-peninsular-electricity-demand.template';
import TemplateEmbedIndicatorsMaxAnnualForecastPeninsularElectricityDemand from './embed-indicators-complex-graphs/embed-indicators-maximum-annual-forecast-peninsular-electricity-demand.template';
import TemplateEmbedIndicatorsSolarPlannedGeneration from './embed-indicators-complex-graphs/embed-indicators-solar-planned-generation.template';
import TemplateEmbedIndicatorsAvalibleGenerationMarginMaximumForecast from './embed-indicators-complex-graphs/embed-indicators-avalible-generation-margin-maximum-forecast.template';
import TemplateEmbedIndicatorsMinAnnualForecastPeninsularElectricityDemand from './embed-indicators-complex-graphs/embed-indicators-minimum-annual-forecast-peninsular-electricity-demand.template';
import TemplateEmbedIndicatorsMaxMonthlyForecastPeninsularElectricityDemand from './embed-indicators-complex-graphs/embed-indicators-maximum-monthly-forecast-peninsular-electricity-demand.template';
import TemplateEmbedIndicatorsPrevisonDemandaResidualWiew from './embed-indicators-complex-graphs/embed-indicators-prevision-de-demanda-residual.template';
import TemplateEmbedIndicatorsMinMonthlyForecastPeninsularElectricityDemand from './embed-indicators-complex-graphs/embed-indicators-minimum-monthly-forecast-peninsular-electricity-demand.template';
import TemplateEmbedIndicatorsBreakdown from './embed-indicators-complex-graphs/embed-indicators-breakdown.template';
import TemplateEmbedIndicatorsEnergy from './embed-indicators-complex-graphs/embed-indicators-energy.template';
import TemplateEmbedIndicatorsHourlyBalances from './embed-indicators-complex-graphs/embed-indicators-hourly-balances-border.template';
import TemplateEmbedIndicatorsInbalances from './embed-indicators-complex-graphs/embed-indicators-inbalances.template';
import TemplateEmbedIndicatorsInterrumpibility from './embed-indicators-complex-graphs/embed-indicators-interrumpibility.template';
import TemplateEmbedIndicatorsProgramBalance from './embed-indicators-complex-graphs/embed-indicators-program-balance.template';
import TemplateEmbedIndicatorsBProgrammedInterconection from './embed-indicators-complex-graphs/embed-indicators-programmed-interconection.template';
import TemplateEmbedIndicatorsSpotEuropa from './embed-indicators-complex-graphs/embed-indicators-spot-europa.template';
import TemplateEmbedIndicatorsSpotEuropaMap from './embed-indicators-complex-graphs/embed-indicators-spot-europa.template-map';
import TemplateEmbedIndicatorsVolume from './embed-indicators-complex-graphs/embed-indicators-volume.template';
import TemplateEmbedIndicatorsTermica from './embed-indicators-complex-graphs/embed-indicators-termica.template';
import TemplateEmbedIndicatorsFotovoltaica from './embed-indicators-complex-graphs/embed-indicators-fotovoltaica.template';

const TemplateEmbedIndicatorsGraph = (widgetId, title, dateTime) => {
  const state = store.getState();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const dateUrl =
    urlParams.get('date') || moment(new Date()).format('DD-MM-YYYY');

  const changeSelectValue = (value) => {
    const { id, newValue } = value;
    let hours = newValue.split(':')[0];
    let minutes = newValue.split(':')[1];
    if (document.querySelector(`#char${widgetId}`)) {
      document.querySelector(`#char${widgetId}`).style.opacity = '.5';
    }

    if (document.getElementById(`spinner${widgetId}`)) {
      document.getElementById(`spinner${widgetId}`).classList.remove('hidden');
    }

    if (id === selectTimeDesglose.id) {
      selectTimeDesglose.optionsHours.map((el) => {
        if (el.value === hours) {
          selectTimeDesglose.currentHour = hours;
          selectTimeDesglose.currentMinutes = minutes;
          componentTimeSelector.render(
            selectTimeDesglose,
            '#embed-timeSelector'
          );
        }
      });
    }
    servicesHome
      .getDataFromWidget(widgetId, {
        lang: state.exampleReducer.currentLang,
        globalState:
          widgetId === '1' ||
          widgetId === '4' ||
          widgetId === '6' ||
          widgetId === '7'
            ? state.exampleReducer
            : {
                pages: {
                  home: {
                    indicatorsTime:
                      selectTimeDesglose.currentHour +
                      ':' +
                      selectTimeDesglose.currentMinutes,
                  },
                },
              },
        date: dateUrl
          ? moment(dateUrl, 'DD-MM-YYYY')
              .hours(selectTimeDesglose.currentHour)
              .minutes(selectTimeDesglose.currentMinutes)
              .format('DD-MM-YYYY HH:mm')
          : new Date(),
        dateFormat:
          widgetId == '4' ||
          widgetId == '6' ||
          widgetId == '7' ||
          widgetId == '24'
            ? moment(dateUrl, 'DD-MM-YYYY')
                .hours(hours)
                .minutes(minutes)
                .format('DD-MM-YYYYTHH:mm')
            : '',
      })
      .then((data) => {
        if (widgetId != 23) {
          const svgs = document.querySelectorAll(`#char${widgetId} svg`);
          svgs.forEach((svg) => {
            if (svg) {
              svg.remove();
            }
          });
          document.querySelector(`#char${widgetId} svg`)?.remove();
        }

        let slug = state.exampleReducer.routeParams.slug;
        if (slug == 'spot-europa-map' || slug == 'spot-europa') {
          charts[widgetId]({
            data,
            currentDate: dateUrl
              ? moment(dateUrl, 'DD-MM-YYYY')
                  .hours(selectTimeDesglose.currentHour)
                  .format('DD-MM-YYYYTHH')
              : new Date(),
            slug: state.exampleReducer.routeParams.slug,
          });
        } else {
          charts[widgetId](
            data,
            dateUrl
              ? moment(dateUrl, 'DD-MM-YYYY')
                  .hours(selectTimeDesglose.currentHour)
                  .minutes(selectTimeDesglose.currentMinutes)
                  .format('DD-MM-YYYY HH:mm')
              : new Date(),
            'DD / MM / YYYY | HH:mm'
          );
        }

        let systemDateTime = document.querySelector('#embed-hour');
        if (systemDateTime) {
          systemDateTime.innerHTML = moment()
            .tz('Europe/Madrid')
            .format('HH:mm');
        }
        if (widgetId != 23) {
          if (document.getElementById(`spinner${widgetId}`)) {
            document
              .getElementById(`spinner${widgetId}`)
              .classList.add('hidden');
          }
          if (document.querySelector(`#char${widgetId}`)) {
            document.querySelector(`#char${widgetId}`).style.opacity = '1';
          }
        }
      });
  };

  const getFormat = () => {
    if (title === 'evolution_of_demand') {
      return 'MM / YYYY';
    } else {
      return 'DD / MM / YYYY';
    }
  };

  const checkGraph = () => {
    switch (title) {
      case 'balance':
        return TemplateEmbedIndicatorsProgramBalance();
      case 'interruptible-demand':
        return TemplateEmbedIndicatorsInterrumpibility();
      case 'breakdown':
        return TemplateEmbedIndicatorsBreakdown();
      case 'active_energy_billing_term_of_VBAC':
        return TemplateEmbedIndicatorsBilling();
      case 'erni':
        return TemplateEmbedIndicatorsErni();
      case 'demand-or-forecasts':
        return TemplateEmbedIndicatorsDemanda();
      case 'wind-forecast':
        return TemplateEmbedIndicatorsPrevisionEolicaPen();
      case 'daily-forecast-d1-demand':
        return TemplateEmbedIndicatorsDailyForecastD1Demand();
      case 'daily-forecast-d1-wind-plus-photovoltaic':
        return TemplateEmbedIndicatorsDailyForecastD1WindPlusPhotovoltaic();
      case 'intraday-forecast-h3-demand':
        return TemplateEmbedIndicatorsIntradayForecastH3Demand();
      case 'intraday-forecast-h3-more-photovoltaic-demand':
        return TemplateEmbedIndicatorsIntradayForecastH3MorePhotovoltaicDemand();
      case 'annual-demand-forecast':
        return TemplateEmbedIndicatorsAnnualDemandForecast();
      case 'snp-forecast-demand':
        return TemplateEmbedIndicatorsSNPForecastDemand();
      case 'daily-forecast-peninsular-electricity-demand':
        return TemplateEmbedIndicatorsDailyForecastPeninsularElectricityDemand();
      case 'monthly-forecast-peninsular-electricity-demand':
        return TemplateEmbedIndicatorsMonthlyForecastPeninsularElectricityDemand();
      case 'weekly-forecast-peninsular-electricity-demand':
        return TemplateEmbedIndicatorsWeeklyForecastPeninsularElectricityDemand();
      case 'maximum-annual-forecast-peninsular-electricity-demand':
        return TemplateEmbedIndicatorsMaxAnnualForecastPeninsularElectricityDemand();
      case 'solar-planned-generation':
        return TemplateEmbedIndicatorsSolarPlannedGeneration();
      case 'minimum-annual-forecast-peninsular-electricity-demand':
        return TemplateEmbedIndicatorsMinAnnualForecastPeninsularElectricityDemand();
      case 'maximum-monthly-forecast-peninsular-electricity-demand':
        return TemplateEmbedIndicatorsMaxMonthlyForecastPeninsularElectricityDemand();
      case 'residual-demand-forecast':
        return TemplateEmbedIndicatorsPrevisonDemandaResidualWiew();
      case 'minimum-monthly-forecast-peninsular-electricity-demand':
        return TemplateEmbedIndicatorsMinMonthlyForecastPeninsularElectricityDemand();
      case 'available-generation-margin-maximum-forecast':
        return TemplateEmbedIndicatorsAvalibleGenerationMarginMaximumForecast();
      case 'deviations_cost_in_real_time':
        return TemplateEmbedIndicatorsInbalances();
      case 'photovoltaic-solar-forecast':
        return TemplateEmbedIndicatorsFotovoltaica();
      case 'solar-thermal-forecast':
        return TemplateEmbedIndicatorsTermica();
      case 'energy_final_price':
        return TemplateEmbedIndicatorsEnergy();
      case 'programming_in_Production_Market':
        return TemplateEmbedIndicatorsVolume();
      case 'electric_balance':
        return TemplateEmbedIndicatorsBalance();
      case 'spot-europa':
        return TemplateEmbedIndicatorsSpotEuropa();
      case 'spot-europa-map':
        return TemplateEmbedIndicatorsSpotEuropaMap();
      case 'hourly-balances-border':
        return TemplateEmbedIndicatorsHourlyBalances();
      case 'balance-programmed-interconections':
        return TemplateEmbedIndicatorsBProgrammedInterconection();
      case 'saldo-frontera':
        return TemplateEmbedIndicatorsTotalBalanceProgrammedInterconection();
      case 'measured_generation':
        return html` <div id="spinner${widgetId}" class="spinner-container">
            <div class="spinner"></div>
          </div>
          <div class="esios-pie-graph chart" id="chart">
            <div id="rect${widgetId}" class="rect-no-data">
              ${i18n.t('no-data-available')}
            </div>
            <div class="chartLegend"></div>
          </div>
          <div id="bar" class="percentBar"></div>`;
      default:
        return html` <div id="spinner${widgetId}" class="spinner-container">
            <div class="spinner"></div>
          </div>
          <div id="widget${widgetId}" class="generacion-consum">
            ${widgetId !== 21
              ? html`<div id="rect${widgetId}" class="rect-no-data hidden">
              ${i18n.t('no-data-available')}
            </div>
        </div>
        `
              : ``}
          </div>`;
    }
  };

  const listaindicadores = [
    544, 541, 542, 543, 1775, 10358, 1776, 10359, 1774, 1742, 460, 461, 603,
    621, 620, 10034, 604, 619, 622, 10249,
  ];

  render(
    html`
      <div
        id="${widgetId == 40 ? 'widget' + widgetId : 'char' + widgetId}"
        class=${'row main__chart esios-embed-indicators__' + title}
      >
        <div class="row">
          <div class="col-xs-7">
            <h2 class="esios-home__title" style="margin-bottom: 35px">
              ${i18n.t(title)}
            </h2>
            ${title === 'power'
              ? html`<h2>${i18n.t('gen_conventional')}</h2>`
              : ''}
          </div>
          <div class="col-xs-5 time__selector">
            <div class="mod-date-embed">
              ${widgetId === 23
                ? html`
                    <div class="esios-gd">
                      <div class="esios-gd__selector"></div>
                    </div>
                    <div
                      class="esios-gd__selector"
                      id="embed-timeSelector"
                    ></div>
                  `
                : ''}
              <input
                id="datepicker"
                class="datepicker-btn mod-date-embed--input-datepicker"
                size="2"
                name="datepicker"
                value=${dateUrl
                  ? moment(dateUrl, 'DD-MM-YYYY').format(getFormat())
                  : moment().format(getFormat())}
                readonly="true"
              />
              ${widgetId === 1 ||
              widgetId === 4 ||
              widgetId === 6 ||
              widgetId === 7 ||
              widgetId === 24 ||
              // widgetId === 39 ||
              (widgetId === 40 &&
                state.exampleReducer.routeParams.slug == 'spot-europa-map')
                ? html` |
                    <div
                      class="esios-gd__selector"
                      id="embed-timeSelector"
                    ></div>`
                : ''}
              ${title === 'generation_and_consumption' ||
              title === 'markets_and_prices' ||
              title === 'deviations_cost_in_real_time' ||
              title === 'active_energy_billing_term_of_VBAC' ||
              title === 'interruptible-demand' ||
              (widgetId === 40 &&
                state.exampleReducer.routeParams.slug == 'spot-europa') ||
              widgetId === 42 ||
              listaindicadores.includes(widgetId)
                ? html` <span id="embed-hour">HH:mm</span>`
                : ''}
            </div>
          </div>
        </div>
        ${checkGraph()}
        ${widgetId === 2
          ? html`<div id="widget2Data" class="grid-xs-24">
              <div class="widget-legend visual-legend in-line">
                <ul></ul>
              </div>

              <div class="widget-summary gyc-summary">
                <div class="gyc-summary highlighted-summary">
                  <div class="in-line"></div>

                  <div class="widget-summary-item ">
                    <div class="value is-big variacion"></div>
                    <div class="label">${i18n.t('variation')}</div>
                  </div>
                </div>
              </div>
            </div>`
          : null}
        ${widgetId == 17
          ? html` <div id="widget${widgetId}Data">
              <div
                class="esios-home__widget-legend highlighted-legend visual-legend"
              >
                <ul></ul>
              </div>
            </div>`
          : html`<div id="widget${widgetId}Data"></div>`}
      </div>
    `,
    document.querySelector('#embed-container')
  );
  datepickerComponent.render();
  const datePicker = document.querySelector('#datepicker');
  if (dateUrl) {
    datePicker.value = moment(dateUrl, 'DD-MM-YYYY').format(getFormat());
  }

  let currentHour = moment().tz('Europe/Madrid').format('HH');
  let currentMinutes = '00';

  if (
    widgetId == '1' ||
    widgetId == '4' ||
    widgetId == '6' ||
    widgetId == '7' ||
    widgetId == '8' ||
    widgetId == '9' ||
    widgetId == '42' ||
    listaindicadores.includes(widgetId) ||
    widgetId == '23' ||
    widgetId == '24'
  ) {
    if (widgetId == '23') {
      let currentMinutes10in10 = `${Math.trunc(
        moment().tz('Europe/Madrid').format('mm') / 10
      )}0`;

      currentMinutes = currentMinutes10in10;
    }

    if (widgetId == '42') {
      currentHour = '00';
      currentMinutes = '00';
    }

    if (moment().format('DD / MM / YYYY') != datePicker.value) {
      currentHour = '00';
      currentMinutes = '00';
    }
  }

  let selectTimeDesglose = {
    id: 'selectTimeDesglose',
    currentHour,
    currentMinutes,
    optionsHours: Utils.getArrayOfHours(),
    optionsMinutes: [],
    changeTime: changeSelectValue,
  };

  widgetId == 23 || widgetId == 24
    ? (selectTimeDesglose.optionsMinutes = Utils.getArrayOfMinutes(5))
    : '';

  componentTimeSelector.render(selectTimeDesglose, '#embed-timeSelector');

  if (widgetId) {
    hideEmptyEmbedContent(widgetId);
    document.querySelector(
      `#${widgetId == 40 ? 'widget' + widgetId : 'char' + widgetId}`
    ).style.opacity = '.5';
    servicesHome
      .getDataFromWidget(widgetId, {
        lang: state.exampleReducer.currentLang,
        globalState: state.exampleReducer,
        date: dateUrl,
        dateFormat: dateTime,
      })
      .then((data) => {
        if (widgetId != 23) {
          const svgs = document.querySelectorAll(`#char${widgetId} svg`);
          svgs.forEach((svg) => {
            if (svg) {
              svg.remove();
            }
          });
        }
        let chartDate = document
          .getElementById('datepicker')
          .value.split(' / ');
        chartDate = dateUrl
          ? dateUrl
          : `${chartDate[0]}-${chartDate[1]}-${chartDate[2]}`;

        let slug = state.exampleReducer.routeParams.slug;
        if (slug == 'spot-europa-map' || slug == 'spot-europa') {
          let hour = dateTime.split('T')[1].split(':')[0];
          charts[widgetId]({
            data,
            currentDate: `${chartDate}T${hour}`,
            slug: state.exampleReducer.routeParams.slug,
          });
        } else {
          charts[widgetId](data, chartDate, 'DD / MM / YYYY | HH:mm');
        }

        let systemDateTime = document.querySelector('#embed-hour');
        if (systemDateTime) {
          systemDateTime.innerHTML = moment()
            .tz('Europe/Madrid')
            .format('HH:mm');
        }

        widgetId == 40
          ? ''
          : Utils.showAndHideGraphLinesEyes(
              `#char${widgetId}`,
              `#char${widgetId} #widget${widgetId}Data ul .icon-eye`
            );

        if (widgetId != 23) {
          document
            .getElementById(`spinner${widgetId}`)
            ?.classList.add('hidden');
        }
        widgetId == 40
          ? document
              .getElementById(`spinner${widgetId}-2`)
              ?.classList.add('hidden')
          : '';
        document.querySelector(
          `#${widgetId == 40 ? 'widget' + widgetId : 'char' + widgetId}`
        ).style.opacity = '1';
      });
  }
};

export default TemplateEmbedIndicatorsGraph;

const hideEmptyEmbedContent = (widgetId) => {
  if (widgetId === '24') {
    document.querySelector('.esios-balance')?.classList.add('hidden');
    document.querySelector('.esios-home__title')?.classList.add('hidden');
    document.querySelector('.time__selector')?.classList.add('hidden');
  }
};
