import { store } from '../app.init';

/**
 *
 * @param {string} endpoint points to the desired endpoint

 * (available to put params as well)

 * @param {object} settings if you want to change some parameters from settings

 * or include the language endpoint you can do it here

 * @returns Response

 * @example api('helloWorld', {hasLang:true}) => response from 'API_URL/en/helloworld

 * @example api('byeWorld?name=John') => response from 'API_URL/byeWorld?name=John

 */

const cache = {};

export const api = async (endpoint, settings = { headers: {} }) => {
  // Token's instance
  const API_URL = window.ENV?.API_URL;
  const API_TOKEN = window.ENV?.API_TOKEN;
  const API_X_KEY = window.ENV?.API_X_KEY;

  // Preparing headers and http request configuration
  const headerToken =
    window.ENV?.environment === 'prod'
      ? { Authorization: `Token token=${API_TOKEN}` }
      : { 'X-API-KEY': API_X_KEY };

  const isPhoto = settings?.isPhoto;

  const mainSettings = {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json; application/vnd.esios-api-v1+json',
      'Content-Type': 'application/json',
      Host: 'api.esios.ree.es',
      ...headerToken,
      ...settings.headers,
    },
  };

  // This kind of settings must be deleted after put it on the configuration
  delete settings.headers;

  // Creating string endpoint (url)
  if (endpoint.charAt(0) == '/') {
    endpoint = endpoint.slice(1);
  }
  let urlApiEndpoint = `${API_URL}/`;

  // This logic is for language endpoint server purposes
  if (settings.hasLang) {
    const state = store.getState();
    urlApiEndpoint += `${state.exampleReducer.currentLang}/`;
    delete settings.hasLang;
  }

  // Preparing current settings
  const currentSettings = { ...mainSettings, ...settings };
  urlApiEndpoint += endpoint;

  if (cache[endpoint]) {
    return cache[endpoint];
  }

  cache[endpoint] = new Promise((resolve, reject) => {
    if (isPhoto) {
      fetch(urlApiEndpoint, currentSettings)
        .then((res) => {
          return res.blob();
        })
        .then((blob) => {
          var img = URL.createObjectURL(blob);
          return resolve(img);
        });
    } else {
      fetch(urlApiEndpoint, currentSettings)
        .then((res) => resolve(res.json()))
        .catch((error) => reject(error));
    }
  });
  // due to some duplicated calls the only way to manage this kind of behavior is with a setTimeout
  // the right way is to check the lifecycle component and why it is making some calls
  setTimeout(() => {
    delete cache[endpoint];
  }, 5000);

  return cache[endpoint];
};
