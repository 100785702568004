'use strict';

import i18n from 'i18next';
import { render } from 'lit-html';
import { store } from '../app.init.js';
import * as translationEn from '../assets/translations/en.json';
import * as translationEs from '../assets/translations/es.json';
import { componentHome } from '../pages/home/home.component.js';
import { componentnoEncontrada } from '../pages/noEncontrada/noEncontrada.component';
import { componentFooter } from '../shared/components/footer/footer.component.js';
import { componentHeader } from '../shared/components/header/header.component.js';
import { setRouteId } from '../state/actions.js';
import routes from './routes-list.js';

const transformToKey = (value) => {
  if (store.getState().exampleReducer.currentLang === 'es') {
    for (let key in translationEs.routes) {
      if (value === translationEs.routes[key]) {
        return 'routes.' + key;
      }
    }
  } else if (store.getState().exampleReducer.currentLang === 'en') {
    for (let key in translationEn.routes) {
      if (value === translationEn.routes[key]) {
        return 'routes.' + key;
      }
    }
  } else {
    return value;
  }
};

const getLastChildren = (routeLevels, count) => {
  if (count === routeLevels.length - 1) {
    var routesChild = routes;
    for (let index = 0; index < count; index++) {
      routesChild = routesChild.find(
        (i) =>
          i18n.t(i.url) ===
          i18n.t(transformToKey(routeLevels[index].split('/')[1]), {
            lng: store.getState().exampleReducer.currentLang,
          })
      ).childrens;
    }

    return routesChild;
  } else {
    return getLastChildren(routeLevels, count + 1);
  }
};

const checkIfHasParam = (route) => {
  const routeLevels = route
    .split('/')
    .filter((e) => /\S/.test(e))
    .map((i) => '/' + i);
  let routesLoop = routes;
  let params = {};

  for (let count = 0; count < routeLevels.length; count++) {
    if (routesLoop.find((i) => i.url.indexOf(':') !== -1)) {
      params[
        routesLoop
          .find((route) => route.url.indexOf(':') !== -1)
          .url.split(':')[1]
      ] = routeLevels[count].split('/')[1];
    }
    routesLoop = routesLoop.find(
      (i) =>
        i18n.t(i.url) ===
          i18n.t(transformToKey(routeLevels[count].split('/')[1]), {
            lng: store.getState().exampleReducer.currentLang,
          }) || i.url.indexOf(':') !== -1
    ).childrens;
  }
  store.dispatch(setRouteId(params));
};

// Busqueda de url con hijos ex: /dashboard/home
const childrenUrlSearch = async (route) => {
  const routeLevels = route
    .split('/')
    .filter((e) => /\S/.test(e))
    .map((i) => '/' + i);

  var childrenUrl;

  for (let count = 0; count < routeLevels.length - 1; count++) {
    childrenUrl = getLastChildren(routeLevels, count)?.find((i) => {
      if (i.url.indexOf(':') !== -1) {
        return true;
      }
      return (
        i18n.t(i.url) ===
        i18n.t(transformToKey(routeLevels[count + 1].split('/')[1]), {
          lng: store.getState().exampleReducer.currentLang,
        })
      );
    });
  }

  if (childrenUrl) {
    if (typeof childrenUrl.component === 'function') {
      render(childrenUrl.component(), document.getElementById('app'));
    } else {
      childrenUrl.component.render();
    }
    if (childrenUrl.header !== false) {
      componentHeader.render();
    }
    if (childrenUrl.footer !== false) {
      componentFooter.render();
    }
  } else {
    // Aqui podemos redirigir la pagina a donde sea cuando la url introducida no esté registrada
    // Seria una pantalla de Not found o el requisito que nos ponga el cliente
    componentHome.render();
    componentHeader.render();
    componentFooter.render();
  }
};

// Busqueda de primera capa, para urls sin hijos
const firstLevelSearch = (route) => {
  let routeFind = routes.find(
    (i) =>
      i18n.t(i.url) ===
      i18n.t(transformToKey(route.split('/')[1]), {
        lng: store.getState().exampleReducer.currentLang,
      })
  );
  if (routeFind.redirectTo) {
    window.location.pathname = `/${i18n.t(routeFind.redirectTo)}`;
  } else {
    if (routeFind) {
      routeFind.component.render();
      componentHeader.render();
      componentFooter.render();
    } else {
      // Aqui podemos redirigir la pagina a donde sea cuando la url introducida no esté registrada
      // Seria una pantalla de Not found o el requisito que nos ponga el cliente
    }
  }
};

const go = (route) => {
  try {
    checkIfHasParam(route);

    const routeSet = route.match(/\//gi);
    const isChildrenUrl = routeSet ? routeSet.length > 1 : false;
    if (isChildrenUrl) {
      childrenUrlSearch(route);
    } else {
      firstLevelSearch(route);
    }
  } catch {
    componentnoEncontrada.render();
  }
};

export { go };
