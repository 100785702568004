"use strict";
import { html } from "lit-html";
import { templateLayoutSubcontent } from "../layoutSubcontent/layoutSubcontent.template";

let templateLayoutExtended = (template, subcontentData) => {
  return html`
    <div class="esios-layout__extended">
      ${template ? template : null} 
      ${subcontentData ? templateLayoutSubcontent(subcontentData) : null}
    </div>
  `;
};

export { templateLayoutExtended };
