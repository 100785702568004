'use strict';
import { html } from 'lit-html';

let templateModal = (content, idButton) => {
  return html`
    <div id="modal-${idButton}" class="esios-modal">
      <div class="esios-modal__container esios-modal__container--${idButton}">
        <div id="modal-close-${idButton}" class="esios-modal__close">×</div>
        <div class="esios-modal__content esios-modal__content--${idButton}">
          ${typeof content === 'object' ? content : html([content])}
        </div>
      </div>
      <div
        id="esios-modal__background-${idButton}"
        class="esios-modal__background"
      ></div>
    </div>
  `;
};

export { templateModal };
