import i18n from 'i18next';
import { render } from 'lit-html';
import { api } from '../../utils/api-interceptor.js';
import MyEsios from '../my-esios/my-esios.js';
import servicesLogin from './login.service.js';
import { templateLogin } from './login.template.js';

let componentLogin = (function () {
  let renderSuccess = false;
  var auth2;

  const loginFacebook = () => {
    const { currentLang } = getState();
    const url = currentLang === 'es' ? '/es/mi-esios' : '/en/my-esios';
    FB.login(
      (response) => {
        if (response.authResponse) {
          window.location.pathname = url;
        }
      },
      { scope: '' }
    );
  };

  var startApp = function () {
    gapi.load('auth2', function () {
      auth2 = gapi.auth2.init({
        client_id: window.ENV?.GOOGLE_CLIENT_ID,
        cookiepolicy: 'single_host_origin',
      });
      attachSignin(document.getElementById('google-auth'));
    });
  };
  let renderComponent = () => {
    try {
      // Render the template to the document
      templateLogin(loginFacebook);
      renderSuccess = true;
      document
        .querySelector('.esios-layout__wrap')
        .classList.add('esios-layout__wrap--myesios');
      document
        .querySelector('.esios-layout__breadcrumbs')
        .classList.add('esios-layout__breadcrumbs--myesios');
    } catch (error) {
      renderSuccess = false;
    }
  };
  let postRenderComponent = () => {
    startApp();
  };

  async function attachSignin(element) {
    auth2.attachClickHandler(
      element,
      {},
      function (googleUser) {
        const profile = googleUser.getBasicProfile();
        const myUserEntity = {};
        myUserEntity.id = profile.getId();
        myUserEntity.name = profile.getName();
        document.getElementById('spinner').style.display = 'flex';
        document
          .querySelector('.esios-login__container')
          .classList.add('disabled');
        api(
          'social-login?uid=' +
            myUserEntity.id +
            '&provider=google' +
            '&rdn=' +
            Math.floor(Math.random() * 1000)
        )
          .then(async ({ token }) => {
            myUserEntity.token = token;
            let addToFavIsAnalysis = false;
            localStorage.setItem('myUserEntity', JSON.stringify(myUserEntity));
            let addToFav = localStorage.getItem('addToFav');
            addToFavIsAnalysis = localStorage.getItem('addToFavIsAnalysis');
            if (addToFav) {
              if (addToFav.charAt(0) == '/') {
                addToFav = addToFav.slice(1);
              }
              await api('favourites', {
                method: 'POST',
                body: JSON.stringify({
                  uri: '/' + addToFav,
                  authentication_token: token,
                  favourite_type: addToFavIsAnalysis ? 'analysis' : 'widget',
                  order: 0,
                }),
              });
              localStorage.removeItem('addToFav');
              if (addToFavIsAnalysis) {
                localStorage.removeItem('addToFavIsAnalysis');
              }
            }

            window.location.pathname = '/es/mi-esios';
          })
          .catch(() => {
            document.getElementById('spinner').style.display = 'none';
            document
              .querySelector('.esios-login__container')
              .classList.remove('disabled');
          });
      },
      function (error) {}
    );
  }

  let init = () => {
    document.title = `${i18n.t('my_esios')} | ${i18n.t('page_subtitle')}`;

    let token = servicesLogin().validateSession();

    // Call render component
    renderComponent();
    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';

    if (token) {
      render(MyEsios(), document.getElementById('app'));
    }
  };

  return {
    render: init,
  };
})();

export { componentLogin };
