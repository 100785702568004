'use strict';

/**
 * @description Dashboard Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { render } from 'lit-html';
import { templateModal } from './modal.template.js';

let componentModal = (function () {
  //COMPONENTE MODAL (COMO USARLO)
  //En el template que queramos usarlo debemos crear un elemento (boton,input...) con un id que pasaremos al componente y
  //tambien crearemos o haremos referencia a un div que este dentro del body (no influenciará en nada solo que es necesario para el funcionamiento del componente)

  //componentModal.render(body,idBoton,parent)
  //primer parametro:   (el contenido que mostraras en el modal)
  //segundo parametro:  (el id del boton que accionara el modal)
  //tercer parametro:   (el id del div que usaremos para que funciones bien el componente (da igual donde esté, mientras este dentro del body))
  //---------------------------------------------------------------------------
  //ejemplo de uso en un template:
  //<button id='idDelBoton'>click para mostrar modal</button>
  //<div id='idDelPadre'></div>
  //componentModal.render('<h2>titulo modal</h1><p>texto</p>','idDelBoton','idDelPadre')

  let renderSuccess = false;

  let renderComponent = (
    content,
    idButton,
    idParent,
    renderOnClick = false
  ) => {
    try {
      // Render the template to the document
      if (renderOnClick) {
        render(
          // Define a template
          templateModal('', idButton),
          document.querySelector('#' + idParent)
        );
      } else {
        render(
          // Define a template
          templateModal(content, idButton),
          document.querySelector('#' + idParent)
        );
      }

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = (
    idButton,
    showOnClick,
    idParent,
    content,
    renderOnClick
  ) => {
    let modal = document.getElementById('modal-' + idButton);
    let span = document.getElementById('modal-close-' + idButton);
    let body = document.getElementsByTagName('body')[0];
    let background = document.getElementById(
      'esios-modal__background-' + idButton
    );

    if (showOnClick) {
      if (document.getElementById(idButton)) {
        let btn = document.getElementById(idButton);

        btn.onclick = () => {
          if (renderOnClick) {
            render(
              // Define a template
              templateModal(content(), idButton),
              document.querySelector('#' + idParent)
            );
          }
          modal.style.display = 'flex';
          body.style.overflow = 'hidden';
        };
      }
    } else {
      modal.style.display = 'flex';
      body.style.overflow = 'hidden';
    }

    span.onclick = () => {
      if (document.querySelector('.yt_player_iframe')) {
        document
          .querySelector('.yt_player_iframe')
          ?.contentWindow.postMessage(
            '{"event":"command","func":"pauseVideo","args":""}',
            '*'
          );
      }
      modal.style.display = 'none';
      body.style.position = 'inherit';
      body.style.height = 'auto';
      body.style.overflow = 'visible';
    };

    background.onclick = () => {
      if (document.querySelector('.yt_player_iframe')) {
        document
          .querySelector('.yt_player_iframe')
          .contentWindow.postMessage(
            '{"event":"command","func":"pauseVideo","args":""}',
            '*'
          );
      }
      modal.style.display = 'none';
      body.style.position = 'inherit';
      body.style.height = 'auto';
      body.style.overflow = 'visible';
    };
  };

  let init = (
    content,
    idButton,
    idParent,
    showOnClick = true,
    renderOnClick = false
  ) => {
    renderComponent(content, idButton, idParent, renderOnClick);
    renderSuccess
      ? postRenderComponent(
          idButton,
          showOnClick,
          idParent,
          content,
          renderOnClick
        )
      : '';
  };

  return {
    render: (content, idButton, idParent, showOnClick, renderOnClick) =>
      init(content, idButton, idParent, showOnClick, renderOnClick),
  };
})();

export { componentModal };
