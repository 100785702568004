'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';
import moment from 'moment';
import backImage from '../../assets/images/header-news.jpg';
import { componentLayout } from '../../components/layout/layout.component';

let templateTitulacionOperacion = (data) => {
  return componentLayout.render(
    html`
      <div class="esios-titulares">
        <div
          class="esios-titulares__container esios-titulares__container--main"
        >
          <h1 class="esios-info__title">${i18n.t('news')}</h1>
          ${data.map(({ title, body, published_at, slug }) => {
            return html`<article class="esios-info__container">
              <time class="esios-info__time" datetime="${published_at}"
                >${moment(published_at).format('DD / MM / YYYY')}</time
              >
              <h2 class="esios-info__subtitle">
                <a
                  class="esios-titulares__subtitle"
                  href="/${i18n.t('routes.lng')}/${i18n.t(
                    'routes.news'
                  )}/${slug}"
                  >${title}</a
                >
              </h2>
              <p class="">${html([body])}</p>
            </article>`;
          })}
        </div>
        <aside
          class="esios-titulares__container esios-titulares__container--aside"
        >
          <h2 class="esios-titulares__title-aside">
            ${i18n.t('related_news')}
          </h2>
        </aside>
      </div>
    `,
    {
      image: backImage,
      showFooterImage: true,
    }
  );
};

export { templateTitulacionOperacion };
