import { html } from 'lit-html';

const templateBanner = (
  pathname,
  { text, image, bannerIsAligned = false, showBanner = true }
) => {
  if (!showBanner) {
    document.querySelector('#esios-layout-banner').classList.add('no-banner');
  }
  return html`
    ${text
      ? html` <div class="esios-layout__indicator-caption">
          <p class="banner-subtitle" id="indicatorCaption"></p>
        </div>`
      : null}
    <div
      class="layout-header-banner ${bannerIsAligned ? 'banner-aligned' : ''} "
    >
      <div class="mod-banner" id="bannerHome">
        <div class="wrapper">
          <div
            id="regular-banner"
            class="banner-secondary-image pvpc-high-2"
            style="background-image: url('${image}');"
          ></div>
          <div class="banner-main-image" style="display: none;"></div>
        </div>
      </div>
    </div>
  `;
};

export default templateBanner;
