import i18n from 'i18next';
import { html } from 'lit-html';
import { store } from '../../app.init';
import Tabs from '../../shared/components/tabs/tabs.template';
import { setAnalysis } from '../../state/actions';

const AnalysisTab = (indicator) => {
  const urlParams = new URLSearchParams(window.location.search);
  const tabsForAnalysis = getTabsForAnalysis(indicator);

  const activeTab = urlParams.get('vis') || 1;

  const onTabSelected = (index) => {
    const payload = { tabSelected: { index, label: tabsForAnalysis[index] } };
    store.dispatch(setAnalysis(payload));
  };

  onTabSelected(activeTab - 1);

  const tabInfo = {
    activeTab,
    tabsContent: tabsForAnalysis.map((el) => {
      return {
        text: i18n.t(el),
        class: 'tab__content',
      };
    }),
  };

  return html` ${Tabs(tabInfo, onTabSelected)} `;
};
export function getTabsForAnalysis(indicator) {
  return indicator.composited
    ? ['comparative', 'temporal', 'composition', 'geographical']
    : ['comparative', 'temporal', 'geographical'];
}

export default AnalysisTab;
