import { store } from '../app.init';

export const getState = () => {
  return store.getState().exampleReducer;
};

const subscribers = {};

export function getSubscriber(stateFn) {
  // This is a tricky code to get the caller in order to avoid reinstances.
  // NOTE: this kind of solution does not work with double instanced components.
  let caller = new Error().stack.split('\n')[2].trim();

  caller = caller.includes('@')
    ? new Error().stack.split('\n')[1].trim().split('@')[0]
    : new Error().stack.split('\n')[2].trim().split(' ')[1];

  if (!subscribers[caller]) {
    subscribers[caller] = true;
    store.subscribe(stateFn);
  }
}
