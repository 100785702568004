'use strict';

/**
 * @description Help Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { store } from '../../app.init.js';
import { api } from './../../utils/api-interceptor';
import { templateTitulacionOperacion } from './operacion.template';
import i18n from 'i18next';

let componentTitulacionOperacion = (function () {
  let context = {};
  let renderSuccess = false;

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    document.title = `${i18n.t('news')} | ${i18n.t('page_subtitle')}`;
    context.name = state.exampleReducer.name;
  };

  let renderComponent = async () => {
    try {
      const { contents } = await api(
        'news?temporal=' + Math.floor(Math.random() * 1000),
        { hasLang: true }
      );
      let orderNewsList = contents.slice(0, 6);
      templateTitulacionOperacion(orderNewsList);
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = () => {
    preRenderComponent();
    renderComponent();
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentTitulacionOperacion };
