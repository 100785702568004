'use strict';
import { html } from 'lit-html';
import { componentLayout } from '../../components/layout/layout.component';
import backimage from '../../assets/images/header-default.jpg';
import i18n from 'i18next';

let templateMapLinks = (context, data, selectedMap) => {
  const { body, title } = selectedMap;
  let delimiter = '/';
  let url = context.path.split('/');
  let correctedUrl =
    url[0] + delimiter + url[1] + delimiter + url[2] + delimiter;
  let articleChain = '';
  let newArticle = `
  <article class="esios-mapas-links__mod-article-item">
    <h1 id="map-title" class="esios-mapas-links__main-title">${title}</h1>
    ${body}
  </article>`;
  articleChain += newArticle.trim();

  return componentLayout.render(
    html`
      <div class="esios-mapas-links" id="mapas-links">
        <div class="esios-mapas-links__wrap">
          <div class="esios-mapas-links__row">
            <div class="grid-xs-24">
              <div class="grid-xs-24 grid-sm-16">${html([articleChain])}</div>
            </div>
          </div>
          <aside class="esios-mapas-links__aside">
            <h2>${i18n.t('related_maps')}</h2>
            <ul>
              ${data.map((element) =>
                element.slug !== selectedMap.slug
                  ? html`
                      <li>
                        <a href="${correctedUrl + element.slug}"
                          >${element.title}</a
                        >
                      </li>
                    `
                  : ''
              )}
            </ul>
          </aside>
        </div>
      </div>
    `,
    {
      image: backimage,
      showBanner: true,
      showFooterImage: true,
    }
  );
};

export { templateMapLinks };
