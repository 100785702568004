'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';
import calendar from '../../assets/images/calendar.svg';
import backImage from '../../assets/images/header-descargas.jpg';
import { componentLayout } from '../../components/layout/layout.component';
import { templateBoxMarketColumn } from '../../shared/components/box-market-column/box-market-column.template';

import { descargas } from '../globals.js';
const { labeltabla, labelSubtitle, labelparrafo1, labelSubtitle2 } = descargas;

let templateDescargas = (
  context,
  handlers,
  pageTitle,
  select,
  paginationValues,
  pagination,
  filterForm,
  searchForm,
  listMarket,
  selectElements,
  lang
) => {
  const {
    onTotalChange,
    onPageChange,
    onQueryChange,
    onTypeSearchChange,
    ocultar,
    onSearch,
    onSelectChange,
  } = handlers;
  const id = select.map((item) => item.id);
  const label = select.map((item) => item.label);
  const options = select.map((item) => item.options);

  const headerOfFruit = (value, property) =>
    html`<div style="color: red;">${value}</div>`;
  const bodyOfFruit = (value, property) =>
    html`<div style="color: red;">${value}</div>`;
  const sort = (value, property) => html`
    <ld-header-with-sort
      language="en"
      direction="desc"
      data-property="${property}"
    >
      ${value}
    </ld-header-with-sort>
  `;
  return componentLayout.render(
    html`
      <head>
        <link
          href="https://unpkg.com/boxicons@2.0.9/css/boxicons.min.css"
          rel="stylesheet"
        />
      </head>
      <div class="esios-tabla esios-tabla--descargas">
        <h1 class="esios-tabla__title">${i18n.t(labeltabla)}</h1>
        <div class="content-paragraph">
          <div class="esios-tabla__filtro">
            <div class="grid-sm-24">
              <div class="intro-text">
                <p class="esios-tabla__opinion">
                  ${i18n.t('descargas_intro_1')}
                </p>
                <p class="esios-tabla__opinion">
                  ${i18n.t('descargas_intro_2')}
                </p>
              </div>
            </div>
            <div class="esios-tabla__container-select">
              <div
                class="init-dropdown esios-tabla__dropdown1 esios-tabla__wrap col-3"
              >
                <select name="speed" class="ree-select-publication">
                  ${options[2]?.map(
                    (item) =>
                      html`<option class="option" value="${item.value}">
                        ${i18n.t(item.label)}
                      </option>`
                  )}
                </select>
              </div>

              <div
                class="esios-tabla__wrap col-3 esios-tabla__divFecha is-datepicker mod-date-days is-day-selectable"
                id="datepickerStartDate"
              >
                <div
                  class="esios-tabla__inputCalendar esios-tabla__inputCalendar--datepicker mod-date mod-date-days is-day-selectable"
                >
                  <input
                    id="datepicker1"
                    name="datepicker1"
                    class="esios-tabla__datepicker"
                    value=${searchForm.date_init}
                  />
                </div>
              </div>

              <div
                class="esios-tabla__wrap col-3 esios-tabla__divFecha is-datepicker mod-date-days is-day-selectable"
                id="datepickerEndDate"
              >
                <div
                  class="esios-tabla__inputCalendar esios-tabla__inputCalendar--datepicker mod-date mod-date-days is-day-selectable"
                >
                  <input
                    id="datepicker2"
                    name="datepicker2"
                    class="esios-tabla__datepicker"
                    value=${searchForm.date_end}
                  />
                </div>
              </div>
              <button
                @click="${onSearch}"
                class="esios-tabla__buscarFecha icon-download btn-info"
                href="#"
                id="tableDownloadJSONBtn"
              >
                ${i18n.t('date_search')}
                <img
                  class="esios-tabla__calendarIcon"
                  src=${calendar}
                  alt="calendar"
                />
              </button>
            </div>
          </div>
        </div>

        <hr />
        <!--filtros con su onchange-->

        <div class="esios-tabla__filters">
          <div
            class="esios-tabla__wrap esios-tabla__select esios-tabla__select--search col-3"
          >
            <input
              class="esios-tabla__input esios-tabla__input--search"
              placeholder="${i18n.t('go_search')}"
              type="text"
              @keyup="${onQueryChange}"
            />
            <button
              class="esios-tabla__boton-remove hidden"
              @click="${ocultar}"
            >
              &nbsp
            </button>
          </div>
          ${selectElements.map(
            (select, index) =>
              html`<div
                class="esios-tabla__wrap esios-tabla__select esios-tabla__select--filters col-3"
              >
                <select
                  class="esios-tabla__dropdown2 ree-select-${select.value}"
                  value="${filterForm[select.value]}"
                  @change="${onSelectChange(index)}"
                >
                  <option class="option" value="" selected disabled hidden>
                    ${i18n.t(select.label)}
                  </option>
                  ${options[index]?.map(
                    (item) =>
                      html` <option class="option" value="${item.value}">
                        ${i18n.t(item.label)}
                      </option>`
                  )}
                </select>
              </div>`
          )}
        </div>
        <div id="spinner1" class="spinner-container">
          <div class="spinner"></div>
        </div>
        <div
          id="download-table"
          class="esios-documentacion__content esios-documentacion__content--border-bottom tabla-descargas"
        ></div>

        <div id="pagination" class="esios-pagination paginador--descargas">
          <div class="esios-pagination__results">
            <span class="esios-pagination__txt esios-pagination__arrow">
              ${i18n.t('showing')}
              <select
                class="esios-pagination__select"
                value="${pagination.pagination}"
                @change="${onTotalChange}"
              >
                ${paginationValues.map(
                  (item) => html`<option value="${item}">${item}</option>`
                )}
              </select>
            </span>
            <span class="esios-pagination__txt"
              >${i18n.t('text_results')} ${pagination.total}</span
            >
          </div>
          ${pagination.totalPages > 0
            ? html`<div
                class="esios-pagination__paginator"
                id="pagination-descargas"
              ></div>`
            : ''}
        </div>
        <div
          class="esios-tabla__buttons blue-buttons"
          id="button-download"
        ></div>
      </div>
    `,
    {
      text: '',
      image: backImage,
      //showBanner: true,
    },

    html``,
    {
      title: `${i18n.t('more')}`,
      subcontent: html`
        <div class="esios-documents">
          <div class="grid-xs-12 documents-container-download">
            <div class="mod-documents-download">
              ${templateBoxMarketColumn(listMarket)}
              <div class="mod-buttons">
                ${lang == 'es'
                  ? html` <a
                      class="mod-buttons__btn"
                      href="https://www.ree.es/es/clientes"
                      target="_blank"
                      >${i18n.t('gde_website_text')}</a
                    >`
                  : ''}
              </div>
            </div>
          </div>
        </div>
      `,
    }
  );
};

export { templateDescargas };
