import i18n from 'i18next';
import { html } from 'lit-html';

const MyEsiosModal = (favourites) => {
  return html`
    <div class="row">
      <h2 class="col-9">${i18n.t('order_my_esios')}</h2>
      <div class="col-3 esios-layout__breadcrumbs__select select-typeOrder">
        <select id="typeOrder" class="esios-myesios__select">
          <option value="alpha">
            ${i18n.t('alphabetical_order').toUpperCase()}
          </option>
          <option value="manual" selected="selected">
            ${i18n.t('manual_order').toUpperCase()}
          </option>
        </select>
      </div>
    </div>

    <ul id="sortable" class="esios-myesios__list">
      ${favourites.map(({ name, id }) => {
        return html`<li id="order${id}" class="esios-myesios__item icon-drag">
          ${name}
        </li>`;
      })}
    </ul>
    <div class="col-12">
      <div class="esios-myesios__sorting-buttons">
        <button
          id="btnCancel"
          class="esios-myesios__buttons esios-myesios__buttons--cancel"
        >
          ${i18n.t('cancel')}
        </button>
        <button
          id="btnApply"
          class="esios-myesios__buttons esios-myesios__buttons--apply"
        >
          ${i18n.t('apply')}
        </button>
      </div>
    </div>
  `;
};

export default MyEsiosModal;
