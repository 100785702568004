'use strict';

/**
 * @description Tab Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { render } from 'lit-html';
import Tabs from './tabs.template';

let componentTabs = (function () {
  let renderSuccess = false;

  let renderC = async (data, parent) => {
    try {
      // Render the template to the document
      data.parent = parent;
      render(
        // Define a template
        Tabs(data),
        document.querySelector(parent)
      );

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  return {
    render: renderC,
  };
})();

export { componentTabs };
