'use strict';

import { api } from '../../utils/api-interceptor.js';

export const getDataOfSlug = (slug) => {
  return api(
    `static_pages/${slug}?temporal=` + Math.floor(Math.random() * 1000),
    {
      hasLang: true,
    }
  );
};
