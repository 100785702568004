"use strict";
import { html } from "lit-html";

let templateLayoutMain = (template) => {
  return html`
    <div class="esios-layout__wrap">
      ${template}
      <div id="esios-main-page"></div>
    </div>
  `;
};

export { templateLayoutMain };
