'use strict';
import i18next from 'i18next';
import moment from 'moment-timezone';
import 'regenerator-runtime/runtime';
import { api } from '../../utils/api-interceptor';
import { Utils } from '../../utils/utils';

let servicesSaldoFronteras = (() => {
  let init = (e) => {};

  const getAuctionsDataFromWidget = async (params) => {
    const { lang, time, day, month, year } = params;
    if (time === '00:00') {
      return api(
        `widgets/10?locale=${lang}&datetime=${
          year.replace(/ /g, '') +
          '-' +
          month.replace(/ /g, '') +
          '-' +
          day.replace(/ /g, '')
        }`
      ).then((data) => {
        const indicators = data.widget.indicators;
        const slug = data.widget.id;
        const description = data.widget.description;
        const name = data.widget.name;
        const magnituds = [];
        const alias = [];
        const indicatorName = [];
        const shortName = [];
        return Promise.all(
          indicators.map((el) => {
            magnituds.push(el.display_options);
            alias.push(el.alias);
            shortName.push(el.short_name);
            indicatorName.push(el.name);
            return api(`${el.url}&locale=${lang}`);
          })
        ).then((data) => {
          const indicators = data.map((ele, index) => {
            const values = ele.indicator.values.map((el) => {
              return {
                x: new Date(el.datetime),
                y: el.value,
              };
            });

            return {
              values,
              print_values: ele.indicator.values,
              time: ele.indicator.tiempo[0]?.id,
              alias: alias[index],
              display_options: magnituds[index],
              composited: ele.indicator?.composited,
              magnitud: ele.indicator?.magnitud,
              disaggregated: ele.indicator?.disaggregated,
              short_name: shortName[index],
              name: indicatorName[index],
              step: ele.indicator.step_type,
              id: ele.indicator.id,
              geo_id: ele.indicator.geos[0]?.geo_id,
              geo_name: ele.indicator.geos[0]?.geo_name,
            };
          });

          return {
            description,
            name,
            indicators,
            slug,
          };
        });
      });
    } else {
      let timeInHours = time;
      const timezone = new Date().getTimezoneOffset() / 60;
      let newDate =
        year +
        '-' +
        month +
        '-' +
        day +
        'T' +
        timeInHours.split(':')[0] +
        ':00:00' +
        (timezone <= 0 ? '+' + -1 * timezone : -1 * timezone) +
        ':00';
      return api(`widgets/10?locale=${lang}&datetime=${newDate}`).then(
        (data) => {
          const indicators = data.widget.indicators;
          const slug = data.widget.id;
          const description = data.widget.description;
          const name = data.widget.name;
          const magnituds = [];
          const alias = [];
          const indicatorName = [];
          const shortName = [];
          return Promise.all(
            indicators.map((el) => {
              magnituds.push(el.display_options);
              alias.push(el.alias);
              shortName.push(el.short_name);
              indicatorName.push(el.name);
              return api(`${el.url}&locale=${lang}`);
            })
          ).then((data) => {
            const indicators = data.map((ele, index) => {
              const values = ele.indicator.values.map((el) => {
                return {
                  x: new Date(el.datetime),
                  y: el.value,
                };
              });

              return {
                values,
                print_values: ele.indicator.values,
                time: ele.indicator.tiempo[0]?.id,
                alias: alias[index],
                display_options: magnituds[index],
                composited: ele.indicator?.composited,
                magnitud: ele.indicator?.magnitud,
                disaggregated: ele.indicator?.disaggregated,
                short_name: shortName[index],
                name: indicatorName[index],
                step: ele.indicator.step_type,
                id: ele.indicator.id,
                geo_id: ele.indicator.geos[0]?.geo_id,
                geo_name: ele.indicator.geos[0]?.geo_name,
              };
            });

            return {
              description,
              name,
              indicators,
              slug,
            };
          });
        }
      );
    }
  };

  const getAuctionsPortugalDataFromWidget = async (params) => {
    const { lang, day, month, year, time } = params;
    const timeInHours = time;
    const newDate = moment()
      .tz('Europe/Madrid')
      .year(year)
      .month(month - 1)
      .date(day)
      .hours(timeInHours?.split(':')[0] || '00')
      .minutes('00')
      .seconds(0)
      .format();
    return api(`widgets/35?locale=${lang}&datetime=${newDate}`).then((data) => {
      const indicators = data.widget.indicators;
      const slug = data.widget.id;
      const description = data.widget.description;
      const name = data.widget.name;
      const magnituds = [];
      const alias = [];
      const indicatorName = [];
      const shortName = [];
      return Promise.all(
        indicators.map((el) => {
          magnituds.push(el.display_options);
          alias.push(el.alias);
          shortName.push(el.short_name);
          indicatorName.push(el.name);
          return api(`${el.url}&locale=${lang}`);
        })
      ).then((data) => {
        const indicators = data.map((ele, index) => {
          const values = ele.indicator.values.map((el) => {
            return {
              x: new Date(el.datetime),
              y: el.value,
            };
          });

          return {
            values,
            print_values: ele.indicator.values,
            time: ele.indicator.tiempo[0]?.id,
            alias: alias[index],
            display_options: magnituds[index],
            composited: ele.indicator?.composited,
            magnitud: ele.indicator?.magnitud,
            disaggregated: ele.indicator?.disaggregated,
            short_name: shortName[index],
            name: indicatorName[index],
            step: ele.indicator.step_type,
            id: ele.indicator.id,
            geo_id: ele.indicator.geos[0]?.geo_id,
            geo_name: ele.indicator.geos[0]?.geo_name,
          };
        });

        return {
          description,
          name,
          indicators,
          slug,
        };
      });
    });
  };

  const getDataFromWidget = async (params) => {
    const lang = i18next.t('routes.lng');
    const { id, hours, minutes, date } = params;
    let newDate;

    newDate = moment(date, 'DD-MM-YYYY')
      .tz('Europe/Madrid')
      .hours(hours ? hours : '00')
      .minutes(minutes ? minutes : '00')
      .seconds('00')
      .format();

    return api(`widgets/${id}?datetime=${newDate}&locale=${lang}`).then(
      (data) => {
        let urlAnalysis = Utils.getUrlAnalysis(data.widget.main_indicators);
        const indicators = data.widget.indicators;
        const slug = data.widget.id;
        const description = data.widget.description;
        const name = data.widget.name;
        const magnituds = [];
        const alias = [];
        const indicatorName = [];
        return Promise.all(
          indicators.map((el) => {
            magnituds.push(el.display_options);
            alias.push(el.alias);
            indicatorName.push(el.name);
            return el.url
              ? api(`${el.url}&locale=${lang}`)
              : { ...el, url: 'empty' };
          })
        ).then((data) => {
          const indicators = data.map((ele, index) => {
            if (ele.indicator) {
              const values = ele.indicator.values.map((el) => {
                return {
                  x: new Date(el.datetime),
                  y: el.value,
                };
              });

              return {
                values,
                print_values: ele.indicator.values,
                alias: alias[index],
                display_options: magnituds[index],
                time: ele.indicator.tiempo[0]?.id,
                composited: ele.indicator?.composited,
                magnitud: ele.indicator?.magnitud,
                disaggregated: ele.indicator?.disaggregated,
                name: indicatorName[index],
                short_name: ele.indicator.short_name,
                step: ele.indicator.step_type,
                id: ele.indicator.id,
                geo_id: ele.indicator.geos[0]?.geo_id,
              };
            } else {
              return {
                values: [],
                print_values: null,
                alias: alias[index],
                display_options: magnituds[index],
                time: null,
                composited: null,
                magnitud: null,
                disaggregated: null,
                name: indicatorName[index],
                short_name: null,
                step: null,
                id: ele.id,
                geo_id: null,
              };
            }
          });

          return {
            description,
            name,
            indicators,
            slug,
            urlAnalysis,
          };
        });
      }
    );
  };

  return {
    init: (e) => init(e),
    getAuctionsDataFromWidget: (params) => getAuctionsDataFromWidget(params),
    getAuctionsPortugalDataFromWidget: (params) =>
      getAuctionsPortugalDataFromWidget(params),
    tableContent: (data) => tableContent(data),
    getDataFromWidget: (params) => getDataFromWidget(params),
  };
})();

export { servicesSaldoFronteras };
