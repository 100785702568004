'use strict';

/**
 * @description Unidades Fisicas Component
 * @returns {Object} Devuelve la instancia del componente
 */

import i18n from 'i18next';
import { render } from 'lit-html';
import moment from 'moment';
import { store } from '../../app.init.js';
import { componentButtons } from '../../shared/components/buttons/buttons.component.js';
import { componentListPrintView } from '../../shared/components/list-print-view/list-print-view.component.js';
import { componentPaginator } from '../../shared/components/paginator/paginator.component.js';
import { Utils } from '../../utils/utils.js';
import { templateTable } from '../table/table.template.js';
import { servicesUnidades } from './unidades.services.js';
import { templateUnidades } from './unidades.template.js';

let componentUnidades = (function () {
  let context = {};
  let handlers = {
    onPageChange: (page) => onPageChange(page),
    onTotalChange: (event) => onTotalChange(event),

    onQueryChange: (event) => onQueryChange(event),
    onPotenciaChange: (event) => onPotenciaChange(event),
    onTipoProduccionChange: (event) => onTipoProduccionChange(event),
    onVinculacionSMChange: (event) => onVinculacionSMChange(event),
    onVinculacionUPChange: (event) => onVinculacionUPChange(event),

    onTypeSearchChange: (event) => onTypeSearchChange(event),
    onDateInitChange: (event) => onDateInitChange(event),
    onDateEndChange: (event) => onDateEndChange(event),
    onSearch: () => onSearch(),
    onHide: (event) => onHide(event),
    onSelectChange: (index) => onSelectChange(index),
  };
  let pageTitle = '';
  let columns;
  let renderSuccess = false;
  let listMarket;
  let select = [];

  const currentRoute = window.location.pathname;
  let lang = currentRoute.split('/')[1];
  listMarket = [
    {
      type: 'title',
      id: 2,
      title: 'structural_data',
    },
    {
      type: 'list',
      id: 1,
      text: 'participants_auction',
      url: `/${i18n.t('routes.lng')}/${i18n.t('routes.participants_auction')}`,
    },
    {
      type: 'list',
      id: 2,
      text: 'programming_units',
      url: `/${i18n.t('routes.lng')}/${i18n.t('routes.programming_units')}`,
    },
    {
      type: 'list',
      id: 3,
      text: 'physical_units',
      url: `/${i18n.t('routes.lng')}/${i18n.t('routes.physical_units')}`,
    },
    {
      type: 'list',
      id: 4,
      text: 'market_subjects',
      url: `/${i18n.t('routes.lng')}/${i18n.t('routes.market_subjects')}`,
    },
  ];

  const buttonsArray = [
    {
      title: 'EXPORTAR ',
      action: 'exportJSON',
      url: '#',
    },
    {
      title: 'EXPORTAR CSV',
      action: 'exportCSV',
      url: '#',
    },
    {
      title: 'EXPORTAR EXCEL',
      action: 'exportEXCEL',
      url: '#',
    },
    {
      title: 'IMPRIMIR',
      action: 'print',
      url: '#',
    },
  ];
  componentButtons.render(buttonsArray, '#button-esios-unidades');
  // tres filtros
  let searchForm = {
    type: 'publicacion',
    date_init: moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD'),
    date_end: moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD'),
  };
  let filterForm = {
    query: '',
    potencia: '',
    tipoproduccion: '',
    vinculacionsm: '',
    vinculacionup: '',
  };
  let data = [];
  let dataPaginated = [];

  let paginationValues = [25, 50, 100];
  let pagination = {
    total: 0,
    page: 1,
    totalPages: 1,
    pagination: paginationValues[0],
  };
  const onPageChange = (page) => {
    pagination.page = page;
    pagination.totalPages = Math.ceil(data.length / pagination.pagination);
    filterData();
  };

  const onTotalChange = (event) => {
    pagination.page = 1;
    pagination.pagination = event.target.value;
    pagination.totalPages = Math.ceil(data.length / pagination.pagination);
    filterData();
  };

  const onQueryChange = (event) => {
    filterForm.query = event.target.value;
    if (event.target.value.length > 0)
      document
        .querySelector('.esios-unidades__button-remove')
        .classList.remove('hidden');
    else
      document
        .querySelector('.esios-unidades__button-remove')
        .classList.add('hidden');
    filterData();
  };

  const selectElements = [
    {
      value: 'potencia',
      label: 'maximum_power_capacity_MW',
    },
    {
      value: 'tipoproduccion',
      label: 'production_type',
    },
    {
      value: 'vinculacionsm',
      label: 'linking_with_SM',
    },
    {
      value: 'vinculacionup',
      label: 'pairing_with_UP',
    },
  ];

  const onSelectChange = (index) => {
    // cE = chosen element
    const cE = selectElements[index];
    return (event) => {
      filterForm[cE.value] = event.target.value;
      let selected = document.querySelectorAll('.ui-selectmenu-text');
      filterForm[cE.value] === ''
        ? (selected[index].innerHTML = i18n.t(cE.label))
        : filterForm[cE.value];
      filterData();
    };
  };

  const onHide = (event) => {
    document.querySelector('.esios-unidades__tabla__input').value = '';
    document
      .querySelector('.esios-unidades__button-remove')
      .classList.add('hidden');
    filterForm.query = '';
    filterData();
  };
  // fin de funciones de filtrado

  const filterData = () => {
    let filtered = data.filter((item) =>
      item[i18n.t('description')]
        .toLowerCase()
        .includes(filterForm.query.toLowerCase())
    );

    const filterQuery = (column) => {
      let filter = data.filter((item) =>
        item[i18n.t(column)]
          .toLowerCase()
          .includes(filterForm.query.toLowerCase())
      );
      filtered = [...filter, ...filtered];
    };

    const filterSelect = (select, attribute) => {
      if (select == 'maximum_power_capacity MW') {
        filterForm[attribute]
          ? (filtered = filtered.filter(
              (item) =>
                item[i18n.t(select)].toLowerCase() == filterForm[attribute]
            ))
          : '';
      } else {
        filtered = filtered.filter((item) =>
          item[i18n.t(select)]
            .toLowerCase()
            .includes(filterForm[attribute].toLowerCase())
        );
      }
    };

    filterQuery('description');
    filterQuery('eic-code');
    filterQuery('UF-code');
    filterQuery('production_type');
    filterQuery('linking_with_SM');
    filterQuery('pairing_with_UP');
    filterQuery('maximum_power_capacity_MW');

    filterSelect('production_type', 'tipoproduccion');
    filterSelect('maximum_power_capacity_MW', 'potencia');
    filterSelect('linking_with_SM', 'vinculacionsm');
    filterSelect('pairing_with_UP', 'vinculacionup');

    var hash = {};
    filtered = filtered.filter(function (current) {
      var exists = !hash[current[i18n.t('UF-code')]];
      hash[current[i18n.t('UF-code')]] = true;
      return exists;
    });

    dataPaginated = filtered.slice(
      (pagination.page - 1) * pagination.pagination,
      pagination.page * pagination.pagination
    );

    const columns = [
      {
        name: i18n.t('UF-code'),
        field: i18n.t('UF-code'),
      },
      {
        name: i18n.t('eic-code'),
        field: i18n.t('eic-code'),
      },
      {
        name: i18n.t('description'),
        field: i18n.t('description'),
      },
      {
        name: i18n.t('maximum_power_capacity_MW'),
        field: i18n.t('maximum_power_capacity_MW'),
      },
      {
        name: i18n.t('production_type'),
        field: i18n.t('production_type'),
      },
      {
        name: i18n.t('linking_with_SM'),
        field: i18n.t('linking_with_SM'),
      },
      {
        name: i18n.t('pairing_with_UP'),
        field: i18n.t('pairing_with_UP'),
      },
    ];
    componentListPrintView.render('print-list', dataPaginated, columns);
    pagination.total = filtered.length;
    pagination.totalPages = Math.ceil(filtered.length / pagination.pagination);
    renderAll();
    renderTable();
  };

  // filtros de busqueda por fecha, arriba
  const onTypeSearchChange = (event) => {
    searchForm.query = event.target.value;
  };

  const onDateInitChange = (event) => {
    searchForm.date_init = event.target.value;
  };

  const onDateEndChange = (event) => {
    searchForm.date_end = event.target.value;
  };

  // buscar por fecha
  const onSearch = () => {
    searchDataUnidades();
  };

  const getAmbitText = (taxonomy_terms) => {
    const ambito = taxonomy_terms.filter(
      (taxonomy_termsItem) => taxonomy_termsItem.vocabulary_id === 20
    );
    let ambitoText = '';
    ambito.forEach((ambitoItem) => {
      ambitoText += ambitoItem.name + ', ';
    });
    return ambitoText.substring(0, ambitoText.length - 2);
  };

  let preRenderComponent = async () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState(searchForm);
    document.title = `${i18n.t('physical_units')} | ${i18n.t('page_subtitle')}`;
    context.name = state.exampleReducer.name;
    listMarket = [
      {
        type: 'title',
        id: 2,
        title: 'structural_data',
      },
      {
        type: 'list',
        id: 1,
        text: 'participants_auction',
        url: `/${i18n.t('routes.lng')}/${i18n.t(
          'routes.participants_auction'
        )}`,
      },
      {
        type: 'list',
        id: 2,
        text: 'programming_units',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.programming_units')}`,
      },
      {
        type: 'list',
        id: 3,
        text: 'physical_units',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.physical_units')}`,
      },
      {
        type: 'list',
        id: 4,
        text: 'market_subjects',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.market_subjects')}`,
      },
    ];
    columns = [
      {
        title: 'UF-code',
        dataIndex: [i18n.t('UF-code')],
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            if (order === 'asc') {
              if (a[i18n.t('UF-code')] < b[i18n.t('UF-code')]) {
                return -1;
              }
              if (a[i18n.t('UF-code')] > b[i18n.t('UF-code')]) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (a[i18n.t('UF-code')] > b[i18n.t('UF-code')]) {
                return -1;
              }
              if (a[i18n.t('UF-code')] < b[i18n.t('UF-code')]) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
      {
        title: 'eic-code',
        dataIndex: [i18n.t('eic-code')],
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            if (order === 'asc') {
              if (a[i18n.t('eic-code')] < b[i18n.t('eic-code')]) {
                return -1;
              }
              if (a[i18n.t('eic-code')] > b[i18n.t('eic-code')]) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (a[i18n.t('eic-code')] > b[i18n.t('eic-code')]) {
                return -1;
              }
              if (a[i18n.t('eic-code')] < b[i18n.t('eic-code')]) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
      {
        title: 'description',
        dataIndex: [i18n.t('description')],
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            if (order === 'asc') {
              if (a[i18n.t('description')] < b[i18n.t('description')]) {
                return -1;
              }
              if (a[i18n.t('description')] > b[i18n.t('description')]) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (a[i18n.t('description')] > b[i18n.t('description')]) {
                return -1;
              }
              if (a[i18n.t('description')] < b[i18n.t('description')]) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
      {
        title: 'maximum_power_capacity MW',
        dataIndex: [i18n.t('maximum_power_capacity_MW')],
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            let newA = +a[i18n.t('maximum_power_capacity_MW')]
              .replaceAll('.', '')
              .replaceAll(',', '.');
            let newB = +b[i18n.t('maximum_power_capacity_MW')]
              .replaceAll('.', '')
              .replaceAll(',', '.');

            if (order === 'asc') {
              if (newA < newB) {
                return -1;
              }
              if (newA > newB) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (newA > newB) {
                return -1;
              }
              if (newA < newB) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
      {
        title: 'production_type',
        dataIndex: [i18n.t('production_type')],
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            if (order === 'asc') {
              if (a[i18n.t('production_type')] < b[i18n.t('production_type')]) {
                return -1;
              }
              if (a[i18n.t('production_type')] > b[i18n.t('production_type')]) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (a[i18n.t('production_type')] > b[i18n.t('production_type')]) {
                return -1;
              }
              if (a[i18n.t('production_type')] < b[i18n.t('production_type')]) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
      {
        title: 'linking_with_SM',
        dataIndex: [i18n.t('linking_with_SM')],
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            if (order === 'asc') {
              if (a[i18n.t('linking_with_SM')] < b[i18n.t('linking_with_SM')]) {
                return -1;
              }
              if (a[i18n.t('linking_with_SM')] > b[i18n.t('linking_with_SM')]) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (a[i18n.t('linking_with_SM')] > b[i18n.t('linking_with_SM')]) {
                return -1;
              }
              if (a[i18n.t('linking_with_SM')] < b[i18n.t('linking_with_SM')]) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
      {
        title: 'pairing_with_UP',
        dataIndex: [i18n.t('pairing_with_UP')],
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            if (order === 'asc') {
              if (a[i18n.t('pairing_with_UP')] < b[i18n.t('pairing_with_UP')]) {
                return -1;
              }
              if (a[i18n.t('pairing_with_UP')] > b[i18n.t('pairing_with_UP')]) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (a[i18n.t('pairing_with_UP')] > b[i18n.t('pairing_with_UP')]) {
                return -1;
              }
              if (a[i18n.t('pairing_with_UP')] < b[i18n.t('pairing_with_UP')]) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
    ];
  };

  const createFilterModel = (data) => {
    const filters = {
      maxPower: [],
      prodType: [],
      bindingSM: [],
      bindingUP: [],
    };

    const filterAttrs = [
      {
        label: i18n.t('maximum_power_capacity_MW'),
        value: 'maxPower',
        id: 1,
      },
      {
        label: i18n.t('production_type'),
        value: 'prodType',
        id: 2,
      },
      {
        label: i18n.t('linking_with_SM'),
        value: 'bindingSM',
        id: 3,
      },
      {
        label: i18n.t('pairing_with_UP'),
        value: 'bindingUP',
        id: 4,
      },
    ];

    // id, label, options
    data.forEach((element) => {
      for (const attr of filterAttrs) {
        const value = element[attr.label];

        if (!filters[attr.value].some((e) => e.value === value)) {
          filters[attr.value].push({
            label: value,
            value,
          });
        }
      }
    });
    const result = filterAttrs.map((at) => {
      const orderedF = filters[at.value].sort((a, b) => {
        const comma2FloatA = a.label.split('.').join('').split(',').join('.');
        const comma2FloatB = b.label.split('.').join('').split(',').join('.');

        if (isNaN(comma2FloatA) || isNaN(comma2FloatB)) {
          if (a.label < b.label) {
            return -1;
          }
          return 1;
        } else {
          if (Number(comma2FloatA) < Number(comma2FloatB)) {
            return -1;
          }
          return 1;
        }
      });

      return {
        id: at.id,
        label: '',
        options: [
          {
            label: 'show_all',
            value: '',
          },
          ...orderedF,
        ],
      };
    });
    return result;
  };

  // get archives trae por fecha API
  const searchDataUnidades = () => {
    return servicesUnidades.getArchivesUnidades().then((respData) => {
      data = respData;
      pagination.total = respData.length;
      pagination.totalPages = Math.ceil(
        respData.length / pagination.pagination
      );
      select = createFilterModel(data);
      if (respData) {
        document.getElementById('spinner1').style.display = 'none';
      } else {
        return respData;
      }
    });
  };

  let renderComponent = async () => {
    let listDocumen = [
      {
        type: 'title',
        id: 1,
        title: 'how_to_become_a_balance_responsible_party',
      },
      {
        type: 'button',
        id: 1,
        label: 'guides_for_admission',
        url: `/${i18n.t('routes.lng')}/${i18n.t(
          'routes.documentation'
        )}?scope=${i18n.t('routes.structural_data')}`,
      },
      {
        type: 'button',
        id: 2,
        label: 'gde_website_text',
        url: 'https://www.ree.es/es/clientes',
      },
      {
        type: 'text',
        id: 1,
        content: 'market_subject_wannabe_description',
      },
    ];
    try {
      // Render the template to the document

      templateUnidades(
        context,
        handlers,
        listDocumen,
        pageTitle,
        select,
        paginationValues,
        pagination,
        filterForm,
        searchForm,

        listMarket,
        lang
      );
      document
        .querySelector('.esios-layout__print-logo')
        .classList.add('unidades');
      document
        .getElementById('esios-layout-banner')
        .classList.add('hideForPrint');
      await searchDataUnidades();
      filterData();
      const dataCsv = data.map((i) => {
        return [
          i[i18n.t('UF-code')],
          i[i18n.t('eic-code')],
          i[i18n.t('description')],
          i[i18n.t('maximum_power_capacity_MW')],
          i[i18n.t('production_type')],
          i[i18n.t('linking_with_SM')],
          i[i18n.t('pairing_with_UP')],
        ];
      });
      dataCsv.unshift([
        i18n.t('UF-code'),
        i18n.t('eic-code'),
        i18n.t('description'),
        i18n.t('maximum_power_capacity_MW'),
        i18n.t('production_type'),
        i18n.t('linking_with_SM'),
        i18n.t('pairing_with_UP'),
      ]);
      const fileName =
        'export_' +
        i18n.t('routes.physical_units') +
        '_' +
        moment().format('YYYY-MM-DD_HH_mm');
      const buttonsArray = [
        {
          title: 'EXPORTAR JSON',
          action: 'exportJSON',
          data: data.map((i) => ({
            [i18n.t('UF-code')]: i[i18n.t('UF-code')],
            [i18n.t('eic-code')]: i[i18n.t('eic-code')],
            [i18n.t('description')]: i[i18n.t('description')],
            [i18n.t('maximum_power_capacity_MW')]:
              i[i18n.t('maximum_power_capacity_MW')],
            [i18n.t('production_type')]: i[i18n.t('production_type')],
            [i18n.t('linking_with_SM')]: i[i18n.t('linking_with_SM')],
            [i18n.t('pairing_with_UP')]: i[i18n.t('pairing_with_UP')],
          })),
          docName: fileName,
        },
        {
          title: 'EXPORTAR CSV',
          action: 'exportCSV',
          data: dataCsv,
          docName: fileName,
        },
        {
          title: 'EXPORTAR EXCEL',
          action: 'exportExcel',
          data: dataCsv,
        },
        {
          title: 'IMPRIMIR',
          action: 'print',
          url: '#',
        },
      ];
      componentButtons.render(buttonsArray, '#unidad-fisica-buttons');

      document
        .querySelector('.esios-pagination__results')
        .classList.remove('hidden');
      document.querySelector('.unidades-selects').classList.remove('hidden');
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
    const selects = [
      {
        className: '.ree-select-potencia',
        evento: handlers.onSelectChange(0),
      },
      {
        className: '.ree-select-tipoproduccion',
        evento: handlers.onSelectChange(1),
      },
      {
        className: '.ree-select-vinculacionsm',
        evento: handlers.onSelectChange(2),
      },
      {
        className: '.ree-select-vinculacionup',
        evento: handlers.onSelectChange(3),
      },
    ];

    const createSelect = (selects) => {
      selects.map(({ className, evento }) => {
        $(className).selectmenu({
          appendTo: '.init-dropdown ',
        });

        $(className).on('selectmenuchange', function (event, ui) {
          evento(event);
        });
      });
    };
    createSelect(selects);

    document
      .querySelector('.row.is-strech')
      .classList.add('row.is-strech--unidadesfisicas');
    document
      .querySelector('.esios-footer')
      .classList.add('esios-footer--unidadesfisicas');
    document
      .querySelector('.esios-layout__subcontent__wrapper')
      .classList.add('esios-layout__subcontent__wrapper--unidades');
  };

  let renderTable = () => {
    const tableElement = document.getElementById('download-table');
    render(templateTable(columns, dataPaginated, data), tableElement);
    componentPaginator.render(pagination, onPageChange, 'pagination-unidades');
  };

  let renderAll = () => {
    let listDocumen = [
      {
        type: 'title',
        id: 1,
        title: 'how_to_become_a_balance_responsible_party',
      },
      {
        type: 'button',
        id: 1,
        label: 'guides_for_admission',
        // url: '/es/documentacion?scope=Datos%20estructurales',
        url: `/${i18n.t('routes.lng')}/${i18n.t(
          'routes.documentation'
        )}?scope=${i18n.t('routes.structural_data')}`,
      },
      {
        type: 'button',
        id: 2,
        label: 'gde_website_text',
        url: 'https://www.ree.es/es/clientes',
      },
      {
        type: 'text',
        id: 1,
        content: 'market_subject_wannabe_description',
      },
    ];
    templateUnidades(
      context,
      handlers,
      listDocumen,
      pageTitle,
      select,
      paginationValues,
      pagination,
      filterForm,
      searchForm,
      listMarket,
      lang
    );
    document
      .getElementById('esios-layout-banner')
      .classList.add('hideForPrint');
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentUnidades };
