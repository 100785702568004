'use strict';
import i18n from 'i18next';
import { api } from './../../utils/api-interceptor';

let servicesPoliticaPrivacidad = (() => {
  let init = (e) => llamarServicio(e);
  const llamarServicio = async (e) => {
    const urlApi =
      `static_pages?slug=${i18n.t('routes.legal')}&temporal=` +
      Math.floor(Math.random() * 1000);

    try {
      const data = await api(urlApi, { hasLang: true });
      return data.content;
    } catch (error) {}
  };

  return {
    init: (e) => init(e),
  };
})();

export { servicesPoliticaPrivacidad };
