'use strict';
import { html } from 'lit-html';
import { componentLayout } from '../../components/layout/layout.component';
import backImage from '../../assets/images/header-default.jpg';

let templateCodigos = (context, handlers, codigosData) => {
  const { title, body } = codigosData;
  return componentLayout.render(
    html`
      <div class="esios-codigos">
        <h1>${title}</h1>

        ${html([body])}
      </div>
    `,
    {
      image: backImage,
      showFooterImage: true,
    }
  );
};

export { templateCodigos };
