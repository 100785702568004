import i18next from 'i18next';
import { html } from 'lit-html';
import AnalysisHelpModal from './analysis-form-help-modal';
import { componentModal } from '../../../shared/components/modal/modal.component.js';

const AnalysisHelp = () => {
  setTimeout(() => {
    componentModal.render(
      AnalysisHelpModal,
      'video-help',
      'external-modal',
      true,
      true
    );
  }, 10);

  return html`
    <div class="analysis-help" id="video-help">
      <h3 class="analysis-help__title analysis-help__title--font">
        ${i18next.t('help')}
      </h3>
      <p>${i18next.t('see_explicative_video')}</p>
    </div>
  `;
};

export default AnalysisHelp;
