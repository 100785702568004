'use strict';
import i18 from 'i18next';
import { html } from 'lit-html';
import { componentLayout } from '../../components/layout/layout.component';
let templateAnalysisLanding = (context, handlers) => {
  return componentLayout.render(
    html`
      <div class="esios-analysis">
        <div class="esios-analysis__container wrap">
          <div id="mypSubastasWidgetView" class="mypSubastasWidgetView col-sm-12 col-xs-12">
            <p class="esios-analysis__empty-msg">
              ${i18.t('analysis_empty')}
            </p>
      
            <div class="esios-toolbar col-12"></div>
            <div class="row">
              <div id="tabwithcontent"></div>
      
              <div class="esios-analysis__components row">
                <div class="fixed-form col-4 is-analysis-page">
                  <div id="dashboardView">
                    <form class="mod-dashboard">
                      <div id="help_btn"></div>
                  </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div id="external-modal"></div>
    `,
    {
      showBanner: false,
    },
    html``,
    {
      title: i18.t('more'),
      subcontent: html`<div id="document-list-analysis"></div>`,
      showMaps: false,
    }
  );
};

export { templateAnalysisLanding };
