import { render } from 'lit-html';
import { templateListPrintView } from './list-print-view.template';

let componentListPrintView = (() => {
  const renderComponent = (id, data, columns) => {
    render(templateListPrintView(data, columns), document.getElementById(id));
  };

  const init = (id, data, columns) => {
    renderComponent(id, data, columns);
  };

  return {
    render: init,
  };
})();

export { componentListPrintView };
