import { html } from 'lit-html';
import i18n from 'i18next';
import MyEsiosWidget4 from './templates/myesios-widget-4.template';
import MyEsiosWidget3 from './templates/myesios-widget-3.template';
import MyEsiosWidget6 from './templates/myesios-widget-6.template';
import MyEsiosWidget7 from './templates/myesios-widget-7.template';
import MyEsiosWidget8 from './templates/myesios-widget-8.template';
import MyEsiosWidget9 from './templates/myesios-widget-9.template';
import MyEsiosWidget10 from './templates/myesios-widget-10.template';
import MyEsiosWidget19 from './templates/myesios-widget-19.template';
import MyEsiosWidget2 from './templates/myesios-widget-2.template';
import MyEsiosWidget22 from './templates/myesios-widget-22.template';
import MyEsiosWidget23 from './templates/myesios-widget-23.template';
import MyEsiosWidget1 from './templates/myesios-widget-1.template';
import { templateToolbarTime } from '../../../shared/components/toolbarTime/toolbarTime.template';
import MyEsiosWidget20 from './templates/myesios-widget-20.template';
import { templatenoPeninsulares } from '../../noPeninsulares/noPeninsulares.template';
import TemplateEmbedIndicatorsSpotEuropa from '../../embed-indicators/embed-indicators-complex-graphs/embed-indicators-spot-europa.template';
import MyEsiosWidget37 from './templates/myesios-widget-37.template';
import MyEsiosWidget36 from './templates/myesios-widget-36.template';
import MyEsiosWidget41 from './templates/myesios-widget-41.template';
import TemplateEmbedIndicatorsProgramBalance from '../../embed-indicators/embed-indicators-complex-graphs/embed-indicators-program-balance.template';
import MyEsiosWidget38 from './templates/myesios-widget-38.template';
import MyEsiosWidget39 from './templates/myesios-widget-39.template';

const MyEsiosChart = (id) => {
  const getWidgetOptions = (type, widget) => {
    try {
      let widgetsOptions = {
        4: { container: 'widget widget-4' },
        17: {
          timer: 'generation_and_consumption',
          widget: 'generacion-consum esios-myesios__chart',
        },
        18: {
          timer: 'mercade-prices',
          widget: 'generacion-consum esios-myesios__chart',
        },
        21: {
          timer: 'demand-evolution',
          widget: 'evoluciondemanda',
          widgetData:
            'esios-home__widget-legend esios-home__widget-legend--ed-legend highlighted-legend visual-legend',
        },
        25: {
          widget: 'esios-home__widget-legend basic-summary ',
          widgetData: 'esios-home__widget-legend basic-summary ',
          container: 'esios-home__aside',
          containerTimer: 'otherIndicatorsTimeSelector',
        },
        26: {
          timer: 'system-status-time',
          widget: 'homeMetaforaWidgetView',
          container: 'esios-home__aside',
        },
      };
      let widgetOption = widgetsOptions[widget][type]
        ? widgetsOptions[widget][type]
        : '';
      return widgetOption;
    } catch {
      return '';
    }
  };

  let snpData,
    documents,
    snpMoreData,
    titles,
    buttons,
    balearNumbers,
    canaryNumbers,
    melillaNumbers,
    ceutaNumbers;

  const getTemplate = (id) => {
    switch (id) {
      case '1':
        return MyEsiosWidget1();
      case '2':
        return MyEsiosWidget2();
      case '3':
        return MyEsiosWidget3();
      case '4':
        return MyEsiosWidget4();
      case '6':
        return MyEsiosWidget6();
      case '7':
        return MyEsiosWidget7();
      case '8':
        return MyEsiosWidget8();
      case '9':
        return MyEsiosWidget9();
      case '10':
        return MyEsiosWidget10();
      case '12':
        return templatenoPeninsulares(
          snpData,
          documents,
          snpMoreData,
          titles,
          buttons,
          balearNumbers,
          canaryNumbers,
          melillaNumbers,
          ceutaNumbers,
          '12'
        );
      case '13':
        return templatenoPeninsulares(
          snpData,
          documents,
          snpMoreData,
          titles,
          buttons,
          balearNumbers,
          canaryNumbers,
          melillaNumbers,
          ceutaNumbers,
          '13'
        );
      case '14':
        return templatenoPeninsulares(
          snpData,
          documents,
          snpMoreData,
          titles,
          buttons,
          balearNumbers,
          canaryNumbers,
          melillaNumbers,
          ceutaNumbers,
          '14'
        );
      case '15':
        return templatenoPeninsulares(
          snpData,
          documents,
          snpMoreData,
          titles,
          buttons,
          balearNumbers,
          canaryNumbers,
          melillaNumbers,
          ceutaNumbers,
          '15'
        );
      case '19':
        return MyEsiosWidget19();
      case '20':
        return MyEsiosWidget20();
      case '22':
        return MyEsiosWidget22();
      case '23':
        return MyEsiosWidget23();
      case '24':
        return TemplateEmbedIndicatorsProgramBalance();
      case '36':
        return MyEsiosWidget36();
      case '37':
        return MyEsiosWidget37();
      case '38':
        return MyEsiosWidget38();
      case '39':
        return MyEsiosWidget39();
      case '40':
        return TemplateEmbedIndicatorsSpotEuropa();
      case '41':
        return MyEsiosWidget41();
      default:
        return html` <div class="esios-toolbar">
            <div class="esios-toolbar${id}"></div>
            ${getWidgetOptions('timer', id)
              ? templateToolbarTime(getWidgetOptions('timer', id))
              : ''}
            ${getWidgetOptions('containerTimer', id)
              ? html`<div id="${getWidgetOptions('containerTimer', id)}"></div>`
              : ''}
          </div>
          <div id="rect${id}" style="display:none" class="esios-pvpc__no-data">
            > ${i18n.t('no-data-available')}
          </div>
          <div
            id="widget${id}"
            class="widget-${id} ${getWidgetOptions('widget', id)}"
          >
            <div id="spinner${id}" class="spinner-container">
              <div class="spinner"></div>
            </div>
            <div style="display:none" class="esios-myesios__no-data">
              ${i18n.t('no-data-available')}
            </div>
          </div>
          <div
            id="widget${id}Data"
            class="${getWidgetOptions(
              'widgetData',
              id
            )} esios-home__widget-legend highlighted-legend visual-legend"
          ></div>`;
    }
  };

  return html`
    <div
      id="${id == 40 ? 'widget' + id : 'char' + id}"
      class="${getWidgetOptions('container', id)}"
    >
      ${id != 12 && id != 13 && id != 14 && id != 15 && id != 23
        ? html`<h2
            id="title${id}"
            class="esios-home__title esios-myesios__title"
          ></h2>`
        : ''}
      ${getTemplate(id)}
    </div>
  `;
};

export default MyEsiosChart;
