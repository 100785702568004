import { html } from 'lit-html';
import i18n from 'i18next';
import { templateToolbarTime } from '../../../../shared/components/toolbarTime/toolbarTime.template';

const MyEsiosWidget19 = () => {
  return html`
    <div class="esios-home__container-demanda">
      <div id="char19" class="esios-home__demanda">
        <div class="esios-toolbar col-12">
          <div class="esios-toolbar19"></div>
          ${templateToolbarTime('demand')}
        </div>
        <div id="widget19" class="esios-home__widget-legend">
          <div id="spinner19" class="spinner-container">
            <div class="spinner"></div>
          </div>
        </div>
        <div class="mod-action">
          <a
            class="btn-info"
            @click=${(e) => {
              e.preventDefault(),
                (window.location.pathname = `/${i18n.t('routes.lng')}/${i18n.t(
                  'routes.gyc'
                )}/${i18n.t('routes.snp')}`);
            }}
            >${i18n.t('more_info')}</a
          >
        </div>
      </div>
    </div>
  `;
};

export default MyEsiosWidget19;
