'use strict';
import i18n from 'i18next';

let templateChart2 = () => {
  return `
    <div class="widget-legend visual-legend in-line">
            <ul></ul>
        </div>
        <div class="widget-summary gyc-summary">
        <div class="gyc-summary highlighted-summary">
            <div class="in-line"></div>

            <div class="widget-summary-item ">
            <div class="value is-big variacion"></div>
            <div class="label">${i18n.t('variation')}</div>
            </div>
        </div>
    </div>  
  `;
};

export { templateChart2 };
