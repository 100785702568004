'use strict';
import { api } from '../../utils/api-interceptor';
import XLSX from 'xlsx';

let servicesWebCalculadora = (() => {
  let init = () => {};

  const getLumiosChangeDates = async (params) => {
    const { fechaStart, fechaEnd, tab } = params;

    const data = await api(
      `/lumios?start_date=${fechaStart}T00:00:00+0100&end_date=${fechaEnd}T00:00:00+0100`
    );

    if (data.status) {
      return data;
    }

    let url1;
    let url2;
    let url3;
    let url4;
    let url5;
    let url6;
    let newArray = [];

    if (Object.keys(data).length > 2) {
      for (let i = 0; i < Object.keys(data).length; i++) {
        let key = Object.keys(data[i])[0];
        switch (key) {
          case 'PVPC_PBC_P1_DD':
            url1 = decodeURIComponent(data[i].PVPC_PBC_P1_DD.path);
            break;
          case 'PVPC_PBC_P2_DD':
            url2 = decodeURIComponent(data[i].PVPC_PBC_P2_DD.path);
            break;
          case 'PVPC_PBC_P3_DD':
            url3 = decodeURIComponent(data[i].PVPC_PBC_P3_DD.path);
            break;
          case 'PVPC_CYM_P1_DD':
            url4 = decodeURIComponent(data[i].PVPC_CYM_P1_DD.path);
            break;
          case 'PVPC_CYM_P2_DD':
            url5 = decodeURIComponent(data[i].PVPC_CYM_P2_DD.path);
            break;
          case 'PVPC_CYM_P3_DD':
            url6 = decodeURIComponent(data[i].PVPC_CYM_P3_DD.path);
            break;
          default:
            break;
        }
      }
    }

    if (tab === 'tab1' || tab === undefined) {
      if (url1 == undefined || url2 == undefined || url3 == undefined) {
        return { status: true };
      }
      await api(url1).then((data) => {
        return newArray.push(data);
      });

      await api(url2).then((data) => {
        return newArray.push(data);
      });

      await api(url3).then((data) => {
        return newArray.push(data);
      });
    } else {
      if (url4 == undefined || url5 == undefined || url6 == undefined) {
        return { status: true };
      }
      await api(url4).then((data) => {
        return newArray.push(data);
      });

      await api(url5).then((data) => {
        return newArray.push(data);
      });

      await api(url6).then((data) => {
        return newArray.push(data);
      });
    }

    return newArray;
  };

  const getExcelCalculator = (data) => {
    const xlsxBase = {
      Custprops: {},
      Deps: {},
      Directory: {
        workbooks: ['/xl/workbook.xml'],
        sheets: ['/xl/worksheets/sheet1.xml'],
        charts: [],
        dialogs: [],
        macros: [],
        rels: [],
        strs: ['/xl/sharedStrings.xml'],
        comments: [],
        links: [],
        coreprops: ['/docProps/core.xml'],
        extprops: ['/docProps/app.xml'],
        custprops: [],
        themes: ['/xl/theme/theme1.xml'],
        styles: ['/xl/styles.xml'],
        vba: [],
        drawings: [],
        TODO: [],
        xmlns: 'http://schemas.openxmlformats.org/package/2006/content-types',
        calcchain: '',
        sst: '/xl/sharedStrings.xml',
        style: '/xl/styles.xml',
        defaults: {
          bin: 'application/vnd.openxmlformats-officedocument.spreadsheetml.printerSettings',
          rels: 'application/vnd.openxmlformats-package.relationships+xml',
          xml: 'application/xml',
        },
      },
      Props: {
        AppVersion: '16.0300',
        Application: 'Microsoft Excel Online',
        Author: 'REE',
        Category: '',
        Comments: '',
        Company: 'REE',
        ContentStatus: '',
        CreatedDate: '2022-01-24T13:01:10.000Z',
        HyperlinksChanged: false,
        Keywords: '',
        LastAuthor: '',
        LinksUpToDate: false,
        ModifiedDate: '2022-01-25T10:31:00.000Z',
        RevNumber: '',
        ScaleCrop: false,
        SharedDoc: false,
        SheetNames: ['Calculadora_20220124_1520'],
        Subject: '',
        Title: '',
        Worksheets: 1,
      },
      SSF: {
        0: 'General',
        1: '0',
        2: '0.00',
        3: '#,##0',
        4: '#,##0.00',
        9: '0%',
        10: '0.00%',
        11: '0.00E+00',
        12: '# ?/?',
        13: '# ??/??',
        14: 'm/d/yy',
        15: 'd-mmm-yy',
        16: 'd-mmm',
        17: 'mmm-yy',
        18: 'h:mm AM/PM',
        19: 'h:mm:ss AM/PM',
        20: 'h:mm',
        21: 'h:mm:ss',
        22: 'm/d/yy h:mm',
        37: '#,##0 ;(#,##0)',
        38: '#,##0 ;[Red](#,##0)',
        39: '#,##0.00;(#,##0.00)',
        40: '#,##0.00;[Red](#,##0.00)',
        45: 'mm:ss',
        46: '[h]:mm:ss',
        47: 'mmss.0',
        48: '##0.0E+0',
        49: '@',
        56: '"上午/下午 "hh"時"mm"分"ss"秒 "',
        164: '#,##0.00\\ "€"',
      },
      SheetNames: ['Calculadora_20220124_1520'],
      Sheets: {
        Calculadora_20220124_1520: {
          '!margins': {
            bottom: 0.75,
            footer: 0.3,
            header: 0.3,
            left: 0.7,
            right: 0.7,
            top: 0.75,
          },
          '!merges': [
            {
              e: {
                c: 1,
                r: 22,
              },
              s: {
                c: 1,
                r: 21,
              },
            },
            {
              e: {
                c: 2,
                r: 1,
              },
              s: {
                c: 1,
                r: 1,
              },
            },
            {
              e: {
                c: 2,
                r: 2,
              },
              s: {
                c: 1,
                r: 2,
              },
            },
            {
              e: {
                c: 6,
                r: 1,
              },
              s: {
                c: 4,
                r: 1,
              },
            },
            {
              e: {
                c: 6,
                r: 2,
              },
              s: {
                c: 4,
                r: 2,
              },
            },
            {
              e: {
                c: 6,
                r: 21,
              },
              s: {
                c: 4,
                r: 21,
              },
            },
            {
              e: {
                c: 6,
                r: 22,
              },
              s: {
                c: 4,
                r: 22,
              },
            },
          ],
          '!ref': 'B2:G23',
          B2: {
            t: 's',
            v: 'PERIODO DE FACTURACIÓN',
            r: '<t>PERIODO DE FACTURACIÓN</t>',
            h: 'PERIODO DE FACTURACIÓN',
            w: 'PERIODO DE FACTURACIÓN',
          },
          B3: {
            t: 's',
            v: 'A efectos de aplicación de estos términos, el día …excluido y el día de lectura final está incluido.',
            r: '<t>A efectos de aplicación de estos términos, el d…uido y el día de lectura final está incluido.</t>',
            h: 'A efectos de aplicación de estos términos, el día …excluido y el día de lectura final está incluido.',
            w: 'A efectos de aplicación de estos términos, el día …excluido y el día de lectura final está incluido.',
          },
          B5: {
            t: 's',
            v: 'Desde',
            r: '<t>Desde</t>',
            h: 'Desde',
            w: 'Desde',
          },
          B6: {
            t: 's',
            v: '(lectura inicial)',
            r: '<t>(lectura inicial)</t>',
            h: '(lectura inicial)',
            w: '(lectura inicial)',
          },
          B7: {
            t: 's',
            v: 'x',
            r: '<t>x</t>',
            h: 'x',
            w: 'x',
          },
          B9: {
            t: 's',
            v: 'RESULTADO',
            r: '<t>RESULTADO</t>',
            h: 'RESULTADO',
            w: 'RESULTADO',
          },
          B10: {
            t: 's',
            v: 'PUNTA (1,00 €/kWh)',
            r: '<t>PUNTA (1,00 €/kWh)</t>',
            h: 'PUNTA (1,00 €/kWh)',
            w: 'PUNTA (1,00 €/kWh)',
          },
          B11: {
            t: 's',
            v: 'Peaje de acceso y cargos (1 kWh X 37,84 €/kWh)',
            r: '<t>Peaje de acceso y cargos (1 kWh X 37,84 €/kWh)</t>',
            h: 'Peaje de acceso y cargos (1 kWh X 37,84 €/kWh)',
            w: 'Peaje de acceso y cargos (1 kWh X 37,84 €/kWh)',
          },
          B12: {
            t: 's',
            v: 'Coste de la energía (1 kWh X 7,36 €/kWh)',
            r: '<t>Coste de la energía (1 kWh X 7,36 €/kWh)</t>',
            h: 'Coste de la energía (1 kWh X 7,36 €/kWh)',
            w: 'Coste de la energía (1 kWh X 7,36 €/kWh)',
          },
          B14: {
            t: 's',
            v: 'LLANO (1,00 €/kWh)',
            r: '<t>LLANO (1,00 €/kWh)</t>',
            h: 'LLANO (1,00 €/kWh)',
            w: 'LLANO (1,00 €/kWh)',
          },
          B15: {
            t: 's',
            v: 'Peaje de acceso y cargos (5 kWh X 40,69 €/kWh)',
            r: '<t>Peaje de acceso y cargos (5 kWh X 40,69 €/kWh)</t>',
            h: 'Peaje de acceso y cargos (5 kWh X 40,69 €/kWh)',
            w: 'Peaje de acceso y cargos (5 kWh X 40,69 €/kWh)',
          },
          B16: {
            t: 's',
            v: 'Coste de la energía (5 kWh X 171,60 €/kWh)',
            r: '<t>Coste de la energía (5 kWh X 171,60 €/kWh)</t>',
            h: 'Coste de la energía (5 kWh X 171,60 €/kWh)',
            w: 'Coste de la energía (5 kWh X 171,60 €/kWh)',
          },
          B18: {
            t: 's',
            v: 'VALLE (1,00 €/kWh)',
            r: '<t>VALLE (1,00 €/kWh)</t>',
            h: 'VALLE (1,00 €/kWh)',
            w: 'VALLE (1,00 €/kWh)',
          },
          B19: {
            t: 's',
            v: 'Peaje de acceso y cargos (10 kWh X 42,18 €/kWh)',
            r: '<t>Peaje de acceso y cargos (10 kWh X 42,18 €/kWh)</t>',
            h: 'Peaje de acceso y cargos (10 kWh X 42,18 €/kWh)',
            w: 'Peaje de acceso y cargos (10 kWh X 42,18 €/kWh)',
          },
          B20: {
            t: 's',
            v: 'Coste de la energía (10 kWh X 252,08 €/kWh)',
            r: '<t>Coste de la energía (10 kWh X 252,08 €/kWh)</t>',
            h: 'Coste de la energía (10 kWh X 252,08 €/kWh)',
            w: 'Coste de la energía (10 kWh X 252,08 €/kWh)',
          },
          C5: {
            t: 's',
            v: 'Hasta',
            r: '<t>Hasta</t>',
            h: 'Hasta',
            w: 'Hasta',
          },
          C6: {
            t: 's',
            v: '(lectura final)',
            r: '<t>(lectura final)</t>',
            h: '(lectura final)',
            w: '(lectura final)',
          },
          C7: {
            t: 's',
            v: 'x',
            r: '<t>x</t>',
            h: 'x',
            w: 'x',
          },
          D11: {
            t: 's',
            v: 'X',
            r: '<t>X</t>',
            h: 'X',
            w: 'X',
          },
          D12: {
            t: 's',
            v: 'X',
            r: '<t>X</t>',
            h: 'X',
            w: 'X',
          },
          D15: {
            t: 's',
            v: 'x',
            r: '<t>x</t>',
            h: 'x',
            w: 'x',
          },
          D16: {
            t: 's',
            v: 'x',
            r: '<t>x</t>',
            h: 'x',
            w: 'x',
          },
          D19: {
            t: 's',
            v: 'x',
            r: '<t>x</t>',
            h: 'x',
            w: 'x',
          },
          D20: {
            t: 's',
            v: 'x',
            r: '<t>x</t>',
            h: 'x',
            w: 'x',
          },
          E2: {
            t: 's',
            v: 'CONSUMO',
            r: '<t>CONSUMO</t>',
            h: 'CONSUMO',
            w: 'CONSUMO',
          },
          E3: {
            t: 's',
            v: 'Consumo en kWh de los periodos Punta, Llano y Valle.',
            r: '<t>Consumo en kWh de los periodos Punta, Llano y Valle.</t>',
            h: 'Consumo en kWh de los periodos Punta, Llano y Valle.',
            w: 'Consumo en kWh de los periodos Punta, Llano y Valle.',
          },
          E5: {
            t: 's',
            v: 'Punta',
            r: '<t>Punta</t>',
            h: 'Punta',
            w: 'Punta',
          },
          E22: {
            t: 's',
            v: 'Término de facturación de la energía',
            r: '<t>Término de facturación de la energía</t>',
            h: 'Término de facturación de la energía',
            w: 'Término de facturación de la energía',
          },
          E23: {
            t: 's',
            v: 'TOTAL x €',
            r: '<t>TOTAL x €</t>',
            h: 'TOTAL x €',
            w: 'TOTAL x €',
          },
          F5: {
            t: 's',
            v: 'Llano',
            r: '<t>Llano</t>',
            h: 'Llano',
            w: 'Llano',
          },
          F6: {
            t: 's',
            v: 'x',
            r: '<t>x</t>',
            h: 'x',
            w: 'x',
          },
          G5: {
            t: 's',
            v: 'Valle',
            r: '<t>Valle</t>',
            h: 'Valle',
            w: 'Valle',
          },
          G6: {
            t: 's',
            v: data.valle,
            r: '<t>' + data.valle + '</t>',
            h: data.valle,
            w: data.valle,
          },
          G6: {
            t: 's',
            v: 'x',
            r: '<t>x</t>',
            h: 'x',
            w: 'x',
          },
          G10: {
            t: 's',
            v: 'x',
            r: '<t>x</t>',
            h: 'x',
            w: 'x',
          },
          G14: {
            t: 's',
            v: 'x',
            r: '<t>x</t>',
            h: 'x',
            w: 'x',
          },
          G18: {
            t: 's',
            v: 'x',
            r: '<t>x</t>',
            h: 'x',
            w: 'x',
          },
        },
      },
      Strings: [
        {
          t: 'PERIODO DE FACTURACIÓN',
          r: '<t>PERIODO DE FACTURACIÓN</t>',
          h: 'PERIODO DE FACTURACIÓN',
        },
        {
          t: 'CONSUMO',
          r: '<t>CONSUMO</t>',
          h: 'CONSUMO',
        },
        {
          t: 'A efectos de aplicación de estos términos, el día …excluido y el día de lectura final está incluido.',
          r: '<t>A efectos de aplicación de estos términos, el d…uido y el día de lectura final está incluido.</t>',
          h: 'A efectos de aplicación de estos términos, el día …excluido y el día de lectura final está incluido.',
        },
        {
          t: 'Consumo en kWh de los periodos Punta, Llano y Valle.',
          r: '<t>Consumo en kWh de los periodos Punta, Llano y Valle.</t>',
          h: 'Consumo en kWh de los periodos Punta, Llano y Valle.',
        },
        {
          t: 'Desde',
          r: '<t>Desde</t>',
          h: 'Desde',
        },
        {
          t: 'Hasta',
          r: '<t>Hasta</t>',
          h: 'Hasta',
        },
        {
          t: 'Punta',
          r: '<t>Punta</t>',
          h: 'Punta',
        },
        {
          t: 'Llano',
          r: '<t>Llano</t>',
          h: 'Llano',
        },
        {
          t: 'Valle',
          r: '<t>Valle</t>',
          h: 'Valle',
        },
        {
          t: '(lectura inicial)',
          r: '<t>(lectura inicial)</t>',
          h: '(lectura inicial)',
        },
        {
          t: '(lectura final)',
          r: '<t>(lectura final)</t>',
          h: '(lectura final)',
        },
        {
          t: 'x',
          r: '<t>x</t>',
          h: 'x',
        },
        {
          t: 'RESULTADO',
          r: '<t>RESULTADO</t>',
          h: 'RESULTADO',
        },
        {
          t: 'PUNTA (1,00 €/kWh)',
          r: '<t>PUNTA (1,00 €/kWh)</t>',
          h: 'PUNTA (1,00 €/kWh)',
        },
        {
          t: 'Peaje de acceso y cargos (1 kWh X 37,84 €/kWh)',
          r: '<t>Peaje de acceso y cargos (1 kWh X 37,84 €/kWh)</t>',
          h: 'Peaje de acceso y cargos (1 kWh X 37,84 €/kWh)',
        },
        {
          t: 'Coste de la energía (1 kWh X 7,36 €/kWh)',
          r: '<t>Coste de la energía (1 kWh X 7,36 €/kWh)</t>',
          h: 'Coste de la energía (1 kWh X 7,36 €/kWh)',
        },
        {
          t: 'LLANO (1,00 €/kWh)',
          r: '<t>LLANO (1,00 €/kWh)</t>',
          h: 'LLANO (1,00 €/kWh)',
        },
        {
          t: 'Peaje de acceso y cargos (5 kWh X 40,69 €/kWh)',
          r: '<t>Peaje de acceso y cargos (5 kWh X 40,69 €/kWh)</t>',
          h: 'Peaje de acceso y cargos (5 kWh X 40,69 €/kWh)',
        },
        {
          t: 'Coste de la energía (5 kWh X 171,60 €/kWh)',
          r: '<t>Coste de la energía (5 kWh X 171,60 €/kWh)</t>',
          h: 'Coste de la energía (5 kWh X 171,60 €/kWh)',
        },
        {
          t: 'VALLE (1,00 €/kWh)',
          r: '<t>VALLE (1,00 €/kWh)</t>',
          h: 'VALLE (1,00 €/kWh)',
        },
        {
          t: 'Peaje de acceso y cargos (10 kWh X 42,18 €/kWh)',
          r: '<t>Peaje de acceso y cargos (10 kWh X 42,18 €/kWh)</t>',
          h: 'Peaje de acceso y cargos (10 kWh X 42,18 €/kWh)',
        },
        {
          t: 'X',
          r: '<t>X</t>',
          h: 'X',
        },
        {
          t: 'Coste de la energía (10 kWh X 252,08 €/kWh)',
          r: '<t>Coste de la energía (10 kWh X 252,08 €/kWh)</t>',
          h: 'Coste de la energía (10 kWh X 252,08 €/kWh)',
        },
        {
          t: 'Término de facturación de la energía',
          r: '<t>Término de facturación de la energía</t>',
          h: 'Término de facturación de la energía',
        },
        {
          t: 'TOTAL x €',
          r: '<t>TOTAL x €</t>',
          h: 'TOTAL x €',
        },
        {
          t: '',
        },
      ],
      Styles: {
        NumberFmt: [
          'General',
          '0',
          '0.00',
          '#,##0',
          '#,##0.00',
          null,
          null,
          null,
          null,
          '0%',
          '0.00%',
          '0.00E+00',
          '# ?/?',
          '# ??/??',
          'm/d/yy',
          'd-mmm-yy',
          'd-mmm',
          'mmm-yy',
          'h:mm AM/PM',
          'h:mm:ss AM/PM',
          'h:mm',
          'h:mm:ss',
          'm/d/yy h:mm',
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          '#,##0 ;(#,##0)',
          '#,##0 ;[Red](#,##0)',
          '#,##0.00;(#,##0.00)',
          '#,##0.00;[Red](#,##0.00)',
          null,
          null,
          null,
          null,
          'mm:ss',
          '[h]:mm:ss',
          'mmss.0',
          '##0.0E+0',
          '@',
          null,
          null,
          null,
          null,
          null,
          null,
          '"上午/下午 "hh"時"mm"分"ss"秒 "',
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          '#,##0.00\\ "€"',
        ],
        Fonts: [
          {
            sz: 11,
            color: {
              theme: 1,
            },
            name: 'Calibri',
            family: 2,
            scheme: 'minor',
          },
          {
            sz: 10,
            color: {
              rgb: '006699',
            },
            name: 'Inherit',
          },
          {
            bold: 1,
            sz: 14,
            color: {
              rgb: '006699',
            },
            name: 'Arial',
            family: 2,
          },
          {
            sz: 10,
            color: {
              rgb: '444444',
            },
            name: 'Arial',
            family: 2,
          },
          {
            bold: 1,
            sz: 10,
            color: {
              rgb: '444444',
            },
            name: 'Arial',
            family: 2,
          },
          {
            sz: 10,
            name: 'Inherit',
          },
        ],
        Fills: [
          {
            patternType: 'none',
          },
          {
            patternType: 'gray125',
          },
        ],
        Borders: [{}, {}, {}, {}, {}, {}, {}],
        CellXf: [
          {
            numFmtId: 0,
            numfmtid: '0',
            fontId: 0,
            fontid: '0',
            fillId: 0,
            fillid: '0',
            borderId: 0,
            borderid: '0',
            xfId: 0,
            xfid: '0',
          },
          {
            numFmtId: 0,
            numfmtid: '0',
            fontId: 2,
            fontid: '2',
            fillId: 0,
            fillid: '0',
            borderId: 0,
            borderid: '0',
            xfId: 0,
            xfid: '0',
            applyFont: true,
            applyfont: '1',
            applyAlignment: true,
            applyalignment: '1',
            alignment: {
              vertical: 'center',
              wrapText: true,
            },
          },
          {
            numFmtId: 0,
            numfmtid: '0',
            fontId: 3,
            fontid: '3',
            fillId: 0,
            fillid: '0',
            borderId: 0,
            borderid: '0',
            xfId: 0,
            xfid: '0',
            applyFont: true,
            applyfont: '1',
            applyAlignment: true,
            applyalignment: '1',
            alignment: {
              vertical: 'center',
              wrapText: true,
            },
          },
          {
            numFmtId: 0,
            numfmtid: '0',
            fontId: 1,
            fontid: '1',
            fillId: 0,
            fillid: '0',
            borderId: 0,
            borderid: '0',
            xfId: 0,
            xfid: '0',
            applyFont: true,
            applyfont: '1',
            applyAlignment: true,
            applyalignment: '1',
            alignment: {
              vertical: 'top',
              wrapText: true,
            },
          },
          {
            numFmtId: 14,
            numfmtid: '14',
            fontId: 0,
            fontid: '0',
            fillId: 0,
            fillid: '0',
            borderId: 0,
            borderid: '0',
            xfId: 0,
            xfid: '0',
            applyNumberFormat: true,
            applynumberformat: '1',
          },
          {
            numFmtId: 0,
            numfmtid: '0',
            fontId: 3,
            fontid: '3',
            fillId: 0,
            fillid: '0',
            borderId: 0,
            borderid: '0',
            xfId: 0,
            xfid: '0',
            applyFont: true,
            applyfont: '1',
            applyAlignment: true,
            applyalignment: '1',
            alignment: {
              vertical: 'center',
              horizontal: 'left',
              indent: '1',
              wrapText: true,
            },
          },
          {
            numFmtId: 0,
            numfmtid: '0',
            fontId: 5,
            fontid: '5',
            fillId: 0,
            fillid: '0',
            borderId: 0,
            borderid: '0',
            xfId: 0,
            xfid: '0',
            applyFont: true,
            applyfont: '1',
            applyAlignment: true,
            applyalignment: '1',
            alignment: {
              vertical: 'top',
              wrapText: true,
            },
          },
          {
            numFmtId: 0,
            numfmtid: '0',
            fontId: 2,
            fontid: '2',
            fillId: 0,
            fillid: '0',
            borderId: 0,
            borderid: '0',
            xfId: 0,
            xfid: '0',
            applyFont: true,
            applyfont: '1',
            applyAlignment: true,
            applyalignment: '1',
            alignment: {
              vertical: 'center',
              horizontal: 'left',
              wrapText: true,
            },
          },
          {
            numFmtId: 0,
            numfmtid: '0',
            fontId: 3,
            fontid: '3',
            fillId: 0,
            fillid: '0',
            borderId: 0,
            borderid: '0',
            xfId: 0,
            xfid: '0',
            applyFont: true,
            applyfont: '1',
            applyAlignment: true,
            applyalignment: '1',
            alignment: {
              vertical: 'center',
              horizontal: 'left',
              wrapText: true,
            },
          },
          {
            numFmtId: 164,
            numfmtid: '164',
            fontId: 3,
            fontid: '3',
            fillId: 0,
            fillid: '0',
            borderId: 0,
            borderid: '0',
            xfId: 0,
            xfid: '0',
            applyNumberFormat: true,
            applynumberformat: '1',
            applyFont: true,
            applyfont: '1',
            applyAlignment: true,
            applyalignment: '1',
            alignment: {
              vertical: 'center',
              wrapText: true,
            },
          },
          {
            numFmtId: 164,
            numfmtid: '164',
            fontId: 0,
            fontid: '0',
            fillId: 0,
            fillid: '0',
            borderId: 0,
            borderid: '0',
            xfId: 0,
            xfid: '0',
            applyNumberFormat: true,
            applynumberformat: '1',
          },
          {
            numFmtId: 0,
            numfmtid: '0',
            fontId: 4,
            fontid: '4',
            fillId: 0,
            fillid: '0',
            borderId: 0,
            borderid: '0',
            xfId: 0,
            xfid: '0',
            applyFont: true,
            applyfont: '1',
            applyAlignment: true,
            applyalignment: '1',
            alignment: {
              vertical: 'center',
              horizontal: 'center',
              wrapText: true,
            },
          },
          {
            numFmtId: 0,
            numfmtid: '0',
            fontId: 3,
            fontid: '3',
            fillId: 0,
            fillid: '0',
            borderId: 0,
            borderid: '0',
            xfId: 0,
            xfid: '0',
            applyFont: true,
            applyfont: '1',
            applyAlignment: true,
            applyalignment: '1',
            alignment: {
              vertical: 'center',
              wrapText: true,
            },
          },
          {
            numFmtId: 0,
            numfmtid: '0',
            fontId: 2,
            fontid: '2',
            fillId: 0,
            fillid: '0',
            borderId: 0,
            borderid: '0',
            xfId: 0,
            xfid: '0',
            applyFont: true,
            applyfont: '1',
            applyAlignment: true,
            applyalignment: '1',
            alignment: {
              vertical: 'center',
              horizontal: 'left',
              wrapText: true,
            },
          },
          {
            numFmtId: 0,
            numfmtid: '0',
            fontId: 3,
            fontid: '3',
            fillId: 0,
            fillid: '0',
            borderId: 0,
            borderid: '0',
            xfId: 0,
            xfid: '0',
            applyFont: true,
            applyfont: '1',
            applyAlignment: true,
            applyalignment: '1',
            alignment: {
              vertical: 'center',
              horizontal: 'left',
              wrapText: true,
            },
          },
          {
            numFmtId: 0,
            numfmtid: '0',
            fontId: 3,
            fontid: '3',
            fillId: 0,
            fillid: '0',
            borderId: 1,
            borderid: '1',
            xfId: 0,
            xfid: '0',
            applyFont: true,
            applyfont: '1',
            applyBorder: true,
            applyborder: '1',
            applyAlignment: true,
            applyalignment: '1',
            alignment: {
              vertical: 'center',
              horizontal: 'center',
              wrapText: true,
            },
          },
          {
            numFmtId: 0,
            numfmtid: '0',
            fontId: 3,
            fontid: '3',
            fillId: 0,
            fillid: '0',
            borderId: 2,
            borderid: '2',
            xfId: 0,
            xfid: '0',
            applyFont: true,
            applyfont: '1',
            applyBorder: true,
            applyborder: '1',
            applyAlignment: true,
            applyalignment: '1',
            alignment: {
              vertical: 'center',
              horizontal: 'center',
              wrapText: true,
            },
          },
          {
            numFmtId: 0,
            numfmtid: '0',
            fontId: 3,
            fontid: '3',
            fillId: 0,
            fillid: '0',
            borderId: 3,
            borderid: '3',
            xfId: 0,
            xfid: '0',
            applyFont: true,
            applyfont: '1',
            applyBorder: true,
            applyborder: '1',
            applyAlignment: true,
            applyalignment: '1',
            alignment: {
              vertical: 'center',
              horizontal: 'center',
              wrapText: true,
            },
          },
          {
            numFmtId: 0,
            numfmtid: '0',
            fontId: 4,
            fontid: '4',
            fillId: 0,
            fillid: '0',
            borderId: 4,
            borderid: '4',
            xfId: 0,
            xfid: '0',
            applyFont: true,
            applyfont: '1',
            applyBorder: true,
            applyborder: '1',
            applyAlignment: true,
            applyalignment: '1',
            alignment: {
              vertical: 'center',
              horizontal: 'center',
              wrapText: true,
            },
          },
          {
            numFmtId: 0,
            numfmtid: '0',
            fontId: 4,
            fontid: '4',
            fillId: 0,
            fillid: '0',
            borderId: 5,
            borderid: '5',
            xfId: 0,
            xfid: '0',
            applyFont: true,
            applyfont: '1',
            applyBorder: true,
            applyborder: '1',
            applyAlignment: true,
            applyalignment: '1',
            alignment: {
              vertical: 'center',
              horizontal: 'center',
              wrapText: true,
            },
          },
          {
            numFmtId: 0,
            numfmtid: '0',
            fontId: 4,
            fontid: '4',
            fillId: 0,
            fillid: '0',
            borderId: 6,
            borderid: '6',
            xfId: 0,
            xfid: '0',
            applyFont: true,
            applyfont: '1',
            applyBorder: true,
            applyborder: '1',
            applyAlignment: true,
            applyalignment: '1',
            alignment: {
              vertical: 'center',
              horizontal: 'center',
              wrapText: true,
            },
          },
        ],
      },
      Themes: {},
      Workbook: {
        AppVersion: {
          appName: 'xl',
          appname: 'xl',
          lastEdited: '7',
          lastedited: '7',
          lowestEdited: '6',
          lowestedited: '6',
          rupBuild: '24923',
          rupbuild: '24923',
        },
        WBProps: {
          allowRefreshQuery: false,
          autoCompressPictures: true,
          backupFile: false,
          checkCompatibility: false,
          CodeName: '',
          date1904: false,
          defaultThemeVersion: 0,
          filterPrivacy: false,
          hidePivotFieldList: false,
          promptedSolutions: false,
          publishItems: false,
          refreshAllConnections: false,
          saveExternalLinkValues: true,
          showBorderUnselectedTables: true,
          showInkAnnotation: true,
          showObjects: 'all',
          showPivotChartFilter: false,
          updateLinks: 'userSet',
        },
        WBView: [
          {
            xWindow: '0',
            xwindow: '0',
            yWindow: '0',
            ywindow: '0',
            windowWidth: '28800',
            windowwidth: '28800',
            windowHeight: '12624',
            windowheight: '12624',
            uid: '{00000000-000D-0000-FFFF-FFFF00000000}',
            activeTab: 0,
            autoFilterDateGrouping: true,
            firstSheet: 0,
            minimized: false,
            showHorizontalScroll: true,
            showSheetTabs: true,
            showVerticalScroll: true,
            tabRatio: 600,
            visibility: 'visible',
          },
        ],
        Sheets: [
          {
            name: 'Calculadora_20220124_1520',
            sheetId: '2',
            sheetid: '2',
            id: 'rId1',
            Hidden: 0,
          },
        ],
        CalcPr: {
          calcId: '191028',
          calcid: '191028',
          calcCompleted: 'true',
          calcMode: 'auto',
          calcOnSave: 'true',
          concurrentCalc: 'true',
          fullCalcOnLoad: 'false',
          fullPrecision: 'true',
          iterate: 'false',
          iterateCount: '100',
          iterateDelta: '0.001',
          refMode: 'A1',
        },
        Names: [],
        xmlns: 'http://schemas.openxmlformats.org/spreadsheetml/2006/main',
        Views: [{}],
      },
    };
    XLSX.writeFile(xlsxBase, 'example.xlsx');
  };

  return {
    init: init,
    getLumiosChangeDates: (params) => getLumiosChangeDates(params),
    getExcelCalculator: getExcelCalculator,
  };
})();

export { servicesWebCalculadora };
