'use strict';
import i18n from 'i18next';
import moment from 'moment-timezone';
import { html } from 'lit-html';
import backImage from '../../assets/images/header-default.jpg';
import { componentLayout } from '../../components/layout/layout.component';
import { templateBoxDocument } from '../../shared/components/box-documents/box-documents.template';
import { templateBoxMarket } from '../../shared/components/box-market/box-market.template';
import { getColour } from '../../shared/components/chart/chart-helpers';
import { templateToolbarTime } from '../../shared/components/toolbarTime/toolbarTime.template';
import { getUrlParams } from '../../utils/url';
import { templateBoxMarketColumn } from '../../shared/components/box-market-column/box-market-column.template';

let templateIntercambiosInternacionales = (
  handlers,
  buttons,
  documents,
  listMarket,
  lang
) => {
  const { handleSelect } = handlers;
  let selectDetails = {
    values: [
      {
        label: i18n.t('france'),
        value: 0,
      },
      {
        label: i18n.t('portugal'),
        value: 1,
      },
      {
        label: i18n.t('morocco'),
        value: 2,
      },
      {
        label: i18n.t('andorra'),
        value: 3,
      },
    ],
    selected: i18n.t('france'),
    onClick: handleSelect,
  };
  // let params = getUrlParams();
  //let sdate = params?.date ? `?date=${params?.date}` : '';
  const sdate = '';
  $(document).on('change', '.intercambios  #datepicker', function (e) {
    let date = moment(
      document.getElementById('datepicker').value,
      'DD / MM / YYYY'
    )
      .tz('europe/Madrid')
      .format('DD-MM-YYYY');
    sdate = date ? `?date=${date}` : '';
  });
  return componentLayout.render(
    html`
      <div class="esios-intercambios">
        <div class="esios-intercambios__row">
          <div id="widget40">
            <h1 class="esios-market-prices__title">
              ${i18n.t('Day_ahead_market')}
            </h1>
            <div class="esios-toolbar">
              <div class="esios-toolbar1"></div>
              ${templateToolbarTime('mercade-spot')}
            </div>
            <div class="first__row">
              <div
                id="char40Container"
                class="esios-intercambios__container esios-intercambios__widget-container"
              >
                <div
                  id="spinner1"
                  style="display: flex;"
                  class="spinner-container"
                >
                  <div class="overlay"></div>
                  <div class="spinner"></div>
                </div>
                <div id="char40" class="esios-chart chari-chart int-exchange">
                  <div
                    style="display:none"
                    id="rect1"
                    class="esios-pvpc__no-data"
                  >
                    ${i18n.t('no-data-available')}
                  </div>
                </div>
              </div>
              <div
                id="map40"
                class="esios-analysis__info-chart esios-intercambios__container"
              >
                <h2 class="esios-market-prices__title">
                  ${i18n.t('Day_ahead_market')}
                </h2>
                <div class="esios-toolbar">
                  <div class="esios-toolbar4"></div>
                  ${templateToolbarTime('mercade-spot-map')}
                  <!-- <div id="intMercadeSpotSelector"></div> -->
                </div>
                <div
                  id="spinner2"
                  style="display: flex;"
                  class="spinner-container"
                >
                  <div class="overlay"></div>
                  <div class="spinner"></div>
                </div>
                <div
                  style="display:none"
                  id="rect2"
                  class="esios-pvpc__no-data"
                >
                  ${i18n.t('no-data-available')}
                </div>

                <div id="geo-map-container-600"></div>
                <div
                  id="geo-legend"
                  class="mod-analysis-map-legend is-hidden"
                ></div>
              </div>
            </div>
          </div>

          <div id="widget46" class="">
            <div id="widget39" class="">
              <h1 class="esios-market-prices__title mb-2">
                ${i18n.t('hourly_balances_by_border')}
              </h1>
              <div class="esios-toolbar col-12 mb-2">
                <div class="esios-toolbar2" style="margin-top: -11px"></div>
                ${templateToolbarTime('horario-frontera')}
              </div>
              <div class="graph-wrapper">
                <div id="spinner39" class="spinner-container">
                  <div class="white-overlay"></div>
                  <div class="spinner"></div>
                </div>
                <div
                  id="char39"
                  class="esios-chart esios-intercambios__no-data-graphic"
                  style="display:none"
                >
                  <div id="rect1" class="esios-pvpc__no-data">
                    ${i18n.t('no-data-available')}
                  </div>
                </div>
                <div
                  id="chart-bar-line"
                  class="chari-chart"
                  style="min-height: initial!important;"
                ></div>
                <div class="legends esios-intercambios__container-data">
                  <div
                    class="legend esios-intercambios__label esios-intercambios__legend"
                    style="display: flex; justify-content: space-between;"
                  >
                    <div>
                      <span
                        class="circle"
                        style="background: ${getColour(0)}"
                      ></span>
                      ${i18n.t('balances')} ${i18n.t('france')}
                    </div>

                    <div class="valcontainer legendbar-10015">
                      <div class="value"></div>
                      <small class="magnitud"></small>
                    </div>
                  </div>

                  <div
                    class="legend esios-intercambios__label esios-intercambios__legend"
                    style="display: flex; justify-content: space-between;"
                  >
                    <div>
                      <span
                        class="circle"
                        style="background: ${getColour(1)}"
                      ></span>
                      ${i18n.t('balances')} ${i18n.t('portugal')}
                    </div>

                    <div class="valcontainer legendbar-10014">
                      <div class="value"></div>
                      <small class="magnitud"></small>
                    </div>
                  </div>

                  <div
                    class="legend esios-intercambios__label esios-intercambios__legend"
                    style="display: flex; justify-content: space-between;"
                  >
                    <div>
                      <span
                        class="circle"
                        style="background: ${getColour(2)}"
                      ></span>
                      ${i18n.t('balances')} ${i18n.t('morocco')}
                    </div>

                    <div class="valcontainer legendbar-10016">
                      <div class="value"></div>
                      <small class="magnitud"></small>
                    </div>
                  </div>

                  <div
                    class="legend esios-intercambios__label esios-intercambios__legend"
                    style="display: flex; justify-content: space-between;"
                  >
                    <div>
                      <span
                        class="circle"
                        style="background: ${getColour(3)}"
                      ></span>
                      ${i18n.t('balances')} ${i18n.t('andorra')}
                    </div>

                    <div class="valcontainer legendbar-10017">
                      <div class="value"></div>
                      <small class="magnitud"></small>
                    </div>
                  </div>

                  <div
                    class="legend esios-intercambios__label esios-intercambios__legend"
                    style="display: flex; justify-content: space-between;"
                  >
                    <div>
                      <span class="line" style="background: #000000"></span>
                      ${i18n.t('total-balance')}
                    </div>

                    <div class="valcontainer legendbar-10027">
                      <div class="value"></div>
                      <small class="magnitud"></small>
                    </div>
                  </div>
                </div>

                <div class="mod-action">
                  <a
                    id="snp-button-more"
                    href="/${i18n.t('routes.lng')}/${i18n.t(
                      'routes.saldo-frontera'
                    )}${sdate}"
                    class="btn-info"
                    >${i18n.t('more_info')}</a
                  >
                </div>
              </div>
            </div>
            <div class="programmed__interconections">
              <h2 class="esios-market-prices__title">
                ${i18n.t('balance_programmed_by_interconnection')}
              </h2>
              <div class="esios-toolbar col-12 mb-1">
                <div class="esios-toolbar3" style="margin-top: -15px"></div>
                ${templateToolbarTime('horario-frontera-map')}
              </div>
              <div id="map-wrapper">
                <div id="spinner38" class="spinner-container">
                  <div class="white-overlay"></div>
                  <div class="spinner"></div>
                </div>
                <div
                  id="char38"
                  class="esios-chart esios-intercambios__no-data-graphic"
                  style="display:none"
                >
                  <div id="rect1" class="esios-pvpc__no-data">
                    ${i18n.t('no-data-available')}
                  </div>
                </div>
                <div id="map-main-container"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="row intexchange mt-3">
          <div
            id="mypSubastasWidgetView"
            class="mypSubastasWidgetView col-sm-12 col-xs-12"
          >
            <div id="rect1" class="esios-pvpc__no-data hidden">
              ${i18n.t('no-data-available')}
            </div>
            <h1 class="esios-market-prices__title">
              ${i18n.t('explicit_Capacity_Auctions')}
            </h1>
            <div class="esios-toolbar col-12">
              <div class="esios-toolbar5"></div>
              <div id="mypSubastasWidgetViewSelector"></div>
            </div>
            <div id="spinner10" class="spinner-container">
              <div class="spinner"></div>
            </div>
            <div class="row content">
              <div id="tabwithcontent"></div>

              ${['1', '2', '3', '4'].map((element) => {
                return html` <div
                  id="tab-myp-content${element}"
                  class="tab-myp-content tab__content"
                ></div>`;
              })}

              <a
                id="calendar-button"
                href="${i18n.t('routes.lng')}/${i18n.t(
                  'routes.auctions_calendar'
                )}#fra"
                class="esios-market-prices__btn-info center"
                >${i18n.t('auctions_calendar')}</a
              >
            </div>
            <div class="col-xs-24 col-sm-24 mt-5"></div>
          </div>
        </div>
      </div>
    `,
    {
      text: '',
      image: backImage,
      showBanner: true,
    },
    html``,
    {
      title: i18n.t('more'),
      subcontent: html`
        <ul class="esios-also__list row" id="more-ii"></ul>

        <div id="document-list-ii"></div>

        <div class="esios-documents">
          <div class="grid-xs-24 grid-xs-24--gc documents-container">
            <div class="mod-documents mod-documents--gc">
              ${templateBoxDocument(documents, buttons)}
            </div>
            <div class="mod-documents mod-documents--gc">
              ${templateBoxMarketColumn(listMarket)}
              <div class="mod-buttons mod-buttons--gc">
              </br>
              ${
                lang == 'es'
                  ? html` <a
                      class="mod-buttons__btn"
                      href="https://www.ree.es/es/clientes"
                      target="_blank"
                      >${i18n.t('gde_website_text')}</a
                    >`
                  : ''
              }
              </div>
            </div>
          </div>
        </div>
      `,
    }
  );
};

export { templateIntercambiosInternacionales };
