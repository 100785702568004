// Smart Render
// This functionality is a patch
// When children are loading and another sibling (for instance) updates because
// it needs overwrite some relevant info from Redux but it is not created yet.
// We need to ensure we are going to re-render this component.

import { render } from 'lit-html';

// NOTE: this is not the most orthodox solution, but it is the best in time

const TIMEOUT = 1000; // 1 second

export const sRender = (content, id, ...args) => {
  const checker = setInterval(checkDOM, 50);
  let currentTime = 0;
  function checkDOM() {
    const selector = document.getElementById(id);
    if (selector) {
      clearInterval(checker);
      if (typeof content === 'function') {
        // render's whim
        render('', selector);
        render(content(...args), selector);
      } else {
        render('', selector);
        render(content, selector);
      }
    }
    if (TIMEOUT <= currentTime) {
      clearInterval(checker);
    }
    currentTime += 50;
  }
};
