'use strict';

/**
 * @description Mercados y precios Component
 * @returns {Object} Devuelve la instancia del componente
 */
import i18n from 'i18next';
import moment from 'moment-timezone';
import { datepickerComponent } from '../../shared/components/datepicker/datepicker.component.js';
import { componentTabs } from '../../shared/components/tabs/tabs.component.js';
import { componentToolbar } from '../../shared/components/toolbar/toolbar.component.js';
import { getUrlParams } from '../../utils/url.js';
import { Utils } from '../../utils/utils.js';
import { servicesWebCalculadora } from './webCalculadora.services';
import { templateWebCalculadora } from './webCalculadora.template';

let componentWebCalculadora = (function () {
  let context = {};
  let handlers = {
    hasData: (data, fechaS) => {
      if (!data[0]?.PVPC_PBC_P1_DD[fechaS]?.Precio) {
        return false;
      }
      if (!data[1]?.PVPC_PBC_P2_DD[fechaS]?.Precio) {
        return false;
      }
      if (!data[2]?.PVPC_PBC_P3_DD[fechaS]?.Precio) {
        return false;
      }
      return true;
    },
  };
  let data;
  let start;
  let tab;
  let minDate = '01 / 06 / 2021';

  let input1, input2, input3;

  let paramsUrl = {
    rate: 'rate1',
    start_date: moment().subtract('1', 'day').format('DD-MM-YYYYTHH:mm'),
    end_date: moment().format('DD-MM-YYYYTHH:mm'),
  };

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let renderSuccess = false;

  /*Accordion*/
  let setAccordion = (anchor) => {
    $(anchor).accordion({
      active: false,
      collapsible: true,
      icons: false,
      heightStyle: 'content',
    });
  };

  const checkUrlParameter = (parameterKey) => {
    if (urlParams.get(parameterKey)) {
      paramsUrl[parameterKey] = urlParams.get(parameterKey);
      return true;
    }
    return false;
  };

  const checkUrlParameterAndSetDefault = (parameterKey) => {
    checkUrlParameter(parameterKey);
    setParameter(parameterKey, paramsUrl[parameterKey]);
  };

  const setParameter = (key, value) => {
    urlParams.set(key, value);
    history.pushState({}, '', window.location.pathname + '?' + urlParams);
  };

  const getValForParameter = (param) => {
    let val = '';
    if (param === 'p1' || param === 'p2' || param === 'p3') {
      let urlVal = urlParams.get(param) || '';
      let tempVal = parseFloat(urlVal.replaceAll('.', ','));
      let isNum = Number.isInteger(tempVal);
      if (isNum) {
        val = parseFloat(urlParams.get(param));
      }
    }

    return val;
  };

  const setValuesCalc = (param, input) => {
    checkUrlParameter(param)
      ? (document.getElementById(input).value = getValForParameter(param))
      : '';
  };

  const clearData = () => {
    let resultshtml1 = document.getElementById('web-calculadora1');
    let resultshtml2 = document.getElementById('web-calculadora2');
    let resultshtml3 = document.getElementById('web-calculadora3');
    resultshtml1.value = 0;
    resultshtml2.value = 0;
    resultshtml3.value = 0;
    setParameter('p1', 0);
    setParameter('p2', 0);
    setParameter('p3', 0);
    if (document.getElementById('P1')) {
      document.getElementById('P1').textContent = 0;
    }
    if (document.getElementById('P1b')) {
      document.getElementById('P1b').textContent = 0;
    }
    if (document.getElementById('P2')) {
      document.getElementById('P2').textContent = 0;
    }
    if (document.getElementById('P2b')) {
      document.getElementById('P2b').textContent = 0;
    }
    if (document.getElementById('P3')) {
      document.getElementById('P3').textContent = 0;
    }
    if (document.getElementById('P3b')) {
      document.getElementById('P3b').textContent = 0;
    }

    if (document.getElementById('B1')) {
      document.getElementById('B1').textContent = '0,00';
    }
    if (document.getElementById('B2')) {
      document.getElementById('B2').textContent = '0,00';
    }
    if (document.getElementById('B3')) {
      document.getElementById('B3').textContent = '0,00';
    }

    if (document.getElementById('D1')) {
      document.getElementById('D1').textContent = '0,00';
    }
    if (document.getElementById('D2')) {
      document.getElementById('D2').textContent = '0,00';
    }
    if (document.getElementById('D3')) {
      document.getElementById('D3').textContent = '0,00';
    }

    if (document.getElementById('F1')) {
      document.getElementById('F1').textContent = '0,00';
    }
    if (document.getElementById('F2')) {
      document.getElementById('F2').textContent = '0,00';
    }
    if (document.getElementById('F3')) {
      document.getElementById('F3').textContent = '0,00';
    }
    if (document.getElementById('TOTAL')) {
      document.getElementById('TOTAL').textContent = '0,00';
    }

    document.getElementById('results').style.display = 'none';
  };

  const updateData = (dataWidget) => {
    let fechaS = $('#datepicker1').val();
    fechaS = fechaS.replace(/\s/g, '');
    let { rate, end_date } = getUrlParams();
    data = dataWidget;
    let dataTemp = [];
    let fakeData = false;
    if (data.status) {
      fakeData = true;
      data = [
        {
          PVPC_PBC_P1_DD: {
            [fechaS]: {
              Precio: '0',
              'Coste Energia': '0',
              'Peaje Acceso': '0',
            },
          },
        },
        {
          PVPC_PBC_P2_DD: {
            [fechaS]: {
              Precio: '0',
              'Coste Energia': '0',
              'Peaje Acceso': '0',
            },
          },
        },
        {
          PVPC_PBC_P3_DD: {
            [fechaS]: {
              Precio: '0',
              'Coste Energia': '0',
              'Peaje Acceso': '0',
            },
          },
        },
      ];
    }
    data.map((el) => {
      for (const [key, value] of Object.entries(el)) {
        for (const [k, val] of Object.entries(value)) {
          for (const [ca, va] of Object.entries(val)) {
            dataTemp.push({
              zone:
                rate === 'rate1'
                  ? 'Península, Baleares y Canarias'
                  : 'Ceuta y Melilla',
              tariff:
                key === 'PVPC_PBC_P1_DD'
                  ? 'Punta'
                  : key === 'PVPC_PBC_P2_DD'
                  ? 'Llano'
                  : 'Valle',
              name: ca,
              start_date: k.split('/').join('-'),
              end_date: end_date.split('T')[0],
              value: va,
            });
          }
        }
      }
    });

    objToolbar[0].calculatorData = true;
    objToolbar[0].widgetData = {
      slug: 'lumios_' + window.location.search.split('?')[1] || 'lumios',
      indicators: [
        {
          print_values: dataTemp,
        },
      ],
    };
    componentToolbar.render(objToolbar[0], '.esios-toolbar1');
    if (fakeData) {
      data = { status: '500' };
    }
    templateWebCalculadora(context, handlers, data, fechaS);
    document.querySelector('.esios-layout').classList.add('layout-calculator');
  };

  let objToolbar;

  let preRenderComponent = async () => {
    document.title = `${i18n.t('lumios')} | ${i18n.t('page_subtitle')}`;
  };

  let renderComponent = async () => {
    checkUrlParameter('rate');
    checkUrlParameterAndSetDefault('rate');
    checkUrlParameterAndSetDefault('start_date');
    checkUrlParameterAndSetDefault('end_date');

    let activeTab = (paramsUrl.rate = 'rate2' ? 2 : 1);
    let miArrayTab = {
      activeTab,
      tabsContent: [
        {
          id: 'rate1',
          text: `${i18n.t('calculadora_tab1')}`,
          class: 'tab-web-calculadora',
          content: `
                      <div class="row">
                        <p class="text-tab">${i18n.t('calculadora_peaje')}:</p>
                        <p class="text-tab"><strong>${i18n.t(
                          'calculadora_punta'
                        )}</strong> ${i18n.t('calculadora_punta_pbc')}.</p>
                        <p class="text-tab"><strong>${i18n.t(
                          'calculadora_llano'
                        )}</strong> ${i18n.t('calculadora_llano_pbc')}.</p>
                        <p class="text-tab"><strong>${i18n.t(
                          'calculadora_valle'
                        )}</strong> ${i18n.t('calculadora_valle_pbc')}.</p>
                      </div>
                    `,
        },
        {
          id: 'rate2',
          text: `${i18n.t('calculadora_tab2')}`,
          class: 'tab-web-calculadora',
          content: `
                      <div class="row">
                        <p class="text-tab">${i18n.t('calculadora_peaje')}:</p>
                        <p class="text-tab"><strong>${i18n.t(
                          'calculadora_punta'
                        )}</strong> ${i18n.t('calculadora_punta_cym')}.</p>
                        <p class="text-tab"><strong>${i18n.t(
                          'calculadora_llano'
                        )}</strong> ${i18n.t('calculadora_llano_cym')}.</p>
                        <p class="text-tab"><strong>${i18n.t(
                          'calculadora_valle'
                        )}</strong> ${i18n.t('calculadora_valle_cym')}.</p>
                      </div>
                    `,
        },
      ],
    };

    const bodyTab = `<div class="row">
                      <div class="col-sm-6">
                        <div class="col-sm-12">
                          <h1>${i18n.t('billing_period')}</h1>
                          <p>${i18n.t('calculadora_tab1_billing')}</p>
                        </div>
                        <div class="consumption-mobile col-sm-12">
                          <h1>${i18n.t('consumption')}</h1>
                          <p>${i18n.t('calculadora_tab1_consumption')}</p><br />
                        </div>
                        <div class="row">
                          <div class="col-sm-6">
                            <label>${i18n.t('from_capitalize')}</label>
                            <p class="text-dates">${i18n.t('initial_read')}</p>
                            <div class="date-selector p-hour1">
                                  <div class="datepicker-mod">
                                    <input
                                      id="datepicker1"
                                      class="datepicker-btn datepicker-abs"
                                      name="datepicker1"
                                      size="2"
                                    />
                                  </div>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <label>${i18n.t('to_capitalize')}</label>
                            <p class="text-dates">${i18n.t('final_read')}</p>
                            <div class="date-selector p-hour1">
                                  <div class="datepicker-mod">
                                    <input
                                      id="datepicker2"
                                      class="datepicker-btn datepicker-abs"
                                      name="datepicker2"
                                    />
                                  </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="consumption-desktop col-sm-12">
                          <h1>${i18n.t('consumption')}</h1>
                          <p>${i18n.t('calculadora_tab1_consumption')}</p><br />
                        </div>
                        <div class="row mobile-space">
                          <div class="col-sm-3">
                            <label class="inputs">Punta</label>
                            <div class="esios-tabla__search-container d-flex">
                              <input
                                id="web-calculadora1"
                                name="text"
                                class="esios-tabla__input"
                              />
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <label class="inputs">Llano</label>
                            <div class="esios-tabla__search-container d-flex">
                              <input
                                id="web-calculadora2"
                                name="text"
                                class="esios-tabla__input"
                              />
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <label class="inputs">Valle</label>
                            <div class="esios-tabla__search-container d-flex">
                              <input
                                id="web-calculadora3"
                                name="text"
                                class="esios-tabla__input"
                              />
                            </div>
                          </div>
                          <div class="col-sm-3">     
                            <div id="supply-buttons1">
                              <button id="lumios-clear" class="icon-delete btn-lumios-borrar">Borrar</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>`;

    templateWebCalculadora(
      context,
      handlers,
      (data = { status: '500' }),
      fechaS
    );

    let tableContent1 = document.getElementById('tab-web-calculadora1');
    tableContent1.innerHTML = miArrayTab.tabsContent[0].content;
    let tableContent2 = document.getElementById('tab-web-calculadora2');
    tableContent2.innerHTML = miArrayTab.tabsContent[1].content;

    /*Accordion content*/
    let accordionTab1 = document.getElementById('accordion-tab-calculadora1');
    accordionTab1.innerHTML = miArrayTab.tabsContent[0].content;
    let accordionTab2 = document.getElementById('accordion-tab-calculadora2');
    accordionTab2.innerHTML = miArrayTab.tabsContent[1].content;

    /*Accordion*/
    let anchorAccordion = document.getElementById('accordion');
    if (anchorAccordion) {
      setAccordion(anchorAccordion);
    }

    objToolbar = [
      {
        id: 1,
        info: true,
        share: true,
        favorite: false,
        analysis: false,
      },
    ];

    objToolbar[0].textModal = `
                <p>${i18n.t('calculadora_info_1')}</p>
                <p>${i18n.t('calculadora_info_2')}</p>
                <p>${i18n.t('calculadora_info_3')}</p>`;

    let tabswithbody = document.getElementById('tabwithbody');

    tabswithbody.innerHTML = bodyTab;

    componentTabs.render(miArrayTab, '#tabwithcontent');

    datepickerComponent.render('1');
    $('#datepicker1').datepicker('option', 'minDate', minDate);
    datepickerComponent.render('2');

    $.datepicker._gotoToday = function (id) {
      $(id).datepicker('setDate', new Date()).datepicker('hide').blur();
    };

    const setEventTabs = (numberTab) => {
      let rate = 'rate' + numberTab;
      document.getElementById(rate).addEventListener('click', () => {
        setParameter('rate', rate);
        clearData();
        updateData(data);
      });
    };
    objToolbar[0].widgetData = data;
    componentToolbar.render(objToolbar[0], '.esios-toolbar1');

    setEventTabs(1);
    setEventTabs(2);

    let startDate = moment(paramsUrl.start_date, 'DD-MM-YYYY')
      .tz('Europe/Madrid')
      .format('DD / MM / YYYY');
    let minEndDate = moment(startDate, 'DD / MM / YYYY')
      .add(1, 'day')
      .format('DD / MM / YYYY');
    let endDate = moment(paramsUrl.end_date, 'DD-MM-YYYY')
      .tz('Europe/Madrid')
      .format('DD / MM / YYYY');
    let maxStartDate = moment(endDate, 'DD / MM / YYYY')
      .subtract(1, 'day')
      .format('DD / MM / YYYY');
    document.getElementById('datepicker1').value = startDate;
    document.getElementById('datepicker2').value = endDate;
    $('#datepicker1').datepicker('option', 'maxDate', maxStartDate);
    $('#datepicker2').datepicker('option', 'minDate', minEndDate);
    $('#datepicker2').datepicker(
      'option',
      'maxDate',
      moment().tz('Europe/Madrid').format('DD / MM / YYYY')
    );

    let fechaS = $('#datepicker1').val();
    fechaS = fechaS.replace(/\s/g, '');
    let fechaStart = moment(fechaS, 'DD/MM/YYYY')
      .tz('Europe/Madrid')
      .format('YYYY-MM-DD');
    let fechaE = $('#datepicker2').val();
    let fechaEnd = moment(fechaE, 'DD/MM/YYYY')
      .tz('Europe/Madrid')
      .format('YYYY-MM-DD');
    data = await servicesWebCalculadora.getLumiosChangeDates({
      fechaStart,
      fechaEnd,
      tab,
    });
    objToolbar[0].widgetData = data;
    componentToolbar.render(objToolbar[0], '.esios-toolbar1');

    updateData(data);

    renderSuccess = true;
  };

  let postRenderComponent = () => {
    document.getElementById('web-calculadora1').value = '0';
    document.getElementById('web-calculadora2').value = '0';
    document.getElementById('web-calculadora3').value = '0';

    let resultshtml = document.getElementById('results');
    let resultshtml1 = document.getElementById('web-calculadora1');
    let resultshtml2 = document.getElementById('web-calculadora2');
    let resultshtml3 = document.getElementById('web-calculadora3');
    let fechaS = $('#datepicker1').val();
    fechaS = fechaS.replace(/\s/g, '');

    templateWebCalculadora(context, handlers, data, fechaS);
    document.getElementById('spinner1').style.display = 'none';

    const puntaCalculate = (number) => {
      document.getElementById('results').style.display = 'block';
      document.getElementById('spinner1').style.display = 'none';
      let puntaAccessAndCharge = document.getElementById('P1');
      let puntaEnergyCost = document.getElementById('P1b');

      if (puntaAccessAndCharge) {
        puntaAccessAndCharge.innerHTML = number;
        puntaEnergyCost.innerHTML = number;

        let numberC1 = document.getElementById('C1').textContent;
        let C1 = parseFloat(numberC1.replace(/,/, '.'));
        let multiplyD1 = C1 * parseInt(number);
        let finalD1 = (document.getElementById('D1').innerHTML =
          number == 0
            ? 0
            : Utils.toNumber(multiplyD1, 2, 'comma') == '0,00'
            ? 0
            : Utils.toNumber(multiplyD1, 2, 'comma'));

        let numberE1 = document.getElementById('E1').textContent;
        let E1 = parseFloat(numberE1.replace(/,/, '.'));
        let multiplyF1 = E1 * parseInt(number);
        let finalF1 = (document.getElementById('F1').innerHTML =
          number == 0
            ? 0
            : Utils.toNumber(multiplyF1, 2, 'comma') == '0,00'
            ? 0
            : Utils.toNumber(multiplyF1, 2, 'comma'));

        let fD1 = finalD1 != 0 && parseFloat(finalD1.replace(/,/, '.'));
        let fF1 = finalF1 != 0 && parseFloat(finalF1.replace(/,/, '.'));

        let sumVal = fD1 + fF1;

        document.getElementById('B1').innerHTML =
          number == 0 ? 0 : Utils.toNumber(sumVal, 2, 'comma');

        let numberB1 = document
          .getElementById('B1')
          .textContent.replace(/,/, '.');
        let numberB2 = document
          .getElementById('B2')
          .textContent.replace(/,/, '.');
        let numberB3 = document
          .getElementById('B3')
          .textContent.replace(/,/, '.');

        let sum =
          parseFloat(numberB1) + parseFloat(numberB2) + parseFloat(numberB3);
        document.getElementById('TOTAL').innerHTML = Utils.toNumber(
          sum,
          2,
          'comma'
        );
      }
    };
    const llanoCalculate = (number) => {
      document.getElementById('results').style.display = 'block';
      document.getElementById('spinner1').style.display = 'none';
      let llanoAccessAndCharge = document.getElementById('P2');
      let llanoEnergyCost = document.getElementById('P2b');
      if (llanoAccessAndCharge) {
        llanoAccessAndCharge.innerHTML = number;
        llanoEnergyCost.innerHTML = number;

        let numberC2 = document.getElementById('C2').textContent;
        let C2 = parseFloat(numberC2.replace(/,/, '.'));
        let multiplyD2 = C2 * parseInt(number);
        let finalD2 = (document.getElementById('D2').innerHTML =
          number == 0
            ? 0
            : Utils.toNumber(multiplyD2, 2, 'comma') == '0,00'
            ? 0
            : Utils.toNumber(multiplyD2, 2, 'comma'));

        let numberE2 = document.getElementById('E2').textContent;
        let E2 = parseFloat(numberE2.replace(/,/, '.'));
        let multiplyF2 = E2 * parseInt(number);
        let finalF2 = (document.getElementById('F2').innerHTML =
          number == 0
            ? 0
            : Utils.toNumber(multiplyF2, 2, 'comma') == '0,00'
            ? 0
            : Utils.toNumber(multiplyF2, 2, 'comma'));

        let fD2 = finalD2 != 0 && parseFloat(finalD2.replace(/,/, '.'));
        let fF2 = finalF2 != 0 && parseFloat(finalF2.replace(/,/, '.'));

        let sumVal = fD2 + fF2;

        document.getElementById('B2').innerHTML =
          number == 0 ? 0 : Utils.toNumber(sumVal, 2, 'comma');

        let numberB1 = document
          .getElementById('B1')
          .textContent.replace(/,/, '.');
        let numberB2 = document
          .getElementById('B2')
          .textContent.replace(/,/, '.');
        let numberB3 = document
          .getElementById('B3')
          .textContent.replace(/,/, '.');
        let sum =
          parseFloat(numberB1) + parseFloat(numberB2) + parseFloat(numberB3);
        document.getElementById('TOTAL').innerHTML = Utils.toNumber(
          sum,
          2,
          'comma'
        );
      }
    };
    const valleCalculate = (number) => {
      document.getElementById('results').style.display = 'block';
      document.getElementById('spinner1').style.display = 'none';
      let valleAccessAndCharge = document.getElementById('P3');
      let valleEnergyCost = document.getElementById('P3b');

      if (valleAccessAndCharge) {
        valleAccessAndCharge.innerHTML = number;
        valleEnergyCost.innerHTML = number;

        let numberC3 = document.getElementById('C3').textContent;
        let C3 = parseFloat(numberC3.replace(/,/, '.'));
        let multiplyD3 = C3 * parseInt(number);
        let finalD3 = (document.getElementById('D3').innerHTML =
          number == 0
            ? 0
            : Utils.toNumber(multiplyD3, 2, 'comma') == '0,00'
            ? 0
            : Utils.toNumber(multiplyD3, 2, 'comma'));
        let numberE3 = document.getElementById('E3').textContent;
        let E3 = parseFloat(numberE3.replace(/,/, '.'));
        let multiplyF3 = E3 * parseInt(number);
        let finalF3 = (document.getElementById('F3').innerHTML =
          number == 0
            ? 0
            : Utils.toNumber(multiplyF3, 2, 'comma') == '0,00'
            ? 0
            : Utils.toNumber(multiplyF3, 2, 'comma'));

        let fD3 = finalD3 != 0 && parseFloat(finalD3.replace(/,/, '.'));
        let fF3 = finalF3 != 0 && parseFloat(finalF3.replace(/,/, '.'));

        let sumVal = fD3 + fF3;

        document.getElementById('B3').innerHTML =
          number == 0 ? 0 : Utils.toNumber(sumVal, 2, 'comma');

        let numberB1 = document
          .getElementById('B1')
          .textContent.replace(/,/, '.');
        let numberB2 = document
          .getElementById('B2')
          .textContent.replace(/,/, '.');
        let numberB3 = document
          .getElementById('B3')
          .textContent.replace(/,/, '.');
        let sum =
          parseFloat(numberB1) + parseFloat(numberB2) + parseFloat(numberB3);
        document.getElementById('TOTAL').innerHTML = Utils.toNumber(
          sum,
          2,
          'comma'
        );
      }
    };

    const getArchives = async () => {
      let fechaS = moment($('#datepicker1').val(), 'DD / MM / YYYY').format(
        'DD/MM/YYYY'
      );
      let fechaStart = moment(fechaS, 'DD/MM/YYYY')
        .tz('Europe/Madrid')
        .format('YYYY-MM-DD');
      let fechaE = $('#datepicker2').val();
      let fechaEnd = moment(fechaE, 'DD/MM/YYYY')
        .tz('Europe/Madrid')
        .format('YYYY-MM-DD');

      let value1 = document.getElementById('web-calculadora1').value;
      let value2 = document.getElementById('web-calculadora2').value;
      let value3 = document.getElementById('web-calculadora3').value;
      servicesWebCalculadora
        .getLumiosChangeDates({
          fechaStart,
          fechaEnd,
          tab,
        })
        .then((start) => {
          updateData(start);
          document.getElementById('spinner1').style.display = 'none';
          if (!start.status) {
            document.getElementById('spinner1').style.display = 'none';
            if (value1 == 0 && value2 == 0 && value3 == 0) {
              document.getElementById('results').style.display = 'none';
            } else {
              templateWebCalculadora(context, handlers, start, fechaS);
              if (document.getElementById('P1')) {
                document.getElementById('P1').textContent = value1;
              }
              if (document.getElementById('P1b')) {
                document.getElementById('P1b').textContent = value1;
              }
              if (document.getElementById('P2')) {
                document.getElementById('P2').textContent = value2;
              }
              if (document.getElementById('P2b')) {
                document.getElementById('P2b').textContent = value2;
              }
              if (document.getElementById('P3')) {
                document.getElementById('P3').textContent = value3;
              }
              if (document.getElementById('P3b')) {
                document.getElementById('P3b').textContent = value3;
              }
              puntaCalculate(resultshtml1.value);
              llanoCalculate(resultshtml2.value);
              valleCalculate(resultshtml3.value);
            }
          }
        });
    };

    let tabsInfoDelete = document.querySelector('.ui-tabs-nav');

    const contentChange = (elemTarget) => {
      let rate;
      if (elemTarget.target.innerText == i18n.t('calculadora_tab2')) {
        tab = 'tab2';
        rate = 'rate2';
      } else {
        tab = 'tab1';
        rate = 'rate1';
      }
      setParameter('rate', rate);
      tabsInfoDelete.click();
      getArchives();
    };

    tabsInfoDelete.addEventListener('click', function (e) {
      $('#web-calculadora1').val(0);
      $('#web-calculadora2').val(0);
      $('#web-calculadora3').val(0);
      let value1 = document.getElementById('web-calculadora1').value;
      let value2 = document.getElementById('web-calculadora2').value;
      let value3 = document.getElementById('web-calculadora3').value;
      if (document.getElementById('P1')) {
        document.getElementById('P1').textContent = value1;
      }
      if (document.getElementById('P1b')) {
        document.getElementById('P1b').textContent = value1;
      }
      if (document.getElementById('P2')) {
        document.getElementById('P2').textContent = value2;
      }
      if (document.getElementById('P2b')) {
        document.getElementById('P2b').textContent = value2;
      }
      if (document.getElementById('P3')) {
        document.getElementById('P3').textContent = value3;
      }
      if (document.getElementById('P3b')) {
        document.getElementById('P3b').textContent = value3;
      }
      puntaCalculate(resultshtml1.value);
      llanoCalculate(resultshtml2.value);
      valleCalculate(resultshtml3.value);
      document.getElementById('results').style.display = 'none';
    });

    /*Accordion*/
    $('#accordion').on('accordionactivate', function (e) {
      contentChange(e);
    });

    setValuesCalc('p1', 'web-calculadora1');
    setValuesCalc('p2', 'web-calculadora2');
    setValuesCalc('p3', 'web-calculadora3');
    let v1 = document.getElementById('web-calculadora1').value;
    let v2 = document.getElementById('web-calculadora2').value;
    let v3 = document.getElementById('web-calculadora3').value;
    if (v1 != 0 || v2 != 0 || v3 != 0) {
      puntaCalculate(v1);
      llanoCalculate(v2);
      valleCalculate(v3);
    }

    $(document).on('change', '#datepicker1', async (e) => {
      let currentDate = document.getElementById('datepicker1').value;
      $('#datepicker2').datepicker(
        'option',
        'minDate',
        moment(currentDate, 'DD / MM / YYYY')
          .add(1, 'day')
          .format('DD / MM / YYYY')
      );

      let startDate =
        moment(currentDate, 'DD/MM/YYYY').format('DD-MM-YYYY') + 'T00:00';
      setParameter('start_date', startDate);

      Utils.spinnerStatus('spinner1', true);
      getArchives();
    });

    $(document).on('change', '#datepicker2', async (e) => {
      let currentDate = document.getElementById('datepicker2').value;
      $('#datepicker1').datepicker(
        'option',
        'maxDate',
        moment(currentDate, 'DD / MM / YYYY')
          .subtract(1, 'day')
          .format('DD / MM / YYYY')
      );

      let endDate =
        moment(currentDate, 'DD/MM/YYYY').format('DD-MM-YYYY') + 'T00:00';
      setParameter('end_date', endDate);
      Utils.spinnerStatus('spinner1', true);
      getArchives();
    });

    resultshtml1.addEventListener('keyup', (event) => {
      let val = parseFloat(event.target.value.replaceAll('.', ','));
      const isNum = Number.isInteger(val);
      if (isNum || event.key == 'Backspace' || event.key == 'Delete') {
        val = parseFloat(event.target.value);
        if (isNaN(val)) {
          val = 0;
        }

        input1 = getUrlParams().p1 || 0;
        resultshtml1.value = val;
        if (val.length != 0) {
          setParameter('p1', val);
        } else {
          urlParams.delete('p1');
          resultshtml1.value = 0;
          input1 = 0;
        }
        puntaCalculate(resultshtml1.value);
      } else {
        resultshtml1.value = input1 || getUrlParams().p1 || 0;
        input1 = resultshtml1.value;
      }
    });

    resultshtml2.addEventListener('keyup', (event) => {
      let val = parseFloat(event.target.value.replaceAll('.', ','));
      const isNum = Number.isInteger(val);
      if (isNum || event.key == 'Backspace' || event.key == 'Delete') {
        val = parseFloat(event.target.value);
        if (isNaN(val)) {
          val = 0;
        }

        input2 = getUrlParams().p2 || 0;
        resultshtml2.value = val;
        if (val.length != 0) {
          setParameter('p2', val);
        } else {
          urlParams.delete('p2');
          resultshtml2.value = 0;
          input2 = 0;
        }
        llanoCalculate(resultshtml2.value);
      } else {
        resultshtml2.value = input2 || getUrlParams().p2 || 0;
        input2 = resultshtml2.value;
      }
    });

    resultshtml3.addEventListener('keyup', (event) => {
      let val = parseFloat(event.target.value.replaceAll('.', ','));
      const isNum = Number.isInteger(val);
      if (isNum || event.key == 'Backspace' || event.key == 'Delete') {
        val = parseFloat(event.target.value);
        if (isNaN(val)) {
          val = 0;
        }

        input3 = getUrlParams().p3 || 0;
        resultshtml3.value = val;
        if (val.length != 0) {
          setParameter('p3', val);
        } else {
          urlParams.delete('p3');
          resultshtml3.value = 0;
          input3 = 0;
        }
        valleCalculate(resultshtml3.value);
      } else {
        resultshtml3.value = input3 || getUrlParams().p3 || 0;
        input3 = resultshtml3.value;
      }
    });

    document.getElementById('lumios-clear').addEventListener('click', () => {
      clearData();
    });
  };

  let init = async () => {
    preRenderComponent();
    // Call render component
    await renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentWebCalculadora };

export function isANumber(evt) {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}
