import i18n from 'i18next';
import { html, render } from 'lit-html';
import moment from 'moment';
import { store } from '../../app.init';
import { datepickerComponent } from '../../shared/components/datepicker/datepicker.component';
import { componentTimeSelector } from '../../shared/components/time-selector/time-selector.component';
import { templateToolbarTime } from '../../shared/components/toolbarTime/toolbarTime.template';
import { charts } from '../../utils/options-charts';
import { getUrlParams, setUrlParam } from '../../utils/url';
import { Utils } from '../../utils/utils';
import { servicesHome } from '../home/home.services';
import MyEsiosWidget3 from '../my-esios/chart/templates/myesios-widget-3.template';
import TemplateEmbedIndicatorsCapacity from './embed-indicators-complex-graphs/embed-indicators-capacity.template';

const TemplateEmbedIndicatorsItemList = (widgetId, title, dateTime) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const dateUrl =
    urlParams.get('date') || moment(new Date()).format('DD-MM-YYYY');

  let currentMinutes10in10 = `${Math.trunc(
    moment().tz('Europe/Madrid').format('mm') / 10
  )}0`;
  let selectTimeDesglose = {
    id: 'selectTimeDesglose',
    currentHour: moment().tz('Europe/Madrid').format('HH'),
    currentMinutes: '00',
    optionsHours: Utils.getArrayOfHours(),
    optionsMinutes: Utils.getArrayOfMinutes(5),
    changeTime: changeSelectValue,
  };

  const checkList = () => {
    switch (title) {
      case 'explicit_Capacity_Auctions':
        return TemplateEmbedIndicatorsCapacity();
      case 'co2_free_generation':
        return MyEsiosWidget3();
      default:
        return html`
          <div id="spinner${widgetId}" class="spinner-container">
            <div class="spinner"></div>
          </div>
          <div
            id="widget${widgetId}"
            class="${widgetId == 26 ? 'esios-home__aside' : ''}"
          ></div>
        `;
    }
  };

  render(
    html`
      <div id="char${widgetId}">
        <div class="row">
          <div class="col-xs-6">
            <h2 class="esios-home__title">${html([i18n.t(title)])}</h2>
          </div>
          <div
            class="col-xs-6 time-sel ${widgetId === 3 ? 'time__selector' : ''}"
          >
            <div class="mod-date-embed">
              ${widgetId === 26 ||
              widgetId === 25 ||
              widgetId === 3 ||
              widgetId === 10 ||
              widgetId === 19
                ? html`<input
                      id="datepicker"
                      class="datepicker-btn mod-date-embed--input-datepicker"
                      readonly="true"
                      size="2"
                      name="datepicker"
                      value=${dateUrl
                        ? moment(dateUrl, 'DD-MM-YYYY').format('DD / MM / YYYY')
                        : moment().format('DD / MM / YYYY')}
                    />
                    |
                    <div
                      class="esios-gd__selector"
                      id="embed-timeSelector"
                    ></div>`
                : templateToolbarTime('system-status-time')}
            </div>
          </div>
        </div>
        ${checkList()}
        <div id="widget${widgetId}Data"></div>
      </div>
    `,
    document.querySelector('#embed-container')
  );
  const commonTreat = (data, selectTime) => {
    document.getElementById(`spinner${widgetId}`).classList.remove('hidden');
    charts[widgetId](data, selectTime);
    let systemDateTime = document.querySelector('#system-status-time');
    if (systemDateTime) {
      systemDateTime.innerHTML = moment()
        .tz('Europe/Madrid')
        .format('DD / MM / YYYY | HH:mm');
    }
    if (widgetId != 10) {
      document.getElementById(`spinner${widgetId}`).classList.add('hidden');
    }
  };
  const changeSelectValue = (value) => {
    document.getElementById(`spinner${widgetId}`).classList.remove('hidden');
    const { id, newValue } = value;
    let hours = newValue.split(':')[0];
    let minutes = newValue.split(':')[1];
    if (id === selectTimeDesglose.id) {
      selectTimeDesglose.optionsHours.map((el) => {
        if (el.value === hours) {
          selectTimeDesglose.currentHour = hours;
          selectTimeDesglose.currentMinutes = minutes;
          componentTimeSelector.render(
            selectTimeDesglose,
            '#embed-timeSelector'
          );
        }
      });
    }
    servicesHome
      .getDataFromWidget(widgetId, {
        lang: state.exampleReducer.currentLang,
        globalState:
          widgetId === '26' ||
          widgetId === '25' ||
          widgetId === '3' ||
          widgetId === '10'
            ? state.exampleReducer
            : {
                pages: {
                  home: {
                    indicatorsTime:
                      selectTimeDesglose.currentHour +
                      ':' +
                      selectTimeDesglose.currentMinutes,
                  },
                },
              },
        date: dateUrl
          ? new Date(
              moment(dateUrl, 'DD-MM-YYYY')
                .set({
                  hour: selectTimeDesglose.currentHour,
                  minute: selectTimeDesglose.currentMinutes,
                })
                .format('MM-DD-YYYY HH:mm')
            )
          : new Date(
              moment(new Date(), 'DD-MM-YYYY')
                .set({
                  hour: selectTimeDesglose.currentHour,
                  minute: selectTimeDesglose.currentMinutes,
                })
                .format('MM-DD-YYYY HH:mm')
            ),
        dateFormat:
          widgetId == '25' || widgetId == '26'
            ? moment(dateUrl, 'DD-MM-YYYY')
                .hours(hours)
                .minutes(minutes)
                .format('DD-MM-YYYYTHH:mm')
            : '',
      })
      .then((data) => {
        if (widgetId != 10) {
          document.getElementById(`spinner${widgetId}`).classList.add('hidden');
        }
        commonTreat(data, selectTimeDesglose);
      });
  };

  if (
    widgetId === 25 ||
    widgetId === 26 ||
    widgetId === 3 ||
    widgetId === 10 ||
    widgetId === 19
  ) {
    let currentHour = moment().tz('Europe/Madrid').format('HH');
    let currentMinutes = '00';
    if (widgetId === 3 || widgetId === 25) {
      currentMinutes = currentMinutes10in10;
    }

    datepickerComponent.render();
    const datePicker = document.querySelector('#datepicker');
    if (moment().format('DD / MM / YYYY') != datePicker.value) {
      currentHour = '00';
      currentMinutes = '00';
    }

    selectTimeDesglose = {
      id: 'selectTimeDesglose',
      currentHour,
      currentMinutes,
      optionsHours: Utils.getArrayOfHours(),
      optionsMinutes: Utils.getArrayOfMinutes(5),
      changeTime: changeSelectValue,
    };
    if (dateUrl) {
      datePicker.value = moment(dateUrl, 'DD-MM-YYYY').format('DD / MM / YYYY');
    }
    componentTimeSelector.render(selectTimeDesglose, '#embed-timeSelector');
  }
  const state = store.getState();

  servicesHome
    .getDataFromWidget(widgetId, {
      lang: state.exampleReducer.currentLang,
      globalState: state.exampleReducer,
      date: dateUrl,
      dateFormat: dateTime,
    })
    .then(async (data) => {
      commonTreat(data, selectTimeDesglose);
    });
};

export default TemplateEmbedIndicatorsItemList;
