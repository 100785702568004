'use strict';

/**
 * @description Help Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { store } from '../../app.init.js';
import { templatenoAccess } from './noAccess.template.js';
import i18n from 'i18next';

let componentnoAccess = (function () {
  let context = {};
  let handlers = {};
  let renderSuccess = false;

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    document.title = `${i18n.t('accessibility')} | ${i18n.t('page_subtitle')}`;
    context.name = state.exampleReducer.name;
  };

  let renderComponent = async () => {
    const state = store.getState();
    try {
      templatenoAccess(context, handlers),
        //document.querySelector("#app")

        (renderSuccess = true);
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentnoAccess };
