'use strict';
import 'regenerator-runtime/runtime';
import i18n from 'i18next';
import { api } from '../../utils/api-interceptor';

let servicesAyuda = (() => {
  let init = (e) => llamarServicio(e);

  const llamarServicio = async (e) => {
    let data = await api(
      `static_pages?slug=${i18n.t('routes.help')}&temporal=` +
        Math.floor(Math.random() * 1000),
      {
        hasLang: true,
      }
    );
    return data.content;
  };

  return {
    init: (e) => init(e),
  };
})();

export { servicesAyuda };
