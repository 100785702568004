'use strict';
import { html } from 'lit-html';
import { componentLayout } from '../../components/layout/layout.component';
import backimage from '../../assets/images/header-default.jpg';

let templatefaq = (contactData) => {
  const { title, body } = contactData;

  return componentLayout.render(
    html`
      <div class="esios-faq ">
        <div class="grid-sm-16 grid-xs-24 ">
          <h1>${title}</h1>

          ${html([body])}
        </div>
      </div>
    `,
    {
      text: '',
      image: backimage,
      showBanner: true,
      showFooterImage: true,
    }
  );
};

export { templatefaq };
