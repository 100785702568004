export const getUrlParams = (url) => {
  if (url) {
    const splittedUrl = url.split('?');
    url = splittedUrl[splittedUrl.length - 1];
  }
  const queryString = url || window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let hasMoreEntries = true;
  const entries = urlParams.entries();
  const result = {};
  while (hasMoreEntries) {
    const { done, value } = entries.next();
    if (value) {
      const [k, v] = value;
      result[k] = v;
    }
    hasMoreEntries = !done;
  }
  return result;
};

export const setUrlParam = (key, value) => {
  const url = new URL(window.location.href);
  const nKey = typeof key === 'object' ? key : { [key]: value };
  const keys = Object.keys(nKey);
  for (let k of keys) {
    url.searchParams.set(k, nKey[k]);
  }
  window.history.pushState({ path: url.href }, '', url.href);
};

export const getSplittedRoute = () => {
  return window.location.pathname.split('/');
};
