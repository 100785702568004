import i18n from 'i18next';
import { html } from 'lit-html';
import { getColour } from '../../../../shared/components/chart/chart-helpers';
import Select from '../../../../shared/components/selects/select';
import {
  renderColumnSelected,
  showTable,
} from '../../../saldo-fronteras/saldoFronteras.component';

const MyEsiosWidget41 = () => {
  let selectDetails = {
    values: [
      {
        label: i18n.t('france'),
        value: 0,
      },
      {
        label: i18n.t('portugal'),
        value: 1,
      },
      {
        label: i18n.t('morocco'),
        value: 2,
      },
      {
        label: i18n.t('andorra'),
        value: 3,
      },
    ],
    selected: i18n.t('france'),
    onClick: (e) => {
      document.querySelectorAll('#widget41 .col-9').forEach((el) => {
        el.style.display = 'none';
      });
      document.querySelector('#widget41 .chart-' + e.value).style.display =
        'block';
      document.querySelectorAll('#widget41 .multi-bar').forEach((el) => {
        el.classList.add('hidden');
      });

      document.querySelectorAll('#widget41 .legends').forEach((el) => {
        el.classList.add('hidden');
      });
      showTable(e.value);

      let tableWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (tableWidth <= 540) {
        renderColumnSelected(0, 'hour');
      }
      let graph = document.querySelector(
        `#widget41 #area-graph-${parseInt(e.value)}`
      );
      let legends = document.querySelector(
        `#widget41 #area-graph-${parseInt(e.value)}-legends`
      );
      graph.classList.remove('hidden');
      legends.classList.remove('hidden');
    },
  };
  return html`
    <div class="esios-toolbar col-12 mb-4">
      <div class="esios-toolbar41"></div>
    </div>
    <div class="esios-intercambios">
      <div id="widget41" class="row">
        <div class="col-12">
          <div class="graph-wrapper">
            <div
              id="char41"
              class="esios-chart esios-intercambios__no-data-graphic"
              style="display:none"
            >
              <div id="rect1" class="esios-pvpc__no-data">
                ${i18n.t('no-data-available')}
              </div>
            </div>
            <div class="esios-intercambios__row">
              <div class="col-3">
                <div class="esios-select">${Select(selectDetails)}</div>
                <div class="legend__container mt-4 mb-4">
                  <div id="area-graph-0-legends" class="legends"></div>
                  <div id="area-graph-1-legends" class="legends hidden"></div>
                  <div id="area-graph-2-legends" class="legends hidden"></div>
                  <div id="area-graph-3-legends" class="legends hidden"></div>
                </div>
              </div>
              <div class="col-9 chart-0">
                <div id="spinner41" class="spinner-container">
                  <div class="white-overlay"></div>
                  <div class="spinner"></div>
                </div>
                <div
                  id="area-graph-0"
                  class="chari-chart multi-bar"
                  style="min-height: initial!important;"
                ></div>
              </div>
              <div class="col-9 chart-1" style="display: none;">
                <div
                  id="area-graph-1"
                  class="chari-chart multi-bar hidden"
                  style="min-height: initial!important;"
                ></div>
              </div>
              <div class="col-9 chart-2" style="display: none;">
                <div
                  id="area-graph-2"
                  class="chari-chart multi-bar hidden"
                  style="min-height: initial!important;"
                ></div>
              </div>
              <div class="col-9 chart-3" style="display: none;">
                <div
                  id="area-graph-3"
                  class="chari-chart multi-bar hidden"
                  style="min-height: initial!important;"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div class="medium">
          <div class="selector-title">
            ${i18n.t('hour').toLocaleUpperCase() || 'HORA'}
          </div>
          <div class="selector-container">
            <div id="mobile__selector"></div>
          </div>
        </div>
        <div class="row esios-balance__row-table">
          <div id="spinner41table" class="spinner-container">
            <div class="white-overlay"></div>
            <div class="spinner"></div>
          </div>
          ${[0, 1, 2, 3].map(
            (el) =>
              html`<div class="tabcontentclass" id="tabwithcontent${el}"></div>`
          )}
        </div>
      </div>
    </div>
  `;
};

export default MyEsiosWidget41;
