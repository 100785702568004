"use strict";

/**
 * @description Select Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { render } from "lit-html";
import { templateSelect } from "./select.template";
import { setSelectedOption } from '../../../state/actions'
import { servicesSelect } from "./select.services";

let componentSelect = (function () {
  let context = {};
  let handlers = {};
  let renderSuccess = false;

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    context.name = state.exampleReducer.name;
    option = state.exampleReducer.option;
  };

  let renderComponent = () => {
    try {
      // Render the template to the document
      render(
        // Define a template
        templateSelect(
          context, 
          handlers),
        document.querySelector(".esios-balance__wrap")
      );
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : "";
  };

  return {
    render: init,
  };
})();

export { componentSelect };
