import i18n from 'i18next';
import { html } from 'lit-html';
import { templateToolbarTime } from '../../../shared/components/toolbarTime/toolbarTime.template';

const TemplateEmbedIndicatorsSpotEuropa = () => {
  return html` ${!window.location.pathname.includes('insertado') &&
    !window.location.pathname.includes('embed')
      ? html` <div class="esios-toolbar">
          <div id="esios-toolbar40" class="esios-toolbar40"></div>
          ${templateToolbarTime('mercade-spot')}
        </div>`
      : ''}
    <div class="esios-intercambios esios-intercambios__row">
      <div
        id="char40Container"
        style="width: 100%"
        class="esios-intercambios__col esios-intercambios__container esios-intercambios__widget-container"
      >
        <div id="spinner40" style="display: flex;" class="spinner-container">
          <div class="overlay"></div>
          <div class="spinner"></div>
        </div>
        <div id="char40" class="esios-chart chari-chart int-exchange">
          <div style="display:none" id="rect1" class="esios-pvpc__no-data">
            ${i18n.t('no-data-available')}
          </div>
        </div>
      </div>
    </div>`;
};

export default TemplateEmbedIndicatorsSpotEuropa;
