'use strict';
import { html } from 'lit-html';
import i18n from 'i18next';
let templateBoxDocument = (documents, buttons, translate = true) => {
  let title = 'documents';
  return html`
    <div class="esios-boxdoc">
      <div class="esios-boxdoc__container">
        <div class="esios-boxdoc__header">
          <div class="esios-boxdoc__title">
            <h2>${i18n.t(title)}</h2>
          </div>
        </div>

        <div class="esios-boxdoc__body">
          <div class="esios-boxdoc__list">
            <ul>
              ${documents.map(
                (item) => html`
                  <li>
                    <span class="esios-boxdoc__icon-document"></span>
                    <a href="${item.link}"
                      >${translate ? i18n.t(item.title) : item.title}</a
                    >
                  </li>
                `
              )}
            </ul>
          </div>
        </div>

        <div class="esios-boxdoc__footer">
          ${buttons.map(
            (element) => html`
              <button type="button" class="esios-boxdoc__button">
                <a
                  class="esios-boxdoc__btn-info"
                  href="${element.url}"
                  target="_self"
                  >${i18n.t(element.label)}</a
                >
              </button>
            `
          )}
        </div>
      </div>
    </div>
  `;
};
export { templateBoxDocument };
