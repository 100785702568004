'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';
import { componentLayout } from '../../components/layout/layout.component';
import { BreadCrumbs } from '../../shared/components/breadcrumbs/breadcrumbs.template';

let templatenoAccess = (context, handlers) => {
  return componentLayout.render(
    html`
      <div class="esios-gd__wrap esios-gd__wrap--analysis">
        <div class="esios-Analisis-movil">
          <div class="grid-xs-24 grid-sm-16">
            <h1 class="esios-Analisis-movil__title">
              ${i18n.t('no_mobile_version')}
            </h1>
            <p class="esios-Analisis-movil__p">${i18n.t('mobile_warning')}</p>
            <div class="esios-Analisis-movil__button">
              <a class="button-start" href="/es"> ${i18n.t('start')} </a>
            </div>
          </div>
        </div>
      </div>
    `,
    {
      showBanner: false,
      showFooterImage: true,
    }
  );
};

export { templatenoAccess };
