import { html } from 'lit-html';
import i18n from 'i18next';
import { templateToolbarTime } from '../../../../shared/components/toolbarTime/toolbarTime.template';

const MyEsiosWidget10 = () => {
  return html`
    <div
      id="mypSubastasWidgetView"
      class="mypSubastasWidgetView col-sm-12 col-xs-12"
    >
      <div class="esios-toolbar col-12">
        <div class="esios-toolbar10"></div>
        <div id="mypSubastasWidgetViewSelector"></div>
      </div>
      <div id="spinner10" class="spinner-container">
        <div class="spinner"></div>
      </div>
      <div class="row">
        <div id="tabwithcontent"></div>

        ${['1', '2', '3', '4'].map((element) => {
          return html` <div
            id="tab-myp-content${element}"
            class="tab-myp-content tab__content"
          ></div>`;
        })}

        <a
          id="calendar-button"
          href="${i18n.t('routes.lng')}/${i18n.t(
            'routes.auctions_calendar'
          )}#fra"
          class="esios-market-prices__btn-info center"
          >${i18n.t('auctions_calendar')}</a
        >
      </div>
      <div class="col-xs-24 col-sm-24 mt-5"></div>
    </div>
  `;
};

export default MyEsiosWidget10;
