'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';

let templateShareModal = (
  id,
  params,
  slug = false,
  size = { width: '100%', height: '326' }
) => {
  let HOST = window.location.host.includes('www')
    ? window.location.host
    : // 'www.' without www
      '' + window.location.host;
  let URL = `https://${HOST}/${i18n.t('routes.lng')}/${i18n.t(
    'routes.embed'
  )}/${slug ? id : i18n.t(`routes.${id}`)}${params}`;
  let iframeValue = `<iframe src="${URL}" width="${size.width}" height="${size.height}"></iframe>`;

  const selectText = (e) => {
    e.target.select();
  };

  return html`
    <div class="share-modal">
      <h2 class="share-modal-title">${i18n.t('insert_into_my_web')}</h2>
      <p>${i18n.t('main_message_embed_toolbar')}</p>

      <input
        id="iframe-input-container"
        type="text"
        readonly="true"
        class="share-modal-iframe"
        value="${iframeValue}"
        @click=${selectText}
      />

      <a href="${URL}" target="_blank" class="share-modal-ancle"
        >${i18n.t('open_in_new_window')}</a
      >

      <p>
        ${html([i18n.t('second_message_embed_toolbar')])} “${HOST}”
        ${html([i18n.t('third_message_embed_toolbar')])}
        <a href="https://${HOST}">https://${HOST}</a>
      </p>
    </div>
  `;
};

export { templateShareModal };
