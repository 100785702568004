'use strict';
import { setDateFormat } from '../../utils/dateFormat';
import { api } from '../../utils/api-interceptor';

let mapasInteresService = (() => {
  let init = (e) => apiMapCall(e);

  const apiMapCall = async (e) => {
    const apiUrlCall =
      `/maps?order=published&locale=${e}&temporal=` +
      Math.floor(Math.random() * 1000);

    try {
      const data = await api(apiUrlCall);
      data.contents.forEach((current) => {
        current.updated_at_formated = setDateFormat(current.updated_at);
      });
      return data.contents;
    } catch (error) {
      null;
    }
  };

  /****
   */

  /*** */
  return {
    init: (e) => init(e),
  };
})();

export { mapasInteresService };
