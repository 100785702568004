'use strict';
import {store} from '../../../app.init'
import {frontJs} from '../../../constants';

let servicesSelect = (() => {
    
    let init = () => {	
       
       
	};

	return {
		init: init
    };

})();

export { servicesSelect }