import { html } from 'lit-html';
import i18n from 'i18next';
import { templateToolbarTime } from '../../../../shared/components/toolbarTime/toolbarTime.template';

const MyEsiosWidget8 = () => {
  return html`
    <div id="mypPrecioWidgetView" class="mypPrecioWidgetView col-sm-6 col-xs-6">
      <div class="esios-toolbar col-12" style="z-index: 1">
        <div class="esios-toolbar8"></div>
        ${templateToolbarTime('volumen')}
      </div>
      <div id="spinner8" class="spinner-container">
        <div class="spinner"></div>
      </div>
      <div>
        <div id="widget8">
          <div id="mypGraphic3" class="chari-chart aspect myp-aspect"></div>
          <div
            id="mypNoDataRect3"
            class="esios-home__no-data-container_cuadrado hidden"
          >
            ${i18n.t('no-data-available')}
          </div>
        </div>
        <ul class="esios-listEnergyItems__list"></ul>
      </div>
    </div>
  `;
};

export default MyEsiosWidget8;
