'use strict';

/**
 * @description Dashboard Component
 * @returns {Object} Devuelve la instancia del componente
 */

import i18n from 'i18next';
import { render } from 'lit-html';
import moment from 'moment';
import { store } from '../../app.init.js';
import { templateTable } from '../table/table.template.js';
import { servicesUnidadesProgramacion } from './unidadprogramacion.services.js';
import { templateUnidadesProgramacion } from './unidadprogramacion.template.js';
import { componentButtons } from '../../shared/components/buttons/buttons.component.js';
import { componentListPrintView } from '../../shared/components/list-print-view/list-print-view.component.js';
import { Utils } from '../../utils/utils.js';
import { componentPaginator } from '../../shared/components/paginator/paginator.component.js';

let componentUnidadesProgramacion = (function () {
  let context = {};
  let handlers = {
    onPageChange: (page) => onPageChange(page),
    onTotalChange: (event) => onTotalChange(event),

    onQueryChange: (event) => onQueryChange(event),

    onTypeSearchChange: (event) => onTypeSearchChange(event),
    onDateInitChange: (event) => onDateInitChange(event),
    onDateEndChange: (event) => onDateEndChange(event),
    onSearch: () => onSearch(),
    onHide: (event) => onHide(event),
    onSelectChange: (index) => onSelectChange(index),
  };
  let columns;
  let pageTitle = '';
  let renderSuccess = false;
  let select = [];

  let listMarket;

  let listDocumen;

  const currentRoute = window.location.pathname;
  let lang = currentRoute.split('/')[1];

  // tres filtros
  let searchForm = {
    type: 'publicacion',
    date_init: moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD'),
    date_end: moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD'),
  };
  let filterForm = {
    query: '',
    potencia: '',
    tipoproduccion: '',
    negocio: '',
    zonaregulacion: '',
    sujetomercado: '',
    tipoup: '',
  };
  let data = [];
  let dataPaginated = [];

  let paginationValues = [25, 50, 100];
  let pagination = {
    total: 0,
    page: 1,
    totalPages: 1,
    pagination: paginationValues[0],
  };

  // Fin Definir columnas
  // paginacion funciones
  const onPageChange = (page) => {
    pagination.page = page;
    pagination.totalPages = Math.ceil(data.length / pagination.pagination);
    filterData();
  };

  const onTotalChange = (event) => {
    pagination.page = 1;
    pagination.pagination = event.target.value;
    pagination.totalPages = Math.ceil(data.length / pagination.pagination);
    filterData();
  };

  const onQueryChange = (event) => {
    filterForm.query = event.target.value;
    if (event.target.value.length > 0)
      document
        .querySelector('.esios-unidadesprogramacion__button-remove')
        .classList.remove('hidden');
    else
      document
        .querySelector('.esios-unidadesprogramacion__button-remove')
        .classList.add('hidden');
    filterData();
  };

  const selectElements = [
    {
      value: 'tipoproduccion',
      label: 'production_type',
    },
    {
      value: 'negocio',
      label: 'trade',
    },
    {
      value: 'zonaregulacion',
      label: 'regulation_zone',
    },
    {
      value: 'sujetomercado',
      label: 'market_subject',
    },
    {
      value: 'tipoup',
      label: 'UP-type',
    },
  ];

  const onSelectChange = (index) => {
    // cE = chosen element
    const cE = selectElements[index];
    return (event) => {
      filterForm[cE.value] = event.target.value;
      let selected = document.querySelectorAll('.ui-selectmenu-text');
      filterForm[cE.value] === ''
        ? (selected[index].innerHTML = i18n.t(cE.label))
        : filterForm[cE.value];
      filterData();
    };
  };

  const onHide = (event) => {
    document.querySelector('.esios-unidadesprogramacion__tabla__input').value =
      '';
    document
      .querySelector('.esios-unidadesprogramacion__button-remove')
      .classList.add('hidden');
    filterForm.query = '';
    filterData();
  };

  const filterData = () => {
    let filtered = data.filter((item) =>
      item[i18n.t('UP-code')]
        .toLowerCase()
        .includes(filterForm.query.toLowerCase())
    );

    const filterQuery = (column) => {
      let filter = data.filter((item) =>
        item[i18n.t(column)]
          .toLowerCase()
          .includes(filterForm.query.toLowerCase())
      );
      filtered = [...filter, ...filtered];
    };

    const filterSelect = (select, attribute) => {
      filtered = filtered.filter((item) =>
        item[i18n.t(select)]
          .toLowerCase()
          .includes(filterForm[attribute].toLowerCase())
      );
    };

    filterQuery('production_type');
    filterQuery('trade');
    filterQuery('regulation_zone');
    filterQuery('market_subject');
    filterQuery('UP-type');
    filterQuery('short_description');
    filterQuery('large_description');
    filterQuery('maximum_power_capacity_MW');
    filterQuery('eic-code');

    filterSelect('production_type', 'tipoproduccion');
    filterSelect('trade', 'negocio');
    filterSelect('regulation_zone', 'zonaregulacion');
    filterSelect('market_subject', 'sujetomercado');
    filterSelect('UP-type', 'tipoup');

    var hash = {};
    filtered = filtered.filter(function (current) {
      var exists = !hash[current[i18n.t('eic-code')]];
      hash[current[i18n.t('eic-code')]] = true;
      return exists;
    });

    dataPaginated = filtered.slice(
      (pagination.page - 1) * pagination.pagination,
      pagination.page * pagination.pagination
    );
    const columns = [
      {
        name: i18n.t('UP-code'),
        field: i18n.t('UP-code'),
      },
      {
        name: i18n.t('short_description'),
        field: i18n.t('short_description'),
      },
      {
        name: i18n.t('large_description'),
        field: i18n.t('large_description'),
      },
      {
        name: i18n.t('maximum_power_capacity_MW'),
        field: i18n.t('maximum_power_capacity_MW'),
      },
      {
        name: i18n.t('eic-code'),
        field: i18n.t('eic-code'),
      },
      {
        name: i18n.t('production_type'),
        field: i18n.t('production_type'),
      },
      {
        name: i18n.t('trade'),
        field: i18n.t('trade'),
      },
      {
        name: i18n.t('regulation_zone'),
        field: i18n.t('regulation_zone'),
      },
      {
        name: i18n.t('market_subject'),
        field: i18n.t('market_subject'),
      },
      {
        name: i18n.t('UP-type'),
        field: i18n.t('UP-type'),
      },
    ];
    componentListPrintView.render('print-list', dataPaginated, columns);
    pagination.total = filtered.length;
    pagination.totalPages = Math.ceil(filtered.length / pagination.pagination);
    renderAll();
    renderTable();
  };

  // filtros de busqueda por fecha, arriba

  const onTypeSearchChange = (event) => {
    searchForm.query = event.target.value;
  };

  const onDateInitChange = (event) => {
    searchForm.date_init = event.target.value;
  };

  const onDateEndChange = (event) => {
    searchForm.date_end = event.target.value;
  };

  // buscar por fecha
  const onSearch = () => {
    searchDataUnidades();
  };

  let preRenderComponent = async () => {
    listDocumen = [
      {
        type: 'title',
        id: 1,
        title: 'how_to_become_a_balance_responsible_party',
      },
      {
        type: 'button',
        id: 2,
        label: 'gde_website_text',
        url: 'https://www.ree.es/es/clientes',
      },
    ];
    listMarket = [
      {
        type: 'title',
        id: 2,
        title: 'structural_data',
      },
      {
        type: 'list',
        id: 1,
        text: 'participants_auction',
        url: `/${i18n.t('routes.lng')}/${i18n.t(
          'routes.participants_auction'
        )}`,
      },
      {
        type: 'list',
        id: 2,
        text: 'programming_units',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.programming_units')}`,
      },
      {
        type: 'list',
        id: 3,
        text: 'physical_units',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.physical_units')}`,
      },
      {
        type: 'list',
        id: 4,
        text: 'market_subjects',
        url: `/${i18n.t('routes.lng')}/${i18n.t('routes.market_subjects')}`,
      },
    ];
    templateUnidadesProgramacion(
      context,
      handlers,
      listDocumen,
      select,
      paginationValues,
      pagination,
      filterForm,
      searchForm,
      listMarket,
      lang
    );
    document
      .querySelector('.esios-layout__main')
      .classList.add('esios-layout__main--programming');
    document
      .getElementById('esios-layout-banner')
      .classList.add('hideForPrint');
    document
      .querySelector('.esios-layout__print-logo')
      .classList.add('programming');

    // Asi accedemos a la propiedad del store
    const state = store.getState(searchForm);
    document.title = `${i18n.t('programming_units')} | ${i18n.t(
      'page_subtitle'
    )}`;
    context.name = state.exampleReducer.name;

    columns = [
      {
        title: 'UP-code',
        dataIndex: [i18n.t('UP-code')],
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            if (order === 'asc') {
              if (a[i18n.t('UP-code')] < b[i18n.t('UP-code')]) {
                return -1;
              }
              if (a[i18n.t('UP-code')] > b[i18n.t('UP-code')]) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (a[i18n.t('UP-code')] > b[i18n.t('UP-code')]) {
                return -1;
              }
              if (a[i18n.t('UP-code')] < b[i18n.t('UP-code')]) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;

          filterData();
        },
      },
      {
        title: 'short_description',
        dataIndex: [i18n.t('short_description')],
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            if (order === 'asc') {
              if (
                a[i18n.t('short_description')] < b[i18n.t('short_description')]
              ) {
                return -1;
              }
              if (
                a[i18n.t('short_description')] > b[i18n.t('short_description')]
              ) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (
                a[i18n.t('short_description')] > b[i18n.t('short_description')]
              ) {
                return -1;
              }
              if (
                a[i18n.t('short_description')] < b[i18n.t('short_description')]
              ) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
      {
        title: 'large_description',
        dataIndex: [i18n.t('large_description')],
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            if (order === 'asc') {
              if (
                a[i18n.t('large_description')] < b[i18n.t('large_description')]
              ) {
                return -1;
              }
              if (
                a[i18n.t('large_description')] > b[i18n.t('large_description')]
              ) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (
                a[i18n.t('large_description')] > b[i18n.t('large_description')]
              ) {
                return -1;
              }
              if (
                a[i18n.t('large_description')] < b[i18n.t('large_description')]
              ) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
      {
        title: 'maximum_power_capacity_MW',
        dataIndex: [i18n.t('maximum_power_capacity_MW')],
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            let newA = +a[i18n.t('maximum_power_capacity_MW')]
              .replaceAll('.', '')
              .replaceAll(',', '.');
            let newB = +b[i18n.t('maximum_power_capacity_MW')]
              .replaceAll('.', '')
              .replaceAll(',', '.');
            if (order === 'asc') {
              if (newA < newB) {
                return -1;
              }
              if (newA > newB) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (newA > newB) {
                return -1;
              }
              if (newA < newB) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
      {
        title: 'eic-code',
        dataIndex: [i18n.t('eic-code')],
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            if (order === 'asc') {
              if (a[i18n.t('eic-code')] < b[i18n.t('eic-code')]) {
                return -1;
              }
              if (a[i18n.t('eic-code')] > b[i18n.t('eic-code')]) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (a[i18n.t('eic-code')] > b[i18n.t('eic-code')]) {
                return -1;
              }
              if (a[i18n.t('eic-code')] < b[i18n.t('eic-code')]) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
      {
        title: 'production_type',
        dataIndex: [i18n.t('production_type')],
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            if (order === 'asc') {
              if (a[i18n.t('production_type')] < b[i18n.t('production_type')]) {
                return -1;
              }
              if (a[i18n.t('production_type')] > b[i18n.t('production_type')]) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (a[i18n.t('production_type')] > b[i18n.t('production_type')]) {
                return -1;
              }
              if (a[i18n.t('production_type')] < b[i18n.t('production_type')]) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
      {
        title: 'trade',
        dataIndex: [i18n.t('trade')],
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            if (order === 'asc') {
              if (a[i18n.t('trade')] < b[i18n.t('trade')]) {
                return -1;
              }
              if (a[i18n.t('trade')] > b[i18n.t('trade')]) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (a[i18n.t('trade')] > b[i18n.t('trade')]) {
                return -1;
              }
              if (a[i18n.t('trade')] < b[i18n.t('trade')]) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
      {
        title: 'regulation_zone',
        dataIndex: [i18n.t('regulation_zone')],
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            if (order === 'asc') {
              if (a[i18n.t('regulation_zone')] < b[i18n.t('regulation_zone')]) {
                return -1;
              }
              if (a[i18n.t('regulation_zone')] > b[i18n.t('regulation_zone')]) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (a[i18n.t('regulation_zone')] > b[i18n.t('regulation_zone')]) {
                return -1;
              }
              if (a[i18n.t('regulation_zone')] < b[i18n.t('regulation_zone')]) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
      {
        title: 'market_subject',
        dataIndex: [i18n.t('market_subject')],
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            if (order === 'asc') {
              if (a[i18n.t('market_subject')] < b[i18n.t('market_subject')]) {
                return -1;
              }
              if (a[i18n.t('market_subject')] > b[i18n.t('market_subject')]) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (a[i18n.t('market_subject')] > b[i18n.t('market_subject')]) {
                return -1;
              }
              if (a[i18n.t('market_subject')] < b[i18n.t('market_subject')]) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
      {
        title: 'UP-type',
        dataIndex: [i18n.t('UP-type')],
        defaultSortOrder: 'asc',
        sortDirections: ['asc', 'desc'],
        sorter: (datalist, order) => {
          const sorted = [...datalist].sort((a, b) => {
            if (order === 'asc') {
              if (a[i18n.t('UP-type')] < b[i18n.t('UP-type')]) {
                return -1;
              }
              if (a[i18n.t('UP-type')] > b[i18n.t('UP-type')]) {
                return 1;
              }
              return 0;
            } else if (order === 'desc') {
              if (a[i18n.t('UP-type')] > b[i18n.t('UP-type')]) {
                return -1;
              }
              if (a[i18n.t('UP-type')] < b[i18n.t('UP-type')]) {
                return 1;
              }
              return 0;
            }
          });
          data = sorted;
          filterData();
        },
      },
    ];
  };

  // get archives trae por fecha API
  const searchDataUnidades = () => {
    return servicesUnidadesProgramacion
      .getArchivesUnidades()
      .then((respData) => {
        data = respData;
        pagination.total = respData.length;
        pagination.totalPages = Math.ceil(
          respData.length / pagination.pagination
        );
        const filters = {
          prodType: [],
          trade: [],
          regulationZone: [],
          marketSubject: [],
          bindingUP: [],
        };

        const filterAttrs = [
          {
            label: i18n.t('production_type'),
            value: 'prodType',
            id: 1,
          },
          {
            label: i18n.t('trade'),
            value: 'trade',
            id: 2,
          },
          {
            label: i18n.t('regulation_zone'),
            value: 'regulationZone',
            id: 3,
          },
          {
            label: i18n.t('market_subject'),
            value: 'marketSubject',
            id: 4,
          },
          {
            label: i18n.t('UP-type'),
            value: 'bindingUP',
            id: 5,
          },
        ];
        select = Utils.createFilterModel(data, filters, filterAttrs);

        if (respData) {
          document.getElementById('spinner1').style.display = 'none';
        } else {
          return respData;
        }
        document
          .querySelector('.esios-unidadesprogramacion__selectors')
          .classList.remove('hidden');
        document
          .querySelector('.esios-pagination__results')
          .classList.remove('hidden');
      });
  };

  let renderComponent = async () => {
    try {
      // Render the template to the document
      await searchDataUnidades();
      templateUnidadesProgramacion(
        context,
        handlers,
        listDocumen,
        select,
        paginationValues,
        pagination,
        filterForm,
        searchForm,
        listMarket,
        lang
      );
      document
        .getElementById('esios-layout-banner')
        .classList.add('hideForPrint');

      filterData();
      const dataCsv = data.map((i) => {
        return [
          i[i18n.t('UP-code')],
          i[i18n.t('short_description')],
          i[i18n.t('large_description')],
          i[i18n.t('maximum_power_capacity_MW')],
          i[i18n.t('eic-code')],
          i[i18n.t('production_type')],
          i[i18n.t('trade')],
          i[i18n.t('regulation_zone')],
          i[i18n.t('market_subject')],
          i[i18n.t('UP-type')],
        ];
      });
      dataCsv.unshift([
        i18n.t('UP-code'),
        i18n.t('short_description'),
        i18n.t('large_description'),
        i18n.t('maximum_power_capacity_MW'),
        i18n.t('eic-code'),
        i18n.t('production_type'),
        i18n.t('trade'),
        i18n.t('regulation_zone'),
        i18n.t('market_subject'),
        i18n.t('UP-type'),
      ]);
      const fileName =
        'export_' +
        i18n.t('routes.programming_units') +
        '_' +
        moment().format('YYYY-MM-DD_HH_mm');
      const buttonsArray = [
        {
          title: 'EXPORTAR JSON',
          action: 'exportJSON',
          data: data.map((i) => ({
            [i18n.t('UP-code')]: i[i18n.t('UP-code')],
            [i18n.t('short_description')]: i[i18n.t('short_description')],
            [i18n.t('large_description')]: i[i18n.t('large_description')],
            [i18n.t('maximum_power_capacity_MW')]:
              i[i18n.t('maximum_power_capacity_MW')],
            [i18n.t('eic-code')]: i[i18n.t('eic-code')],
            [i18n.t('production_type')]: i[i18n.t('production_type')],
            [i18n.t('trade')]: i[i18n.t('trade')],
            [i18n.t('regulation_zone')]: i[i18n.t('regulation_zone')],
            [i18n.t('market_subject')]: i[i18n.t('market_subject')],
            [i18n.t('UP-type')]: i[i18n.t('UP-type')],
          })),
          docName: fileName,
        },
        {
          title: 'EXPORTAR CSV',
          action: 'exportCSV',
          data: dataCsv,
          docName: fileName,
        },
        {
          title: 'EXPORTAR EXCEL',
          action: 'exportExcel',
          data: dataCsv,
        },
        {
          title: 'IMPRIMIR',
          action: 'print',
          url: '#',
        },
      ];
      componentButtons.render(buttonsArray, '#unidad-prog-buttons');
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }

    document
      .querySelector('.mod-documents')
      .classList.add('mod-documents--unidadespro');

    document
      .querySelector('.row.is-strech')
      .classList.add('row.is-strech--unidadespro');

    document
      .querySelector('.esios-boxmarket')
      .classList.add('esios-boxmarket--unidadespro');

    document
      .querySelector('.esios-footer')
      .classList.add('esios-footer--unidadespro');

    const selects = [
      {
        className: '.ree-select-tipoproduccion',
        evento: handlers.onSelectChange(0),
      },
      {
        className: '.ree-select-negocio',
        evento: handlers.onSelectChange(1),
      },
      {
        className: '.ree-select-zonaregulacion',
        evento: handlers.onSelectChange(2),
      },
      {
        className: '.ree-select-sujetomercado',
        evento: handlers.onSelectChange(3),
      },
      {
        className: '.ree-select-tipoup',
        evento: handlers.onSelectChange(4),
      },
    ];

    const createSelect = (selects) => {
      selects.map(({ className, evento }) => {
        $(className).selectmenu({
          appendTo: '.init-dropdown',
        });

        $(className).on('selectmenuchange', function (event, ui) {
          evento(event);
        });
      });
    };
    createSelect(selects);

    let list = document.querySelectorAll('.esios-table__head-txt');
    for (let i = 0; i < list.length; ++i) {
      list[i].classList.add('esios-table__head-txt--uprogramacion');
    }
  };

  let renderTable = () => {
    const tableElement = document.getElementById('download-table');
    render(templateTable(columns, dataPaginated, data), tableElement);
    componentPaginator.render(pagination, onPageChange, 'paginator-progra');
  };

  let renderAll = () => {
    templateUnidadesProgramacion(
      context,
      handlers,
      listDocumen,
      select,
      paginationValues,
      pagination,
      filterForm,
      searchForm,
      listMarket,
      lang
    );
    document
      .getElementById('esios-layout-banner')
      .classList.add('hideForPrint');
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componentUnidadesProgramacion };
