'use strict';
import { timeHours } from 'd3';
import i18next from 'i18next';
import moment from 'moment-timezone';
import { api } from '../../utils/api-interceptor';
import { getBreadCrumbsDate } from '../../utils/dateFormat';
import { Utils } from '../../utils/utils';

let servicesMercadosPrecios = (() => {
  let init = () => {};

  const getArchives = async (params) => {
    const { ambit, category, action, process, query } = params;

    const urlApi = `indicators?${
      ambit !== '' ? `taxonomy_ids[]=${ambit}&` : ''
    }${category !== '' ? `taxonomy_ids[]=${category}&` : ''}${
      action !== '' ? `taxonomy_ids[]=${action}&` : ''
    }${process !== '' ? `taxonomy_ids[]=${process}&` : ''}locale=${i18next.t(
      'routes.lng'
    )}${query ? `&text=${query}` : ''}`;

    let loading;
    let newIndicators;
    try {
      loading = true;
      const data = await api(urlApi);
      if (!ambit && !query && !category && !process && !action) {
        return {
          newIndicators: [],
          isLoading: loading,
        };
      }
      newIndicators = data.indicators
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
        .map((indicator) => {
          return {
            name: indicator.name,
            id: indicator.id,
          };
        });
      loading = false;
      return {
        isLoading: loading,
        newIndicators,
      };
    } catch (error) {}
  };

  const getVolumenDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year, volumenUTC1 = false } = params;

    if (globalState === '00:00') {
      return api(
        `widgets/6?locale=${lang}&datetime=${
          year.replace(/ /g, '') +
          '-' +
          month.replace(/ /g, '') +
          '-' +
          day.replace(/ /g, '')
        }`
      ).then((data) => {
        const indicators = data.widget.indicators;
        const slug = data.widget.id;
        const description = data.widget.description;
        const name = data.widget.name;
        const magnituds = [];
        const alias = [];
        const indicatorName = [];
        const shortName = [];
        return Promise.all(
          indicators.map((el) => {
            magnituds.push(el.display_options);
            alias.push(el.alias);
            shortName.push(el.short_name);
            indicatorName.push(el.name);
            return api(`${el.url}&locale=${lang}`);
          })
        ).then((data) => {
          const indicators = data.map((ele, index) => {
            const values = ele.indicator.values.map((el) => {
              return {
                x: new Date(el.datetime),
                y: el.value,
              };
            });

            return {
              values,
              print_values: ele.indicator.values,
              time: ele.indicator.tiempo[0]?.id,
              alias: alias[index],
              display_options: magnituds[index],
              composited: ele.indicator?.composited,
              magnitud: ele.indicator?.magnitud,
              disaggregated: ele.indicator?.disaggregated,
              short_name: shortName[index],
              name: indicatorName[index],
              step: ele.indicator.step_type,
              id: ele.indicator.id,
              geo_id: ele.indicator.geos[0]?.geo_id,
              geo_name: ele.indicator.geos[0]?.geo_name,
            };
          });

          return {
            description,
            name,
            indicators,
            slug,
          };
        });
      });
    } else {
      let timeInHours;
      typeof globalState == 'object'
        ? (timeInHours = globalState.pages.myp.volumeHour)
        : (timeInHours = globalState);

      let date = `${day}-${month}-${year}`;
      newDate = moment(date, 'DD-MM-YYYY')
        .tz('Europe/Madrid')
        .hours(timeInHours.split(':')[0])
        .minutes(timeInHours.split(':')[1])
        .seconds('00')
        .format();

      let hoursOfDay = Utils.getHoursOfDay(date, 'DD-MM-YYYY');
      if (hoursOfDay == 25) {
        if (timeInHours.split(':')[0] == '02') {
          if (volumenUTC1) {
            newDate = newDate.replace('+02:00', '+01:00');
          }
        }
      }

      return api(`widgets/6?locale=${lang}&datetime=${newDate}`).then(
        (data) => {
          const indicators = data.widget.indicators;
          const slug = data.widget.id;
          const description = data.widget.description;
          const name = data.widget.name;
          const magnituds = [];
          const alias = [];
          const indicatorName = [];
          const shortName = [];
          return Promise.all(
            indicators.map((el) => {
              magnituds.push(el.display_options);
              alias.push(el.alias);
              shortName.push(el.short_name);
              indicatorName.push(el.name);
              return api(`${el.url}&locale=${lang}`);
            })
          ).then((data) => {
            const indicators = data.map((ele, index) => {
              const values = ele.indicator.values.map((el) => {
                return {
                  x: new Date(el.datetime),
                  y: el.value,
                };
              });
              return {
                values,
                print_values: ele.indicator.values,
                time: ele.indicator.tiempo[0]?.id,
                alias: alias[index],
                display_options: magnituds[index],
                composited: ele.indicator?.composited,
                magnitud: ele.indicator?.magnitud,
                disaggregated: ele.indicator?.disaggregated,
                short_name: shortName[index],
                name: indicatorName[index],
                step: ele.indicator.step_type,
                id: ele.indicator.id,
                geo_id: ele.indicator.geos[0]?.geo_id,
                geo_name: ele.indicator.geos[0]?.geo_name,
              };
            });

            return {
              description,
              name,
              indicators,
              slug,
            };
          });
        }
      );
    }
  };
  const getUnitaryCostDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year, costeUTC1 = false } = params;

    if (globalState === '00:00') {
      return api(
        `widgets/7?locale=${lang}&datetime=${
          year.replace(/ /g, '') +
          '-' +
          month.replace(/ /g, '') +
          '-' +
          day.replace(/ /g, '')
        }`
      ).then((data) => {
        const indicators = data.widget.indicators;
        const slug = data.widget.id;
        const description = data.widget.description;
        const name = data.widget.name;
        const magnituds = [];
        const alias = [];
        const indicatorName = [];
        const shortName = [];
        return Promise.all(
          indicators.map((el) => {
            magnituds.push(el.display_options);
            alias.push(el.alias);
            shortName.push(el.short_name);
            indicatorName.push(el.name);
            return api(`${el.url}&locale=${lang}`);
          })
        ).then((data) => {
          const indicators = data.map((ele, index) => {
            const values = ele.indicator.values.map((el) => {
              return {
                x: new Date(el.datetime),
                y: el.value,
              };
            });

            return {
              values,
              print_values: ele.indicator.values,
              time: ele.indicator.tiempo[0]?.id,
              alias: alias[index],
              display_options: magnituds[index],
              composited: ele.indicator?.composited,
              magnitud: ele.indicator?.magnitud,
              disaggregated: ele.indicator?.disaggregated,
              short_name: shortName[index],
              name: indicatorName[index],
              step: ele.indicator.step_type,
              id: ele.indicator.id,
              geo_id: ele.indicator.geos[0]?.geo_id,
              geo_name: ele.indicator.geos[0]?.geo_name,
            };
          });

          return {
            description,
            name,
            indicators,
            slug,
          };
        });
      });
    } else {
      let timeInHours;
      typeof globalState == 'object'
        ? (timeInHours = globalState.pages.myp.volumeHour)
        : (timeInHours = globalState);

      let date = `${day}-${month}-${year}`;
      newDate = moment(date, 'DD-MM-YYYY')
        .tz('Europe/Madrid')
        .hours(timeInHours.split(':')[0])
        .minutes(timeInHours.split(':')[1])
        .seconds('00')
        .format();

      let hoursOfDay = Utils.getHoursOfDay(date, 'DD-MM-YYYY');
      if (hoursOfDay == 25) {
        if (timeInHours.split(':')[0] == '02') {
          if (costeUTC1) {
            newDate = newDate.replace('+02:00', '+01:00');
          }
        }
      }
      return api(`widgets/7?locale=${lang}&datetime=${newDate}`).then(
        (data) => {
          const indicators = data.widget.indicators;
          const slug = data.widget.id;
          const description = data.widget.description;
          const name = data.widget.name;
          const magnituds = [];
          const alias = [];
          const indicatorName = [];
          const shortName = [];
          return Promise.all(
            indicators.map((el) => {
              magnituds.push(el.display_options);
              alias.push(el.alias);
              shortName.push(el.short_name);
              indicatorName.push(el.name);
              return api(`${el.url}&locale=${lang}`);
            })
          ).then((data) => {
            const indicators = data.map((ele, index) => {
              const values = ele.indicator.values.map((el) => {
                return {
                  x: new Date(el.datetime),
                  y: el.value,
                };
              });

              return {
                values,
                print_values: ele.indicator.values,
                time: ele.indicator.tiempo[0]?.id,
                alias: alias[index],
                display_options: magnituds[index],
                composited: ele.indicator?.composited,
                magnitud: ele.indicator?.magnitud,
                disaggregated: ele.indicator?.disaggregated,
                short_name: shortName[index],
                name: indicatorName[index],
                step: ele.indicator.step_type,
                id: ele.indicator.id,
                geo_id: ele.indicator.geos[0]?.geo_id,
                geo_name: ele.indicator.geos[0]?.geo_name,
              };
            });

            return {
              description,
              name,
              indicators,
              slug,
            };
          });
        }
      );
    }
  };
  const getFotovoltaicaDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year } = params;
    if (globalState === '00:00') {
      const startDate = new Date(
        `${year.replace(/ /g, '')}-${month.replace(/ /g, '')}-${day.replace(
          / /g,
          ''
        )}T00:00:00+0100`
      );
      const formattedStartDate = startDate
        .toISOString()
        .replace(/\.\d{3}/, '')
        .substring(0, 19);
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 1);
      const formattedEndDate = endDate
        .toISOString()
        .replace(/\.\d{3}/, '')
        .substring(0, 19);
      return api(
        `indicators/542?locale=${lang}&end_date=${formattedEndDate.replace(
          '23:00:00',
          '23:59:00'
        )}&start_date=${formattedStartDate.replace('23:00:00', '23:59:00')}`
      ).then((data) => {
        const values = data.indicator.values.map((el) => {
          return {
            x: new Date(el.datetime),
            y: el.value,
          };
        });
        // Segunda llamada para obtener la descripción del indicador
        return api(
          `indicators/542?values=false&start_date=${formattedStartDate}&locale=es`
        ).then((descriptionData) => {
          const description = descriptionData.indicator.description;

          // Devolver los datos combinados de las dos llamadas
          return {
            values,
            print_values: data.indicator.values,
            time: data.indicator.tiempo[0]?.id,
            display_options: data.indicator.magnitud[0],
            composited: data.indicator?.composited,
            magnitud: data.indicator?.magnitud,
            disaggregated: data.indicator?.disaggregated,
            short_name: data.indicator.short_name,
            name: data.indicator.name,
            step: data.indicator.step_type,
            id: data.indicator.id,
            geo_id: data.indicator.geos[0]?.geo_id,
            geo_name: data.indicator.geos[0]?.geo_name,
            start: formattedStartDate,
            end: formattedEndDate,
            description: description,
          };
        });
      });
    }
  };

  const getTermicaDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year } = params;
    if (globalState === '00:00') {
      const endDate = `${year.replace(/ /g, '')}-${month.replace(
        / /g,
        ''
      )}-${day.replace(/ /g, '')}%2023%3A59%3A00`;
      const startDate = new Date(
        `${year.replace(/ /g, '')}-${month.replace(/ /g, '')}-${day.replace(
          / /g,
          ''
        )}T00:00:00+0100`
      );

      const formattedStartDate = startDate
        .toISOString()
        .replace(/\.\d{3}/, '')
        .substring(0, 19);

      // Llamada a la API para obtener los valores del indicador
      return api(
        `indicators/543?locale=${lang}&end_date=${endDate}&start_date=${formattedStartDate.replace(
          '23:00:00',
          '23:59:00'
        )}`
      ).then((data) => {
        const values = data.indicator.values.map((el) => {
          return {
            x: new Date(el.datetime),
            y: el.value,
          };
        });

        // Llamada a la API para obtener la descripción del indicador
        return api(
          `indicators/543?values=false&start_date=${formattedStartDate}&locale=es`
        ).then((descriptionData) => {
          const description = descriptionData.indicator.description;

          // Devolver los datos combinados de las dos llamadas
          return {
            values,
            print_values: data.indicator.values,
            time: data.indicator.tiempo[0]?.id,
            display_options: data.indicator.magnitud[0],
            composited: data.indicator?.composited,
            magnitud: data.indicator?.magnitud,
            disaggregated: data.indicator?.disaggregated,
            short_name: data.indicator.short_name,
            name: data.indicator.name,
            step: data.indicator.step_type,
            id: data.indicator.id,
            geo_id: data.indicator.geos[0]?.geo_id,
            geo_name: data.indicator.geos[0]?.geo_name,
            start: formattedStartDate,
            end: endDate,
            description: description,
          };
        });
      });
    }
  };

  const getDesvioDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year } = params;
    if (globalState === '00:00') {
      const endDate = `${year.replace(/ /g, '')}-${month.replace(
        / /g,
        ''
      )}-${day.replace(/ /g, '')}%2023%3A59%3A00`;
      const startDate = new Date(
        `${year.replace(/ /g, '')}-${month.replace(/ /g, '')}-${day.replace(
          / /g,
          ''
        )}T00:00:00+0100`
      );

      const formattedStartDate = startDate
        .toISOString()
        .replace(/\.\d{3}/, '')
        .substring(0, 19);

      // Llamada a la API para obtener los valores del indicador
      return api(
        `indicators/541?locale=${lang}&end_date=${endDate}&start_date=${formattedStartDate.replace(
          '23:00:00',
          '23:59:00'
        )}`
      ).then((data) => {
        const values = data.indicator.values.map((el) => {
          return {
            x: new Date(el.datetime),
            y: el.value,
          };
        });

        // Llamada a la API para obtener la descripción del indicador
        return api(
          `indicators/541?values=false&start_date=${formattedStartDate}&locale=es`
        ).then((descriptionData) => {
          const description = descriptionData.indicator.description;

          // Devolver los datos combinados de las dos llamadas
          return {
            values,
            print_values: data.indicator.values,
            time: data.indicator.tiempo[0]?.id,
            display_options: data.indicator.magnitud[0],
            composited: data.indicator?.composited,
            magnitud: data.indicator?.magnitud,
            disaggregated: data.indicator?.disaggregated,
            short_name: data.indicator.short_name,
            name: data.indicator.name,
            description: description,
            step: data.indicator.step_type,
            id: data.indicator.id,
            geo_id: data.indicator.geos[0]?.geo_id,
            geo_name: data.indicator.geos[0]?.geo_name,
            start: formattedStartDate,
            end: endDate,
          };
        });
      });
    }
  };

  const getFacturationDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year } = params;
    if (globalState === '00:00') {
      const endDate = `${year.replace(/ /g, '')}-${month.replace(
        / /g,
        ''
      )}-${day.replace(/ /g, '')}%2023%3A59%3A00`;
      const startDate = new Date(
        `${year.replace(/ /g, '')}-${month.replace(/ /g, '')}-${day.replace(
          / /g,
          ''
        )}T00:00:00+0100`
      );

      const formattedStartDate = startDate
        .toISOString()
        .replace(/\.\d{3}/, '')
        .substring(0, 19);

      // Llamada a la API para obtener los valores del indicador
      return api(
        `indicators/544?locale=${lang}&end_date=${endDate}&start_date=${formattedStartDate.replace(
          '23:00:00',
          '23:59:00'
        )}`
      ).then((data) => {
        const values = data.indicator.values.map((el) => {
          return {
            x: new Date(el.datetime),
            y: el.value,
          };
        });

        // Llamada a la API para obtener la descripción del indicador
        return api(
          `indicators/544?values=false&start_date=${formattedStartDate}&locale=es`
        ).then((descriptionData) => {
          const description = descriptionData.indicator.description;

          // Devolver los datos combinados de las dos llamadas
          return {
            values,
            print_values: data.indicator.values,
            time: data.indicator.tiempo[0]?.id,
            display_options: data.indicator.magnitud[0],
            composited: data.indicator?.composited,
            magnitud: data.indicator?.magnitud,
            disaggregated: data.indicator?.disaggregated,
            short_name: data.indicator.short_name,
            name: data.indicator.name,
            description: description,
            step: data.indicator.step_type,
            id: data.indicator.id,
            geo_id: data.indicator.geos[0]?.geo_id,
            geo_name: data.indicator.geos[0]?.geo_name,
            start: formattedStartDate,
            end: endDate,
          };
        });
      });
    }
  };

  const getDDemandaDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year } = params;

    if (globalState === '00:00') {
      const endDate = `${year.replace(/ /g, '')}-${month.replace(
        / /g,
        ''
      )}-${day.replace(/ /g, '')}%2023%3A59%3A00`;
      const startDate = new Date(
        `${year.replace(/ /g, '')}-${month.replace(/ /g, '')}-${day.replace(
          / /g,
          ''
        )}T00:00:00+0100`
      );

      const formattedStartDate = startDate
        .toISOString()
        .replace(/\.\d{3}/, '')
        .substring(0, 19);

      // Llamada a la API para obtener los valores del indicador
      return api(
        `indicators/1775?locale=${lang}&end_date=${endDate.replace(
          '23:00:00',
          '23:59:00'
        )}&start_date=${formattedStartDate.replace('23:00:00', '23:59:00')}`
      ).then((data) => {
        const values = data.indicator.values.map((el) => {
          return {
            x: new Date(el.datetime),
            y: el.value,
          };
        });

        // Llamada a la API para obtener la descripción del indicador
        return api(
          `indicators/1775?values=false&start_date=${formattedStartDate}&locale=es`
        ).then((descriptionData) => {
          const description = descriptionData.indicator.description;

          // Devolver los datos combinados de las dos llamadas
          return {
            values,
            print_values: data.indicator.values,
            time: data.indicator.tiempo[0]?.id,
            display_options: data.indicator.magnitud[0],
            composited: data.indicator?.composited,
            magnitud: data.indicator?.magnitud,
            disaggregated: data.indicator?.disaggregated,
            short_name: data.indicator.short_name,
            name: data.indicator.name,
            description: description,
            step: data.indicator.step_type,
            id: data.indicator.id,
            geo_id: data.indicator.geos[0]?.geo_id,
            geo_name: data.indicator.geos[0]?.geo_name,
            start: formattedStartDate,
            end: endDate,
          };
        });
      });
    }
  };

  const getDEolicaDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year } = params;
    if (globalState === '00:00') {
      const endDate = `${year.replace(/ /g, '')}-${month.replace(
        / /g,
        ''
      )}-${day.replace(/ /g, '')}%2023%3A59%3A00`;
      const startDate = new Date(
        `${year.replace(/ /g, '')}-${month.replace(/ /g, '')}-${day.replace(
          / /g,
          ''
        )}T00:00:00+0100`
      );

      const formattedStartDate = startDate
        .toISOString()
        .replace(/\.\d{3}/, '')
        .substring(0, 19);

      // Llamada a la API para obtener los valores del indicador
      return api(
        `indicators/10358?locale=${lang}&end_date=${endDate.replace(
          '23:00:00',
          '23:59:00'
        )}&start_date=${formattedStartDate.replace('23:00:00', '23:59:00')}`
      ).then((data) => {
        const values = data.indicator.values.map((el) => {
          return {
            x: new Date(el.datetime),
            y: el.value,
          };
        });
        // Segunda llamada para obtener la descripción del indicador
        return api(
          `indicators/10358?values=false&start_date=${formattedStartDate}&locale=es`
        ).then((descriptionData) => {
          const description = descriptionData.indicator.description;

          // Devolver los datos combinados de las dos llamadas
          return {
            values,
            print_values: data.indicator.values,
            time: data.indicator.tiempo[0]?.id,
            display_options: data.indicator.magnitud[0],
            composited: data.indicator?.composited,
            magnitud: data.indicator?.magnitud,
            disaggregated: data.indicator?.disaggregated,
            short_name: data.indicator.short_name,
            name: data.indicator.name,
            description: description,
            step: data.indicator.step_type,
            id: data.indicator.id,
            geo_id: data.indicator.geos[0]?.geo_id,
            geo_name: data.indicator.geos[0]?.geo_name,
            start: formattedStartDate,
            end: endDate,
          };
        });
      });
    }
  };

  const getIDDemandaDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year } = params;
    if (globalState === '00:00') {
      const endDate = `${year.replace(/ /g, '')}-${month.replace(
        / /g,
        ''
      )}-${day.replace(/ /g, '')}%2023%3A59%3A00`;
      const startDate = new Date(
        `${year.replace(/ /g, '')}-${month.replace(/ /g, '')}-${day.replace(
          / /g,
          ''
        )}T00:00:00+0100`
      );

      const formattedStartDate = startDate
        .toISOString()
        .replace(/\.\d{3}/, '')
        .substring(0, 19);

      // Llamada a la API para obtener los valores del indicador
      return api(
        `indicators/1776?locale=${lang}&end_date=${endDate.replace(
          '23:00:00',
          '23:59:00'
        )}&start_date=${formattedStartDate.replace('23:00:00', '23:59:00')}`
      ).then((data) => {
        const values = data.indicator.values.map((el) => {
          return {
            x: new Date(el.datetime),
            y: el.value,
          };
        });
        // Segunda llamada para obtener la descripción del indicador
        return api(
          `indicators/1776?values=false&start_date=${formattedStartDate}&locale=es`
        ).then((descriptionData) => {
          const description = descriptionData.indicator.description;

          // Devolver los datos combinados de las dos llamadas
          return {
            values,
            print_values: data.indicator.values,
            time: data.indicator.tiempo[0]?.id,
            display_options: data.indicator.magnitud[0],
            composited: data.indicator?.composited,
            magnitud: data.indicator?.magnitud,
            disaggregated: data.indicator?.disaggregated,
            short_name: data.indicator.short_name,
            name: data.indicator.name,
            description: description,
            step: data.indicator.step_type,
            id: data.indicator.id,
            geo_id: data.indicator.geos[0]?.geo_id,
            geo_name: data.indicator.geos[0]?.geo_name,
            start: formattedStartDate,
            end: endDate,
          };
        });
      });
    }
  };

  const getIDEolicaDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year } = params;
    if (globalState === '00:00') {
      const endDate = `${year.replace(/ /g, '')}-${month.replace(
        / /g,
        ''
      )}-${day.replace(/ /g, '')}%2023%3A59%3A00`;
      const startDate = new Date(
        `${year.replace(/ /g, '')}-${month.replace(/ /g, '')}-${day.replace(
          / /g,
          ''
        )}T00:00:00+0100`
      );

      const formattedStartDate = startDate
        .toISOString()
        .replace(/\.\d{3}/, '')
        .substring(0, 19);

      // Llamada a la API para obtener los valores del indicador
      return api(
        `indicators/10359?locale=${lang}&end_date=${endDate.replace(
          '23:00:00',
          '23:59:00'
        )}&start_date=${formattedStartDate.replace('23:00:00', '23:59:00')}`
      ).then((data) => {
        const values = data.indicator.values.map((el) => {
          return {
            x: new Date(el.datetime),
            y: el.value,
          };
        });
        // Segunda llamada para obtener la descripción del indicador
        return api(
          `indicators/10359?values=false&start_date=${formattedStartDate}&locale=es`
        ).then((descriptionData) => {
          const description = descriptionData.indicator.description;

          // Devolver los datos combinados de las dos llamadas
          return {
            values,
            print_values: data.indicator.values,
            time: data.indicator.tiempo[0]?.id,
            display_options: data.indicator.magnitud[0],
            composited: data.indicator?.composited,
            magnitud: data.indicator?.magnitud,
            disaggregated: data.indicator?.disaggregated,
            short_name: data.indicator.short_name,
            name: data.indicator.name,
            description: description,
            step: data.indicator.step_type,
            id: data.indicator.id,
            geo_id: data.indicator.geos[0]?.geo_id,
            geo_name: data.indicator.geos[0]?.geo_name,
            start: formattedStartDate,
            end: endDate,
          };
        });
      });
    }
  };

  const getPDemandaAnualDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year } = params;
    if (globalState === '00:00') {
      const endYear = year.replace(/ /g, '');
      const endMonth = month.replace(/ /g, '');
      const endDay = day.replace(/ /g, '');

      // Calcular la fecha de finalización
      const endDate = `${endYear}-${endMonth}-${endDay}%2023%3A59%3A00`;

      // Calcular la fecha de inicio para que abarque varios años
      const startYear = endYear - 4; // Comienza 4 años antes
      const startMonth = endMonth;
      const startDay = endDay;

      const startDate = new Date(
        `${startYear}-${startMonth}-${startDay}T00:00:00+0100`
      );
      const formattedStartDate = startDate.toISOString().substring(0, 19);

      // Llamada a la API para obtener los valores del indicador
      return api(
        `indicators/1774?locale=${lang}&end_date=${endDate.replace(
          '23:00:00',
          '23:59:00'
        )}&start_date=${formattedStartDate.replace('23:00:00', '23:59:00')}`
      ).then((data) => {
        const values = data.indicator.values.map((el) => {
          return {
            x: new Date(el.datetime),
            y: el.value,
          };
        });
        // Segunda llamada para obtener la descripción del indicador
        return api(
          `indicators/1774?values=false&start_date=${formattedStartDate}&locale=es`
        ).then((descriptionData) => {
          const description = descriptionData.indicator.description;

          // Devolver los datos combinados de las dos llamadas
          return {
            values,
            print_values: data.indicator.values,
            time: data.indicator.tiempo[0]?.id,
            display_options: data.indicator.magnitud[0],
            composited: data.indicator?.composited,
            magnitud: data.indicator?.magnitud,
            disaggregated: data.indicator?.disaggregated,
            short_name: data.indicator.short_name,
            name: data.indicator.name,
            description: description,
            step: data.indicator.step_type,
            id: data.indicator.id,
            geo_id: data.indicator.geos[0]?.geo_id,
            geo_name: data.indicator.geos[0]?.geo_name,
            start: formattedStartDate,
            end: endDate,
          };
        });
      });
    }
  };

  const getDemandaPrevistaPeninsularDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year } = params;
    if (globalState === '00:00') {
      const endDate = `${year.replace(/ /g, '')}-${month.replace(
        / /g,
        ''
      )}-${day.replace(/ /g, '')}%2023%3A59%3A00`;
      const startDate = new Date(
        `${year.replace(/ /g, '')}-${month.replace(/ /g, '')}-${day.replace(
          / /g,
          ''
        )}T00:00:00+0100`
      );

      const formattedStartDate = startDate
        .toISOString()
        .replace(/\.\d{3}/, '')
        .substring(0, 19);

      // Llamada a la API para obtener los valores del indicador
      return api(
        `indicators/544?locale=${lang}&end_date=${endDate}&start_date=${formattedStartDate.replace(
          '23:00:00',
          '23:59:00'
        )}`
      ).then((data) => {
        const values = data.indicator.values.map((el) => {
          return {
            x: new Date(el.datetime),
            y: el.value,
          };
        });
        // Segunda llamada para obtener la descripción del indicador
        return api(
          `indicators/544?values=false&start_date=${formattedStartDate}&locale=es`
        ).then((descriptionData) => {
          const description = descriptionData.indicator.description;

          // Devolver los datos combinados de las dos llamadas
          return {
            values,
            print_values: data.indicator.values,
            time: data.indicator.tiempo[0]?.id,
            display_options: data.indicator.magnitud[0],
            composited: data.indicator?.composited,
            magnitud: data.indicator?.magnitud,
            disaggregated: data.indicator?.disaggregated,
            short_name: data.indicator.short_name,
            name: data.indicator.name,
            description: description,
            step: data.indicator.step_type,
            id: data.indicator.id,
            geo_id: data.indicator.geos[0]?.geo_id,
            geo_name: data.indicator.geos[0]?.geo_name,
            start: formattedStartDate,
            end: endDate,
          };
        });
      });
    }
  };
  const getDemandaPrevistaSNPDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year } = params;
    if (globalState === '00:00') {
      const endDate = `${year.replace(/ /g, '')}-${month.replace(
        / /g,
        ''
      )}-${day.replace(/ /g, '')}%2023%3A59%3A00`;
      const startDate = new Date(
        `${year.replace(/ /g, '')}-${month.replace(/ /g, '')}-${day.replace(
          / /g,
          ''
        )}T00:00:00+0100`
      );

      const formattedStartDate = startDate
        .toISOString()
        .replace(/\.\d{3}/, '')
        .substring(0, 19);

      // Llamada a la API para obtener los valores del indicador
      return api(
        `indicators/1742?locale=${lang}&geo_agg=sum&end_date=${endDate}&start_date=${formattedStartDate.replace(
          '23:00:00',
          '23:59:00'
        )}`
      ).then((data) => {
        const values = data.indicator.values.map((el) => {
          return {
            x: new Date(el.datetime),
            y: el.value,
          };
        });
        // Segunda llamada para obtener la descripción del indicador
        return api(
          `indicators/1742?values=false&start_date=${formattedStartDate}&locale=es`
        ).then((descriptionData) => {
          const description = descriptionData.indicator.description;

          // Devolver los datos combinados de las dos llamadas
          return {
            values,
            print_values: data.indicator.values,
            time: data.indicator.tiempo[0]?.id,
            display_options: data.indicator.magnitud[0],
            composited: data.indicator?.composited,
            magnitud: data.indicator?.magnitud,
            disaggregated: data.indicator?.disaggregated,
            short_name: data.indicator.short_name,
            name: data.indicator.name,
            description: description,
            step: data.indicator.step_type,
            id: data.indicator.id,
            geo_id: data.indicator.geos[0]?.geo_id,
            geo_name: data.indicator.geos[0]?.geo_name,
            start: formattedStartDate,
            end: endDate,
          };
        });
      });
    }
  };
  const getDemandaDiariaElectricaPeninsularDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year } = params;
    if (globalState === '00:00') {
      const endDate = `${year.replace(/ /g, '')}-${month.replace(
        / /g,
        ''
      )}-${day.replace(/ /g, '')}%2023%3A59%3A00`;
      const startDate = new Date(
        `${year.replace(/ /g, '')}-${month.replace(/ /g, '')}-${day.replace(
          / /g,
          ''
        )}T00:00:00+0100`
      );

      const formattedStartDate = startDate
        .toISOString()
        .replace(/\.\d{3}/, '')
        .substring(0, 19);

      // Llamada a la API para obtener los valores del indicador
      return api(
        `indicators/460?locale=${lang}&end_date=${endDate}&start_date=${formattedStartDate.replace(
          '23:00:00',
          '23:59:00'
        )}`
      ).then((data) => {
        const values = data.indicator.values.map((el) => {
          return {
            x: new Date(el.datetime),
            y: el.value,
          };
        });
        // Segunda llamada para obtener la descripción del indicador
        return api(
          `indicators/460?values=false&start_date=${formattedStartDate}&locale=es`
        ).then((descriptionData) => {
          const description = descriptionData.indicator.description;

          // Devolver los datos combinados de las dos llamadas
          return {
            values,
            print_values: data.indicator.values,
            time: data.indicator.tiempo[0]?.id,
            display_options: data.indicator.magnitud[0],
            composited: data.indicator?.composited,
            magnitud: data.indicator?.magnitud,
            disaggregated: data.indicator?.disaggregated,
            short_name: data.indicator.short_name,
            name: data.indicator.name,
            description: description,
            step: data.indicator.step_type,
            id: data.indicator.id,
            geo_id: data.indicator.geos[0]?.geo_id,
            geo_name: data.indicator.geos[0]?.geo_name,
            start: formattedStartDate,
            end: endDate,
          };
        });
      });
    }
  };
  const getDemandaMensualElectricaPeninsularDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year } = params;
    if (globalState === '00:00') {
      // Obtener el primer día del primer mes
      const startDate = new Date(
        `${year.replace(/ /g, '')}-01-01T00:00:00+0100`
      );
      const formattedStartDate = startDate
        .toISOString()
        .replace(/\.\d{3}/, '')
        .substring(0, 10)
        .concat('%2000%3A00%3A00');

      // Obtener el último día del último mes
      const lastDayOfMonth = new Date(year, 12, 0).getDate();
      const endDate = `${year.replace(
        / /g,
        ''
      )}-12-${lastDayOfMonth}%2023%3A59%3A00`;

      return api(
        `indicators/461?locale=${lang}&end_date=${endDate}&start_date=${formattedStartDate}`
      ).then((data) => {
        const values = data.indicator.values.map((el) => {
          return {
            x: new Date(el.datetime),
            y: el.value,
          };
        });
        // Segunda llamada para obtener la descripción del indicador
        return api(
          `indicators/461?values=false&start_date=${formattedStartDate}&locale=es`
        ).then((descriptionData) => {
          const description = descriptionData.indicator.description;

          // Devolver los datos combinados de las dos llamadas
          return {
            values,
            print_values: data.indicator.values,
            time: data.indicator.tiempo[0]?.id,
            display_options: data.indicator.magnitud[0],
            composited: data.indicator?.composited,
            magnitud: data.indicator?.magnitud,
            disaggregated: data.indicator?.disaggregated,
            short_name: data.indicator.short_name,
            name: data.indicator.name,
            description: description,
            step: data.indicator.step_type,
            id: data.indicator.id,
            geo_id: data.indicator.geos[0]?.geo_id,
            geo_name: data.indicator.geos[0]?.geo_name,
            start: formattedStartDate,
            end: endDate,
          };
        });
      });
    }
  };
  const getDemandaSemanalElectricaPeninsularDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year } = params;
    if (globalState === '00:00') {
      const endDate = `${year.replace(/ /g, '')}-${month.replace(
        / /g,
        ''
      )}-${day.replace(/ /g, '')}%2023%3A59%3A00`;
      const startDate = new Date(
        `${year.replace(/ /g, '')}-${month.replace(/ /g, '')}-${day.replace(
          / /g,
          ''
        )}T00:00:00+0100`
      );

      const formattedStartDate = startDate
        .toISOString()
        .replace(/\.\d{3}/, '')
        .substring(0, 19);

      // Llamada a la API para obtener los valores del indicador
      return api(
        `indicators/603?locale=${lang}&end_date=${endDate}&start_date=${formattedStartDate.replace(
          '23:00:00',
          '23:59:00'
        )}`
      ).then((data) => {
        const values = data.indicator.values.map((el) => {
          return {
            x: new Date(el.datetime),
            y: el.value,
          };
        });
        // Segunda llamada para obtener la descripción del indicador
        return api(
          `indicators/603?values=false&start_date=${formattedStartDate}&locale=es`
        ).then((descriptionData) => {
          const description = descriptionData.indicator.description;

          // Devolver los datos combinados de las dos llamadas
          return {
            values,
            print_values: data.indicator.values,
            time: data.indicator.tiempo[0]?.id,
            display_options: data.indicator.magnitud[0],
            composited: data.indicator?.composited,
            magnitud: data.indicator?.magnitud,
            disaggregated: data.indicator?.disaggregated,
            short_name: data.indicator.short_name,
            name: data.indicator.name,
            description: description,
            step: data.indicator.step_type,
            id: data.indicator.id,
            geo_id: data.indicator.geos[0]?.geo_id,
            geo_name: data.indicator.geos[0]?.geo_name,
            start: formattedStartDate,
            end: endDate,
          };
        });
      });
    }
  };
  const getPrevisionMaximaAnualPeninsularDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year } = params;
    if (globalState === '00:00') {
      const endDate = `${year.replace(/ /g, '')}-${month.replace(
        / /g,
        ''
      )}-${day.replace(/ /g, '')}%2023%3A59%3A00`;
      const startDate = new Date(
        `${year.replace(/ /g, '')}-${month.replace(/ /g, '')}-${day.replace(
          / /g,
          ''
        )}T00:00:00+0100`
      );

      const formattedStartDate = startDate
        .toISOString()
        .replace(/\.\d{3}/, '')
        .substring(0, 19);

      // Llamada a la API para obtener los valores del indicador
      return api(
        `indicators/541?locale=${lang}&end_date=${endDate}&start_date=${formattedStartDate.replace(
          '23:00:00',
          '23:59:00'
        )}`
      ).then((data) => {
        const values = data.indicator.values.map((el) => {
          return {
            x: new Date(el.datetime),
            y: el.value,
          };
        });
        // Segunda llamada para obtener la descripción del indicador
        return api(
          `indicators/541?values=false&start_date=${formattedStartDate}&locale=es`
        ).then((descriptionData) => {
          const description = descriptionData.indicator.description;

          // Devolver los datos combinados de las dos llamadas
          return {
            values,
            print_values: data.indicator.values,
            time: data.indicator.tiempo[0]?.id,
            display_options: data.indicator.magnitud[0],
            composited: data.indicator?.composited,
            magnitud: data.indicator?.magnitud,
            disaggregated: data.indicator?.disaggregated,
            short_name: data.indicator.short_name,
            name: data.indicator.name,
            description: description,
            step: data.indicator.step_type,
            id: data.indicator.id,
            geo_id: data.indicator.geos[0]?.geo_id,
            geo_name: data.indicator.geos[0]?.geo_name,
            start: formattedStartDate,
            end: endDate,
          };
        });
      });
    }
  };
  const getGeneracionPrevistaSolarDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year } = params;
    if (globalState === '00:00') {
      const endDate = `${year.replace(/ /g, '')}-${month.replace(
        / /g,
        ''
      )}-${day.replace(/ /g, '')}%2023%3A59%3A00`;
      const startDate = new Date(
        `${year.replace(/ /g, '')}-${month.replace(/ /g, '')}-${day.replace(
          / /g,
          ''
        )}T00:00:00+0100`
      );

      const formattedStartDate = startDate
        .toISOString()
        .replace(/\.\d{3}/, '')
        .substring(0, 19);

      // Llamada a la API para obtener los valores del indicador
      return api(
        `indicators/10034?locale=${lang}&end_date=${endDate}&start_date=${formattedStartDate}`
      ).then((data) => {
        const values = data.indicator.values.map((el) => {
          return {
            x: new Date(el.datetime),
            y: el.value,
          };
        });
        // Segunda llamada para obtener la descripción del indicador
        return api(
          `indicators/10034?values=false&start_date=${formattedStartDate}&locale=es`
        ).then((descriptionData) => {
          const description = descriptionData.indicator.description;

          // Devolver los datos combinados de las dos llamadas
          return {
            values,
            print_values: data.indicator.values,
            time: data.indicator.tiempo[0]?.id,
            display_options: data.indicator.magnitud[0],
            composited: data.indicator?.composited,
            magnitud: data.indicator?.magnitud,
            disaggregated: data.indicator?.disaggregated,
            short_name: data.indicator.short_name,
            name: data.indicator.name,
            description: description,
            step: data.indicator.step_type,
            id: data.indicator.id,
            geo_id: data.indicator.geos[0]?.geo_id,
            geo_name: data.indicator.geos[0]?.geo_name,
            start: formattedStartDate,
            end: endDate,
          };
        });
      });
    }
  };
  const getPrevisionMaximaAnualPeninsular2DataFromWidget = async (params) => {
    const { lang, globalState, day, month, year } = params;
    if (globalState === '00:00') {
      const endDate = `${year.replace(/ /g, '')}-${month.replace(
        / /g,
        ''
      )}-${day.replace(/ /g, '')}%2023%3A59%3A00`;

      // Calcular la fecha de inicio para que abarque un año completo
      const startDate = new Date(
        `${year.replace(/ /g, '') - 1}-${month.replace(/ /g, '')}-${day.replace(
          / /g,
          ''
        )}T00:00:00+0100`
      );

      const formattedStartDate = startDate
        .toISOString()
        .replace(/\.\d{3}/, '')
        .substring(0, 19);
      return api(
        `indicators/620?locale=${lang}&end_date=${endDate}&start_date=${formattedStartDate}&time_trunc=day`
      ).then((data) => {
        const values = data.indicator.values.map((el) => {
          return {
            x: new Date(el.datetime),
            y: el.value,
          };
        });
        // Segunda llamada para obtener la descripción del indicador
        return api(
          `indicators/620?values=false&start_date=${formattedStartDate}&locale=es`
        ).then((descriptionData) => {
          const description = descriptionData.indicator.description;

          // Devolver los datos combinados de las dos llamadas
          return {
            values,
            print_values: data.indicator.values,
            time: data.indicator.tiempo[0]?.id,
            display_options: data.indicator.magnitud[0],
            composited: data.indicator?.composited,
            magnitud: data.indicator?.magnitud,
            disaggregated: data.indicator?.disaggregated,
            short_name: data.indicator.short_name,
            name: data.indicator.name,
            description: description,
            step: data.indicator.step_type,
            id: data.indicator.id,
            geo_id: data.indicator.geos[0]?.geo_id,
            geo_name: data.indicator.geos[0]?.geo_name,
            start: formattedStartDate,
            end: endDate,
          };
        });
      });
    }
  };
  const getPrevisionMinimaAnualPeninsularDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year } = params;
    if (globalState === '00:00') {
      const endDate = `${year.replace(/ /g, '')}-${month.replace(
        / /g,
        ''
      )}-${day.replace(/ /g, '')}%2023%3A59%3A00`;

      // Calcular la fecha de inicio para que abarque un año completo
      const startDate = new Date(
        `${year.replace(/ /g, '') - 1}-${month.replace(/ /g, '')}-${day.replace(
          / /g,
          ''
        )}T00:00:00+0100`
      );

      const formattedStartDate = startDate
        .toISOString()
        .replace(/\.\d{3}/, '')
        .substring(0, 19);
      return api(
        `indicators/621?locale=${lang}&end_date=${endDate}&start_date=${formattedStartDate}`
      ).then((data) => {
        const values = data.indicator.values.map((el) => {
          return {
            x: new Date(el.datetime),
            y: el.value,
          };
        });
        // Segunda llamada para obtener la descripción del indicador
        return api(
          `indicators/621?values=false&start_date=${formattedStartDate}&locale=es`
        ).then((descriptionData) => {
          const description = descriptionData.indicator.description;

          // Devolver los datos combinados de las dos llamadas
          return {
            values,
            print_values: data.indicator.values,
            time: data.indicator.tiempo[0]?.id,
            display_options: data.indicator.magnitud[0],
            composited: data.indicator?.composited,
            magnitud: data.indicator?.magnitud,
            disaggregated: data.indicator?.disaggregated,
            short_name: data.indicator.short_name,
            name: data.indicator.name,
            description: description,
            step: data.indicator.step_type,
            id: data.indicator.id,
            geo_id: data.indicator.geos[0]?.geo_id,
            geo_name: data.indicator.geos[0]?.geo_name,
            start: formattedStartDate,
            end: endDate,
          };
        });
      });
    }
  };
  const getPrevisionMaximaMensualPeninsularDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year } = params;
    if (globalState === '00:00') {
      const endDate = `${year.replace(/ /g, '')}-${month.replace(
        / /g,
        ''
      )}-${day.replace(/ /g, '')}%2023%3A59%3A00`;
      const startDate = new Date(
        `${year.replace(/ /g, '')}-${month.replace(/ /g, '')}-${day.replace(
          / /g,
          ''
        )}T00:00:00+0100`
      );
      startDate.setDate(startDate.getDate() - 30);
      const formattedStartDate = startDate
        .toISOString()
        .replace(/\.\d{3}/, '')
        .substring(0, 10)
        .concat('%2000%3A00%3A00');
      return api(
        `indicators/604?locale=${lang}&end_date=${endDate}&start_date=${formattedStartDate}&time_trunc=day`
      ).then((data) => {
        const values = data.indicator.values.map((el) => {
          return {
            x: new Date(el.datetime),
            y: el.value,
          };
        });
        // Segunda llamada para obtener la descripción del indicador
        return api(
          `indicators/604?values=false&start_date=${formattedStartDate}&locale=es`
        ).then((descriptionData) => {
          const description = descriptionData.indicator.description;

          // Devolver los datos combinados de las dos llamadas
          return {
            values,
            print_values: data.indicator.values,
            time: data.indicator.tiempo[0]?.id,
            display_options: data.indicator.magnitud[0],
            composited: data.indicator?.composited,
            magnitud: data.indicator?.magnitud,
            disaggregated: data.indicator?.disaggregated,
            short_name: data.indicator.short_name,
            name: data.indicator.name,
            description: description,
            step: data.indicator.step_type,
            id: data.indicator.id,
            geo_id: data.indicator.geos[0]?.geo_id,
            geo_name: data.indicator.geos[0]?.geo_name,
            start: formattedStartDate,
            end: endDate,
          };
        });
      });
    }
  };
  const getPrevisionMinimaMensualPeninsularDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year } = params;
    if (globalState === '00:00') {
      const endDate = `${year.replace(/ /g, '')}-${month.replace(
        / /g,
        ''
      )}-${day.replace(/ /g, '')}%2023%3A59%3A00`;
      const startDate = new Date(
        `${year.replace(/ /g, '')}-${month.replace(/ /g, '')}-${day.replace(
          / /g,
          ''
        )}T00:00:00+0100`
      );
      startDate.setDate(startDate.getDate() - 30);
      const formattedStartDate = startDate
        .toISOString()
        .replace(/\.\d{3}/, '')
        .substring(0, 10)
        .concat('%2000%3A00%3A00');
      return api(
        `indicators/619?locale=${lang}&end_date=${endDate}&start_date=${formattedStartDate}&time_trunc=day`
      ).then((data) => {
        const values = data.indicator.values.map((el) => {
          return {
            x: new Date(el.datetime),
            y: el.value,
          };
        });
        // Segunda llamada para obtener la descripción del indicador
        return api(
          `indicators/619?values=false&start_date=${formattedStartDate}&locale=es`
        ).then((descriptionData) => {
          const description = descriptionData.indicator.description;

          // Devolver los datos combinados de las dos llamadas
          return {
            values,
            print_values: data.indicator.values,
            time: data.indicator.tiempo[0]?.id,
            display_options: data.indicator.magnitud[0],
            composited: data.indicator?.composited,
            magnitud: data.indicator?.magnitud,
            disaggregated: data.indicator?.disaggregated,
            short_name: data.indicator.short_name,
            name: data.indicator.name,
            description: description,
            step: data.indicator.step_type,
            id: data.indicator.id,
            geo_id: data.indicator.geos[0]?.geo_id,
            geo_name: data.indicator.geos[0]?.geo_name,
            start: formattedStartDate,
            end: endDate,
          };
        });
      });
    }
  };
  const getMargenGeneracionDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year } = params;
    if (globalState === '00:00') {
      const endYear = year.replace(/ /g, '');
      const endMonth = month.replace(/ /g, '');
      const endDay = day.replace(/ /g, '');

      // Calcular la fecha de finalización
      const endDate = `${endYear}-${endMonth}-${endDay}%2023%3A59%3A00`;

      // Calcular la fecha de inicio para que abarque varios años
      const startYear = endYear - 4; // Comienza 4 años antes
      const startMonth = endMonth;
      const startDay = endDay;

      const startDate = new Date(
        `${startYear}-${startMonth}-${startDay}T00:00:00`
      );
      const formattedStartDate = startDate.toISOString().substring(0, 19);

      return api(
        `indicators/622?locale=${lang}&end_date=${endDate}&start_date=${formattedStartDate}`
      ).then((data) => {
        const values = data.indicator.values.map((el) => {
          return {
            x: new Date(el.datetime),
            y: el.value,
          };
        });
        // Segunda llamada para obtener la descripción del indicador
        return api(
          `indicators/622?values=false&start_date=${formattedStartDate}&locale=es`
        ).then((descriptionData) => {
          const description = descriptionData.indicator.description;

          // Devolver los datos combinados de las dos llamadas
          return {
            values,
            print_values: data.indicator.values,
            time: data.indicator.tiempo[0]?.id,
            display_options: data.indicator.magnitud[0],
            composited: data.indicator?.composited,
            magnitud: data.indicator?.magnitud,
            disaggregated: data.indicator?.disaggregated,
            short_name: data.indicator.short_name,
            name: data.indicator.name,
            description: description,
            step: data.indicator.step_type,
            id: data.indicator.id,
            geo_id: data.indicator.geos[0]?.geo_id,
            geo_name: data.indicator.geos[0]?.geo_name,
            start: formattedStartDate,
            end: endDate,
          };
        });
      });
    }
  };
  const getPrevisonDemandaResidualDataFromWidget = async (params) => {
    const { lang, globalState, day, month, year } = params;
    if (globalState === '00:00') {
      const endDate = `${year.replace(/ /g, '')}-${month.replace(
        / /g,
        ''
      )}-${day.replace(/ /g, '')}%2023%3A59%3A00`;
      const startDate = new Date(
        `${year.replace(/ /g, '')}-${month.replace(/ /g, '')}-${day.replace(
          / /g,
          ''
        )}T23:59:00`
      );
      startDate.setDate(startDate.getDate());
      const formattedStartDate = startDate
        .toISOString()
        .replace(/\.\d{3}/, '')
        .substring(0, 10)
        .concat('%2000%3A00%3A00');
      return api(
        `indicators/10249?locale=${lang}&end_date=${endDate.replace(
          '00:00:00',
          '23:59:00'
        )}&start_date=${formattedStartDate.replace('23:00:00', '00:00:00')}`
      ).then((data) => {
        const values = data.indicator.values.map((el) => {
          return {
            x: new Date(el.datetime),
            y: el.value,
          };
        });
        // Segunda llamada para obtener la descripción del indicador
        return api(
          `indicators/10249?values=false&start_date=${formattedStartDate}&locale=es`
        ).then((descriptionData) => {
          const description = descriptionData.indicator.description;

          // Devolver los datos combinados de las dos llamadas
          return {
            values,
            print_values: data.indicator.values,
            time: data.indicator.tiempo[0]?.id,
            display_options: data.indicator.magnitud[0],
            composited: data.indicator?.composited,
            magnitud: data.indicator?.magnitud,
            disaggregated: data.indicator?.disaggregated,
            short_name: data.indicator.short_name,
            name: data.indicator.name,
            description: description,
            step: data.indicator.step_type,
            id: data.indicator.id,
            geo_id: data.indicator.geos[0]?.geo_id,
            geo_name: data.indicator.geos[0]?.geo_name,
            start: formattedStartDate,
            end: endDate,
          };
        });
      });
    }
  };

  const getAuctionsDataFromWidget = async (params) => {
    const { lang, time, day, month, year } = params;
    if (time === '00:00') {
      return api(
        `widgets/10?locale=${lang}&datetime=${
          year.replace(/ /g, '') +
          '-' +
          month.replace(/ /g, '') +
          '-' +
          day.replace(/ /g, '')
        }`
      ).then((data) => {
        const indicators = data.widget.indicators;
        const slug = data.widget.id;
        const description = data.widget.description;
        const name = data.widget.name;
        const magnituds = [];
        const alias = [];
        const indicatorName = [];
        const shortName = [];
        return Promise.all(
          indicators.map((el) => {
            magnituds.push(el.display_options);
            alias.push(el.alias);
            shortName.push(el.short_name);
            indicatorName.push(el.name);
            return api(`${el.url}&locale=${lang}`);
          })
        ).then((data) => {
          const indicators = data.map((ele, index) => {
            const values = ele.indicator.values.map((el) => {
              return {
                x: new Date(el.datetime),
                y: el.value,
              };
            });

            return {
              values,
              print_values: ele.indicator.values,
              time: ele.indicator.tiempo[0]?.id,
              alias: alias[index],
              display_options: magnituds[index],
              composited: ele.indicator?.composited,
              magnitud: ele.indicator?.magnitud,
              disaggregated: ele.indicator?.disaggregated,
              short_name: shortName[index],
              name: indicatorName[index],
              step: ele.indicator.step_type,
              id: ele.indicator.id,
              geo_id: ele.indicator.geos[0]?.geo_id,
              geo_name: ele.indicator.geos[0]?.geo_name,
            };
          });

          return {
            description,
            name,
            indicators,
            slug,
          };
        });
      });
    } else {
      let timeInHours = time;
      const timezone = new Date().getTimezoneOffset() / 60;
      let newDate =
        year.replace(/ /g, '') +
        '-' +
        month.replace(/ /g, '') +
        '-' +
        day.replace(/ /g, '') +
        'T' +
        timeInHours.split(':')[0] +
        ':00:00' +
        (timezone <= 0 ? '+' + -1 * timezone : -1 * timezone) +
        ':00';
      return api(`widgets/10?locale=${lang}&datetime=${newDate}`).then(
        (data) => {
          const indicators = data.widget.indicators;
          const slug = data.widget.id;
          const description = data.widget.description;
          const name = data.widget.name;
          const magnituds = [];
          const alias = [];
          const indicatorName = [];
          const shortName = [];
          return Promise.all(
            indicators.map((el) => {
              magnituds.push(el.display_options);
              alias.push(el.alias);
              shortName.push(el.short_name);
              indicatorName.push(el.name);
              return api(`${el.url}&locale=${lang}`);
            })
          ).then((data) => {
            const indicators = data.map((ele, index) => {
              const values = ele.indicator.values.map((el) => {
                return {
                  x: new Date(el.datetime),
                  y: el.value,
                };
              });

              return {
                values,
                print_values: ele.indicator.values,
                time: ele.indicator.tiempo[0]?.id,
                alias: alias[index],
                display_options: magnituds[index],
                composited: ele.indicator?.composited,
                magnitud: ele.indicator?.magnitud,
                disaggregated: ele.indicator?.disaggregated,
                short_name: shortName[index],
                name: indicatorName[index],
                step: ele.indicator.step_type,
                id: ele.indicator.id,
                geo_id: ele.indicator.geos[0]?.geo_id,
                geo_name: ele.indicator.geos[0]?.geo_name,
              };
            });

            return {
              description,
              name,
              indicators,
              slug,
            };
          });
        }
      );
    }
  };

  const getAuctionsPortugalDataFromWidget = async (params) => {
    const { lang, day, month, year, time } = params;

    const timeInHours = time;
    const newDate = moment()
      .tz('Europe/Madrid')
      .year(year)
      .month(month - 1)
      .date(day)
      .hours(timeInHours?.split(':')[0] || '00')
      .minutes('00')
      .seconds(0)
      .format();
    return api(`widgets/35?locale=${lang}&datetime=${newDate}`).then((data) => {
      const indicators = data.widget.indicators;
      const slug = data.widget.id;
      const description = data.widget.description;
      const name = data.widget.name;
      const magnituds = [];
      const alias = [];
      const indicatorName = [];
      const shortName = [];
      return Promise.all(
        indicators.map((el) => {
          magnituds.push(el.display_options);
          alias.push(el.alias);
          shortName.push(el.short_name);
          indicatorName.push(el.name);
          return api(`${el.url}&locale=${lang}`);
        })
      ).then((data) => {
        const indicators = data.map((ele, index) => {
          const values = ele.indicator.values.map((el) => {
            return {
              x: new Date(el.datetime),
              y: el.value,
            };
          });

          return {
            values,
            print_values: ele.indicator.values,
            time: ele.indicator.tiempo[0]?.id,
            alias: alias[index],
            display_options: magnituds[index],
            composited: ele.indicator?.composited,
            magnitud: ele.indicator?.magnitud,
            disaggregated: ele.indicator?.disaggregated,
            short_name: shortName[index],
            name: indicatorName[index],
            step: ele.indicator.step_type,
            id: ele.indicator.id,
            geo_id: ele.indicator.geos[0]?.geo_id,
            geo_name: ele.indicator.geos[0]?.geo_name,
          };
        });

        return {
          description,
          name,
          indicators,
          slug,
        };
      });
    });
  };

  const getDataFromWidget = async (id, e) => {
    return api(`widgets/${id}?locale=${e}`).then((data) => {
      const indicators = data.widget.indicators;
      const slug = data.widget.id;
      const description = data.widget.description;
      const name = data.widget.name;
      const magnituds = [];
      const alias = [];
      const indicatorName = [];
      const shortName = [];
      return Promise.all(
        indicators.map((el) => {
          magnituds.push(el.display_options);
          alias.push(el.alias);
          shortName.push(el.short_name);
          indicatorName.push(el.name);
          return api(`${el.url}&locale=${e}`);
        })
      ).then((data) => {
        const indicators = data.map((ele, index) => {
          const values = ele.indicator.values.map((el) => {
            return {
              x: new Date(el.datetime),
              y: el.value,
            };
          });

          return {
            values,
            time: ele.indicator.tiempo[0].id,
            alias: alias[index],
            display_options: magnituds[index],
            composited: ele.indicator?.composited,
            magnitud: ele.indicator?.magnitud,
            disaggregated: ele.indicator?.disaggregated,
            short_name: shortName[index],
            name: indicatorName[index],
            step: ele.indicator.step_type,
            id: ele.indicator.id,
            geo_id: ele.indicator.geos[0]?.geo_id,
            geo_name: ele.indicator.geos[0]?.geo_name,
          };
        });

        return {
          description,
          name,
          indicators,
          slug,
        };
      });
    });
  };

  const getMoreData = async (params) => {
    const { lang } = params;
    return api(`widgets/11?locale=${lang}`).then((response) => {
      const data = response.widget.indicators.map((el) => {
        return {
          id: el.id,
          name: el.alias,
          url: el.url,
        };
      });
      return data;
    });
  };

  return {
    init: init,
    getArchives: (params) => getArchives(params),
    getDataFromWidget: (id, e) => getDataFromWidget(id, e),
    getVolumenDataFromWidget: (params) => getVolumenDataFromWidget(params),
    getDesvioDataFromWidget: (params) => getDesvioDataFromWidget(params),
    getFotovoltaicaDataFromWidget: (params) =>
      getFotovoltaicaDataFromWidget(params),
    getTermicaDataFromWidget: (params) => getTermicaDataFromWidget(params),
    getUnitaryCostDataFromWidget: (params) =>
      getUnitaryCostDataFromWidget(params),
    getMoreData: (params) => getMoreData(params),
    getFacturationDataFromWidget: (params) =>
      getFacturationDataFromWidget(params),
    getAuctionsDataFromWidget: (params) => getAuctionsDataFromWidget(params),
    getAuctionsPortugalDataFromWidget: (params) =>
      getAuctionsPortugalDataFromWidget(params),
    getDDemandaDataFromWidget: (params) => getDDemandaDataFromWidget(params),
    getDEolicaDataFromWidget: (params) => getDEolicaDataFromWidget(params),
    getIDDemandaDataFromWidget: (params) => getIDDemandaDataFromWidget(params),
    getIDEolicaDataFromWidget: (params) => getIDEolicaDataFromWidget(params),
    getPDemandaAnualDataFromWidget: (params) =>
      getPDemandaAnualDataFromWidget(params),
    getMargenGeneracionDataFromWidget: (params) =>
      getMargenGeneracionDataFromWidget(params),
    getPrevisonDemandaResidualDataFromWidget: (params) =>
      getPrevisonDemandaResidualDataFromWidget(params),
    getPrevisionMinimaMensualPeninsularDataFromWidget: (params) =>
      getPrevisionMinimaMensualPeninsularDataFromWidget(params),
    getPrevisionMaximaMensualPeninsularDataFromWidget: (params) =>
      getPrevisionMaximaMensualPeninsularDataFromWidget(params),
    getPrevisionMinimaAnualPeninsularDataFromWidget: (params) =>
      getPrevisionMinimaAnualPeninsularDataFromWidget(params),
    getPrevisionMaximaAnualPeninsular2DataFromWidget: (params) =>
      getPrevisionMaximaAnualPeninsular2DataFromWidget(params),
    getGeneracionPrevistaSolarDataFromWidget: (params) =>
      getGeneracionPrevistaSolarDataFromWidget(params),
    getPrevisionMaximaAnualPeninsularDataFromWidget: (params) =>
      getPrevisionMaximaAnualPeninsularDataFromWidget(params),
    getDemandaSemanalElectricaPeninsularDataFromWidget: (params) =>
      getDemandaSemanalElectricaPeninsularDataFromWidget(params),
    getDemandaMensualElectricaPeninsularDataFromWidget: (params) =>
      getDemandaMensualElectricaPeninsularDataFromWidget(params),
    getDemandaDiariaElectricaPeninsularDataFromWidget: (params) =>
      getDemandaDiariaElectricaPeninsularDataFromWidget(params),
    getDemandaPrevistaSNPDataFromWidget: (params) =>
      getDemandaPrevistaSNPDataFromWidget(params),
    getDemandaPrevistaPeninsularDataFromWidget: (params) =>
      getDemandaPrevistaPeninsularDataFromWidget(params),
  };
})();

export { servicesMercadosPrecios };
