import i18n from 'i18next';
import { html } from 'lit-html';

const MyEsiosWidget1 = () => {
  return html`
    <div class="grid-md-5 grid-sm-6 grid-xs-24 div-potencia">
      <div id="char1" class="widget widget-gyc-potencia">
        <div class="grid-xs-24 grid-sm-24">
          <h2>${i18n.t('gen_conventional')}</h2>

          <div class="widget-toolbar">
            <div class="esios-toolbar">
              <div class="esios-toolbar1"></div>
              <div class=" pickers-wrapper">
                <div id="gen_conventional-time"></div>
              </div>
            </div>
          </div>
          <div id="widget1">
            <div id="spinner1" class="spinner-container">
              <div class="spinner"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `;
};

export default MyEsiosWidget1;
