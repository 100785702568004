'use strict';

/**
 * @description Error Component
 * @returns {Object} Devuelve la instancia del componente
 */

import i18n from 'i18next';
import { render } from 'lit-html';
import { store } from '../../app.init.js';
import { templateerror } from './error.template';

let componenterror = (function () {
  let context = {};
  let handlers = {};
  let renderSuccess = false;

  let preRenderComponent = () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let renderComponent = async () => {
    const state = store.getState();
    try {
      // Render the template to the document

      render(
        // Define a template
        templateerror(context, handlers),
        document.querySelector('body')
      );
      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  return {
    render: init,
  };
})();

export { componenterror };
