const indicatorsSimple = (data) => {
  return `
  WITH t as (

    SELECT ${data.agg}(INDICATOR_values.value) AS indicator_total, indicator_values.geo_id
    FROM indicator_values
    WHERE indicator_id = ${data.id}
      AND datetime >= to_timestamp('${data.start_date}', 'YYYY-MM-DD HH24:MI:SS')
      AND datetime <= to_timestamp('${data.end_date}', 'YYYY-MM-DD HH24:MI:SS')
    GROUP BY INDICATOR_values.geo_id
  
  ), j as (
  
    SELECT geo_def.cartodb_id,
      geo_def.name AS name,
      (SELECT ROUND(${data.geo_agg}(indicator_total)::numeric, ${data.decimals}) FROM t WHERE geo_id = cartodb_id LIMIT 1) as total,
      to_timestamp('${data.datetime}', 'YYYY-MM-DD HH24:MI:SS') AS stamp
    FROM geo_def
    WHERE nivel = ${data.nivel} ${data.where_geoids}
  
  )
  
  SELECT j.*, k.name as local_name
  FROM j
  JOIN GEOLOCALIZATION_TRANSLATIONS k ON k.geolocalization_id=j.cartodb_id
  WHERE j.total IS NOT NULL
    AND k.locale='${data.lang}'
  `;
};

export default indicatorsSimple;
