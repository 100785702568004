'use strict';

import { html } from 'lit-html';
/**
 * @description Header Component
 * @returns {Object} Devuelve la instancia del componente
 */

import { store } from '../../../app.init.js';
import { templateRapidAccess } from './rapid-access.template.js';

let componentRapidAccess = (function () {
  let context = {};
  let idForRender;
  let renderSuccess = false;

  let preRenderComponent = () => {
    const state = store.getState();
  };

  let renderComponent = (data) => {
    try {
      // Render the template to the document

      $(function () {
        idForRender = document.querySelector('#rapid-access');
        idForRender.innerHTML = templateRapidAccess(data);
        let menu = document.querySelector('#rapid-access ul');
        let activatorBtn = document.querySelector('#buttonActivator');
        const content = document.getElementById('rapid-access');

        let navigationFn = {
          goToSection: function (id) {
            $('html, body').animate(
              {
                scrollTop: $(id).offset().top - menu.offsetHeight - 100,
              },
              0
            );
          },
        };

        data.map((item) => {
          document
            .getElementById('button-' + item.id)
            .addEventListener('click', (e) => {
              idForRender.style.height = '0px';
              activatorBtn.classList.toggle('is-open');
              navigationFn.goToSection(item.id);

              if (content.classList.contains('expanded')) {
                content.style.height = `${menu.offsetHeight + 10}px`;
                activatorBtn.classList.add('is-open');
              } else {
                content.style.height = '0px';
                activatorBtn.classList.remove('is-open');
              }
              content.classList.toggle('expanded');
            });
        });
      });

      renderSuccess = true;
    } catch (error) {
      renderSuccess = false;
    }
  };

  let postRenderComponent = (data) => {
    const state = store.getState();
  };

  let init = (data) => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent(data);

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent(data) : '';
  };

  return {
    render: init,
  };
})();

export { componentRapidAccess };
