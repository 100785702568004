'use strict';
import { html } from 'lit-html';
import { componentLayout } from '../../components/layout/layout.component';
import backImage from '../../assets/images/header-default.jpg';

let templateinterrupcion = (context, handlers, contactData) => {
  const { title, body } = contactData;

  return componentLayout.render(
    html`
      <div class="esios-interrupcion ">
        <div class="grid-sm-23 grid-xs-24">
          <h1 class="esios-interrupcion__title">${title}</h1>
          ${html([body])}
        </div>
      </div>
    `,
    {
      image: backImage,
      showBanner: true,
      showFooterImage: true,
    }
  );
};

export { templateinterrupcion };
