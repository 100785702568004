'use strict';

import i18next from 'i18next';

let templateRapidAccess = (data) => {
  return `
    <ul>
    ${data
      .map((item) => {
        return `
        <li>
          <a class="ancle" id="button-${item.id}"> ${i18next.t(item.title)} </a>
        </li>
        `;
      })
      .join('')}
    </ul>
  `;
};
export { templateRapidAccess };
