'use strict';
import { html } from 'lit-html';
import { componentLayout } from '../../components/layout/layout.component';
import { templateLayout } from '../../components/layout/layout.template';
import backImage from '../../assets/images/header-default.jpg';
let templatePropuestas = (context, handlers, propuestasData) => {
  const { title, body } = propuestasData;
  return componentLayout.render(
    html`
      <div class="esios-propuesta">
        <h1>${title}</h1>
        ${html([body])}
      </div>
    `,
    {
      image: backImage,
      showBanner: true,
      showFooterImage: true,
    }
  );
};

export { templatePropuestas };
