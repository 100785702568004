import i18n from 'i18next';

const options = () => ({
  firstDay: 1,
  dateFormat: 'dd / mm / yy',
  altFormat: 'dd / mm / yy',
  monthNames: [
    i18n.t('january'),
    i18n.t('february'),
    i18n.t('march'),
    i18n.t('april'),
    i18n.t('ma'),
    i18n.t('june'),
    i18n.t('july'),
    i18n.t('august'),
    i18n.t('september'),
    i18n.t('october'),
    i18n.t('november'),
    i18n.t('december'),
  ],
  monthNamesShort: [
    i18n.t('jan'),
    i18n.t('feb'),
    i18n.t('mar'),
    i18n.t('apr'),
    i18n.t('may'),
    i18n.t('june'),
    i18n.t('july'),
    i18n.t('aug'),
    i18n.t('sep'),
    i18n.t('oct'),
    i18n.t('nov'),
    i18n.t('dec'),
  ],
  dayNames: [
    i18n.t('sunday'),
    i18n.t('monday'),
    i18n.t('tuesday'),
    i18n.t('wednesday'),
    i18n.t('thursday'),
    i18n.t('friday'),
    i18n.t('saturday'),
  ],
  dayNamesShort: [
    i18n.t('sun'),
    i18n.t('mon'),
    i18n.t('tue'),
    i18n.t('wed'),
    i18n.t('thu'),
    i18n.t('fri'),
    i18n.t('sat'),
  ],
  dayNamesMin: [
    i18n.t('Su'),
    i18n.t('M'),
    i18n.t('T'),
    i18n.t('W'),
    i18n.t('Th'),
    i18n.t('F'),
    i18n.t('S'),
  ],
  weekHeader: i18n.t('week'),
  autoSize: true,
  maxDate: null,
  changeMonth: true,
  changeYear: true,
  showButtonPanel: true,
  todayBtn: 'linked',
  currentText: i18n.t('today'),
});

export default options;
