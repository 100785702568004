'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';

let templateobsoleto = (context, handlers) => {
  return html`
    <div class="esios-Obsoleto">
      <h1 class="esios-Obsoleto__title">${i18n.t('no_compatible')}</h1>
      <p class="esios-Obsoleto__text">${i18n.t('incompatibility_msg')}</p>
      <p class="esios-Obsoleto__text">${html([i18n.t('please_contact')])}</p>
    </div>
  `;
};

export { templateobsoleto };
