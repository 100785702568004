'use strict';
import { html } from 'lit-html';
import i18n from 'i18next';
import { componentLayout } from '../../components/layout/layout.component';
import backImage from '../../assets/images/header-default.jpg';

let templateMapa = (context, handlers) => {
  return componentLayout.render(
    html`
      <div class="esios-mapa">
        <div class="esios-mapa__title">
          <h1>${i18n.t('sitemap_word').toUpperCase()}</h1>
        </div>
        <div class="esios-mapa__content">
          <a href="https://www.esios.ree.es/es"
            ><h2>${i18n.t('start').toUpperCase()}</h2></a
          >
        </div>

        <div class="esios-mapa__content--row">
          <div class="col-lg-3 col-sm-4 col-xs-14">
            <ul>
              <li>
                <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.gyc')}"
                  >${i18n.t('generation_and_consumption').toUpperCase()}</a
                >
                <ul>
                  <li>
                    <a
                      href="/${i18n.t('routes.lng')}/${i18n.t('routes.gcsnp')}"
                      class="ColorOption"
                      >${i18n.t('non_peninsular_systems').toUpperCase()}</a
                    >
                  </li>
                  <li>
                    <a
                      href="/${i18n.t('routes.lng')}/${i18n.t(
                        'routes.balance'
                      )}"
                      class="ColorOption"
                      >${i18n.t('balance').toUpperCase()}</a
                    >
                  </li>
                </ul>
              </li>
              <li>
                <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.myp')}"
                  >${i18n.t('markets_and_prices').toUpperCase()}</a
                >
                <ul>
                  <li>
                    <a
                      href="/${i18n.t('routes.lng')}/${i18n.t(
                        'routes.supply_curves'
                      )}"
                      class="ColorOption"
                      >${i18n.t('supply_curves').toUpperCase()}</a
                    >
                  </li>
                </ul>
              </li>
              <li>
                <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.gd')}"
                  >${i18n.t('demand_management').toUpperCase()}</a
                >
              </li>
              <li>
                <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.pvpc')}"
                  >${i18n.t('pvpc').toUpperCase()}</a
                >
                <ul>
                  <li>
                    <a
                      href="/${i18n.t('routes.lng')}/${i18n.t('routes.lumios')}"
                      class="ColorOption"
                      >${i18n.t('routes.lumios').toUpperCase()}</a
                    >
                  </li>
                </ul>
              </li>
              <li>
                <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.analysis')}"
                  >${i18n.t('analysis').toUpperCase()}</a
                >
              </li>
            </ul>
          </div>
          <div class="col-lg-3 col-sm-4 col-xs-14">
            <ul>
              <li>
                <a
                  href="/${i18n.t('routes.lng')}/${i18n.t(
                    'routes.market_subjects'
                  )}"
                  >${i18n.t('market_subjects').toUpperCase()}</a
                >
              </li>
              <li>
                <a
                  href="/${i18n.t('routes.lng')}/${i18n.t(
                    'routes.programming_units'
                  )}"
                  >${i18n.t('programming_units').toUpperCase()}</a
                >
              </li>
              <li>
                <a
                  href="/${i18n.t('routes.lng')}/${i18n.t(
                    'routes.physical_units'
                  )}"
                  >${i18n.t('physical_units').toUpperCase()}</a
                >
              </li>
              <li>
                <a
                  href="/${i18n.t('routes.lng')}/${i18n.t(
                    'routes.participants_auction'
                  )}"
                >
                  ${i18n.t('participants_auction').toUpperCase()}
                </a>
              </li>
              <li>
                <a
                  href="/${i18n.t('routes.lng')}/${i18n.t(
                    'routes.service_activation'
                  )}"
                >
                  ${i18n.t('service_activation').toUpperCase()}
                </a>
              </li>
            </ul>
          </div>

          <div class="col-lg-3 col-sm-4 col-xs-14">
            <ul>
              <li>
                <a
                  href="/${i18n.t('routes.lng')}/${i18n.t(
                    'routes.documentation'
                  )}"
                >
                  ${i18n.t('documentation').toUpperCase()}</a
                >
              </li>
              <li>
                <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.downloads')}"
                  >${i18n.t('downloads').toUpperCase()}</a
                >
              </li>
              <li>
                <a href="https://www.ree.es/es/aviso-legal">
                  ${i18n.t('legal_advise').toUpperCase()}</a
                >
              </li>
              <li>
                <a href="https://www.ree.es/es/politica-de-privacidad">
                  ${i18n.t('privacy_policy').toUpperCase()}</a
                >
              </li>
            </ul>
          </div>

          <div class="col-lg-2 col-sm-4 col-xs-14">
            <ul>
              <li>
                <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.help-FAQ')}"
                  >${i18n.t('help-FAQ').toUpperCase()}</a
                >
                <ul>
                  <li>
                    <a
                      href="/${i18n.t('routes.lng')}/${i18n.t(
                        'routes.glossary'
                      )}"
                      class="ColorOption"
                      >${i18n.t('glossary').toUpperCase()}</a
                    >
                  </li>
                </ul>
              </li>
              <li>
                <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.contact')}"
                  >${i18n.t('contact').toUpperCase()}</a
                >
              </li>
            </ul>
          </div>

          <div class="col-lg-2 col-sm-4 col-xs-14">
            <ul>
              <li>
                <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.umms')}"
                  >${i18n.t('umm').toUpperCase()}</a
                >
              </li>
              <li>
                <a href="/${i18n.t('routes.lng')}/${i18n.t('routes.news')}"
                  >${i18n.t('news').toUpperCase()}</a
                >
              </li>
              <li>
                <a
                  href="/${i18n.t('routes.lng')}/${i18n.t(
                    'routes.interest_maps'
                  )}"
                  >${i18n.t('interest_maps').toUpperCase()}</a
                >
              </li>
              <li>
                <a
                  href="/${i18n.t('routes.lng')}/${i18n.t(
                    'routes.other_links'
                  )}"
                  >${i18n.t('other_links').toUpperCase()}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    `,
    {
      image: backImage,
      showFooterImage: true,
    }
  );
};

export { templateMapa };
