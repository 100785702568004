import i18next from 'i18next';
import { html } from 'lit-html';

const TemplateEmbedIndicatorsMaxAnnualForecastPeninsularElectricityDemand =
  () => {
    return html` <style>
        .caption {
          color: #444444;
          font-size: 1.4375rem;
          letter-spacing: -0.145rem;
          font-family: 'Cousine';
        }

        .value.is-number {
          margin: 0.7vh 0 0 1rem !important;
          display: block;
        }

        .label {
          color: #717171;
          text-transform: uppercase;
          font-size: 0.875rem;
          font-family: 'Proxima-Nova', Arial, sans-serif;
          font-weight: 600 !important;
          margin-bottom: 1rem;
        }

        .unit {
          margin-left: 5px;
        }

        .esios-listEnergyItems__label {
          display: block;
        }
      </style>

      <div
        class="col-sm-26 col-xs-9 myPrevisionMaximaAnualPeninsularWiew"
        id="myPrevisionMaximaAnualPeninsularWiew"
      >
        <div id="spinner18" class="spinner-container">
          <div class="overlay"></div>
          <div class="spinner"></div>
        </div>
        <div id="widget18">
          <div id="mypGraphic18" class="chari-chart aspect myp-aspect">
            <div
              id="mypNoDataRect18"
              class="esios-home__no-data-container_cuadrado hidden"
            >
              ${i18next.t('no-data-available')}
            </div>
          </div>
          <ul class="esios-listEnergyItems__list">
            <div class="row"></div>
          </ul>
        </div>
        <div class="col-xs-24 col-sm-24 mt-5"></div>
      </div>`;
  };

export default TemplateEmbedIndicatorsMaxAnnualForecastPeninsularElectricityDemand;
