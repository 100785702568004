'use strict';
import i18n from 'i18next';
import { html } from 'lit-html';
import downArrow from '../../assets/images/down-arrow.svg';
import upArrow from '../../assets/images/up-arrow.svg';
import zipImage from '../../assets/images/zip.svg';
import pdfImage from '../../assets/images/pdf.svg';
import linkImage from '../../assets/images/link.svg';
import wordImage from '../../assets/images/word.svg';
import xmlImage from '../../assets/images/xml.svg';
import xlsImage from '../../assets/images/xls.svg';
import csvImage from '../../assets/images/csv.svg';

let templateTable = (columns = [], datasource = [], allData = []) => {
  let sortedData = [...datasource];
  const sortData = (columnItem, $index) => {
    if (
      $("[data-index='" + $index + "']").hasClass('asc') ||
      $("[data-index='" + $index + "']").hasClass('desc')
    ) {
      columnItem.defaultSortOrder =
        columnItem.defaultSortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      columnItem.defaultSortOrder = 'asc';
    }
    $('.esios-table__cell--sort').removeClass('asc desc');

    if (columnItem.defaultSortOrder === 'asc') {
      $("[data-index='" + $index + "']").addClass('desc');
    }
    if (columnItem.defaultSortOrder === 'desc') {
      $("[data-index='" + $index + "']").addClass('asc');
    }
    columnItem.sorter([...allData], columnItem.defaultSortOrder);
  };

  return html`
    <table class="esios-table">
      <thead class="esios-table__head">
        <tr class="esios-table__row">
          ${columns.map((columnItem, i) => {
            const hasDesc = i === 0 ? 'desc' : '';
            return html`<th>
              <div
                class="esios-table__cell"
                @click=${() => sortData(columnItem, i)}
              >
                <a class="esios-table__head-txt" href="javascript:;"
                  >${i18n.t(columnItem.title)}</a
                >
                <div class="esios-table__cell--sort ${hasDesc}" data-index=${i}>
                  <i class="fas fa-sort"></i>
                  <i class="fas fa-sort-up"></i>
                  <i class="fas fa-sort-down"></i>
                </div>
              </div>
            </th>`;
          })}
        </tr>
      </thead>
      <tbody>
        ${sortedData.length > 0
          ? sortedData.map((dataItem) => {
              return html`<tr class="esios-table__row">
                ${columns.map((columnItem) => {
                  if (columnItem.render) {
                    return html`<td data-title=${i18n.t(columnItem.title)}>
                      <div class="esios-table__content-mobile">
                        <span class="esios-table__cell-txt">
                          ${columnItem.render(
                            dataItem[columnItem.dataIndex],
                            columnItem
                          )}
                        </span>
                      </div>
                    </td>`;
                  } else {
                    /*  html([body]) */
                    if (dataItem.type === 'documentations') {
                      if (dataItem.media[0]?.media_type == 'Link') {
                        return html`<td
                          data-title=${i18n.t(columnItem.title)}
                          class="esios-table__title"
                        >
                          ${dataItem.media
                            ? html`<div class="esios-table__content-mobile">
                                <figure class="esios-table__figure">
                                  <img
                                    class="esios-document-list__pdf"
                                    src="${linkImage}"
                                  />
                                </figure>
                                <a
                                  href="${dataItem.media[0]?.download
                                    ? ENV.API_URL + dataItem.media[0]?.download
                                    : dataItem.media[0]?.embed_path}"
                                  download
                                  >${dataItem.title}</a
                                >
                              </div>`
                            : html`<td>
                                ${html([dataItem[columnItem.dataIndex]])}
                              </td>`}
                        </td>`;
                      } else if (
                        dataItem.media[0]?.document_value_file_name.split(
                          '.'
                        )[1] == 'docx'
                      ) {
                        return html`<td
                          data-title=${i18n.t(columnItem.title)}
                          class="esios-table__title"
                        >
                          ${dataItem.media
                            ? html`<div class="esios-table__content-mobile">
                                <figure class="esios-table__figure">
                                  <img
                                    class="esios-document-list__pdf"
                                    src="${wordImage}"
                                  />
                                </figure>
                                <a
                                  href="${ENV.API_URL}${dataItem.media[0]
                                    ?.download}"
                                  download
                                  >${dataItem.title}</a
                                >
                              </div>`
                            : html`<td>
                                ${html([dataItem[columnItem.dataIndex]])}
                              </td>`}
                        </td>`;
                      } else {
                        return html`<td
                          data-title=${i18n.t(columnItem.title)}
                          class="esios-table__title"
                        >
                          ${dataItem.media
                            ? html`<div class="esios-table__content-mobile">
                                <figure class="esios-table__figure">
                                  <img
                                    class=${dataItem.media[0]
                                      ?.document_value_content_type ===
                                    'application/pdf'
                                      ? 'esios-document-list__pdf'
                                      : 'esios-document-list__zip'}
                                    src="${dataItem.media[0]
                                      ?.document_value_content_type ===
                                    'application/pdf'
                                      ? pdfImage
                                      : zipImage}"
                                  />
                                </figure>
                                <a
                                  href="${ENV.API_URL}${dataItem.media[0]
                                    ?.download}"
                                  download
                                  >${dataItem.title}</a
                                >
                              </div>`
                            : html`<td>
                                ${html([dataItem[columnItem.dataIndex]])}
                              </td>`}
                        </td>`;
                      }
                    } else if (dataItem.download) {
                      if (
                        dataItem.archive_type === 'pdf' ||
                        dataItem.archive_type === 'zip' ||
                        dataItem.archive_type === 'xls' ||
                        dataItem.archive_type === 'csv' ||
                        dataItem.archive_type === 'xml'
                      ) {
                        return html` <td class="esios-table__title">
                          <figure class="esios-table__figure">
                            <img
                              class="esios-document-list__${dataItem.archive_type}"
                              src="${dataItem.archive_type == 'pdf'
                                ? pdfImage
                                : dataItem.archive_type == 'zip'
                                ? zipImage
                                : dataItem.archive_type == 'xls'
                                ? xlsImage
                                : dataItem.archive_type == 'xml'
                                ? xmlImage
                                : dataItem.archive_type == 'csv'
                                ? csvImage
                                : ''}"
                            />
                          </figure>
                          <a
                            href="${ENV.API_URL + dataItem.download.url}"
                            download
                            >${dataItem.download.name}</a
                          >
                        </td>`;
                      }
                    } else {
                      return html`<td data-title=${i18n.t(columnItem.title)}>
                        ${html([dataItem[columnItem.dataIndex]])}
                      </td>`;
                    }
                  }
                })}
              </tr>`;
            })
          : html`<tr class="esios-table__row center-row">
              <td class="not-found" colspan=${columns.length}>
                ${i18n.t('zeroRecords')}
              </td>
            </tr>`}
      </tbody>
    </table>
    <hr class="line-table" />
  `;
};

export { templateTable };
